/* eslint-disable react/no-array-index-key */
import React from 'react';
import {dynID} from '@ecosio/pathform';
import PropTypes from 'prop-types';
import {Modal, Header, Button, Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import {get} from 'lodash';
import {injectIntl, FormattedMessage as Msg} from 'react-intl';
import {ecosioColors} from '@ecosio/customer-layout';
import {connect} from 'react-redux';
import {ContentDivider} from '../../CommonComponents/Create/Header/StyledDivs';
import DesadvInput from '../../CommonComponents/Create/LineItems/DesadvInput';
import {FieldContent} from '../../CommonComponents/Create/LineItems/FieldContent';
import {pageConfigShape} from '../../../../../../shapes/scenarios';
import SimpleComplexModalSideBar from './SimpleComplexModalSideBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props?.index !== 0 ? '32px' : '0px')};
  margin-bottom: ${(props) => (props?.islastLineItem ? '10em' : '0em')};
`;

const ContainerHeader = styled.div`
  flex: 1;
  display: flex;
`;

const PackagingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props?.backgroundColor ? `background:${props.backgroundColor}` : ''}
`;

const PackagingLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    display: flex;
    i {
      margin-right: 8px;
      font-size: 12px !important;
    }
  }
`;

const ModalContainer = styled.div``;

const ModalSubContainer = styled.div`
  display: flex;
  width: 100% !important;
`;

const ModalContentContainer = styled.div`
  width: 100% !important;
  min-height: 70vh;
`;

const DesadvModalLineItem = ({
  index,
  deliveryLineItem,
  pageConfig,
  intl,
  deliveryLineItemsLength,
}) => {
  const packagingLabel = pageConfig?.cpsConfigs[0]?.label;
  const formFieldConfig = pageConfig?.formFields || {};

  const dispatchQuantityUnit = deliveryLineItem?.dispatchQuantity?.unit;
  const islastLineItem =
    deliveryLineItemsLength > 0 && deliveryLineItemsLength === index + 1;

  return (
    <Container index={index} islastLineItem={islastLineItem}>
      <ContainerHeader>
        <DesadvInput
          columnWidth={6}
          path="itemDescription"
          forceRender
          render={(props) => (
            <FieldContent
              {...props}
              value={deliveryLineItem?.itemDescription}
              injectedLabelValue={
                deliveryLineItem.references?.agreementOrOrderLineNumber
              }
              fieldConfig={get(formFieldConfig, 'itemDescription')}
            />
          )}
        />
        <DesadvInput
          columnWidth={3}
          path="gtin"
          forceRender
          render={(props) => (
            <FieldContent
              {...props}
              value={deliveryLineItem?.gtin}
              fieldConfig={get(formFieldConfig, 'gtin')}
            />
          )}
        />
        <DesadvInput
          columnWidth={3}
          path="packagingInformations.numberOfPackages"
          forceRender
          render={(props) => (
            <FieldContent
              {...props}
              value={`${deliveryLineItem.cpses.length}`}
              unit="LE_PACKAGES"
              fieldConfig={get(
                formFieldConfig,
                'packagingInformations.numberOfPackages'
              )}
            />
          )}
        />
      </ContainerHeader>
      <ContentDivider />

      {deliveryLineItem?.cpses.map((cps, cpsIndex) => {
        return (
          <>
            <PackagingContainer
              key={cpsIndex}
              className="desadvModalPackagingEditFieldsComponent">
              <DesadvInput
                columnWidth={3}
                forceRender
                render={() => (
                  <PackagingLabelContainer>
                    <Header as="h4">
                      <Icon name="pallet" />
                      <Msg id={dynID(packagingLabel)} />
                    </Header>
                    <div>
                      {cps?.packagingInformations[0]?.quantityPerPack} /{' '}
                      {deliveryLineItem?.quantityPerPack}{' '}
                      <Msg id={dynID(dispatchQuantityUnit)} />
                    </div>
                  </PackagingLabelContainer>
                )}
              />
              <DesadvInput
                columnWidth={3}
                path="packagingInformations.quantityPerPack"
                formFieldConfig={formFieldConfig}
                render={(props) => (
                  <FieldContent
                    {...props}
                    renderLabel={cpsIndex === 0}
                    value={`${
                      cps.packagingInformations[0].quantityPerPack
                    } ${intl.formatMessage({
                      id: dynID(dispatchQuantityUnit),
                    })}`}
                    fieldConfig={get(
                      formFieldConfig,
                      'packagingInformations.quantityPerPack'
                    )}
                  />
                )}
              />
              <DesadvInput
                columnWidth={3}
                path="packagingInformations.identityNumber"
                formFieldConfig={formFieldConfig}
                render={(props) => (
                  <FieldContent
                    {...props}
                    renderLabel={cpsIndex === 0}
                    value={
                      cps.packagingInformations[0].identifications[0]
                        .identityNumber
                    }
                    fieldConfig={get(
                      formFieldConfig,
                      'packagingInformations.identityNumber'
                    )}
                  />
                )}
              />

              <DesadvInput
                columnWidth={3}
                path="packagingInformations.packageTypeCustomer"
                formFieldConfig={formFieldConfig}
                render={(props) => (
                  <FieldContent
                    {...props}
                    renderLabel={cpsIndex === 0}
                    value={cps.packagingInformations[0].packageTypeCustomer}
                    fieldConfig={get(
                      formFieldConfig,
                      'packagingInformations.packageTypeCustomer'
                    )}
                  />
                )}
              />

              <DesadvInput
                columnWidth={3}
                path="packagingInformations.batchNumber"
                formFieldConfig={formFieldConfig}
                render={(props) => (
                  <FieldContent
                    {...props}
                    renderLabel={cpsIndex === 0}
                    value={cps.packagingInformations[0].batchNumber}
                    fieldConfig={get(
                      formFieldConfig,
                      'packagingInformations.batchNumber'
                    )}
                  />
                )}
              />

              <DesadvInput
                columnWidth={4}
                path="packagingInformations.expirationDate"
                formFieldConfig={formFieldConfig}
                render={(props) => (
                  <FieldContent
                    {...props}
                    renderLabel={cpsIndex === 0}
                    value={cps.packagingInformations[0].expirationDate}
                    fieldConfig={get(
                      formFieldConfig,
                      'packagingInformations.expirationDate'
                    )}
                  />
                )}
              />
            </PackagingContainer>
            {cpsIndex !== deliveryLineItem?.cpses.length - 1 && (
              <ContentDivider style={{margin: '0px'}} />
            )}
          </>
        );
      })}
    </Container>
  );
};

DesadvModalLineItem.defaultProps = {};

DesadvModalLineItem.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  deliveryLineItem: PropTypes.object,
  index: PropTypes.number.isRequired,
};

export const DesadvLineModalContent = ({
  deliveryLineItems,
  onCancel,
  pageConfig,
  intl,
  document,
  theme,
}) => {
  return (
    <ModalContainer>
      <ModalSubContainer>
        <SimpleComplexModalSideBar
          pageConfig={pageConfig}
          document={document || {}}
          theme={theme}
        />
        <ModalContentContainer>
          {deliveryLineItems.map((item, index) => (
            <DesadvModalLineItem
              key={index}
              pageConfig={pageConfig}
              deliveryLineItemsLength={deliveryLineItems.length}
              index={index}
              deliveryLineItem={item}
              theme={theme}
              intl={intl}
            />
          ))}
        </ModalContentContainer>
      </ModalSubContainer>
      <ContentDivider
        style={{
          marginTop: '0px',
          marginLeft: '-20px',
        }}
      />
      <div style={{float: 'right', margin: '13px 0px 13px 0px'}}>
        <Button
          onClick={onCancel}
          style={{backgroundColor: ecosioColors.greyShades[0]}}>
          <Msg id="GENERAL_CANCEL" />
        </Button>
      </div>
    </ModalContainer>
  );
};

DesadvLineModalContent.propTypes = {
  deliveryLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  pageConfig: pageConfigShape.isRequired,
};

const DesadvPackagingInformationModal = ({open, ...rest}) => {
  return (
    <Modal open={open} size="fullscreen">
      <Header style={{background: `${ecosioColors.greyShades[5]}`}}>
        <Msg id={dynID('LE_MODAL_SHOW_INFORMATION')} />
      </Header>
      <Modal.Content>
        <DesadvLineModalContent {...rest} />
      </Modal.Content>
    </Modal>
  );
};

DesadvPackagingInformationModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({config}) => ({
  theme: config?.userConfig?.theme,
});

export default injectIntl(
  connect(mapStateToProps)(DesadvPackagingInformationModal)
);
