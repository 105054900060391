import React from 'react';
import {Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {injectIntl} from 'react-intl';

const TaskStatusButton = styled(Button)`
  padding: 7px 8px!important;
  border-radius: 4px !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  letter-spacing: 0px !important;
  color: white;!important;
  background-color: ${(props) => props.color} !important;
  cursor: default !important;
`;

export const taskStatusIconData = {
  TASK_STATUS_OPEN: {
    color: '#5cb85c',
    label: 'TASK_STATUS_OPEN',
  },
  TASK_STATUS_DUE: {
    color: '#f2711c',
    label: 'TASK_STATUS_DUE',
  },
  TASK_STATUS_OVERDUE: {
    color: 'red',
    label: 'TASK_STATUS_OVERDUE',
  },
};

export const statuses = {
  OPEN: 'TASK_STATUS_OPEN',
  DUE: 'TASK_STATUS_DUE',
  OVERDUE: 'TASK_STATUS_OVERDUE',
};

const getStatusColor = (status) => taskStatusIconData[status].color || 'grey';

const TaskStatusLabel = ({status, intl, ...rest}) => {
  return (
    <div>
      <TaskStatusButton color={getStatusColor(status)} {...rest}>
        {intl.formatMessage({id: status})}
      </TaskStatusButton>
    </div>
  );
};

TaskStatusLabel.propTypes = {
  status: PropTypes.oneOf(Object.values(statuses)).isRequired,
};

export default injectIntl(TaskStatusLabel);
