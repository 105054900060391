import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';

import {pageConfigShape, scenarioShape} from '../../../shapes/scenarios';
import {
  INDUSTRY_DESADV,
  INDUSTRY_INVOICE,
  INDUSTRY_ORDER_RESPONSE,
} from '../../../shapes/supportedDocTypes';
import {envelopesShape} from '../../../shapes/envelopes';
import {outboundPageConfigForDocType} from '../../../util/scenarioUtil';
import CreateDocumentButton from './CreateDocumentButton';
import CreateTurnaroundModal from './CreateTurnaroundModal';

class CreateDocumentButtonBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalOpen: false, payload: {}};
  }

  onOpenModal = (payload) => {
    this.setState({payload, modalOpen: true});
  };

  onCloseModal = () => {
    this.setState({payload: {}, modalOpen: false});
  };

  render() {
    const {scenario, envelopeStatus, envelope, documentUuid, hide} = this.props;

    return hide || envelopeStatus === 'ARCHIVED' ? null : (
      <>
        <CreateTurnaroundModal
          {...this.state.payload}
          open={this.state.modalOpen}
          onCloseModal={this.onCloseModal}
          envelope={envelope}
          scenario={scenario}
        />
        <Grid className="_si_create_doc" data-spec="create-doc-bar">
          <Grid.Row columns={1}>
            <Grid.Column>
              <CreateDocumentButton
                scenario={scenario}
                envelope={envelope}
                documentUuid={documentUuid}
                targetDocType={INDUSTRY_ORDER_RESPONSE}
                pageConfig={outboundPageConfigForDocType(
                  scenario,
                  INDUSTRY_ORDER_RESPONSE
                )}
                onOpenModal={this.onOpenModal}
              />
              <CreateDocumentButton
                scenario={scenario}
                envelope={envelope}
                documentUuid={documentUuid}
                targetDocType={INDUSTRY_DESADV}
                pageConfig={outboundPageConfigForDocType(
                  scenario,
                  INDUSTRY_DESADV
                )}
                onOpenModal={this.onOpenModal}
              />
              <CreateDocumentButton
                scenario={scenario}
                envelope={envelope}
                documentUuid={documentUuid}
                targetDocType={INDUSTRY_INVOICE}
                pageConfig={outboundPageConfigForDocType(
                  scenario,
                  INDUSTRY_INVOICE
                )}
                onOpenModal={this.onOpenModal}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

CreateDocumentButtonBar.propTypes = {
  hide: PropTypes.bool.isRequired,
  envelopeStatus: PropTypes.string,
  scenario: scenarioShape.isRequired,
  envelope: envelopesShape.isRequired,
  // the uuid of the currently viewed document ( can also be 'merged')
  documentUuid: PropTypes.string.isRequired,
  pageConfig: pageConfigShape.isRequired,
};

export default CreateDocumentButtonBar;
