import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {pageConfigShape} from '../../../../../../../shapes/scenarios';
import BusinessEntities from './BusinessEntities/BusinessEntities';
import OrderHeaderInformation from './OrderHeaderInformation/OrderHeaderInformation';

class DocumentMeta extends Component {
  constructor(props) {
    super(props);
    const {documentExchange} = props;
    if (!documentExchange.document) {
      return;
    }

    this.state = {};
  }

  onSubmit = (values) => {
    return this.props.onSubmit(values);
  };

  render() {
    const {
      documentExchange,
      pageConfig,
      showReadonly,
      exposeHeaderValues,
      exposedValues,
    } = this.props;

    return (
      <div>
        <OrderHeaderInformation
          showReadonly={showReadonly}
          documentExchange={documentExchange}
          onSubmit={this.onSubmit}
          exposeHeaderValues={exposeHeaderValues}
          exposedValues={exposedValues}
          pageConfig={pageConfig}
        />
        <BusinessEntities
          businessEntities={documentExchange.document.header.businessEntities}
        />
      </div>
    );
  }
}

DocumentMeta.propTypes = {
  showReadonly: PropTypes.bool,
  pageConfig: pageConfigShape.isRequired,
  documentExchange: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default DocumentMeta;
