import React from 'react';

interface SVGRendererProps {
  svgIcon?: React.ComponentClass;
}

const SVGRenderer: React.FC<SVGRendererProps> = ({svgIcon, ...props}) => {
  if (!svgIcon) {
    return null;
  }
  const SVGComponent = svgIcon;

  return <SVGComponent {...props} />;
};

export default SVGRenderer;
