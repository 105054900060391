/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Content, RenderContents, filterFields, dynID} from '@ecosio/pathform';
import {Table, Button, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {pageConfigShape} from '../../../../../shapes/scenarios';
import {UnitPriceCell, TotalPriceCell} from './Cells/index';

const styleAoc = {color: '#99a4af'};

export const actionRequestMap = {
  1: 'LE_ADD',
  2: 'LE_CANCEL',
  3: 'LE_CHANGE',
};

export const isRequestCodeCancelled = (actionRequest) => {
  // both values map to "cancelled/rejected"
  return actionRequest === '2' || actionRequest === 'REJECTED';
};

export const isLineItemCancelled = (lineItem) => {
  if (!lineItem) {
    console.warn('No line item passed to isLineItemCancelled');
    return false;
  }

  const actionRequest = lineItem.lineItemActionRequest;
  return isRequestCodeCancelled(actionRequest);
};

const rowFormatter = (lineItem) => {
  return isLineItemCancelled(lineItem)
    ? {'text-decoration': 'line-through', ...styleAoc}
    : {};
};

const SerialNumberCell = ({lineItem, serialNumbersConfig, dataSpec}) => {
  const numbers = lineItem?.materialCharacteristics?.serialNumbers || [];

  if (!serialNumbersConfig) {
    return null;
  }
  if (!numbers.length) {
    return <Table.Cell />;
  }

  return (
    <Table.Cell className="snCell" data-spec={dataSpec}>
      {numbers.map((number, idx) => (
        <div key={`${number} ${idx}`}>{number}</div>
      ))}
    </Table.Cell>
  );
};
SerialNumberCell.propTypes = {
  lineItem: PropTypes.object,
  dataSpec: PropTypes.string,
};

const CalcBody = ({lineItem, currency, idx}) => (
  <Fragment>
    <UnitPriceCell
      lineItem={lineItem}
      styleAoc={styleAoc}
      currency={currency}
      dataSpec={`unitPrice-${idx}`}
    />
    <TotalPriceCell
      lineItem={lineItem}
      styleAoc={styleAoc}
      currency={currency}
      dataSpec={`totalPrice-${idx}`}
    />
  </Fragment>
);

CalcBody.propTypes = {
  lineItem: PropTypes.object,
  currency: PropTypes.string,
  idx: PropTypes.number,
};

export const PartyNames = ({partyNames}) => {
  if (!Array.isArray(partyNames)) {
    return <span>{partyNames}</span>;
  }

  return (
    <span>
      {partyNames.map((name) => (
        <React.Fragment key={name}>
          <span>{name}</span>
          <br />
        </React.Fragment>
      ))}
    </span>
  );
};

PartyNames.propTypes = {
  partyNames: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

const TableBody = ({
  lineItems,
  pageConfig,
  showCalculation,
  onSelect,
  currency,
}) => {
  const getField = (path) => {
    return pageConfig.formFields[path];
  };

  const BusinessEntityCell = ({value, fields, prefix}) => {
    // Use .startsWith so that paths in the header don't match
    const found = filterFields(fields, (field) =>
      field.path.startsWith(prefix)
    );

    if (!found) {
      return null;
    }

    // check if the node is there and is a table column
    if (found[prefix] && found[prefix].tableColumn === false) {
      return null;
    }

    return (
      <Table.Cell verticalAlign="top">
        <RenderContents initialValues={{consignee: value}} fields={fields}>
          {() => (
            <Content
              path="consignee.partyNames"
              render={({content}) => (
                <div>
                  <PartyNames partyNames={content} />{' '}
                </div>
              )}
            />
          )}
        </RenderContents>
      </Table.Cell>
    );
  };

  BusinessEntityCell.propTypes = {
    value: PropTypes.object,
    fields: PropTypes.object,
    prefix: PropTypes.string,
  };

  const Cell = ({value, config, width, dataSpec, unit}) => {
    // it's not configured, don't render the cell at all
    if (!config) {
      return null;
    }

    // the field is disabled in the table, don't render it
    if (!config.tableColumn) {
      return null;
    }

    switch (config.type) {
      // TODO: date / number types
      case 'dropdown':
        const opt = config.options.find((item) => item.value === value) || {
          text: 'GENERAL_SHIPMENT_NOT_FOUND',
        };
        return (
          <Table.Cell width={width} verticalAlign="center" data-spec={dataSpec}>
            <FormattedMessage id={opt.text} />
          </Table.Cell>
        );

      default:
        return (
          <Table.Cell width={width} verticalAlign="center" data-spec={dataSpec}>
            {value} {unit ? <FormattedMessage id={dynID(unit)} /> : ''}
          </Table.Cell>
        );
    }
  };

  Cell.propTypes = {
    value: PropTypes.any,
    config: PropTypes.object,
    width: PropTypes.number,
    dataSpec: PropTypes.string,
    unit: PropTypes.string,
  };

  return (
    <Table.Body>
      {lineItems.map((row, idx) => (
        <Table.Row key={`tableRow_${idx}`} style={rowFormatter(row)}>
          <Cell
            config={getField('positionNumber')}
            value={row.positionNumber}
            dataSpec={`positionNumber-${idx}`}
          />
          <Cell
            config={getField('references.agreementOrOrderLineNumber')}
            value={row?.references?.agreementOrOrderLineNumber}
            dataSpec={`references.agreementOrOrderLineNumber-${idx}`}
          />

          <Cell
            config={getField('gtin')}
            value={row.gtin}
            dataSpec={`gtin-${idx}`}
          />

          <Cell
            config={getField('customersItemMaterialNumber')}
            value={row.customersItemMaterialNumber}
            dataSpec={`customersItemMaterialNumber-${idx}`}
          />

          <Cell
            config={getField('suppliersItemMaterialNumber')}
            value={row.suppliersItemMaterialNumber}
            dataSpec={`suppliersItemMaterialNumber-${idx}`}
          />

          <Cell
            config={getField('manufacturersArticleNumber')}
            value={row.manufacturersArticleNumber}
            dataSpec={`manufacturersArticleNumber-${idx}`}
          />

          <Cell
            config={getField('itemDescription')}
            value={row.itemDescription}
            dataSpec={`itemDescription-${idx}`}
          />
          <Cell
            config={getField('reasonOfReturn.value')}
            value={row?.reasonOfReturn?.value}
            dataSpec={`reasonOfReturn-${idx}`}
          />
          <SerialNumberCell
            lineItem={row}
            serialNumbersConfig={getField(
              'materialCharacteristics.serialNumbers'
            )}
            dataSpec={`serialNumbers-${idx}`}
          />

          <Cell
            config={getField('invoicedQuantity')}
            value={`${row.invoicedQuantity} ${row.measureUnit}`}
            dataSpec={`invoicedQuantity-${idx}`}
          />

          <Cell
            config={getField('ratioAlternativeQuantity')}
            value={`${row.ratioAlternativeQuantity}`}
            dataSpec={`ratioAlternativeQuantity-${idx}`}
          />

          <Cell
            config={getField('vatrate')}
            value={row.vatrate}
            dataSpec={`vatrate-${idx}`}
            unit="%"
          />
          <Cell
            config={getField('vatamount')}
            value={row.vatamount}
            dataSpec={`vatamount-${idx}`}
          />
          {showCalculation && (
            <CalcBody lineItem={row} currency={currency} idx={idx} />
          )}

          <Table.Cell
            verticalAlign="top"
            textAlign="center"
            data-spec={`actions-${idx}`}>
            <Button
              size="mini"
              color="purple"
              index={idx}
              onClick={onSelect}
              compact
              icon>
              <Icon name="eye" />
            </Button>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  );
};

export default TableBody;

TableBody.propTypes = {
  lineItems: PropTypes.array,
  pageConfig: pageConfigShape,
  showCalculation: PropTypes.bool,
  onSelect: PropTypes.func,
  currency: PropTypes.string,
};
