import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {Dropdown, Form, FormField} from 'semantic-ui-react';
import moment from 'moment';
import {intlShape} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import styled from 'styled-components';
import {
  documentExchangeShape,
  documentMetaShape,
} from '../../../shapes/documentExchange';

const TurnAroundDropDownContainer = styled.div`
  i {
    display: flex !important;
    align-items: center;
    height: 100% !important;
  }
`;
const TurnAroundDropDownTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TurnAroundDropDownTextDate = styled.div`
  margin-left: 20px;
  background: ${ecosioColors.greyShades[4]};
  border-radius: 5px;
  font-size: 12px;
  padding: 4px;
`;
function TurnaroundInputDocumentSelector(props) {
  const {intl, onDocumentSelect, selectedDocument} = props;

  const options = props.sourceDocuments.map(
    ({documentTypeId, uuid, createdAt}) => {
      return {
        key: uuid,

        text: (
          <TurnAroundDropDownTextContainer>
            <div>{`${intl.formatMessage({
              id: documentTypeId,
            })}`}</div>
            <TurnAroundDropDownTextDate>{`${moment(createdAt).format(
              'DD.MM.YYYY, HH:mm:ss'
            )}`}</TurnAroundDropDownTextDate>
          </TurnAroundDropDownTextContainer>
        ),
        value: uuid,
      };
    }
  );

  useEffect(() => {
    const sourceDocs = props?.sourceDocuments || [];

    const foundDoc = sourceDocs.find(
      (doc) => doc.uuid === selectedDocument?.meta?.uuid
    );

    if (!foundDoc) {
      onDocumentSelect(options[0]);
    }
  });

  return (
    <div>
      <Form>
        <FormField>
          <label>
            {intl.formatMessage({id: 'TURNAROUND_MODAL_SOURCE_DOCUMENT'})}
          </label>
          <TurnAroundDropDownContainer>
            <Dropdown
              data-spec="source-document-selector"
              placeholder={intl.formatMessage({
                id: 'TURNAROUND_MODAL_SOURCE_DOCUMENT_PLACEHOLDER',
              })}
              selection
              fluid
              defaultValue={options.length > 1 ? options[0]?.value : null}
              options={options}
              value={selectedDocument?.meta?.uuid}
              onChange={(event, data) => onDocumentSelect(data)}
            />
          </TurnAroundDropDownContainer>
        </FormField>
      </Form>
    </div>
  );
}

TurnaroundInputDocumentSelector.defaultProps = {
  sourceDocuments: [],
};

TurnaroundInputDocumentSelector.propTypes = {
  selectedDocument: documentExchangeShape,
  sourceDocuments: PropTypes.arrayOf(documentMetaShape),
  intl: intlShape,
  onDocumentSelect: PropTypes.func.isRequired,
};

export default injectIntl(TurnaroundInputDocumentSelector);
