/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import {ActionButton} from '../EditCell';
import './SerialNumberCell.css';

const SerialNumberCell = ({lineItem, onSerial}) => {
  const numbers = lineItem?.materialCharacteristics?.serialNumbers || [];
  return (
    <Table.Cell className="snCell">
      <ActionButton
        icon="pencil"
        onAction={() => onSerial(lineItem)}
        spec="edit-sn"
      />
      {numbers.map((number, idx) => (
        <div key={`${number} ${idx}`}>{number}</div>
      ))}
    </Table.Cell>
  );
};

export const materialCharactericticsShape = PropTypes.shape({
  serialNumbers: PropTypes.arrayOf(PropTypes.string),
});

SerialNumberCell.propTypes = {
  onSerial: PropTypes.func.isRequired,
  lineItem: PropTypes.shape({
    materialCharacteristics: materialCharactericticsShape.isRequired,
  }),
};

export default SerialNumberCell;
