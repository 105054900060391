import React from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';

export const TransportType = ({type}) => {
  const types = {
    1: 'SHIPPING_TYPE_SEA',
    4: 'SHIPPING_TYPE_AIR',
    6: 'SHIPPING_TYPE_MIX',
  };
  return <FormattedMessage id={types[type]} />;
};

TransportType.propTypes = {
  type: PropTypes.string,
};

export const MessageType = (messageType) => {
  const availableTypes = [
    'DELFOR',
    'DELJIT',
    'DESADV',
    'RECADV',
    'ORDERS',
    'ORDCHG',
    'ORDRSP',
    'APERAK',
    'GENRAL',
    'CONTRL',
    'INVOIC',
    'RETANN',
    'CREDIT_NOTE',
  ];
  if (availableTypes.indexOf(messageType) < 0) {
    console.error(
      `messageType ${messageType} is not known \n available MessageTypes are: ${availableTypes}`
    );
  }
  const translationKey = `MESSAGETYPE_${[
    ...availableTypes.filter((item) => item === messageType),
  ]}`;

  return <FormattedMessage id={translationKey} defaultMessage={messageType} />;
};
