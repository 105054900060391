export const cpsTransformation = (cps) => {
  //ids have been transformed from string to number
  const newCps = cps.map((cpsItem) => {
    let hierarchicalParentID;
    if (!cpsItem.hierarchicalParentID) {
      hierarchicalParentID = 0;
    } else {
      hierarchicalParentID = parseInt(cpsItem.hierarchicalParentID, 10);
    }
    const hierarchicalID = parseInt(cpsItem.hierarchicalID, 10);
    return {
      ...cpsItem,
      hierarchicalParentID,
      hierarchicalID,
    };
  });

  const tree = (nodes, id = 0, pId = 'hierarchicalParentID') => {
    return nodes
      .filter((node) => {
        return node[pId] === id;
      })
      .map((child) => {
        return {
          ...child,
          cps: tree(nodes, child.hierarchicalID),
        };
      });
  };

  return tree(newCps);
};
