/* eslint-disable react/no-array-index-key */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Input, Form, Submit} from '@ecosio/pathform';
import {Grid, Button} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

/**
 * This is only a first shot.
 * TODO: improvement needed, see: https://gitlab.ecosio.com/code/frontend/-/issues/1589
 */
class LineItemsBatchEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {fields, onSave, onCancel} = this.props;
    return (
      <Form initialValues={{}} onSubmit={onSave} fields={fields}>
        {() => (
          <Grid divided="vertically" container>
            <Grid.Row columns={2}>
              {Object.keys(fields).map((i, idx) => {
                const dateFieldType = fields[i].input.type === 'date';

                return (
                  <Grid.Column key={idx}>
                    <Input
                      key={idx}
                      path={i}
                      name={i}
                      {...(dateFieldType ? {stripTimezone: true} : '')}
                    />
                  </Grid.Column>
                );
              })}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Submit
                  disabled={() => {
                    return false;
                  }}
                  positive
                  data-spec="lineItemBatchEditor-save">
                  <FormattedMessage id="GENERAL_SAVE_AND_CONFIRM" />
                </Submit>
                <Button
                  type="button"
                  color="red"
                  onClick={onCancel}
                  data-spec="lineItemBatchEditor-cancel">
                  <FormattedMessage id="GENERAL_CANCEL" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Form>
    );
  }
}

LineItemsBatchEditor.propTypes = {
  fields: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default LineItemsBatchEditor;
