import {injectIntl} from 'react-intl';
import {intlShape, toast} from '@ecosio/components';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {scenarioShape} from '../../shapes/scenarios';
import {HelmetTags} from '../../helpers/components/HelmetTags';
import {loadTheme} from '../../helpers/utils';
import {STANDARD_TOAST_DURATION} from '../../helpers/constants';
import withScenario from '../hoc/withScenario';
import DashboardTasks from './tasks/DashboardTasks';
import DashboardHeader from './DashboardHeader';

export const DashboardContainer = styled.div`
  margin-left: 30px;
  margin-top: 20px;
`;

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerStats: null,
      tasksStats: null,
      loadingOverview: true,
    };
  }

  componentDidMount() {
    this.fetchHeaderAndTableData();
  }

  fetchHeaderAndTableData = async () => {
    const {scenarioUuid, intl} = this.props;
    axios
      .get(`/api/dashboard/${scenarioUuid}/overview`)
      .then((response) => {
        this.setState({
          headerStats: response?.data?.headerStats,
          tasksStats: response?.data?.tasksStats,
          loadingOverview: false,
        });
      })
      .catch(() => {
        toast({
          title: intl.formatMessage({
            id: 'DASHBOARD_DATA_LOADING_FAILED',
          }),
          description: intl.formatMessage({
            id: 'DASHBOARD_DATA_LOADING_FAILED_DESCRIPTION',
          }),
          type: 'error',
          time: STANDARD_TOAST_DURATION,
        });
      });
  };

  render() {
    const {scenario, userData, intl} = this.props;
    const {headerStats, tasksStats, loadingOverview} = this.state;
    const currentTheme = loadTheme(userData.theme);
    return (
      <DashboardContainer data-spec="dashboard-container">
        <HelmetTags scenario={scenario} />
        <DashboardHeader
          intl={intl}
          currentTheme={currentTheme}
          firstName={userData.firstName}
          lastName={userData.lastName}
          stats={headerStats}
          isLoading={loadingOverview}></DashboardHeader>
        {scenario.dashboardPageConfiguration?.tasksConfiguration && (
          <DashboardTasks
            renderConfig={
              scenario.dashboardPageConfiguration?.tasksConfiguration
            }
            intl={intl}
            currentTheme={currentTheme}
            stats={tasksStats}
            isLoading={loadingOverview}
          />
        )}
      </DashboardContainer>
    );
  }
}
DashboardComponent.propTypes = {
  scenario: scenarioShape.isRequired,
  intl: intlShape.isRequired,
  userData: PropTypes.object.isRequired,
};

export default injectIntl(withScenario(DashboardComponent));
