import {isEqual} from 'lodash';
import jexl from 'jexl';
import {
  DESADV_NULL_POSITION,
  COOP_BUSINESSCASE_EXPRESSION_X2,
} from '../../../../../../helpers/constants';
import {generateDesadvPackageNumbers} from '../../../Utils/CreateUtils';

export const fetchTotalSsccNumbers = async (cpsPackageCountArray = []) => {
  const totalPackages = await cpsPackageCountArray.reduce(async (acc, curr) => {
    if (curr?.packages) {
      return (await acc) + curr?.packages;
    }

    return acc;
  }, 0);

  if (!totalPackages) {
    return undefined;
  }

  return await generateDesadvPackageNumbers('SSCC_GENERATE', totalPackages);
};

/**
 * Transforms data before opening the desadv modal.
 *
 * Data transformation for desadv modal.
 * Used for  both desadvs:
 * - simpleStructured desadv
 * - simpleStructured with complex packaging desadv
 */
export const duplicateCps = async (
  prevStateDeliveryLineItems,
  deliveryLineItems,
  cpsConfigs,
  document
  // eslint-disable-next-line radar/cognitive-complexity
) => {
  if (!Array.isArray(deliveryLineItems) || !deliveryLineItems.length) {
    console.error(
      'duplicating structured desadv cps failed, deliveryLineItems are empty or undefined'
    );
    return [];
  }

  if (!Array.isArray(cpsConfigs) || !cpsConfigs.length) {
    console.error(
      'duplicating structured desadv cps failed, cpsConfig array is empty or undefined'
    );
    return [];
  }

  const packageTypeCustomer =
    cpsConfigs[0]?.packagingInformation?.packageTypeCustomer;
  const shippingMarkType =
    cpsConfigs[0]?.packagingInformation?.shippingMarkType;

  let cpsPackageCountArray = [];
  for (let index = 0; index < deliveryLineItems.length; index++) {
    if (!isEqual(deliveryLineItems[index], prevStateDeliveryLineItems[index])) {
      let packages = Math.ceil(
        deliveryLineItems[index].dispatchQuantity.value /
          deliveryLineItems[index].quantityPerPack
      );

      if (packages === Infinity) {
        packages = undefined;
      }
      const ssccObject = {
        packages: packages,
        lineIndex: index,
      };
      cpsPackageCountArray = [...cpsPackageCountArray, ssccObject];
    }
  }

  const isSimpleComplexDesadvWithSideBar = jexl.evalSync(
    COOP_BUSINESSCASE_EXPRESSION_X2,
    document
  );

  const totalSsccNumbers = !isSimpleComplexDesadvWithSideBar
    ? await fetchTotalSsccNumbers(cpsPackageCountArray)
    : {};

  return Promise.all(
    deliveryLineItems.map(async (lineItem, lineItemindex) => {
      if (!isEqual(lineItem, prevStateDeliveryLineItems[lineItemindex])) {
        const cpsesSccObject = cpsPackageCountArray.find(
          (cpsesScc) => cpsesScc?.lineIndex === lineItemindex
        );
        let packages = cpsesSccObject?.packages;

        if (packages === Infinity) {
          packages = undefined;
        }

        let ssccnumbers = undefined;

        if (typeof packages !== 'undefined') {
          ssccnumbers = {
            packagingNumber: totalSsccNumbers?.packagingNumber?.length
              ? totalSsccNumbers?.packagingNumber?.splice(0, packages)
              : [],
          };
        }
        const prevCpses = deliveryLineItems[lineItemindex]?.cpses;

        // Null positions still needs 1 package, otherwise no cps is generated and the null position is not send to backend as outbound
        if (packages === 0) {
          packages = 1;
          ssccnumbers = {
            packagingNumber: [
              {
                value: DESADV_NULL_POSITION,
              },
            ],
          };
        }

        let totalDispatchQuantity = lineItem.dispatchQuantity.value;

        const quantityPerPack = lineItem.quantityPerPack;
        return {
          ...lineItem,
          cpses: Array(packages)
            .fill()
            .map((value, index) => {
              totalDispatchQuantity = totalDispatchQuantity - quantityPerPack;
              const quantityPerPackValue =
                totalDispatchQuantity >= 0
                  ? quantityPerPack
                  : lineItem.dispatchQuantity.value % quantityPerPack;
              return {
                //temporary value, this value will be automatically incremented before sending the desadv to hub
                hierarchicalID: '2',
                hierarchicalParentID: '1',
                packagingInformations:
                  prevCpses &&
                  prevCpses[index] &&
                  prevCpses[index]?.packagingInformations[0]?.identifications[0]
                    ?.identityNumber !== DESADV_NULL_POSITION &&
                  ssccnumbers?.packagingNumber[index]?.value !==
                    DESADV_NULL_POSITION
                    ? prevCpses[index]?.packagingInformations.map((info) => {
                        return {
                          ...info,
                          quantityPerPack: quantityPerPackValue,
                        };
                      })
                    : [
                        {
                          numberOfPackages: '1',
                          packageTypeCustomer: packageTypeCustomer,
                          shippingMarkType: shippingMarkType,
                          quantityPerPack: quantityPerPackValue,
                          identifications: [
                            {
                              identityNumber:
                                ssccnumbers?.packagingNumber[index]?.value,
                            },
                          ],
                        },
                      ],
              };
            }),
        };
      }
      return {
        ...lineItem,
      };
    })
  );
};
