import PropTypes from 'prop-types';
import {DEFAULT_LAYOUT} from './envelopes';

const INVOIC_TEMPLATE = 'InvoiceTemplate';
const ORDERS_TEMPLATE = 'OrdersTemplate';
const DELFOR_TEMPLATE = 'DelforTemplate';
const DESADV_SIMPLE_TEMPLATE = 'DesadvSimpleTemplate';
const DESADV_SIMPLE_TEMPLATEV2 = 'DesadvSimpleTemplateV2';
const DESADV_COMPLEX_TEMPLATE = 'DesadvComplexTemplate';

export const DOCUMENT_SEND_ACTIONS = {
  SEND: 'SEND',
  SEND_AND_ARCHIVE: 'SEND_AND_ARCHIVE',
};

export const CALCULATION_TYPES = {
  NONE: 'NO_CALCULATION',
  DEFAULT: 'DEFAULT_CALCULATION',
  NO_LINEITEMAMOUNT_AND_TOTAL_AMOUNT_CALCULATION:
    'NO_LINEITEMAMOUNT_AND_TOTALAMOUNT_CALCULATION',
  NO_LINEITEMAMOUNT_AND_TOTALAMOUNT_CALCULATION:
    'NO_LINEITEMAMOUNT_AND_TOTALAMOUNT_CALCULATION',
};

// calculation types which do actually need line item recalculation
export const CALCULATION_ENABLED_TYPES = [CALCULATION_TYPES.DEFAULT];

const lineItemConfigurationShape = PropTypes.shape({
  lineItemRendering: PropTypes.oneOf(['INLINE', 'MODAL']),
  amountCalculation: PropTypes.oneOf([
    CALCULATION_TYPES.NONE,
    CALCULATION_TYPES.DEFAULT,
    CALCULATION_TYPES.NO_LINEITEMAMOUNT_AND_TOTALAMOUNT_CALCULATION,
  ]),
  allowAddingCharges: PropTypes.bool,
  allowRemovingCharges: PropTypes.bool,
  allowAddingAllowances: PropTypes.bool,
  allowRemovingAllowances: PropTypes.bool,
  allowSplittingDeliveryDates: PropTypes.bool,
  allowAddLineItem: PropTypes.bool,
  allowDeleteLineItem: PropTypes.bool,
  lineItemsHaveScheduleLines: PropTypes.bool,
});

const headerConfigShape = PropTypes.shape({
  allowAddingAllowances: PropTypes.bool,
  allowRemovingAllowances: PropTypes.bool,
  allowAddingCharges: PropTypes.bool,
  allowRemovingCharges: PropTypes.bool,
  allowRemovingChargesAndAllowances: PropTypes.bool,
});

export const sendActionShape = PropTypes.shape({
  type: PropTypes.oneOf([
    DOCUMENT_SEND_ACTIONS.SEND,
    DOCUMENT_SEND_ACTIONS.SEND_AND_ARCHIVE,
  ]),
  translationKey: PropTypes.string.isRequired,
  confirmKey: PropTypes.string.isRequired,
  confirmContentKey: PropTypes.string.isRequired,
});

const pageConfigShape = PropTypes.shape({
  documentTypeId: PropTypes.string.isRequired,
  correlatorXPath: PropTypes.string,
  headerConfiguration: headerConfigShape,
  vatSelectorConfig: PropTypes.object,
  detailLayout: PropTypes.oneOf([
    INVOIC_TEMPLATE,
    ORDERS_TEMPLATE,
    DELFOR_TEMPLATE,
    DESADV_SIMPLE_TEMPLATE,
    DESADV_SIMPLE_TEMPLATEV2,
    DESADV_COMPLEX_TEMPLATE,
  ]),
  lineItemConfiguration: lineItemConfigurationShape.isRequired,
  // TODO: map shape?
  formFields: PropTypes.object,
  allowAddLineItem: PropTypes.bool,
  allowDeleteLineItem: PropTypes.bool,
  sendActionTypes: PropTypes.arrayOf(sendActionShape).isRequired,
});

const mergerShape = PropTypes.shape({
  documentMerger: PropTypes.string.isRequired,
  documentTypesToMerge: PropTypes.arrayOf(PropTypes.string),
});

export const documentDropdownShape = PropTypes.shape({
  sortOrder: PropTypes.oneOf(['DESC', 'ASC']),
  mergedDocumentPosition: PropTypes.oneOf(['TOP', 'BOTTOM']),
});

const turnaroundShape = PropTypes.shape({
  inboundDocType: PropTypes.string.isRequired,
  outboundDocType: PropTypes.string.isRequired,
});

const scenarioShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  // maps to ScenarioDetailLayout enum in java
  detailLayout: PropTypes.oneOf([DEFAULT_LAYOUT]),
  descriptionTranslationKey: PropTypes.string,
  correlatorTranslation: PropTypes.string,
  nameTranslationKey: PropTypes.string.isRequired,
  outboundWebDocTypes: PropTypes.arrayOf(pageConfigShape),
  inboundWebDocTypes: PropTypes.arrayOf(pageConfigShape),
  documentMerger: mergerShape,
  documentDropdown: documentDropdownShape,
  mappings: PropTypes.arrayOf(turnaroundShape),
});

export {
  turnaroundShape,
  scenarioShape,
  pageConfigShape,
  mergerShape,
  lineItemConfigurationShape,
};
