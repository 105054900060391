import React from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';

class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {values: props.values};
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const {values, onSubmit} = this.props;
    if (!isEqual(this.state.values, values)) {
      this.setState({values});
      this.promise = onSubmit(values);
      await this.promise;
      delete this.promise;
    }
  };

  render() {
    return <></>;
  }
}
AutoSave.defaultProps = {};

AutoSave.propTypes = {
  values: PropTypes.any,
  onSubmit: PropTypes.func,
  debounce: PropTypes.number,
};

export default AutoSave;
