import axios from 'axios';
const BASE_URL = '/';

export const BASE_DOCUMENT_DRAFTS = `${BASE_URL}api/documentDrafts`;

const getDraftsForEnvelopeAndDocType = (
  scenarioUuid,
  envelopeUuid,
  docType
) => {
  const newParams = Object.assign(
    {},
    {
      envelopeUuid: envelopeUuid,
      documentTypeId: docType,
    }
  );

  const config = {params: newParams};

  return axios.get(
    `${BASE_DOCUMENT_DRAFTS}/${scenarioUuid}/drafts/list`,
    config
  );
};

const deleteDraftWithUuid = (draftUuid) => {
  const newParams = Object.assign(
    {},
    {
      draftUuid: draftUuid,
    }
  );

  const config = {params: newParams};

  return axios.delete(`${BASE_DOCUMENT_DRAFTS}/delete`, config);
};

const baseSaveDocumentAsDraft = (
  scenarioUuid,
  envelopeUuid,
  documentExchange,
  documentNumber,
  sendingDelay,
  scheduledSendingDate,
  draftUuid,
  scenarioChecksum
) => {
  const pathParam = `?browserScenarioConfigChecksum=${scenarioChecksum}&browserScenarioUuid=${scenarioUuid}`;
  const data = Object.assign(
    {},
    {
      documentExchange: documentExchange,
      correlatorId: documentNumber,
      scheduledSendingDate: scheduledSendingDate,
      sendingDelay: sendingDelay,
      draftUuid: draftUuid,
    }
  );

  return axios.post(
    `${BASE_DOCUMENT_DRAFTS}/${scenarioUuid}/${envelopeUuid}/saveDraft${pathParam}`,
    data
  );
};

const saveDocumentDraftWithSendingDelay = (
  scenarioUuid,
  envelopeUuid,
  documentExchange,
  documentNumber,
  sendingDelay,
  scenarioChecksum,
  draftUuid = null
) => {
  return baseSaveDocumentAsDraft(
    scenarioUuid,
    envelopeUuid,
    documentExchange,
    documentNumber,
    sendingDelay,
    null,
    draftUuid,
    scenarioChecksum
  );
};

const saveDocumentDraftWithScheduledDate = (
  scenarioUuid,
  envelopeUuid,
  documentExchange,
  documentNumber,
  scheduledSendingDate,
  scenarioChecksum,
  draftUuid = null
) => {
  return baseSaveDocumentAsDraft(
    scenarioUuid,
    envelopeUuid,
    documentExchange,
    documentNumber,
    null,
    scheduledSendingDate,
    draftUuid,
    scenarioChecksum
  );
};

const editDraftWithUuid = (scenarioUuid, draftUuid) => {
  return axios.get(
    `${BASE_DOCUMENT_DRAFTS}/${scenarioUuid}/fetchDocumentExchangeForDraft/${draftUuid}`
  );
};

export {
  getDraftsForEnvelopeAndDocType,
  deleteDraftWithUuid,
  saveDocumentDraftWithSendingDelay,
  saveDocumentDraftWithScheduledDate,
  editDraftWithUuid,
};
