import createDecorator from 'final-form-calculate';
import InvoiceCalculator from '../../../../../calculations/InvoiceCalculator';

const updates = (value, name, allValues) => {
  const Invoice = new InvoiceCalculator();
  return Invoice.setDocument(allValues).getDocument();
};

const documentAocDecorator = createDecorator(
  {
    field: /allowancesAndCharges\[\d]\.percentageOfAllowanceOrCharge/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.allowanceChargeAmount/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.allowanceChargeQualifier/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.vatrate/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.vatamount/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.allowanceChargeRelativeCalculation/,
    updates,
  }
);

export default documentAocDecorator;
