import React from 'react';
import DynamicTemplate from '../../DynamicTemplate';
import DesadvSimpleTemplate from '../Desadv/V1/SimpleDesadv/Create/DesadvForm';
import OrdersTemplate from '../Order/Create/OrderForm';
import {pageConfigShape} from '../../../shapes/scenarios';
import InvoiceTemplate from '../Invoice/Create/InvoiceTemplate';
import DesadvComplexTemplate from '../Desadv/V1/ComplexDesadv/Create/DesadvComplexTemplate';
import DesadvSimpleTemplateV2 from '../Desadv/V2/DesadvSimpleV2/Create/DesadvSimpleTemplateV2';
import DesadvComplexTemplateV1_1 from '../Desadv/V2/DesadvComplexV1_1/Create/DesadvComplexCreateTemplateV1_1';

const templates = {
  OrdersTemplate,
  DesadvSimpleTemplate,
  DesadvSimpleTemplateV2,
  DesadvComplexTemplate,
  DesadvComplexTemplateV1_1,
  InvoiceTemplate,
};

/**
 * This is the current default outer layout for creating documents.
 * Currently it doesn't really do anything special, but who knows what
 * might come...
 */
const CreateDocumentLayout = ({pageConfig, ...rest}) => {
  if (!pageConfig) {
    throw new Error('Unable to load pageConfig for document form');
  }

  return (
    <div className="_si_doc_create">
      <DynamicTemplate
        pageConfig={pageConfig}
        componentName={pageConfig.detailLayout}
        options={{templates}}
        {...rest}
      />
    </div>
  );
};

CreateDocumentLayout.propTypes = {
  pageConfig: pageConfigShape,
};

export const layoutOptions = {
  templates: {
    DEFAULT: CreateDocumentLayout,
  },
};
