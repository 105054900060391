export const submitForm = (formId) => {
  const formEl = document.getElementById(formId);
  let event;
  // IE 11 workaround: does not have an Event constructor
  // see: https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11
  if (typeof Event === 'function') {
    // https://github.com/final-form/react-final-form/issues/878#issuecomment-799050543
    event = new Event('submit', {cancelable: true, bubbles: true});
  } else {
    event = document.createEvent('Event');
    event.initEvent('submit', false, true);
  }

  if (formEl) {
    formEl.dispatchEvent(event);
  } else {
    console.warn(`Form with id ${formId} not found`);
  }
};
