/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {get, includes} from 'lodash';
import styled from 'styled-components';
import {TabbedItem} from '../../TabbedItem';

const HeaderFreeTextViewElements = ({
  fields,
  freeTextsPath,
  values,
  inMemoryValidation,
}) => {
  const intl = useIntl();

  //Will show all header freetexts
  if (!freeTextsPath || !fields || !values) {
    return null;
  }

  const freeTextConfig = get(fields, 'header.freeTexts');
  const freeTextValues = get(values, 'header.freeTexts');

  if (!freeTextConfig || !freeTextValues) {
    return null;
  }

  const freeTextsArray = Object.keys(freeTextValues).map(
    (path) => freeTextValues[path]
  );

  const toRenderResult = [];
  const additionalInformationRenderResult = [];
  freeTextsArray.forEach((freeText) => {
    const subPath = `header.freeTexts.${freeText?.textFunction}`;

    const filteredHeaderFreeTextsConfigs = Object.keys(fields)
      .filter((path) => includes(path, subPath))
      .map((path) => fields[path]);

    if (filteredHeaderFreeTextsConfigs.length) {
      filteredHeaderFreeTextsConfigs.forEach((config) => {
        const translationKey = config?.input?.label || freeText?.textFunction;
        const descriptionTranslationKeyExists = !!intl.messages[translationKey];

        if (descriptionTranslationKeyExists) {
          toRenderResult.push(
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path={config?.path}
            />
          );
        } else {
          freeText?.text &&
            additionalInformationRenderResult.push(freeText?.text);
        }
      });
    } else {
      freeText?.text && additionalInformationRenderResult.push(freeText?.text);
    }
  });

  toRenderResult.push(
    <AdditionalFreeTextInformationList
      list={additionalInformationRenderResult}
      translationKey={freeTextConfig?.input?.label}
    />
  );

  return toRenderResult;
};

const StyledAdditionalFreeTextContainer = styled.div`
  margin-top: 20px;
`;

const StyledAdditionalFreeTextUnorderedList = styled.ul`
  margin-left: 20px;
  overflow-wrap: break-word;
`;

const AdditionalFreeTextInformationList = (props) => {
  const intl = useIntl();
  const list = props?.list || [];
  const traslationkey = props?.translationKey || 'GENERAL_FREETEXT_HEADER';
  if (list.length) {
    return (
      <StyledAdditionalFreeTextContainer>
        <h5>{intl.formatMessage({id: traslationkey})}</h5>
        <StyledAdditionalFreeTextUnorderedList>
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </StyledAdditionalFreeTextUnorderedList>
      </StyledAdditionalFreeTextContainer>
    );
  }
  return null;
};

AdditionalFreeTextInformationList.propTypes = {
  list: PropTypes.array,
  translationKey: PropTypes.string,
};

export default HeaderFreeTextViewElements;
