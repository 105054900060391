/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Header, List, Message, Segment} from 'semantic-ui-react';
import {get} from 'lodash';
import {
  FormattedMessage as Msg,
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import extractItemConfig from '../../../../Utils/helpers';
import {HeaderMetaContext} from '../../../../../Order/View/Header/DocumentHeadEditForm';

const ShowAllField = ({value, field}) => {
  return value ? (
    <div style={{display: 'flex', marginRight: '15px'}}>
      <strong style={{marginRight: '5px'}}>
        <FormattedMessage id={dynID(field)} />:
      </strong>
      {value}
    </div>
  ) : null;
};

ShowAllField.propTypes = {
  field: PropTypes.string,
  value: PropTypes.any,
};

const ShowAll = ({data, config, onSend, sending, sendingError}) => {
  const listLineItems = (lineitems, config) => {
    // TODO use from config and filter by isLineItemFlag
    const keys = [
      'props.intrastatNumber',
      'props.countryOfOrigin.countryCode',
      'props.orderedQuantity',
      'props.measureUnitQualifier',
      'props.batchNumber',
    ];
    return lineitems.map((li, segmentkey) => {
      const gtin = get(li, 'props.gtin');
      const manufacturersArticleNumber = get(
        li,
        'props.manufacturersArticleNumber'
      );
      const itemDescription = get(li, 'props.itemDescription');
      const customerMatNr = get(li, 'props.customersItemMaterialNumber');
      const supplrMatNr = get(li, 'props.suppliersItemMaterialNumber');
      return (
        <Segment key={`showall_${segmentkey}_segment`}>
          <Header className="header">
            <FormattedMessage id="LE_GTIN" /> {gtin}
            <br />
            <FormattedMessage id="LE_MANUFACTURERS_ARTICLENUMBER" />
            {manufacturersArticleNumber}
            <br />
            <FormattedMessage id="DESADV_MAT_NR_HDR" /> {customerMatNr} /{' '}
            {supplrMatNr}
            <br />
            <span className="header-sub">{itemDescription}</span>
          </Header>
          <div style={{display: 'flex', justifyContent: 'flex-start'}}>
            {keys.map((key, innerkey) => {
              const fieldValue = get(li, key);
              return config.formFields[key] ? (
                <ShowAllField
                  key={`showall_${innerkey}_innersegment`}
                  value={fieldValue}
                  field={config.formFields[key].input.label}
                />
              ) : null;
            })}
          </div>
          <div style={{marginTop: '15px'}}>
            {li.props.values &&
            Array.isArray(li.props.values) &&
            li.props.values.length > 0 ? (
              <Fragment>
                <Header as="h5">
                  <Msg id="GENERAL_DESADV_SERIAL_NUMBER" />
                </Header>
                <List>
                  {li.props.values.map((i, valueskey) => (
                    <List.Item key={`values_${valueskey}`}>
                      {i.serialNumbers}
                    </List.Item>
                  ))}
                </List>
              </Fragment>
            ) : null}
          </div>
        </Segment>
      );
    });
  };

  const rec = (cpsitem) => {
    const settings = extractItemConfig(config, cpsitem.configID);
    return (
      <Segment>
        <h2>
          <FormattedMessage id={dynID(settings?.label)} />
        </h2>
        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
          {settings.fields.map((fieldpath, fieldkey) => {
            const fieldValue = get(cpsitem, fieldpath);
            return (
              <ShowAllField
                key={`field_${fieldkey}`}
                value={fieldValue}
                field={config.formFields[fieldpath].input.label}
              />
            );
          })}
        </div>
        {cpsitem.cps.length ? cpsitem.cps.map((i) => rec(i)) : null}
        {cpsitem.lineitems.length
          ? listLineItems(cpsitem.lineitems, config)
          : null}
      </Segment>
    );
  };

  return (
    <>
      {sendingError && (
        <Message error>
          <FormattedMessage id="DESADV_GENERAL_SEND_ERROR" />
        </Message>
      )}

      {data.map((item, areakey) => {
        const settings = extractItemConfig(config, item.configID);
        return (
          <Segment key={`recursive_${areakey}`}>
            <h2>
              <FormattedMessage id={dynID(settings?.label)} />
            </h2>
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
              {settings.fields.map((fieldpath) => {
                const fieldValue = get(item, fieldpath);

                return (
                  <ShowAllField
                    key={config.formFields[fieldpath].input.label}
                    value={fieldValue}
                    field={config.formFields[fieldpath].input.label}
                  />
                );
              })}
            </div>
            {item.cps.length ? item.cps.map((cpsitem) => rec(cpsitem)) : null}
            {item.lineitems.length
              ? listLineItems(item.lineitems, config)
              : null}
          </Segment>
        );
      })}
      <div style={{textAlign: 'center'}}>
        <HeaderMetaContext.Consumer>
          {(ctx) => (
            <Button
              data-spec="complex-desadv-submit-button"
              onClick={() => {
                onSend(ctx.headerValid);
              }}
              color={ctx.headerValid ? 'green' : 'red'}
              loading={sending}
              disabled={sending}>
              <FormattedMessage id="DESADV_SUBMIT_BUTTON" />
            </Button>
          )}
        </HeaderMetaContext.Consumer>
      </div>
    </>
  );
};

ShowAll.propTypes = {
  config: PropTypes.any,
  data: PropTypes.any,
  onSend: PropTypes.func,
  sending: PropTypes.bool,
  sendingError: PropTypes.bool,
};

export default injectIntl(ShowAll);
