/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {Grid, Button, Header, Message} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {Input, Form, FormGroup, FilterArray, Submit} from '@ecosio/pathform';
import {Field} from 'react-final-form';
import {connect} from 'react-redux';
import {CALCULATION_TYPES, pageConfigShape} from '../../../../shapes/scenarios';
import FreeTextComponent from '../../FreeTextComponent';
import {requiresLineItemConfirmation} from '../../../../util/scenarioUtil';
import BusinessEntityComponent from '../../BusinessEntityComponent';
import {ECOSIO_THEME} from '../../../../helpers/constants';
import {loadTheme} from '../../../../helpers/utils';
import {
  defaultLineItemCalculator,
  sumOnlyLineItemCalculator,
} from './decorators/lineItemDecorators';
import OrderScheduleLines from './OrderScheduleLines';
import SimpleOrderLineItems from './SimpleOrderLineItems';
import AllowancesAndCharges from './AllowancesAndCharges';
import './LineItemEditor.css';

// TODO: Quickfix with paul - See Line 162 for "currentItemPriceCalculationNet"
const ValidationError = () => (
  <Grid.Row>
    <Grid.Column>
      <Message
        style={{display: 'block'}}
        error
        negative
        content={<FormattedMessage id="LE_CONFIRMATION_ERROR_MESSAGE_MODAL" />}
      />
    </Grid.Column>
  </Grid.Row>
);

class OrderLineItemEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {showValidationError: false};
  }

  internalSave = (values) => {
    const requiresConfirmation = requiresLineItemConfirmation(
      this.props.pageConfig.formFields
    );

    if (requiresConfirmation && isEmpty(values.lineItemActionRequest)) {
      this.setState({showValidationError: true});
      return;
    }
    this.props.onSave(values);
  };

  render() {
    const {
      lineItem,
      currency,
      fields,
      onCancel,
      pageConfig: {lineItemConfiguration},
      theme,
    } = this.props;
    const validationError = this.state.showValidationError ? (
      <ValidationError />
    ) : null;

    const noPriceCalc =
      lineItemConfiguration.amountCalculation === CALCULATION_TYPES.NONE;

    const noLineItemAndTotalCalc =
      lineItemConfiguration.amountCalculation ===
      CALCULATION_TYPES.NO_LINEITEMAMOUNT_AND_TOTAL_AMOUNT_CALCULATION;

    const hideAoC =
      (!lineItemConfiguration.allowAddingCharges &&
        !lineItemConfiguration.allowRemovingCharges &&
        !lineItemConfiguration.allowAddingAllowances &&
        !lineItemConfiguration.allowRemovingAllowances) ||
      noPriceCalc;

    const decorator = noPriceCalc
      ? sumOnlyLineItemCalculator
      : defaultLineItemCalculator;

    const currencyTranslation = currency
      ? {
          inputLabel: {
            basic: true,
            content: <FormattedMessage id={currency} />,
          },
          labelPosition: 'right',
        }
      : {};

    const alternativeQuantityUnit = lineItem?.alternativeQuantity?.unit;
    const alternativeQuantityUnitTranslation = alternativeQuantityUnit
      ? {
          inputLabel: {
            basic: true,
            content: <FormattedMessage id={alternativeQuantityUnit} />,
          },
        }
      : {};

    const currentTheme = loadTheme(theme);

    return (
      <div className="readableOpacity">
        <Form
          initialValues={lineItem}
          onSubmit={this.internalSave}
          fields={fields}
          decorators={[decorator]}
          themeSpecificInfo={{
            theme: theme,
            primaryColor: currentTheme?.primaryColor,
            secondaryColor: currentTheme?.secondaryColor,
          }}>
          {(ctx) => (
            <Grid divided="vertically" container className="_si_li_editor">
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Grid divided>
                    <Grid.Row>
                      <Grid.Column width={8} className="_si_editor_left">
                        <Grid.Row>
                          <Grid.Column>
                            <FormGroup>
                              <Input
                                size="mini"
                                path="itemDescription"
                                name="itemDescription"
                                width={10}
                              />
                              <Input
                                size="mini"
                                path="itemCategory"
                                name="itemCategory"
                                width={6}
                              />
                              <FilterArray name="references" path="references">
                                {(filtered) => {
                                  return filtered.map((name, idx) => {
                                    return (
                                      <div key={`referencelist-number-${idx}`}>
                                        <Input
                                          size="mini"
                                          path="references.orderReference.referenceNumber"
                                          name={`${name}.orderReference.referenceNumber`}
                                        />
                                      </div>
                                    );
                                  });
                                }}
                              </FilterArray>
                            </FormGroup>
                            <FormGroup>
                              <Input size="mini" path="gtin" name="gtin" />
                              <Input
                                size="mini"
                                path="gtinpriceItem"
                                name="gtinpriceItem"
                              />
                              <Input
                                size="mini"
                                path="manufacturersArticleNumber"
                                name="manufacturersArticleNumber"
                              />
                            </FormGroup>
                            <FormGroup widths="equal">
                              <Input
                                size="mini"
                                path="customersItemMaterialNumber"
                                name="customersItemMaterialNumber"
                              />
                              <Input
                                size="mini"
                                path="suppliersItemMaterialNumber"
                                name="suppliersItemMaterialNumber"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                size="mini"
                                path="unitPriceBasis"
                                name="unitPriceBasis"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                name="countryOfOrigin.countryName"
                                path="countryOfOrigin.countryName"
                                search
                                displayTextWithKey
                                size="mini"
                              />
                            </FormGroup>
                            <FormGroup widths="equal">
                              <Input
                                size="mini"
                                path="packagingType.value"
                                name="packagingType.value"
                              />
                              <Input
                                size="mini"
                                path="secondaryPackagingType.value"
                                name="secondaryPackagingType.value"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                size="mini"
                                path="alternativeQuantity.value"
                                name="alternativeQuantity.value"
                                {...alternativeQuantityUnitTranslation}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Input
                                size="mini"
                                path="caliber.value"
                                name="caliber.value"
                              />
                            </FormGroup>
                            <FormGroup widths="equal">
                              <Input
                                size="mini"
                                path="references[0].contractReference.referenceNumber"
                                name="references[0].contractReference.referenceNumber"
                              />
                              <Input
                                size="mini"
                                path="references[0].contractReference.lineNumber"
                                name="references[0].contractReference.lineNumber"
                              />
                            </FormGroup>
                            <FormGroup widths="equal">
                              <Input
                                size="mini"
                                path="references[0].externalOrderReference.referenceNumber"
                                name="references[0].externalOrderReference.referenceNumber"
                              />
                              <Input
                                size="mini"
                                path="references[0].externalOrderReference.lineNumber"
                                name="references[0].externalOrderReference.lineNumber"
                              />
                            </FormGroup>
                            <FormGroup widths="equal">
                              <Input
                                size="mini"
                                path="promotion.referenceNumber"
                                name="promotion.referenceNumber"
                              />
                              <Input
                                size="mini"
                                path="promotion.referenceQualifier"
                                name="promotion.referenceQualifier"
                              />
                            </FormGroup>
                            <FormGroup widths="equal">
                              <Input
                                size="mini"
                                stripTimezone
                                path="promotion.date.dateTime"
                                name="promotion.date.dateTime"
                              />
                              <Input
                                size="mini"
                                stripTimezone
                                path="promotion.endDate.dateTime"
                                name="promotion.endDate.dateTime"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                name="batchNumber"
                                path="batchNumber"
                                size="mini"
                                inputLabel={{
                                  basic: true,
                                }}
                              />
                              <Input
                                name="lotNumber"
                                path="lotNumber"
                                size="mini"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                size="mini"
                                stripTimezone
                                path="bestBeforeDate.dateTime"
                                name="bestBeforeDate.dateTime"
                              />
                            </FormGroup>
                            <FormGroup widths="equal">
                              <Input
                                size="mini"
                                path="lineItemActionRequest"
                                name="lineItemActionRequest"
                                type="dropdown"
                              />
                            </FormGroup>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <FreeTextComponent
                              path="freeTexts"
                              fields={fields}
                              object={lineItem}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <BusinessEntityComponent
                              path="consignee"
                              object={lineItem}
                              fields={fields}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column width={8} className="_si_editor_right">
                        <Grid divided={hideAoC ? undefined : 'divided'}>
                          <Grid.Row>
                            <Grid.Column>
                              {lineItemConfiguration.lineItemsHaveScheduleLines ? (
                                <OrderScheduleLines
                                  lineItemConfig={lineItemConfiguration}
                                  push={ctx.form.mutators.push}
                                  lineItem={lineItem}
                                />
                              ) : (
                                <SimpleOrderLineItems
                                  lineItemConfig={lineItemConfiguration}
                                  push={ctx.form.mutators.push}
                                  lineItem={lineItem}
                                />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <FormGroup widths="equal">
                                <Input
                                  size="mini"
                                  path="packagingDetails.packagingCapacity"
                                  name="packagingDetails.packagingCapacity"
                                />
                                <Input
                                  size="mini"
                                  path="packagingDetails.numberOfPackages"
                                  name="packagingDetails.numberOfPackages"
                                />
                              </FormGroup>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <FormGroup widths="equal">
                                <Input
                                  size="mini"
                                  path="freeTexts[0].text"
                                  name="freeTexts[0].text"
                                />
                              </FormGroup>
                            </Grid.Column>
                          </Grid.Row>
                          {noPriceCalc ? null : (
                            <Grid.Row>
                              <Grid.Column>
                                <Grid className="_si_price_info">
                                  <Grid.Row columns={3}>
                                    <Grid.Column width={16}>
                                      <Header as="h3" textAlign="left">
                                        <FormattedMessage id="GENERAL_PRICE_INFORMATION" />
                                      </Header>
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row columns={3}>
                                    <Grid.Column width={8}>
                                      <Input
                                        name="currentItemPriceCalculationGross.value"
                                        path="currentItemPriceCalculationGross.value"
                                        {...currencyTranslation}
                                      />
                                      <div style={{display: 'none'}}>
                                        <Input
                                          name="currentItemPriceCalculationNet.value"
                                          path="currentItemPriceCalculationNet.value"
                                          {...currencyTranslation}
                                        />
                                      </div>
                                    </Grid.Column>
                                    {noLineItemAndTotalCalc ? (
                                      ''
                                    ) : (
                                      <Grid.Column
                                        width={5}
                                        textAlign="right"
                                        style={{left: '50px'}}
                                        verticalAlign="bottom">
                                        <Field
                                          name="lineItemAmount"
                                          render={({input}) => (
                                            <div
                                              style={{
                                                paddingBottom: '20px',
                                              }}
                                              className="_si_le_total">
                                              <span className="le-total label">
                                                <FormattedMessage id="LE_TOTAL" />
                                              </span>
                                              <span className="le-total value">
                                                {' '}
                                                <FormattedNumber
                                                  minimumFractionDigits={2}
                                                  value={input.value}
                                                  currency={currency}
                                                  style="currency"
                                                />
                                              </span>
                                            </div>
                                          )}
                                        />
                                      </Grid.Column>
                                    )}
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                            </Grid.Row>
                          )}
                          <Grid.Row>
                            <Grid.Column>
                              {!hideAoC ? (
                                <AllowancesAndCharges
                                  push={ctx.form.mutators.push}
                                  currency={currency}
                                  lineItemConfig={lineItemConfiguration}
                                />
                              ) : null}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              {validationError}
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Submit
                    disabled={() => {
                      return false;
                    }}
                    positive
                    data-spec="orderLineItemEditor-save-and-confirm">
                    <FormattedMessage id="GENERAL_SAVE_AND_CONFIRM" />
                  </Submit>
                  <Button
                    type="button"
                    color="red"
                    onClick={onCancel}
                    data-spec="orderLineItemEditor-cancel">
                    <FormattedMessage id="GENERAL_CANCEL" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Form>
      </div>
    );
  }
}

OrderLineItemEditor.defaultProps = {
  theme: ECOSIO_THEME,
};

OrderLineItemEditor.propTypes = {
  currency: PropTypes.string,
  pageConfig: pageConfigShape.isRequired,
  lineItem: PropTypes.object,
  fields: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

const mapStateToProps = ({config}) => ({
  theme: config?.userConfig?.theme,
});

export default connect(mapStateToProps)(OrderLineItemEditor);
