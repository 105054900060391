/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import jexl from 'jexl';
import {InputArray, Input, dynID} from '@ecosio/pathform';
import styled from 'styled-components';
import {Header, Icon, Button, Divider} from 'semantic-ui-react';
import {injectIntl, FormattedMessage as Msg} from 'react-intl';
import {get} from 'lodash';
import {intlShape} from '@ecosio/components';
import {
  COOP_BUSINESSCASE_EXPRESSION_X2,
  ECOSIO_THEME,
} from '../../../../../../helpers/constants';
import DesadvInput from '../../CommonComponents/Create/LineItems/DesadvInput';
import {loadTheme} from '../../../../../../helpers/utils';
import {generateDesadvPackageNumbers} from '../../../Utils/CreateUtils';

const ContentDivider = styled(Divider)`
  margin-top: 16px;
  margin-left: -20px !important;
`;

const StyledButton = styled(Button)`
  #add-icon {
    font-size: 12px !important;
  }
  background: none !important;
  ${(props) =>
    props?.customColor ? `color:${props.customColor} !important` : ''}
`;
const StyledDeleteButton = styled(Button)`
  background: none !important;
  ${(props) =>
    props?.customColor ? `color:${props.customColor} !important` : ''};
`;
const ModalSideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  margin-top: 20px;
  margin-left: 20px;
  width: 100%;
`;

const ModalPackagingAmountContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const PackagingLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    display: flex;
    i {
      margin-right: 8px;
      font-size: 12px !important;
      ${(props) =>
        props?.customColor ? `color: ${props.customColor} !important` : ''};
    }
  }
`;

const ModalSideBarRootContainer = styled.div`
  display: flex;
  margin-right: 40px;
  background-color: #fff3e5;
  width: 20%;
`;

const NumberOfPackagesContainer = styled.div`
  border: 1px solid #f59c00;
  border-radius: 5px;
  margin-right: 20px;
  font-size: 11px;
  padding: 4px 7px 4px 6px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  margin-left: 32px;
`;

const SSCC_Container = styled.div`
  margin-left: -10px;
  overflow-wrap: anywhere;
  margin-right: 10px;
`;

const Empty_SSCC_Container = styled.div`
  overflow-wrap: anywhere;
  margin-right: 10px;
  font-size: 0.83em;
`;

const composeValidators = (...validators) => (value, values) =>
  validators.reduce(
    (error, validator) => error || validator(value, values),
    undefined
  );

export const validateSsccDuplicatesForSimpleComplexSideBar = (
  value,
  values
) => {
  //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/514
  if (value && `${value}`.length === 18) {
    const findDuplicate = values?.consignmentPackagingSequences
      .filter((cps, index) => {
        const indexOfRootCps = values?.consignmentPackagingSequences.findIndex(
          (cps) => cps.hierarchicalID === '1'
        );
        //We dont want root cps in sidebar
        if (indexOfRootCps === index) {
          return false;
        }

        return true;
      })
      .filter(
        (cps) =>
          cps?.packagingInformations[0]?.identifications[0]?.identityNumber ===
          value
      );
    if (findDuplicate.length > 1) {
      return 'GENERAL_SSCC_DUPLICATE';
    }
  }

  return undefined;
};

export const validateIfCpsHasDeliveryLineItems = (value, values) => {
  if (value) {
    const cpsesWithoutRootCps =
      // eslint-disable-next-line radar/no-identical-functions
      values?.consignmentPackagingSequences.filter((cps, index) => {
        const indexOfRootCps = values?.consignmentPackagingSequences.findIndex(
          (cps) => cps.hierarchicalID === '1'
        );
        //We dont want root cps in sidebar
        if (indexOfRootCps === index) {
          return false;
        }

        return true;
      }) || [];

    const findIndexOfSscc = cpsesWithoutRootCps.findIndex(
      (cps) =>
        cps?.packagingInformations[0]?.identifications[0]?.identityNumber ===
        value
    );

    if (findIndexOfSscc !== -1) {
      const deliveryLineItemsOfCps =
        cpsesWithoutRootCps[findIndexOfSscc]?.deliveryLineItems || [];

      return !deliveryLineItemsOfCps.length
        ? 'COOP_PACKAGE_IS_EMPTY'
        : undefined;
    }
  }
  return undefined;
};

const ModalSideBar = ({
  packagingLabel,
  formFieldConfig,
  dispatchData,
  exposedValues,
  intl,
  isNullPosition,
  theme,
  formContext,
}) => {
  const isSimpleComplexDesadvWithSideBar = jexl.evalSync(
    COOP_BUSINESSCASE_EXPRESSION_X2,
    exposedValues
  );

  if (!isSimpleComplexDesadvWithSideBar) {
    return null;
  }

  const sccFieldPath = 'cpses.packagingInformations.identityNumber';

  const ssccField = get(formFieldConfig, sccFieldPath);
  const ssccLabelTranslationKey = ssccField?.input?.label;
  const currentTheme = loadTheme(theme);

  return (
    <ModalSideBarRootContainer>
      <ModalSideBarContainer>
        <PackagingLabelContainer customColor={currentTheme?.primaryColor}>
          <Header as="h4" data-spec="desadv-sidebar-pck-type">
            <Icon name="pallet" />
            <Msg id={dynID(`${packagingLabel}_MODAL_SIDEBAR`)} />
            <ModalPackagingAmountContainer>
              <NumberOfPackagesContainer data-spec="desadv-sidebar-package-count">
                {
                  dispatchData?.consignmentPackagingSequences[0]
                    ?.packagingInformations[0]?.numberOfPackages
                }
                {` `}
                <Msg id="PCE" />
              </NumberOfPackagesContainer>
            </ModalPackagingAmountContainer>
          </Header>
        </PackagingLabelContainer>
        <ContentDivider />
        <InputArray
          name="consignmentPackagingSequences"
          path="consignmentPackagingSequences"
          render={(cpses, index) => {
            if (!cpses.fields.length) {
              return null;
            }

            const nonRootCpses = cpses.fields.value.filter(
              (item) => item.hierarchicalID !== '1'
            );

            if (!nonRootCpses.length) {
              return (
                <Empty_SSCC_Container>
                  {intl.formatMessage({
                    id: 'COOP_SSCC_SIDEBAR_CREATE_VIEW_ZERO_PACKAGES',
                  })}
                </Empty_SSCC_Container>
              );
            }
            const indexOfRootCps = cpses.fields.value.findIndex(
              (ele) => ele.hierarchicalID === '1'
            );
            //We dont want root cps in sidebar
            if (indexOfRootCps === index) {
              return null;
            }
            return cpses.fields.map((cpsesPath, cpsesKey) => {
              const indexOfRootCps = cpses.fields.value.findIndex(
                (ele) => ele.hierarchicalID === '1'
              );
              //We dont want root cps in sidebar
              if (indexOfRootCps === cpsesKey) {
                return null;
              }
              return (
                <DesadvInput
                  key={cpsesKey}
                  columnWidth={16}
                  path={sccFieldPath}
                  formFieldConfig={formFieldConfig}
                  exposedValues={exposedValues}
                  render={(props) => (
                    <SSCC_Container>
                      <Input
                        {...props}
                        action={
                          <StyledDeleteButton
                            data-spec={`desadv-sidebar-delete-package-${cpsesKey}`}
                            icon="trash"
                            onClick={(e) => {
                              e.preventDefault();
                              //length check is here 2, because we have to add root cps too
                              if (cpses.fields.length > 2) {
                                cpses.fields.remove(cpsesKey);

                                //Change the numbOfPackages when deleting, it updates the dropDownOptions
                                formContext.form.change(
                                  `consignmentPackagingSequences[0].packagingInformations[0]`,
                                  {numberOfPackages: cpses.fields.length - 2}
                                );
                              }
                            }}
                            customColor={currentTheme?.primaryColor}
                          />
                        }
                        name={`${cpsesPath}.packagingInformations[0].identifications[0].identityNumber`}
                        path={sccFieldPath}
                        label={intl.formatMessage(
                          {id: dynID(ssccLabelTranslationKey)},
                          {
                            position: cpsesKey,
                          }
                        )}
                        placeholder={intl.formatMessage({
                          id: `${dynID(ssccLabelTranslationKey)}_PLACEHOLDER`,
                        })}
                        validate={composeValidators(
                          validateSsccDuplicatesForSimpleComplexSideBar,
                          validateIfCpsHasDeliveryLineItems
                        )}
                      />

                      {!isNullPosition &&
                      cpsesKey + 1 === cpses.fields.length ? (
                        <StyledButton
                          data-spec="desadv-sidebar-add-package"
                          onClick={async (e) => {
                            e.preventDefault();

                            const fieldValue = {
                              ...cpses.fields.value[cpsesKey],
                            };

                            const ssccNumber = await generateDesadvPackageNumbers(
                              'SSCC_GENERATE',
                              1
                            );

                            delete fieldValue?.deliveryLineItems;
                            const allCpses = cpses?.fields?.value || [];

                            const allCpsHierarchicalIDs = allCpses?.map(
                              (cps) => {
                                return Number(cps?.hierarchicalID || 0);
                              }
                            );

                            const highestCpsHierarchicalID = Math.max(
                              ...allCpsHierarchicalIDs
                            );
                            cpses.fields.push({
                              ...fieldValue,
                              hierarchicalID: `${highestCpsHierarchicalID + 1}`,
                              packagingInformations: [
                                {
                                  identifications: [
                                    {
                                      identityNumber:
                                        ssccNumber?.packagingNumber[0]?.value,
                                    },
                                  ],
                                },
                              ],
                            });

                            formContext.form.change(
                              `consignmentPackagingSequences[0].packagingInformations[0]`,
                              {numberOfPackages: cpses.fields.length}
                            );
                          }}
                          customColor={currentTheme?.primaryColor}>
                          <Icon id="add-icon" name="add" />
                          &nbsp;
                          <Msg id="COOP_COMPLEX_MODAL_ADD_CPS_BUTTON" />
                        </StyledButton>
                      ) : (
                        ''
                      )}
                    </SSCC_Container>
                  )}
                />
              );
            });
          }}
        />
      </ModalSideBarContainer>
    </ModalSideBarRootContainer>
  );
};

ModalSideBar.defaultProps = {
  isNullPosition: false,
  theme: ECOSIO_THEME,
};

ModalSideBar.propTypes = {
  packagingLabel: PropTypes.string,
  formFieldConfig: PropTypes.object.isRequired,
  dispatchData: PropTypes.object.isRequired,
  exposedValues: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isNullPosition: PropTypes.bool,
  theme: PropTypes.string,
};

export default injectIntl(ModalSideBar);
