import React, {Fragment} from 'react';
import {Header, Grid} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {Field} from 'react-final-form';
import {Input} from '@ecosio/pathform';

const PriceInformation = ({lineItem, currency}) => {
  const measureUnitTranslation = lineItem?.measureUnit
    ? {
        inputLabel: {
          basic: true,
          content: <FormattedMessage id={lineItem.measureUnit} />,
        },
        labelPosition: 'right',
      }
    : {};

  return (
    <Fragment>
      <Header as="h3" textAlign="left">
        <FormattedMessage id="GENERAL_PRICE_INFORMATION" />
      </Header>
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column data-spec="price-col-1">
            <Input
              name="currentItemPriceCalculationGross.value"
              path="currentItemPriceCalculationGross.value"
            />
            <div style={{display: 'none'}}>
              <Input
                name="currentItemPriceCalculationNet.value"
                path="currentItemPriceCalculationNet.value"
              />
            </div>
            <Input name="quantityUnit" path="quantityUnit" />
            <Input
              name="ratioAlternativeQuantity"
              path="ratioAlternativeQuantity"
            />
            <Input
              name="invoicedQuantity"
              path="invoicedQuantity"
              {...measureUnitTranslation}
            />
          </Grid.Column>
          <Grid.Column data-spec="price-col-2">
            <Field
              name="lineItemAmount"
              render={({input}) => (
                <div style={{marginTop: '24px'}} className="_si_le_total">
                  <span className="le-total label">
                    <FormattedMessage id="LE_TOTAL" />
                  </span>
                  <span className="le-total value" data-spec="total">
                    {' '}
                    <FormattedNumber
                      minimumFractionDigits={2}
                      value={input.value}
                      currency={currency}
                      style="currency"
                    />
                  </span>
                </div>
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default PriceInformation;
