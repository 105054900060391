import {get, set} from 'lodash';

export const preprocessInvoice = (doc) => {
  const footer = get(doc, 'footer.invoiceFooter');

  if (!footer) {
    set(doc, 'footer.invoiceFooter', {});
  }

  const header = get(doc, 'header.invoiceHeader');

  if (!header) {
    set(doc, 'header.invoiceHeader', {allowancesAndCharges: []});
  }

  let lineItems = get(doc, 'details.invoiceData.invoiceLineItems');

  if (!lineItems) {
    const errorMessage = 'No invoice lineItems';

    // currently users cannot add new line items, so if the initial
    // document has none, they will not be able to create an invoice.
    throw new Error(errorMessage);
  }

  let posNr = 1;
  lineItems = lineItems.map((lineItem) => {
    return {
      ...lineItem,
      positionNumber: posNr++,
    };
  });

  set(doc, 'details.invoiceData.invoiceLineItems', lineItems);

  return doc;
};
