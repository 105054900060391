/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {FormatDate, FormatDateTime} from '@ecosio/components';
import {Segment, Grid, List, Button} from 'semantic-ui-react';
import get from 'lodash/get';
import {SwitcherOrBackButton} from '../SwitchDropdown';
import DocumentHeader from '../DocumentHeader';
import Archive from '../ArchiveComponents';
import DocumentMeta from '../Order/View/DocumentMeta';
import {
  STATUS_READ,
  STATUS_ARCHIVED,
} from '../../../shapes/supportedEnvelopeStati';

import {documentExchangeShape} from '../../../shapes/documentExchange';
import {scenarioShape} from '../../../shapes/scenarios';
import {envelopesShape} from '../../../shapes/envelopes';

import DownloadPdfButton from '../Download/DownloadPdfButton';
import CreateDocumentButtonBar from '../Turnaround/CreateDocumentButtonBar';
import {TabbedItem, BusinessEntity} from './DelforCommons';
import ForecastItem from './ForecastItem';

const DelforHeader = ({
  supplier,
  buyer,
  consignee,
  dates,
  documentNumber,
  referencedDocuments,
}) => (
  <Grid columns={3}>
    <Grid.Column width={3}>
      <BusinessEntity headerId="GENERAL_SUPPLIER" data={supplier} />
    </Grid.Column>
    <Grid.Column width={3}>
      <BusinessEntity
        headerId="GENERAL_BUYER"
        data={buyer}
        headerStyle={{marginTop: '0'}}
      />
    </Grid.Column>
    <Grid.Column width={3}>
      <BusinessEntity
        headerId="GENERAL_CONSIGNEE"
        data={consignee}
        headerStyle={{marginTop: '0'}}
      />
    </Grid.Column>
    <Grid.Column width={6}>
      <List>
        <TabbedItem
          title="DOCUMENT_DATE"
          content={
            <FormatDate
              stripTimezone
              dateString={get(dates, 'documentDate.dateTime')}
            />
          }
        />
        <TabbedItem title="DOCUMENT_NUMBER" content={documentNumber} />
        <TabbedItem
          title="VERSION_NUMBER"
          content={get(
            referencedDocuments,
            'schedulingAgreement.referenceVersionNumber'
          )}
        />
        <TabbedItem
          title="PREV_VERSION_NUMBER"
          content={get(
            referencedDocuments,
            'previouslyReceivedDocument.referenceVersionNumber'
          )}
        />
      </List>
    </Grid.Column>
  </Grid>
);

DelforHeader.propTypes = {
  supplier: PropTypes.object,
  buyer: PropTypes.object,
  consignee: PropTypes.object,
  dates: PropTypes.object,
  documentNumber: PropTypes.string,
  referencedDocuments: PropTypes.object,
};

const DelforPage = ({
  documentExchange,
  documentUuid,
  dropdownItems,
  onChangeDropdown,
  onClickArchive,
  onClickMetaDoc,
  scenario,
  envelope,
  match,
  history,
}) => {
  const referenceNumber = get(
    documentExchange.data,
    'document.header.referencedDocuments.schedulingAgreement.referenceNumber'
  );
  const lineItems = get(
    documentExchange.data,
    'document.details.forecastData.forecastLineItems'
  );
  const buyer = get(
    documentExchange.data,
    'document.header.businessEntities.buyer'
  );
  const supplier = get(
    documentExchange.data,
    'document.header.businessEntities.supplier'
  );

  const consignee = get(
    documentExchange.data,
    'document.header.businessEntities.consignee'
  );

  const envelopeUuid = match.params.envelopeUuid;
  const scenarioUuid = match.params.scenarioUuid;

  const pageConfig = documentExchange.pageConfig;

  return (
    <Fragment>
      <div id="delfor-template">
        <DocumentHeader
          pageConfig={pageConfig}
          envelope={envelope.data}
          scenario={scenario}
          documentExchange={documentExchange}>
          <Archive.Button
            loading={envelope.status.posting}
            onArchive={onClickArchive}
            status={envelope.data.status}
          />
        </DocumentHeader>
        <Archive.Message show={envelope.data.status === 'ARCHIVED'} />
        <Segment attached="top" clearing>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={8}>
                <SwitcherOrBackButton
                  items={dropdownItems}
                  onChange={onChangeDropdown}
                  selected={documentUuid}
                  scenarioUuid={scenarioUuid}
                  envelopeUuid={envelopeUuid}
                  documentUuid={documentUuid}
                />
              </Grid.Column>
              <Grid.Column width={8} style={{textAlign: 'right'}}>
                <Button.Group floated="right">
                  <DownloadPdfButton
                    dataSpec="createDocumentBar-download-button"
                    currentDocumentUuid={documentUuid}
                    envelopeUuid={envelopeUuid}
                    scenario={scenario}
                    currentDocumentTypeID={
                      documentExchange.data.meta.documentTypeId
                    }
                  />
                  <CreateDocumentButtonBar
                    deliveryDates={documentExchange.deliveryDates?.data}
                    hide={false}
                    envelopeStatus={envelope.data.status}
                    scenario={scenario}
                    envelopeUuid={envelopeUuid}
                    envelope={envelope.data}
                    documentUuid={documentUuid}
                    docType={documentExchange.data.meta.documentTypeId}
                    pageConfig={pageConfig}
                  />
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment attached>
          <DelforHeader
            buyer={buyer}
            supplier={supplier}
            consignee={consignee}
            referenceNumber={referenceNumber}
            dates={get(documentExchange.data, 'document.header.dates')}
            referencedDocuments={get(
              documentExchange.data,
              'document.header.referencedDocuments'
            )}
            documentNumber={get(
              documentExchange.data,
              'document.header.beginningOfMessage.documentNumber'
            )}
          />
        </Segment>
        <Segment attached="bottom" style={{backgroundColor: '#F1F2F3'}}>
          {lineItems.map((lineitem, idx) => (
            <ForecastItem key={idx} lineitem={lineitem} />
          ))}
        </Segment>
        <DocumentMeta
          metaDocuments={envelope.data.metaDocuments}
          onSelect={onClickMetaDoc}
          pageConfig={pageConfig}
          location={history?.location}
        />
      </div>
    </Fragment>
  );
};

DelforPage.propTypes = {
  documentExchange: PropTypes.shape({
    data: documentExchangeShape,
  }),
  documentUuid: PropTypes.string,
  dropdownItems: PropTypes.array,
  onChangeDropdown: PropTypes.func,
  onClickMetaDoc: PropTypes.func,
  onClickArchive: PropTypes.func,
  scenario: scenarioShape,
  envelope: PropTypes.shape({
    data: envelopesShape,
    status: PropTypes.shape({
      posting: PropTypes.bool,
      error: PropTypes.any,
    }),
  }),
  match: PropTypes.object,
};

export default class DelforTemplate extends React.Component {
  constructor(props) {
    super(props);
  }

  onChangeDropdown = (e, value) => {
    const {scenarioUuid, envelopeUuid} = this.props;
    this.props.history.push(
      `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${value}`
    );
  };

  getDropdownListItems = () => {
    return (
      this.props.envelope.data.selectableDocuments.map((item, idx) => ({
        key: idx,
        text: (
          <div>
            <FormattedMessage id={item.documentTypeId} />{' '}
            <FormattedMessage id="GENERAL_FROM" />{' '}
            <FormatDateTime stripTimezone dateString={item.createdAt} />
          </div>
        ),
        value: item.uuid,
        content: (
          <div>
            <strong>
              <FormattedMessage id={item.documentTypeId} />
            </strong>
            <div>
              <FormatDateTime stripTimezone dateString={item.createdAt} />
            </div>
          </div>
        ),
      })) || []
    );
  };

  onClickArchive = (e) => {
    e.preventDefault();
    const {envelope} = this.props;
    const newStatus =
      envelope.data.status === STATUS_ARCHIVED ? STATUS_READ : STATUS_ARCHIVED;
    this.props.postEnvelopeStatus(newStatus);
  };

  onClickMetaDoc = (uuid) => {
    const {scenarioUuid, envelopeUuid, history} = this.props;
    history.push(
      `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${uuid}`
    );
  };

  render() {
    return (
      <div className="_si_delfor_view">
        <DelforPage
          scenario={this.props.scenario}
          dropdownItems={this.getDropdownListItems()}
          onChangeDropdown={this.onChangeDropdown}
          onClickArchive={this.onClickArchive}
          onClickMetaDoc={this.onClickMetaDoc}
          history={history}
          {...this.props}
        />
      </div>
    );
  }
}

DelforTemplate.propTypes = {
  scenarioUuid: PropTypes.string,
  envelopeUuid: PropTypes.string,
  history: PropTypes.object,
  scenario: scenarioShape,
  envelope: PropTypes.shape({
    data: envelopesShape,
    status: PropTypes.shape({
      posting: PropTypes.bool,
      error: PropTypes.any,
    }),
  }),
  postEnvelopeStatus: PropTypes.func,
};
