/* eslint-disable react/no-array-index-key */
import React from 'react';
import {get, set} from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'uuid-random';

import {Input, InputArray} from '@ecosio/pathform';

const NveComponent = ({context, index}) => {
  const count = parseInt(
    get(context, `fields.value[${index}].numberOfPackages`, 0)
  );

  if (count && count > 0) {
    const numbersArray = get(
      context,
      `fields.value[${index}].identifications`,
      0
    );

    if (count > numbersArray.length) {
      const a = numbersArray.concat(
        new Array(count - numbersArray.length).fill(0).map(() => ({
          identityNumber: uuid(),
        }))
      );

      set(context, `fields.value[${index}].identifications`, a);
    }

    if (count < numbersArray.length) {
      const a = numbersArray.slice(0, count);
      set(context, `fields.value[${index}].identifications`, a);
    }
  }
  return (
    <InputArray name={`packagingInformations[${index}].identifications`}>
      {(ctx) =>
        ctx.fields.map((name, index) => (
          <Input
            key={index}
            name={`${name}.identityNumber`}
            path="deliveryLineItems.nve"
            width={10}
          />
        ))
      }
    </InputArray>
  );
};

NveComponent.propTypes = {
  context: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default NveComponent;
