import axios from 'axios';
import debounce from 'lodash/debounce';

export const WEBEDI_DOCUMENT_PROCESSING_FAILED =
  'WEBEDI_DOCUMENT_PROCESSING_FAILED';

class Event {
  constructor() {}

  triggerEvent = debounce(async (webEdiEvent) => {
    return await axios
      .post(`/api/envelopes/event`, webEdiEvent)
      .then((res) => res)
      .catch((err) => {
        console.error(
          `Triggering WebEdiEvent failed:  ${err?.message}`,
          webEdiEvent
        );
      });
  }, 250);
}

export default new Event();
