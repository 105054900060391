import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const dynID = (translationID, fallback) => {
  if (!translationID || typeof translationID !== 'string') {
    console.error(
      `[DynamicKeyTranslation] Dynamic translationkey ${translationID} not found`
    );
    return fallback || 'GENERAL_TRANSLATION_NOT_FOUND';
  }

  return translationID;
};

export const DynamicMessage = ({id, fallback}) => (
  <FormattedMessage id={dynID(id, fallback)} />
);

DynamicMessage.propTypes = {
  id: PropTypes.string.isRequired,
  fallback: PropTypes.string,
};
