import createDecorator from 'final-form-calculate';
import InvoiceCalculator from '../../../../../calculations/InvoiceCalculator';

const InvoiceCalc = new InvoiceCalculator();
const updates = (value, name, allValues) =>
  InvoiceCalc.calculateLineItem(allValues);
const invoiceDecorator = createDecorator(
  {
    field: /unitPriceBasis/,
    updates,
  },
  {
    field: /invoicedQuantity/,
    updates,
  },
  {
    field: 'currentItemPriceCalculationGross',
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.percentageOfAllowanceOrCharge/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.allowanceChargeAmount/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.allowanceChargeQualifier/,
    updates,
  }
);

export default invoiceDecorator;
