import qs from 'qs';
import jexl from 'jexl';
import {MERGED_UUID} from '../constants';

export const createDeliveryParams = (deliveryDate, consignee) => {
  if (!deliveryDate) {
    return {};
  }

  const params = {
    dateFilter: deliveryDate,
  };

  if (typeof consignee === 'object') {
    if (consignee.duns) {
      params.duns = consignee.duns;
    }

    if (consignee.storageLocation) {
      params.storageLocation = consignee.storageLocation;
    }

    if (consignee.unloadingPoint) {
      params.unloadingPoint = consignee.unloadingPoint;
    }
  } else {
    if (typeof consignee !== 'undefined') {
      console.warn(`Invalid consignee type ${typeof consignee}`);
    }
  }

  return params;
};

/**
 *
 * @param options  {envelopeUuid, documentUuid, docType, deliveryDate, consignee: {duns, storageLocation, unloadingPoint}}
 * @param params
 * @return {string|undefined}
 */
export const createTurnaroundLink = (options, params = {}) => {
  const {
    scenarioUuid,
    envelopeUuid,
    documentUuid,
    docType,
    deliveryDate,
    consignee,
  } = options || {};

  if (!scenarioUuid || !envelopeUuid || !docType) {
    console.warn('Invalid options supplied to createTurnaroundLink', options);
    return;
  }
  const deliveryPart = createDeliveryParams(deliveryDate, consignee);

  return `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${documentUuid}/create/${docType}?${qs.stringify(
    Object.assign(params, deliveryPart)
  )}`;
};

/**
 * Receives an array of delivery dates (output of the groupDates function
 * on an order) and returns an object indicating that a turnaround
 * modal with desadv relevant should be shown or not - when creating
 * a new desadv.
 *
 * @param deliveryDates
 * @return {{desadvModal: boolean}|{consignee: *, desadvModal: boolean, deliveryDate: *}}
 */
export const extractDesadvDataFromDeliveryDates = (deliveryDates) => {
  if (!Array.isArray(deliveryDates)) {
    return {desadvModal: false};
  }
  let desadvModal = false;
  let deliveryDate = undefined;
  let consignee = undefined;
  if (deliveryDates.length > 1) {
    desadvModal = true;
  } else if (deliveryDates.length === 1) {
    deliveryDate = deliveryDates[0].date;
    if (deliveryDates[0].consignees.length > 1) {
      desadvModal = true;
    } else {
      consignee = deliveryDates[0].consignees[0];
    }
  }

  return {desadvModal, deliveryDate, consignee};
};

export const doesEnableButtonExpressionMatch = (mapping, envelope) => {
  const enableDocumentExpression = mapping?.enableDocumentExpression;
  const documentData = envelope?.documentData;

  if (
    !enableDocumentExpression ||
    typeof enableDocumentExpression !== 'string'
  ) {
    console.error('enableExpression is undefined or not a string');
    return false;
  }

  if (!documentData) {
    console.error('extracted documentData is undefined');
    return false;
  }

  try {
    return jexl.evalSync(`${enableDocumentExpression}`, documentData);
  } catch (error) {
    console.error(
      `enableExpression evaluation failed for targetDoc ${mapping?.outboundDocType}: `,
      error
    );
    return false;
  }
};

export const getTurnaroundSourceDocuments = (
  envelope,
  scenario,
  targetDocType
) => {
  const targetMappings = scenario.mappings.filter(
    (mapping) =>
      mapping.outboundDocType === targetDocType &&
      (!mapping.enableDocumentExpression ||
        doesEnableButtonExpressionMatch(mapping, envelope))
  );

  const sourceDocs = envelope.documents.filter((document) => {
    return (
      targetMappings.filter(
        (mapping) =>
          mapping.inboundDocType === document.documentTypeId &&
          mapping.outboundDocType === targetDocType
      ).length > 0
    );
  });

  const mergedDocTypes = scenario.documentMerger?.documentTypesToMerge || [];

  return (
    sourceDocs
      /**https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/236#note_167120 */
      /*
    .filter(({documentTypeId}) => {
      // if it's a doc type that is merged and its not the starting doctype,
      // skip this from the sourceDocs as the user should not be able to select
      // this type of doc as a source doc for a turnaround document
      return !(
        mergedDocTypes.indexOf(documentTypeId) > -1 &&
        scenario.startingWebDocTypeId !== documentTypeId
      );
    })*/
      .map(({uuid, documentTypeId, ...rest}) => {
        return {
          ...rest,
          // map the uuid of the source doc to the "virtual" "merged" uuid
          // if its the merged one, should only be one, see the filter above
          uuid:
            mergedDocTypes.indexOf(documentTypeId) > -1 ? MERGED_UUID : uuid,
          documentTypeId: documentTypeId,
        };
      })
      .reduce((acc, curr) => {
        //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/479#note_368039
        /**
         * many source documents have uuid`s as "merged", but in webedi we only need one merged state
         * we reduce all sourceDocuments to one source document here. e.g only one source document with "merged" state.
         */
        const objFound = acc.find((ele) => ele?.uuid === curr.uuid);

        if (!objFound) {
          acc.push(curr);
        }

        return acc;
      }, [])
  );
};

/**
 * helper function for creating turnaround button links.
 * Finds the uuid for the source document of a turnaround mapping.
 *
 * Tricky part here is to handle the special case of the "MERGED" envelope
 * view, where we need to create the turnaround document based on the
 * virtual/merged view of an envelope.
 */
export const determineSourceDocumentUuid = (
  scenario,
  currentDocumentUuid,
  sourceDocument,
  sourceDocType,
  targetDocType
) => {
  // simple, if its not the merged state we can return
  if (currentDocumentUuid !== MERGED_UUID) {
    if (!sourceDocument || !sourceDocType.uuid) {
      throw new Error(
        'Invalid source document, cannot determine source doc uuid'
      );
    }
    return sourceDocument.uuid;
  }

  if (!scenario) {
    throw new Error('Unable to get source document without a scenario');
  }

  // check if the source doc type is mergeable...
  // eslint-disable-next-line radar/no-collapsible-if
  if (
    scenario.documentMerger?.documentTypesToMerge &&
    scenario.documentMerger.documentTypesToMerge.indexOf(sourceDocType) > -1
  ) {
    if (
      !scenario.mappings.filter(
        (mapping) =>
          mapping.inboundDocType === sourceDocType &&
          mapping.outboundDocType === targetDocType
      ).length
    ) {
      // it's mergeable but we cannot find a mapping with the source
      // target doc type. This means we're on a merged document
      // page but want to create a turnaround document from another
      // source than the merged document...
      return sourceDocument.uuid;
    }
  }
  return MERGED_UUID;
};

export const isCreateButtonDisabled = (
  currentNumberOfDocuments,
  sourceDocuments,
  pageConfig,
  createdAt
) => {
  // if none of the documents in the envelope has a matching
  // mapping, we cannot create a turnaround for it
  if (sourceDocuments.length === 0) {
    return true;
  }

  //disable button when enableAt date is greater than createdAt date
  if (pageConfig?.enableAt) {
    return pageConfig?.enableAt > createdAt;
  }

  if (currentNumberOfDocuments && pageConfig?.maximumOutboundDocuments) {
    return currentNumberOfDocuments >= pageConfig?.maximumOutboundDocuments;
  }

  return false;
};
