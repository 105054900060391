/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Grid,
  Segment,
  Modal,
  Header,
  Button,
  Form as SemanticForm,
} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
  RenderContents,
  Content,
  Input,
  Form,
  FormGroup,
  Submit,
  InputArray,
} from '@ecosio/pathform';
import {intlShape} from '@ecosio/components';

import {deliveryLineItemShape} from '../../../Utils/shapes';
import DesAdvEditorValidator from './DesAdvEditorValidator';
import {newNVE} from './DesAdvCommons';
import NveComponent from './NveComponent';

export const TextLine = ({content, label, intl}) => (
  <div style={{marginBottom: '10px'}}>
    <strong>{intl.formatMessage({id: label})}</strong>
    <br />
    <span>{content}</span>
    <br />
  </div>
);

TextLine.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string,
  intl: intlShape,
};

export const DesadvLineEditorContent = ({
  item,
  fields,
  onSave,
  onCancel,
  nveIndex,
  intl,
}) => {
  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column width={5}>
          <RenderContents initialValues={item} fields={fields}>
            {() => {
              return (
                <Fragment>
                  <Content
                    path="positionNumber"
                    name="deliveryLineItems[0].positionNumber"
                    render={TextLine}
                  />
                  <Content
                    path="gtin"
                    name="deliveryLineItems[0].gtin"
                    render={TextLine}
                  />
                  <Content
                    path="manufacturersArticleNumber"
                    name="deliveryLineItems[0].manufacturersArticleNumber"
                    render={TextLine}
                  />

                  <Content
                    path="customersItemMaterialNumber"
                    name="deliveryLineItems[0].customersItemMaterialNumber"
                    render={TextLine}
                  />
                  <Content
                    path="suppliersItemMaterialNumber"
                    name="deliveryLineItems[0].suppliersItemMaterialNumber"
                    render={TextLine}
                  />
                  <Content
                    path="itemDescription"
                    name="deliveryLineItems[0].itemDescription"
                    render={TextLine}
                  />
                </Fragment>
              );
            }}
          </RenderContents>
        </Grid.Column>
        <Grid.Column width={11}>
          <Form
            validate={(values) => DesAdvEditorValidator(values, nveIndex, intl)}
            initialValues={item}
            onSubmit={onSave}
            fields={fields}>
            {(context) => (
              <InputArray name="packagingInformations">
                {(ctx) => (
                  <Fragment>
                    <FormGroup>
                      <Input
                        path="batchNumber"
                        name="deliveryLineItems[0].batchNumber"
                        width={10}
                      />
                      <Input
                        path="expirationDate"
                        name="deliveryLineItems[0].expirationDate"
                        width={10}
                        stripTimezone
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        path="deliveryLineItems.dispatchQuantity"
                        name="deliveryLineItems[0].dispatchQuantity.value"
                        width={10}
                        inputLabel={{
                          basic: true,
                          content:
                            item?.deliveryLineItems[0]?.dispatchQuantity?.unit,
                        }}
                        labelPosition="right"
                        autoFocus
                      />
                      <SemanticForm.Field>
                        <label>&nbsp;</label>
                        <Button
                          className="_si_nve_add"
                          data-spec="nve-add"
                          fluid
                          type="button"
                          primary
                          onClick={() => {
                            context.form.mutators.push(
                              'packagingInformations',
                              newNVE()
                            );
                          }}>
                          <FormattedMessage id="BUTTON_ADD_NVE" />
                        </Button>
                      </SemanticForm.Field>
                    </FormGroup>
                    {ctx.fields.map((name, index) => (
                      <Segment key={index}>
                        <FormGroup>
                          <Input
                            name={`${name}.numberOfPackages`}
                            path="deliveryLineItems.numberOfPackages"
                            width={10}
                          />

                          <Input
                            name={`${name}.quantityPerPack`}
                            path="deliveryLineItems.quantityPerPack"
                            width={10}
                            inputLabel={{
                              basic: true,
                              content:
                                item.deliveryLineItems[0].dispatchQuantity.unit,
                            }}
                            labelPosition="right"
                          />

                          <Input
                            name={`${name}.packageTypeCustomer`}
                            path="deliveryLineItems.packageTypeCustomer"
                            width={10}
                          />

                          <SemanticForm.Field>
                            <label>&nbsp;</label>
                            <Button
                              type="button"
                              data-spec="nve-remove"
                              negative
                              icon
                              onClick={() => ctx.fields.remove(index)}>
                              <Icon name="trash" />
                            </Button>
                          </SemanticForm.Field>
                        </FormGroup>
                        <NveComponent context={ctx} index={index} />
                      </Segment>
                    ))}
                    <div style={{float: 'right'}}>
                      <Submit color="green" data-spec="nve-submit">
                        <FormattedMessage id="GENERAL_SAVE" />
                      </Submit>

                      <Button
                        primary
                        onClick={onCancel}
                        color="red"
                        data-spec="nve-cancel">
                        <FormattedMessage id="GENERAL_CANCEL" />
                      </Button>
                    </div>
                  </Fragment>
                )}
              </InputArray>
            )}
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DesadvLineEditorContent.propTypes = {
  item: deliveryLineItemShape,
  // TODO: document what's  in there
  nveIndex: PropTypes.array,
  fields: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

/**
 * TODO: vi tests
 */
const DesAdvLineItemEditor = ({
  item,
  nveIndex = [],
  selectedIndex,
  open,
  ...rest
}) => {
  if (open && !item) {
    console.warn('No item given to desadv line item editor');
    return null;
  }
  /*
    We check always for new identityNumbers in the current selectedIndex
    for a document global check we need an NveIndex Array without this selectedIndex

    filter out everything which is not the current index - reduce it to flatten the array
  */

  const documentNveIndex = nveIndex
    .filter((item, idx) => idx !== selectedIndex)
    .reduce((acc, cur) => acc.concat(cur), []);

  return (
    <Modal
      data-spec="simple-desadv-lineItem-editor-modal"
      open={open}
      size="large"
      className="_si_desadv_li">
      <Header className="_si_desadv_li_head">
        {item && item?.deliveryLineItems[0] ? (
          <FormattedMessage
            id="LE_EDIT_ITEM"
            values={{item: item.deliveryLineItems[0].positionNumber}}
          />
        ) : null}
      </Header>
      <Modal.Content className="_si_desadv_content">
        <DesadvLineEditorContent
          item={item}
          nveIndex={documentNveIndex}
          {...rest}
        />
      </Modal.Content>
    </Modal>
  );
};

DesAdvLineItemEditor.propTypes = {
  item: deliveryLineItemShape,
  nveIndex: PropTypes.array,
  selectedIndex: PropTypes.number,
  fields: PropTypes.object,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default injectIntl(DesAdvLineItemEditor);
