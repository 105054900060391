import React from 'react';
import {Button, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import ConfirmPopup from '../ConfirmPopup';

const ArchiveButton = ({status, onArchive, loading}) => {
  const isArchived = status === 'ARCHIVED';

  if (isArchived) {
    return (
      <ConfirmPopup
        dialogtitle={<FormattedMessage id="BUSINESSCASE_ARCHIVE" />}
        dialogcontent={<FormattedMessage id="ARCHIVE_CONFIRM_UNDO" />}
        onConfirm={onArchive}
        position="left center"
        trigger={
          <Button
            content={<FormattedMessage id="ARCHIVE_BUTTON_UNDO" />}
            type="button"
            primary
            icon="file alternate outline"
            size="mini"
            loading={loading}
          />
        }
      />
    );
  } else {
    return (
      <ConfirmPopup
        dialogtitle={<FormattedMessage id="BUSINESSCASE_ARCHIVE" />}
        dialogcontent={<FormattedMessage id="ARCHIVE_CONFIRM_DO" />}
        onConfirm={onArchive}
        position="left center"
        trigger={
          <Button
            content={<FormattedMessage id="ARCHIVE_BUTTON_DO" />}
            type="button"
            primary
            icon="archive"
            size="mini"
            loading={loading}
          />
        }
      />
    );
  }
};

const ArchiveMessage = ({show}) => {
  return (
    show && (
      <Message warning>
        <Message.Header>
          <FormattedMessage id="ARCHIVED_BC_WARN_TITLE" />
        </Message.Header>
        <p>
          <FormattedMessage id="ARCHIVED_BC_WARN_CONTENT" />
        </p>
      </Message>
    )
  );
};

const Archive = {
  Button: ArchiveButton,
  Message: ArchiveMessage,
};

export default Archive;
