import React, {Component} from 'react';
import {connect} from 'react-redux';
import {parse} from 'qs';
import {Grid, Loader, Header, Icon} from 'semantic-ui-react';
import {set} from 'lodash';
import {FormattedMessage as Msg, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import htmlParse from 'html-react-parser';
import {fetchTurnaroundDocument} from '../../../../../../reducers/turnaround';
import {ContextWrapper} from '../../CommonComponents/Create/Header/OrderHeaderInformation/EditFormFields/EditForm';
import {
  pageConfigShape,
  scenarioShape,
} from '../../../../../../shapes/scenarios';
import {envelopesShape} from '../../../../../../shapes/envelopes';
import DocumentMeta from '../../CommonComponents/Create/Header/DocumentMeta';
import {
  ContentDivider,
  HeaderContainer,
  HeaderLineContainer,
  TemplateContainer,
  TemplateSubContainer,
} from '../../CommonComponents/Create/Header/StyledDivs';
import CpsForm from './CpsForm';
import Sidebar from './Sidebar/Sidebar';
import {calculateRemainingQuantityForPosition} from './Utils/DispatchedQuantityAggregator';

const mapDispatchToProps = (dispatch) => ({
  fetchTurnaround: (
    scenario,
    envelopeUuid,
    documentMetaUuid,
    documentType,
    filterParams = {}
  ) =>
    dispatch(
      fetchTurnaroundDocument(
        scenario,
        envelopeUuid,
        documentMetaUuid,
        documentType,
        filterParams
      )
    ),
});

const mapStateToProps = ({turnaround}) => ({turnaround});

class DesadvComplexCreateTemplateV1_1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentExchange: null,
      fetching: true,
      //For clientside usage only
      ordersDataTemp: {
        orderLineItems: {},
      },
    };
  }

  componentDidMount() {
    const {match, scenario} = this.props;

    const params = parse(this.props.location.search.substring(1)) || {};
    this.props
      .fetchTurnaround(
        scenario,
        match.params.envelopeUuid,
        match.params.documentUuid,
        match.params.documentType,
        params
      )
      .then((action) => {
        this.setState({
          documentExchange: action,
          fetching: false,
        });
      })
      .catch((err) => console.error(err));
  }

  onSaveOrderMeta = (formdata) => {
    return new Promise((resolve) => {
      const copy = Object.assign({}, this.state.documentExchange);
      this.setState(
        {
          documentExchange: set(copy, 'data.document.header', formdata.header),
        },
        resolve
      );
    });
  };

  updateRemainingQuantityAggregates = (remainingQuantityAggregates) => {
    const documentExchangeCopy = Object.assign({}, this.state.documentExchange);
    let orderLinsOverDeliveries = {};

    /***
     * remove remainingQuantityAggregateForCompletePosition in case a lineItem
     * is not found in remainingQuantityAggregates.
     * Is required when a lineItem is removed manually in the Ui.
     */
    documentExchangeCopy.data.document.details.ordersData.ordersLineItems
      .filter(
        (li) =>
          !remainingQuantityAggregates.find(
            (aggregate) => li.positionNumber === aggregate.positionNumber
          )
      )
      .forEach((li) => {
        delete li.remainingQuantityAggregateForCompletePosition;
      });

    remainingQuantityAggregates.forEach((aggregate) => {
      const ordersPosition = documentExchangeCopy.data.document.details.ordersData.ordersLineItems.find(
        (orderLI) => orderLI.positionNumber === aggregate.positionNumber
      );

      if (ordersPosition) {
        const remainingQuantityForPosition = calculateRemainingQuantityForPosition(
          ordersPosition.orderedQuantity,
          ordersPosition.measureUnitQualifier,
          aggregate
        );

        const remainingQuantityForPositionWithNegativeValue = calculateRemainingQuantityForPosition(
          ordersPosition.orderedQuantity,
          ordersPosition.measureUnitQualifier,
          aggregate,
          true
        );

        if (remainingQuantityForPosition !== null) {
          ordersPosition.remainingQuantityAggregateForCompletePosition = {
            value: remainingQuantityForPosition,
            unit: ordersPosition.measureUnitQualifier,
          };
        }

        if (remainingQuantityForPositionWithNegativeValue != null) {
          orderLinsOverDeliveries = {
            ...orderLinsOverDeliveries,
            [ordersPosition.positionNumber]: {
              remainingQuantityForPosition: remainingQuantityForPositionWithNegativeValue,
            },
          };
        }
      }
    });
    this.setState((state) => {
      return {
        ...state,
        documentExchange: documentExchangeCopy,
        ordersDataTemp: {
          ...state.ordersDataTemp,
          orderLineItems: {
            ...orderLinsOverDeliveries,
          },
        },
      };
    });
  };

  render() {
    const {pageConfig, scenario, envelope} = this.props;
    const renderLoader = this.state.fetching || !this.state.documentExchange;

    return renderLoader ? (
      <Loader active />
    ) : (
      <TemplateContainer>
        <ContextWrapper>
          <HeaderContainer>
            <Header as="h1">
              <Icon
                circular
                link
                name="chevron left"
                onClick={() => this.props.history.goBack()}
              />
              <Header.Content>
                <FormattedMessage id="CREATE_XML_DESADV_1p0_ERPEL_INDUSTRY_MESSAGE" />
              </Header.Content>
            </Header>
          </HeaderContainer>
          <TemplateSubContainer>
            <DocumentMeta
              showReadonly={false}
              documentExchange={this.state.documentExchange.data}
              onSubmit={this.onSaveOrderMeta}
              exposedValues={this.state.exposedValues}
              pageConfig={pageConfig}
              exposeHeaderValues={this.exposeHeaderValues}
            />
          </TemplateSubContainer>
          <TemplateSubContainer>
            <HeaderLineContainer>
              <div>
                <Header as="h3">
                  <Msg id="DESADV_INSTRUCTION_HEADER" />
                </Header>
              </div>
              <ContentDivider />
            </HeaderLineContainer>
            <div></div>
            {/**https://formatjs.io/docs/react-intl/upgrade-guide-4x#migrating-off-embedded-html-in-messages */}
            <FormattedMessage id="DESADV_INSTRUCTION_BODY">
              {(chunks) => htmlParse(chunks.join(''))}
            </FormattedMessage>
            <Grid columns={2} style={{marginTop: '30px'}}>
              <Grid.Column width="11">
                <CpsForm
                  history={this.props.history}
                  match={this.props.match}
                  documentExchange={this.state.documentExchange?.data.document}
                  data={this.state.documentExchange?.data.document.details}
                  config={this.props.pageConfig}
                  scenario={scenario}
                  onCpsStructureUpdate={this.updateRemainingQuantityAggregates}
                  ordersDataTemp={this.state.ordersDataTemp}
                />
              </Grid.Column>
              <Grid.Column width="5">
                <Sidebar
                  data={this.state.documentExchange?.data.document.details}
                  envelope={envelope}
                  ordersDataTemp={this.state.ordersDataTemp}
                  config={this.props.pageConfig}
                />
              </Grid.Column>
            </Grid>
          </TemplateSubContainer>
        </ContextWrapper>
      </TemplateContainer>
    );
  }
}

DesadvComplexCreateTemplateV1_1.propTypes = {
  match: PropTypes.object,
  scenario: scenarioShape,
  pageConfig: pageConfigShape,
  envelope: envelopesShape,
  fetchTurnaround: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesadvComplexCreateTemplateV1_1);
