//first argument is the value of storage, all other arguments are keys concated with :
export const saveToLocalStorage = (data, ...rest) => {
  const id = generateLocalStorageKey(...rest);

  if (id && data) {
    localStorage.setItem(id, JSON.stringify(data));
  }
};

export const getFromLocalStorage = (...args) => {
  const id = generateLocalStorageKey(...args);

  const json = localStorage.getItem(id);

  try {
    const parsed = JSON.parse(json);
    if (parsed) {
      return parsed;
    } else {
      return null;
    }
  } catch (e) {
    console.warn('LocalStorageUtil parsing json failed', e);
    return null;
  }
};

export const generateLocalStorageKey = (...args) => {
  let key;

  for (let i = 0; i < args.length; i++) {
    if (!key) {
      key = args[i];
    } else {
      key = `${key}:${args[i]}`;
    }
  }
  return key;
};
