import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from '@ecosio/pathform';
import {FormSpy} from 'react-final-form';
import {isEqual} from 'lodash';
import styled from 'styled-components';
import DesadvInput from '../../../LineItems/DesadvInput';
import {pageConfigShape} from '../../../../../../../../../shapes/scenarios';
import {isFieldReadOnly} from '../../../LineItems/FieldContent';
import {HeaderCountryOfOriginComponent} from '../../../../../../../ComplexEnrichmentComponents/HeaderCountryOfOriginComponent';
import InputArrayValueElementsListComponent from '../../../InputArrayValueElementsListComponent.jsx';

export const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 9px 0px 9px;
`;

export const HEADER_FORM_ID = 'HEADER_FORM';

export const DocumentMetaContext = React.createContext({
  headerValid: false,
  lineItemsValid: false,
  updateHeader: null,
  updateLineItems: null,
});

/**
 * Provides a context for the header form  and lineItem form validation state
 */
export class ContextWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line
        headerValid: false,
      // eslint-disable-next-line
         lineItemsValid: false,
      // eslint-disable-next-line
        updateHeader: this.updateHeader,
      // eslint-disable-next-line
        updateLineItems: this.updateLineItems,
    };
  }

  updateHeader = (headerValid) => {
    // eslint-disable-next-line
      this.setState({headerValid});
  };

  updateLineItems = (lineItemsValid) => {
    // eslint-disable-next-line
      this.setState({lineItemsValid});
  };
  render() {
    return (
      <DocumentMetaContext.Provider value={this.state}>
        {this.props.children}
      </DocumentMetaContext.Provider>
    );
  }
}

ContextWrapper.propTypes = {
  children: PropTypes.any,
};

export class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {values: props.values};
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const {values, onSubmit} = this.props;
    if (!isEqual(this.state.values, values)) {
      this.setState({values});
      this.promise = onSubmit(values);
      await this.promise;
      delete this.promise;
    }
  };

  render() {
    return <></>;
  }
}
AutoSave.defaultProps = {};

AutoSave.propTypes = {
  values: PropTypes.any,
  onSubmit: PropTypes.func,
  debounce: PropTypes.number,
};

const EditForm = ({
  documentExchange,
  onSubmit,
  pageConfig,
  exposeHeaderValues,
  exposedValues,
}) => {
  const formFieldConfig = pageConfig?.formFields || {};
  return (
    <Form
      formid={HEADER_FORM_ID}
      onSubmit={onSubmit}
      initialValues={documentExchange?.document}
      fields={formFieldConfig}>
      {(formContext) => (
        <React.Fragment>
          <FormSpy
            onSubmit={onSubmit}
            subscription={{values: true}}
            component={AutoSave}
            debounce={250}
          />

          <DocumentMetaContext.Consumer>
            {({updateHeader}) => {
              if (typeof updateHeader !== 'function') {
                return null;
              }

              return (
                <FormSpy
                  onChange={({valid}) => updateHeader(valid)}
                  subscription={{valid: true}}
                />
              );
            }}
          </DocumentMetaContext.Consumer>
          <FormSpy
            subscription={{values: true}}
            onChange={(state) => {
              const {values} = state;

              exposeHeaderValues(values.header);
            }}
          />
          <ItemsContainer>
            <DesadvInput
              columnWidth={3}
              path="header.beginningOfMessage.documentNumber"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.beginningOfMessage.documentNumber'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.beginningOfMessage.documentNumber"
                  name="header.beginningOfMessage.documentNumber"
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.referencedDocuments.masterBillOfLading.referenceNumber"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.referencedDocuments.masterBillOfLading.referenceNumber'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.referencedDocuments.masterBillOfLading.referenceNumber"
                  name="header.referencedDocuments.masterBillOfLading.referenceNumber"
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.dates.documentDate.dateTime"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.dates.documentDate.dateTime'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.dates.documentDate.dateTime"
                  name="header.dates.documentDate.dateTime"
                  stripTimezone
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.dates.deliveryDate.dateTime"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.dates.deliveryDate.dateTime'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.dates.deliveryDate.dateTime"
                  name="header.dates.deliveryDate.dateTime"
                  stripTimezone
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.dates.estimatedArrivalDate.dateTime"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.dates.estimatedArrivalDate.dateTime'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.dates.estimatedArrivalDate.dateTime"
                  name="header.dates.estimatedArrivalDate.dateTime"
                  stripTimezone
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.deliveryHeader.carrierID"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.deliveryHeader.carrierID'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.deliveryHeader.carrierID"
                  name="header.deliveryHeader.carrierID"
                  size="mini"
                />
              )}
            />

            <DesadvInput
              columnWidth={3}
              path="header.deliveryHeader.trackingNumbers"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.deliveryHeader.trackingNumbers'
              )}
              render={(props) => (
                <InputArrayValueElementsListComponent
                  inputArrayName="header.deliveryHeader.trackingNumbers"
                  inputName="trackingNumber"
                  path="header.deliveryHeader.trackingNumbers"
                  {...props}
                />
              )}
            />

            <DesadvInput
              columnWidth={3}
              path="header.deliveryHeader.termsOfDeliveryOrTransport"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.deliveryHeader.termsOfDeliveryOrTransport'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.deliveryHeader.termsOfDeliveryOrTransport"
                  name="header.deliveryHeader.termsOfDeliveryOrTransport"
                  size="mini"
                />
              )}
            />

            <DesadvInput
              columnWidth={3}
              path="header.countryOfOrigin.countryName"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.countryOfOrigin.countryName'
              )}
              render={(props) => (
                <HeaderCountryOfOriginComponent
                  {...props}
                  documentExchange={documentExchange}
                  path="header.countryOfOrigin.countryName"
                  name="header.countryOfOrigin.countryName"
                  formContext={formContext}
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.headerComment"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.headerComment'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.headerComment"
                  name="header.headerComment"
                  size="mini"
                />
              )}
            />

            <DesadvInput
              columnWidth={3}
              path="header.businessEntities.shipFrom.city"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.businessEntities.shipFrom.city'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.businessEntities.shipFrom.city"
                  name="header.businessEntities.shipFrom.city"
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.businessEntities.shipFrom.street"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.businessEntities.shipFrom.street'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.businessEntities.shipFrom.street"
                  name="header.businessEntities.shipFrom.street"
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="header.businessEntities.shipFrom.country.countryName"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.businessEntities.shipFrom.country.countryName'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.businessEntities.shipFrom.country.countryName"
                  name="header.businessEntities.shipFrom.country.countryName"
                  size="mini"
                />
              )}
            />

            <DesadvInput
              columnWidth={3}
              path="header.businessEntities.shipFrom.postCode"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'header.businessEntities.shipFrom.postCode'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="header.businessEntities.shipFrom.postCode"
                  name="header.businessEntities.shipFrom.postCode"
                  size="mini"
                />
              )}
            />
            <DesadvInput
              columnWidth={3}
              path="details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages"
              formFieldConfig={formFieldConfig}
              exposedValues={exposedValues}
              shouldNotRender={isFieldReadOnly(
                formFieldConfig,
                'details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages'
              )}
              render={(props) => (
                <Input
                  {...props}
                  path="details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages"
                  name="details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages"
                  size="mini"
                />
              )}
            />
          </ItemsContainer>
        </React.Fragment>
      )}
    </Form>
  );
};

EditForm.defaultProps = {};

EditForm.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  documentExchange: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default EditForm;
