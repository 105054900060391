/* eslint-disable react/no-array-index-key */
import React, {Component} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Segment,
} from 'semantic-ui-react';
import styled from 'styled-components';
import {
  Form,
  FormGroup,
  Input,
  InputArray,
  Submit,
  dynID,
} from '@ecosio/pathform';
import {FormSpy} from 'react-final-form';
import isEqual from 'react-fast-compare';
import {FormattedMessage, injectIntl} from 'react-intl';
import {intlShape, toast} from '@ecosio/components';

import {
  PACKAGING_NUMBER_INPUT_SSCC,
  PACKAGING_NUMBER_INPUT_UUID,
} from '../../../../../../../constants';
import './CpsList.css';
import extractItemConfig from '../../../../Utils/helpers';
import {cloneCps, newCps, newLineItem} from '../../../../Utils/CreateUtils';
import {panelLightGrey} from '../../../CommonComponents/Create/DesAdvColors';
import {STANDARD_TOAST_DURATION} from '../../../../../../../helpers/constants';
import CpsSelection from './CpsSelection';

export const CpsListPanelSegment = styled(Segment)`
   {
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : panelLightGrey} !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
`;

const canQuantize = (item) => {
  let classification = [];

  if (item.props.classifications) {
    classification = item.props.classifications.filter(
      (i) => i.classificationSchema === 'SERIALNUMBER_MANDATORY'
    );
  }

  if (classification.length > 1) {
    console.warn(
      'canQuantize() found more than 1 SERIALNUMBER_MANDATORY Field'
    );
  }
  return classification.find((i) => i.content.indexOf(true) > -1);
};

/**
 * TODO: rename - this is not rendering nves
 */
const IterateNve = ({path, num, intl}) => {
  let i = 0;
  const arr = [];
  while (i < num) {
    // TODO: why path txt ?
    arr.push(
      <Input
        placeholder={intl.formatMessage({id: 'DESADV_SN_PLACEHOLDER'})}
        key={`serialnumber_${path}_${i}`}
        name={`${path}.props.values[${i}].serialNumbers`}
        path="txt"
      />
    );
    i++;
  }
  return arr;
};

IterateNve.propTypes = {
  num: PropTypes.number,
  path: PropTypes.string,
};

const CPSErrorState = (errorStates) => {
  const history = useHistory();

  let headerMessage = 'Unknown error';
  let subHeaderMessage = 'Unknown error';

  // Currently, only ssccGenerationFailed error is supported
  if (errorStates.errorStates[0]?.ssccGenerationFailed) {
    headerMessage = 'SSCC_GENERATION_ERROR_1';
    subHeaderMessage = 'SSCC_GENERATION_ERROR_2';
  }

  const renderErrorHandlingButton = () => {
    return errorStates.errorStates[0]?.ssccGenerationFailed ? (
      <Button
        data-spec="webedi_settings_link"
        type="button"
        color="blue"
        onClick={() => {
          history.push('/settings');
        }}>
        <FormattedMessage id="WEBEDI_SETTINGS_TITLE" />
      </Button>
    ) : (
      ''
    );
  };

  return (
    <React.Fragment>
      <Grid
        centered
        container
        columns={1}
        verticalAlign="middle"
        className="_si_elist_le">
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header as="h2" icon textAlign="center">
              <Icon name="barcode" color="grey" circular />
              <Header.Content>
                <FormattedMessage id={headerMessage} />
              </Header.Content>
              <Header.Subheader>
                <FormattedMessage id={subHeaderMessage} />
              </Header.Subheader>
            </Header>
            {renderErrorHandlingButton()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

const EmptyLineItemState = () => (
  <React.Fragment>
    <CpsListPanelSegment>
      <Grid
        centered
        container
        columns={1}
        verticalAlign="middle"
        className="_si_elist_le">
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header as="h2" icon textAlign="center">
              <Icon name="barcode" color="grey" circular />
              <Header.Content>
                <FormattedMessage id="DESADV_EMPTY_LIN" />
              </Header.Content>
              <Header.Subheader>
                <FormattedMessage id="DESADV_EMPTY_LIN_BODY" />
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CpsListPanelSegment>
  </React.Fragment>
);

const LineItems = injectIntl(
  ({path, setDirty, intl, ordersDataTemp = {}, config, formCtx}) => {
    return (
      <InputArray
        name={path}
        render={(ctx) => {
          if (!ctx.fields.length) {
            return <EmptyLineItemState />;
          }
          return ctx.fields.map((name, key) => {
            const increment = ctx.fields.value[key].props.orderedQuantity;
            const lineItem = ctx.fields.value[key];
            const tempLineItem =
              ordersDataTemp?.orderLineItems[lineItem.props.positionNumber];

            return (
              <CpsListPanelSegment
                key={`LineItems_${name}_${key}`}
                backgroundColor="white">
                <div style={{float: 'left'}}>
                  <div>
                    <strong style={{fontSize: '16px'}}>
                      {/**
                       * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/484
                       */}
                      {config?.desadvOverDelivery === false &&
                      tempLineItem?.remainingQuantityForPosition < 0 ? (
                        <div>
                          <span style={{color: 'red'}}>
                            {intl.formatMessage({
                              id: 'DESADV_OVER_DELIVERY_POSITION_ERROR',
                            })}
                          </span>
                        </div>
                      ) : null}
                      {/**
                       * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/487
                       * TODO: should use <Content/> from path-form
                       */}
                      <span
                        style={{
                          color:
                            config?.desadvOverDelivery === false &&
                            tempLineItem?.remainingQuantityForPosition < 0
                              ? 'red'
                              : '',
                        }}>
                        Pos: {lineItem.props.positionNumber}
                        {lineItem.props.customersItemMaterialNumber}
                        {' / '}
                        {lineItem.props.suppliersItemMaterialNumber}
                        {' / '}
                        {lineItem.props.manufacturersArticleNumber}
                      </span>
                      <br />
                      GTIN: {lineItem.props.gtin}
                    </strong>
                  </div>
                  <div>{lineItem.props.itemDescription}</div>
                </div>
                <div className="le_wrap">
                  <Input
                    name={`${name}.props.classifications[0].content[0]`}
                    path="props.classifications.content"
                  />
                  <Button
                    data-spec="remove-lin"
                    type="button"
                    color="red"
                    icon
                    onClick={async () => {
                      ctx.fields.remove(key);
                      setDirty(true);
                      await formCtx.form.submit();
                    }}
                    size="mini">
                    <Icon name="trash" />
                  </Button>
                </div>
                <br style={{clear: 'both'}} />
                <FormGroup widths={3}>
                  <Input
                    name={`${name}.props.intrastatNumber`}
                    path="props.intrastatNumber"
                  />
                  <Input
                    name={`${name}.props.countryOfOrigin.countryCode`}
                    path="props.countryOfOrigin.countryCode"
                    search
                  />
                  <Input
                    name={`${name}.props.orderedQuantity`}
                    path="props.orderedQuantity"
                  />
                  <Input
                    name={`${name}.props.measureUnitQualifier`}
                    path="props.measureUnitQualifier"
                    required
                  />
                  <Input
                    name={`${name}.props.batchNumber`}
                    path="props.batchNumber"
                  />
                </FormGroup>
                {canQuantize(lineItem) ? (
                  <div className="snr_field">
                    <FormattedMessage id="DESADV_SERIALNUMBERS" />
                    <Divider />
                    <IterateNve num={increment} path={name} intl={intl} />
                  </div>
                ) : null}
              </CpsListPanelSegment>
            );
          });
        }}
      />
    );
  }
);

LineItems.propTypes = {
  path: PropTypes.string,
};

const getStaticFieldInitialValue = (usePath, cpsFieldValues) => {
  if (
    usePath === 'props.nve' &&
    (cpsFieldValues?.packagingNumber?.type === PACKAGING_NUMBER_INPUT_UUID ||
      cpsFieldValues?.packagingNumber?.type === PACKAGING_NUMBER_INPUT_SSCC)
  ) {
    return cpsFieldValues.packagingNumber.value;
  }
  return null;
};

const getNVETypeExtension = (itemPath, cpsFieldValues) => {
  if (itemPath === 'props.nve') {
    if (cpsFieldValues?.packagingNumber?.type === PACKAGING_NUMBER_INPUT_UUID) {
      return '_uuid';
    } else if (
      cpsFieldValues?.packagingNumber?.type === PACKAGING_NUMBER_INPUT_SSCC
    ) {
      return '_sscc';
    }
  }
  return '';
};

const StaticFields = ({path, itemConfig, values}) => {
  const pkgType = itemConfig?.packagingInformation?.packageTypeCustomer;

  const headerKey = pkgType
    ? `DESADV_PKG_TYPE_${pkgType}`
    : 'DESADV_PKG_GENERIC';

  return (
    <>
      <Header as="h">
        <FormattedMessage id={headerKey} />
      </Header>
      <FormGroup>
        {itemConfig.fields.map((itemPath) => {
          const usePath = path ? `${path}.${itemPath}` : `${itemPath}`;
          const itemPathWithValidator =
            itemPath + getNVETypeExtension(itemPath, values?.cpsFieldValues);
          return (
            <Input
              key={`StaticField_${path}_${itemPath}`}
              name={usePath}
              path={itemPathWithValidator}
              initialValue={getStaticFieldInitialValue(
                usePath,
                values?.cpsFieldValues
              )}
            />
          );
        })}
      </FormGroup>
    </>
  );
};

StaticFields.propTypes = {
  itemConfig: PropTypes.any,
  path: PropTypes.string,
  values: PropTypes.object,
};

const RecursiveInput = ({path, push, config, onPushButton, setDirty}) => {
  const colors = ['orange', 'violet', 'red'];

  return (
    <>
      <InputArray
        name={path}
        render={(ctx) => {
          return ctx.fields.map((cpsname, cpskey) => {
            const confID = ctx.fields.value[cpskey].configID;
            const depth = cpsname.split('.').length;
            const color = colors[depth - 1];
            const itemConfig = extractItemConfig(config, confID);
            return (
              <CpsListPanelSegment key={`RecursiveInput_${cpsname}_${cpskey}`}>
                <Header as="h">
                  <FormattedMessage id={dynID(itemConfig?.label)} />
                </Header>
                <div style={{float: 'right', marginBottom: '15px'}}>
                  <Button
                    data-spec="pkg-copy"
                    type="button"
                    color="grey"
                    icon
                    onClick={async () => {
                      const clonedCps = await cloneCps(
                        ctx.fields.value[cpskey]
                      );
                      ctx.fields.push(clonedCps);
                      setDirty(true);
                    }}
                    size="mini">
                    <Icon name="copy" />
                  </Button>
                  <Button
                    data-spec="pkg-trash"
                    type="button"
                    color="red"
                    icon
                    onClick={() => {
                      ctx.fields.remove(cpskey);
                      setDirty(true);
                    }}
                    size="mini">
                    <Icon name="trash" />
                  </Button>
                </div>
                <br style={{clear: 'both'}} />
                <StaticFields itemConfig={itemConfig} path={`${cpsname}`} />
                {itemConfig.isParent ? (
                  <RecursiveInput
                    setDirty={setDirty}
                    onPushButton={onPushButton}
                    path={`${cpsname}.cps`}
                    push={push}
                    config={config}
                  />
                ) : (
                  <LineItems
                    setDirty={setDirty}
                    path={cpsname ? `${cpsname}.lineitems` : 'lineitems'}
                    color={color}
                  />
                )}
                <PushButton
                  onPushButton={onPushButton}
                  itemConfig={itemConfig}
                  push={push}
                  path={`${cpsname}`}
                  isParent={itemConfig.isParent}
                  color={color}
                />
              </CpsListPanelSegment>
            );
          });
        }}
      />
    </>
  );
};

RecursiveInput.propTypes = {
  config: PropTypes.object,
  onPushButton: PropTypes.func,
  path: PropTypes.string,
  push: PropTypes.func,
  setDirty: PropTypes.func,
};

const PushButton = ({path, itemConfig, push, onPushButton, color, ctx}) => {
  const ctxLineItems = ctx?.values?.lineitems || [];

  const onClick = async (e) => {
    if (ctxLineItems.length) {
      //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/483
      await ctx?.handleSubmit(e);
    }

    await onPushButton(e, {isParent: itemConfig.isParent, push, path});
  };

  const spec = itemConfig.isParent ? 'add-pkg' : 'add-lin';
  return (
    <Button type="button" onClick={onClick} color={color} data-spec={spec}>
      {itemConfig.isParent ? (
        <FormattedMessage id="BUTTON_ADD_PACKAGE" />
      ) : (
        <FormattedMessage id="BUTTON_ADD_LINE_ITEM" />
      )}
    </Button>
  );
};

PushButton.propTypes = {
  color: PropTypes.string,
  itemConfig: PropTypes.object,
  onPushButton: PropTypes.func,
  path: PropTypes.string,
  push: PropTypes.func,
};

class CpsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      path: '',
      isParent: false,
    };
  }

  onModalCancel = () =>
    this.setState({
      modalOpen: false,
    });

  onModalSave = async (e, {push, value}) => {
    if (this.state.isParent) {
      push(this.state.path, await newCps(this.props.config.cpsConfigs, value));
    } else {
      const item = this.props.data.ordersData.ordersLineItems.find(
        (i) => i.positionNumber === value
      );
      push(this.state.path, newLineItem(item));
    }

    this.setState({modalOpen: false});
    this.props.setDirty(true);
  };

  onPushButton = (e, {isParent, path}) => {
    const {config, desadvOverDeliveryReached, lineitemOptions} = this.props;
    const openLineItemModal =
      config?.desadvOverDelivery === false
        ? desadvOverDeliveryReached !== true
        : true;

    if (isParent) {
      this.setState({
        modalOpen: true,
        path: path ? `${path}.cps` : 'cps',
        isParent: isParent,
      });
    } else {
      this.setState({
        modalOpen: openLineItemModal && lineitemOptions.length !== 0,
        path: path ? `${path}.lineitems` : 'lineitems',
        isParent: isParent,
      });
    }
  };

  onChangeFormSpy = ({values}) => {
    const isDirty = !isEqual(values, this.props.item);
    this.props.setDirty(isDirty);
    /*
    // This is dangerous - we need to take validation into consideration
    // before auto-saving
    if (isDirty) {
      this.props.onSave(values);
    }
     */
  };

  getCpsOptions = (cpsOptions, itemConfig) => {
    if (itemConfig?.isParent && itemConfig?.childCpsConfigUuids?.length) {
      return cpsOptions.filter((cpsOption) =>
        itemConfig.childCpsConfigUuids.includes(cpsOption.value)
      );
    } else {
      return cpsOptions;
    }
  };

  render() {
    const {
      config,
      item,
      onSave,
      lineitemOptions,
      cpsOptions,
      setDirty,
      hasLineItems,
      desadvOverDeliveryReached = false,
      ordersDataTemp = {},
      rootDirty,
    } = this.props;
    if (
      lineitemOptions.length === 0 &&
      !rootDirty &&
      !desadvOverDeliveryReached
    ) {
      //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/482
      toast({
        title: this.props.intl.formatMessage({
          id: 'DESADV_ALL_POSITIONS_DELIVERED_TITLE',
        }),
        description: this.props.intl.formatMessage({
          id: 'DESADV_ALL_POSITIONS_DELIVERED_DESCRIPTION',
        }),
        type: 'success',
        time: STANDARD_TOAST_DURATION,
      });
    }
    return (
      <>
        <Form onSubmit={onSave} fields={config.formFields} initialValues={item}>
          {(ctx) => {
            const itemConfig = extractItemConfig(config, item.configID);

            return (
              <>
                <FormSpy
                  onChange={this.onChangeFormSpy}
                  subscription={{values: true}}
                />
                <CpsSelection
                  open={this.state.modalOpen}
                  lineitemOptions={lineitemOptions}
                  cpsOptions={this.getCpsOptions(cpsOptions, itemConfig)}
                  onSave={this.onModalSave}
                  onCancel={this.onModalCancel}
                  push={ctx.form.mutators.push}
                  isParent={this.state.isParent}
                  intl={this.props.intl}
                  rootDirty={this.props.rootDirty}
                />
                <CpsListPanelSegment>
                  {ctx.values.errorStates?.length === 0 ? (
                    <div>
                      <StaticFields
                        itemConfig={itemConfig}
                        values={ctx.values}
                      />

                      {itemConfig.isParent ? (
                        <RecursiveInput
                          setDirty={setDirty}
                          onPushButton={this.onPushButton}
                          mutators={ctx.form.mutators.push}
                          config={config}
                          path="cps"
                        />
                      ) : (
                        <LineItems
                          path="lineitems"
                          setDirty={setDirty}
                          ordersDataTemp={ordersDataTemp}
                          config={config}
                          formCtx={ctx}
                        />
                      )}
                      <PushButton
                        onPushButton={this.onPushButton}
                        itemConfig={itemConfig}
                        push={ctx.form.mutators.push}
                        color="blue"
                        ctx={ctx}
                      />
                    </div>
                  ) : (
                    <CPSErrorState
                      errorStates={{...ctx.values.errorStates}}></CPSErrorState>
                  )}
                </CpsListPanelSegment>
                <div style={{textAlign: 'center'}}>
                  <Submit
                    data-spec="pkg-submit"
                    color={
                      this.props.rootDirty ||
                      !hasLineItems ||
                      desadvOverDeliveryReached
                        ? 'red'
                        : 'green'
                    }
                    content={this.props.intl.formatMessage({
                      id: 'DESADV_CPS_SUBMIT_BUTTON',
                    })}
                  />
                  <div style={{clear: 'both'}} />
                  {
                    this.props.rootDirty ? (
                      <Label color="black" pointing>
                        <FormattedMessage id="DESADV_CPS_FORM_DIRTY" />
                      </Label>
                    ) : null
                    //                    <Label color="green" pointing>
                    //                      <FormattedMessage id="DESADV_CPS_FORM_NOT_DIRTY" />
                    //                    </Label>
                  }
                </div>
              </>
            );
          }}
        </Form>
      </>
    );
  }
}

CpsList.propTypes = {
  config: PropTypes.shape({
    cpsConfigs: PropTypes.arrayOf(
      PropTypes.shape({
        packageType: PropTypes.number,
        configID: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string,
        depth: PropTypes.number,
        fields: PropTypes.arrayOf(PropTypes.string),
        packagingInformation: PropTypes.shape({
          packageTypeCustomer: PropTypes.string,
          shippingMarkType: PropTypes.string,
        }),
        filterPath: PropTypes.any,
        isParent: PropTypes.bool,
      })
    ),
  }),
  cpsOptions: PropTypes.array,
  data: PropTypes.shape({
    ordersData: PropTypes.shape({
      ordersLineItems: PropTypes.arrayOf(
        PropTypes.shape({
          customersItemMaterialNumber: PropTypes.string,
          itemDescription: PropTypes.string,
          orderedQuantity: PropTypes.number,
          positionNumber: PropTypes.number,
          suppliersItemMaterialNumber: PropTypes.string,
          classifications: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.array,
              classificationSchema: PropTypes.string,
            })
          ),
        })
      ),
    }),
  }),
  item: PropTypes.any,
  lineitemOptions: PropTypes.any,
  onSave: PropTypes.func,
  setDirty: PropTypes.func,
  hasLineItems: PropTypes.bool,
  rootDirty: PropTypes.bool,
  intl: intlShape,
};

export default injectIntl(CpsList);
