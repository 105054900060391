/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Header,
  Icon,
  Form,
  Input as SemanticInput,
  Dropdown,
} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {InputArray, ErrorLabel} from '@ecosio/pathform';
import {Field} from 'react-final-form';
import {ABSOLUTE, ALLOWANCE, CHARGE, PERCENTAGE} from '../../../../constants';

const AddButton = ({push}) => (
  <Button
    compact
    icon
    type="button"
    size="tiny"
    primary
    onClick={() => push('allowancesAndCharges', newAllowanceAndCharge())}>
    <Icon name="add" />
  </Button>
);

AddButton.propTypes = {
  push: PropTypes.func,
};

const newAllowanceAndCharge = () => ({
  allowanceChargeQualifier: ALLOWANCE,
  allowanceChargeTypeCoded: PERCENTAGE,
  percentageOfAllowanceOrCharge: null,
});

const percentageOrAbsolute = (currency) => [
  {key: ABSOLUTE, text: currency, value: ABSOLUTE},
  {key: PERCENTAGE, text: '%', value: PERCENTAGE},
];

const buildAllowanceOrChargeOptions = (lineItemConfig) => {
  /* Dont do something like this - it doesnt work
   [lineItemConfig.allowAddingAllowances && {key, text, value}]
  */

  const ALLOWANCE_OPTION = {
    key: ALLOWANCE,
    text: <FormattedMessage id="GENERAL_ALLOWANCE" />,
    value: ALLOWANCE,
  };

  const CHARGE_OPTION = {
    key: CHARGE,
    text: <FormattedMessage id="GENERAL_CHARGE" />,
    value: CHARGE,
  };

  const ITEMS = [];
  lineItemConfig.allowAddingAllowances && ITEMS.push(ALLOWANCE_OPTION);
  lineItemConfig.allowAddingCharges && ITEMS.push(CHARGE_OPTION);

  return ITEMS;
};

const AllowanceOrChargeLabel = ({item, currency}) => {
  return (
    <label
      style={{fontWeight: 'normal', fontStyle: 'italic'}}
      className="_si_aoc_label"
      data-spec="aoc-label">
      {item.allowanceChargeQualifier === ALLOWANCE ? (
        <FormattedMessage
          id="LE_ALLOWANCE_AMOUNT"
          values={{amount: item.allowanceChargeAmount}}
        />
      ) : (
        <FormattedMessage
          id="LE_CHARGE_AMOUNT"
          values={{amount: item.allowanceChargeAmount}}
        />
      )}{' '}
      {currency}
    </label>
  );
};

AllowanceOrChargeLabel.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string.isRequired,
};

const validateLinAOC = (aocValue, lineItemAmount, allowanceChargeTypeCoded) => {
  const invalid =
    aocValue > 0 && lineItemAmount < 0 && allowanceChargeTypeCoded === ABSOLUTE;
  return invalid ? 'LIN_AOC_NEGATIVE_LIN_AMOUNT_VALIDATION' : undefined;
};

const AllowanceAndCharge = ({
  fields,
  name,
  index,
  currency,
  lineItemConfig,
  lineItemAmount,
}) => {
  const canRemove = (item) => {
    return (
      (item.allowanceChargeQualifier === ALLOWANCE &&
        lineItemConfig.allowRemovingAllowances) ||
      (item.allowanceChargeQualifier === CHARGE &&
        lineItemConfig.allowRemovingCharges)
    );
  };

  const item = fields.value[index];
  const fieldName =
    item.allowanceChargeTypeCoded === PERCENTAGE
      ? `${name}.percentageOfAllowanceOrCharge`
      : `${name}.allowanceChargeAmount`;

  return (
    <Grid.Row className="condensed _si_aoc_wrap" data-spec="aoc-wrap">
      <Grid.Column width={12}>
        <Form.Field>
          <AllowanceOrChargeLabel item={item} currency={currency} />
          <Field
            name={fieldName}
            validate={(aocValue) =>
              validateLinAOC(
                aocValue,
                lineItemAmount,
                item?.allowanceChargeTypeCoded
              )
            }
            render={({input, meta, ...rest}) => (
              <Fragment>
                <SemanticInput
                  {...input}
                  {...rest}
                  type="number"
                  step="0.01"
                  labelPosition="right"
                  size="mini"
                  required
                  placeholder="Allowance or Charge">
                  <Field
                    name={`${name}.allowanceChargeQualifier`}
                    render={({input, ...rest}) => {
                      return (
                        <Dropdown
                          {...input}
                          {...rest}
                          onChange={(e, {value}) => {
                            e.preventDefault();
                            input.onChange(value);
                          }}
                          options={buildAllowanceOrChargeOptions(
                            lineItemConfig
                          )}
                          className="label _si_aoc_sel"
                        />
                      );
                    }}
                  />

                  <input />

                  <Field
                    name={`${name}.allowanceChargeTypeCoded`}
                    render={({input, ...rest}) => {
                      return (
                        <Dropdown
                          {...input}
                          {...rest}
                          onChange={(e, {value}) => {
                            e.preventDefault();
                            input.onChange(value);
                          }}
                          options={percentageOrAbsolute(currency)}
                          className="label _si_aoc_typ"
                        />
                      );
                    }}
                  />
                </SemanticInput>
                {(meta.error || meta.submitError) && meta.touched && (
                  <ErrorLabel error={meta.error || meta.submitError} />
                )}
              </Fragment>
            )}
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column width={4} style={{paddingTop: 24}} textAlign="right">
        {canRemove(item) ? (
          <Button
            color="red"
            compact
            icon
            size="tiny"
            type="button"
            onClick={() => {
              fields.remove(index);
            }}>
            <Icon name="trash" />
          </Button>
        ) : null}
      </Grid.Column>
    </Grid.Row>
  );
};

AllowanceAndCharge.propTypes = {
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  lineItemConfig: PropTypes.object,
  lineItemAmount: PropTypes.number.isRequired,
};

const NoAllowances = ({push, allowAdd}) => {
  return allowAdd ? (
    <Grid.Row>
      <Grid.Column>
        <FormattedMessage id="LE_AOC_EMPTY_STATE" />
        <br />
        <FormattedMessage
          id="LE_AOC_EMPTY_STATE_CLICK"
          values={{button: <AddButton push={push} />}}
        />
      </Grid.Column>
    </Grid.Row>
  ) : null;
};

NoAllowances.propTypes = {
  push: PropTypes.func,
  allowAdd: PropTypes.bool.isRequired,
};

const AllowancesAndCharges = ({
  push,
  currency,
  lineItemConfig,
  lineItemAmount,
}) => {
  const hideAddButton =
    !lineItemConfig.allowAddingCharges && !lineItemConfig.allowAddingAllowances;

  return (
    <Grid className="_si_le_edit_aoc" data-spec="aoc-grid">
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h3" textAlign="left">
            <FormattedMessage id="GENERAL_ALLOWANCES_AND_CHARGES" />
          </Header>
        </Grid.Column>
        {!hideAddButton ? (
          <Grid.Column textAlign="right">
            <AddButton push={push} />
          </Grid.Column>
        ) : null}
      </Grid.Row>
      <InputArray name="allowancesAndCharges">
        {({fields}) =>
          fields.length ? (
            <React.Fragment>
              {fields.map((name, index) => (
                <AllowanceAndCharge
                  lineItemConfig={lineItemConfig}
                  currency={currency}
                  key={index}
                  fields={fields}
                  name={name}
                  index={index}
                  lineItemAmount={lineItemAmount}
                />
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NoAllowances push={push} allowAdd={!hideAddButton} />

              {hideAddButton ? (
                <FormattedMessage id="LE_AOC_EMPTY_STATE" />
              ) : null}
            </React.Fragment>
          )
        }
      </InputArray>
    </Grid>
  );
};

AllowancesAndCharges.propTypes = {
  push: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  lineItemConfig: PropTypes.object,
  lineItemAmount: PropTypes.number.isRequired,
};

export default AllowancesAndCharges;
