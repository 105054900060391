import {useSelector} from 'react-redux';
import {loadTheme} from '../utils';
import {ECOSIO_THEME} from '../constants';

export const useThemeSpecificColors = () => {
  let theme = useSelector(({config}) => config?.userConfig?.theme);

  if (!theme) {
    console.info('theme is undefined, fallback to ecosio theme');
    theme = ECOSIO_THEME;
  }

  return loadTheme(theme);
};
