import PropTypes from 'prop-types';
import axios from 'axios';
import {
  PACKAGING_NUMBER_INPUT_SSCC,
  PACKAGING_NUMBER_INPUT_UUID,
} from '../../../../constants';

const genID = () => Math.random().toString(36).substring(3);

export const newLineItem = (item) => {
  return {
    props: {
      ...item,
      values: [],
    },
    // Adding as meta information, outside of props, so that it isnt mapped to document data during desadv submit
    meta: {
      originalOrderedQuantity: item.orderedQuantity,
      originalMeasureUnitQualifier: item.measureUnitQualifier,
    },
  };
};

export const cloneCps = async (wantedCps) => {
  let generatedNVE = {};
  const creationErrors = [];

  // cps to be cloned has error with generated NVE number -> forward error to the new cps and do not try generating number
  if (wantedCps.errorStates?.length > 0) {
    const errorStates = wantedCps?.errorStates || [];
    creationErrors.push(...errorStates);
  } else {
    // cps to be cloned has no error with generated NVE number -> generate new number and attach it to the new cps
    const packageNumber = await generateDesadvPackageNumbers(
      wantedCps.cpsFieldValues?.packagingNumber?.type,
      1
    );

    // currently (18.5.2021) only sscc generated nves should throw error in generation fails
    if (
      wantedCps?.cpsFieldValues?.packagingNumber?.type ===
        PACKAGING_NUMBER_INPUT_SSCC &&
      (!Array.isArray(packageNumber?.packagingNumber) ||
        (!Array.isArray(packageNumber?.packagingNumber) &&
          !packageNumber?.packagingNumber.length))
    ) {
      creationErrors.push({ssccGenerationFailed: true});
    } else {
      generatedNVE = packageNumber.packagingNumber[0];
    }
  }
  return {
    ...wantedCps,
    group: genID(),
    cpsFieldValues: {
      packagingNumber: generatedNVE,
    },
    errorStates: creationErrors,
  };
};

export const newCps = async (cpsConfig, configID) => {
  const found = cpsConfig.find((item) => item.configID === configID);

  const packageNumber = await generateDesadvPackageNumbers(
    found?.packagingNumberInput,
    1
  );

  let generatedNVE = {};
  const creationErrors = [];
  // currently (18.5.2021) only sscc generated nves should throw error in generation fails
  if (
    found?.packagingNumberInput === PACKAGING_NUMBER_INPUT_SSCC &&
    (!Array.isArray(packageNumber?.packagingNumber) ||
      (!Array.isArray(packageNumber?.packagingNumber) &&
        !packageNumber?.packagingNumber.length))
  ) {
    creationErrors.push({ssccGenerationFailed: true});
  } else {
    generatedNVE = packageNumber.packagingNumber[0];
  }

  return {
    group: genID(),
    configID: found.configID,
    icon: found.icon,
    props: {
      ...cpsConfig.fields,
      packagingInformation: found.packagingInformation,
    },
    cps: [],
    lineitems: [],
    cpsFieldValues: {
      packagingNumber: generatedNVE,
    },
    errorStates: creationErrors,
  };
};

export const generateDesadvPackageNumbers = async (
  generatorType,
  numbersAmount
) => {
  const retObj = {
    packagingNumber: {
      type: generatorType,
    },
  };
  if (
    generatorType === PACKAGING_NUMBER_INPUT_SSCC ||
    generatorType === PACKAGING_NUMBER_INPUT_UUID
  ) {
    const response = await axios.get(
      `/api/desadv/packaging_number/generate/${generatorType}/amount/${numbersAmount}`
    );

    retObj.packagingNumber = response.data;
  }
  return retObj;
};

export const generatedPackageNumberShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});
