import PropTypes from 'prop-types';

export const deliveryLineItemShape = PropTypes.shape({
  deliveryLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      positionNumber: PropTypes.string.isRequired,
      dispatchQuantity: PropTypes.shape({
        unit: PropTypes.string,
        value: PropTypes.string,
      }),
      serialNumbers: PropTypes.arrayOf(PropTypes.string),
    })
  ),
});
