/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {InputArray} from '@ecosio/pathform';
import styled from 'styled-components';
import {FormSpy} from 'react-final-form';
import PropTypes from 'prop-types';
import DeleteAndDuplicateComponent from '../DeleteAndDuplicateComponent';
import {pageConfigShape} from '../../../../../../../shapes/scenarios';
import LineItemViewFields, {
  LineItemReadFieldsFromEditForm,
} from './ViewFields/LineItemViewFields';
import LineItemEditFields from './EditFormFields/LineItemEditFields';

const DeliveryLineItemContainer = styled.div`
  margin-bottom: 10px;
  ${(props) => (props?.opacity ? `opacity:${props.opacity}` : '')}
`;

export const ItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 9px 0px 9px;
  ${(props) =>
    props?.backgroundColor ? `background:${props.backgroundColor}` : ''}
`;

const ItemViewFieldsContainer = styled.div`
  display: flex;
`;

const DesadvLineItems = ({
  pageConfig,
  initialValues,
  partialDeliveryAllowed,
  sscc,
  showReadonly,
  exposedValues,
}) => {
  const [delisOpacity, setDelisOpacity] = useState([]);
  return (
    <FormSpy subscription={{values: true}}>
      {(formContext) => (
        <InputArray
          name="deliveryLineItems"
          path="deliveryLineItems"
          render={(ctx) => {
            if (!ctx.fields.length) {
              console.warn('No desadv lineitems');
              return null;
            }

            return ctx.fields.map((name, key) => {
              return (
                <DeliveryLineItemContainer
                  key={key}
                  opacity={delisOpacity[key]}
                  data-spec={`deliveryLineItem-${key}`}>
                  <ItemViewFieldsContainer>
                    <LineItemViewFields
                      index={key}
                      formFieldConfig={pageConfig?.formFields || {}}
                      initialValues={formContext?.values?.deliveryLineItems}
                    />
                    {!showReadonly && (
                      <DeleteAndDuplicateComponent
                        pageConfig={pageConfig}
                        formContext={formContext}
                        setDelisOpacity={setDelisOpacity}
                        delisOpacity={delisOpacity}
                        index={key}
                      />
                    )}
                  </ItemViewFieldsContainer>

                  {!showReadonly ? (
                    <LineItemEditFields
                      index={key}
                      formFieldConfig={pageConfig?.formFields}
                      partialDeliveryAllowed={partialDeliveryAllowed}
                      formContext={formContext}
                      initialValues={initialValues}
                      exposedValues={exposedValues}
                      setDelisOpacity={setDelisOpacity}
                      delisOpacity={delisOpacity}
                      sscc={sscc}
                    />
                  ) : (
                    <LineItemReadFieldsFromEditForm
                      index={key}
                      formFieldConfig={pageConfig?.formFields || {}}
                      partialDeliveryAllowed={partialDeliveryAllowed}
                      initialValues={initialValues}
                      sscc={sscc}
                    />
                  )}
                </DeliveryLineItemContainer>
              );
            });
          }}
        />
      )}
    </FormSpy>
  );
};

DesadvLineItems.defaultProps = {
  exposedValues: {},
};

DesadvLineItems.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  initialValues: PropTypes.object.isRequired,
  partialDeliveryAllowed: PropTypes.bool,
  sscc: PropTypes.bool,
  showReadonly: PropTypes.bool,
  exposedValues: PropTypes.object,
};

export default DesadvLineItems;
