import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {flowRight} from 'lodash';
import {scenarioShape} from '../../shapes/scenarios';
import withScenario from '../hoc/withScenario';
import {fetchEnvelope} from '../../reducers/envelope';

const mapStateToProps = ({envelope}) => ({envelope});

const mapDispatchToProps = (dispatch) => ({
  fetchEnvelope: (uuid, scenario) => dispatch(fetchEnvelope(uuid, scenario)),
});

/**
 * Sole purpose of this component is to have an entry point for an envelope
 * and redirect to the appropriate document (which differs from scenario
 * to scenario).
 */
class EnvelopeComponent extends React.Component {
  componentDidMount() {
    const {match, scenario} = this.props;
    const uuid = match.params.envelopeUuid;

    this.props.fetchEnvelope(uuid, scenario);
  }

  render() {
    const {scenario, envelope, location} = this.props;
    if (envelope.fetching) {
      return <Loader active />;
    }
    // if we have a merger, we show the merged document first
    if (scenario.documentMerger) {
      return (
        <Redirect
          to={{
            pathname: `${location.pathname}/doc/merged`,
            state: this.props?.location?.state,
          }}
        />
      );
      // otherwise just load the latest document
    } else {
      if (envelope.data.documents.length) {
        const firstDoc = envelope.data.selectableDocuments[0].uuid;

        // TODO error handling
        return (
          <Redirect
            to={{
              pathname: `${location.pathname}/doc/${firstDoc}`,
              state: this.props?.location?.state,
            }}
          />
        );
      } else {
        return <FormattedMessage id="ENVELOPELIST_LOAD_ERROR" />;
      }
    }
  }
}

EnvelopeComponent.propTypes = {
  scenario: scenarioShape,
  location: PropTypes.object,
  match: PropTypes.object,
  // TODO
  envelope: PropTypes.object,
  fetchEnvelope: PropTypes.func,
};

export default flowRight(
  withScenario(),
  connect(mapStateToProps, mapDispatchToProps)
)(EnvelopeComponent);
