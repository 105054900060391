import React from 'react';
import PropTypes from 'prop-types';
import {Segment, Grid, List} from 'semantic-ui-react';
import {FormatDateTime} from '@ecosio/components';
import {FormattedMessage} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import MessagePlural from '../../../MessagePlural';
import DownloadPdfButton from '../../Download/DownloadPdfButton';
import {scenarioShape} from '../../../../shapes/scenarios';

const DocumentMetaItem = ({
  item,
  onSelect,
  optionalTranslation,
  envelopeUuid,
  scenario,
  index,
  location,
}) => {
  const onClickListItem = () => onSelect.bind(this, item.uuid)();

  let disableItem = false;

  if (item?.uuid && location?.pathname) {
    disableItem = location?.pathname.includes(item?.uuid);
  }

  return (
    <List.Item onClick={onClickListItem} disabled={disableItem}>
      <List.Icon name="linkify" size="large" verticalAlign="middle" />
      <List.Content>
        <List.Content floated="left">
          <List.Header as="a">
            {optionalTranslation ? (
              <FormattedMessage id={dynID(optionalTranslation)} />
            ) : (
              <FormattedMessage id={item.documentTypeId} />
            )}
          </List.Header>
          <List.Description as="a">
            <FormattedMessage id="GENERAL_FROM" />{' '}
            <FormatDateTime stripTimezone={false} dateString={item.createdAt} />
          </List.Description>
        </List.Content>

        {scenario?.downloadOptionOfEachPdfOnEachMetaBox ? (
          <List.Content floated="left">
            <DownloadPdfButton
              disabled={disableItem}
              dataSpec={`metaBox-${item.documentTypeId}-download-${index}`}
              currentDocumentUuid={item.uuid}
              envelopeUuid={envelopeUuid}
              scenario={scenario}
              currentDocumentTypeID={item.documentTypeId}
            />
          </List.Content>
        ) : null}
      </List.Content>
    </List.Item>
  );
};

DocumentMetaItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  optionalTranslation: PropTypes.string,
  envelopeUuid: PropTypes.string.isRequired,
  scenario: scenarioShape,
  index: PropTypes.number,
  location: PropTypes.object.isRequired,
};

const DocumentMetaList = ({
  documents,
  docType,
  onSelect,
  pageConfig,
  envelopeUuid,
  scenario,
  location,
}) => {
  const optionalTranslation = pageConfig?.headerButtonLabel;

  return (
    <Grid.Column>
      <Segment>
        <h3>
          {optionalTranslation ? (
            <MessagePlural
              id={`PLURAL_${dynID(optionalTranslation)}`}
              value={documents.length}
            />
          ) : (
            <MessagePlural id={`PLURAL_${docType}`} value={documents.length} />
          )}
        </h3>
        <List divided relaxed data-spec={`doc-box-${docType}`}>
          {documents.map((item, idx) => (
            <DocumentMetaItem
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              index={idx}
              item={item}
              onSelect={onSelect}
              optionalTranslation={optionalTranslation}
              envelopeUuid={envelopeUuid}
              scenario={scenario}
              location={location}
            />
          ))}
        </List>
      </Segment>
    </Grid.Column>
  );
};

DocumentMetaList.propTypes = {
  documents: PropTypes.array,
  docType: PropTypes.string,
  onSelect: PropTypes.func,
  pageConfig: PropTypes.object.isRequired,
  envelopeUuid: PropTypes.string.isRequired,
  scenario: scenarioShape,
  location: PropTypes.object.isRequired,
};

const DocumentMeta = ({
  metaDocuments,
  onSelect,
  pageConfig,
  envelopeUuid,
  scenario,
  location,
}) => (
  <Grid style={{marginTop: 10}}>
    <a name="metadocuments" id="metadocuments" />
    <Grid.Row columns={Object.keys(metaDocuments).length || 1}>
      {Object.keys(metaDocuments).map((key) => (
        <DocumentMetaList
          key={key}
          documents={metaDocuments[key]}
          docType={key}
          onSelect={onSelect}
          pageConfig={pageConfig}
          envelopeUuid={envelopeUuid}
          scenario={scenario}
          location={location}
        />
      ))}
    </Grid.Row>
  </Grid>
);

DocumentMeta.propTypes = {
  metaDocuments: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  pageConfig: PropTypes.object.isRequired,
  envelopeUuid: PropTypes.string.isRequired,
  scenario: scenarioShape,
  location: PropTypes.object.isRequired,
};

export default DocumentMeta;
