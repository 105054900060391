import {
  INDUSTRY_ORDER,
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER_RESPONSE,
  INDUSTRY_DESADV,
  INDUSTRY_INVOICE,
} from '../shapes/supportedDocTypes';
import {
  getEnvelopesList,
  InitialStatePaging,
  postEnvelopeStatus,
} from './fetcher';

export const TYPES = {
  REQUEST: 'envelopesList/request',
  SUCCESS: 'envelopesList/success',
  ERROR: 'envelopesList/error',
  FORCERELOAD: 'envelopesList/forceReload',
  STATUS_CHANGE_REQUEST_SET_PARAMS:
    'envelopesList/statusChangeRequestSetParams',
  STATUS_CHANGE_REQUEST_PARAMS_RESET:
    'envelopesList/statusChangeRequestParamsReset',
  STATUS_CHANGE_REQUEST_IN_PROCESS:
    'envelopesList/statusChangeRequestInProcess',
  STATUS_CHANGE_SUCCESS: 'envelopesList/statusChangeSuccess',
  STATUS_CHANGE_ERROR: 'envelopesList/statusChangeError',
};

const DEFAULT_DOCUMENT_SORTORDER = [
  INDUSTRY_ORDER,
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER_RESPONSE,
  INDUSTRY_DESADV,
  INDUSTRY_INVOICE,
];

const initState = {
  paging: InitialStatePaging,
  data: [],
  fetching: true,
  customPropertiesConfig: [],
  error: null,
  shouldForceReload: false,
  envelopeUuids: null,
  status: null,
};

const fetchEnvelopesRequest = () => ({
  type: TYPES.REQUEST,
});

const forceReloadAction = (shouldForceReload) => ({
  type: TYPES.FORCERELOAD,
  shouldForceReload,
});

const fetchEnvelopesSuccess = (data) => {
  const {content, customPropertiesConfig, ...rest} = data;
  return {
    type: TYPES.SUCCESS,
    data: content,
    customPropertiesConfig,
    paging: {...rest},
  };
};

const fetchEnvelopesError = (error) => ({
  type: TYPES.ERROR,
  error: error.message,
});

export const setEnvelopesListstatusChangeData = (envelopeUuids, status) => ({
  type: TYPES.STATUS_CHANGE_REQUEST_SET_PARAMS,
  envelopeUuids: envelopeUuids,
  status: status,
});

export const setEnvelopesListstatusChangeDataReset = () => ({
  type: TYPES.STATUS_CHANGE_REQUEST_PARAMS_RESET,
});

const envelopesStatusChangeRequestInProgress = () => ({
  type: TYPES.STATUS_CHANGE_REQUEST_IN_PROCESS,
});
const envelopesStatusChangeError = (error) => ({
  type: TYPES.STATUS_CHANGE_ERROR,
  error: error,
});

const envelopesStatusChangeSuccess = () => ({
  type: TYPES.STATUS_CHANGE_SUCCESS,
});

const prepareDocuments = (scenarioUuid, data, store) => {
  const currentScenario = store.scenariosList.data.find(
    (item) => item.uuid === scenarioUuid
  );

  const DOCUMENT_ORDER = currentScenario?.statusColumnOptions?.documentSortOrder
    ? currentScenario.statusColumnOptions.documentSortOrder
    : DEFAULT_DOCUMENT_SORTORDER;

  const CUSTPROP_ORDER = currentScenario?.customPropertiesConfig?.propertyOrder
    ? currentScenario.customPropertiesConfig.propertyOrder
    : [];

  data.content = data.content.map((item) => {
    return {
      ...item,
      customProperties: item.customProperties.sort(
        (a, b) =>
          CUSTPROP_ORDER.indexOf(a.path) - CUSTPROP_ORDER.indexOf(b.path)
      ),
      documents: item.documents.sort(
        (a, b) =>
          DOCUMENT_ORDER.indexOf(a.documentTypeId) -
          DOCUMENT_ORDER.indexOf(b.documentTypeId)
      ),
    };
  });

  const fields = currentScenario.customPropertiesConfig.formFields;

  data.customPropertiesConfig = Object.keys(fields)
    .sort((a, b) => CUSTPROP_ORDER.indexOf(a) - CUSTPROP_ORDER.indexOf(b))
    .map((key, idx) => {
      return {
        id: key,
        hidden: false,
        hideable: true,
        sort: idx + 4,
        label: fields[key]?.input?.label,
      };
    });

  return data;
};

const prepareQuery = (query, store) => {
  /**
   * we need to send the offeringCompanyUuid to fetch the correct envelopes for companies which use the same moduleConfig
   * e.g. Coop and Transgourmet
   * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/327
   * */
  const state = store();

  const currentlySelectedEdiPermission =
    state?.config?.userConfig?.selectedEdiPermission;

  const ediPermissions = state?.config?.userConfig?.ediPermissions || [];

  const ediPermissionObject = ediPermissions.find(
    (d) => d?.uuid === currentlySelectedEdiPermission
  );

  const offeringCompanyUuid = ediPermissionObject?.offeringCompany?.uuid;
  if (offeringCompanyUuid) {
    return {
      ...query,
      offeringCompanyUuid: offeringCompanyUuid,
    };
  }
  return query;
};

export const fetchEnvelopesPages = (scenarioUuid, query) => (
  dispatch,
  getStore
) => {
  dispatch(fetchEnvelopesRequest());
  if (typeof scenarioUuid === 'undefined') {
    console.error('Undefined passed for scnearioUuid');
    return dispatch(fetchEnvelopesError('No scenario provide'));
  }
  const queryToSend = prepareQuery(query, getStore);
  return getEnvelopesList(scenarioUuid, queryToSend)
    .then((res) =>
      dispatch(
        fetchEnvelopesSuccess(
          prepareDocuments(scenarioUuid, res.data, getStore())
        )
      )
    )
    .catch((error) => dispatch(fetchEnvelopesError(error)));
};

export const forceReloadEnvelopes = (forceReload) => (dispatch) => {
  dispatch(forceReloadAction(forceReload));
};

/***
 * Is used to set all batch-pdf downloaded
 * envelopes state to read atm.
 */
export const setEnvelopesListState = () => (dispatch, getState) => {
  dispatch(envelopesStatusChangeRequestInProgress());
  const {envelopesList} = getState();
  const envelopesUuidList = envelopesList?.envelopeUuids || [];
  const envelopesStatus = envelopesList?.status || null;

  return postEnvelopeStatus(envelopesUuidList, envelopesStatus)
    .then(() => {
      dispatch(envelopesStatusChangeSuccess());
      dispatch(forceReloadAction(true));
    })
    .catch((err) => {
      console.error(
        `Error while setting status ${envelopesStatus} to selectedEnvelopesList`,
        err
      );
      dispatch(envelopesStatusChangeError(err));
    });
};

export function reducer(state = initState, action) {
  switch (action.type) {
    case TYPES.REQUEST:
    case TYPES.STATUS_CHANGE_REQUEST_IN_PROCESS:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case TYPES.FORCERELOAD:
      return {
        ...state,
        shouldForceReload: action.shouldForceReload,
      };
    case TYPES.SUCCESS:
      return {
        ...state,
        data: action.data,
        paging: action.paging,
        customPropertiesConfig: action.customPropertiesConfig,
        fetching: false,
        error: null,
      };

    case TYPES.ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };

    case TYPES.STATUS_CHANGE_REQUEST_SET_PARAMS:
      return {
        ...state,
        envelopeUuids: action.envelopeUuids,
        status: action.status,
      };

    case TYPES.STATUS_CHANGE_REQUEST_PARAMS_RESET:
      return {
        ...state,
        envelopeUuids: null,
        status: null,
      };
    case TYPES.STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        envelopeUuids: null,
        status: null,
      };
    case TYPES.STATUS_CHANGE_ERROR:
      return {
        ...state,
        fetching: false,
        envelopeUuids: null,
        status: null,
        error: action.error,
      };
    default:
      return state;
  }
}
