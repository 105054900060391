/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {ecosioColors} from '@ecosio/customer-layout';
import {useHistory} from 'react-router-dom';
import {Popup, Message, MessageHeader, Divider, Icon} from 'semantic-ui-react';
import {intlShape} from '@ecosio/components';
import {ECOSIO_THEME} from '../../helpers/constants';
import {loadTheme} from '../../helpers/utils';
import ConfirmPopup from '../ConfirmPopup';
import {DraftActionButton} from './CreateButtonDraftList';
import {deleteDraftWithUuid} from './draftRestCalls';
import {
  convertUTCdateTimeToLocalDateTime,
  triggerDelayErrorToast,
} from './draftUtils';

/**
 * container for ORDRSP and INVOICE page
 */
const Container = styled.div`
  height: 54px;
  background: ${(props) => props.backgroudColor};
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  z-index: 1;
  top: calc(0% + 64px);
`;

/**
 * container for desadv page
 */
const DesadvV2Container = styled.div`
  height: 54px;
  background: ${(props) => props.backgroudColor};
  margin-left: -59px;
  margin-right: -59px;
  margin-top: -52px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  z-index: 1;
  top: calc(0% + 64px);
`;

const MessageContainer = styled.div`
  color: ${(props) => (props?.primaryColor ? props.primaryColor : `#333`)};
  font-size: 15px;
  font-weight: bold;
  flex: 1;
  margin-left: 20px;
`;

const DraftInfoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 20px;
`;

const DraftInfoContainerLastUpdated = styled.div`
  color: ${ecosioColors.greyShades[1]};
  font-size: 10px;
  font-weight: 700;
  margin-right: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 12px;
`;

const StyledSendDate = styled.div`
  line-height: 12px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 2px solid ${(props) => props.primaryColor};
  padding: 6px;
  border-radius: 0.28rem;
  font-size: 10px;
  font-weight: 400;
  width: max-content;
  color: ${(props) => props.primaryColor};
`;

export const ROOT_CONTAINER = {
  DESADV_V2: 'DESADV_V2',
  ORDRSP_AND_INVOICE: 'ORDRSP_AND_INVOICE',
};
const DocumentDraftInfoHeader = (props) => {
  const {intl, scenarioUuid, envelopeUuid, draftData, draftSettings} = props;
  const history = useHistory();

  if (!draftSettings?.shouldRenderDrafts || !draftData) {
    return null;
  }

  const currentTheme = loadTheme(props.theme);
  //convert utc dateTime to browser local dateTime
  const sendDate = convertUTCdateTimeToLocalDateTime(
    draftData?.sendDate,
    props?.locale
  );

  const lastUpdatedDate = convertUTCdateTimeToLocalDateTime(
    draftData?.updatedAt,
    props?.locale
  );

  const createdDate = convertUTCdateTimeToLocalDateTime(
    draftData?.createdAt,
    props?.locale
  );

  const deleteDraft = async (draftUuid) => {
    try {
      await deleteDraftWithUuid(draftUuid);
      const pathToRedirect = `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/merged`;
      history.push({
        pathname: pathToRedirect,
      });
    } catch (error) {
      triggerDelayErrorToast(error, intl);
    }
  };

  const DocumentDraftInfoHeaderRootContainer =
    props?.renderRootContainer === ROOT_CONTAINER.DESADV_V2
      ? DesadvV2Container
      : Container;

  return (
    <DocumentDraftInfoHeaderRootContainer
      data-spec="DRAFT_INFO_HEADER"
      backgroudColor={currentTheme?.secondaryColor}>
      <MessageContainer data-spec="DRAFT_INFO_HEADER_TITLE">
        {intl.formatMessage({id: 'DOCUMENT_DRAFT_INFO_HEADER_TITLE'})}
      </MessageContainer>
      <DraftInfoContainer>
        <Popup
          style={{
            padding: '0px',
          }}
          content={
            <DocumentDraftInfoPopUpMessage
              currentTheme={currentTheme}
              data={[
                {
                  titleWithDate: intl.formatMessage(
                    {id: 'DRAFT_INFO_LAST_UPDATED_DATE_POPUP'},
                    {
                      lastUpdatedDate: lastUpdatedDate,
                    }
                  ),
                  description: intl.formatMessage(
                    {id: 'DRAFT_INFO_LAST_UPDATED_BY_POPUP'},
                    {
                      lastUpdatedBy: draftData?.updatedBy,
                    }
                  ),
                },
                {
                  titleWithDate: intl.formatMessage(
                    {id: 'DRAFT_INFO_CREATED_DATE_POPUP'},
                    {
                      createdDate: createdDate,
                    }
                  ),
                  description: intl.formatMessage(
                    {id: 'DRAFT_INFO_CREATED_BY_POPUP'},
                    {
                      createdBy: draftData?.createdBy,
                    }
                  ),
                },
              ]}
            />
          }
          position="bottom left"
          trigger={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <DraftInfoContainerLastUpdated data-spec="DRAFT_INFO_LAST_UPDATED_DATE">
                {intl.formatMessage(
                  {id: 'DRAFT_INFO_LAST_UPDATED'},
                  {
                    lastUpdatedDate: lastUpdatedDate,
                  }
                )}
              </DraftInfoContainerLastUpdated>
              <StyledSendDate
                primaryColor={currentTheme?.primaryColor}
                data-spec="DRAFT_INFO_SEND_DATE">
                <Icon name="clock outline" />
                {intl.formatMessage(
                  {id: 'DRAFT_INFO_PLANNED_DATE'},
                  {
                    sendDate: sendDate,
                  }
                )}
              </StyledSendDate>
              <ConfirmPopup
                position="left center"
                dialogtitle={
                  <span>
                    <FormattedMessage id="DOCUMENT_DRAFT_EDIT_PAGE_DELETE_CONFIRM_POPUP_TITLE" />
                  </span>
                }
                dialogcontent={
                  <FormattedMessage id="DOCUMENT_DRAFT_EDIT_PAGE_DELETE_CONFIRM_POPUP_CONTENT" />
                }
                onConfirm={async () => await deleteDraft(draftData?.uuid)}
                trigger={
                  <div
                    style={{
                      marginLeft: '23px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <DraftActionButton
                      dataSpec="DRAFT_INFO_TRASH_BUTTON"
                      iconName="trash"
                      currentTheme={currentTheme}
                    />
                  </div>
                }
              />
            </div>
          }
        />
      </DraftInfoContainer>
    </DocumentDraftInfoHeaderRootContainer>
  );
};

DocumentDraftInfoHeader.defaultProps = {
  theme: ECOSIO_THEME,
  locale: 'en',
  renderRootContainer: ROOT_CONTAINER.ORDRSP_AND_INVOICE,
};

DocumentDraftInfoHeader.propTypes = {
  theme: PropTypes.string,
  locale: PropTypes.string,
  intl: intlShape.isRequired,
  scenarioUuid: PropTypes.string,
  envelopeUuid: PropTypes.string,
  draftData: PropTypes.object,
  draftSettings: PropTypes.object,
  renderRootContainer: PropTypes.string,
};

const mapStateToProps = ({config, locales}) => ({
  theme: config?.userConfig?.theme,
  locale: locales?.locale,
});

export default injectIntl(connect(mapStateToProps)(DocumentDraftInfoHeader));

const DocumentDraftInfoPopUpMessage = ({currentTheme, data}) => {
  return (
    <Message size="mini" style={{width: '269px'}}>
      {data.map((d, index) => (
        <div key={index} style={{padding: '5px 0px 5px 0px'}}>
          <MessageHeader
            data-spec="DRAFT_INFO_POPUP_HEADER"
            style={{
              color: currentTheme?.primaryColor,
              'font-size': '10px',
            }}>
            {d.titleWithDate}
          </MessageHeader>
          <p
            data-spec="DRAFT_INFO_POPUP_DESCRIPTION"
            style={{
              color: ecosioColors.greyShades[1],
              'font-size': '10px',
              'white-space': 'nowrap',
              'text-overflow': 'ellipsis',
              overflow: 'hidden',
            }}>
            {d.description}
          </p>
          {index === 0 && <Divider />}
        </div>
      ))}
    </Message>
  );
};

DocumentDraftInfoPopUpMessage.defaultProps = {
  data: [],
};

DocumentDraftInfoPopUpMessage.propTypes = {
  currentTheme: PropTypes.object.isRequired,
  data: PropTypes.array,
};
