import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {dynID} from '@ecosio/pathform';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Helmet} from 'react-helmet';
import {parse, stringify} from 'qs';
import {hasPaging, Pagination, toast} from '@ecosio/components';
import {
  Header,
  Segment,
  Button,
  Dropdown,
  Icon,
  Message,
} from 'semantic-ui-react';
import axios from 'axios';
import {isEmpty, flowRight} from 'lodash';

import {log} from 'debug';
import {scenarioShape} from '../../shapes/scenarios';
import {
  fetchEnvelopesPages,
  forceReloadEnvelopes,
  setEnvelopesListstatusChangeData,
} from '../../reducers/envelopesList';
import {envelopesListShape} from '../../shapes/envelopes';
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from '../../helpers/localStorageUtil';
import {FlashMessage} from '../DynamicTemplate';
import {
  startBatchPdfDownload,
  startPolling,
  TYPES,
} from '../../reducers/fileDownload';
import {STANDARD_TOAST_DURATION} from '../../helpers/constants';
import {saveDocument} from '../../helpers/utils';
import {STATUS_ARCHIVED} from '../../shapes/supportedEnvelopeStati';
import {shouldRenderDrafts} from '../DocumentDrafts/draftUtils';
import EnvelopeFilter from './EnvelopeFilter';
import EnvelopesGrid, {extractUserSort} from './EnvelopesGrid';

export const ENVELOPE_SORT = 'envelope-sort';
export const ENVELOPE_COLUMN_VISIBILITY = 'envelope-column-visibility';

const mapStateToProps = ({envelopesList, config, locales, fileDownload}) => ({
  userID: config?.userConfig?.email,
  envelopesList,
  shouldForceReload: envelopesList.shouldForceReload,
  locale: locales.locale,
  fileDownload,
  userConfig: config?.userConfig,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPage: (uuid, query) => {
    const envelopesSortQuery = getSortQuery(ownProps, uuid, query);

    return dispatch(fetchEnvelopesPages(uuid, envelopesSortQuery));
  },
  setShouldForceReload: (forceReload) =>
    dispatch(forceReloadEnvelopes(forceReload)),
  startFilePolling: (downloadId) => dispatch(startPolling(downloadId)),
  startBatchFileDownload: (scenarioUuid, action, params) =>
    dispatch(startBatchPdfDownload(scenarioUuid, action, params)),
  setEnvelopesListStateOnDownloadSuccess: (envelopesUuidList, state) =>
    dispatch(setEnvelopesListstatusChangeData(envelopesUuidList, state)),
});

const HelmetTags = injectIntl(({scenario, intl}) => (
  <Helmet>
    <title>
      {intl.formatMessage({id: dynID(scenario.nameTranslationKey)})}
    </title>
  </Helmet>
));

/**
 *
 * @param {Object} ownProps props from mapDispatchToProps
 * @param {String} uuid current scenarioUUid
 * @param {Object} query current query object
 * @returns query object
 *
 * A function which searches localStorage for a saved user-specific query and return it
 * when it exists.
 *
 * UseCase: determine which query is correct before sending a fetch request to the backend
 *
 */
export const getSortQuery = (ownProps, uuid, query) => {
  const {userID, scenarios} = ownProps;
  const scenario = scenarios.find((item) => item.uuid === uuid);

  const searchExistsinQuery = query?.search !== undefined;
  const searchNotEmpty = query?.search?.trim().length > 0;
  // If query.search exists and its value is empty string
  // then delete this query.search, since empty string is not a valid value for URL query
  if (searchExistsinQuery && !searchNotEmpty) {
    delete query.search;
  }

  if (Object.keys(query).length && !query?.search) {
    saveToLocalStorage(query, ENVELOPE_SORT, uuid, userID);
  }

  const defaultSortEnvelopes = scenario?.defaultSortEnvelopes;

  const envelopesSort = getEnvelopesDefaultSort(
    defaultSortEnvelopes,
    scenario.uuid,
    userID,
    true
  );

  const searchExists = query?.search === '' ? !query?.search : query?.search;

  if (searchExists && parseInt(query?.page) > 1) {
    /**
     * when the user filters for a specific document and somehow wants to search for that in page > 1, then obviously it finds nothing.
     * thats why we delete here the page from query. this searches the document in page 1 and finds it.
     *
     * Some users really somehow filtered with search on page > 1. It was really a prod issue :/
     * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/451
     * */
    if (envelopesSort?.page) {
      delete envelopesSort.page;
    }
    delete query.page;
  }

  if (envelopesSort && !query?.search) {
    return envelopesSort;
  }

  return query;
};

export const getEnvelopesDefaultSort = (
  defaultSortEnvelopes,
  scenarioUuid,
  userID,
  getParsedSort = false
) => {
  const userSort = getFromLocalStorage(ENVELOPE_SORT, scenarioUuid, userID);
  const userSortDirection = extractUserSort(scenarioUuid, userID, 'direction');
  if (userSort && userSortDirection) {
    return getParsedSort ? userSort : `?${stringify(userSort)}`;
  } else if (!isEmpty(defaultSortEnvelopes)) {
    const col = defaultSortEnvelopes.columnName;
    const sort = defaultSortEnvelopes.sortOrder
      ? defaultSortEnvelopes.sortOrder
      : 'DESC';
    const map = {
      ASC: 'asc',
      DESC: 'desc',
    };
    const filter = parse(location.search.substr(1));
    filter.sort = `${col},${map[sort]}`;
    return getParsedSort ? filter : `?${stringify(filter)}`;
  } else {
    return undefined;
  }
};

const BatchActionMessage = ({
  error,
  batchEndPointType,
  batchActionType,
  handleDismiss,
}) => {
  if (error) {
    return (
      <Message icon negative onDismiss={handleDismiss}>
        <Icon name="warning circle" />
        <Message.Content>
          <Message.Header data-spec="batchAction-message-header">
            <FormattedMessage
              id={dynID(
                `BATCH_${batchEndPointType}_${batchActionType}_ERROR_HEADER`
              )}
            />
          </Message.Header>
          <div data-spec="batchAction-message-body">
            <FormattedMessage
              id={dynID(
                `BATCH_${batchEndPointType}_${batchActionType}_ERROR_CONTENT`
              )}
            />
          </div>
        </Message.Content>
      </Message>
    );
  }

  /*No onSuccess Message for downloads, because the download action is already visible */
  if (!error && batchEndPointType === 'UPDATE') {
    return (
      <Message
        success
        onDismiss={handleDismiss}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid',
        }}>
        <Message.Content>
          <Message.Header data-spec="batchAction-message-header">
            <FormattedMessage
              id={dynID(
                `BATCH_${batchEndPointType}_${batchActionType}_SUCCESS_HEADER`
              )}
            />
          </Message.Header>
          <div data-spec="batchAction-message-body">
            <FormattedMessage
              id={dynID(
                `BATCH_${batchEndPointType}_${batchActionType}_SUCCESS_CONTENT`
              )}
            />
          </div>
        </Message.Content>
      </Message>
    );
  }
  return null;
};

BatchActionMessage.propTypes = {
  error: PropTypes.bool,
  batchEndPointType: PropTypes.string,
  batchActionType: PropTypes.string,
  handleDismiss: PropTypes.func,
};

/**
 * TODO: Update component for `UPDATE` all or newest envelopes
 */
const MetaComponent = injectIntl(({intl, data, onChange}) => {
  return Object.keys(data).map((key) => {
    const batchActionType = data[key][0]?.batchActionType;

    const placeholder =
      key === 'all' ? `${batchActionType}_ALL` : `${batchActionType}_NEWEST`;

    if (data[key].length > 1) {
      return (
        <div
          style={{display: 'inline-block', marginRight: '5px'}}
          key={`META_DROPDOWN_${key}`}>
          <Dropdown
            data-spec={`META_DROPDOWN_${key}`}
            style={{width: '300px'}}
            selectOnBlur={false}
            placeholder={intl.formatMessage({id: placeholder})}
            selection
            options={data[key]}
            onChange={(e) => onChange(e, 'DOWNLOAD', placeholder)}
          />
        </div>
      );
    } else {
      return (
        <Button
          data-spec={`META_BUTTON_${key}`}
          value={data[key][0].value}
          onClick={(e) => onChange(e, 'DOWNLOAD', placeholder)}
          key={`META_BUTTON_${key}`}>
          <Icon name="download" />
          {intl.formatMessage({id: placeholder})}
        </Button>
      );
    }
  });
});

class EnvelopesComponent extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {scenario, scenarioUuid} = this.props;
    const batchActions = Object.keys(scenario?.envelopeBatchActions);
    this.metaConfig = batchActions.reduce(
      (acc, key) => {
        const BASE = `/api/download/batch`;
        if (
          !scenario?.envelopeBatchActions[key].allowDownloadNewest &&
          !scenario?.envelopeBatchActions[key].allowDownloadAll
        ) {
          return null;
        }

        if (scenario?.envelopeBatchActions[key].allowDownloadNewest) {
          acc.newest.push({
            icon: 'download',
            key: `${scenarioUuid}-${key}-NEWEST`,
            text: <FormattedMessage id={key} />,
            value: `${BASE}/${scenarioUuid}/${key}?newest=true`,
            batchActionType: key,
          });
        }

        if (scenario?.envelopeBatchActions[key].allowDownloadAll) {
          acc.all.push({
            icon: 'download',
            key: `${scenarioUuid}-${key}-ALL`,
            text: <FormattedMessage id={key} />,
            value: `${BASE}/${scenarioUuid}/${key}?all=true`,
            batchActionType: key,
          });
        }
        return acc;
      },
      {all: [], newest: []}
    );

    // if there are no batch actions, we do not render the datagrid
    // MetaComponent (see below)
    if (batchActions.length === 0) {
      this.metaConfig = null;
    }

    this.state = {
      actionFetch: false,
      batchOpen: false,
      error: false,
      visibleMessage: false,
      draftsPerEnvelope: null,
    };

    this.actions = this.fetchBatchActions(scenario);

    this.showCustomProperties = scenario?.customPropertiesConfig?.enabled;

    this.hasDescription =
      scenario.descriptionTranslationKey &&
      scenario.descriptionTranslationKey.trim() !== '';
  }

  componentDidUpdate(prevProps) {
    const {scenario, envelopesList, userConfig} = this.props;
    const {data, paging} = envelopesList;
    if (scenario?.uuid !== prevProps?.scenarioUuid) {
      this.actions = this.fetchBatchActions(scenario);
    }

    // If the loaded data content is empty and there are some envelopes in DB but there is only one page of them
    // Delete page index from search so that only the first page is loaded, containing all the elements
    if (
      data?.length === 0 &&
      paging?.numberOfElements === 0 &&
      paging?.totalElements >= 0
    ) {
      const filter = parse(location.search.substr(1));
      // if there is just one page -> redirect to that page
      // Otherwise, if totalPages are loaded from BE (totalPages>0) and current page is greater than totalPage
      //-> redirect to page 1
      if (
        paging?.totalPages === 1 ||
        (paging?.totalPages > 0 && paging?.totalPages < filter.page)
      ) {
        filter.page = '1';
      }
      const searchParamsSuffix = stringify(filter);
      // If there are no changes in the search query - dont update component
      if (location.search.substr(1) !== searchParamsSuffix) {
        const searchParams = `?${searchParamsSuffix}`;
        this.props.history.replace({
          ...this.props.history.location,
          search: searchParams,
        });
      }
    }

    if (data?.length !== 0 && shouldRenderDrafts(userConfig)) {
      this.fetchDraftsCountPerEnvelope();
    }
  }

  fetchBatchActions = (scenario) => {
    return Object.keys(scenario?.envelopeBatchActions).map((key) => {
      return {
        id: key,
        label:
          scenario?.envelopeBatchActions[key]?.downloadOptionTranslationKey,
        action: this.selectedFetch,
      };
    });
  };
  selectedFetch = (selected, action) => {
    const {scenario, scenarioUuid} = this.props;

    // envelopes are unsorted here - sort envelopeUuids according to currently selected sort option

    const {envelopesList} = this.props;
    const sortedSelectedEnvelopes = {};
    for (const selectedEnvelopeKey in selected) {
      const envelopeIndex = envelopesList.data.findIndex(
        (env) => env.uuid === selected[selectedEnvelopeKey]
      );

      if (envelopeIndex < 0) {
        log.warn(
          `This should not happen - index not found for envelope ${selected[selectedEnvelopeKey]}`
        );
      }
      sortedSelectedEnvelopes[envelopeIndex] = selected[selectedEnvelopeKey];
    }

    // if all selected envelopes are sorted - use sorted envelope uuids for further processing
    if (selected.length === Object.values(sortedSelectedEnvelopes).length) {
      selected = Object.values(sortedSelectedEnvelopes);
    }

    this.setState(
      {
        actionFetch: true,
      },
      () => {
        const params = stringify(
          {
            envelopes: selected,
            locale: this.props.locale,
          },
          {indices: false}
        );
        const controllerEndpointType =
          scenario?.envelopeBatchActions[action]?.endpointType;

        switch (controllerEndpointType) {
          case 'DOWNLOAD':
            this.downloadEnvelopes(scenarioUuid, action, params);
            break;
          case 'UPDATE':
            this.updateEnvelopes(scenarioUuid, action, params);
            break;

          default:
            this.setError(
              `No batchControllerEndpoint defined for ${controllerEndpointType}`,
              'UNKNOWN',
              action
            );
        }
      }
    );
  };

  setError = (e, batchEndpointType, batchActionType) => {
    console.error(e);
    this.setState({
      error: true,
      actionFetch: false,
      batchActionType: batchActionType,
      batchEndPointType: batchEndpointType,
      visibleMessage: true,
    });
  };

  batchFetch = (ev, batchEndPointType, batchActionType) => {
    const target = ev.target;
    this.setState(
      {
        actionFetch: true,
      },
      () => {
        axios({
          url: target.value,
          method: 'GET',
          responseType: 'blob',
        })
          .then((res) => {
            saveDocument(res);
            this.setState({
              actionFetch: false,
              error: false,
              visibleMessage: false,
              batchEndPointType: batchEndPointType,
              batchActionType: batchActionType,
            });
          })
          .catch((e) => this.setError(e, batchEndPointType, batchActionType));
      }
    );
  };

  renderFooter = ({Table, cols}) => {
    const {envelopesList} = this.props;
    return (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={cols}>
            <Pagination paging={envelopesList.paging} />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  };

  handleBatchOpen = () => {
    this.setState((prev) => ({
      batchOpen: !prev.batchOpen,
    }));
  };

  onSort = ({col, dir}) => {
    const {scenarioUuid, userID, scenario} = this.props;

    const map = {
      ascending: 'asc',
      descending: 'desc',
    };

    const filter = parse(location.search.substr(1));
    filter.sort = `${col},${map[dir]}`;

    const envelopesSort = getEnvelopesDefaultSort(
      scenario.defaultSortEnvelopes,
      scenarioUuid,
      userID,
      true
    );
    // If sorting column and order are not the same - restart page to 1
    if (envelopesSort?.sort !== filter.sort) {
      filter.page = '1';
    }

    saveToLocalStorage(filter, ENVELOPE_SORT, scenarioUuid, userID);

    let searchParams = `?${stringify(filter)}`;

    if (!dir) {
      searchParams = getEnvelopesDefaultSort(
        scenario.defaultSortEnvelopes,
        scenarioUuid,
        userID
      );
    }

    this.props.history.push({
      ...this.props.history.location,
      search: searchParams,
    });
  };

  downloadEnvelopes(scenarioUuid, action, params) {
    const {scenario, fileDownload, intl, envelopesList} = this.props;
    const downloadId = fileDownload?.downloadId;
    const downloadPending = fileDownload?.polling;

    if (downloadId && downloadPending) {
      //When there is already a downloadOperation running, trigger a toast.
      toast({
        title: intl.formatMessage({
          id: 'ONE_RENDERING_ALREADY_IN_PROGRESS_TITLE',
        }),
        description: intl.formatMessage({
          id: 'ONE_RENDERING_ALREADY_IN_PROGRESS_TITLE_DESCRIPTION',
        }),
        type: 'warning',
        time: STANDARD_TOAST_DURATION,
      });
      this.setState({
        actionFetch: false,
        error: false,
        visibleMessage: true,
        batchEndPointType: 'DOWNLOAD',
        batchActionType: action,
      });

      //When there is already a downloadOperation running,return here and send no further download requests.
      return;
    }

    if (action === 'DELFOR_AS_CSV_DOWNLOAD') {
      //Dungs csv download endpoint

      const url = `/api/download/batch/${scenarioUuid}/${action}?${params}`;

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      })
        .then((res) => {
          /*
        Since this is a blob we have to deal with the Filename ourselfs
        https://github.com/eligrey/FileSaver.js/issues/439
        */
          saveDocument(res);
          this.setState({
            actionFetch: false,
            error: false,
            visibleMessage: false,
            batchEndPointType: 'DOWNLOAD',
            batchActionType: action,
          });
        })
        .catch((e) => this.setError(e, 'DOWNLOAD', action));
    } else {
      this.props
        .startBatchFileDownload(scenarioUuid, action, params)
        .then(({type, downloadId}) => {
          if (type === TYPES.START_SUCCESS) {
            this.setState({
              actionFetch: false,
              error: false,
              visibleMessage: true,
              batchEndPointType: 'DOWNLOAD',
              batchActionType: action,
            });

            const envelopesConfiguredStatus =
              scenario?.envelopeBatchActions[action]?.modifyEnvelopesStatusTo;

            //set status only when it is configured in web-config-loader
            if (envelopesConfiguredStatus) {
              //Archived is the final stage, i guess
              const areEnvelopesArchived = envelopesList?.data?.every(
                (envelope) => envelope?.status === STATUS_ARCHIVED
              );

              /**
               * we dont want to unArchive envelopes, which are already archived.
               * But still want to bulk download in archived view.
               * */
              if (!areEnvelopesArchived) {
                this.props.setEnvelopesListStateOnDownloadSuccess(
                  parse(params)?.envelopes,
                  envelopesConfiguredStatus
                );
              }
            }
            return this.props.startFilePolling(downloadId);
          }
        })
        .catch((e) => {
          this.setError(e, 'DOWNLOAD', action);
        });
    }
  }

  updateEnvelopes(scenarioUuid, action, wat) {
    const url = `/api/batchActions/update/${scenarioUuid}/${action}?${wat}`;

    axios({
      url: url,
      method: 'POST',
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            actionFetch: false,
            error: false,
            visibleMessage: true,
            batchEndPointType: 'UPDATE',
            batchActionType: action,
          });
          this.props.setShouldForceReload(true);
        }
      })
      .catch((e) => {
        this.setError(e, 'UPDATE', action);
      });
  }

  handleDismiss = () => {
    this.setState({visibleMessage: false});
  };

  fetchDraftsCountPerEnvelope = async () => {
    const {envelopesList, scenarioUuid} = this.props;
    const {data} = envelopesList;

    const envelopeUuidList = data.map((d) => d.uuid);

    if (!this.state.draftsPerEnvelope) {
      const draftsForAllEnvelopesResponse = await axios.get(
        `/api/documentDrafts/${scenarioUuid}/draftsCountForEnvelopes`,
        {
          params: {
            envelopeUuids: [...envelopeUuidList],
          },
        }
      );

      this.setState({
        draftsPerEnvelope:
          draftsForAllEnvelopesResponse?.data?.draftsPerEnvelope,
      });
    }
  };

  render() {
    const {envelopesList, scenario, scenarioUuid, history, userID} = this.props;
    const {fetching, data} = envelopesList;
    const {
      error,
      visibleMessage,
      batchActionType,
      batchEndPointType,
      actionFetch,
      draftsPerEnvelope,
    } = this.state;

    return scenario ? (
      <Fragment>
        <HelmetTags scenario={scenario} />
        <FlashMessage />
        <React.Fragment>
          <Segment attached="top">
            <Header as="h1">
              <Header.Content>
                <FormattedMessage id={dynID(scenario.nameTranslationKey)} />
                {this.hasDescription && (
                  <Fragment>
                    <Header.Subheader>
                      <FormattedMessage
                        id={dynID(scenario.descriptionTranslationKey)}
                      />
                    </Header.Subheader>
                  </Fragment>
                )}
              </Header.Content>
            </Header>
          </Segment>
          <Segment attached="bottom">
            <EnvelopeFilter history={history} location={location} />
          </Segment>
          <Segment id="env-table-wrapper">
            {visibleMessage ? (
              <BatchActionMessage
                error={error}
                batchActionType={batchActionType}
                batchEndPointType={batchEndPointType}
                handleDismiss={this.handleDismiss}
              />
            ) : null}

            <EnvelopesGrid
              customPropertiesConfig={envelopesList.customPropertiesConfig}
              userID={userID}
              onSort={this.onSort}
              data={data}
              fetching={fetching || actionFetch}
              loadMessage={actionFetch ? 'Generating Documents' : null}
              MetaComponent={
                this.metaConfig ? (
                  <MetaComponent
                    data={this.metaConfig}
                    onChange={this.batchFetch}
                  />
                ) : null
              }
              scenarioUuid={scenarioUuid}
              scenario={scenario}
              renderFooter={this.renderFooter}
              actions={this.actions}
              draftsPerEnvelope={draftsPerEnvelope}
            />
          </Segment>
        </React.Fragment>
      </Fragment>
    ) : (
      <EnvelopesGrid data={[]} fetching={false} />
    );
  }
}

EnvelopesComponent.propTypes = {
  scenarioUuid: PropTypes.string,
  userID: PropTypes.string,
  scenario: scenarioShape,
  envelopesList: envelopesListShape,
  setShouldForceReload: PropTypes.func,
};

export default injectIntl(
  flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    hasPaging('scenarioUuid')
  )(EnvelopesComponent)
);
