import {useEffect, useRef} from 'react';

//run the callback in an interval
const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  //set up the interval
  useEffect(() => {
    // do nothing when no delay is defined
    if (!delay) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    // clear old interval
    return () => clearInterval(id);
  }, [callback, delay]);
};

export default useInterval;
