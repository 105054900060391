/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import {FilterArray, FormGroup, Input} from '@ecosio/pathform';

const InvoiceLineItemFields = () => {
  return (
    <Fragment>
      <FormGroup data-spec="line-item">
        <Input
          size="mini"
          path="itemDescription"
          name="itemDescription"
          width={10}
        />
        <Input size="mini" path="itemCategory" name="itemCategory" width={6} />
        <FilterArray name="references" path="references">
          {(filtered) => {
            return filtered.map((name, idx) => {
              return (
                <div key={`referencelist-number-${idx}`}>
                  <Input
                    size="mini"
                    path="references.orderReference.referenceNumber"
                    name={`${name}.orderReference.referenceNumber`}
                  />
                </div>
              );
            });
          }}
        </FilterArray>
      </FormGroup>
      <FormGroup>
        <Input size="mini" path="gtin" name="gtin" />
        <Input
          size="mini"
          path="manufacturersArticleNumber"
          name="manufacturersArticleNumber"
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input
          size="mini"
          path="customersItemMaterialNumber"
          name="customersItemMaterialNumber"
        />
        <Input
          size="mini"
          path="suppliersItemMaterialNumber"
          name="suppliersItemMaterialNumber"
        />
        <Input size="mini" path="unitPriceBasis" name="unitPriceBasis" />
      </FormGroup>

      <FormGroup widths="equal">
        <Input
          size="mini"
          path="countryOfOrigin.countryCode"
          name="countryOfOrigin.countryCode"
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input
          size="mini"
          path="materialCharacteristics.customsTariffNumber"
          name="materialCharacteristics.customsTariffNumber"
        />
        <Input
          size="mini"
          path="materialCharacteristics.intrastatNumber"
          name="materialCharacteristics.intrastatNumber"
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input
          size="mini"
          path="materialCharacteristics.containerDeposit"
          name="materialCharacteristics.containerDeposit"
        />
        <Input
          size="mini"
          path="materialCharacteristics.netWeight"
          name="materialCharacteristics.netWeight"
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input
          size="mini"
          path="references.agreementOrOrderNumberReference"
          name="references.agreementOrOrderNumberReference"
        />
        <Input
          size="mini"
          path="references.agreementOrOrderLineNumber"
          name="references.agreementOrOrderLineNumber"
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input
          size="mini"
          path="alternativeQuantity.value"
          name="alternativeQuantity.value"
        />
        <Input
          size="mini"
          path="alternativeQuantity.unit"
          name="alternativeQuantity.unit"
        />
      </FormGroup>
    </Fragment>
  );
};

export default InvoiceLineItemFields;
