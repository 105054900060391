import createDecorator from 'final-form-calculate';
import {
  SETTING_TYPE_GS1,
  SETTING_TYPE_SSCC,
  SSCC_PACKAGE_NUMBER_MAX_LENGTH,
} from '../../constants';

export const getMaxCounterValue = (gs1) => {
  const maxCounterPartLength = gs1
    ? SSCC_PACKAGE_NUMBER_MAX_LENGTH - gs1.length
    : SSCC_PACKAGE_NUMBER_MAX_LENGTH;
  let result = '';
  for (let i = 1; i <= maxCounterPartLength; i++) {
    result += '9';
  }
  // Set 9 on all positions for counter in SSCC
  return parseInt(result);
};

export const calculateNextCounterValue = (ssccCounter, gs1) => {
  const ssccCntInt = ssccCounter ? parseInt(ssccCounter) : 0;
  return ssccCntInt + 1 <= getMaxCounterValue(gs1) ? ssccCntInt + 1 : 0;
};

const webEdiSettingsFormDecorator = createDecorator(
  {
    field: SETTING_TYPE_SSCC,
    updates: {
      NEXT_SSCC_VALUE: (ssccCounter, allFormValues) =>
        calculateNextCounterValue(ssccCounter, allFormValues[SETTING_TYPE_GS1]),
      SETTING_TYPE_SSCC: (ssccCounter) => (ssccCounter ? ssccCounter : 0),
    },
  },
  {
    field: SETTING_TYPE_GS1,
    updates: {
      NEXT_SSCC_VALUE: (gs1, allFormValues) =>
        calculateNextCounterValue(allFormValues[SETTING_TYPE_SSCC], gs1),
    },
  }
);

export default webEdiSettingsFormDecorator;
