import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Input, Form} from 'semantic-ui-react';
import {Field} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {get} from 'lodash';
import buildVatOptions from '../buildVatOptions';

const AocVatField = ({
  customerSpecificTranslations,
  name,
  vatrates,
  index,
  formFields,
}) => {
  const intl = useIntl();
  const vatAmountFormField = get(
    formFields,
    'header.invoiceHeader.allowancesAndCharges.vatAmount'
  );

  const vatAmountIsDisabled = vatAmountFormField?.input?.disabled;
  return (
    <Form.Field>
      <label style={{fontWeight: 'normal', fontStyle: 'italic'}}>
        <FormattedMessage
          id={get(customerSpecificTranslations, 'INVOIC_VAT', 'INVOIC_VAT')}
        />
      </label>

      <Field
        name={`${name}.vatamount`}
        render={({input}) => {
          return (
            <Input
              {...input}
              type="number"
              step="0.01"
              labelPosition="left"
              disabled={vatAmountIsDisabled}
              style={{
                opacity: '1',
              }}
              size="mini">
              <Field
                data-spec={`aoc-vat-${index}`}
                name={`${name}.vatrate`}
                render={({input}) => {
                  const options = buildVatOptions(
                    vatrates,
                    input.value,
                    intl,
                    customerSpecificTranslations
                  );
                  return (
                    <Dropdown
                      {...input}
                      onChange={(e, {value}) => {
                        e.preventDefault();
                        input.onChange(value);
                      }}
                      options={options}
                      className="label _si_aoc_sel"
                    />
                  );
                }}
              />
              <input />
            </Input>
          );
        }}
      />
    </Form.Field>
  );
};

AocVatField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  vatrates: PropTypes.array,
  index: PropTypes.number,
};

export default AocVatField;
