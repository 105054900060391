import React from 'react';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import {Dropdown, Form} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {intlShape} from '@ecosio/components';
import moment from 'moment';

export const buildDates = (data) => {
  if (!Array.isArray(data)) {
    console.warn('buildDates received an invalid data argument', typeof data);
    return [];
  }

  return data.map((item, idx) => ({
    key: idx,
    text: `${moment(item.date).format('DD.MM.YYYY')}`,
    value: item.date,
  }));
};
/**
 * TODO: more tests if the built link is valid
 */
class DesadvSelector extends React.Component {
  constructor(props) {
    super(props);
    const data = props.deliveryDates || [];
    const dates = buildDates(data);
    this.state = {
      dates,
      consigneeOptions: [],
      // flag to indicate what we've already selected
      selected: {
        date: false,
        consignee: false,
      },
      // the actual selected data
      // will be used as GET params for the backend
      data: {
        // important: this filterkeys must be defined like that exactly,
        // as this is the parameter evaluated by the backend
        dateFilter: dates[0] ? dates[0].value : null,
        unloadingPoint: null,
        duns: null,
        storageLocation: null,
      },
    };
  }
  componentDidMount() {
    const {dates, consigneeOptions} = this.state;
    if (dates.length === 1 && consigneeOptions.length === 0) {
      this.onDateChange(dates[0]);
    }
  }
  componentDidUpdate() {
    const {selected, consigneeOptions} = this.state;
    if (
      selected?.date &&
      !selected?.consignee &&
      consigneeOptions.length === 1
    ) {
      this.onConsigneeChange(consigneeOptions[0]);
    }
  }

  buildConsigneeOptions = (date) => {
    // get the raw data
    const data = this.props.deliveryDates || [];
    // filter by date
    const found = data.find((item) => item.date === date);

    // create a list of dropdown options for semantic-ui
    return found.consignees.map((item, idx) => {
      return {
        key: idx,
        text: `${item.name} - ${item.duns}, ${item.storageLocation} ,${item.unloadingPoint}`,
        // semantic ui dropdown does not like objects as values
        // we must use the index and later pick the right consignee
        // by the index...
        value: item.index,
      };
    });
  };

  onConsigneeChange = ({value}) => {
    // get all consignees from the props
    const data = this.props.deliveryDates || [];
    const allConsignees = flatMap(data, (item) => item.consignees);
    // find the selected one by index
    // the index attribute is calculated in groupDates
    const consignee = find(allConsignees, {index: value});
    // update the state with tne new consignee
    this.setState(
      (prevState) => ({
        selected: {
          ...prevState.selected,
          consignee: value,
        },
        data: {
          ...prevState.data,
          ...consignee,
        },
      }),
      // let the world know we've updated
      this.fireUpdate
    );
  };

  onDateChange = ({value}) => {
    this.setState((prevState) => {
      const consigneeOptions = this.buildConsigneeOptions(value);
      return {
        // when the date changes we need to rebuild the consignee dropdown
        consigneeOptions,
        selected: {
          ...prevState.selected,
          date: true,
          consignee: null,
        },
        data: {
          ...prevState.data,
          dateFilter: value,
          duns: null,
          unloadingPoint: null,
          storageLocation: null,
        },
      };
    }, this.fireUpdate);
  };

  fireUpdate = () => {
    this.props.onLinkUpdate(this.state.data);
  };

  render() {
    const {intl} = this.props;
    const {consigneeOptions, dates} = this.state;

    return (
      <Form
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
          flexWrap: 'wrap',
        }}>
        <Form.Field style={{flexBasis: '48%'}}>
          <label>
            <FormattedMessage id="CREATE_FOR_DELIVERYDATE" />
          </label>
          <Dropdown
            className="_si_date_pick"
            options={dates}
            selection
            defaultValue={dates.length === 1 ? dates[0]?.value : null}
            onChange={(event, data) => this.onDateChange(data)}
            placeholder={intl.formatMessage({
              id: 'GENERAL_PLEASE_CHOOSE',
            })}
          />
        </Form.Field>

        <Form.Field style={{flexBasis: '48%'}}>
          <label>
            <FormattedMessage id="CREATE_FOR_CONSIGNEE" />
          </label>
          <Dropdown
            className="_si_consignee_pick"
            options={consigneeOptions}
            selection
            defaultValue={
              consigneeOptions.length === 1 ? consigneeOptions[0]?.value : null
            }
            onChange={(event, data) => this.onConsigneeChange(data)}
            value={this.state.selected?.consignee}
            placeholder={intl.formatMessage({
              id: 'GENERAL_PLEASE_CHOOSE',
            })}
          />
        </Form.Field>
      </Form>
    );
  }
}

DesadvSelector.propTypes = {
  deliveryDates: PropTypes.array.isRequired,
  intl: intlShape,
  // fired then the link to create the desadv has been updated
  onLinkUpdate: PropTypes.func.isRequired,
};

export default injectIntl(DesadvSelector);
