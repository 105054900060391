import React, {Fragment} from 'react';
import {filterFields} from '@ecosio/pathform';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

import {pageConfigShape} from '../../../../../shapes/scenarios';

const BusinessEntityHeader = ({prefix, fields}) => {
  // Use .startsWith so that paths in the header don't match
  const found = filterFields(fields, (field) => field.path.startsWith(prefix));

  if (!found) {
    return null;
  }

  // check if the node is there and is a table column
  if (found[prefix] && found[prefix].tableColumn === false) {
    return null;
  }

  return (
    <Table.HeaderCell>
      <FormattedMessage id={prefix} />
    </Table.HeaderCell>
  );
};

BusinessEntityHeader.propTypes = {
  prefix: PropTypes.string,
  fields: PropTypes.object,
};

const CalcHeader = ({hideTotalPrice}) => (
  <Fragment>
    <Table.HeaderCell>
      <FormattedMessage id="GENERAL_UNIT_PRICE" />
    </Table.HeaderCell>
    {hideTotalPrice ? (
      ''
    ) : (
      <Table.HeaderCell>
        <FormattedMessage id="GENERAL_TOTAL_PRICE" />
      </Table.HeaderCell>
    )}
  </Fragment>
);

export const HeaderCell = ({field, width}) => {
  if (!field) {
    return null;
  }
  // the field is disabled in the table, don't render it
  if (field.tableColumn === false) {
    return null;
  }

  return (
    <Table.HeaderCell width={width}>
      <FormattedMessage id={field.input.label} />
    </Table.HeaderCell>
  );
};

HeaderCell.propTypes = {
  width: PropTypes.number,
  field: PropTypes.shape({
    tableColumn: PropTypes.bool,
    input: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  }),
};

const TableHeader = ({
  showCalculation,
  hideTotalPrice,
  pageConfig,
  hasEditables = false,
  hasSelect = false,
}) => {
  const getField = (path) => {
    return pageConfig.formFields[path];
  };

  return (
    <Table.Header>
      <Table.Row>
        <HeaderCell field={getField('positionNumber')} />
        <HeaderCell
          field={getField('references[0].orderReference.lineNumber')}
        />
        <HeaderCell field={getField('gtin')} />
        <HeaderCell field={getField('gtinWithManufacturerArticleNumber')} />
        <HeaderCell field={getField('manufacturersArticleNumber')} />
        <HeaderCell field={getField('customersItemMaterialNumber')} />
        <HeaderCell field={getField('suppliersItemMaterialNumber')} />
        <HeaderCell field={getField('itemDescription')} />
        <HeaderCell field={getField('itemCategory')} />
        <HeaderCell field={getField('references')} />
        <HeaderCell field={getField('countryOfOrigin.countryName')} />
        <HeaderCell field={getField('caliber.value')} />
        <HeaderCell field={getField('batchNumber')} />
        <HeaderCell field={getField('reasonOfReturn.value')} />
        <HeaderCell field={getField('currentItemPriceCalculationNetPerUnit')} />
        <BusinessEntityHeader
          fields={pageConfig.formFields}
          prefix="consignee"
        />
        <HeaderCell
          field={getField('references[0].contractReference.referenceNumber')}
          header
        />
        <HeaderCell field={getField('previouslyScheduledQuantity')} />
        <Table.HeaderCell>
          <div style={{width: '150px'}}>
            <FormattedMessage
              id={
                getField('orderedQuantity')?.input?.label ||
                'GENERAL_DELIVERIES'
              }
            />
          </div>
        </Table.HeaderCell>
        {showCalculation ? (
          <CalcHeader hideTotalPrice={hideTotalPrice} />
        ) : null}
        {hasSelect ? (
          <Table.HeaderCell>
            <FormattedMessage id="GENERAL_DETAILS" />
          </Table.HeaderCell>
        ) : null}
        {hasEditables ? (
          <Table.HeaderCell>
            <FormattedMessage id="GENERAL_ACTIONS" />
          </Table.HeaderCell>
        ) : null}
      </Table.Row>
    </Table.Header>
  );
};

export default TableHeader;

TableHeader.propTypes = {
  pageConfig: pageConfigShape,
  showCalculation: PropTypes.bool,
  hasEditables: PropTypes.bool,
  hasSelect: PropTypes.bool,
};
