import React from 'react';

import WebEdiSettingsForm from './WebEdiSettingsForm';

const WebEdiSettingsPage = (props) => {
  const {history} = props;

  return (
    <div>
      <WebEdiSettingsForm history={history} />
    </div>
  );
};

export default WebEdiSettingsPage;
