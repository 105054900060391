import React from 'react';
import PropTypes from 'prop-types';
import {Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

const ValidationErrorMessage = ({id, show}) =>
  show ? (
    <Message
      icon="warning sign"
      negative
      error
      content={<FormattedMessage id={id} />}
    />
  ) : null;

ValidationErrorMessage.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};
export default ValidationErrorMessage;
