import React from 'react';
import {Input} from '@ecosio/pathform';
import {get} from 'lodash';

const HAGEBAU_GLN = '4333990000009';

const dropDownOptionsValidation = (
  enrichedCountryOfOriginSettingsList = []
) => {
  if (!enrichedCountryOfOriginSettingsList.length) {
    return 'NO_COUNTRIES_DEFINED_FOR_DROPDOWN_OPTIONS';
  }

  return undefined;
};

export const HeaderCountryOfOriginComponent = ({
  documentExchange,
  path,
  name,
  formContext,
  onVatVariantChange,
}) => {
  const isHagebauGLN =
    get(
      documentExchange?.document,
      'header.businessEntities.invoiceRecipient.gln'
    ) === HAGEBAU_GLN;

  if (isHagebauGLN) {
    const enrichedCountryOfOriginSettingsList = get(
      documentExchange,
      'complexWebediCompanyProperties.SETTING_COUNTRY_OF_ORIGIN',
      []
    );

    const dynamicOptions = enrichedCountryOfOriginSettingsList
      .filter((countryOfOriginSetting) => countryOfOriginSetting?.countryName)
      .map((countryOfOriginSetting) => {
        return {
          text: `COUNTRY_${countryOfOriginSetting.countryName}`,
          value: `${countryOfOriginSetting.countryName}`,
        };
      });
    return (
      <Input
        path={path}
        name={name}
        size="mini"
        options={dynamicOptions}
        validate={() =>
          dropDownOptionsValidation(enrichedCountryOfOriginSettingsList)
        }
        onInputChange={(e, value) => {
          /**
           * changes header.businessEntities.supplier.vatid with each onchange of complexCountryOfOrigin
           */
          const vatIDPath = 'header.businessEntities.supplier.vatid';

          const complexCountryOfOriginObjectFromList = enrichedCountryOfOriginSettingsList.find(
            (o) => o?.countryName === value
          );

          if (complexCountryOfOriginObjectFromList?.vatID) {
            formContext?.form?.change(
              vatIDPath,
              complexCountryOfOriginObjectFromList?.vatID
            );
          }

          /**
           * changes header.invoiceHeader.internalVATVarianceCode with each onchange of complexCountryOfOrigin
           */
          const internalVATVarianceCodePath =
            'header.invoiceHeader.internalVATVarianceCode';

          if (complexCountryOfOriginObjectFromList?.internalVATVarianceCode) {
            formContext.form.batch(() => {
              formContext?.form?.change(
                internalVATVarianceCodePath,
                complexCountryOfOriginObjectFromList?.internalVATVarianceCode
              );
            });
            onVatVariantChange(
              e,
              complexCountryOfOriginObjectFromList?.internalVATVarianceCode
            );
          }
        }}
      />
    );
  }
  return <Input path={path} name={name} size="mini" />;
};
