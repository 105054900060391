import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Icon, Popup} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {historyShape} from '../../shapes/generalShapes';

const SwitchDropdownContainer = styled.div`
  display: flex;
`;

const isSelected = (items, selected) => {
  if (!items || !items.length) {
    return false;
  }

  return typeof items.find((item) => item.value === selected) !== 'undefined';
};

export const SwitcherOrBackButton = ({
  onChange,
  items,
  selected,
  scenarioUuid,
  envelopeUuid,
  documentUuid,
  history,
  isDailyDocumentScenario,
}) => {
  return isSelected(items, documentUuid) ? (
    <SwitchDropdown onChange={onChange} items={items} selected={selected} />
  ) : isDailyDocumentScenario ? (
    <Button onClick={() => history.goBack()}>
      <FormattedMessage id="GENERAL_BACK" />
    </Button>
  ) : (
    <Button as={Link} to={`/scenario/${scenarioUuid}/envelope/${envelopeUuid}`}>
      <FormattedMessage id="GENERAL_BACK" />
    </Button>
  );
};

SwitcherOrBackButton.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.array,
  selected: PropTypes.string,
  scenarioUuid: PropTypes.string.isRequired,
  envelopeUuid: PropTypes.string.isRequired,
  documentUuid: PropTypes.string,
  isDailyDocumentScenario: PropTypes.bool,
  history: historyShape,
};

const dropdownStyle = {width: '400px'};

export class SwitchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected || null,
    };
  }
  getActiveIndex = () => {
    return parseInt(
      this.props.items.findIndex((i) => i.value === this.state.selected)
    );
  };

  hasNext = () => {
    return this.getActiveIndex() >= this.props.items.length - 1;
  };

  hasPrev = () => {
    return this.getActiveIndex() <= 0;
  };

  onNextClick = (e) => {
    const next = this.getActiveIndex() + 1;
    const selected = this.props.items[next].value;

    this.setState({selected});
    this.props.onChange(e, selected);
  };

  onPrevClick = (e) => {
    const prev = this.getActiveIndex() - 1;
    const selected = this.props.items[prev].value;

    this.setState({selected});
    this.props.onChange(e, selected);
  };

  onChangeHandler = (e, {value}) => {
    if (this.state.selected !== value) {
      this.setState({selected: value});
      this.props.onChange(e, value);
    }
  };

  render() {
    const {items, disabled, ...rest} = this.props;
    if (!items || !items.length) {
      return null;
    }
    return (
      <SwitchDropdownContainer className="_si_doc_switch">
        <Popup
          trigger={
            <Button
              icon
              disabled={this.hasPrev() || disabled}
              onClick={this.onPrevClick}
              style={{marginRight: '0px'}}
              className="_si_doc_prev"
              color="purple">
              <Icon name="angle left" />
            </Button>
          }
          content={<FormattedMessage id="GENERAL_PREV_STATE" />}
          hideOnScroll
          on="hover"
          position="left center"
        />
        <Dropdown
          {...rest}
          disabled={disabled}
          selection
          options={items}
          value={this.state.selected}
          style={dropdownStyle}
          onChange={this.onChangeHandler}
        />
        <Popup
          trigger={
            <Button
              icon
              disabled={this.hasNext() || disabled}
              onClick={this.onNextClick}
              className="_si_doc_next"
              color="purple">
              <Icon name="angle right" />
            </Button>
          }
          content={<FormattedMessage id="GENERAL_NEXT_STATE" />}
          hideOnScroll
          on="hover"
          position="right center"
        />
      </SwitchDropdownContainer>
    );
  }
}

SwitchDropdown.propTypes = {
  selected: PropTypes.string,
  items: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
