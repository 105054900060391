import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Popup} from 'semantic-ui-react';
import './CpsBar.css';
import {FormattedMessage} from 'react-intl';
import CpsIcon from '../../../../../../CpsIcon';

export const IconInteraction = ({onClick, group, type}) => {
  const types = {
    trash: {
      icon: 'trash',
      color: 'red',
    },
    copy: {
      color: 'purple',
      icon: 'copy outline',
    },
  };

  return (
    <Button
      data-spec={`btn-int-${type}`}
      type="button"
      color={types[type].color}
      icon={types[type].icon}
      group={group}
      onClick={onClick}
      size="mini"
    />
  );
};

IconInteraction.propTypes = {
  group: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export const RootCpsInteraction = ({
  active,
  icon,
  onClick,
  tooltip,
  margin = true,
  disabled = false,
  background = 'white',
}) => {
  const containerStyle = {
    cursor: disabled ? 'default' : 'pointer',
    background,
    transition: 'border 0.4s ease',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    position: 'relative',
    textAlign: 'center',
    padding: '35px 5px 5px 5px',
    borderRadius: '.28571429rem',
    marginRight: margin ? '10px' : undefined,
    border: active ? '3px solid #56C2FF' : '3px solid rgba(34,36,38,.15)',
    width: '85px',
    height: '103px',
  };

  const className = disabled ? 'cpsBarItem disabled' : 'cpsBarItem';
  return (
    <Popup
      trigger={
        <div
          data-spec={`root-interaction-${icon}`}
          style={containerStyle}
          onClick={disabled ? undefined : onClick}
          className={className}>
          <Icon name={icon} size="big" />
        </div>
      }
      content={<FormattedMessage id={tooltip} />}
    />
  );
};

RootCpsInteraction.propTypes = {
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  margin: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  background: PropTypes.string,
};

export const RootCpsItem = ({
  type,
  group,
  active,
  num,
  onSelect,
  onClone,
  onDelete,
}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    position: 'relative',
    textAlign: 'center',
    padding: '15px 5px 5px 5px',
    borderRadius: '.28571429rem',
    border: active ? '3px solid #56C2FF' : '3px solid rgba(34,36,38,.15)',
    marginRight: '10px',
    marginBottom: '15px',
  };

  const numStyle = {
    fontWeight: 'bold',
    position: 'absolute',
    right: '7px',
    top: '3px',
  };

  return (
    <div style={containerStyle}>
      <span style={numStyle}>{num}</span>
      <CpsIcon type={type} size="40px" onClick={(e) => onSelect(e, group)} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: '5px',
          flex: 1,
        }}>
        <IconInteraction type="copy" group={group} onClick={onClone} />
        <IconInteraction type="trash" group={group} onClick={onDelete} />
      </div>
    </div>
  );
};

RootCpsItem.propTypes = {
  active: PropTypes.bool,
  group: PropTypes.string,
  num: PropTypes.number,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  type: PropTypes.string,
};

const RootCpsBar = ({children}) => {
  return <div style={{display: 'flex'}}>{children}</div>;
};

RootCpsBar.propTypes = {
  children: PropTypes.any,
};

export default RootCpsBar;
