import {set} from 'lodash';

export const toBool = (doc) => {
  const ordersLineItems = doc?.details?.ordersData?.ordersLineItems;

  if (!ordersLineItems) {
    return doc;
  }

  const newItems = doc.details.ordersData.ordersLineItems.map((item) => {
    if (!item.classifications) {
      return {
        ...item,
        classifications: [
          {content: [false], classificationSchema: 'SERIALNUMBER_MANDATORY'},
        ],
      };
    } else {
      const found = item.classifications.find((clazz) => {
        return clazz.classificationSchema === 'SERIALNUMBER_MANDATORY';
      });

      if (!found) {
        return {
          ...item,
          classifications: [
            ...item.classifications,
            {content: [false], classificationSchema: 'SERIALNUMBER_MANDATORY'},
          ],
        };
      }

      return {
        ...item,
        classifications: item.classifications.map((clazz) => {
          if (clazz.classificationSchema === 'SERIALNUMBER_MANDATORY') {
            return {
              ...clazz,
              content: clazz.content.map((cont) => {
                if (cont === 'false') {
                  return false;
                } else if (cont === 'true') {
                  return true;
                } else {
                  return cont;
                }
              }),
            };
          }
        }),
      };
    }
  });

  return set(doc, 'details.ordersData.ordersLineItems', newItems);
};

export const fromBool = (doc) => {
  const newItems = doc.details.ordersData.ordersLineItems.map((item) => {
    if (!item.classifications) {
      return {
        ...item,
        classifications: [
          {content: ['false'], classificationSchema: 'SERIALNUMBER_MANDATORY'},
        ],
      };
    } else {
      const found = item.classifications.find((clazz) => {
        return clazz.classificationSchema === 'SERIALNUMBER_MANDATORY';
      });

      if (!found) {
        return {
          ...item,
          classifications: [
            ...item.classifications,
            {
              content: ['false'],
              classificationSchema: 'SERIALNUMBER_MANDATORY',
            },
          ],
        };
      }

      return {
        ...item,
        classifications: item.classifications.map((clazz) => {
          if (clazz.classificationSchema === 'SERIALNUMBER_MANDATORY') {
            return {
              ...clazz,
              content: clazz.content.map((cont) => {
                if (cont === false) {
                  return 'false';
                } else if (cont === true) {
                  return 'true';
                } else {
                  return cont;
                }
              }),
            };
          }
        }),
      };
    }
  });

  return set(doc, 'details.ordersData.ordersLineItems', newItems);
};
