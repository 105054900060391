import React, {Fragment} from 'react';
import {DataGrid, Pagination} from '@ecosio/components';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import TaskStatusLabel from './TaskStatusLabel';
import TaskDateLabel from './TaskDateLabel';
import TaskActionsButtonGroup from './TaskActionsButtonGroup';
import './TasksDatagrid.css';

const defaultDataRender = (Table, value) => (
  <Table.Cell collapsing textAlign="center">
    {value ? value : <FormattedMessage id="-" />}
  </Table.Cell>
);

const dateRender = (Table, value) => {
  return (
    <Table.Cell collapsing textAlign="center">
      <TaskDateLabel dateString={value} />
    </Table.Cell>
  );
};

const statusRender = (Table, value) => {
  return (
    <Table.Cell collapsing textAlign="center">
      <TaskStatusLabel status={value}></TaskStatusLabel>
    </Table.Cell>
  );
};

const actionRender = (Table, value) => {
  return (
    <Table.Cell collapsing textAlign="center">
      <TaskActionsButtonGroup action={value} />
    </Table.Cell>
  );
};

export const getDynamicCell = (field) => {
  if (!field) {
    return undefined;
  }

  const Cell = {
    id: 'test',
    label: '',
    hidden: false,
    hideable: true,
    sortable: field?.sortable,
    render: defaultDataRender,
  };

  if (field?.tableSort) {
    Cell.sort = field?.tableSort;
  }

  if (field?.path) {
    Cell.id = field?.path;
  }

  if (field?.input?.label) {
    Cell.label = field?.input?.label;
  }

  if (field?.type === 'date') {
    Cell.render = dateRender;
  }

  if (field?.path === 'status') {
    Cell.render = statusRender;
  }

  if (field?.path === 'action') {
    Cell.render = actionRender;
  }

  return Cell;
};

const DashboardTasksDataGrid = ({stats, isLoading, gridConfig}) => {
  const fieldsConfig = {fields: []};
  Object.keys(gridConfig).forEach((path) => {
    const field = gridConfig[path];
    if (getDynamicCell(field)) {
      fieldsConfig.fields.push(getDynamicCell(field));
    }
  });

  const renderFooter = ({Table, cols}) => {
    return (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={cols}>
            <Pagination paging={stats.pageable} />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  };

  return (
    <div className="tasksDataGrid">
      <DataGrid
        data-spec="dashboard-tasks-datagrid"
        data={stats.content}
        config={fieldsConfig}
        loading={isLoading}
        renderFooter={renderFooter}
        translated
      />
    </div>
  );
};

const DashboardTasksTable = ({
  stats,
  isLoading,
  renderConfig,
  currentTheme,
}) => {
  return (
    <Fragment>
      {!isLoading && stats && (
        <DashboardTasksDataGrid
          stats={stats.tasks}
          isLoading={isLoading}
          gridConfig={renderConfig.dataGridColumnFields}
          currentTheme={currentTheme}
          // rowFormatter - custom style
        />
      )}
    </Fragment>
  );
};

DashboardTasksTable.propTypes = {
  renderConfig: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  currentTheme: PropTypes.object,
};

export default DashboardTasksTable;
