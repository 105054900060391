import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {get} from 'lodash';
import {Message, Button, Segment, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {FormatDateTime} from '@ecosio/components';
import {SwitcherOrBackButton} from '../../SwitchDropdown';
import {DynamicMessage} from '../../../../helpers/translate';
import {
  STATUS_READ,
  STATUS_ARCHIVED,
} from '../../../../shapes/supportedEnvelopeStati';
import DocumentHeader from '../../DocumentHeader';
import DownloadPdfButton from '../../Download/DownloadPdfButton';
import DrawingDownloads from '../../Download/DrawingDownloads';
import {documentExchangeShape} from '../../../../shapes/documentExchange';
import {pageConfigShape, scenarioShape} from '../../../../shapes/scenarios';
import OrderLineItemViewer from '../LineItems/OrderLineItemViewer';
import LineItemTable from '../LineItems/LineItemTable';
import CustomPropertyEditor from '../../CustomPropertyEditor';
import ConfirmPopup from '../../../ConfirmPopup/index';
import CreateDocumentButtonBar from '../../Turnaround/CreateDocumentButtonBar';
import {getDocumentExchange} from '../../../../reducers/fetcher';
import {INDUSTRY_ORDER} from '../../../../shapes/supportedDocTypes';
import {
  shouldRecalculateLineItems,
  updateResponseDocument,
  createExchangePayload,
} from '../../../../reducers/documentExchange';
import {preProcessOrder} from '../../../../preprocessors/ordersPreprocessor';
import DocumentMeta from './DocumentMeta';
import OrderMeta from './OrderMeta';

export const StatusPopup = ({status, onArchive, loading, pageConfig}) => {
  const isArchived = status === 'ARCHIVED';

  const customerSpecificTranslations = pageConfig?.customerSpecificTranslations;
  const businessCaseArchiveTranslation = get(
    customerSpecificTranslations,
    'BUSINESSCASE_ARCHIVE',
    'BUSINESSCASE_ARCHIVE'
  );

  const businessCaseArchiveConfirmDoTranslation = get(
    customerSpecificTranslations,
    'ARCHIVE_CONFIRM_DO',
    'ARCHIVE_CONFIRM_DO'
  );

  const businessCaseArchiveConfirmUndoTranslation = get(
    customerSpecificTranslations,
    'ARCHIVE_CONFIRM_UNDO',
    'ARCHIVE_CONFIRM_UNDO'
  );

  const businessCaseArchiveDoButtonTranslation = get(
    customerSpecificTranslations,
    'ARCHIVE_BUTTON_DO',
    'ARCHIVE_BUTTON_DO'
  );

  const businessCaseArchiveUndoButtonTranslation = get(
    customerSpecificTranslations,
    'ARCHIVE_BUTTON_UNDO',
    'ARCHIVE_BUTTON_UNDO'
  );

  if (isArchived) {
    return (
      <ConfirmPopup
        dialogtitle={<FormattedMessage id={businessCaseArchiveTranslation} />}
        dialogcontent={
          <FormattedMessage id={businessCaseArchiveConfirmUndoTranslation} />
        }
        onConfirm={onArchive}
        position="left center"
        trigger={
          <Button
            content={
              <FormattedMessage id={businessCaseArchiveUndoButtonTranslation} />
            }
            data-spec="unarchive-button"
            type="button"
            primary
            icon="file alternate outline"
            size="mini"
            loading={loading}
          />
        }
      />
    );
  } else {
    return (
      <ConfirmPopup
        dialogtitle={<FormattedMessage id={businessCaseArchiveTranslation} />}
        dialogcontent={
          <FormattedMessage id={businessCaseArchiveConfirmDoTranslation} />
        }
        onConfirm={onArchive}
        position="left center"
        trigger={
          <Button
            content={
              <FormattedMessage id={businessCaseArchiveDoButtonTranslation} />
            }
            type="button"
            primary
            data-spec="archive-button"
            icon="archive"
            size="mini"
            loading={loading}
          />
        }
      />
    );
  }
};

export default class OrdersTemplate extends React.Component {
  constructor(props) {
    super(props);

    const {envelope} = this.props;
    this.state = {
      dropdownItems: this.formatDropdownItems(
        this.props.envelope.data.selectableDocuments
      ),
      customProperties: this.formatCustomProperties(
        envelope.data.customProperties
      ),

      metaDocuments: envelope.data.metaDocuments,
      propertyEditor: {
        open: false,
      },

      orderLineItemViewer: {
        open: false,
        index: 0,
      },
      ordersDocumentExchange: null,
    };
  }

  componentDidMount() {
    const {documentExchange} = this.props;

    const pageConfig = documentExchange.pageConfig;
    if (pageConfig?.showOrdchgChangedLineItemState) {
      this.fetchOrdersDocument();
    }
  }

  fetchOrdersDocument = () => {
    const {envelopeUuid, scenario, envelope} = this.props;
    const envelopeDocuments = envelope?.data?.documents || [];

    const orderDocumentUuid = envelopeDocuments.reduce((acc, curr) => {
      const documentTypeId = curr?.documentTypeId;
      const documentUuid = curr?.uuid;

      if (documentTypeId === INDUSTRY_ORDER) {
        acc = documentUuid;
      }

      return acc;
    }, undefined);

    if (!envelopeDocuments.length || !orderDocumentUuid) {
      return;
    }

    getDocumentExchange(envelopeUuid, orderDocumentUuid)
      .then((res) => {
        let responseDoc = res?.data?.document;
        const documentType = res?.data?.meta?.documentTypeId;
        // only recalculate if amount calculation is configured
        const shouldCalc = shouldRecalculateLineItems(scenario, documentType);

        responseDoc = shouldCalc ? preProcessOrder(responseDoc) : responseDoc;

        res = updateResponseDocument(res, responseDoc);
        const exchangePayload = createExchangePayload(res?.data, scenario);
        if (typeof exchangePayload === 'undefined') {
          this.setState({
            ordersDocumentExchange: null,
          });
        } else {
          this.setState({
            ordersDocumentExchange: exchangePayload,
          });
        }
      })
      .catch((error) => {
        const errorMessage = error?.message;

        if (errorMessage) {
          console.error(`Fetching document failed: ${error?.message}`, error);
        } else if (error) {
          console.error(
            `Fetching document failed for scenario : ${scenario?.uuid}`,
            error
          );
        } else {
          console.error(
            `Fetching document failed and error object is undefined for scenario : 
          ${scenario?.uuid}
        `
          );
        }
      });
  };

  formatCustomProperties = (props) =>
    props.reduce((acc, item) => {
      acc[item.path] = item.value;
      return acc;
    }, {});

  onSelect = (e, {index}) => {
    this.setState({
      orderLineItemViewer: {
        open: true,
        index,
      },
    });
  };

  onSelectClose = () => {
    this.setState((prevState) => ({
      orderLineItemViewer: {
        ...prevState.orderLineItemViewer,
        open: false,
      },
    }));
  };

  onSubmitPropertyEditor = (values) => {
    const props = Object.keys(values).map((i) => ({
      path: i,
      value: values[i],
    }));

    this.props
      .postEnvelopeProperties(props)
      .then((action) => {
        if (action.error) {
          this.setState((prevState) => ({
            propertyEditor: {
              ...prevState.propertyEditor,
              error: action.error,
            },
          }));
        } else {
          this.setState({
            customProperties: values,
            propertyEditor: {
              open: false,
              error: null,
            },
          });
        }
      })
      .catch((err) => console.error(err));
  };

  onTogglePropertyEditor = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState((prevState) => ({
      propertyEditor: {
        ...prevState.modal,
        open: !prevState.propertyEditor.open,
      },
    }));
  };

  onClickMetaDocument = (uuid) => {
    const {scenarioUuid, envelopeUuid} = this.props;
    this.props.history.push(
      `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${uuid}`
    );
  };

  onChangeDropdown = (e, value) => {
    const {scenarioUuid, envelopeUuid} = this.props;
    this.props.history.push(
      `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${value}`
    );
  };

  onClickArchive = (e) => {
    e.preventDefault();
    const {envelope} = this.props;
    const newStatus =
      envelope.data.status === STATUS_ARCHIVED ? STATUS_READ : STATUS_ARCHIVED;
    this.props.postEnvelopeStatus(newStatus);
  };

  // TODO: this could be extracted and reused
  formatDropdownItems = (data) => {
    return (
      data.map((item, idx) => ({
        key: idx,
        text: (
          <div>
            <DynamicMessage id={item.documentTypeId} />{' '}
            <DynamicMessage id="GENERAL_FROM" />{' '}
            <FormatDateTime stripTimezone={false} dateString={item.createdAt} />
          </div>
        ),
        value: item.uuid,
        content: (
          <div>
            <strong>
              <DynamicMessage id={item.documentTypeId} />
            </strong>
            <div>
              <FormatDateTime
                stripTimezone={false}
                dateString={item.createdAt}
              />
            </div>
          </div>
        ),
      })) || []
    );
  };

  render() {
    const {
      documentUuid,
      scenario,
      scenarioUuid,
      envelope,
      envelopeUuid,
      documentExchange,
    } = this.props;

    const {ordersDocumentExchange} = this.state;

    const pageConfig = documentExchange.pageConfig;

    const messageId = get(documentExchange, 'data.meta.erpelMessageId');

    const documentTypeId = get(documentExchange, 'data.meta.documentTypeId');

    const currency = get(
      documentExchange,
      'data.document.header.referenceCurrency'
    );

    const isArchived = envelope.data.status === STATUS_ARCHIVED;

    return (
      <div className="_si_order_view">
        <Helmet>
          <title>ecosio GmbH</title>
        </Helmet>
        <CustomPropertyEditor
          correlatorId={envelope.data.correlatorId}
          open={this.state.propertyEditor.open}
          error={envelope.props.error}
          posting={envelope.props.posting}
          onSubmit={this.onSubmitPropertyEditor}
          onCancel={this.onTogglePropertyEditor}
          items={this.state.customProperties}
          formFields={scenario.customPropertiesConfig.formFields}
        />

        <OrderLineItemViewer
          open={this.state.orderLineItemViewer.open}
          onClose={this.onSelectClose}
          currency={currency}
          pageConfig={pageConfig}
          lineitem={
            documentExchange.data.document.details.ordersData.ordersLineItems[
              this.state.orderLineItemViewer.index
            ]
          }
        />

        <React.Fragment>
          <DocumentHeader
            messageId={messageId}
            envelope={envelope.data}
            scenario={scenario}
            pageConfig={pageConfig}
            documentExchange={documentExchange}
            metaDocuments={this.state.metaDocuments}
            correlatorId={envelope.data.correlatorId}
            envelopeStatus={envelope.data.status}
            customProperties={this.state.customProperties}>
            <Fragment>
              {!isArchived && scenario.customPropertiesConfig.enabled ? (
                <Button
                  type="button"
                  primary
                  icon
                  labelPosition="left"
                  onClick={this.onTogglePropertyEditor}>
                  <Icon name="edit" />
                  <FormattedMessage id="BUSINESSCASE_EDIT" />
                </Button>
              ) : null}
              <StatusPopup
                pageConfig={pageConfig}
                loading={envelope.status.posting}
                onArchive={this.onClickArchive}
                status={envelope.data.status}
              />
            </Fragment>
          </DocumentHeader>

          {isArchived ? (
            <Message warning>
              <Message.Header>
                <FormattedMessage id="ARCHIVED_BC_WARN_TITLE" />
              </Message.Header>
              <p>
                <FormattedMessage id="ARCHIVED_BC_WARN_CONTENT" />
              </p>
            </Message>
          ) : null}

          {envelope.status.error ? (
            <Message warning>
              <Message.Header>
                <FormattedMessage id="GENERAL_ERROR" />
              </Message.Header>
              <p>{envelope.status.error}</p>
            </Message>
          ) : null}

          <Segment attached="top" clearing>
            <SwitcherOrBackButton
              onChange={this.onChangeDropdown}
              items={this.state.dropdownItems}
              selected={documentUuid}
              scenarioUuid={scenarioUuid}
              envelopeUuid={envelopeUuid}
              documentUuid={documentUuid}
            />
            <Button.Group floated="right">
              <DownloadPdfButton
                dataSpec="createDocumentBar-download-button"
                currentDocumentUuid={documentUuid}
                metaDocuments={this.state.metaDocuments}
                envelopeUuid={envelopeUuid}
                scenario={scenario}
                currentDocumentTypeID={documentTypeId}
              />
              <CreateDocumentButtonBar
                deliveryDates={documentExchange.deliveryDates?.data}
                hide={false}
                envelopeStatus={envelope.data.status}
                scenario={scenario}
                envelopeUuid={envelopeUuid}
                envelope={envelope.data}
                documentUuid={documentUuid}
                docType={documentExchange.data.meta.documentTypeId}
                pageConfig={pageConfig}
              />
            </Button.Group>
          </Segment>
          <Segment attached>
            <div>
              <OrderMeta
                documentExchange={documentExchange.data}
                pageConfig={pageConfig}
                scenario={scenario}
              />
              <LineItemTable
                data={documentExchange.data.document}
                lineItemTrackingData={
                  documentExchange.data.lineItemStateTrackingData
                }
                pageConfig={pageConfig}
                currency={currency}
                onSelect={this.onSelect}
                ordersDocument={ordersDocumentExchange?.data?.document}
              />
            </div>
          </Segment>
          <DocumentMeta
            envelopeUuid={envelopeUuid}
            metaDocuments={this.state.metaDocuments}
            onSelect={this.onClickMetaDocument}
            pageConfig={pageConfig}
            scenario={scenario}
            location={this.props?.history?.location}
          />
          <DrawingDownloads
            correlator={envelope.data.correlatorId}
            sender={envelope.data.offeringCompanyUuid}
            receiver={envelope.data.usingCompanyUuid}
          />
        </React.Fragment>
      </div>
    );
  }
}

OrdersTemplate.propTypes = {
  postEnvelopeProperties: PropTypes.func,
  documentUuid: PropTypes.string,
  envelopeUuid: PropTypes.string,
  scenarioUuid: PropTypes.string,
  scenario: scenarioShape,
  envelope: PropTypes.object,
  history: PropTypes.object,
  postEnvelopeStatus: PropTypes.func.isRequired,
  documentExchange: PropTypes.shape({
    data: documentExchangeShape,
    pageConfig: pageConfigShape,
  }),
};
