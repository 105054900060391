import uuid from 'uuid-random';

export const newNVE = () => {
  return {
    numberOfPackages: 1,
    packageTypeCustomer: null,
    quantityPerPack: 0,
    identifications: [
      {
        identityNumber: uuid(),
      },
    ],
  };
};
