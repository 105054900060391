import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {set, get, cloneDeep, flowRight} from 'lodash';
import {parse} from 'qs';
import {Loader, Header, Button, Message, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {toast, intlShape} from '@ecosio/components';
import {
  fetchTurnaroundDocument,
  postDocumentToEnvelope,
} from '../../../../../../reducers/turnaround';
import {
  pageConfigShape,
  scenarioShape,
} from '../../../../../../shapes/scenarios';
import {envelopesShape} from '../../../../../../shapes/envelopes';
import {submitForm} from '../../../../../../util/formUtil';
import {INDUSTRY_DESADV} from '../../../../../../shapes/supportedDocTypes';
import ReadOnlyDesadvPackagingInformationModal from '../View/DesadvPackagingInformationModal';
import {
  FILTER_OUT,
  FILTER_OUT_VALUE_TRUE,
  FILTER_OUT_VALUE_FALSE,
  STANDARD_TOAST_DURATION,
} from '../../../../../../helpers/constants';
import DocumentMeta from '../../CommonComponents/Create/Header/DocumentMeta';
import {
  ContextWrapper,
  DocumentMetaContext,
  HEADER_FORM_ID,
} from '../../CommonComponents/Create/Header/OrderHeaderInformation/EditFormFields/EditForm';
import {
  HeaderContainer,
  TemplateContainer,
  TemplateSubContainer,
} from '../../CommonComponents/Create/Header/StyledDivs';
import {
  buildComplexCps,
  buildSimpleDesadvWithComplexStructure,
} from '../../CommonComponents/SimpleWithComplexStructureFunctions/cpsTransform';
import {buildStructuredCps} from '../../CommonComponents/SimpleV2Functions/simpleV2DesadvCpsTransform';
import {duplicateCps} from '../../CommonComponents/CommonFuntions/cpsTransformBeforeModal';
import {jexlWithTransformFuctions} from '../../../../../../util/jexlWithTransformFunctions';
import DocumentDraftSendButtonOptions from '../../../../../DocumentDrafts/DocumentDraftSendButtonOptions';
import {
  deleteDraftWithUuid,
  saveDocumentDraftWithScheduledDate,
  saveDocumentDraftWithSendingDelay,
} from '../../../../../DocumentDrafts/draftRestCalls';
import {
  isSendingDelayFromWebEdiSettingsEnabled,
  triggerDelayErrorToast,
} from '../../../../../DocumentDrafts/draftUtils';
import {
  DRAFTS_DELAY_TIME,
  DRAFTS_DELAY_TIME_IN_MS_FALLBACK,
} from '../../../../../../constants';
import {modifyDeliveryLineItems} from '../View/ViewDesadvSimpleTemplateV2';
import DocumentDraftInfoHeader, {
  ROOT_CONTAINER,
} from '../../../../../DocumentDrafts/DocumentDraftInfoHeader';
import DesadvLineItemsForm, {
  DESADV_LINEITEM_FORM_ID,
} from './LineItems/DesadvLineItemsForm';
import DesadvPackagingInformationModal from './DesadvPackagingInformationModalComplexStructure';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 35px;
`;

export const sortLineItems = (res = []) => {
  //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/280
  return res.sort((a, b) => {
    if (
      (a?.partialDelivery === true && a?.dispatchQuantity?.value === 0) ||
      (b?.partialDelivery === true && b?.dispatchQuantity?.value === 0)
    ) {
      if (a?.partialDelivery === true && a?.dispatchQuantity?.value === 0) {
        //check if 'FILTER_OUT' property already exists
        if (
          a.detailsProperties.filter((d) => d.key === FILTER_OUT).length === 1
        ) {
          a.detailsProperties.filter(
            (d) => d.key === FILTER_OUT
          )[0].value = FILTER_OUT_VALUE_TRUE;
        } else {
          a.detailsProperties.push({
            key: FILTER_OUT,
            value: FILTER_OUT_VALUE_TRUE,
          });
        }

        return 1;
      }

      if (b?.partialDelivery === true && b?.dispatchQuantity?.value === 0) {
        //check if 'FILTER_OUT' property already exists
        if (
          b.detailsProperties.filter((d) => d.key === FILTER_OUT).length === 1
        ) {
          b.detailsProperties.filter(
            (d) => d.key === FILTER_OUT
          )[0].value = FILTER_OUT_VALUE_TRUE;
        } else {
          b.detailsProperties.push({
            key: FILTER_OUT,
            value: FILTER_OUT_VALUE_TRUE,
          });
        }
        return -1;
      }
    } else {
      if (
        a.detailsProperties.filter((d) => d.key === FILTER_OUT).length === 1
      ) {
        a.detailsProperties.filter(
          (d) => d.key === FILTER_OUT
        )[0].value = FILTER_OUT_VALUE_FALSE;
      }
      if (
        b.detailsProperties.filter((d) => d.key === FILTER_OUT).length === 1
      ) {
        b.detailsProperties.filter(
          (d) => d.key === FILTER_OUT
        )[0].value = FILTER_OUT_VALUE_FALSE;
      }
      let firstAgreementOrOrderLineNumber =
        a?.references?.agreementOrOrderLineNumber;
      let secondAgreementOrOrderLineNumber =
        b?.references?.agreementOrOrderLineNumber;

      if (firstAgreementOrOrderLineNumber && secondAgreementOrOrderLineNumber) {
        // If both are numbers - cast them to have natural number sorting
        if (
          !isNaN(firstAgreementOrOrderLineNumber) &&
          !isNaN(secondAgreementOrOrderLineNumber)
        ) {
          firstAgreementOrOrderLineNumber = +firstAgreementOrOrderLineNumber;
          secondAgreementOrOrderLineNumber = +secondAgreementOrOrderLineNumber;
        }
        //sort by orderLineNumber, when partdelivery and dispatchQuantity is not null
        if (
          firstAgreementOrOrderLineNumber > secondAgreementOrOrderLineNumber
        ) {
          return 1;
        }

        if (
          firstAgreementOrOrderLineNumber < secondAgreementOrOrderLineNumber
        ) {
          return -1;
        }
      }
    }

    return 0;
  });
};

const mapDispatchToProps = (dispatch) => ({
  postDocumentToEnvelope: (
    uuid,
    content,
    actionType,
    scenarioUuid,
    scenarioChecksum
  ) =>
    dispatch(
      postDocumentToEnvelope(
        uuid,
        content,
        actionType,
        scenarioUuid,
        scenarioChecksum
      )
    ),

  fetchTurnaround: (
    scenario,
    envelopeUuid,
    documentMetaUuid,
    documentType,
    filterParams = {}
  ) =>
    dispatch(
      fetchTurnaroundDocument(
        scenario,
        envelopeUuid,
        documentMetaUuid,
        documentType,
        filterParams
      )
    ),
});

const mapStateToProps = ({turnaround}) => ({turnaround});

class DesadvSimpleTemplateV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showSendButton: false,
      fetching: true,
      documentExchange: {},
      sscc: false,
      sending: false,
      showAll: false,
      partialDeliveryAllowed: false,
      lineItemsCache: {},
      exposedValues: {
        header: {},
        deliveryLineItems: [],
      },
      preventSendingOfDocument: false,
      currentDraftData: null,
    };
  }

  componentDidMount() {
    const {match, scenario, envelope, location} = this.props;
    const params = parse(this.props.location.search.substring(1)) || {};

    if (location?.state?.draftDocumentExchange) {
      this.modifyAndSetDraftData(location, envelope);
    } else {
      this.props
        .fetchTurnaround(
          scenario,
          match.params.envelopeUuid,
          match.params.documentUuid,
          match.params.documentType,
          params
        )
        .then((action) => {
          this.setState({
            documentExchange: action,
            fetching: false,
            sscc: envelope?.data?.documentData?.SSCC_REQUIRED === 'true',
            showSendButton: false,
            partialDeliveryAllowed:
              envelope?.data?.documentData?.PART_DELIVERY_ALLOWED === 'true',
          });
        })
        .catch((err) => console.error(err));
    }
  }

  componentDidUpdate() {
    const {pageConfig} = this.props;

    if (pageConfig?.docPreventSendingConfiguration?.preventSendingExpression) {
      this.checkIfSendingShouldBePrevented();
    }
  }

  /**
   * the desadv needs a "unstructured " form of desadv
   * to be able to work with this edit template.
   * We modify here the document exchange(complex desadv structured) which was saved as "draft" to
   * "unstructured" form to be able to edit it again.
   *
   */
  modifyAndSetDraftData = (location, envelope) => {
    const delis =
      modifyDeliveryLineItems(
        {
          data: location?.state?.draftDocumentExchange,
        },
        envelope?.data?.documentData?.SSCC_REQUIRED === 'true'
      ) || [];

    const modifiedDelis = delis.map((item) => {
      const isParialdelivery = item?.partialDelivery === 'true' ? true : false;
      return {
        ...item,
        partialDelivery: isParialdelivery,
      };
    });
    this.setState({
      documentExchange: {
        data: {
          ...location?.state?.draftDocumentExchange,
          document: {
            ...location?.state?.draftDocumentExchange.document,
            details: {
              ...location?.state?.draftDocumentExchange.document.details,
              dispatchData: {
                ...location?.state?.draftDocumentExchange.document.details
                  .dispatchData,
                consignmentPackagingSequences: [
                  {
                    ...location?.state?.draftDocumentExchange.document.details
                      .dispatchData?.consignmentPackagingSequences[0],
                    deliveryLineItems: modifiedDelis,
                  },
                ],
              },
            },
          },
        },
      },
      currentDraftData: location?.state?.draftData,
      fetching: false,
      sscc: envelope?.data?.documentData?.SSCC_REQUIRED === 'true',
      showSendButton: false,
      partialDeliveryAllowed:
        envelope?.data?.documentData?.PART_DELIVERY_ALLOWED === 'true',
    });
  };

  /***
   * checks if in delis all partialDeliveries are true and dispatchQuantities are 0
   *
   * if true => toast is triggered, sendButton is disabled
   * if it was true in prev state and then false in currentState => trigger toast and allow sendButton
   */
  checkIfSendingShouldBePrevented = () => {
    const {exposedValues, preventSendingOfDocument} = this.state;
    const {intl, pageConfig} = this.props;
    const expression =
      pageConfig?.docPreventSendingConfiguration?.preventSendingExpression;

    const preventSending = jexlWithTransformFuctions(
      `${expression}`,
      exposedValues
    );

    if (preventSending) {
      toast({
        title: intl.formatMessage({
          id:
            'DESADV_ALL_PARTIAL_DEL_TRUE_AND_DISPATCH_QUANTITY_ZERO_PREVENTED_SENDING_TITLE',
        }),
        description: intl.formatMessage({
          id:
            'DESADV_ALL_PARTIAL_DEL_TRUE_AND_DISPATCH_QUANTITY_ZERO_PREVENTED_SENDING_DESCRIPTION',
        }),
        type: 'warning',
      });
      if (!preventSendingOfDocument) {
        this.setState({
          preventSendingOfDocument: true,
        });
      }
    } else {
      if (preventSendingOfDocument) {
        toast({
          title: intl.formatMessage({
            id:
              'DESADV_ALL_PARTIAL_DEL_NOT_TRUE_OR_DISPATCH_QUANTITY_NOT_ZERO_SENDING_ALLOWED_TITLE',
          }),
          description: intl.formatMessage({
            id:
              'DESADV_ALL_PARTIAL_DEL_NOT_TRUE_OR_DISPATCH_QUANTITY_NOT_ZERO_SENDING_ALLOWED_DESCRIPTION',
          }),
          type: 'success',
          time: STANDARD_TOAST_DURATION,
        });
        this.setState({
          preventSendingOfDocument: false,
        });
      }
    }
  };

  onModalCancel = (e, formContext) => {
    const dispatchData = formContext.form.getState().values;

    //Save state even when clicked on cancel
    this.setState((prevState) => ({
      modal: false,
      documentExchange: {
        ...prevState.documentExchange,
        data: {
          ...prevState.documentExchange.data,
          document: {
            ...prevState.documentExchange.data.document,
            details: {
              ...prevState.documentExchange.data.document.details,
              dispatchData: {
                ...prevState.documentExchange.data.document.details
                  .dispatchData,
                consignmentPackagingSequences: dispatchData?.consignmentPackagingSequences.map(
                  (cps, index) => {
                    return {
                      ...prevState.documentExchange.data.document.details
                        .dispatchData.consignmentPackagingSequences[index],
                      ...cps,
                    };
                  }
                ),
              },
            },
          },
        },
      },
    }));
  };

  onModalSave = (dispatchData) => {
    const {intl} = this.props;
    const res = sortLineItems(
      dispatchData?.consignmentPackagingSequences[0]?.deliveryLineItems
    );

    const showToast =
      res[res.length - 1]?.detailsProperties.filter((d) => d.key === FILTER_OUT)
        .length > 0;

    if (showToast) {
      toast({
        title: intl.formatMessage({
          id: 'SORTED_DESADV_NULL_POSITION_TO_LAST_TITLE',
        }),
        description: intl.formatMessage({
          id: 'SORTED_DESADV_NULL_POSITION_TO_LAST_DESCRIPTION',
        }),
        type: 'warning',
        time: STANDARD_TOAST_DURATION,
      });
    }

    this.setState((prevState) => ({
      showAll: !prevState.showAll,
      modal: false,
      showSendButton: true,
      documentExchange: {
        ...prevState.documentExchange,
        data: {
          ...prevState.documentExchange.data,
          document: {
            ...prevState.documentExchange.data.document,
            details: {
              ...prevState.documentExchange.data.document.details,
              dispatchData: {
                ...prevState.documentExchange.data.document.details
                  .dispatchData,
                consignmentPackagingSequences: dispatchData?.consignmentPackagingSequences.map(
                  (cps, index) => {
                    if (index === 0) {
                      return {
                        ...prevState.documentExchange.data.document.details
                          .dispatchData.consignmentPackagingSequences[0],
                        ...dispatchData.consignmentPackagingSequences[0],
                        deliveryLineItems: res,
                      };
                    }
                    return {
                      ...prevState.documentExchange.data.document.details
                        .dispatchData.consignmentPackagingSequences[index],
                      ...cps,
                    };
                  }
                ),
              },
            },
          },
        },
      },
    }));
  };

  noSsccSubmit = async (result) => {
    let deliveryLineItems = {
      deliveryLineItems:
        Object.keys(result).length && result?.deliveryLineItems?.length
          ? result.deliveryLineItems
          : this.state.documentExchange.data.document.details.dispatchData
              .consignmentPackagingSequences[0].deliveryLineItems,
    };

    deliveryLineItems = sortLineItems(deliveryLineItems.deliveryLineItems);

    let positionNumberCounter = 0;
    deliveryLineItems = deliveryLineItems.map((item) => ({
      ...item,
      positionNumber: `${++positionNumberCounter}`,
    }));

    await this.setState((prevState) => ({
      showAll: true,
      showSendButton: true,
      modal: false,
      documentExchange: {
        ...prevState.documentExchange,
        data: {
          ...prevState.documentExchange.data,
          document: {
            ...prevState.documentExchange.data.document,
            details: {
              ...prevState.documentExchange.data.document.details,
              dispatchData: {
                ...prevState.documentExchange.data.document.details
                  .dispatchData,
                consignmentPackagingSequences: [
                  {
                    hierarchicalParentID: '1',
                    deliveryLineItems: deliveryLineItems,
                  },
                ],
              },
            },
          },
        },
      },
    }));

    const {showAll, documentExchange} = this.state;
    const {intl} = this.props;

    if (showAll) {
      const delItems = get(
        documentExchange,
        'data.document.details.dispatchData.consignmentPackagingSequences[0].deliveryLineItems',
        []
      );
      const showToast =
        delItems[delItems.length - 1]?.detailsProperties.filter(
          (d) => d.key === FILTER_OUT
        ).length > 0;

      if (showToast) {
        toast({
          title: intl.formatMessage({
            id: 'SORTED_DESADV_NULL_POSITION_TO_LAST_TITLE',
          }),
          description: intl.formatMessage({
            id: 'SORTED_DESADV_NULL_POSITION_TO_LAST_DESCRIPTION',
          }),
          type: 'warning',
          time: STANDARD_TOAST_DURATION,
        });
      }
    }
  };

  ssccSubmit = async (result) => {
    const {pageConfig, intl} = this.props;
    const {documentExchange, sscc} = this.state;
    const openModal = sscc;
    const cpsConfigs = pageConfig?.cpsConfigs;

    const document = documentExchange?.data?.document || {};
    const consignmentPackagingSequences =
      document?.details?.dispatchData?.consignmentPackagingSequences || [];
    const prevLineItems =
      consignmentPackagingSequences[0]?.deliveryLineItems || [];
    const currentLinItems = result?.deliveryLineItems || [];

    //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/653#note_301137
    if (document?.header?.businessCaseType === 'X2' && currentLinItems.length) {
      const allLinsAreNullPositions = currentLinItems.every(
        (lin) => Number(lin?.dispatchQuantity?.value) === 0
      );

      if (allLinsAreNullPositions) {
        set(
          document,
          'details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages',
          0
        );
      }

      const totalPackages = get(
        document,
        'details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages'
      );

      if (!allLinsAreNullPositions && Number(totalPackages) === 0) {
        toast({
          title: intl.formatMessage({
            id:
              'COOP_TOAST_TOTAL_PAC_ZERO_AND_DISPATCH_QUANTITY_NOT_ZERO_TITLE',
          }),
          description: intl.formatMessage({
            id:
              'COOP_TOAST_TOTAL_PAC_ZERO_AND_DISPATCH_QUANTITY_NOT_ZERO_DESCRIPTION',
          }),
          type: 'warning',
          time: STANDARD_TOAST_DURATION,
        });
        return;
      }
    }
    const modifiedDeliveryLineItems = await duplicateCps(
      prevLineItems,
      currentLinItems,
      cpsConfigs,
      document
    );

    let cpsesWithoutRoot = undefined;

    if (document?.header?.businessCaseType === 'X2') {
      cpsesWithoutRoot = await buildSimpleDesadvWithComplexStructure(
        consignmentPackagingSequences,
        cpsConfigs
      );
    }

    this.setState((prevState) => ({
      modal: openModal,
      documentExchange: {
        ...prevState.documentExchange,
        data: {
          ...prevState.documentExchange.data,
          document: {
            ...prevState.documentExchange.data.document,
            details: {
              ...prevState.documentExchange.data.document.details,
              dispatchData: {
                ...prevState.documentExchange.data.document.details
                  .dispatchData,
                consignmentPackagingSequences: [
                  {
                    hierarchicalID: '1',
                    ...prevState.documentExchange.data.document.details
                      .dispatchData.consignmentPackagingSequences[0],
                    deliveryLineItems: modifiedDeliveryLineItems,
                  },
                  ...(cpsesWithoutRoot || []),
                ],
              },
            },
          },
        },
      },
    }));
  };

  prepareSendSsccDesadv = () => {
    const documentExchange = cloneDeep(this.state.documentExchange);

    const dispatchData = get(
      documentExchange,
      'data.document.details.dispatchData'
    );

    const document = documentExchange?.data?.document || {};
    const businessCaseType = document?.header?.businessCaseType;

    const cpses =
      businessCaseType === 'X2'
        ? buildComplexCps(dispatchData)
        : buildStructuredCps(dispatchData);

    if (!cpses) {
      console.error(
        'Building Structured Desadv failed, canceled sending desadv'
      );
      return;
    }

    set(
      documentExchange,
      'data.document.details.dispatchData.consignmentPackagingSequences',
      cpses
    );

    return documentExchange;
  };

  postDesadv = (documentExchange) => {
    const {
      match: {
        params: {scenarioUuid, envelopeUuid},
      },
      history,
      scenario,
      pageConfig,
      envelope,
    } = this.props;
    this.setState({sending: true});

    this.props
      .postDocumentToEnvelope(
        envelopeUuid,
        documentExchange.data,
        'SEND',
        scenarioUuid,
        scenario?.scenarioChecksum
      )
      .then((res) => {
        const erpelMessageId = res?.documentMetadata?.erpelMessageId;
        this.setState({sending: false});
        history.push({
          pathname: `/scenario/${scenarioUuid}/envelope/${envelopeUuid}`,
          state: {
            message: 'GENERAL_DOCUMENT_SENT',
            status: 'info',
            erpelMessageId: erpelMessageId,
            downloadButtonData: {
              documentData: envelope?.data?.documentData,
              downloadConfigExists: pageConfig?.downloadConfigurations.length
                ? true
                : false,
              currentDocumentUuid: res?.documentMetadata?.uuid,
              envelopeUuid: envelopeUuid,
              scenario: scenario,
              currentDocumentTypeID: res?.documentMetadata?.documentTypeId,
            },
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });

    return document;
  };

  onSaveOrderMeta = (formdata) => {
    return new Promise((resolve) => {
      const copy = Object.assign({}, this.state.documentExchange);
      set(copy, 'data.document.header', formdata.header);

      const businessCaseType = formdata.header?.businessCaseType;

      if (businessCaseType === 'X2') {
        set(
          copy,
          'data.document.details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages',
          formdata?.details?.dispatchData?.consignmentPackagingSequences[0]
            ?.packagingInformations[0]?.numberOfPackages
        );
      }

      this.setState(
        {
          documentExchange: {...copy},
        },
        resolve
      );
    });
  };

  /**
   * Document is processed
   * We need to prepare complex desadv and unstructured, before sending
   */
  postDesadvDocument = () => {
    const {sscc, documentExchange} = this.state;

    if (sscc) {
      const ssccDocumentExchangeToSend = this.prepareSendSsccDesadv();
      this.postDesadv(ssccDocumentExchangeToSend);
    } else {
      const documentExchangeToSend = documentExchange;
      this.postDesadv(documentExchangeToSend);
    }
  };

  /**
   * On clicking "Next Step" button, we need to proccess the document
   * for further processing.
   * e.g.
   * - for modal (sscc)
   * - for unstructured
   * - for drafts
   */
  processDesadvDocumentNextStep = () => {
    const {sscc, currentDraftData, lineItemsCache} = this.state;

    if (sscc) {
      /***
       * When current documentExchange is from Draft,
       * then dont modify it for modal, we need to modify it differently for an existing draft
       * It is modified on component mount with "modifyAndSetDraftData" function
       * We just need to open the modal on click of the "Next" button
       */
      if (currentDraftData?.uuid) {
        this.setState({
          modal: sscc,
        });
      } else {
        this.ssccSubmit(lineItemsCache);
      }
    } else {
      this.noSsccSubmit(lineItemsCache);
    }
  };

  goBackToEditing = () => {
    this.setState({
      showAll: false,
      showSendButton: false,
    });
  };

  saveLineItems = (deliveryLineItems) => {
    this.setState({
      lineItemsCache: deliveryLineItems,
    });
  };

  exposeHeaderValues = (header) => {
    this.setState((prevState) => ({
      exposedValues: {
        ...prevState.exposedValues,
        header: header,
      },
    }));
  };

  exposeDeliveryLineItemValues = (deliveryLineItems) => {
    this.setState((prevState) => ({
      exposedValues: {
        ...prevState.exposedValues,
        deliveryLineItems: deliveryLineItems,
      },
    }));
  };

  submitDesadvWithOutDelay = async (ctx) => {
    //validate document
    if (!ctx.lineItemsValid || !ctx.headerValid) {
      submitForm(DESADV_LINEITEM_FORM_ID);
      submitForm(HEADER_FORM_ID);

      return null;
    }
    const {currentDraftData} = this.state;

    if (currentDraftData?.uuid) {
      deleteDraftWithUuid(currentDraftData?.uuid)
        .then(async () => await this.postDesadvDocument())
        .catch((error) => {
          console.error(
            `Failed, deleting the draft ${currentDraftData?.uuid} before sending the document without delay`,
            error
          );
          triggerDelayErrorToast(error, this.props.intl);
        });
    } else {
      await this.postDesadvDocument();
    }
  };

  saveDesadvDraftWithDefaultDelay = (ctx) => {
    //validate document
    if (!ctx.lineItemsValid || !ctx.headerValid) {
      submitForm(DESADV_LINEITEM_FORM_ID);
      submitForm(HEADER_FORM_ID);

      return null;
    }

    const {
      history,
      scenarioUuid,
      scenario,
      envelope,
      draftSettings,
    } = this.props;
    const {currentDraftData, sscc, documentExchange} = this.state;

    const webediSettings = draftSettings?.webediSettings || [];

    const defaultDelayTimeObjectFromSettings = webediSettings.find(
      (setting) => setting?.type === DRAFTS_DELAY_TIME
    );

    const defaultDelayTime = defaultDelayTimeObjectFromSettings?.value
      ? Number(defaultDelayTimeObjectFromSettings?.value)
      : DRAFTS_DELAY_TIME_IN_MS_FALLBACK; //15min

    const prepareDocumentExchange = sscc
      ? this.prepareSendSsccDesadv()
      : documentExchange;

    const documentExchangeToSendAsDraft = {
      meta: prepareDocumentExchange?.data?.meta,
      document: prepareDocumentExchange?.data?.document,
    };

    const documentNumber =
      documentExchangeToSendAsDraft?.document?.header?.beginningOfMessage
        ?.documentNumber;

    this.setState({
      sending: true,
    });

    saveDocumentDraftWithSendingDelay(
      scenarioUuid,
      envelope?.data?.uuid,
      documentExchangeToSendAsDraft,
      documentNumber,
      defaultDelayTime,
      scenario?.scenarioChecksum,
      currentDraftData?.uuid
    )
      .then(() =>
        history.push(
          `/scenario/${scenarioUuid}/envelope/${envelope?.data?.uuid}`
        )
      )
      .catch((error) => {
        console.error(
          'Failed, saving desadv document draft with default delay',
          error
        );
        triggerDelayErrorToast(error, this.props.intl);
      });
  };

  saveDesadvDraftWithScheduledDate = (ctx, scheduledDate) => {
    if (!ctx.lineItemsValid || !ctx.headerValid) {
      submitForm(DESADV_LINEITEM_FORM_ID);
      submitForm(HEADER_FORM_ID);

      return null;
    }

    const {history, scenarioUuid, scenario, envelope} = this.props;
    const {currentDraftData, sscc, documentExchange} = this.state;

    const prepareDocumentExchange = sscc
      ? this.prepareSendSsccDesadv()
      : documentExchange;

    const documentExchangeToSendAsDraft = {
      meta: prepareDocumentExchange?.data?.meta,
      document: prepareDocumentExchange?.data?.document,
    };

    const documentNumber =
      documentExchangeToSendAsDraft?.document?.header?.beginningOfMessage
        ?.documentNumber;

    if (scheduledDate) {
      this.setState({
        sending: true,
      });
      saveDocumentDraftWithScheduledDate(
        scenarioUuid,
        envelope?.data?.uuid,
        documentExchangeToSendAsDraft,
        documentNumber,
        scheduledDate,
        scenario?.scenarioChecksum,
        currentDraftData?.uuid
      ) // eslint-disable-next-line radar/no-identical-functions
        .then(() =>
          history.push(
            `/scenario/${scenarioUuid}/envelope/${envelope?.data?.uuid}`
          )
        )
        .catch((error) => {
          console.error(
            'Failed, saving desadv document draft with scheduledDate delay',
            error
          );
          triggerDelayErrorToast(error, this.props.intl);
        });
    }
  };

  submitDocument = (ctx) => {
    if (!ctx.lineItemsValid || !ctx.headerValid) {
      submitForm(DESADV_LINEITEM_FORM_ID);
      submitForm(HEADER_FORM_ID);

      return null;
    }

    const {showSendButton} = this.state;

    if (showSendButton) {
      if (isSendingDelayFromWebEdiSettingsEnabled(this.props.draftSettings)) {
        this.saveDesadvDraftWithDefaultDelay(ctx);
      } else {
        this.submitDesadvWithOutDelay(ctx);
      }
    } else {
      this.processDesadvDocumentNextStep();
    }
  };

  render() {
    const {pageConfig, draftSettings, scenario, envelope} = this.props;
    const {documentExchange, fetching, currentDraftData} = this.state;
    const cpsConfigs = pageConfig?.cpsConfigs || [];
    const renderLoader =
      fetching || !documentExchange || draftSettings?.fetchingSettings;

    const deliveryLineItems = get(
      documentExchange,
      'data.document.details.dispatchData.consignmentPackagingSequences[0].deliveryLineItems',
      []
    );

    const dispatchData = get(
      documentExchange,
      'data.document.details.dispatchData',
      {}
    );

    if (!fetching && !deliveryLineItems.length) {
      console.error('dispatchData has no LineItems');
    }

    if (!cpsConfigs.length) {
      console.warn('No cps config found, failing to render simple desadv v2!');
    }

    return renderLoader ? (
      <Loader active />
    ) : (
      <TemplateContainer>
        <DocumentDraftInfoHeader
          draftData={currentDraftData}
          scenarioUuid={scenario?.uuid}
          envelopeUuid={envelope?.data?.uuid}
          draftSettings={this.props.draftSettings}
          renderRootContainer={ROOT_CONTAINER.DESADV_V2}
        />
        <HeaderContainer>
          <Header as="h1">
            <Icon
              circular
              link
              name="chevron left"
              onClick={() => this.props.history.goBack()}
            />
            <Header.Content>
              <FormattedMessage id="CREATE_XML_DESADV_1p0_ERPEL_INDUSTRY_MESSAGE" />
            </Header.Content>
          </Header>
        </HeaderContainer>

        <TemplateSubContainer>
          <ContextWrapper>
            {!this.state.showAll ? (
              <DesadvPackagingInformationModal
                exposedValues={this.state.exposedValues}
                open={this.state.modal}
                dispatchData={dispatchData}
                pageConfig={pageConfig}
                onSave={this.onModalSave}
                onCancel={this.onModalCancel}
              />
            ) : (
              <ReadOnlyDesadvPackagingInformationModal
                open={this.state.modal}
                deliveryLineItems={deliveryLineItems}
                pageConfig={pageConfig}
                document={documentExchange?.data?.document}
                onCancel={() => {
                  this.setState({
                    modal: false,
                  });
                }}
              />
            )}
            <DocumentMeta
              showReadonly={this.state.showAll}
              documentExchange={this.state.documentExchange.data}
              onSubmit={this.onSaveOrderMeta}
              exposedValues={this.state.exposedValues}
              pageConfig={pageConfig}
              exposeHeaderValues={this.exposeHeaderValues}
            />
            {!deliveryLineItems.length || !cpsConfigs.length ? (
              <Message error style={{width: '100%'}}>
                {!deliveryLineItems.length ? (
                  <FormattedMessage id="GENERAL_NO_POSITIONS" />
                ) : null}
                {!cpsConfigs.length ? (
                  <FormattedMessage id="GENERAL_NO_CPS_CONFIG" />
                ) : null}
              </Message>
            ) : (
              <Fragment>
                <DesadvLineItemsForm
                  showReadonly={this.state.showAll}
                  goBackToEditing={this.goBackToEditing}
                  sscc={this.state.sscc}
                  partialDeliveryAllowed={this.state.partialDeliveryAllowed}
                  showSendButton={this.state.showSendButton}
                  onSubmit={this.saveLineItems}
                  exposedValues={this.state.exposedValues}
                  exposeDeliveryLineItemValues={
                    this.exposeDeliveryLineItemValues
                  }
                  sending={this.state.sending}
                  pageConfig={pageConfig || {}}
                  deliveryLineItems={deliveryLineItems}
                />
                <DocumentMetaContext.Consumer>
                  {(ctx) => {
                    return (
                      <ButtonContainer>
                        {this.state.showAll ? (
                          <Button
                            data-spec="back-to-editing"
                            color="black"
                            onClick={this.goBackToEditing}
                            content={
                              <FormattedMessage id="GO_BACK_TO_EDITING" />
                            }
                          />
                        ) : (
                          <Button
                            data-spec="back"
                            color="black"
                            onClick={() => this.props.history.goBack()}
                            content={<FormattedMessage id="GENERAL_BACK" />}
                          />
                        )}

                        <div style={{display: 'flex'}}>
                          {this.state.showAll && this.state.sscc ? (
                            <Button
                              data-spec="show-desadv-structure"
                              onClick={() => this.setState({modal: true})}
                              content={
                                <FormattedMessage id="SHOW_DESADV_STRUCTURE" />
                              }
                            />
                          ) : (
                            ''
                          )}

                          <Button
                            data-spec="send-desadv"
                            style={{
                              marginRight: '0px',
                            }}
                            color="green"
                            loading={this.state.sending}
                            disabled={
                              this.state.sending ||
                              this.state.preventSendingOfDocument
                            }
                            onClick={() => this.submitDocument(ctx)}
                            content={
                              this.state.showSendButton ? (
                                <FormattedMessage
                                  id="GENERAL_SEND_DOCTYPE"
                                  values={{
                                    docType: (
                                      <FormattedMessage
                                        id={INDUSTRY_DESADV || ''}
                                      />
                                    ),
                                  }}
                                />
                              ) : (
                                <FormattedMessage id="GENERAL_NEXT_STEP" />
                              )
                            }
                          />
                          <DocumentDraftSendButtonOptions
                            hide={!this.state.showSendButton}
                            loading={this.state.sending}
                            disabled={
                              this.state.sending ||
                              this.state.preventSendingOfDocument
                            }
                            submitWithOutDelay={async () =>
                              await this.submitDesadvWithOutDelay(ctx)
                            }
                            saveDraftWithDefaultDelay={async () =>
                              await this.saveDesadvDraftWithDefaultDelay(ctx)
                            }
                            saveDraftWithScheduledDate={async (scheduledDate) =>
                              await this.saveDesadvDraftWithScheduledDate(
                                ctx,
                                scheduledDate
                              )
                            }
                            currentDraftData={currentDraftData}
                          />
                        </div>
                      </ButtonContainer>
                    );
                  }}
                </DocumentMetaContext.Consumer>
              </Fragment>
            )}
          </ContextWrapper>
        </TemplateSubContainer>
      </TemplateContainer>
    );
  }
}

DesadvSimpleTemplateV2.propTypes = {
  match: PropTypes.object,
  scenario: scenarioShape,
  pageConfig: pageConfigShape,
  envelope: envelopesShape,
  fetchTurnaround: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  postDocumentToEnvelope: PropTypes.func,
  intl: intlShape.isRequired,
  draftSettings: PropTypes.object,
  scenarioUuid: PropTypes.string,
};

export default injectIntl(
  flowRight(connect(mapStateToProps, mapDispatchToProps))(
    DesadvSimpleTemplateV2
  )
);
