import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Dropdown} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

class CpsSelection extends React.Component {
  constructor(props) {
    super(props);
    this.ddRef = React.createRef(null);
  }

  render() {
    const {
      onSave,
      cpsOptions = [],
      lineitemOptions = [],
      open,
      isParent,
      onCancel,
      rootDirty,
      ...rest
    } = this.props;

    const onComponentSave = (e) => {
      onSave(e, {
        ...rest,
        value: this.ddRef.current.state.value,
      });
    };
    const options = isParent ? cpsOptions : lineitemOptions;

    const headerId = isParent
      ? 'DESADV_CPS_MODAL_HEADER'
      : 'DESADV_LINE_ITEM_MODAL_HEADER';
    return (
      <Modal open={open} data-spec="item-modal">
        <Modal.Header>
          <FormattedMessage id={headerId} />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Dropdown
              fluid
              ref={this.ddRef}
              selection
              options={options}
              defaultValue={options.length ? options[0].value : undefined}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            color="red"
            onClick={onCancel}
            data-spec="modal-cancel-item">
            <FormattedMessage id="GENERAL_CANCEL" />
          </Button>
          <Button
            type="button"
            color="green"
            onClick={onComponentSave}
            data-spec="modal-add-item">
            <FormattedMessage id="GENERAL_CONFIRM" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

CpsSelection.propTypes = {
  cpsOptions: PropTypes.any,
  isParent: PropTypes.bool,
  lineitemOptions: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  rootDirty: PropTypes.bool,
};

export default CpsSelection;
