import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchDocumentWithEnvelope} from '../../reducers/documentExchange';
import {scenarioShape} from '../../shapes/scenarios';
import {postEnvStatus, postEnvProperties} from '../../reducers/envelope';

/**
 * HoC to load the documentExchange and envelope and inject them
 * into the wrapped component.
 */
const withDocument = (ComponentLoader = null) => (WrappedComponent) => {
  const mapStateToProps = ({envelope, documentExchange}) => ({
    envelope,
    documentExchange,
  });

  const mapDispatchToProps = (dispatch, {scenario, match}) => ({
    postEnvelopeProperties: (props) => {
      return dispatch(postEnvProperties(match.params.envelopeUuid, props));
    },

    postEnvelopeStatus: (status) => {
      return dispatch(
        postEnvStatus(match.params.envelopeUuid, scenario, status)
      );
    },

    fetchDocument: (envelopeUuid, documentUuid) => {
      return dispatch(
        fetchDocumentWithEnvelope(envelopeUuid, documentUuid, scenario)
      );
    },
  });

  class WithDocument extends PureComponent {
    constructor(props) {
      super(props);

      const {envelopeUuid, documentUuid} = props.match.params;

      if (documentUuid) {
        this.props.fetchDocument(envelopeUuid, documentUuid);
      }
    }

    componentDidUpdate(prevProps) {
      const {envelopeUuid, documentUuid} = this.props.match.params;

      if (
        documentUuid &&
        documentUuid !== prevProps.match.params.documentUuid
      ) {
        this.props.fetchDocument(envelopeUuid, documentUuid);
      }
    }

    render() {
      const {documentExchange} = this.props;
      const uuidProps = {
        documentUuid: this.props.match.params.documentUuid || null,
        envelopeUuid: this.props.match.params.envelopeUuid || null,
      };

      const isFetching = () =>
        uuidProps.documentUuid && documentExchange.fetching;

      if (ComponentLoader && isFetching()) {
        return <ComponentLoader />;
      } else {
        return <WrappedComponent {...uuidProps} {...this.props} />;
      }
    }
  }

  WithDocument.propTypes = {
    documentExchange: PropTypes.object,
    match: PropTypes.object,
    scenario: scenarioShape,
    fetchDocument: PropTypes.func,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithDocument);
};

export default withDocument;
