/* eslint-disable radar/cognitive-complexity */
import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Grid, Header, Icon, Message, Segment} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import htmlParse from 'html-react-parser';
import {intlShape} from '@ecosio/components';
import {newCps, cloneCps} from '../../../../Utils/CreateUtils';
import {documentShape} from '../../../../../../../shapes/documentExchange';
import {postComplexDesadv} from '../../../../../../../reducers/fetcher';
import {HEADER_FORM_ID} from '../../../../../Order/View/Header/DocumentHeadEditForm';
import {scenarioShape} from '../../../../../../../shapes/scenarios';
import CpsList from './CpsList';
import RootCpsBar, {RootCpsItem, RootCpsInteraction} from './CpsBar';
import CpsSelection from './CpsSelection';
import ShowAll from './ShowAll';

const alertKey = 'DESADV_CPS_UNSAVED_SWITCH_ALERT';

export const EmptyListState = ({style, vAlign, header, subHeader, icon}) => (
  <React.Fragment>
    <Grid
      centered
      container
      columns={1}
      verticalAlign={vAlign}
      style={style}
      className="_si_elist">
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Header as="h2" icon textAlign="center">
            <Icon name={icon} color="grey" circular />
            <Header.Content>
              <FormattedMessage id={dynID(header)} />
            </Header.Content>
            <Header.Subheader>
              {/**https://formatjs.io/docs/react-intl/upgrade-guide-4x#migrating-off-embedded-html-in-messages */}
              <FormattedMessage id={dynID(subHeader)}>
                {(chunks) => htmlParse(chunks.join(''))}
              </FormattedMessage>
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
);

EmptyListState.propTypes = {
  style: PropTypes.object,
  vAlign: PropTypes.string,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  icon: PropTypes.string,
};

class CpsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //      error: null,
      rootDirty: false,
      showAll: false,
      modalOpen: false,
      selected: null,
      selectedItem: null,
      root: [],
      sending: false,
      sendingError: null,
    };
  }

  setDirty = (b = true) => {
    this.setState({rootDirty: b});
  };

  onSelectShowAll = () => {
    let check = true;
    if (this.state.rootDirty) {
      // eslint-disable-next-line no-alert
      check = confirm(this.props.intl.formatMessage({id: alertKey}));
    }

    if (check) {
      this.setState({showAll: true, rootDirty: false});
    }
  };

  onSelect = (e, group) => {
    let check = true;

    if (this.state.rootDirty) {
      // eslint-disable-next-line no-alert
      check = confirm(this.props.intl.formatMessage({id: alertKey}));
    }

    if (check) {
      this.setState((state) => ({
        rootDirty: false,
        showAll: false,
        selected: group,
        selectedItem: state.root.find((i) => i.group === group),
      }));
    } else {
      return;
    }
  };

  toggleCpsSelection = () => {
    let check = true;
    if (this.state.rootDirty) {
      // eslint-disable-next-line no-alert
      check = confirm(this.props.intl.formatMessage({id: alertKey}));
    }

    if (check) {
      this.setState((state) => ({
        modalOpen: !state.modalOpen,
        rootDirty: false,
      }));
    }
  };
  onDelete = (e, {group}) =>
    this.setState((state) => ({
      root: state.root.filter((i) => i.group !== group),
      selectedItem: group === state.selected ? null : state.selectedItem,
      selected: group === state.selected ? null : state.selected,
      rootDirty: group === state.selected ? false : true,
    }));

  onClone = async (e, {group}) => {
    const clone = await cloneCps(
      this.state.root.find((i) => i.group === group)
    );

    this.setState(
      (state) => ({
        root: [...state.root, clone],
      }),
      () => {
        this.setState((state) => ({
          showAll: false,
          selected: clone.group,
          selectedItem: state.root.find((i) => i.group === clone.group),
        }));
      }
    );
  };

  currentHasLineItems = () => {
    if (!this.state.selectedItem) {
      return false;
    }
    if (
      this.state.selectedItem.lineitems &&
      this.state.selectedItem.lineitems.length > 0
    ) {
      return true;
    }

    let i = 0;
    if (this.state.selectedItem.cps) {
      while (i < this.state.selectedItem.cps.length) {
        if (this.state.selectedItem.cps[i].lineitems.length > 0) {
          return true;
        } else if (this.state.selectedItem.cps[i].cps) {
          return this.checkLineItems(this.state.selectedItem.cps[i]);
        }
        i++;
      }
      return false;
    } else {
      return false;
    }
  };
  onCancelRootCpsSelection = () => {
    this.setState({
      modalOpen: false,
    });
  };

  onSaveRootCpsSelection = async (e, {value}) => {
    const created = await newCps(this.props.config.cpsConfigs, value);

    this.setState(
      (state) => ({
        rootDirty: true,
        modalOpen: false,
        root: [...state.root, created],
      }),
      () => {
        this.setState((state) => ({
          showAll: false,
          selected: created.group,
          selectedItem: state.root.find((i) => i.group === created.group),
        }));
      }
    );
  };

  onSendRoot = (headerValid) => {
    if (!headerValid) {
      // header is not valid, trigger the submit button to show validation errors
      // https://github.com/final-form/react-final-form/issues/878#issuecomment-799050543
      document
        .getElementById(HEADER_FORM_ID)
        .dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
      return;
    }

    const unfoldLineItems = (rawLi) => {
      return rawLi.map((li) => {
        return {
          ...li.props,
          classifications: li.props.classifications.map((clazz) => {
            if (clazz.classificationSchema === 'SERIALNUMBER_MANDATORY') {
              return {
                ...clazz,
                content: clazz.content.map((cont) => {
                  if (cont === true) {
                    return 'true';
                  } else if (cont === false) {
                    return 'false';
                  } else {
                    return cont;
                  }
                }),
              };
            }
          }),
        };
      });
    };

    const unfoldCps = (rawCps) => {
      return rawCps.map((obj) => {
        return {
          configID: obj.configID,
          ...obj.props,
          cps: obj.cps.length ? unfoldCps(obj.cps) : [],
          lineitems: obj.lineitems.length ? unfoldLineItems(obj.lineitems) : [],
        };
      });
    };

    const {history, match, documentExchange, config, scenario} = this.props;
    const {scenarioUuid, envelopeUuid} = match.params;

    this.setState({sending: true, sendingError: false});
    postComplexDesadv(
      envelopeUuid,
      documentExchange,
      unfoldCps(this.state.root),
      scenarioUuid,
      scenario?.scenarioChecksum
    )
      .then((res) => {
        this.setState({sending: false, sendingError: false});

        const erpelMessageId = res?.data?.documentMetadata?.erpelMessageId;

        history.push({
          pathname: `/scenario/${scenarioUuid}/envelope/${envelopeUuid}`,
          state: {
            message: 'GENERAL_DOCUMENT_SENT',
            status: 'info',
            erpelMessageId: erpelMessageId,
            downloadButtonData: {
              downloadConfigExists: config?.downloadConfigurations.length
                ? true
                : false,
              currentDocumentUuid: res?.data?.documentMetadata?.uuid,
              envelopeUuid: envelopeUuid,
              scenario: scenario,
              currentDocumentTypeID:
                res?.data?.documentMetadata?.documentTypeId,
            },
          },
        });
      })
      .catch((e) => {
        this.setState({sending: false, sendingError: true});
        console.error(e);
      });
  };

  onSaveList = (submitdata) => {
    const found = this.state.root.find((cps) => cps.group === submitdata.group);
    let newRoot;

    const sanitizeValues = (rawCps) => {
      const sanitizeLineItem = (li) => {
        return li.map((item) => {
          const qty = parseInt(item.props.orderedQuantity);
          if (qty !== item.props.values.length) {
            return {
              ...item,
              props: {
                ...item.props,
                values: item.props.values.slice(0, qty),
              },
            };
          } else {
            return item;
          }
        });
      };

      return rawCps.map((obj) => {
        return {
          ...obj,
          cps: obj.cps.length ? sanitizeValues(obj.cps) : [],
          lineitems: obj.lineitems.length
            ? sanitizeLineItem(obj.lineitems)
            : [],
        };
      });
    };

    submitdata = sanitizeValues([submitdata])[0];

    if (found) {
      newRoot = this.state.root.map((i) =>
        i.group === submitdata.group ? submitdata : i
      );
    } else {
      newRoot = [...this.state.root, submitdata];
    }

    this.setState({
      selectedItem: submitdata,
      rootDirty: false,
      root: newRoot,
    });
  };

  render() {
    const {config, data, documentExchange, intl} = this.props;

    const hasLineItems = this.currentHasLineItems();

    const opts = config.cpsConfigs
      .filter((cps) => {
        if (!Object.keys(cps.filterPath).length) {
          return true;
        }

        for (const key in cps.filterPath) {
          const needle = get(documentExchange, key);

          return cps.filterPath[key].length
            ? cps.filterPath[key].indexOf(needle) > -1
            : true;
        }
      })
      .map((i) => ({
        key: `cpsConfig_${i.configID}`,
        text: intl.formatMessage({id: dynID(i.label)}),
        value: i.configID,
      }));

    const lineItems = get(data, 'ordersData.ordersLineItems', []);

    if (!lineItems) {
      console.error('Ordersdata has no line items"');
    }

    lineItems.sort((a, b) =>
      a.customersItemMaterialNumber > b.customersItemMaterialNumber ? 1 : -1
    );
    const opts2 = lineItems.map((i, idx) => {
      const numbers = `${i.customersItemMaterialNumber} - ${i.suppliersItemMaterialNumber}`;
      const desc = i.itemDescription;

      return {
        key: `orders_${idx}_${i.positionNumber}`,
        text: (
          <>
            <span>{numbers}</span> <span style={{color: 'grey'}}>({desc})</span>
          </>
        ),
        value: i.positionNumber,
        content: <Header as="h5" content={numbers} subheader={desc} />,
      };
    });

    return (
      <>
        <CpsSelection
          open={this.state.modalOpen}
          onCancel={this.onCancelRootCpsSelection}
          cpsOptions={opts}
          lineitemOptions={opts2}
          onSave={this.onSaveRootCpsSelection}
          isParent
        />
        <Segment.Group>
          <Message warning attached="top">
            <Message.Header>
              <FormattedMessage id="DESADV_INSTRUCTION_HEADER" />
            </Message.Header>
            <Message.Content>
              {/**https://formatjs.io/docs/react-intl/upgrade-guide-4x#migrating-off-embedded-html-in-messages */}
              <FormattedMessage id="DESADV_INSTRUCTION_BODY">
                {(chunks) => htmlParse(chunks.join(''))}
              </FormattedMessage>
            </Message.Content>
          </Message>
          <Segment>
            <RootCpsBar>
              {this.state.root.map((item) => {
                return (
                  <RootCpsItem
                    key={`RootCpsItem_${item.group}`}
                    type={item.icon}
                    group={item.group}
                    onSelect={this.onSelect}
                    onDelete={this.onDelete}
                    onClone={this.onClone}
                    active={
                      item.group === this.state.selected && !this.state.showAll
                    }
                  />
                );
              })}
              {opts && opts.length ? (
                <>
                  <RootCpsInteraction
                    tooltip="DESADV_ADD_CPS"
                    icon="plus"
                    onClick={this.toggleCpsSelection}
                  />
                  <RootCpsInteraction
                    tooltip={
                      hasLineItems
                        ? 'DESADV_SHOW_ALL'
                        : 'DESADV_SHOW_ALL_DISABLED'
                    }
                    icon="share"
                    background="#cacaca42"
                    onClick={this.onSelectShowAll}
                    active={this.state.showAll}
                    disabled={!hasLineItems}
                  />
                </>
              ) : (
                <Message error style={{width: '100%'}}>
                  {console.warn(
                    'No cps config found, failing to render complex desadv!'
                  )}
                  An internal error occurred.
                </Message>
              )}
            </RootCpsBar>
          </Segment>
        </Segment.Group>
        {this.state.showAll ? (
          <ShowAll
            data={this.state.root}
            config={config}
            onSend={this.onSendRoot}
            sending={this.state.sending}
            sendingError={this.state.sendingError}
          />
        ) : this.state.selectedItem ? (
          <CpsList
            data={data}
            cpsOptions={opts}
            lineitemOptions={opts2}
            config={config}
            item={this.state.selectedItem}
            onSave={this.onSaveList}
            setDirty={this.setDirty}
            rootDirty={this.state.rootDirty}
            hasLineItems={hasLineItems}
          />
        ) : (
          <Segment>
            <EmptyListState
              header="DESADV_NO_CPS_HEADER"
              subHeader="DESADV_NO_CPS_SUBHEADER"
              icon="box"
            />
          </Segment>
        )}
      </>
    );
  }
}

CpsForm.propTypes = {
  scenario: scenarioShape,
  intl: intlShape,
  history: PropTypes.object,
  match: PropTypes.object,
  // TODO: rename to document
  documentExchange: documentShape,
  config: PropTypes.shape({
    cpsConfigs: PropTypes.arrayOf(
      PropTypes.shape({
        packageType: PropTypes.number,
        configID: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string,
        depth: PropTypes.number,
        fields: PropTypes.arrayOf(PropTypes.string),
        packagingInformation: PropTypes.shape({
          packageTypeCustomer: PropTypes.string,
          shippingMarkType: PropTypes.string,
        }),
        filterPath: PropTypes.any,
        isParent: PropTypes.bool,
      })
    ),
  }),
  data: PropTypes.shape({
    clientData: PropTypes.shape({
      root: PropTypes.array,
      values: PropTypes.array,
    }),
    ordersData: PropTypes.shape({
      ordersLineItems: PropTypes.arrayOf(
        PropTypes.shape({
          customersItemMaterialNumber: PropTypes.string,
          itemDescription: PropTypes.string,
          orderedQuantity: PropTypes.number,
          positionNumber: PropTypes.number,
          suppliersItemMaterialNumber: PropTypes.string,
          classifications: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.array,
              classificationSchema: PropTypes.string,
            })
          ),
        })
      ),
    }),
  }),
};
export default injectIntl(CpsForm);
