/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid, Segment} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {InputArray, Input} from '@ecosio/pathform';
import {
  ALLOWANCE,
  CHARGE,
  EnableRelativeCalculationForAllowanceConstants,
  PERCENTAGE,
} from '../../../../../constants';
import {aocItemShape, semanticOptionShape} from './shapes';
import AocField from './Fields/AocField';
import AocVatField from './Fields/AocVatField';
import {TextField, TextAoc} from './Fields/TextFields';
import ActionButton from './Components/ActionButton';
import RelativeCalculationCheckBoxField from './Fields/RelativeCalculationCheckBoxField';

const buildAllowanceOrChargeOptions = (config) => {
  /* Dont do something like this - it doesnt work
   [config.allowAddingAllowances && {key, text, value}]
  */

  const ALLOWANCE_OPTION = {
    key: ALLOWANCE,
    text: <FormattedMessage id="GENERAL_ALLOWANCE" />,
    value: ALLOWANCE,
  };

  const CHARGE_OPTION = {
    key: CHARGE,
    text: <FormattedMessage id="GENERAL_CHARGE" />,
    value: CHARGE,
  };

  const ITEMS = [];
  config.allowAddingAllowances && ITEMS.push(ALLOWANCE_OPTION);
  config.allowAddingCharges && ITEMS.push(CHARGE_OPTION);

  return ITEMS;
};

const newAllowanceAndCharge = (config) => {
  const newAoc = {
    allowanceChargeQualifier: ALLOWANCE,
    allowanceChargeTypeCoded: PERCENTAGE,
    percentageOfAllowanceOrCharge: null,
    vatrate: 0,
    vatamount: 0,
  };

  if (!config.allowAddingAllowances && config.allowAddingCharges) {
    newAoc.allowanceChargeQualifier = CHARGE;
  }

  return newAoc;
};

const AocItem = ({
  currency,
  item,
  options,
  name,
  onRemove,
  vatrates,
  isIglSet,
  index,
  formFields,
  customerSpecificTranslations,
  docLevelAlcConfiguration,
}) => {
  const allowanceChargeRelativeCalculationEnabledForThisAoc = item?.allowanceChargeRelativeCalculation
    ? true
    : false;

  let hideVatrateField = false;

  if (
    isIglSet ||
    (item.allowanceChargeQualifier === ALLOWANCE &&
      (docLevelAlcConfiguration?.enableRelativeCalculationForAllowance ===
        EnableRelativeCalculationForAllowanceConstants.DEFAULT ||
        !docLevelAlcConfiguration?.showAllowanceVatRate ||
        allowanceChargeRelativeCalculationEnabledForThisAoc))
  ) {
    hideVatrateField = true;
  }

  const isTypeConfigured =
    typeof formFields[
      'header.invoiceHeader.allowancesAndCharges.allowanceChargeCategoryCoded'
    ] !== 'undefined';

  const aocWidth = isTypeConfigured ? 5 : 10;
  return (
    <Segment data-spec={`aoc-field-${index}`}>
      <Grid style={{marginBottom: '3px'}}>
        <Grid.Row>
          {isTypeConfigured && (
            <Grid.Column width={4} className="aoc-type">
              <Input
                size="mini"
                name={`${name}.allowanceChargeCategoryCoded`}
                path="header.invoiceHeader.allowancesAndCharges.allowanceChargeCategoryCoded"
              />
            </Grid.Column>
          )}
          <Grid.Column width={aocWidth} data-spec={`aoc-col-${index}`}>
            <AocField
              item={item}
              index={index}
              currency={currency}
              options={options}
              name={name}
            />
          </Grid.Column>
          <RelativeCalculationCheckBoxField
            name={name}
            index={index}
            allowanceChargeRelativeCalculationEnabledForThisAoc={
              allowanceChargeRelativeCalculationEnabledForThisAoc
            }
            aocItem={item}
            docLevelAlcConfiguration={docLevelAlcConfiguration}
          />

          {/* if we have no IGL or it is an allowance we do not need a VAT rate */}
          {hideVatrateField ? null : (
            <Grid.Column width={5}>
              <AocVatField
                name={name}
                vatrates={vatrates}
                formFields={formFields}
                index={index}
                customerSpecificTranslations={customerSpecificTranslations}
              />
            </Grid.Column>
          )}
          <Grid.Column width={1} style={{paddingTop: 24}} textAlign="right">
            <ActionButton type="remove" onClick={onRemove} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Input
        name={`${name}.allowanceChargeComment`}
        path="header.invoiceHeader.allowancesAndCharges.allowanceChargeComment"
      />
    </Segment>
  );
};

AocItem.propTypes = {
  currency: PropTypes.string.isRequired,
  item: aocItemShape,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  options: semanticOptionShape,
  vatrates: PropTypes.array,
  index: PropTypes.number,
  isIglSet: PropTypes.bool,
  formFields: PropTypes.array,
};

const AocList = ({
  config,
  currency,
  vatrates,
  isIglSet,
  fields: formFields,
  customerSpecificTranslations,
  docLevelAlcConfiguration,
}) => {
  const options = buildAllowanceOrChargeOptions(config);
  return (
    <InputArray name="data.document.header.invoiceHeader.allowancesAndCharges">
      {({fields}) => {
        return fields.length ? (
          fields.map((name, index) => {
            const item = fields.value[index];

            const canRemove =
              (item.allowanceChargeQualifier === ALLOWANCE &&
                config.allowRemovingAllowances) ||
              (item.allowanceChargeQualifier === CHARGE &&
                config.allowRemovingCharges);

            return (
              <AocItem
                currency={currency}
                vatrates={vatrates}
                isIglSet={isIglSet}
                key={`aocitem_${index}`}
                index={index}
                item={item}
                options={options}
                name={name}
                onRemove={canRemove ? () => fields.remove(index) : undefined}
                formFields={formFields}
                customerSpecificTranslations={customerSpecificTranslations}
                docLevelAlcConfiguration={docLevelAlcConfiguration}
              />
            );
          })
        ) : (
          <Segment>
            <FormattedMessage id="INVOICE_AOC_EMPTY_STATE" />
          </Segment>
        );
      }}
    </InputArray>
  );
};

AocList.propTypes = {
  currency: PropTypes.string.isRequired,
  config: PropTypes.object,
  vatrates: PropTypes.array,
  isIglSet: PropTypes.bool,
  fields: PropTypes.array,
};

const TotalCalcList = ({currency}) => {
  return (
    <Segment textAlign="right">
      <Grid style={{padding: '10px'}}>
        <InputArray name="data.document.header.invoiceHeader.allowancesAndCharges">
          {({fields}) => {
            return fields.map((name, index) => {
              const field = fields.value[index];
              return (
                <Fragment key={`documentaoceditor_${index}`}>
                  <TextField
                    label="INVOICE_AOC_TAXABLE_AMOUNT_PRE"
                    name={`${name}.chargeBaseQuantity`}
                    currency={currency}
                  />
                  <TextAoc
                    field={field}
                    name="allowanceChargeAmount"
                    currency={currency}
                  />
                </Fragment>
              );
            });
          }}
        </InputArray>
        <TextField
          name="data.document.footer.invoiceFooter.invoiceTotals.totalTaxableAmount"
          label="INVOICE_AOC_TAXABLE_AMOUNT"
          currency={currency}
        />
      </Grid>
    </Segment>
  );
};

const AllowancesAndCharges = ({
  push,
  currency,
  config,
  fields,
  vatrates,
  isIglSet,
  customerSpecificTranslations = {},
  docLevelAlcConfiguration,
}) => {
  const canAdd = config.allowAddingCharges || config.allowAddingAllowances;

  const onAdd = canAdd
    ? () => {
        const newAoc = newAllowanceAndCharge(config);

        return push(
          'data.document.header.invoiceHeader.allowancesAndCharges',
          newAoc
        );
      }
    : undefined;

  return (
    <Segment.Group>
      <div style={{padding: '15px', textAlign: 'right'}}>
        <ActionButton type="add" onClick={onAdd} />
      </div>

      <AocList
        config={config}
        currency={currency}
        fields={fields}
        customerSpecificTranslations={customerSpecificTranslations}
        vatrates={vatrates}
        isIglSet={isIglSet}
        docLevelAlcConfiguration={docLevelAlcConfiguration}
      />
      <TotalCalcList currency={currency} />
    </Segment.Group>
  );
};

AllowancesAndCharges.propTypes = {
  currency: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  vatrates: PropTypes.array,
  isIglSet: PropTypes.bool,
};

export default AllowancesAndCharges;
