/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Content, RenderContents, filterFields, dynID} from '@ecosio/pathform';
import {Table, Button, Icon} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {pageConfigShape} from '../../../../../shapes/scenarios';
import {filterPathFormArray} from '../../../../../util/documentUtil';
import {INDUSTRY_ORDER_RESPONSE} from '../../../../../shapes/supportedDocTypes';
import {useThemeSpecificColors} from '../../../../../helpers/hooks/useThemeSpecificColors';
import {UnitPriceCell, DeliveryCell, TotalPriceCell} from './Cells/index';
import EditCell from './EditCell';
import CircleForValueChanged from './CircleForValueChanged';

const styleAoc = {color: '#99a4af'};

export const actionRequestMap = {
  1: 'LE_ADD',
  2: 'LE_CANCEL',
  3: 'LE_CHANGE',
};

export const isRequestCodeAddedOrAccepted = (actionRequest) => {
  // both values map to "added/accepted"
  return actionRequest === '1' || actionRequest === 'ACCEPTED';
};

export const isRequestCodeCancelled = (actionRequest) => {
  // both values map to "cancelled/rejected"
  return actionRequest === '2' || actionRequest === 'REJECTED';
};

export const isRequestCodeChanged = (actionRequest) => {
  // both values map to "changed"
  return actionRequest === '3' || actionRequest === 'CHANGED';
};

export const isLineItemAddedOrAccepted = (lineItem) => {
  if (!lineItem) {
    console.warn('No line item passed to isLineItemAddedOrAccepted');
    return false;
  }

  const actionRequest = lineItem.lineItemActionRequest;
  return isRequestCodeAddedOrAccepted(actionRequest);
};

export const isLineItemCancelled = (lineItem) => {
  if (!lineItem) {
    console.warn('No line item passed to isLineItemCancelled');
    return false;
  }

  const actionRequest = lineItem.lineItemActionRequest;
  return isRequestCodeCancelled(actionRequest);
};

export const isLineItemChanged = (lineItem) => {
  if (!lineItem) {
    console.warn('No line item passed to isLineItemChanged');
    return false;
  }

  const actionRequest = lineItem.lineItemActionRequest;
  return isRequestCodeChanged(actionRequest);
};

const rowFormatter = (lineItem) => {
  if (isLineItemCancelled(lineItem)) {
    return {'text-decoration': 'line-through', ...styleAoc};
  }

  return {};
};

const getRowCssClassName = (lineItem) => {
  if (isLineItemChanged(lineItem) || isLineItemAddedOrAccepted(lineItem)) {
    return {className: 'addedOrChangedPosition'};
  }

  return {};
};
const CalcBody = ({
  lineItem,
  currency,
  hideTotalPrice,
  ordersDocument,
  themeSpecificColors,
  lineItemIndex,
}) => (
  <Fragment>
    <UnitPriceCell
      lineItem={lineItem}
      styleAoc={styleAoc}
      currency={currency}
      ordersDocument={ordersDocument}
      themeSpecificColors={themeSpecificColors}
      lineItemIndex={lineItemIndex}
    />
    {hideTotalPrice ? (
      ''
    ) : (
      <TotalPriceCell
        lineItem={lineItem}
        styleAoc={styleAoc}
        currency={currency}
        ordersDocument={ordersDocument}
        themeSpecificColors={themeSpecificColors}
        lineItemIndex={lineItemIndex}
      />
    )}
  </Fragment>
);

CalcBody.propTypes = {
  lineItem: PropTypes.object,
  currency: PropTypes.string,
};

export const PartyNames = ({partyNames}) => {
  if (!Array.isArray(partyNames)) {
    return <span>{partyNames}</span>;
  }

  return (
    <span>
      {partyNames.map((name) => (
        <React.Fragment key={name}>
          <span>{name}</span>
          <br />
        </React.Fragment>
      ))}
    </span>
  );
};

PartyNames.propTypes = {
  partyNames: PropTypes.oneOfType([PropTypes.string, PropTypes.Array]),
};

export const wasStateChanged = (
  ordersDocument,
  currentRow,
  currentFieldPath,
  rowIndex
) => {
  if (!isRequestCodeChanged(currentRow?.lineItemActionRequest)) {
    return false;
  }

  const orderLineItems =
    ordersDocument?.details?.ordersData?.ordersLineItems || [];
  const currentValue = get(currentRow, currentFieldPath, undefined);
  const ordersValue = get(
    orderLineItems[rowIndex],
    currentFieldPath,
    undefined
  );

  /**
   * We are checking the difference between "Orders Document" and the latest "ORDCHG document".
   * We need to check here:(only if the following is true then return true, that the state was changed)
   *
   * ordersDocument -> if ordersDocument exists
   * ordersValue -> if the orders value from ordersDocument exists!!! (could be false if a lineItem has been added to the list)
   *    - e.g.
   *      original orders => 2 lineItems (lineItem 1, lineItem 2)
   *      mergedState after ordchg => 3 lineItems (lineItem 1, lineItem 2, lineItem 3) (the 3rd lineItem has been added)
   *      When we check the ordersDocument and the latest ordchg, then the 3rd lineItem was not changed(even if the lineItemActionRequest is 3 in later ordchg),
   *      but is always "added", because it never existed in ordersDocument.
   *
   * currentValue !== ordersValue -> if both values are different
   *
   */
  if (ordersDocument && ordersValue && currentValue !== ordersValue) {
    return true;
  }

  return false;
};

const TableBody = ({
  lineItems,
  lineItemConfig,
  pageConfig,
  showCalculation,
  hideTotalPrice,
  onEdit,
  onDelete,
  onSelect,
  onDuplicate,
  currency,
  ordersDocument,
}) => {
  const canDuplicate = lineItemConfig.allowDuplicateLineItem;
  const canDelete =
    lineItemConfig.allowDeleteLineItem && onDelete && lineItems.length > 1;
  const hasEditables = onEdit || canDelete;

  const themeSpecificColors = useThemeSpecificColors();

  const getField = (path) => {
    return pageConfig.formFields[path];
  };

  const BusinessEntityCell = ({value, fields, prefix, dataSpec}) => {
    // Use .startsWith so that paths in the header don't match
    const found = filterFields(fields, (field) =>
      field.path.startsWith(prefix)
    );

    if (!found) {
      return null;
    }

    // check if the node is there and is a table column
    if (found[prefix] && found[prefix].tableColumn === false) {
      return null;
    }

    return (
      <Table.Cell verticalAlign="top" data-spec={dataSpec}>
        <RenderContents initialValues={{consignee: value}} fields={fields}>
          {() => (
            <Content
              path="consignee.partyNames"
              render={({content}) => (
                <div>
                  <PartyNames partyNames={content} />{' '}
                </div>
              )}
            />
          )}
        </RenderContents>
      </Table.Cell>
    );
  };

  BusinessEntityCell.propTypes = {
    value: PropTypes.object,
    fields: PropTypes.object,
    prefix: PropTypes.string,
    dataSpec: PropTypes.string,
  };

  const FilterCell = ({value, config, valuePath, width, dataSpec}) => {
    if (!config) {
      // not configured, don't render the whole cell
      return null;
    }
    if (!Array.isArray(value)) {
      console.warn('FilterCell expected an array , but got ', typeof value);
      return (
        <Table.Cell width={width} verticalAlign="top" data-spec={dataSpec} />
      );
    }

    const values = filterPathFormArray(value, config, valuePath).join(',');

    return (
      <Table.Cell width={width} verticalAlign="top" data-spec={dataSpec}>
        {values}
      </Table.Cell>
    );
  };

  FilterCell.propTypes = {
    value: PropTypes.array,
    config: PropTypes.object,
    valuePath: PropTypes.string,
    dataSpec: PropTypes.string,
    width: PropTypes.string,
  };

  const LineItemActionRequestLabel = injectIntl(({labelValue, intl}) => {
    if (!labelValue) {
      return null;
    }

    return (
      <label
        data-spec="lineItemActionRequestLabel"
        className="lineItemActionRequestLabel">
        {intl.formatMessage({id: dynID(labelValue)})}
      </label>
    );
  });

  const getLineItemActionRequestLabel = (
    doctype,
    lineItem,
    ordersDocument,
    currentFieldPath,
    rowIndex
  ) => {
    if (!doctype || !lineItem?.lineItemActionRequest) {
      return null;
    }

    if (isLineItemChanged(lineItem)) {
      const orderLineItems =
        ordersDocument?.details?.ordersData?.ordersLineItems || [];
      const lineItemExistsInOriginalOrdersDoc = orderLineItems[rowIndex];

      /**
       * Edge case,
       * check if the lineItem existed in the original OrdersDoc,
       * else it is a new lineItem, even if the lineItemActionRequest says its changedLineItem
       */
      if (lineItemExistsInOriginalOrdersDoc) {
        return 'GENERAL_LINE_ITEM_CHANGED';
      } else {
        return 'GENERAL_LINE_ITEM_ADDED';
      }
    }

    if (
      doctype === INDUSTRY_ORDER_RESPONSE &&
      isLineItemAddedOrAccepted(lineItem)
    ) {
      return 'GENERAL_LINE_ITEM_ACCEPTED';
    }

    if (isLineItemAddedOrAccepted(lineItem)) {
      return 'GENERAL_LINE_ITEM_ADDED';
    }
  };

  const Cell = ({
    value,
    config,
    width,
    dataSpec,
    lineItemActionRequestLabel,
    showChangedState = false,
    themeSpecificColors,
  }) => {
    let customStyle = {};

    // it's not configured, don't render the cell at all
    if (!config) {
      return null;
    }

    // the field is disabled in the table, don't render it
    if (!config.tableColumn) {
      return null;
    }

    if (showChangedState && themeSpecificColors?.primaryColor) {
      customStyle = {
        color: themeSpecificColors?.primaryColor,
        fontWeight: 700,
      };
    }

    switch (config.type) {
      // TODO: date / number types
      case 'dropdown':
        const opt = config.options.find((item) => item.value === value) || {
          text: 'GENERAL_SHIPMENT_NOT_FOUND',
        };
        return (
          <Table.Cell
            width={width}
            verticalAlign="top"
            data-spec={dataSpec}
            style={{...customStyle}}>
            <CircleForValueChanged shouldRender={showChangedState} />
            <FormattedMessage id={opt.text} />
          </Table.Cell>
        );

      default:
        return (
          <Table.Cell
            width={width}
            verticalAlign="top"
            data-spec={dataSpec}
            style={{...customStyle}}>
            <CircleForValueChanged shouldRender={showChangedState} />
            {value}
            {lineItemActionRequestLabel && (
              <LineItemActionRequestLabel
                labelValue={lineItemActionRequestLabel}
              />
            )}
          </Table.Cell>
        );
    }
  };

  Cell.propTypes = {
    value: PropTypes.any,
    config: PropTypes.object,
    width: PropTypes.number,
    dataSpec: PropTypes.string,
  };

  return (
    <Table.Body>
      {lineItems.map((row, idx) => (
        <Table.Row
          key={`tableRow_${idx}`}
          {...getRowCssClassName(row)}
          style={rowFormatter(row)}
          data-spec={`row-${idx}`}>
          <Cell
            config={getField('positionNumber')}
            value={
              <span
                style={{
                  display: 'inline-block',
                  marginRight: '8px',
                  marginBottom: '8px',
                }}>
                {row.positionNumber}
              </span>
            }
            dataSpec={`positionNumber-${idx}`}
            lineItemActionRequestLabel={getLineItemActionRequestLabel(
              pageConfig?.documentTypeId,
              row,
              ordersDocument,
              'positionNumber',
              idx
            )}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'positionNumber',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <Cell
            config={getField('references[0].orderReference.lineNumber')}
            value={row?.references[0]?.orderReference?.lineNumber}
            dataSpec={`references[0].orderReference.lineNumber-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'references[0].orderReference.lineNumber',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />

          <Cell
            config={getField('gtin')}
            value={row.gtin}
            dataSpec={`gtin-${idx}`}
            showChangedState={wasStateChanged(ordersDocument, row, 'gtin', idx)}
            themeSpecificColors={themeSpecificColors}
          />

          <Cell
            config={getField('gtinWithManufacturerArticleNumber')}
            value={
              <span>
                {row.gtin} <br /> {row.manufacturersArticleNumber}
              </span>
            }
            dataSpec={`gtinWithManufacturerArticleNumber-${idx}`}
            showChangedState={wasStateChanged(ordersDocument, row, 'gtin', idx)}
            themeSpecificColors={themeSpecificColors}
          />

          <Cell
            config={getField('manufacturersArticleNumber')}
            value={row.manufacturersArticleNumber}
            dataSpec={`manufacturersArticleNumber-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'manufacturersArticleNumber',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />

          <Cell
            config={getField('customersItemMaterialNumber')}
            value={row.customersItemMaterialNumber}
            dataSpec={`customersItemMaterialNumber-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'customersItemMaterialNumber',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />

          <Cell
            config={getField('suppliersItemMaterialNumber')}
            value={row.suppliersItemMaterialNumber}
            dataSpec={`suppliersItemMaterialNumber-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'suppliersItemMaterialNumber',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />

          <Cell
            config={getField('itemDescription')}
            value={row.itemDescription}
            dataSpec={`itemDescription-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'itemDescription',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <Cell
            config={getField('itemCategory')}
            value={row.itemCategory}
            dataSpec={`itemCategory-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'itemCategory',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <Cell
            config={getField('countryOfOrigin.countryName')}
            value={row.countryOfOrigin?.countryName}
            dataSpec={`countryOfOrigin-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'countryOfOrigin.countryName',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <Cell
            config={getField('caliber.value')}
            value={row.caliber?.value}
            dataSpec={`caliber-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'caliber.value',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <Cell
            config={getField('batchNumber')}
            value={row.batchNumber}
            dataSpec={`batchNumber-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'batchNumber',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <Cell
            config={getField('reasonOfReturn.value')}
            value={row.reasonOfReturn?.value}
            dataSpec={`reasonOfReturn-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'reasonOfReturn.value',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <Cell
            config={getField('currentItemPriceCalculationNetPerUnit')}
            value={
              <span>
                {currency} {row.currentItemPriceCalculationNet?.value} <br />
                <FormattedMessage
                  id="LE_PER"
                  values={{value: row.unitPriceBasis}}
                />
                {row.measureUnitQualifier}
              </span>
            }
            dataSpec={`currentItemPriceCalculationNet-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'currentItemPriceCalculationNet.value',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <FilterCell
            config={getField('references')}
            value={get(row, 'references')}
            valuePath="contractReference.referenceNumber"
            dataSpec={`references-${idx}`}
          />

          <BusinessEntityCell
            fields={pageConfig.formFields}
            value={row.consignee}
            prefix="consignee"
            dataSpec={`consignee-${idx}`}
          />

          <Cell
            config={getField('references[0].contractReference.referenceNumber')}
            value={get(row, 'references[0].contractReference.referenceNumber')}
            dataSpec={`references[0].contractReference.referenceNumber-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'references[0].contractReference.referenceNumber',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />

          <Cell
            config={getField('previouslyScheduledQuantity')}
            value={row.previouslyScheduledQuantity}
            dataSpec={`previouslyScheduledQuantity-${idx}`}
            showChangedState={wasStateChanged(
              ordersDocument,
              row,
              'previouslyScheduledQuantity',
              idx
            )}
            themeSpecificColors={themeSpecificColors}
          />
          <DeliveryCell
            lineItem={row}
            lineItemConfig={lineItemConfig}
            dataSpec={`deliveries-${idx}`}
            ordersDocument={ordersDocument}
            themeSpecificColors={themeSpecificColors}
            lineItemIndex={idx}
          />

          {showCalculation && (
            <CalcBody
              lineItem={row}
              currency={currency}
              hideTotalPrice={hideTotalPrice}
              ordersDocument={ordersDocument}
              themeSpecificColors={themeSpecificColors}
              lineItemIndex={idx}
            />
          )}

          {onSelect && (
            <Table.Cell
              verticalAlign="top"
              textAlign="center"
              data-spec={`details-${idx}`}>
              <Button
                size="mini"
                color="purple"
                index={idx}
                onClick={onSelect}
                compact
                icon>
                <Icon name="eye" />
              </Button>
            </Table.Cell>
          )}

          {hasEditables && (
            <EditCell
              formFields={pageConfig.formFields}
              onDelete={onDelete}
              onEdit={onEdit}
              onDuplicate={onDuplicate}
              row={row}
              canDelete={canDelete}
              canDuplicate={canDuplicate}
              idx={idx}
              data-spec={`editCell-${idx}`}
            />
          )}
        </Table.Row>
      ))}
    </Table.Body>
  );
};

export default TableBody;

TableBody.propTypes = {
  onDuplicate: PropTypes.func,
  lineItems: PropTypes.array,
  currency: PropTypes.string,
  lineItemConfig: PropTypes.object,
  pageConfig: pageConfigShape,
  showCalculation: PropTypes.bool,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
};
