/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {RenderContents, Content} from '@ecosio/pathform';
import {Button, Table, Modal, Header, Grid} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {intlShape} from '@ecosio/components';
import {CALCULATION_TYPES} from '../../../../shapes/scenarios';
import {round} from '../../../../calculations/math';
import {ALLOWANCE, PERCENTAGE} from '../../../../constants';
import {ConditionalCurrency} from './ConditionalCurrency';

const styleAoc = {color: '#99a4af'};

const AllowanceOrChanceItem = ({item, currency}) => {
  const isPercent = item.allowanceChargeTypeCoded === PERCENTAGE;
  const PREFIX = item.allowanceChargeQualifier === ALLOWANCE ? '-' : '+';

  return isPercent ? (
    <div style={styleAoc}>
      {PREFIX}
      <FormattedNumber
        style="percent"
        minimumFractionDigits={2}
        value={round(item.percentageOfAllowanceOrCharge / 100)}
      />
    </div>
  ) : (
    <div style={styleAoc}>
      {PREFIX}
      <ConditionalCurrency
        value={item.allowanceChargeAmount}
        currency={currency}
      />
    </div>
  );
};

AllowanceOrChanceItem.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string,
};
const ItemComponent = ({content, input, intl, measureUnit}) => {
  return content ? (
    <div style={{display: 'inline-block', marginRight: '15px'}}>
      <strong>{intl.formatMessage({id: input.label})}</strong>
      <div>
        {content} {measureUnit ? intl.formatMessage({id: measureUnit}) : ''}
      </div>
      <br />
    </div>
  ) : null;
};

ItemComponent.propTypes = {
  content: PropTypes.string,
  // TODO: export this shape from the pathform lib
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  intl: intlShape,
  measureUnit: PropTypes.string,
};

const PriceCalculation = ({path, currency}) => (
  <Content
    path={path}
    render={({content, input, intl}) => (
      <React.Fragment>
        <Grid.Row>{intl.formatMessage({id: input.label})}</Grid.Row>
        <Grid.Row>
          <h3>
            <FormattedNumber value={round(content)} /> {currency}
          </h3>
        </Grid.Row>
      </React.Fragment>
    )}
  />
);

PriceCalculation.propTypes = {
  path: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

const Prices = ({lineitem, currency, fields}) => {
  return (
    <RenderContents initialValues={lineitem} fields={fields}>
      {() => (
        <Grid>
          <Grid.Column width={5} style={{textAlign: 'left'}}>
            <PriceCalculation
              path="currentItemPriceCalculationGross.value"
              currency={currency}
            />
          </Grid.Column>
          <Grid.Column width={6} style={{textAlign: 'center'}}>
            <PriceCalculation
              path="currentItemPriceCalculationNet.value"
              currency={currency}
            />
          </Grid.Column>
          <Grid.Column width={5} style={{textAlign: 'right'}}>
            <PriceCalculation path="lineItemAmount" currency={currency} />
          </Grid.Column>
        </Grid>
      )}
    </RenderContents>
  );
};

Prices.propTypes = {
  lineitem: PropTypes.object.isRequired,
  currency: PropTypes.string,
  fields: PropTypes.array.isRequired,
};

const AllowancesAndCharges = ({lineitem, currency, lineItemConfig}) => {
  if (lineitem.allowancesAndCharges.length < 1) {
    // https://gitlab.ecosio.com/code/frontend/issues/337
    if (lineItemConfig.amountCalculation === CALCULATION_TYPES.NONE) {
      return null;
    }

    return (
      <Fragment>
        <h3 style={{textAlign: 'center'}}>
          <FormattedMessage id="LE_AOC_EMPTY_STATE" />
        </h3>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <h3>
        <FormattedMessage id="GENERAL_ALLOWANCES_AND_CHARGES" />
      </h3>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="GENERAL_UNIT_PRICE" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="GENERAL_TOTAL_PRICE" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lineitem.allowancesAndCharges.map((item, idx) => (
            <Table.Row key={idx}>
              <Table.Cell textAlign="right" verticalAlign="top">
                <ConditionalCurrency
                  value={lineitem.currentItemPriceCalculationGross?.value}
                  currency={currency}
                />
                <AllowanceOrChanceItem currency={currency} item={item} />
              </Table.Cell>
              <Table.Cell textAlign="right" verticalAlign="top">
                <ConditionalCurrency
                  value={item.chargeBaseQuantity}
                  currency={currency}
                />

                <div style={styleAoc}>
                  {item.allowanceChargeQualifier === ALLOWANCE ? '-' : '+'}
                  <ConditionalCurrency
                    value={item.allowanceChargeAmount}
                    currency={currency}
                  />
                </div>

                {item.allowanceChargeQualifier === ALLOWANCE ? (
                  <ConditionalCurrency
                    value={item.chargeBaseQuantity - item.allowanceChargeAmount}
                    currency={currency}
                  />
                ) : (
                  <ConditionalCurrency
                    value={item.chargeBaseQuantity + item.allowanceChargeAmount}
                    currency={currency}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
};

AllowancesAndCharges.propTypes = {
  lineitem: PropTypes.object,
  currency: PropTypes.string,
  lineItemConfig: PropTypes.object,
};
const ModalImpl = ({
  open,
  fields,
  onClose,
  lineitem,
  lineItemConfig,
  currency,
}) => {
  return (
    <Modal open={open} size="large">
      <Header>
        <FormattedMessage
          id="LE_VIEW_MODAL_HEADER"
          values={{position: lineitem.positionNumber}}
        />
      </Header>
      <Modal.Content>
        <RenderContents initialValues={lineitem} fields={fields}>
          {() => {
            return (
              <Grid divided="vertically" container>
                <Grid.Column width={9}>
                  <Grid.Row>
                    <Content path="itemDescription" render={ItemComponent} />
                    <br />
                    <Content path="gtin" render={ItemComponent} />
                    <Content
                      path="manufacturersArticleNumber"
                      render={ItemComponent}
                    />
                    <br />
                    <Content
                      path="customersItemMaterialNumber"
                      render={ItemComponent}
                    />
                    <Content
                      path="suppliersItemMaterialNumber"
                      render={ItemComponent}
                    />
                    <Content path="unitPriceBasis" render={ItemComponent} />
                    <br />
                    <Content
                      path="references.agreementOrOrderNumberReference"
                      render={ItemComponent}
                    />
                    <br />
                    <Content
                      path="references.agreementOrOrderLineNumber"
                      render={ItemComponent}
                    />
                    <Content
                      path="alternativeQuantity.value"
                      render={ItemComponent}
                    />
                    <Content
                      path="alternativeQuantity.unit"
                      render={ItemComponent}
                    />
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Grid.Row>
                    <h3>
                      <FormattedMessage id="GENERAL_PRICE_INFORMATION" />
                    </h3>
                    <Prices
                      currency={currency}
                      lineitem={lineitem}
                      fields={fields}
                    />
                    <br />
                    <Content
                      path="invoicedQuantity"
                      measureUnit={lineitem.measureUnit}
                      render={ItemComponent}
                    />

                    <br />

                    <h3>
                      <FormattedMessage id="LE_TOTAL" /> {currency}{' '}
                      {round(
                        parseFloat(
                          get(
                            lineitem,
                            'currentItemPriceCalculationNet.value'
                          ) * get(lineitem, 'invoicedQuantity')
                        )
                      )}
                    </h3>

                    <br />

                    <br />
                    {lineItemConfig.amountCalculation ===
                    'NO_CALCULATION' ? null : (
                      <Fragment>
                        <AllowancesAndCharges
                          currency={currency}
                          lineitem={lineitem}
                          lineItemConfig={lineItemConfig}
                        />
                      </Fragment>
                    )}
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            );
          }}
        </RenderContents>
      </Modal.Content>
      <Modal.Actions>
        <Button color="purple" onClick={onClose}>
          <FormattedMessage id="GENERAL_CLOSE" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalImpl.propTypes = {
  open: PropTypes.bool,
  fields: PropTypes.object,
  lineitem: PropTypes.object,
  lineItemConfig: PropTypes.object,
  onClose: PropTypes.func,
  currency: PropTypes.string,
};

const InvoiceLineItemViewer = ({
  lineitem,
  open,
  onClose,
  pageConfig,
  currency,
}) => {
  // it is possible that a document has no line items...
  if (typeof lineitem === 'undefined') {
    return null;
  }
  return (
    <ModalImpl
      open={open}
      onClose={onClose}
      lineitem={lineitem}
      currency={currency}
      fields={pageConfig.formFields}
      lineItemConfig={pageConfig.lineItemConfiguration}
    />
  );
};

InvoiceLineItemViewer.propTypes = {
  open: PropTypes.bool,
  currency: PropTypes.string,
  lineitem: PropTypes.object,
  pageConfig: PropTypes.object,
  onClose: PropTypes.func,
};

export default InvoiceLineItemViewer;
