/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {InputArray, Input} from '@ecosio/pathform';
import {Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ArrayValueElementRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  div {
    flex: 1;
    word-break: break-word;
  }
`;

const InputArrayValueElementsListComponent = ({
  inputArrayName,
  inputName,
  path,
  maxArrayElements,
}) => {
  const [inputValue, setInputValue] = useState('');

  // Input Field handler
  const handleUserInput = (e) => {
    setInputValue(e.target.value);
  };

  // Reset Input Field handler
  const resetInputField = () => {
    setInputValue('');
  };

  const validateField = (value) =>
    value ? 'COOP_TRACK_AND_TRACE_ADD_VALIDATION' : undefined;

  return (
    <InputArray name={inputArrayName}>
      {({fields}) => {
        return (
          <>
            <Input
              disabled={fields?.length >= maxArrayElements}
              name={inputName}
              path={path}
              defaultValue={inputValue}
              validate={validateField}
              onInputChange={handleUserInput}
              size="mini"
              icon={
                fields.length < maxArrayElements && (
                  <Icon
                    name="plus"
                    onClick={() => {
                      if (inputValue) {
                        fields.push(inputValue);
                      }
                      resetInputField();
                    }}
                    link
                    data-spec="add"
                  />
                )
              }
            />

            {fields.length
              ? fields.map((name, index) => {
                  return (
                    <ArrayValueElementRow key={index}>
                      <Icon
                        style={{transform: 'rotate(90deg)'}}
                        name="level up alternate"
                      />
                      <div>{fields.value[index]}</div>
                      <Icon
                        name="trash"
                        onClick={() => fields.remove(index)}
                        data-spec="remove"
                        link
                      />
                    </ArrayValueElementRow>
                  );
                })
              : ''}
          </>
        );
      }}
    </InputArray>
  );
};

InputArrayValueElementsListComponent.defaultProps = {
  maxArrayElements: Number.MAX_SAFE_INTEGER,
};

InputArrayValueElementsListComponent.propTypes = {
  inputArrayName: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  maxArrayElements: PropTypes.number,
};

export default InputArrayValueElementsListComponent;
