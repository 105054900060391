import {get} from 'lodash';
import {
  INDUSTRY_ORDER,
  INDUSTRY_DELFOR,
  INDUSTRY_DELJIT,
  INDUSTRY_ORDER_RESPONSE,
  INDUSTRY_ORDCHG,
} from '../shapes/supportedDocTypes';
import {getPartyName} from '../util/documentUtil';

const logwarn = (msg) => console.warn(`DESADV GROUPDATE ERROR: ${msg}`);

const groupDates = (data, scenario) => {
  if (!data) {
    logwarn('Missing data parameter in groupDates');
    return {data: [], error: true};
  }

  if (!scenario) {
    logwarn('Missing scenario parameter in groupDates');
    return {data: [], error: true};
  }

  if (
    !data.document ||
    !data.meta ||
    !data.meta.documentTypeId ||
    !data.document.details
  ) {
    logwarn('Data Structure from Endpoint seems invalid');
    return {data: [], error: true};
  }

  const dates = [];

  // URGENT QUICK FIX: discuss this with @pulse00
  // const isOutbound = isOutboundDocType(data.meta.documentTypeId, scenario);

  const isPreceding = true;

  if (!isPreceding) {
    logwarn('Preceding Document configuration error');
    return {data: dates, error: true};
  }

  switch (data.meta.documentTypeId) {
    case INDUSTRY_ORDER:
    case INDUSTRY_ORDER_RESPONSE:
    case INDUSTRY_ORDCHG:
      if (
        !data.document.details.ordersData ||
        // TODO: check if array
        !data.document.details.ordersData.ordersLineItems
      ) {
        logwarn('Document has no ordersData');
        return {data: dates, error: true};
      }

      return groupOrderDates(data);

    case INDUSTRY_DELFOR:
    case INDUSTRY_DELJIT:
      if (
        !data.document.details.forecastData ||
        // TODO: check if array
        !data.document.details.forecastData.forecastLineItems
      ) {
        logwarn('Document has no forecastData');
        return {data: dates, error: true};
      }

      return groupDeliveryDates(data);

    default:
      logwarn(
        `Dategrouping is not compatible with this DocType ${data.meta.documentTypeId}`
      );
      return {data: [], error: true};
  }
};

const groupDeliveryDates = (data) => {
  const dates = [];
  let error = null;
  let consignee = {};
  let index = null;

  data.document.details.forecastData.forecastLineItems.forEach((obj) => {
    const consigneeData = get(obj, 'businessEntities.consignee', null);
    // if any of these fields is not there, we cannot filter
    // during the mapping. We must fail :(
    if (
      !consigneeData ||
      !consigneeData.duns ||
      !consigneeData.unloadingPoint ||
      !consigneeData.storageLocation
    ) {
      error = true;
      consignee = null;
      index = null;
      console.warn(
        'Missing data in consignee, user will not be able to create a DESADV. duns, unloadingPoint and storageLocation MUST be present.',
        consigneeData
      );
    } else {
      index = `${consigneeData.unloadingPoint}${consigneeData.duns}${consigneeData.storageLocation}`;
      consignee = {
        index,
        name: getPartyName(consigneeData),
        unloadingPoint: consigneeData.unloadingPoint,
        duns: consigneeData.duns,
        storageLocation: consigneeData.storageLocation,
      };
    }

    return obj.planningQuantities.reduce((acc, next) => {
      const date = next.requestedShipmentDate.dateTime.split('T')[0];
      const entries = acc.filter((item) => item.date === date);

      if (acc && entries.length < 1) {
        acc.push({
          date,
          consignees: [consignee],
        });
      } else if (acc && entries.length) {
        // If found - map over the accumulator and search for the consignee indexstring
        acc.map((item, idx) => {
          if (
            item.consignees
              .filter((con) => con !== null)
              .findIndex((con) => con.index === index)
          ) {
            // Consignee Indexstring found - push it into the consignees array
            acc[idx].consignees.push(consignee);
          }
        });
      }

      return acc;
    }, dates);
  });

  return {
    data: dates,
    error,
  };
};

export const isValidOrderLineItem = (obj) => {
  return (
    obj.consignee &&
    obj.consignee.duns &&
    obj.consignee.unloadingPoint &&
    obj.consignee.storageLocation
  );
};

/**
 * Builds a map of date -> [consignee] structure,
 * mapping each unique date in this order
 * to a list of possible shipping consignees.
 *
 * @param data the documentExchange containing the `document` erpel-industry
 * order attribute
 */
const groupOrderDates = (data) => {
  if (!data) {
    logwarn('No data supplied to groupDates');
    return [];
  }

  // this will hold a list of objects containing
  // dates to consignee maps
  // which will be the result of this method
  const dates = [];

  let error = null;
  // will hold the current consignee in the loop below
  let consignee = {};

  // the index is a compound unique index to identify a consignee
  // built by storgeLocation, duns and unloadingpoint attributes
  let index = null;

  const ordersLineItems = get(
    data,
    'document.details.ordersData.ordersLineItems',
    null
  );

  if (ordersLineItems === null) {
    logwarn('Invalid data supplied to group dates');
    return dates;
  }

  // go through all line items
  ordersLineItems.forEach((lineItem) => {
    if (!isValidOrderLineItem(lineItem)) {
      // we will skip this consignee in the map/reduce logic below, as it is
      // invalid
      error = true;
      consignee = null;
      index = null;
      console.warn(
        'Missing data in consignee, user will not be able to create a DESADV. duns, unloadingPoint and storageLocation MUST be present.',
        lineItem.consignee
      );
    } else {
      // we build our consignee object for the current line item
      index = `${lineItem.consignee.unloadingPoint}${lineItem.consignee.duns}${lineItem.consignee.storageLocation}`;
      consignee = {
        index,
        name: getPartyName(lineItem.consignee),
        unloadingPoint: lineItem.consignee.unloadingPoint,
        duns: lineItem.consignee.duns,
        storageLocation: lineItem.consignee.storageLocation,
      };
    }

    // now that we have the consignee of this line item, map/reduce
    // all orderScheduleLines to fill the dates array
    return lineItem.ordersScheduleLines.reduce((acc, next) => {
      // Get date without Time for grouping
      const date = next.scheduledQuantityDate.dateTime.split('T')[0];

      // check if the current date is already initialized
      const entries = acc.filter((item) => item.date === date);

      // if the date of this line item has not been set already,
      // push it into the result with an empty consignee array
      if (acc && entries.length === 0) {
        acc.push({date, consignees: []});
      }

      // now for each date item, add the current consignee if not already there
      acc
        .filter((item) => item.date === date)
        .forEach((resultItem) => {
          // check if the current item already contains the current consignee
          const existingConsigneeIdx = resultItem.consignees
            .filter((con) => con !== null)
            .findIndex((con) => con.index === index);
          // if not, append it
          if (existingConsigneeIdx === -1) {
            resultItem.consignees.push(consignee);
          }
        });

      return acc;
    }, dates);
  });

  return {
    data: dates,
    error,
  };
};

export default groupDates;
