import React, {Component} from 'react';
import {Header, Button} from 'semantic-ui-react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {get} from 'lodash';
import withScenario from '../hoc/withScenario';
import {scenarioShape} from '../../shapes/scenarios';
import {FlashMessage} from '../DynamicTemplate';
import {HelmetTags} from '../../helpers/components/HelmetTags';
import MonthlyReportList from './MonthlyReportList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 38px 48px 59px 48px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const getSortColumnNameForBackend = (
  monthlyDocumentPageConfig,
  dataGridColumnName
) => {
  const formFields = monthlyDocumentPageConfig?.formFields || {};
  const columnField = get(formFields, dataGridColumnName);
  return columnField?.tableSortFieldPath;
};

class MonthlyReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {openModal: false};
  }

  onCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  onOpenModal = () => {
    this.setState({
      openModal: true,
    });
  };

  render() {
    const {scenario} = this.props;
    const {openModal} = this.state;

    return (
      <Container>
        <HelmetTags scenario={scenario} />
        <FlashMessage />
        <HeaderContainer>
          <Header as="h1">
            <Header.Content>
              <FormattedMessage
                id={dynID(`${scenario?.nameTranslationKey}_HEADER`)}
              />
            </Header.Content>
            <Header.Subheader>
              <FormattedMessage
                id={dynID(`${scenario?.nameTranslationKey}_SUBHEADER`)}
              />
            </Header.Subheader>
          </Header>
          <Button data-spec="CREATE-MONTHLY-REPORT" onClick={this.onOpenModal}>
            <FormattedMessage id="CREATE_MONTHLY_REPORT" />
          </Button>
        </HeaderContainer>
        <MonthlyReportList
          {...this.props}
          openModal={openModal}
          onCloseModal={this.onCloseModal}
        />
      </Container>
    );
  }
}

MonthlyReportComponent.propTypes = {
  scenario: scenarioShape.isRequired,
};

export default withScenario()(MonthlyReportComponent);
