/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import jexl from 'jexl';
import {dynID} from '@ecosio/pathform';
import styled from 'styled-components';
import {Header, Icon, Divider} from 'semantic-ui-react';
import {injectIntl, FormattedMessage as Msg} from 'react-intl';
import {get} from 'lodash';
import {intlShape} from '@ecosio/components';
import {
  COOP_BUSINESSCASE_EXPRESSION_X2,
  ECOSIO_THEME,
} from '../../../../../../helpers/constants';
import DesadvInput from '../../CommonComponents/Create/LineItems/DesadvInput';
import {FieldContent} from '../../CommonComponents/Create/LineItems/FieldContent';
import {loadTheme} from '../../../../../../helpers/utils';

const ContentDivider = styled(Divider)`
  margin-top: 16px;
  margin-left: -20px !important;
`;

const ModalSideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  margin-top: 20px;
  margin-left: 20px;
  width: 100%;
`;

const ModalPackagingAmountContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const PackagingLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    display: flex;
    i {
      margin-right: 8px;
      font-size: 12px !important;
      ${(props) =>
        props?.customColor ? `color: ${props.customColor} !important` : ''};
    }
  }
`;

const ModalSideBarRootContainer = styled.div`
  display: flex;
  margin-left: -20px;
  margin-top: -20px;
  margin-right: 40px;
  background-color: #fff3e5;
  width: 20%;
`;

const NumberOfPackagesContainer = styled.div`
  border: 1px solid #f59c00;
  border-radius: 5px;
  margin-right: 20px;
  font-size: 11px;
  padding: 4px 7px 4px 6px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  margin-left: 32px;
`;

const SSCC_Container = styled.div`
  margin-left: -10px;
  overflow-wrap: anywhere;
  margin-right: 10px;
`;

const Empty_SSCC_Container = styled.div`
  overflow-wrap: anywhere;
  margin-right: 10px;
  font-size: 0.83em;
`;

const ModalSideBar = ({pageConfig, document, theme, intl}) => {
  const isSimpleComplexDesadvWithSideBar = jexl.evalSync(
    COOP_BUSINESSCASE_EXPRESSION_X2,
    document
  );

  if (!isSimpleComplexDesadvWithSideBar) {
    return null;
  }

  const formFieldConfig = pageConfig?.formFields || {};

  const packagingLabel = pageConfig?.cpsConfigs[0]?.label;
  const dispatchData = document?.details?.dispatchData || {};

  const sccFieldPath = 'cpses.packagingInformations.identityNumber';
  const currentTheme = loadTheme(theme);

  const sideBarSsccFieldConfig = get(formFieldConfig, sccFieldPath);
  const cpsesWithoutRoot = dispatchData?.consignmentPackagingSequences.filter(
    (cps) => cps?.hierarchicalID !== '1'
  );
  return (
    <ModalSideBarRootContainer>
      <ModalSideBarContainer>
        <PackagingLabelContainer customColor={currentTheme?.primaryColor}>
          <Header as="h4" data-spec="pck-type">
            <Icon name="pallet" />
            <Msg id={dynID(packagingLabel)} />
            <ModalPackagingAmountContainer>
              <NumberOfPackagesContainer>
                {
                  dispatchData?.consignmentPackagingSequences[0]
                    ?.packagingInformations[0]?.numberOfPackages
                }
                {` `}
                <Msg id="PCE" />
              </NumberOfPackagesContainer>
            </ModalPackagingAmountContainer>
          </Header>
        </PackagingLabelContainer>
        <ContentDivider />
        {!cpsesWithoutRoot.length && (
          <Empty_SSCC_Container>
            {intl.formatMessage({
              id: 'COOP_SSCC_SIDEBAR_READ_VIEW_ZERO_PACKAGES',
            })}
          </Empty_SSCC_Container>
        )}
        {cpsesWithoutRoot.map((cps, cpsesKey) => {
          return (
            <DesadvInput
              key={cpsesKey}
              columnWidth={16}
              path={sccFieldPath}
              forceRender
              render={(props) => (
                <SSCC_Container>
                  <FieldContent
                    {...props}
                    value={
                      cps.packagingInformations[0].identifications[0]
                        .identityNumber
                    }
                    translatedLabel={intl.formatMessage(
                      {
                        id: dynID(sideBarSsccFieldConfig?.input?.label),
                      },
                      {
                        position: Number(cps?.hierarchicalID) - 1,
                      }
                    )}
                    fieldConfig={sideBarSsccFieldConfig}
                  />
                </SSCC_Container>
              )}
            />
          );
        })}
      </ModalSideBarContainer>
    </ModalSideBarRootContainer>
  );
};

ModalSideBar.defaultProps = {
  isNullPosition: false,
  theme: ECOSIO_THEME,
};

ModalSideBar.propTypes = {
  packagingLabel: PropTypes.string,
  formFieldConfig: PropTypes.object.isRequired,
  dispatchData: PropTypes.object.isRequired,
  exposedValues: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  theme: PropTypes.string,
  isNullPosition: PropTypes.bool,
};

export default injectIntl(ModalSideBar);
