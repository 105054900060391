import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {getApplicationUrls} from '@ecosio/auth';
import {FormattedMessage} from 'react-intl';
import htmlParse from 'html-react-parser';
import {ecosioColors} from '@ecosio/customer-layout';
import {CompanyTypes} from '../../constants';
import {loadTheme} from '../utils';

const BannerContainer = styled.div`
  display: flex;
  height: 31px;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-left: -10px;
  margin-right: -10px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  ${(props) =>
    props?.backgroundColor
      ? `background:${props.backgroundColor} !important`
      : ''}
`;

const TermsOfServiceNotAcceptedBanner = (props) => {
  const {userConfig, theme} = props;
  const currentTheme = loadTheme(theme);

  if (
    !userConfig?.supplierTermOfServiceAccepted &&
    userConfig?.company?.companyType === CompanyTypes.EXTERNAL
  ) {
    const allAppsServerLinks = getApplicationUrls(window.location.href) || {};
    const accountsServerHref = allAppsServerLinks?.FE_CONFIG_OAUTH_SERVER;

    if (accountsServerHref) {
      return (
        <BannerContainer backgroundColor={currentTheme?.primaryColor}>
          <a
            href={`${accountsServerHref}/settings/account`}
            style={{color: ecosioColors.greyShades[7]}}>
            <FormattedMessage id="TERMS_OF_SERVICE_BAR_USER_NOT_ACCEPTED">
              {(chunks) => htmlParse(chunks.join(''))}
            </FormattedMessage>
          </a>
        </BannerContainer>
      );
    }
  }
  return null;
};

TermsOfServiceNotAcceptedBanner.defaultProps = {
  userConfig: {},
};

TermsOfServiceNotAcceptedBanner.propTypes = {
  userConfig: PropTypes.object,
  theme: PropTypes.string.isRequired,
};

const mapStateToProps = ({config}) => ({
  userConfig: config?.userConfig,
  theme: config?.userConfig?.theme,
});

export default connect(mapStateToProps)(TermsOfServiceNotAcceptedBanner);
