import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {Field} from 'react-final-form';
import {aocItemShape} from '../shapes';
import FailSafeFormattedNumber from '../../../../../General/FailSaveFormattedNumber';
import {ALLOWANCE, CURRENCY_EUR} from '../../../../../../constants';

const styleAoc = {color: '#99a4af'};

export const TextField = ({
  label,
  name,
  bold = false,
  currency = CURRENCY_EUR,
}) => {
  const style = {padding: '2px 0px', fontWeight: bold ? 'bold' : 'normal'};
  return (
    <Grid.Row style={style}>
      <Grid.Column width="14">
        {label && <FormattedMessage id={label} />}
      </Grid.Column>
      <Grid.Column width="2">
        <Field
          name={name}
          render={({input}) => (
            <FormattedNumber
              value={input.value}
              style="currency"
              currency={currency}
            />
          )}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

TextField.propTypes = {
  bold: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
};

export const TextAoc = ({field, name, currency = CURRENCY_EUR}) => {
  const sign = field.allowanceChargeQualifier === ALLOWANCE ? '-' : '+';
  const label =
    sign === '-' ? 'INVOICE_AOC_ALLOWANCE_OF' : 'INVOICE_AOC_CHARGE_OF';

  return (
    <Grid.Row style={{padding: '2px 0px'}}>
      <Grid.Column width="14">
        <div style={styleAoc}>
          <FormattedMessage id={label} />
        </div>
      </Grid.Column>
      <Grid.Column width="2">
        <div style={styleAoc}>
          {sign}
          <FailSafeFormattedNumber currency={currency} value={field[name]} />
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

TextAoc.propTypes = {
  field: aocItemShape,
  name: PropTypes.string.isRequired,
};
