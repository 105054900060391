/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {FormatDate} from '@ecosio/components';
import {Content} from '@ecosio/pathform';
import ValidationWarnIcon from '../ValidationWarnIcon';
import {inMemoryValidatorShape} from '../../../shapes/inMemoryValidatorShape';

const renderValidationIconOnError = (path, inMemoryValidation) => {
  if (
    inMemoryValidation?.hasErrors &&
    Array.isArray(inMemoryValidation?.errorData)
  ) {
    const filteredError = inMemoryValidation.errorData.filter(
      (error) => error[path]
    );

    if (filteredError[0]) {
      return <ValidationWarnIcon tooltipData={filteredError[0][path]} />;
    }
  }
};

/**
 *
 * used in TabbedItem for document header webedi ui
 * when there is a text with line breaks `\n`, it will then be mapped as a seperate line
 * needed for paymentTerms KTM
 */
const NewLineText = ({text}) => {
  if (text) {
    return text
      .toString()
      .split('\\n')
      .map((str, key) => <p key={key}>{str}</p>);
  }
  return '';
};

export const TabbedItem = ({path, inMemoryValidation}) => (
  <Content
    path={path}
    render={({label, content}) => {
      return content ? (
        <List.Item divided="true" data-spec={`l-${path}`}>
          <List.Content floated="right">
            <NewLineText text={content} />
            {renderValidationIconOnError(path, inMemoryValidation)}
          </List.Content>
          <List.Content>
            <strong>
              <FormattedMessage id={label} />:
            </strong>
          </List.Content>
        </List.Item>
      ) : null;
    }}
  />
);

TabbedItem.propTypes = {
  path: PropTypes.string.isRequired,
  inMemoryValidation: inMemoryValidatorShape,
};

export const TabbedDate = ({path, stripTimezone, inMemoryValidation}) => (
  <Content
    path={path}
    render={({label, content}) => {
      return content ? (
        <List.Item divided="true" data-spec={`l-${path}`}>
          <List.Content floated="right">
            <FormatDate stripTimezone={stripTimezone} dateString={content} />
            {renderValidationIconOnError(path, inMemoryValidation)}
          </List.Content>
          <List.Content>
            <strong>
              <FormattedMessage id={label} />:
            </strong>
          </List.Content>
        </List.Item>
      ) : null;
    }}
  />
);

TabbedDate.propTypes = {
  path: PropTypes.string.isRequired,
  stripTimezone: PropTypes.bool.isRequired,
  inMemoryValidation: inMemoryValidatorShape,
};
