export const FILTER_OUT = 'FILTER_OUT';

export const FILTER_OUT_VALUE_TRUE = 'true';

export const FILTER_OUT_VALUE_FALSE = 'false';
export const STANDARD_TOAST_DURATION = 8000;
export const DESADV_NULL_POSITION = 'NULLPOSITION';

//https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/787
export const CPS_SHIPPING_MARK_TYPE = {
  MIXED_PACKAGE: 'G',
  UN_MIXED_PACKAGE: 'S',
};

export const DAILY_DOCUMENT = 'DAILY_DOCUMENT';
export const INVOICE_AND_CREDITNOTE_REPORT = 'INVOICE_AND_CREDITNOTE_REPORT';

export const MONTHLY_REPORT = 'MONTHLY_REPORT';
export const MONTHLY_REPORT_CSV = 'MONTHLY_REPORT_CSV';

export const DAILY_DOCUMENT_OVERVIEW = 'OVERVIEW';
export const DAILY_DOCUMENT_CREATE = 'CREATE';

export const FILE_UPLOAD = 'FILE_UPLOAD';

export const MONTHS = 'months';

export const DASHBOARD_SCENARIO = 'DASHBOARD';

/**
 * expl: January 2022
 */
export const DATE_MONTH_NAME_AND_YEAR_FORMAT = 'MMMM YYYY';

/**
 * expl: 2022/01/13
 */
export const DATE_YEAR_MONTH_DAY_FORMAT = 'YYYY/MM/DD';

export const NON_ENVELOPE_PDF = 'NON_ENVELOPE_PDF';

export const ON_SUCCESS_FLASH_MESSAGE_DURATION = 30000;

export const ECOSIO_THEME = '98.1.29/ecosio';

export const EMPTY_DROPDOWN_VALUE = 'NA_DROPDOWN_VALUE';

export const PICKED_UP = 'PICKUP';

//TODO: config based?
export const COOP_BUSINESSCASE_EXPRESSION_X2 = "header.businessCaseType=='X2'";
