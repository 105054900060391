/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {EmptyListState, InputField} from '@ecosio/components';
import {Form as SemanticForm, Grid, Pagination} from 'semantic-ui-react';
import {Form, Field} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import './PaginatedSerialNumberForm.css';

class PaginatedSerialNumberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 12,
    };
  }

  isIndexInCurrentPage = (index) => {
    const min = this.state.currentPage * this.state.pageSize;
    const max = min + this.state.pageSize;
    return index >= min && index < max;
  };

  onPageChange = (e, {activePage}) => {
    this.setState({currentPage: activePage - 1});
  };

  render() {
    const {arrayPath, initialValues} = this.props;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.props.onSave}
        mutators={{
          ...arrayMutators,
        }}
        render={({handleSubmit}) => (
          <SemanticForm onSubmit={handleSubmit} id="serialNumberForm">
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <FieldArray name={arrayPath}>
                    {({fields}) => (
                      <Grid columns={3}>
                        <Grid.Row>
                          {!fields.length && (
                            <EmptyListState
                              header="INVOIC_NO_SN_HEADER"
                              subHeader="INVOIC_NO_SN_SUBHEAD"
                              icon="barcode"
                            />
                          )}
                          {fields.map((name, index) => (
                            <Fragment key={`${name}-${index}`}>
                              <Grid.Column className="fieldCol">
                                {this.isIndexInCurrentPage(index) && (
                                  <Field
                                    fluid
                                    name={name}
                                    label={null}
                                    component={InputField}
                                  />
                                )}
                              </Grid.Column>
                            </Fragment>
                          ))}
                        </Grid.Row>
                        {fields.length > this.state.pageSize && (
                          <Grid.Row>
                            <Grid.Column>
                              <Pagination
                                onPageChange={this.onPageChange}
                                defaultActivePage={1}
                                totalPages={fields.length / this.state.pageSize}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SemanticForm>
        )}
      />
    );
  }
}

PaginatedSerialNumberForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  arrayPath: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default PaginatedSerialNumberForm;
