const extractItemConfig = (config, configID) => {
  const found = config.cpsConfigs.find((item) => {
    return item.configID === configID;
  });

  if (!found) {
    return null;
  }

  return found;
};

export default extractItemConfig;
