import {DEFAULT_DISPATCH_MEASURE_UNIT} from '../../../../../../constants';
import {CPS_SHIPPING_MARK_TYPE} from '../../../../../../helpers/constants';

//StructuredDesadv, Final cps Build before sending to Hub
export const buildStructuredCps = (dispatchData) => {
  const dispatchDataDeliveryLineItems =
    dispatchData?.consignmentPackagingSequences[0]?.deliveryLineItems;

  if (
    !dispatchDataDeliveryLineItems ||
    !Array.isArray(dispatchDataDeliveryLineItems) ||
    !dispatchDataDeliveryLineItems.length
  ) {
    console.error(
      'Failed to build Structured desadv with cpses, undefined or empty deliveryLineItems',
      dispatchDataDeliveryLineItems
    );

    return undefined;
  }

  let result = [];
  let cpsCount = 0;
  let positionNumberCounter = 0;
  let hierarchicalIDCounter = 1;
  dispatchDataDeliveryLineItems.forEach((item) => {
    const itemCpses = item.cpses;
    const deliveryLineItem = item;

    const quantityPerPack = deliveryLineItem.quantityPerPack;
    let totalDispatchQuantity = deliveryLineItem.dispatchQuantity.value;
    delete deliveryLineItem.cpses;

    const cpses = itemCpses.map((cps) => {
      cpsCount = cpsCount + 1;
      totalDispatchQuantity = totalDispatchQuantity - quantityPerPack;
      return {
        ...cps,
        packagingInformations: [
          {
            ...cps?.packagingInformations[0],
            //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/787
            shippingMarkType: CPS_SHIPPING_MARK_TYPE.UN_MIXED_PACKAGE,
          },
        ],
        hierarchicalID: `${++hierarchicalIDCounter}`,
        deliveryLineItems: [
          {
            ...deliveryLineItem,
            positionNumber: `${++positionNumberCounter}`,
            dispatchQuantity: {
              unit: item?.dispatchQuantity?.unit
                ? item.dispatchQuantity.unit
                : DEFAULT_DISPATCH_MEASURE_UNIT,
              value: cps?.packagingInformations[0]?.quantityPerPack || 0,
            },
          },
        ],
      };
    });

    result = [...result, ...cpses];
  });

  const parentCPS = {
    hierarchicalID: '1',
    packagingInformations: [{numberOfPackages: cpsCount}],
  };

  result = [parentCPS, ...result];
  return result;
};
