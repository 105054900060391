import {get, set} from 'lodash';
import {
  COMPLEX_ENRICHMENT_DEFAULT_INTERNAL_VAT_VARIANCE_CODE,
  INVOICE_LIN_ORDERS_VAT_RATE_IN_DETAILS_PROPERTIES_KEY,
} from '../constants';
import {round, percentageOf} from './math';

import AllowanceOrCharges from './AllowanceOrCharge';
import {calculateVatRates} from './FooterVatRates';

const INVOICE_PATH_LINEITEMS =
  'data.document.details.invoiceData.invoiceLineItems';
const INVOICE_PATH_FOOTER = 'data.document.footer.invoiceFooter';

const INVOICE_PATH_HEADER = 'data.document.header.invoiceHeader';

const INVOICE_ROUNDING_AMOUNT_PATH =
  'data.document.footer.simpleTotalInvoiceAmountRoundingAmount';

/**
 * Receives a documentExchange (containing an erpel industry invoice)
 * and calculates the following data:
 *
 * - line items
 * - allowance and charges for line items
 * - allowances and charges on document level
 * - footer totals and vat summary
 *
 * Calculations are always triggered using calcDocument()
 *
 * TODO: this class should be refactored to a set of immutable utility functions which
 * take a document, mutate it, and return a new document
 * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/172
 *
 * Then we could refactor the InvoiceTemplate and set the document as actual initialValues
 * to things like the footer rounding amount, see InvoiceFooterRoundingRow.jsx
 */
class InvoiceCalculator {
  constructor(documentExchange = null, pageConfig = null) {
    this.document = null;
    this.totals = {
      totalLineItemAmount: 0,
      totalTaxableAmount: 0,
    };

    this.pageConfig = null;

    if (documentExchange) {
      this.setDocument(documentExchange);
    }

    if (pageConfig) {
      this.setPageConfig(pageConfig);
    }
  }

  setPageConfig = (pageConfig) => {
    this.pageConfig = Object.assign({}, pageConfig);
    return this;
  };

  getDocument = () => {
    this.calcDocument();

    return this.document;
  };

  reIndexLineItems = () => {
    this.lineItems = this.lineItems.map((item, idx) => {
      return {
        ...item,
        positionNumber: idx + 1,
      };
    });

    return this;
  };

  removeLineItem = (positionNumber) => {
    this.lineItems = this.lineItems.filter(
      (item) => item.positionNumber !== positionNumber
    );

    return this;
  };

  setLineItem = (positionNumber, callback) => {
    if (!positionNumber) {
      this.lineItems.push(callback());
    } else {
      const lineItem = this.lineItems.find(
        (i) => i.positionNumber === positionNumber
      );

      const editedLineItem = callback(lineItem);

      this.lineItems = this.lineItems.map((i) => {
        return i.positionNumber === positionNumber ? editedLineItem : i;
      });
    }

    return this;
  };

  resetLineItemVats = (vatVariant) => {
    let vatRate = {};
    const vatTaxCode = vatVariant?.taxCode;

    if (vatVariant?.vatrates.length === 1) {
      vatRate = {vatrate: vatVariant?.vatrates[0]};
    }

    const addDefaultVatRate =
      vatVariant?.vatrates.length > 1 &&
      vatVariant?.internalVATVarianceCode ===
        COMPLEX_ENRICHMENT_DEFAULT_INTERNAL_VAT_VARIANCE_CODE;

    this.lineItems = this.lineItems.map((item) => {
      if (addDefaultVatRate) {
        const orderVatRateObject = item?.detailsProperties.find(
          (p) => p.key === INVOICE_LIN_ORDERS_VAT_RATE_IN_DETAILS_PROPERTIES_KEY
        );

        if (orderVatRateObject) {
          vatRate = {vatrate: orderVatRateObject?.value};
        }
      }

      return {
        ...item,
        ...vatRate,
        vattaxCode: vatTaxCode,
      };
    });

    return this;
  };

  setRoundingAmount = (amount) => {
    set(this.document, INVOICE_ROUNDING_AMOUNT_PATH, amount);
    return this;
  };

  setDocument = (documentExchange) => {
    this.document = Object.assign({}, documentExchange);
    /**
     * TODO Kranthi align LINI configs so that all three object deliver sufficient data for the page
     * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/309
     *  */
    this.footer = get(this.document, INVOICE_PATH_FOOTER);
    this.lineItems = get(this.document, INVOICE_PATH_LINEITEMS);
    this.header = get(this.document, INVOICE_PATH_HEADER);

    this.totals = {
      totalLineItemAmount: 0,
      totalTaxableAmount: 0,
    };
    this.calcDocument();

    return this;
  };

  /**
   * Main starting point of invoice data calculation.
   * @return {InvoiceCalculator}
   */
  calcDocument = () => {
    // resets the totals
    this.totals = {
      totalLineItemAmount: 0,
      totalTaxableAmount: 0,
    };

    // start with calculating the line-items and their inner allowances and charges
    // this will internally calculate the totalLineItemAmount field
    this.calculateAllLineItems();
    // calculate AoCs on header level
    // will also set this.totalTaxableAmount
    this.calcHeaderAocs();
    // calculate VAT rates and totals in the footer
    this.calcFooter();

    // update the document
    set(this.document, INVOICE_PATH_FOOTER, this.footer);
    set(this.document, INVOICE_PATH_LINEITEMS, this.lineItems);
    set(this.document, INVOICE_PATH_HEADER, this.header);

    return this;
  };

  /**
   * Recalculates all the AoC items in the header and sets the
   * totalTaxableAmount.
   */
  calcHeaderAocs = () => {
    const newHeader = {
      allowancesAndCharges: [],
    };

    const allowanceOrCharges = this.header?.allowancesAndCharges || [];

    if (allowanceOrCharges.length) {
      const aoc = new AllowanceOrCharges(this.header.allowancesAndCharges);

      // calculate all header AoCs with baseAmount (which is the totalLineItemAmount)
      newHeader.allowancesAndCharges = aoc
        .calcAoc(this.totals.totalLineItemAmount)
        .getResult();

      // set the totalTaxableAmount to the last Aocs calculated total
      // background: AoC calculation is cumulative
      this.totals.totalTaxableAmount = aoc.calcTotal();

      // set new header
      this.header = Object.assign({}, this.header, newHeader);
    } else {
      // if we have no AoCs, the taxable amount equals the lineItemAmount
      this.totals.totalTaxableAmount = this.totals.totalLineItemAmount;
    }

    return this.header;
  };

  /**
   * Calculates the document VATs and the invoiceTotals in the documents footer.
   *
   * @return {InvoiceCalculator}
   */
  calcFooter = () => {
    // prepare a new footer object
    const newFooter = {
      invoiceTotals: {
        totalLineItemAmount: 0,
        totalTaxableAmount: 0,
        totalVATAmount: 0,
        invoiceAmount: 0,
      },
      vatrates: [],
    };

    // calculate the vatRates based on all calculated lineItems and header AoCs
    const vatRates = calculateVatRates(
      this.lineItems,
      this.header.allowancesAndCharges,
      this.pageConfig
    );

    newFooter.vatrates = vatRates;

    // calculate the totalVatAmount based on the calculated vats
    newFooter.invoiceTotals.totalVATAmount = round(
      vatRates.reduce((acc, curr) => {
        return acc + curr.vatamount;
      }, 0)
    );

    newFooter.invoiceTotals.totalLineItemAmount = round(
      parseFloat(this.totals.totalLineItemAmount)
    );

    newFooter.invoiceTotals.totalTaxableAmount = round(
      parseFloat(this.totals.totalTaxableAmount)
    );

    newFooter.invoiceTotals.invoiceAmount = round(
      parseFloat(newFooter.invoiceTotals.totalTaxableAmount) +
        parseFloat(newFooter.invoiceTotals.totalVATAmount)
    );

    const roundingAmountAsFloat = parseFloat(
      get(this.document, INVOICE_ROUNDING_AMOUNT_PATH)
    );

    // https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/128
    const roundingAmount = isNaN(roundingAmountAsFloat)
      ? 0
      : roundingAmountAsFloat;

    newFooter.invoiceTotals.invoiceAmount = round(
      newFooter.invoiceTotals.invoiceAmount + roundingAmount
    );

    this.footer = Object.assign({}, this.footer, newFooter);
    return this;
  };

  /**
   * Calculates the lineItemAmount without allowance and charges
   * @param lineItem
   * @return {number}
   */
  calculateLineItemAmountWithoutAoCs = (lineItem) => {
    const {
      unitPriceBasis,
      invoicedQuantity,
      currentItemPriceCalculationGross,
    } = lineItem;

    const ratioAlternativeQuantity = parseFloat(
      lineItem?.ratioAlternativeQuantity
    );
    const quantityRatio = isNaN(ratioAlternativeQuantity)
      ? 1
      : ratioAlternativeQuantity;

    // users can delete the value from the input field, in this case we need
    // to calculate with 0 as amount
    if (
      typeof invoicedQuantity === 'undefined' ||
      typeof currentItemPriceCalculationGross?.value === 'undefined'
    ) {
      return 0;
    }

    return round(
      (parseFloat(currentItemPriceCalculationGross?.value) /
        parseFloat(unitPriceBasis)) *
        invoicedQuantity *
        quantityRatio
    );
  };

  /**
   * Calculates a single lineItem including allowances and charges
   * @param lineItem
   * @return {{lineItemAmount: number, allowancesAndCharges: (*|*[]), vatamount: number}}
   */
  calculateLineItem = (lineItem) => {
    // first calculate the total line item amount based
    let lineItemAmount = this.calculateLineItemAmountWithoutAoCs(lineItem);

    let allowancesAndCharges = lineItem.allowancesAndCharges || [];

    let currentItemPriceCalculationNet =
      lineItem.currentItemPriceCalculationGross?.value;

    // if we have allowancesOrCharges, recalculate the AoCs and
    // the lineItemAmount and the currentItemPriceCalculationNet
    if (allowancesAndCharges.length) {
      const aoc = new AllowanceOrCharges(allowancesAndCharges);
      // recalculate all Aoc items
      allowancesAndCharges = aoc.calcAoc(lineItemAmount).getResult();
      // recalculate the lineItemAmount *after* AoCs
      lineItemAmount = round(aoc.calcTotal());

      // price per unit after allowances or charges
      currentItemPriceCalculationNet = round(
        lineItemAmount / (lineItem.invoicedQuantity / lineItem.unitPriceBasis)
      );
    }

    if (this.document) {
      this.totals.totalLineItemAmount += lineItemAmount;
    }

    const vatamount =
      lineItem.vatrate > 0
        ? round(percentageOf(lineItem.vatrate, lineItemAmount))
        : 0;

    /**
     * ☢ ☢ ☢ DANGER ZONE ☢ ☢ ☢
     *
     * Do not put this assignment in the object of the return value.
     * Final-Form-Decorators will not be able to map the nested value
     * onto the form.
     *
     * It seems that final-form-decorators MUST operates on existing references,
     * hence creating a new nested object reference breaks the form update.
     */

    if (!lineItem.currentItemPriceCalculationNet) {
      lineItem.currentItemPriceCalculationNet = {};
    }

    lineItem.currentItemPriceCalculationNet[
      'value'
    ] = currentItemPriceCalculationNet;

    return {
      ...lineItem,
      lineItemAmount,
      allowancesAndCharges,
      vatamount,
    };
  };

  /**
   * Recalculates all lineItems in this invoice and all their allowances
   * and charges.
   * @return {InvoiceCalculator}
   */
  calculateAllLineItems = () => {
    if (!Array.isArray(this.lineItems)) {
      console.warn('document has no line items...', this.document);
      return this;
    }

    this.lineItems = this.lineItems.map((lineItem) =>
      this.calculateLineItem(lineItem)
    );
    return this;
  };
}

export default InvoiceCalculator;
