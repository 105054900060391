import PropTypes from 'prop-types';

const documentShape = PropTypes.shape({
  // TODO shape
  header: PropTypes.object,
  // TODO shape
  details: PropTypes.object,
  // TODO shape
  footer: PropTypes.object,
});

const documentMetaShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  documentTypeId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
});

const documentExchangeShape = PropTypes.shape({
  meta: documentMetaShape.isRequired,
  document: documentShape.isRequired,
  lineItemStateTrackingData: PropTypes.object,
});

export {documentShape, documentMetaShape, documentExchangeShape};
