/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Table} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {industryDocumentFooterShape} from '../../../../../shapes/industryDocument';
import {CALCULATION_TYPES} from '../../../../../shapes/scenarios';

const TableFooterWithOutVatRates = ({
  hasEditables,
  footer,
  colSpan,
  currency,
  lineItemConfiguration,
}) => {
  const hideTotalAmount =
    lineItemConfiguration?.amountCalculation ===
    CALCULATION_TYPES.NO_LINEITEMAMOUNT_AND_TOTAL_AMOUNT_CALCULATION;

  if (hideTotalAmount) {
    return null;
  }
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell colSpan={colSpan} />
        <Table.HeaderCell colSpan={1}>
          <strong>
            <FormattedMessage id="GENERAL_TOTAL_SUM" />
          </strong>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <strong>
            <FormattedNumber
              value={get(footer, 'invoiceFooter.invoiceTotals.invoiceAmount')}
              style="currency"
              currency={currency}
            />
          </strong>
        </Table.HeaderCell>
        {hasEditables && <Table.HeaderCell />}
      </Table.Row>
    </Table.Footer>
  );
};

TableFooterWithOutVatRates.propTypes = {
  hasEditables: PropTypes.bool,
  footer: industryDocumentFooterShape,
  colSpan: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

const TableFooterWithVatRates = ({
  hasEditables,
  footer,
  colSpan,
  currency,
  lineItemConfiguration,
}) => {
  const hideTotalAmount =
    lineItemConfiguration?.amountCalculation ===
    CALCULATION_TYPES.NO_LINEITEMAMOUNT_AND_TOTAL_AMOUNT_CALCULATION;
  if (hideTotalAmount) {
    return null;
  }
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell colSpan={colSpan} />
        <Table.HeaderCell colSpan={1}>
          <FormattedMessage id="GENERAL_SUBTOTAL" />
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <FormattedNumber
            value={get(
              footer,
              'invoiceFooter.invoiceTotals.totalTaxableAmount'
            )}
            style="currency"
            currency={currency}
          />
        </Table.HeaderCell>
        {hasEditables && <Table.HeaderCell />}
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan={colSpan} />
        <Table.Cell colSpan={1}>
          {get(footer, 'invoiceFooter.vatrates', []).map((vat, idx) => (
            <div key={`footerVatPercent_${idx}`}>
              <FormattedMessage id="GENERAL_PRICE_PLUS" />{' '}
              <FormattedNumber
                style="percent"
                minimumFractionDigits={2}
                value={vat.vatrate / 100}
              />{' '}
              <FormattedMessage id="GENERAL_VAT" />
            </div>
          ))}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {get(footer, 'invoiceFooter.vatrates', []).map((vat, idx) => (
            <div key={`footerVatAmount_${idx}`}>
              <FormattedNumber
                value={vat.vatamount}
                style="currency"
                currency={currency}
              />
            </div>
          ))}
        </Table.Cell>
        {hasEditables && <Table.HeaderCell />}
      </Table.Row>

      <Table.Row>
        <Table.HeaderCell colSpan={colSpan} />
        <Table.HeaderCell colSpan={1}>
          <strong>
            <FormattedMessage id="GENERAL_TOTAL_SUM" />
          </strong>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <strong>
            <FormattedNumber
              value={get(footer, 'invoiceFooter.invoiceTotals.invoiceAmount')}
              style="currency"
              currency={currency}
            />
          </strong>
        </Table.HeaderCell>
        {hasEditables && <Table.HeaderCell />}
      </Table.Row>
    </Table.Footer>
  );
};

TableFooterWithVatRates.propTypes = {
  hasEditables: PropTypes.bool,
  footer: industryDocumentFooterShape,
  colSpan: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

const TableFooter = ({footer, ...rest}) => {
  const vatRates = get(footer, 'invoiceFooter.vatrates', []);
  return vatRates.length ? (
    <TableFooterWithVatRates footer={footer} {...rest} />
  ) : (
    <TableFooterWithOutVatRates footer={footer} {...rest} />
  );
};

export default TableFooter;

TableFooter.propTypes = {
  footer: industryDocumentFooterShape,
  colSpan: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
