import {
  FormattedMessage as Msg,
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import PropTypes from 'prop-types';
import {Header, Icon} from 'semantic-ui-react';
import React from 'react';
import styled from 'styled-components';

const RemainingQuantityContainer = styled.label`
  display: flex;
  ${(props) => (props.negativeValue ? `color: red !important;` : ``)}
`;
const SidebarItemContent = ({label, value, nobreak}) => {
  return value ? (
    <label className="themed">
      <strong>
        <FormattedMessage id={label} />:
      </strong>
      {!nobreak ? <br /> : ' '}
      {value}
    </label>
  ) : null;
};

SidebarItemContent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  nobreak: PropTypes.bool,
};

const RemainingQuantitySidebarItemContent = ({
  label,
  value,
  nobreak,
  negativeValue = false,
  positiveValue = true,
}) => {
  return value ? (
    <RemainingQuantityContainer
      className="themed"
      negativeValue={negativeValue}>
      <strong>
        <FormattedMessage id={label} />:
      </strong>
      {!nobreak ? <br /> : ' '}
      {!positiveValue && (
        <Icon
          name={negativeValue ? 'warning circle' : 'check circle outline'}
        />
      )}

      <span style={{flex: '1'}}>
        {value} {negativeValue ? <Msg id="DESADV_OVER_DELIVERY" /> : ''}
      </span>
    </RemainingQuantityContainer>
  ) : null;
};

SidebarItemContent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  nobreak: PropTypes.bool,
};

const SidebarItem = injectIntl(({item, intl, config, tempItem}) => {
  let formattedUnit = item.measureUnitQualifier;
  // only try to translate it if it's a string, intl will fail otherwise
  if (typeof formattedUnit === 'string' || formattedUnit instanceof String) {
    formattedUnit = intl.formatMessage({
      id: item.measureUnitQualifier,
    });
  }

  // fallback if it's not set, render nothing
  if (typeof formattedUnit === 'undefined') {
    formattedUnit = '';
  }

  return (
    <div data-spec="sidebar-item" className="sidebar-item">
      <Header className="item-header">
        <Msg id="DESADV_MAT_NR_HDR" /> {item.customersItemMaterialNumber} /{' '}
        {item.suppliersItemMaterialNumber}
        <br />
        <span className="header-sub">{item.itemDescription}</span>
      </Header>
      <SidebarItemContent
        label="DESADV_SB_ORD_QTY"
        value={`${item.orderedQuantity} ${formattedUnit}`}
        nobreak
      />
      {item.remainingQuantityAggregateForCompletePosition != null ? (
        config?.desadvOverDelivery === false ? (
          <RemainingQuantitySidebarItemContent
            label="ENVELOPE_DESADV_REMAINING_QUANTITY"
            value={`${Math.abs(
              tempItem?.remainingQuantityForPosition
            )} ${formattedUnit}`}
            nobreak
            negativeValue={
              tempItem?.remainingQuantityForPosition < 0 ? true : false
            }
            positiveValue={
              tempItem?.remainingQuantityForPosition > 0 ? true : false
            }
          />
        ) : (
          <SidebarItemContent
            label="ENVELOPE_DESADV_REMAINING_QUANTITY"
            value={`${item.remainingQuantityAggregateForCompletePosition.value} ${formattedUnit}`}
            nobreak
          />
        )
      ) : (
        ''
      )}
    </div>
  );
});

export default SidebarItem;
