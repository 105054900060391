/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {Grid, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

const FreeTextComponent = ({path, object, fields}) => {
  const objectField = fields[path];
  if (!objectField) {
    return null;
  }
  const freeTexts = object[path] || [];

  const filteredFreeTexts = freeTexts.filter(
    (text) => text.textQualifier !== 'ECOSIO_ORIGINAL' && !isEmpty(text.text)
  );

  if (filteredFreeTexts.length === 0) {
    return null;
  }

  return (
    <Grid divided>
      <Grid.Row>
        <Grid.Column>
          <Message>
            <Message.Header>
              <FormattedMessage id="GENERAL_FREETEXT_HEADER" />
            </Message.Header>
            <Message.List>
              {filteredFreeTexts.map((text, idx) => (
                <Message.Item key={idx}>{text.text}</Message.Item>
              ))}
            </Message.List>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

FreeTextComponent.propTypes = {
  path: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

export default FreeTextComponent;
