import React from 'react';
import {Redirect} from 'react-router-dom';
import withScenario from '../hoc/withScenario';
import EnvelopesComponent from '../Envelopes';
import {scenarioShape} from '../../shapes/scenarios';
import {
  DAILY_DOCUMENT,
  MONTHLY_REPORT,
  FILE_UPLOAD,
  DASHBOARD_SCENARIO,
} from '../../helpers/constants';

const ScenariosComponent = (props) => {
  const {scenario} = props;

  switch (scenario?.listLayout) {
    case DAILY_DOCUMENT:
      return <Redirect to={`/scenario/${scenario?.uuid}/dailyDocuments`} />;
    case MONTHLY_REPORT:
      return <Redirect to={`/scenario/${scenario?.uuid}/monthlyReport`} />;
    case FILE_UPLOAD:
      return <Redirect to={`/scenario/${scenario?.uuid}/fileUpload`} />;
    case DASHBOARD_SCENARIO:
      return <Redirect to={`/scenario/${scenario?.uuid}/dashboard`} />;
    default:
      return <EnvelopesComponent {...props} />;
  }
};

ScenariosComponent.propTypes = {
  scenario: scenarioShape.isRequired,
};

export default withScenario()(ScenariosComponent);
