import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber} from 'react-intl';
import {isNumber} from 'lodash';
import {round} from '../../../../calculations/math';
import {CURRENCY_EUR} from '../../../../constants';

export const ConditionalCurrency = ({value, currency = CURRENCY_EUR}) => {
  return isNumber(value) ? (
    <FormattedNumber
      style="currency"
      currency={currency}
      minimumFractionDigits={2}
      value={round(value)}
    />
  ) : null;
};

ConditionalCurrency.propTypes = {
  value: PropTypes.any,
  currency: PropTypes.string,
};
