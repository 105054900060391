import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Segment, List, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

import {get} from 'lodash';
import {FormatDate, FormatDateTime} from '@ecosio/components';
import {SwitcherOrBackButton} from '../../../../SwitchDropdown';
import DocumentHeader from '../../../../DocumentHeader';
import DesadvLineItemTable from '../Create/DesadvLineItemTable';
import {DynamicMessage} from '../../../../../../helpers/translate';
import Archive from '../../../../ArchiveComponents';
import CreateDocumentButtonBar from '../../../../Turnaround/CreateDocumentButtonBar';
import {
  pageConfigShape,
  scenarioShape,
} from '../../../../../../shapes/scenarios';
import {documentExchangeShape} from '../../../../../../shapes/documentExchange';
import DocumentMeta from '../../../../Order/View/DocumentMeta';

import {
  STATUS_READ,
  STATUS_ARCHIVED,
} from '../../../../../../shapes/supportedEnvelopeStati';

import {TabbedItem, BusinessEntity} from '../../../../Delfor/DelforCommons';

const DesadvHeader = ({
  supplier,
  buyer,
  consignee,
  dates,
  documentNumber,
  referencedDocuments,
}) => (
  <Grid columns={4} className="_sie.dheader">
    <Grid.Column width={3}>
      <BusinessEntity headerId="GENERAL_SUPPLIER" data={supplier} />
    </Grid.Column>
    <Grid.Column width={3}>
      <BusinessEntity headerId="GENERAL_CONSIGNEE" data={consignee} />
    </Grid.Column>
    <Grid.Column width={3}>
      <BusinessEntity
        headerId="GENERAL_BUYER"
        data={buyer}
        headerStyle={{marginTop: '0'}}
      />
    </Grid.Column>
    <Grid.Column width={6}>
      <List>
        1
        <TabbedItem
          title="DOCUMENT_DATE"
          content={
            <FormatDate
              stripTimezone
              dateString={get(dates, 'documentDate.dateTime')}
            />
          }
        />
        <TabbedItem title="DOCUMENT_NUMBER" content={documentNumber} />
        <TabbedItem
          title="VERSION_NUMBER"
          content={get(
            referencedDocuments,
            'schedulingAgreement.referenceVersionNumber'
          )}
        />
        <TabbedItem
          title="PREV_VERSION_NUMBER"
          content={get(
            referencedDocuments,
            'previouslyReceivedDocument.referenceVersionNumber'
          )}
        />
        <TabbedItem
          title="DELIVERY_DATE"
          content={
            <FormatDate
              stripTimezone
              dateString={get(dates, 'deliveryDate.dateTime')}
            />
          }
        />
        <TabbedItem
          title="GENERAL_ESTIMATED_ARRIVAL_DATE"
          content={
            <FormatDate
              stripTimezone
              dateString={get(dates, 'estimatedArrivalDate.dateTime')}
            />
          }
        />
      </List>
    </Grid.Column>
  </Grid>
);

DesadvHeader.propTypes = {
  supplier: PropTypes.object,
  consignee: PropTypes.object,
  buyer: PropTypes.object,
  dates: PropTypes.object,
  documentNumber: PropTypes.string,
  referencedDocuments: PropTypes.object,
};

export default class DesadvSimpleTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownItems: this.formatDropdownItems(
        this.props.envelope.data.selectableDocuments
      ),
    };
  }

  formatDropdownItems = (data) => {
    return (
      data.map((item, idx) => ({
        key: idx,
        text: (
          <div>
            <DynamicMessage id={item.documentTypeId} />{' '}
            <DynamicMessage id="GENERAL_FROM" />{' '}
            <FormatDateTime stripTimezone={false} dateString={item.createdAt} />
          </div>
        ),
        value: item.uuid,
        content: (
          <div>
            <strong>
              <DynamicMessage id={item.documentTypeId} />
            </strong>
            <div>
              <FormatDateTime
                stripTimezone={false}
                dateString={item.createdAt}
              />
            </div>
          </div>
        ),
      })) || []
    );
  };

  onClickMetaDoc = (uuid) => {
    const {scenarioUuid, envelopeUuid, history} = this.props;
    history.push(
      `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${uuid}`
    );
  };

  onChangeDropdown = (e, value) => {
    const {scenarioUuid, envelopeUuid, history} = this.props;
    history.push(
      `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${value}`
    );
  };

  onClickArchive = (e) => {
    e.preventDefault();
    const {envelope} = this.props;
    const newStatus =
      envelope.data.status === STATUS_ARCHIVED ? STATUS_READ : STATUS_ARCHIVED;
    this.props.postEnvelopeStatus(newStatus);
  };

  render() {
    const {
      documentUuid,
      scenarioUuid,
      scenario,
      envelope,
      documentExchange,
      match,
    } = this.props;

    const referenceNumber = get(
      documentExchange.data,
      'document.header.referencedDocuments.schedulingAgreement.referenceNumber'
    );

    const messageId = get(documentExchange.data, 'meta.erpelMessageId', '');

    const buyer = get(
      documentExchange.data,
      'document.header.businessEntities.buyer'
    );
    const supplier = get(
      documentExchange.data,
      'document.header.businessEntities.supplier'
    );
    const consignee = get(
      documentExchange.data,
      'document.header.businessEntities.consignee'
    );

    const envelopeUuid = match.params.envelopeUuid;

    const pageConfig = documentExchange?.pageConfig;

    return (
      <div className="_si_desadv_view">
        <div id="desadv-simple-template">
          <DocumentHeader
            messageId={messageId}
            envelope={envelope.data}
            scenario={scenario}
            pageConfig={pageConfig}
            documentExchange={documentExchange}>
            <Archive.Button
              loading={envelope.status.posting}
              onArchive={this.onClickArchive}
              status={envelope.data.status}
            />
          </DocumentHeader>
          <Archive.Message show={envelope.data.status === 'ARCHIVED'} />

          {envelope.status.error ? (
            <Message warning>
              <Message.Header>
                <FormattedMessage id="GENERAL_ERROR" />
              </Message.Header>
              <p>{envelope.status.error}</p>
            </Message>
          ) : null}

          <Segment attached="top" clearing>
            <Grid stackable>
              <Grid.Row columns="2">
                <Grid.Column width={10}>
                  <SwitcherOrBackButton
                    onChange={this.onChangeDropdown}
                    items={this.state.dropdownItems}
                    selected={documentUuid}
                    scenarioUuid={scenarioUuid}
                    envelopeUuid={envelopeUuid}
                    documentUuid={documentUuid}
                  />
                </Grid.Column>
                <Grid.Column width={6} floated="right" textAlign="right">
                  <CreateDocumentButtonBar
                    hide={false}
                    scenario={scenario}
                    envelopeUuid={envelopeUuid}
                    envelope={envelope.data}
                    documentUuid={documentUuid}
                    docType={documentExchange.data.meta.documentTypeId}
                    pageConfig={pageConfig}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment attached>
            <DesadvHeader
              buyer={buyer}
              supplier={supplier}
              consignee={consignee}
              referenceNumber={referenceNumber}
              dates={get(documentExchange.data, 'document.header.dates')}
              referencedDocuments={get(
                documentExchange.data,
                'document.header.referencedDocuments'
              )}
              documentNumber={get(
                documentExchange.data,
                'document.header.beginningOfMessage.documentNumber'
              )}
            />
          </Segment>
          <DesadvLineItemTable
            onlyView
            pageConfig={pageConfig}
            cpsitems={
              documentExchange.data.document.details.dispatchData
                .consignmentPackagingSequences
            }
          />
          <DocumentMeta
            metaDocuments={envelope.data.metaDocuments}
            onSelect={this.onClickMetaDoc}
            pageConfig={pageConfig}
            location={this.props?.history?.location}
          />
        </div>
      </div>
    );
  }
}

DesadvSimpleTemplate.propTypes = {
  match: PropTypes.object,
  documentUuid: PropTypes.string,
  envelopeUuid: PropTypes.string,
  scenarioUuid: PropTypes.string,
  scenario: scenarioShape,
  envelope: PropTypes.object,
  history: PropTypes.object,
  postEnvelopeStatus: PropTypes.func.isRequired,
  documentExchange: PropTypes.shape({
    data: documentExchangeShape,
    pageConfig: pageConfigShape,
  }),
};
