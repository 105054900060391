import axios from 'axios';
import React, {Component, Fragment} from 'react';
import {Loader, Button, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import moment from 'moment';
import qs from 'qs';
import {find, get} from 'lodash';
import styled from 'styled-components';
import {toast, intlShape} from '@ecosio/components';
import PropTypes from 'prop-types';
import {stringifyQuery, DIRECTION_CONVERTER_OUT} from '../../../helpers/utils';
import DailyDocumentFilter from '../DailyDocumentFilter';
import DailyDocumentsTableGrid, {
  getDynamicCell,
} from '../DailyDocumentsTableGrid';
import {locationShape, historyShape} from '../../../shapes/generalShapes';
import {getSortColumnNameForBackend} from '..';
import {scenarioShape} from '../../../shapes/scenarios';
import {checkDocumentLineItemsLimit} from '../dailyDocumentLimitation';
import {
  STANDARD_TOAST_DURATION,
  EMPTY_DROPDOWN_VALUE,
  PICKED_UP,
} from '../../../helpers/constants';
import CreateViewEmptyState from './CreateViewEmptyState';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

class DailyInvoiceCreateComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceList: [],
      pageNr: 1, //it is used to calculate the offset for backend
      selectedLineItemsUuids: [],
      dataGridSortBy: {},
      settings: null,
      envelopeProperties: null,
      disableSelectAll: true,
      documentLimitReached: false,
    };
  }

  async componentDidMount() {
    const params = this.parseParams();
    this.disableSelectAllWhenFilterisNotSet(params);
    await this.fetchData(params, true, true);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const params = this.parseParams();
      this.disableSelectAllWhenFilterisNotSet(params);
      await Promise.all([this.fetchData(params, true)]);
      this.setState({
        selectedLineItemsUuids: [],
        documentLimitReached: false,
      });
    }

    const {documentLimitReached} = this.state;
    const {intl} = this.props;

    if (documentLimitReached) {
      toast({
        title: intl.formatMessage({
          id: 'DAILY_DOCUMENT_LIMITATION_NOTIFICATION_TITLE',
        }),
        description: intl.formatMessage({
          id: 'DAILY_DOCUMENT_LIMITATION_NOTIFICATION_DESCRIPTION',
        }),
        type: 'success',
        time: STANDARD_TOAST_DURATION,
      });
    }
  }

  disableSelectAllWhenFilterisNotSet = (filterParams = {}) => {
    const {dailyInvoiceCreatePageConfiguration} = this.props;

    const allfilters = dailyInvoiceCreatePageConfiguration?.filters || [];

    const mandatoryFilters = allfilters.filter(
      (d) => d.mandatoryAggregationFilter
    );

    //check if the mandatory filters exists in filterparams
    const allFiltersSet = mandatoryFilters.every((filter) =>
      get(filterParams, filter?.formField?.path)
    );

    if (allFiltersSet) {
      this.setState({
        disableSelectAll: false,
      });
    } else {
      this.setState({
        disableSelectAll: true,
      });
    }
  };

  getGridConfig = (dailyDocumentPageConfig, data = []) => {
    const formFields = dailyDocumentPageConfig?.formFields || {};
    const defaultCfg = {
      idSelector: 'uuid',
      fields: [
        {
          id: 'documentLink',
          label: 'COOP_DOCUMENTLINK',
          hidden: false,
          hideable: false,
          render: (Table, value, row, error, index) => {
            return (
              <Table.Cell collapsing textAlign="center">
                <Button
                  data-spec={`documentLink-${index}`}
                  as={Link}
                  to={`${value}`}
                  primary
                  compact
                  icon
                  style={{backgroundColor: 'transparent', color: '#0054ff'}}>
                  <Icon name="caret right" />
                </Button>
              </Table.Cell>
            );
          },
        },
      ],
    };

    const dispatchQuantityField =
      formFields['lineItemDetails.dispatchLineItem.dispatchQuantity.value'];
    if (dispatchQuantityField) {
      const orderedQuantityCell = {
        id: dispatchQuantityField.path,
        label: dispatchQuantityField.input.label,
        hidden: false,
        hideable: true,
        sort: dispatchQuantityField.tableSort,
        sortable: false,
        render: (Table, value, row, error, index) => {
          let unit = get(
            data[index],
            'lineItemDetails.dispatchLineItem.dispatchQuantity.unit'
          );

          if (unit) {
            unit = <FormattedMessage id={unit} />;
          }
          return (
            <Table.Cell collapsing textAlign="center">
              {value ? value : <FormattedMessage id="-" />} {unit ? unit : ''}
            </Table.Cell>
          );
        },
      };

      defaultCfg.fields.push(orderedQuantityCell);
    }

    const buyerField = formFields['buyer.name'];
    if (buyerField) {
      const buyerFieldCell = {
        id: buyerField.path,
        label: buyerField.input.label,
        hidden: false,
        hideable: true,
        sort: buyerField.tableSort,
        sortable: true,
        render: (Table, value, row, error, index) => {
          const gln = get(data[index], 'buyer.gln');

          return (
            <Table.Cell collapsing textAlign="center">
              {value ? value : <FormattedMessage id="-" />}
              {gln ? ` (${gln})` : ''}
            </Table.Cell>
          );
        },
      };

      defaultCfg.fields.push(buyerFieldCell);
    }

    const orderedQuantityField =
      formFields['lineItemDetails.ordersLineItem.orderedQuantity'];
    if (orderedQuantityField) {
      const orderedQuantityCell = {
        id: orderedQuantityField.path,
        label: orderedQuantityField.input.label,
        hidden: false,
        hideable: true,
        sort: orderedQuantityField.tableSort,
        sortable: true,
        render: (Table, value, row, error, index) => {
          let unit = get(
            data[index],
            'lineItemDetails.ordersLineItem.measureUnitQualifier'
          );

          if (unit) {
            unit = <FormattedMessage id={unit} />;
          }
          return (
            <Table.Cell collapsing textAlign="center">
              {value ? value : <FormattedMessage id="-" />} {unit ? unit : ''}
            </Table.Cell>
          );
        },
      };

      defaultCfg.fields.push(orderedQuantityCell);
    }

    Object.keys(formFields)
      .filter(
        (k) =>
          formFields[k].path !==
            'lineItemDetails.dispatchLineItem.dispatchQuantity.value' &&
          formFields[k].path !== 'buyer.name' &&
          formFields[k].path !==
            'lineItemDetails.ordersLineItem.orderedQuantity'
      )
      .forEach((path) => {
        const field = formFields[path];
        if (getDynamicCell(field)) {
          defaultCfg.fields.push(getDynamicCell(field));
        }
      });

    return defaultCfg;
  };

  getMoreData = async (params) => {
    const {pageNr} = this.state;
    const {dailyInvoiceCreatePageConfiguration} = this.props;

    const newParams = {
      ...params,
      page: pageNr + 1,
      size: dailyInvoiceCreatePageConfiguration?.paginationSettings?.pageSize,
    };

    await this.setState({
      pageNr: pageNr + 1,
    });

    await this.fetchData(newParams, false);
  };

  fetchData = async (params, clearData = true, extractSettings = false) => {
    const {dailyInvoiceCreatePageConfiguration, scenarioUuid} = this.props;

    const {pageNr, settings, envelopeProperties} = this.state;

    try {
      this.setState({loading: true});
      const newParams = Object.assign({}, params);
      const executionDate = newParams?.executionDate;
      const defaultSortingSettings =
        dailyInvoiceCreatePageConfiguration?.defaultSortingSettings;

      if (executionDate) {
        let startTs = executionDate ? new Date(executionDate) : undefined;
        const endTs = moment(startTs).add(1, 'days').valueOf();
        startTs = moment(startTs).valueOf();
        newParams.startTS = startTs;
        newParams.endTS = endTs;
      }
      newParams.type = undefined;
      newParams.executionDate = undefined;
      if (!newParams?.size) {
        newParams.size =
          dailyInvoiceCreatePageConfiguration?.paginationSettings?.pageSize;
      }

      if (!newParams?.page) {
        newParams.page = pageNr;
      }

      newParams.extractSettings = extractSettings;

      if (
        !newParams?.sort &&
        defaultSortingSettings?.sortOrder &&
        defaultSortingSettings?.columnName
      ) {
        const columnName = getSortColumnNameForBackend(
          dailyInvoiceCreatePageConfiguration,
          defaultSortingSettings?.columnName
        );
        const sortOrder = defaultSortingSettings?.sortOrder.toLowerCase();

        newParams.sort = `${columnName},${sortOrder}`;
      }

      // this needs to be fixed better,  backend accepts "buyer" url request param, not "buyer['gln']
      newParams.buyer = newParams.buyer?.gln;
      const config = {params: newParams};

      const response = await axios.get(
        `/api/dailyDocuments/scenario/${scenarioUuid}/lineItems/list`,
        config
      );
      const lineItemsData = response?.data?.lineItemsData || [];

      if (clearData) {
        this.setState({
          invoiceList: lineItemsData,
          responseDataCount: lineItemsData.length,
          loading: false,
          settings: extractSettings ? response?.data?.settings : settings,
          envelopeProperties: extractSettings
            ? response?.data?.envelopeProperties
            : envelopeProperties,
        });
      } else {
        this.setState((prevState) => ({
          invoiceList: [...prevState.invoiceList, ...lineItemsData],
          responseDataCount: lineItemsData.length,
          loading: false,
          settings: extractSettings ? response?.data?.settings : settings,
          envelopeProperties: extractSettings
            ? response?.data?.envelopeProperties
            : envelopeProperties,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  parseParams = (props = this.props) => {
    return qs.parse(props.location.search.substr(1));
  };

  resetPageNr = () => {
    this.setState({
      pageNr: 1,
    });
  };

  onFilter = (values) => {
    const existingParams = this.parseParams();

    const newParams = {
      ...existingParams,
      ...values,
    };

    this.resetPageNr();

    this.props.history.push({
      search: `?${stringifyQuery(newParams)}`,
    });
  };

  onSelectionChange = (uuids = []) => {
    const {invoiceList} = this.state;
    const {intl} = this.props;
    const params = this.parseParams();

    if (this.props.location.search) {
      this.setState(
        {
          selectedLineItemsUuids: uuids,
          documentLimitReached: false,
        },
        () => this.checkDocumentLimitation()
      );
    }

    if (uuids.length === 1) {
      const selectedFirstLineItem = find(
        invoiceList,
        (d) => d?.uuid === uuids[0]
      );

      const deliveryType = get(
        selectedFirstLineItem?.headerFooterProperties,
        'header##dates##pickUpDate##dateTime'
      )
        ? PICKED_UP
        : EMPTY_DROPDOWN_VALUE;

      //setFilters on selecting one lineItem
      this.onFilter({
        executionDate: selectedFirstLineItem?.executionDate,
        businessType: selectedFirstLineItem?.businessType,
        buyer: {gln: selectedFirstLineItem?.buyer?.gln},
        incoterms: get(
          selectedFirstLineItem?.headerFooterProperties,
          'header##deliveryHeader##termsofDeliveryOrTransport'
        ),
        currency: get(
          selectedFirstLineItem?.headerFooterProperties,
          'header##referenceCurrency'
        ),
        countryOfOrigin: get(
          selectedFirstLineItem?.headerFooterProperties,
          'header##countryOfOrigin'
        ),
        deliveryType: deliveryType,
      });

      if (
        !(params?.executionDate && params?.businessType && params?.buyer?.gln)
      ) {
        toast({
          title: intl.formatMessage({
            id: 'DAILY_DOCUMENT_FILTER_SET_NOTIFICATION_TITLE',
          }),
          description: intl.formatMessage({
            id: 'DAILY_DOCUMENT_FILTER_SET_NOTIFICATION_DESCRIPTION',
          }),
          type: 'success',
          time: STANDARD_TOAST_DURATION,
        });
      }
    }
  };

  onSelectDeselectAllLineItems = (uuids = []) => {
    this.setState(
      {
        selectedLineItemsUuids: uuids,
        documentLimitReached: false,
      },
      () => this.checkDocumentLimitation(true)
    );
  };

  onSort = ({col, dir}) => {
    const {dailyInvoiceCreatePageConfiguration} = this.props;
    const {pageNr} = this.state;
    const columnName = getSortColumnNameForBackend(
      dailyInvoiceCreatePageConfiguration,
      col
    );
    const sortOrder = DIRECTION_CONVERTER_OUT[dir];
    const params = this.parseParams();
    const pageSize =
      dailyInvoiceCreatePageConfiguration?.paginationSettings?.pageSize;

    const newParams = {
      ...params,
      sort: sortOrder ? `${columnName},${sortOrder}` : undefined,
      page: 1,
      size: pageNr * pageSize,
    };
    if (dir) {
      this.setState({
        dataGridSortBy: {
          col: col,
          dir: dir,
        },
      });
    } else {
      this.setState({
        dataGridSortBy: null,
      });
    }

    this.props.history.push({
      search: `?${stringifyQuery(newParams)}`,
    });
  };

  sendToTurnAround = async () => {
    const {
      invoiceList,
      selectedLineItemsUuids,
      envelopeProperties,
    } = this.state;
    const {scenarioUuid, history, scenario} = this.props;

    let lineItemsToSend = [];
    lineItemsToSend = this.selectedLineItems(
      selectedLineItemsUuids,
      invoiceList
    );
    const createTemplate = scenario?.outboundWebDocTypes[0]?.documentTypeId;

    const pathToRedirect = `/scenario/${scenarioUuid}/envelope/${envelopeProperties?.envelopeUuid}/doc/${envelopeProperties?.documentUuid}/create/${createTemplate}`;

    if (lineItemsToSend.length) {
      history.push({
        pathname: pathToRedirect,
        state: {
          dailyDocumentLineItems: lineItemsToSend,
          envelopeUuid: envelopeProperties?.envelopeUuid,
        },
      });
    }
  };

  selectedLineItems = (selectedLineItemsUuids = [], invoiceList = []) => {
    if (selectedLineItemsUuids.length && invoiceList.length) {
      return invoiceList.filter((item) => {
        return selectedLineItemsUuids.find((uuid) => {
          return uuid === item?.uuid;
        });
      });
    }
    return [];
  };

  /**
   * checks limitations based on config expression
   * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/298
   */
  checkDocumentLimitation = (selectedAll = false) => {
    const {invoiceList, selectedLineItemsUuids} = this.state;
    const {dailyInvoiceCreatePageConfiguration} = this.props;

    const expressionFromConfig =
      dailyInvoiceCreatePageConfiguration?.dailyDocumentLimitationJexlExpression;

    if (expressionFromConfig) {
      const selectedlineItems = this.selectedLineItems(
        selectedLineItemsUuids,
        invoiceList
      );

      if (!selectedAll) {
        //sort the uuids by checkbox clicking sequence
        const sortedUuids = selectedLineItemsUuids.reverse();

        //sort the selectedLineItems based on sortedUuids, this is needed for limitation check
        selectedlineItems.sort(
          (a, b) => sortedUuids.indexOf(a.uuid) - sortedUuids.indexOf(b.uuid)
        );
      }

      const expression = `${expressionFromConfig}`;
      const result = checkDocumentLineItemsLimit(selectedlineItems, expression);

      this.setState({
        documentLimitReached: result?.countReached,
        selectedLineItemsUuids: Array.isArray(result?.data)
          ? result.data.map((d) => d?.uuid)
          : selectedLineItemsUuids,
      });
    }
  };

  rowFormatter = (row) => {
    const {documentLimitReached, selectedLineItemsUuids} = this.state;
    let disableThisRow = false;

    if (documentLimitReached) {
      const exists = !!selectedLineItemsUuids.find((id) => id === row.uuid);

      if (!exists) {
        disableThisRow = true;
      }
    }

    return {
      disabled: disableThisRow,
    };
  };

  render() {
    const {
      invoiceList,
      loading,
      responseDataCount,
      selectedLineItemsUuids,
      settings,
      disableSelectAll,
    } = this.state;
    const {history, intl, dailyInvoiceCreatePageConfiguration} = this.props;
    const renderLoader = loading;
    const params = this.parseParams();
    const dailyDocumentType =
      dailyInvoiceCreatePageConfiguration?.dailyDocumentType;

    const filterFormFields = dailyInvoiceCreatePageConfiguration?.filters || [];
    const isEmptyData = invoiceList.length === 0;

    return renderLoader ? (
      <Loader active />
    ) : (
      <Fragment>
        <DailyDocumentFilter
          onSubmit={this.onFilter}
          initialValues={params}
          history={history}
          filterFormFields={filterFormFields}
          resetPageNr={this.resetPageNr}
          dropDownSettings={settings?.dropdownValues}
        />
        {isEmptyData ? (
          <CreateViewEmptyState
            history={history}
            dailyDocumentType={dailyDocumentType}
          />
        ) : (
          <Fragment>
            <DailyDocumentsTableGrid
              dataSpec="daily-invoice-create-table"
              data={invoiceList}
              loading={loading}
              onSort={this.onSort}
              getMoreData={this.getMoreData}
              responseDataCount={responseDataCount}
              onSelectionChange={this.onSelectionChange}
              onSelectAllCallback={this.onSelectDeselectAllLineItems}
              onDeselectAllCallback={this.onSelectDeselectAllLineItems}
              params={params}
              dataGridSortBy={this.state.dataGridSortBy}
              dailyDocumentPageConfig={dailyInvoiceCreatePageConfiguration}
              history={history}
              selectedLineItemsUuids={selectedLineItemsUuids}
              gridConfig={this.getGridConfig(
                dailyInvoiceCreatePageConfiguration,
                invoiceList
              )}
              disableSelectAllCheckbox={disableSelectAll}
              rowFormatter={this.rowFormatter}
            />
            <ButtonContainer>
              <Button
                data-spec={`CREATE-DAILY-${dailyDocumentType}`}
                disabled={
                  selectedLineItemsUuids.length === 0 ||
                  Object.keys(params).length === 0
                }
                onClick={() => this.sendToTurnAround()}>
                {intl.formatMessage({
                  id: `CREATE_DAILY_${dailyDocumentType}_BUTTON`,
                })}
              </Button>
            </ButtonContainer>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

DailyInvoiceCreateComponent.propTypes = {
  location: locationShape.isRequired,
  history: historyShape.isRequired,
  intl: intlShape.isRequired,
  dailyInvoiceCreatePageConfiguration: PropTypes.object,
  scenarioUuid: PropTypes.string.isRequired,
  scenario: scenarioShape.isRequired,
};

export default injectIntl(DailyInvoiceCreateComponent);
