import React, {Component, Fragment} from 'react';
import {Button, Modal, Header, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';
import {FormattedMessage} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import PropTypes from 'prop-types';
import CreateMonthlyReportDropDownSelector from './CreateMonthlyReportDropDownSelector';

const StyledModalHeaderContainer = styled(Header)`
  display: flex !important;
  flex-wrap: wrap;
  background-color: ${ecosioColors.greyShades[7]} !important;
  align-items: center;
`;

const StyledModalCancelButton = styled(Button)`
  border: 1px solid ${ecosioColors.greyShades[3]} !important;
  color: ${ecosioColors.greyShades[0]} !important;
  background-color: ${ecosioColors.greyShades[6]} !important;
`;

class CreateMonthlyReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedMonth: null, showModalMessage: false};
  }

  onSelect = (selectedMonth) => {
    this.setState({
      selectedMonth: selectedMonth,
      showModalMessage: false,
    });
  };

  render() {
    const {
      open,
      onCloseModal,
      modalConfig,
      onCreateReport,
      noContentReturned,
    } = this.props;
    return (
      <Fragment>
        <Modal
          data-spec="create-monthly-report-modal"
          dimmer
          open={open}
          onClose={onCloseModal}>
          <StyledModalHeaderContainer>
            <FormattedMessage id={dynID(modalConfig?.modalHeaderTranslation)} />
          </StyledModalHeaderContainer>
          <Modal.Content>
            <CreateMonthlyReportDropDownSelector
              modalConfig={modalConfig}
              onSelect={this.onSelect}
            />
            {noContentReturned && this.state.showModalMessage ? (
              <Message warning>
                <Message.Header>
                  <FormattedMessage id="COOP_CREATE_MONTHLY_REPORT_MODAL_NO_CONTENT_RETURNED_HEADER" />
                </Message.Header>
                <p>
                  <FormattedMessage id="COOP_CREATE_MONTHLY_REPORT_MODAL_NO_CONTENT_RETURNED_CONTENT" />
                </p>
              </Message>
            ) : null}
          </Modal.Content>
          <Modal.Actions>
            <StyledModalCancelButton
              data-spec="create_and_download_report_modal_cancel"
              onClick={onCloseModal}>
              <FormattedMessage id="GENERAL_CANCEL" />
            </StyledModalCancelButton>
            <Button
              data-spec="create_and_download_report"
              primary
              onClick={() =>
                onCreateReport(this.state.selectedMonth, () =>
                  this.setState({showModalMessage: true})
                )
              }>
              <FormattedMessage id="CREATE_REPORT" />
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

CreateMonthlyReportModal.defaultProps = {
  open: false,
  noContentReturned: false,
};

CreateMonthlyReportModal.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onCreateReport: PropTypes.func.isRequired,
  modalConfig: PropTypes.object,
  noContentReturned: PropTypes.bool,
};

export default CreateMonthlyReportModal;
