/* eslint-disable react/no-array-index-key */
import React from 'react';
import {Popup, Label, Icon} from 'semantic-ui-react';
import {get} from 'lodash';
import {FormattedMessage} from 'react-intl';
import DocIcon from '../DocIcon';
import MessagePlural from '../MessagePlural/index';
import {INDUSTRY_DOCUMENTS} from '../../shapes/supportedDocTypes';

export const getScenarioDocTypes = ({
  inboundWebDocTypes,
  outboundWebDocTypes,
}) => {
  return inboundWebDocTypes
    .concat(outboundWebDocTypes)
    .map((config) => config.documentTypeId);
};

const DocumentStatusIcons = ({
  documents,
  scenario,
  envelopeUuid,
  draftsPerEnvelope,
  ...props
}) => {
  const countedDocuments = {};

  const scenarioDocTypes = getScenarioDocTypes(scenario);
  // only show icons for docTyps available in the current scenario
  INDUSTRY_DOCUMENTS.filter((iDoc) => scenarioDocTypes.includes(iDoc)).forEach(
    (iDoc) => {
      countedDocuments[iDoc] = {
        type: iDoc,
        count: 0,
        draftCount: get(draftsPerEnvelope, `${envelopeUuid}.${iDoc}`),
      };
    }
  );

  documents.forEach((item) => {
    if (countedDocuments[item.documentTypeId]) {
      countedDocuments[item.documentTypeId].count += 1;
    }
  });

  return Object.keys(countedDocuments).map((key, idx) => {
    return (
      <Popup
        key={`docIconPopup_${idx}`}
        trigger={
          <Label
            data-spec={props['data-spec']}
            style={{
              marginRight: '3px',
              padding: '3px',
              opacity: countedDocuments[key].count < 1 ? 0.4 : 1,
            }}>
            <DocIcon type={countedDocuments[key].type} />{' '}
            {countedDocuments[key].count}
            {countedDocuments[key]?.draftCount >= 1 ? (
              <span style={{marginLeft: '4px'}}>
                <Icon name="clock outline" style={{marginRight: '0px'}} />
              </span>
            ) : null}
          </Label>
        }
        content={
          <div key={`docIconMessage_${idx}`}>
            <div>
              {countedDocuments[key].count}{' '}
              <MessagePlural
                id={`PLURAL_${countedDocuments[key].type}`}
                value={countedDocuments[key].count}
              />
            </div>
            {countedDocuments[key]?.draftCount >= 1 ? (
              <div>
                <FormattedMessage
                  id={`DRAFT_COUNT_${countedDocuments[key].type}`}
                  values={{draftCount: countedDocuments[key]?.draftCount || ''}}
                />
              </div>
            ) : null}
          </div>
        }
      />
    );
  });
};

export default DocumentStatusIcons;
