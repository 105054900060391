import React from 'react';
import styled from 'styled-components';
import {Header, Icon} from 'semantic-ui-react';
import {get} from 'lodash';
import {CollapseBox} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import {FormattedMessage as Msg} from 'react-intl';
import PropTypes from 'prop-types';
import {ContentDivider, HeaderLineContainer} from '../StyledDivs';
import {BusinessEntityContactDetails} from '../../../../../../Order/View/OrderMeta';

const BusinessEntitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -13px;
  align-items: stretch;
`;

const BusinessEntityContainer = styled.div`
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  padding: 0px 13px;
`;
const BusinessEntityHeader = styled.div`
  display: flex;
  flex-direction: column;
  div > div {
    margin: 0px 0px 4px 8px;
    font-size: 10px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: ${ecosioColors.greyShades[0]};
    font-weight: 400;
  }
`;

const StyledCollapseBox = styled(CollapseBox)`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  div {
    display: flex !important;
    flex: 1;
    justify-content: space-between;
    button {
      position: relative !important;
      background: none !important;
      height: 100% !important;
      i{
       font-size: 17px !important;
       }
      }
    }
  }
`;
const CollapseBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  div {
    font-size: 14px;
    color: ${ecosioColors.greyShades[0]};
    font-weight: 400;
  }
`;

function BusinessEntity({entity, entityTranslationKey}) {
  if (!entity || !Object.keys(entity).length) {
    return null;
  }
  const countryCode = entity?.country?.countryName
    ? entity?.country?.countryName
    : entity?.country?.countryCode;

  return (
    <BusinessEntityContainer
      data-spec={`business-entity-container-${entityTranslationKey}`}>
      <StyledCollapseBox
        collapsedButtonContent={<Icon name="chevron down" size="small" />}
        unCollapsedButtonContent={<Icon name="chevron up" size="small" />}
        initiallyCollapsed
        header={
          <BusinessEntityHeader>
            <div>
              <label className="themed">
                <Msg id={entityTranslationKey} />
              </label>
              <div>{entity?.gln}</div>
            </div>
            {entity?.partyNames[0] && <p>{entity?.partyNames[0]}</p>}
          </BusinessEntityHeader>
        }
        collapsible>
        <CollapseBoxContent>
          {entity?.street && <div>{entity?.street}</div>}
          {(entity?.postCode || entity?.city) && (
            <div>
              {entity?.postCode} {entity?.city}
            </div>
          )}

          {countryCode && (
            <div>
              <Msg id={`COUNTRY_${countryCode}`} />
            </div>
          )}

          <BusinessEntityContactDetails
            initialEntityValue={entity}
            noLineBreak
          />
        </CollapseBoxContent>
      </StyledCollapseBox>
    </BusinessEntityContainer>
  );
}

BusinessEntity.defaultProps = {};

BusinessEntity.propTypes = {
  entity: PropTypes.object,
  entityTranslationKey: PropTypes.string.isRequired,
};

function BusinessEntities({businessEntities}) {
  if (!businessEntities || !Object.keys(businessEntities).length) {
    return null;
  }
  return (
    <div>
      <HeaderLineContainer>
        <div>
          <Header as="h4">
            <Msg id="GENERAL_BUSINESS_PARTIES" />
          </Header>
        </div>
        <ContentDivider />
      </HeaderLineContainer>
      <BusinessEntitiesContainer>
        <BusinessEntity
          entity={get(businessEntities, 'buyer')}
          entityTranslationKey="GENERAL_BUYER"
        />
        <BusinessEntity
          entity={get(businessEntities, 'supplier')}
          entityTranslationKey="GENERAL_SUPPLIER"
        />
        <BusinessEntity
          entity={get(businessEntities, 'consignee')}
          entityTranslationKey="GENERAL_CONSIGNEE"
        />

        <BusinessEntity
          entity={get(businessEntities, 'ultimateConsignee')}
          entityTranslationKey="GENERAL_ULTIMATE_CONSIGNEE"
        />
        <BusinessEntity
          entity={get(businessEntities, 'invoiceRecipient')}
          entityTranslationKey="GENERAL_INVOICE_RECIPIENT"
        />
        <BusinessEntity
          entity={get(businessEntities, 'originalBuyer')}
          entityTranslationKey="COOP_ORIGINAL_BUYER"
        />
        <BusinessEntity
          entity={get(businessEntities, 'carrier')}
          entityTranslationKey="GENERAL_CARRIER"
        />
      </BusinessEntitiesContainer>
    </div>
  );
}

BusinessEntities.defaultProps = {};

BusinessEntities.propTypes = {
  businessEntities: PropTypes.object,
};

export default BusinessEntities;
