/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {FormatDate} from '@ecosio/components';
import {Table} from 'semantic-ui-react';
import get from 'lodash/get';
import {DynamicMessage} from '../../../helpers/translate.jsx';
import {TimeTargetLabel, ReleaseStatusLabel} from './DelforCommons';

const itemshape = PropTypes.shape({
  frequencyIdentifier: PropTypes.string,
  deliveryPlanStatusIdentifier: PropTypes.string,
  planningQuantity: PropTypes.string,
  quantityUnitOfMeasure: PropTypes.string,
  cumulativeQuantity: PropTypes.string,
  requestedShipmentDate: PropTypes.shape({
    dateTime: PropTypes.string,
  }),
});

const Quantity = ({item}) => {
  return (
    <Table.Row>
      <Table.Cell collapsing textAlign="center">
        {item?.frequencyIdentifier && (
          <TimeTargetLabel label={item.frequencyIdentifier} />
        )}
      </Table.Cell>
      <Table.Cell collapsing textAlign="center">
        {item?.deliveryPlanStatusIdentifier && (
          <ReleaseStatusLabel identifier={item.deliveryPlanStatusIdentifier} />
        )}
      </Table.Cell>
      <Table.Cell>
        <FormatDate
          stripTimezone
          dateString={get(item, 'requestedShipmentDate.dateTime')}
        />
      </Table.Cell>
      <Table.Cell>
        {item?.planningQuantity}{' '}
        <DynamicMessage id={item?.quantityUnitOfMeasure} fallback="PCE" />
      </Table.Cell>

      <Table.Cell>
        {item?.cumulativeQuantity}{' '}
        <DynamicMessage id={item?.quantityUnitOfMeasure} fallback="PCE" />
      </Table.Cell>
    </Table.Row>
  );
};

Quantity.propTypes = {
  item: itemshape,
};

const ForecastQuantities = ({items}) => {
  if (!items || !items.length) {
    console.error('planningQuantities are undefined or empty');
    return null;
  }

  return (
    <Table celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="TIME_TARGET" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="RELEASE_STATUS" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="LE_SCHEDULED_DELIVERY_DATE" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="LE_SCHEDULED_QUANTITY" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="LE_CUMULATIVE_QUANTITY" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item, idx) => (
          <Quantity key={idx} item={item} />
        ))}
      </Table.Body>
    </Table>
  );
};

Quantity.propTypes = {item: itemshape};

ForecastQuantities.propTypes = {
  items: PropTypes.arrayOf(itemshape),
};
export default ForecastQuantities;
