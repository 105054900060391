import axios from 'axios';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Loader, Button, Icon, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import moment from 'moment';
import qs from 'qs';
import styled from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';
import {stringifyQuery, DIRECTION_CONVERTER_OUT} from '../../../helpers/utils';
import DailyDocumentFilter from '../DailyDocumentFilter';
import DailyDocumentsTableGrid, {
  getDynamicCell,
} from '../DailyDocumentsTableGrid';
import {locationShape, historyShape} from '../../../shapes/generalShapes';
import {getSortColumnNameForBackend} from '..';
import OverviewEmptyState from './OverviewEmptyState';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledCounter = styled.div`
  border-radius: 100px;
  padding: 6px 12px;
  margin-left: 13px;
  color: #ffffff;
  left: 0px;
  top: 0px;
  ${(props) =>
    props.backgroundColor ? `background: ${props.backgroundColor};` : ''}
`;

const StyledNumbers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 55px;
  padding: 6px 12px;
  left: 0px;
  top: 0px;
  ${(props) =>
    props.backgroundColor ? `background: ${props.backgroundColor};` : ''}
`;

const OverViewNumbersComponent = ({value}) => {
  const isArray = Array.isArray(value);
  const result = isArray ? value[0] : value;

  return (
    <Table.Cell collapsing textAlign="center">
      {result ? (
        <StyledNumbers backgroundColor="#edf4ff">
          {result}
          {isArray && value.length > 1 ? (
            <StyledCounter backgroundColor={ecosioColors.primaryBlue}>
              +{value.length - 1}
            </StyledCounter>
          ) : (
            ''
          )}
        </StyledNumbers>
      ) : (
        <FormattedMessage id="-" />
      )}
    </Table.Cell>
  );
};

OverViewNumbersComponent.propTypes = {
  value: PropTypes.any,
};

class DailyInvoiceOverviewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceList: [],
      pageNr: 1, //it is used to calculate the offset for backend
      dataGridSortBy: {},
      settings: null,
    };
  }

  async componentDidMount() {
    const params = this.parseParams();
    await this.fetchData(params, true, true);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const params = this.parseParams();
      await Promise.all([this.fetchData(params, true)]);
    }
  }

  getGridConfig = (dailyDocumentPageConfig) => {
    const formFields = dailyDocumentPageConfig?.formFields || {};
    const defaultCfg = {
      idSelector: 'envelopeUuid',
      fields: [
        {
          id: 'documentLink',
          label: 'COOP_DOCUMENTLINK',
          hidden: false,
          hideable: false,
          render: (Table, value, row, error, index) => {
            return (
              <Table.Cell collapsing textAlign="center">
                <Button
                  data-spec={`documentLink-${index}`}
                  as={Link}
                  to={`${value}`}
                  primary
                  compact
                  icon
                  style={{backgroundColor: 'transparent', color: '#0054ff'}}>
                  <Icon name="caret right" />
                </Button>
              </Table.Cell>
            );
          },
        },
      ],
    };
    const orderNumbersField = formFields['documentData.ordersDocumentNumbers'];

    if (orderNumbersField) {
      const orderNumbersCell = {
        id: orderNumbersField.path,
        label: orderNumbersField.input.label,
        hidden: false,
        hideable: true,
        sort: orderNumbersField.tableSort,
        sortable: false,
        render: (Table, value) => <OverViewNumbersComponent value={value} />,
      };

      defaultCfg.fields.push(orderNumbersCell);
    }

    const dispatchNumbersField =
      formFields['documentData.dispatchAdviceDocumentNumbers'];

    if (dispatchNumbersField) {
      const dispatchNumbersCell = {
        id: dispatchNumbersField.path,
        label: dispatchNumbersField.input.label,
        hidden: false,
        hideable: true,
        sort: dispatchNumbersField.tableSort,
        sortable: false,
        render: (Table, value) => <OverViewNumbersComponent value={value} />,
      };

      defaultCfg.fields.push(dispatchNumbersCell);
    }

    const retAnnNumbersField = formFields['documentData.retAnnDocumentNumbers'];

    if (retAnnNumbersField) {
      const retAnnNumbersCell = {
        id: retAnnNumbersField.path,
        label: retAnnNumbersField.input.label,
        hidden: false,
        hideable: true,
        sort: retAnnNumbersField.tableSort,
        sortable: false,
        render: (Table, value) => <OverViewNumbersComponent value={value} />,
      };

      defaultCfg.fields.push(retAnnNumbersCell);
    }

    Object.keys(formFields)
      .filter(
        (k) =>
          formFields[k].path !== orderNumbersField?.path &&
          formFields[k].path !== dispatchNumbersField?.path &&
          formFields[k].path !== retAnnNumbersField?.path
      )
      .forEach((path) => {
        const field = formFields[path];
        if (getDynamicCell(field)) {
          defaultCfg.fields.push(getDynamicCell(field));
        }
      });

    return defaultCfg;
  };

  getMoreData = async (params) => {
    const {pageNr} = this.state;
    const {dailyInvoiceOverViewPageConfiguration} = this.props;

    const newParams = {
      ...params,
      page: pageNr + 1,
      size: dailyInvoiceOverViewPageConfiguration?.paginationSettings?.pageSize,
    };

    await this.setState({
      pageNr: pageNr + 1,
    });

    await this.fetchData(newParams, false);
  };

  fetchData = async (params, clearData = true, extractSettings = false) => {
    const {dailyInvoiceOverViewPageConfiguration, scenarioUuid} = this.props;
    const {pageNr, settings} = this.state;

    try {
      this.setState({loading: true});
      const newParams = Object.assign({}, params);
      // Invoice date for daily invoice, CreditNote date for daily creditnote
      // TODO we need to think of the way to make this things generic, independent on the daily document type
      // https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/361
      const filterDocumentDate =
        newParams?.invoiceDate || newParams?.creditNoteDate;
      const defaultSortingSettings =
        dailyInvoiceOverViewPageConfiguration?.defaultSortingSettings;

      if (filterDocumentDate) {
        let startTs = filterDocumentDate
          ? new Date(filterDocumentDate)
          : undefined;
        const endTs = moment(startTs).add(1, 'days').valueOf();
        startTs = moment(startTs).valueOf();
        newParams.startTS = startTs;
        newParams.endTS = endTs;
      }
      newParams.type = undefined;
      newParams.invoiceDate = undefined;
      if (!newParams?.size) {
        newParams.size =
          dailyInvoiceOverViewPageConfiguration?.paginationSettings?.pageSize;
      }
      if (!newParams?.page) {
        newParams.page = pageNr;
      }

      newParams.extractSettings = extractSettings;
      if (
        !newParams?.sort &&
        defaultSortingSettings?.sortOrder &&
        defaultSortingSettings?.columnName
      ) {
        const columnName = getSortColumnNameForBackend(
          dailyInvoiceOverViewPageConfiguration,
          defaultSortingSettings?.columnName
        );
        const sortOrder = defaultSortingSettings?.sortOrder.toLowerCase();

        newParams.sort = `${columnName},${sortOrder}`;
      }

      // this needs to be fixed better, backend accepts "buyer" url request param, not "buyer['gln']
      newParams.buyer = newParams.buyer?.gln;
      const config = {params: newParams};
      const response = await axios.get(
        `/api/dailyDocuments/scenario/${scenarioUuid}/overview`,
        config
      );

      const lineItemsData = response?.data?.dailyDocuments || [];

      if (clearData) {
        this.setState({
          invoiceList: lineItemsData,
          responseDataCount: lineItemsData.length,
          loading: false,
          settings: extractSettings ? response?.data?.settings : settings,
        });
      } else {
        this.setState((prevState) => ({
          invoiceList: [...prevState.invoiceList, ...lineItemsData],
          responseDataCount: lineItemsData.length,
          loading: false,
          settings: extractSettings ? response?.data?.settings : settings,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  parseParams = (props = this.props) => {
    return qs.parse(props.location?.search.substr(1));
  };

  resetPageNr = () => {
    this.setState({
      pageNr: 1,
    });
  };

  onFilter = (values) => {
    const existingParams = this.parseParams();

    const newParams = {
      ...existingParams,
      ...values,
    };

    this.resetPageNr();

    this.props.history.push({
      search: `?${stringifyQuery(newParams)}`,
    });
  };

  onSort = ({col, dir}) => {
    const {dailyInvoiceOverViewPageConfiguration} = this.props;
    const {pageNr} = this.state;
    const columnName = getSortColumnNameForBackend(
      dailyInvoiceOverViewPageConfiguration,
      col
    );
    const sortOrder = DIRECTION_CONVERTER_OUT[dir];
    const params = this.parseParams();
    const pageSize =
      dailyInvoiceOverViewPageConfiguration?.paginationSettings?.pageSize;

    const newParams = {
      ...params,
      sort: sortOrder ? `${columnName},${sortOrder}` : undefined,
      page: 1,
      size: pageNr * pageSize,
    };
    if (dir) {
      this.setState({
        dataGridSortBy: {
          col: col,
          dir: dir,
        },
      });
    } else {
      this.setState({
        dataGridSortBy: null,
      });
    }

    this.props.history.push({
      search: `?${stringifyQuery(newParams)}`,
    });
  };

  render() {
    const {invoiceList, loading, responseDataCount, settings} = this.state;
    const {history, dailyInvoiceOverViewPageConfiguration} = this.props;
    const renderLoader = loading;
    const params = this.parseParams();

    const filterFormFields =
      dailyInvoiceOverViewPageConfiguration?.filters || [];
    const isEmptyData = invoiceList.length === 0;

    const dailyDocumentType =
      dailyInvoiceOverViewPageConfiguration?.dailyDocumentType;

    return renderLoader ? (
      <Loader active />
    ) : (
      <Fragment>
        <DailyDocumentFilter
          onSubmit={this.onFilter}
          initialValues={params}
          history={history}
          dataList={invoiceList}
          filterFormFields={filterFormFields}
          resetPageNr={this.resetPageNr}
          dropDownSettings={settings?.dropdownValues}
        />
        {isEmptyData ? (
          <OverviewEmptyState
            history={history}
            dailyDocumentType={dailyDocumentType}
          />
        ) : (
          <DailyDocumentsTableGrid
            dataSpec="daily-invoice-overview-table"
            data={invoiceList}
            loading={loading}
            onSort={this.onSort}
            getMoreData={this.getMoreData}
            responseDataCount={responseDataCount}
            selectable={false}
            params={params}
            dataGridSortBy={this.state.dataGridSortBy}
            dailyDocumentPageConfig={dailyInvoiceOverViewPageConfiguration}
            history={history}
            gridConfig={this.getGridConfig(
              dailyInvoiceOverViewPageConfiguration
            )}
          />
        )}
      </Fragment>
    );
  }
}

DailyInvoiceOverviewComponent.propTypes = {
  location: locationShape.isRequired,
  history: historyShape.isRequired,
  dailyInvoiceOverViewPageConfiguration: PropTypes.object,
  scenarioUuid: PropTypes.string.isRequired,
};

export default injectIntl(DailyInvoiceOverviewComponent);
