/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Table} from 'semantic-ui-react';
import {FormatDate} from '@ecosio/components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {lineItemConfigurationShape} from '../../../../../../shapes/scenarios';
import {wasStateChanged} from '../TableBody';
import CircleForValueChanged from '../CircleForValueChanged';

const DeliveryCell = ({
  intl,
  dataSpec,
  lineItemConfig,
  lineItem = {},
  ordersDocument,
  themeSpecificColors,
  lineItemIndex,
}) => {
  const customFieldChangedStyle = {
    color: themeSpecificColors?.primaryColor,
    fontWeight: 700,
  };

  const orderedQuantityChanged = wasStateChanged(
    ordersDocument,
    lineItem,
    `orderedQuantity`,
    lineItemIndex
  );

  const measureUnitQualifierChanged = wasStateChanged(
    ordersDocument,
    lineItem,
    `measureUnitQualifier`,
    lineItemIndex
  );

  return (
    <Table.Cell verticalAlign="top" textAlign="right" data-spec={dataSpec}>
      {lineItemConfig.lineItemsHaveScheduleLines && (
        <React.Fragment>
          {lineItem.ordersScheduleLines.map((item, idx) => {
            const dateTimeChanged = wasStateChanged(
              ordersDocument,
              lineItem,
              `ordersScheduleLines[${idx}].scheduledQuantityDate.dateTime`,
              lineItemIndex
            );

            const scheduledQuantityChanged = wasStateChanged(
              ordersDocument,
              lineItem,
              `ordersScheduleLines[${idx}].scheduledQuantity`,
              lineItemIndex
            );

            return (
              <div
                style={{whiteSpace: 'nowrap', clear: 'both', display: 'flex'}}
                key={`scheduleLine_${idx}`}>
                <span
                  style={{
                    float: 'left',
                    ...(dateTimeChanged ? customFieldChangedStyle : {}),
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <CircleForValueChanged shouldRender={dateTimeChanged} />
                  <FormatDate
                    year="2-digit"
                    month="numeric"
                    day="numeric"
                    stripTimezone
                    dateString={get(item, 'scheduledQuantityDate.dateTime')}
                  />
                </span>
                <span
                  style={{
                    ...(scheduledQuantityChanged
                      ? customFieldChangedStyle
                      : {}),
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}>
                  <CircleForValueChanged
                    shouldRender={
                      scheduledQuantityChanged || measureUnitQualifierChanged
                    }
                  />
                  {item.scheduledQuantity} {/* only translate if it's set */}
                </span>
                <span
                  style={{
                    ...(measureUnitQualifierChanged
                      ? customFieldChangedStyle
                      : {}),
                  }}>
                  {lineItem.measureUnitQualifier && (
                    <FormattedMessage
                      id={dynID(lineItem.measureUnitQualifier)}
                    />
                  )}
                </span>
              </div>
            );
          })}
        </React.Fragment>
      )}

      <strong>
        {lineItemConfig.lineItemsHaveScheduleLines && (
          <span style={{float: 'left'}}>Total: </span>
        )}
        <span
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}>
          <span
            style={{
              ...(orderedQuantityChanged ? customFieldChangedStyle : {}),
              display: 'flex',
              alignItems: 'center',
            }}>
            <CircleForValueChanged
              shouldRender={
                orderedQuantityChanged || measureUnitQualifierChanged
              }
            />
            {lineItem.orderedQuantity} {/* only translate if it's set */}
          </span>
          &nbsp;
          <span
            style={{
              ...(measureUnitQualifierChanged ? customFieldChangedStyle : {}),
            }}>
            {lineItem.measureUnitQualifier && (
              <FormattedMessage id={dynID(lineItem.measureUnitQualifier)} />
            )}
          </span>
        </span>
        <span>
          {lineItem?.packagingDetails?.numberOfPackages &&
            ` / ${
              lineItem?.packagingDetails?.numberOfPackages
            } ${intl.formatMessage({
              id: 'LIN_PACKAGING_DETAILS_NUMBER_OF_PACKAGES',
            })}`}
        </span>
      </strong>

      {lineItem.confirmedPositionStateData && (
        <React.Fragment>
          <p></p>
          <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
            <span style={{float: 'left'}}>
              {intl.formatMessage({id: 'ENVELOPE_CONFIRMED_STATE'})}:
            </span>
            <span>{lineItem.confirmedPositionStateData.confirmedState}</span>
            {lineItem.confirmedPositionStateData.confirmedStateHistoryPerUnit.map(
              (item) => (
                // eslint-disable-next-line react/jsx-key
                <div>
                  <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
                    <span style={{float: 'left'}}>
                      {intl.formatMessage({
                        id: 'ENVELOPE_CONFIRMED_QUANTITY',
                      })}
                      :
                    </span>
                    <span>
                      {item.confirmedQuantity.value}{' '}
                      {/* only translate if it's set */}
                      {lineItem.measureUnitQualifier && (
                        <FormattedMessage
                          id={dynID(item.confirmedQuantity.unit)}
                        />
                      )}{' '}
                    </span>
                  </div>
                  <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
                    <span style={{float: 'left'}}>
                      {intl.formatMessage({
                        id: 'ENVELOPE_CONFIRMATION_DATE',
                      })}
                      :
                    </span>
                    <span>{item.confirmationDate}</span>
                  </div>
                </div>
              )
            )}
          </div>
        </React.Fragment>
      )}

      {lineItem.deliveredStateData && (
        <React.Fragment>
          <p></p>
          <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
            <span style={{float: 'left'}}>
              {intl.formatMessage({id: 'ENVELOPE_DESADV_DELIVERED_QUANTITY'})}:
            </span>
            <span>
              {lineItem.deliveredStateData.deliveredQuantity.value}{' '}
              {/* only translate if it's set */}
              {lineItem.measureUnitQualifier && (
                <FormattedMessage
                  id={dynID(lineItem.deliveredStateData.deliveredQuantity.unit)}
                />
              )}{' '}
            </span>
          </div>
          <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
            <span style={{float: 'left'}}>
              {intl.formatMessage({id: 'ENVELOPE_DESADV_REMAINING_QUANTITY'})}:
            </span>
            <span>
              {lineItem.deliveredStateData.remainingQuantity.value}{' '}
              {/* only translate if it's set */}
              {lineItem.measureUnitQualifier && (
                <FormattedMessage
                  id={dynID(lineItem.deliveredStateData.remainingQuantity.unit)}
                />
              )}{' '}
            </span>
          </div>
        </React.Fragment>
      )}

      {lineItem.invoicedState && (
        <React.Fragment>
          <p></p>
          <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
            <span style={{float: 'left'}}>
              {intl.formatMessage({id: 'ENVELOPE_INVOICED_STATE'})}:
            </span>
            <span>{lineItem.invoicedState}</span>
            {lineItem.invoicedStateHistoryPerUnit.map((item) => (
              // eslint-disable-next-line react/jsx-key
              <div>
                <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
                  <span style={{float: 'left'}}>
                    {intl.formatMessage({
                      id: 'ENVELOPE_INVOICED_QUANTITY',
                    })}
                    :
                  </span>
                  <span>
                    {item.invoicedQuantity.value}{' '}
                    {/* only translate if it's set */}
                    {lineItem.measureUnitQualifier && (
                      <FormattedMessage
                        id={dynID(item.invoicedQuantity.unit)}
                      />
                    )}{' '}
                  </span>
                </div>
                <div style={{whiteSpace: 'nowrap', clear: 'both'}}>
                  <span style={{float: 'left'}}>
                    {intl.formatMessage({
                      id: 'ENVELOPE_DESADV_REMAINING_QUANTITY',
                    })}
                    :
                  </span>
                  <span>
                    {item.remainingQuantity.value}{' '}
                    {/* only translate if it's set */}
                    {lineItem.measureUnitQualifier && (
                      <FormattedMessage
                        id={dynID(item.remainingQuantity.unit)}
                      />
                    )}{' '}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </Table.Cell>
  );
};

export default injectIntl(DeliveryCell);

DeliveryCell.propTypes = {
  lineItemConfig: lineItemConfigurationShape.isRequired,
  dataSpec: PropTypes.string,
  lineItem: PropTypes.shape({
    orderedQuantity: PropTypes.number,
    measureUnitQualifier: PropTypes.string,
    ordersScheduleLines: PropTypes.arrayOf(
      PropTypes.shape({
        scheduledQuantity: PropTypes.number,
        scheduledQuantityDate: PropTypes.shape({dateTime: PropTypes.string}),
      })
    ),
  }),
};
