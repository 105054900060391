import styled from 'styled-components';
import {Divider} from 'semantic-ui-react';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    i {
      font-size: 18px !important;
    }
  }
`;

export const ContentDivider = styled(Divider)`
  flex: 1;
`;

export const HeaderLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h4,
  h3 {
    margin-right: 20px !important;
    margin-botton: 10px !important;
  }
`;

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 47px;
  margin-right: 49px;
  margin-top: 32px;
  margin-buttom: 36px;
`;

export const TemplateSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;
