import React from 'react';
import {get} from 'lodash';
import {filterFields} from '@ecosio/pathform';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Grid, Header, Icon, Table} from 'semantic-ui-react';
import {pageConfigShape} from '../../../../shapes/scenarios';
import {documentShape} from '../../../../shapes/documentExchange';
import {showLinCalculation} from '../../../../util/calculationUtil';
import TableHeader from './Table/TableHeader';
import TableBody from './Table/TableBody';
import TableFooter from './Table/TableFooter';

const EmptyStateComponent = () => (
  <React.Fragment>
    <Grid centered container columns={1}>
      <Grid.Row>
        <Grid.Column textAlign="center" style={{marginBottom: 50}}>
          <Header as="h2" icon textAlign="center">
            <Icon name="x" color="grey" circular />
            <Header.Content>
              <FormattedMessage id="GENERAL_ORDER_CANCELLED" />
            </Header.Content>
            <Header.Subheader>
              <FormattedMessage id="GENERAL_ORDER_CANCELLED_SUBHEADER" />
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
);

const LineItemTable = ({pageConfig, data, onSelect, currency}) => {
  const {lineItemConfiguration, formFields} = pageConfig;

  const showCalculation = showLinCalculation(lineItemConfiguration);

  const hasSelect = typeof onSelect === 'function';

  const tableFields = filterFields(formFields, (item) => item.tableColumn);

  // the number of organic table columns (with simple literals in them, no
  // calculation logic
  const tableFieldsNum = Object.keys(tableFields).length;

  // the colSpan is needed for the footer, as it spans over the whole
  // table and attaches 3 cells at the end of the table
  // why do we subtract 2? TP ?
  const colSpan = tableFieldsNum + (showCalculation ? 3 : 0) - 2;

  const lineItems = get(data, 'details.invoiceData.invoiceLineItems', []);

  if (lineItems.length === 0) {
    return <EmptyStateComponent />;
  }
  return (
    <Grid className="_si_li_table">
      <Grid.Row>
        <Grid.Column>
          <Header as="h5">
            <FormattedMessage id="LE_TABLE_INFO" />.
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {/* Added 2 data attributes to help debugging colSpan issues */}
        <Table
          compact
          celled
          data-fieldsnum={tableFieldsNum}
          data-colspan={colSpan}>
          <TableHeader
            pageConfig={pageConfig}
            showCalculation={showCalculation}
            hasSelect={hasSelect}
          />

          <TableBody
            pageConfig={pageConfig}
            lineItemConfig={lineItemConfiguration}
            lineItems={lineItems}
            showCalculation={showCalculation}
            onSelect={onSelect}
            currency={currency}
          />

          {showCalculation && (
            <TableFooter
              header={data.header}
              footer={data.footer}
              colSpan={colSpan}
              currency={currency}
            />
          )}
        </Table>
      </Grid.Row>
    </Grid>
  );
};

LineItemTable.propTypes = {
  pageConfig: pageConfigShape,
  data: documentShape,
  onSelect: PropTypes.func,
  currency: PropTypes.string,
};

export default LineItemTable;
