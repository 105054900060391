import React, {Component} from 'react';
import {get, cloneDeep, set} from 'lodash';
import {filterFields, Content, RenderContents} from '@ecosio/pathform';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Grid, Header, Icon, Table} from 'semantic-ui-react';
import {DataGrid, intlShape} from '@ecosio/components';
import {pageConfigShape} from '../../../../shapes/scenarios';
import {actionsShape} from '../../../../shapes/dataGridShapes';
import {documentShape} from '../../../../shapes/documentExchange';
import {filterPathFormArray} from '../../../../util/documentUtil';
import ValidationWarnIcon from '../../ValidationWarnIcon';
import {inMemoryValidatorShape} from '../../../../shapes/inMemoryValidatorShape';
import {
  showLinCalculation,
  hideLinTotalPrice,
} from '../../../../util/calculationUtil';
import {UnitPriceCell, DeliveryCell, TotalPriceCell} from './Table/Cells/index';
import EditCell from './Table/EditCell';
import TableFooter from './Table/TableFooter';

const styleAoc = {color: '#99a4af'};

const FilterCell = ({dataSpec, value, config, valuePath, width}) => {
  if (!config) {
    // not configured, don't render the whole cell
    return null;
  }
  if (!Array.isArray(value)) {
    console.warn('FilterCell expected an array , but got ', typeof value);
    return (
      <Table.Cell width={width} verticalAlign="top" data-spec={dataSpec} />
    );
  }

  const values = filterPathFormArray(value, config, valuePath).join(',');

  return (
    <Table.Cell width={width} verticalAlign="top" data-spec={dataSpec}>
      {values}
    </Table.Cell>
  );
};

FilterCell.propTypes = {
  dataSpec: PropTypes.string,
  value: PropTypes.Array,
  valuePath: PropTypes.string,
  width: PropTypes.string,
  config: PropTypes.Object,
};

const BusinessEntityCell = ({dataSpec, value, fields, prefix}) => {
  // Use .startsWith so that paths in the header don't match
  const found = filterFields(fields, (field) => field.path.startsWith(prefix));

  if (!found) {
    return null;
  }

  // check if the node is there and is a table column
  if (found[prefix] && found[prefix].tableColumn === false) {
    return null;
  }

  return (
    <Table.Cell verticalAlign="top" data-spec={dataSpec}>
      <RenderContents initialValues={{consignee: value}} fields={fields}>
        {() => (
          <Content
            path="consignee.partyNames"
            render={({content}) => (
              <div>
                <PartyNames partyNames={content} />{' '}
              </div>
            )}
          />
        )}
      </RenderContents>
    </Table.Cell>
  );
};

BusinessEntityCell.propTypes = {
  dataSpec: PropTypes.string,
  value: PropTypes.object,
  fields: PropTypes.object,
  prefix: PropTypes.string,
};

export const PartyNames = ({dataSpec, partyNames}) => {
  if (!Array.isArray(partyNames)) {
    return <span>{partyNames}</span>;
  }

  return (
    <span data-spec={dataSpec}>
      {partyNames.map((name) => (
        <React.Fragment key={name}>
          <span>{name}</span>
          <br />
        </React.Fragment>
      ))}
    </span>
  );
};

PartyNames.propTypes = {
  dataSpec: PropTypes.string,
  partyNames: PropTypes.any,
};

class LineItemTable extends Component {
  constructor(props) {
    super(props);
    this.emptystate = {
      icon: 'times',
      header: this.props.intl.formatMessage({id: 'GENERAL_ORDER_CANCELLED'}),
      subHeader: this.props.intl.formatMessage({
        id: 'GENERAL_ORDER_CANCELLED_SUBHEADER',
      }),
    };
    this.state = {};
  }

  inMemoryValidate = () => {
    const errors = {};
    const {inMemoryValidation} = this.props;

    if (inMemoryValidation?.hasErrors) {
      inMemoryValidation?.errorData.map((inMemoryErrorData) => {
        const inMemoryErrorDataKey = Object.keys(inMemoryErrorData)[0];
        const index = inMemoryErrorDataKey.indexOf('[');
        if (index !== -1) {
          const errorKey = inMemoryErrorDataKey.slice(index);
          set(
            errors,
            `data${errorKey}`,
            <ValidationWarnIcon
              tooltipData={inMemoryErrorData[inMemoryErrorDataKey]}
            />
          );
        }
      });
    }

    if (Object.keys(errors).length === 0) {
      return undefined;
    }

    return errors;
  };

  render() {
    const {
      pageConfig,
      data,
      onEdit,
      onSelect,
      onDelete,
      onDuplicate,
      currency,
      onAdd,
      inMemoryValidation,
      actions,
    } = this.props;

    const {lineItemConfiguration, formFields} = pageConfig;
    const {allowAddLineItem} = lineItemConfiguration;
    const showCalculation = showLinCalculation(lineItemConfiguration);

    const hideTotalPrice = hideLinTotalPrice(lineItemConfiguration);

    const lineItems = get(data, 'details.ordersData.ordersLineItems', []);

    const hasSelect = typeof onSelect === 'function';

    const tableFields = filterFields(formFields, (item) => item.tableColumn);

    // the number of organic table columns (with simple literals in them, no
    // calculation logic
    const tableFieldsNum = Object.keys(tableFields).length;

    // the colSpan is needed for the footer, as it spans over the whole
    // table and attaches 3 cells at the end of the table
    // why do we subtract 2? TP ?
    let colSpan = tableFieldsNum + (showCalculation ? 3 : 0) - 2;

    !hideTotalPrice ? (colSpan = colSpan + 1) : null;

    const canDuplicate = lineItemConfiguration.allowDuplicateLineItem;
    const canDelete =
      lineItemConfiguration.allowDeleteLineItem &&
      onDelete &&
      lineItems.length > 1;

    const hasEditables = onEdit || canDelete;

    const getField = (path) => {
      return pageConfig.formFields[path];
    };

    const datagridConfig = [];

    const references = getField('references');

    if (references && references.tableColumn) {
      datagridConfig.push({
        id: '_references',
        label: references?.input?.label || 'GENERAL_REFERENCES',
        sortable: false,
        sort: references['tableSort'] || 126,
        hidden: false,
        hideable: true,
        forceUpdate: true,
        render: (Table, value, row, error, index, cellIndex) => {
          const clonedRow = cloneDeep(row);
          return (
            <FilterCell
              dataSpec={`references-${index}-${cellIndex}`}
              config={references}
              value={get(clonedRow, 'references')}
              valuePath="contractReference.referenceNumber"
            />
          );
        },
      });
    }
    const businessEntity = getField('consignee');
    if (businessEntity && businessEntity.tableColumn) {
      datagridConfig.push({
        id: '_businessEntity',
        label: 'consignee',
        sortable: false,
        sort: businessEntity['tableSort'] || 127,
        hidden: false,
        hideable: true,
        forceUpdate: true,
        render: (Table, value, row, error, index, cellIndex) => {
          const clonedRow = cloneDeep(row);
          return (
            <BusinessEntityCell
              dataSpec={`businessEntity-${index}-${cellIndex}`}
              fields={pageConfig.formFields}
              value={clonedRow.consignee}
              prefix="consignee"
            />
          );
        },
      });
    }

    if (showCalculation) {
      datagridConfig.push({
        id: '_General_unit_price',
        label: 'GENERAL_UNIT_PRICE',
        sortable: false,
        // TODO: how to sort?
        sort: 129,
        hidden: false,
        hideable: false,
        forceUpdate: true,
        render: (Table, value, row, error, index, cellIndex) => {
          const clonedRow = cloneDeep(row);
          return (
            <UnitPriceCell
              dataSpec={`unitPrice-${index}-${cellIndex}`}
              lineItem={clonedRow}
              styleAoc={styleAoc}
              currency={currency}
            />
          );
        },
      });

      if (!hideTotalPrice) {
        datagridConfig.push({
          id: '_General_total_price',
          label: 'GENERAL_TOTAL_PRICE',
          sortable: false,
          // TODO: how to sort?
          sort: 130,
          hidden: false,
          hideable: false,
          forceUpdate: true,
          render: (Table, value, row, error, index, cellIndex) => {
            const clonedRow = cloneDeep(row);
            return (
              <TotalPriceCell
                dataSpec={`totalPrice-${index}-${cellIndex}`}
                lineItem={clonedRow}
                styleAoc={styleAoc}
                currency={currency}
              />
            );
          },
        });
      }
    }

    if (onSelect) {
      datagridConfig.push({
        id: '_onSelectButton',
        label: 'ON_SELECT_BUTTON',
        sortable: false,
        // one left to the action
        sort: Number.MAX_SAFE_INTEGER - 1,
        hidden: false,
        hideable: false,
        forceUpdate: true,
        render: (Table, value, row, error, index, cellIndex) => {
          return (
            <Table.Cell
              verticalAlign="top"
              textAlign="center"
              data-spec={`onSelectButton-${index}-${cellIndex}`}>
              <Button
                size="mini"
                color="purple"
                index={index}
                onClick={onSelect}
                compact
                icon>
                <Icon name="eye" />
              </Button>
            </Table.Cell>
          );
        },
      });
    }

    if (hasEditables) {
      datagridConfig.push({
        id: '_actions',
        label: 'GENERAL_ACTIONS',
        sortable: false,
        // actions should always be the last column
        sort: Number.MAX_SAFE_INTEGER,
        hidden: false,
        hideable: false,
        forceUpdate: false,
        render: (Table, value, row, error, index, cellIndex) => {
          const clonedRow = cloneDeep(row);

          return (
            <EditCell
              dataSpec={`actions-${index}-${cellIndex}`}
              idx={index}
              formFields={pageConfig.formFields}
              onDelete={onDelete}
              onEdit={onEdit}
              onDuplicate={onDuplicate}
              row={clonedRow}
              canDelete={canDelete}
              canDuplicate={canDuplicate}
              inMemoryValidation={inMemoryValidation}
            />
          );
        },
      });
    }

    const staticConfig = [
      ...datagridConfig,
      {
        id: '_deliveries',
        label:
          getField('orderedQuantity')?.input?.label || 'GENERAL_DELIVERIES',
        sortable: false,
        // TODO: how do we sort this? based on what field?
        sort: 128,
        hidden: false,
        hideable: false,
        forceUpdate: true,
        render: (Table, value, row, error, index, cellIndex) => {
          const clonedRow = cloneDeep(row);
          return (
            <DeliveryCell
              dataSpec={`delivery-${index}-${cellIndex}`}
              lineItem={clonedRow}
              lineItemConfig={lineItemConfiguration}
            />
          );
        },
      },
    ];

    const dynConfig = Object.keys(formFields)
      .filter(
        (k) =>
          formFields[k].tableColumn &&
          formFields[k].path !== 'references' &&
          formFields[k].type !== 'array' &&
          formFields[k].type !== 'object'
      )
      .map((k) => ({
        id: formFields[k].path,
        label: formFields[k].input.label,
        render: (Table, value, row, error) => {
          return (
            <Table.Cell>
              {value} {error}
            </Table.Cell>
          );
        },
        sortable: false,
        hidden: false,
        hideable: false,
        sort: formFields[k].tableSort,
      }));

    const config = [...dynConfig, ...staticConfig];

    const renderFooter = () => {
      return showCalculation ? (
        <TableFooter
          footer={data.footer}
          colSpan={colSpan}
          currency={currency}
          lineItemConfiguration={lineItemConfiguration}
          hasEditables={hasEditables || hasSelect}
        />
      ) : null;
    };

    return (
      <Grid className="_si_li_table">
        <Grid.Row>
          <Grid.Column>
            <Header as="h5">
              <FormattedMessage id="LE_TABLE_INFO" />.
            </Header>
          </Grid.Column>
        </Grid.Row>
        {hasEditables && allowAddLineItem && (
          <Grid.Row>
            <Grid.Column>
              <Button primary size="mini" type="button" onClick={onAdd}>
                Add position
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <DataGrid
              data-spec="lin-table"
              translated
              enableDefaultSelectionWithOnlyOneBatchAction
              data={lineItems}
              batchActionButtonTranslation="EDIT"
              validationKey="data"
              //onError is mandatory for validation, gets validation errors , we do not need them for now
              onError={() => null}
              validate={() => this.inMemoryValidate()}
              config={{idSelector: 'positionNumber', fields: config}}
              emptyState={this.emptystate}
              renderFooter={renderFooter}
              actions={actions}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
LineItemTable.propTypes = {
  pageConfig: pageConfigShape,
  data: documentShape,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  onDuplicate: PropTypes.func,
  currency: PropTypes.string,
  intl: intlShape,
  inMemoryValidation: inMemoryValidatorShape,
  actions: actionsShape,
};

export default injectIntl(LineItemTable);
