import PropTypes from 'prop-types';

export const aocItemShape = PropTypes.shape({
  allowanceChargeQualifier: PropTypes.string,
  allowanceChargeTypeCoded: PropTypes.string,
  chargeBaseQuantity: PropTypes.number,
  allowanceChargeAmount: PropTypes.number,
  allowanceChargeComment: PropTypes.string,
  vatrate: PropTypes.number,
  vatamount: PropTypes.number,
});

export const semanticOptionShape = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.any,
    value: PropTypes.any,
  })
);

export const aocHeadConfig = PropTypes.shape({
  allowAddingCharges: PropTypes.bool.isRequired,
  allowRemovingCharges: PropTypes.bool.isRequired,
  allowAddingAllowances: PropTypes.bool.isRequired,
  allowRemovingAllowances: PropTypes.bool.isRequired,
  allowRemovingChargesAndAllowances: PropTypes.bool.isRequired,
});
