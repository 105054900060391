import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Popup, Header, Divider, Grid, Button, Icon} from 'semantic-ui-react';

const Pop = ({
  dialogtitle,
  dialogcontent,
  trigger,
  callbackprops,
  onClose,
  handleConfirm,
  handleCancel,
  ...rest
}) => {
  const Content = () => (
    <div>
      <Header as="h4">{dialogtitle}</Header>
      <p>{dialogcontent}</p>
      <Divider />

      <Grid centered divided columns={2}>
        <Grid.Column textAlign="center">
          <Button
            data-spec="confirm-popup-green-button"
            onClick={handleConfirm}
            size="mini"
            color="green"
            compact
            icon
            fluid
            {...callbackprops}>
            <Icon name="check" />
          </Button>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Button
            data-spec="confirm-popup-cancel-button"
            onClick={handleCancel}
            size="mini"
            color="red"
            compact
            icon
            fluid>
            <Icon name="cancel" />
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );

  return (
    <Popup
      on="click"
      trigger={trigger}
      content={
        <Content
          dialogcontent={dialogcontent}
          dialogtitle={dialogtitle}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          onClose={onClose}
          callbackprops={callbackprops}
        />
      }
      {...rest}
    />
  );
};

class ConfirmPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleConfirm = (e, data) => {
    e.preventDefault();
    const {onConfirm} = this.props;

    this.setState({isOpen: false});

    if (onConfirm) {
      onConfirm(e, data);
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    const {onCancel} = this.props;

    this.setState({isOpen: false});

    if (onCancel) {
      onCancel();
    }
  };

  handleOpen = () => {
    this.setState({isOpen: true});
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  render() {
    const {...rest} = this.props;
    return (
      <Pop
        handleCancel={this.handleCancel}
        handleConfirm={this.handleConfirm}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={this.state.isOpen}
        {...rest}
      />
    );
  }
}

ConfirmPopup.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

Pop.propTypes = {
  dialogtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogcontent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  callbackprops: PropTypes.object,
  onClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default ConfirmPopup;
