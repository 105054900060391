const getScenarioCategoriesSideBarItems = (userConfig, propsScenarios) => {
  let scenarioCategoriesSideBarItems = [];
  const scenarioCategories = userConfig?.scenarioCategoryList || [];

  if (scenarioCategories.length !== 0) {
    scenarioCategoriesSideBarItems = scenarioCategories.reduce((acc, curr) => {
      const categoryScenarioUuids = curr?.scenarioUuids || [];

      const resultScenarios = categoryScenarioUuids.map((uuid) =>
        propsScenarios.find((scenario) => scenario?.uuid === uuid)
      );

      if (resultScenarios.length) {
        acc.push({
          ...curr,
          scenarios: [...resultScenarios],
        });
      }

      return acc;
    }, []);
  }

  return scenarioCategoriesSideBarItems;
};

export {getScenarioCategoriesSideBarItems};
