import React from 'react';
import PropTypes from 'prop-types';
import {Label} from 'semantic-ui-react';
import {dynID} from '@ecosio/pathform';
import MessagePlural from '../MessagePlural/index';

const LinkList = ({metaDocuments, optionalTranslation}) => (
  <Label.Group color="purple">
    {Object.keys(metaDocuments).map((key) => (
      <Label as="a" href="#metadocuments" key={key} data-spec="doc-link">
        {metaDocuments[key].length}{' '}
        {optionalTranslation ? (
          <MessagePlural
            id={`PLURAL_${dynID(optionalTranslation)}`}
            value={metaDocuments[key].length}
          />
        ) : (
          <MessagePlural
            id={`PLURAL_${key}`}
            value={metaDocuments[key].length}
          />
        )}
      </Label>
    ))}
  </Label.Group>
);

LinkList.propTypes = {
  metaDocuments: PropTypes.object,
  optionalTranslation: PropTypes.string,
};

export default LinkList;
