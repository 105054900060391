import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Input} from '@ecosio/pathform';

/**
 * BankAccount is an array, we need to access a fixed index in the array here:
 * Reason: for enrichment in companySettings page and conditional rendering from IBAN and BIC, AccountHolder
 * For KTM and Coop it is good enough, clear with Paul
 * ToDo: refactor this in later epic
 */
const BusinessEntityBankAccount = ({prefix}) => {
  return (
    <React.Fragment>
      <FormGroup widths="equal">
        <Input
          path={`${prefix}.bankAccounts[0].iban`}
          name={`${prefix}.bankAccounts[0].iban`}
          size="mini"
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input
          path={`${prefix}.bankAccounts[0].bankAccountNr`}
          name={`${prefix}.bankAccounts[0].bankAccountNr`}
          size="mini"
        />
        <Input
          path={`${prefix}.bankAccounts[0].bic`}
          name={`${prefix}.bankAccounts[0].bic`}
          size="mini"
        />
      </FormGroup>
      <FormGroup widths="equal">
        <Input
          path={`${prefix}.bankAccounts[0].bankAccountOwner`}
          name={`${prefix}.bankAccounts[0].bankAccountOwner`}
          size="mini"
        />
      </FormGroup>
    </React.Fragment>
  );
};

BusinessEntityBankAccount.propTypes = {
  prefix: PropTypes.string.isRequired,
};

export default BusinessEntityBankAccount;
