import {createStore, applyMiddleware} from 'redux';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import * as Sentry from '@sentry/react';
import createRootReducer from './reducers';
import {isProd} from './helpers/utils';
import {BASE_URL} from './constants';

if (typeof BASE_URL === 'undefined') {
  console.warn('BASE_URL is undefined!');
}

const configureScopeWithState = (scope, state) => {
  // @kranthi: you can set any arbitrary datat in here for sentry
  // if it helps: https://docs.sentry.io/platforms/javascript/enriching-events/scopes/
  scope.setUser({
    email:
      state?.config?.userConfig?.email || state?.user?.data?.userId || null,
    company: state?.config?.userConfig?.company?.name || null,
  });
};

const stateTransformer = (state) => {
  const {config} = state;

  return {
    // we just store the user config, everything else might be too large
    ...config,
  };
};

const actionTransformer = (/*action*/) => {
  // sentry records redux actions as breadcrumbs.
  // if we have the actual webedi documents in the redux action,
  // this will lead to too large payloads for sentry.
  // actually, there is way more in actions that is being recorded,
  // that's why we just remove everything.
  // if there are some interesting or helpful things in webedi actions,
  // we could hand-pick them out here.
  return {};
  /*
  if (action?.data?.document) {
    return {
      ...action,
      data: {
        ...action.data,
        document: 'removed',
      },
    };
  }
  return action;
   */
};

export const history = createBrowserHistory({basename: BASE_URL});
const routingMiddleware = routerMiddleware(history);
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState,
  stateTransformer,
  actionTransformer,
});

const middleWares = isProd()
  ? [
      applyMiddleware(routingMiddleware),
      applyMiddleware(thunk),
      sentryReduxEnhancer,
    ]
  : [applyMiddleware(routingMiddleware), applyMiddleware(thunk)];

const initState = {};

const enhancer = composeWithDevTools(...middleWares);

export default createStore(createRootReducer(history), initState, enhancer);
