import React from 'react';
import {useIntl} from 'react-intl';
import {CollapseBox} from '@ecosio/components';
import PropTypes from 'prop-types';
import EmptyPageTemplate from '../DailyDocument/EmptyPageTemplate';
import {historyShape} from '../../shapes/generalShapes';

/**
 * It is used as Empty state page, when monthly report query returns an empty result
 */
const MonthlyReportEmptyState = ({history, noButtons = false}) => {
  const intl = useIntl();
  return (
    <CollapseBox>
      <EmptyPageTemplate
        image={{
          height: 324,
          width: 388,
          src: `/webedi/assets/empty.svg`,
        }}
        subHeader={intl.formatMessage({
          id: `MONTHLY_REPORT_LIST_NO_POS_FOUND_SUB_HEADER`,
        })}
        header={intl.formatMessage({
          id: `MONTHLY_REPORT_LIST_NO_POS_FOUND_HEADER`,
        })}
        secondButton={
          !noButtons
            ? {
                onClick: history.goBack,
                text: intl.formatMessage({
                  id: `BACK_BUTTON_MONTHLY_REPORT_LIST`,
                }),
              }
            : undefined
        }
      />
    </CollapseBox>
  );
};

MonthlyReportEmptyState.propTypes = {
  noButtons: PropTypes.bool,
  history: historyShape.isRequired,
};

export default MonthlyReportEmptyState;
