import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Header, Button} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Form, ExternalSubmit} from '@ecosio/pathform';
import {pageConfigShape} from '../../../../../shapes/scenarios';
import AllowancesAndCharges from './AllowancesAndCharges';
import documentAocDecorator from './documentAocDecorator';
import './DocumentAocEditor.css';

const DocumentAocEditor = ({
  pageConfig,
  documentExchange,
  onCancel,
  onSave,
  vatrates,
  isIglSet,
  docCurrency,
}) => {
  return (
    <Modal open size="large">
      <Header>
        <FormattedMessage id="INVOIC_AOC_HEADER" />
      </Header>
      <Modal.Content>
        <Form
          formid="InvoiceHeadAocEditor"
          decorators={[documentAocDecorator]}
          initialValues={documentExchange}
          onSubmit={onSave}
          fields={pageConfig.formFields}>
          {(ctx) => (
            <AllowancesAndCharges
              push={ctx.form.mutators.push}
              currency={docCurrency}
              config={pageConfig.headerConfiguration}
              fields={pageConfig.formFields}
              docLevelAlcConfiguration={pageConfig?.docLevelAlcConfiguration}
              customerSpecificTranslations={
                pageConfig?.customerSpecificTranslations
              }
              vatrates={vatrates}
              isIglSet={isIglSet}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <ExternalSubmit
          color="green"
          form="InvoiceHeadAocEditor"
          content={<FormattedMessage id="GENERAL_SAVE" />}
        />
        <Button color="red" onClick={onCancel} type="button" data-spec="cancel">
          <FormattedMessage id="GENERAL_CANCEL" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DocumentAocEditor.propTypes = {
  documentExchange: PropTypes.object.isRequired,
  pageConfig: pageConfigShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  vatrates: PropTypes.array,
  isIglSet: PropTypes.bool,
  docCurrency: PropTypes.string.isRequired,
};

export default DocumentAocEditor;
