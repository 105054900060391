import React from 'react';
import {injectIntl} from 'react-intl';
import {Input} from '@ecosio/pathform';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {intlShape} from '@ecosio/components';
import {
  extractOriginalPositionNumberFromUniquePositionNumber,
  generateUniquePositionNumber,
} from '../../CommonComponents/SimpleWithComplexStructureFunctions/positionNumberTransform';

const SimpleComplexPackagingDropDownField = ({
  name,
  path,
  disabled,
  renderLabel,
  intl,
  formContext,
  lineItemIndex,
  cpsIndex,
  ...rest
}) => {
  const totalCpsCount =
    formContext?.values?.consignmentPackagingSequences[0]
      ?.packagingInformations[0]?.numberOfPackages;
  if (!totalCpsCount) {
    console.info(
      'SSCC Modal package dropDown will be empty, totalCpsCount is 0 or undefined'
    );
  }

  const dynamicOptions = Array.from({length: totalCpsCount}, (pack, index) => {
    const hierarchicalID =
      formContext?.values?.consignmentPackagingSequences[index + 1]
        ?.hierarchicalID;

    return {
      text: intl.formatMessage(
        {
          id: 'COOP_SIMPLE_COMPLEX_PACKAGE_DROPDOWN_ITEM',
        },
        {
          number: index + 1,
        }
      ),
      value: Number(hierarchicalID),
      key: index,
    };
  });

  const currentValue = get(formContext?.values || {}, name);

  const currentValueFoundInDropDownOptions = dynamicOptions.find(
    (option) => option.value === currentValue
  );

  if (currentValue && !currentValueFoundInDropDownOptions) {
    formContext.form.batch(() => {
      formContext.form.change(name, null);
    });
  }

  return (
    <Input
      {...rest}
      //triggersValidation
      key={currentValueFoundInDropDownOptions ? cpsIndex : -1}
      name={name}
      path={path}
      placeholder={intl.formatMessage({
        id: 'COOP_SIMPLE_COMPLEX_PACKAGE_DROPDOWN_PLACEHOLDER',
      })}
      options={dynamicOptions}
      onInputChange={(e, value) =>
        moveToCps(value, formContext, lineItemIndex, cpsIndex)
      }
      disabled={disabled}
      renderLabel={renderLabel}
    />
  );
};

SimpleComplexPackagingDropDownField.defaultProps = {
  disabled: false,
  renderLabel: false,
};

SimpleComplexPackagingDropDownField.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  renderLabel: PropTypes.bool,
  intl: intlShape.isRequired,
  formContext: PropTypes.object.isRequired,
  lineItemIndex: PropTypes.number.isRequired,
  cpsIndex: PropTypes.number.isRequired,
};

export default injectIntl(SimpleComplexPackagingDropDownField);

export const moveToCps = (
  dropDownValue,
  formContext,
  lineItemIndex,
  cpsIndex,
  removeOnly = false
) => {
  const allValues = formContext?.values?.consignmentPackagingSequences || [];

  let currentLineItem = {
    ...(formContext?.values?.consignmentPackagingSequences[0]
      ?.deliveryLineItems[lineItemIndex] || {}),
  };

  const indexOfcps = allValues.findIndex(
    (element) => element?.hierarchicalID === `${dropDownValue}`
  );

  const tempPositionNumber = generateUniquePositionNumber(
    currentLineItem?.positionNumber,
    cpsIndex
  );

  currentLineItem = {
    ...currentLineItem,
    positionNumber: tempPositionNumber,
  };

  delete currentLineItem.cpses;

  /**
   * check if the unique positionNumber already exists in allCpses
   */
  const oldLinPushIndexes = allValues.flatMap((cps, cpsIndex) => {
    let lineItemIndex = -1;

    if (cps?.deliveryLineItems && cps?.deliveryLineItems.length) {
      lineItemIndex = cps?.deliveryLineItems?.findIndex(
        (z) => `${z.positionNumber}` === `${tempPositionNumber}`
      );
    }

    return lineItemIndex > -1 ? [{cpsIndex, lineItemIndex}] : [];
  });

  /**
   *  if the unique positionNumber already exists then remove it. we need to add it to other cps
   */
  if (oldLinPushIndexes.length === 1) {
    formContext.form.mutators.remove(
      `consignmentPackagingSequences[${oldLinPushIndexes[0]?.cpsIndex}].deliveryLineItems`,
      oldLinPushIndexes[0]?.lineItemIndex
    );

    if (removeOnly) {
      const positionNumber = extractOriginalPositionNumberFromUniquePositionNumber(
        currentLineItem?.positionNumber
      );

      formContext.form.values.consignmentPackagingSequences[
        oldLinPushIndexes[0]?.cpsIndex
      ].deliveryLineItems.forEach((lineItem, index) => {
        formContext.form.mutators.update(
          `consignmentPackagingSequences[${indexOfcps}].deliveryLineItems`,
          index,
          {
            ...lineItem,
            positionNumber: generateUniquePositionNumber(positionNumber, index),
          }
        );
      });
    }
  }

  /**
   *  push the lineItem to a non-root-cps
   */
  if (!removeOnly && indexOfcps !== -1) {
    formContext.form.mutators.push(
      `consignmentPackagingSequences[${indexOfcps}].deliveryLineItems`,
      {...currentLineItem}
    );
  }
};
