import React, {Component} from 'react';
import {connect} from 'react-redux';
import {parse} from 'qs';
import {Segment, Grid, Loader} from 'semantic-ui-react';
import {set} from 'lodash';
import {Helmet} from 'react-helmet';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import DocumentHeader from '../../../../DocumentHeader';
import OrderMeta from '../../../../Order/View/OrderMeta';
import {fetchTurnaroundDocument} from '../../../../../../reducers/turnaround';
import {ContextWrapper} from '../../../../Order/View/Header/DocumentHeadEditForm';
import {
  pageConfigShape,
  scenarioShape,
} from '../../../../../../shapes/scenarios';
import {envelopesShape} from '../../../../../../shapes/envelopes';
import CpsForm from './CpsForm';
import Sidebar from './CpsForm/Sidebar';

const mapDispatchToProps = (dispatch) => ({
  fetchTurnaround: (
    scenario,
    envelopeUuid,
    documentMetaUuid,
    documentType,
    filterParams = {}
  ) =>
    dispatch(
      fetchTurnaroundDocument(
        scenario,
        envelopeUuid,
        documentMetaUuid,
        documentType,
        filterParams
      )
    ),
});

const mapStateToProps = ({turnaround}) => ({turnaround});

const HelmetHeader = injectIntl(({intl}) => {
  return (
    <Helmet>
      <title>{intl.formatMessage({id: 'DESADV_CREATE_HTML_HEADER'})}</title>
    </Helmet>
  );
});

class DesadvComplexTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentExchange: null,
      fetching: true,
    };
  }

  componentDidMount() {
    const {match, scenario} = this.props;

    const params = parse(this.props.location.search.substring(1)) || {};
    this.props
      .fetchTurnaround(
        scenario,
        match.params.envelopeUuid,
        match.params.documentUuid,
        match.params.documentType,
        params
      )
      .then((action) => {
        this.setState({
          documentExchange: action,
          fetching: false,
        });
      })
      .catch((err) => console.error(err));
  }

  onSaveOrderMeta = (formdata) => {
    return new Promise((resolve) => {
      const copy = Object.assign({}, this.state.documentExchange);
      this.setState(
        {
          documentExchange: set(copy, 'data.document.header', formdata.header),
        },
        resolve
      );
    });
  };

  render() {
    const {pageConfig, scenario, envelope} = this.props;
    const renderLoader = this.state.fetching || !this.state.documentExchange;
    return renderLoader ? (
      <Loader active />
    ) : (
      <>
        <HelmetHeader />
        <ContextWrapper>
          <DocumentHeader
            pageConfig={pageConfig}
            envelope={envelope.data}
            documentExchange={this.state.documentExchange}
            scenario={scenario}
          />
          <Segment>
            <OrderMeta
              showOnlyForm
              documentExchange={this.state.documentExchange.data}
              pageConfig={pageConfig}
              onSubmit={this.onSaveOrderMeta}
              editable
            />
          </Segment>
          <Grid columns={2}>
            <Grid.Column width="11">
              <CpsForm
                history={this.props.history}
                match={this.props.match}
                documentExchange={this.state.documentExchange?.data.document}
                data={this.state.documentExchange?.data.document.details}
                config={this.props.pageConfig}
                scenario={scenario}
              />
            </Grid.Column>
            <Grid.Column width="5">
              <Sidebar
                data={this.state.documentExchange?.data.document.details}
                envelope={envelope}
              />
            </Grid.Column>
          </Grid>
        </ContextWrapper>
      </>
    );
  }
}

DesadvComplexTemplate.propTypes = {
  match: PropTypes.object,
  scenario: scenarioShape,
  pageConfig: pageConfigShape,
  envelope: envelopesShape,
  fetchTurnaround: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesadvComplexTemplate);
