import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, FormGroup} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import ConfirmPopup from '../../../ConfirmPopup';
import SendDocumentButton from '../../SendDocumentButton';
import {pageConfigShape} from '../../../../shapes/scenarios';

const DocumentSendConfirmation = ({
  onCancel,
  onConfirm,
  docType,
  isEnabled,
  pageConfig,
  saveDraftWithDefaultDelay,
  submitDraftWithOutDelay,
  saveDraftWithScheduledDate,
  currentDraftData,
}) => {
  return (
    <div style={{float: 'right', marginTop: '20px'}}>
      <Form>
        <FormGroup>
          <SendDocumentButton
            isEnabled={isEnabled}
            onSubmit={(event, actionType) => {
              onConfirm(actionType);
            }}
            pageConfig={pageConfig}
            docType={docType}
            saveDraftWithDefaultDelay={saveDraftWithDefaultDelay}
            submitDraftWithOutDelay={(event, actionType) => {
              submitDraftWithOutDelay(actionType);
            }}
            saveDraftWithScheduledDate={saveDraftWithScheduledDate}
            currentDraftData={currentDraftData}
          />
          <ConfirmPopup
            position="left center"
            dialogtitle={
              <span>
                <FormattedMessage id="DOCTYPE_CANCEL" /> ?
              </span>
            }
            dialogcontent={<FormattedMessage id="DOCTYPE_CANCEL_CONTENT" />}
            onConfirm={onCancel}
            trigger={
              <Button color="red" type="button" data-spec="cancel-submit">
                <FormattedMessage id="GENERAL_CANCEL" />
              </Button>
            }
          />
        </FormGroup>
      </Form>
    </div>
  );
};

DocumentSendConfirmation.defaultProps = {
  isEnabled: true,
};

DocumentSendConfirmation.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  docType: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool,
  pageConfig: pageConfigShape.isRequired,
  saveDraftWithDefaultDelay: PropTypes.func.isRequired,
  submitDraftWithOutDelay: PropTypes.func.isRequired,
  saveDraftWithScheduledDate: PropTypes.func.isRequired,
  currentDraftData: PropTypes.object,
};

export default injectIntl(DocumentSendConfirmation);
