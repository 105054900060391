import React, {Component} from 'react';
import {Header, Menu} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {parse, stringify} from 'qs';
import {Helmet} from 'react-helmet';
import {dynID} from '@ecosio/pathform';
import {get} from 'lodash';
import withScenario from '../hoc/withScenario';
import {scenarioShape} from '../../shapes/scenarios';
import {FlashMessage} from '../DynamicTemplate';
import {
  DAILY_DOCUMENT_OVERVIEW,
  DAILY_DOCUMENT_CREATE,
} from '../../helpers/constants';
import DailyInvoiceCreateComponent from './DailyInvoice/DailyInvoiceCreateComponent';
import DailyInvoiceOverviewComponent from './DailyInvoice/DailyInvoiceOverviewComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 38px 48px 59px 48px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HelmetTags = injectIntl(({scenario, intl}) => (
  <Helmet>
    <title>
      {intl.formatMessage({id: dynID(scenario.nameTranslationKey)})}
    </title>
  </Helmet>
));

const getDailyDocumentPageConfig = (
  scenario,
  dailyDocumentType,
  dailyDocumentPageType
) => {
  const dailyDocumentsPageConfigurations =
    scenario?.dailyDocumentsPageConfigurations || [];
  if (!dailyDocumentsPageConfigurations.length) {
    return;
  }

  return dailyDocumentsPageConfigurations.find(
    (d) =>
      d?.dailyDocumentType === dailyDocumentType &&
      d?.dailyDocumentPageType === dailyDocumentPageType
  );
};

export const getSortColumnNameForBackend = (
  dailyDocumentPageConfig,
  dataGridColumnName
) => {
  const formFields = dailyDocumentPageConfig?.formFields || {};
  const columnField = get(formFields, dataGridColumnName);
  return columnField?.tableSortFieldPath;
};

class DailyDocumentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FILTER_TABS: [],
    };
  }

  componentDidMount() {
    const {scenario} = this.props;
    const dailyDocumentsPageConfigs =
      scenario?.dailyDocumentsPageConfigurations || [];

    if (dailyDocumentsPageConfigs.length) {
      dailyDocumentsPageConfigs.forEach((config) => {
        this.setState((prevState) => ({
          FILTER_TABS: [
            ...prevState.FILTER_TABS,
            config?.dailyDocumentPageType,
          ],
        }));
      });
    }
  }
  parseParams = (props = this.props) => {
    return parse(props.location.search.substr(1));
  };

  render() {
    const params = this.parseParams();
    const {scenario} = this.props;
    const {FILTER_TABS} = this.state;

    const dailyDocumentsPageConfigs =
      scenario?.dailyDocumentsPageConfigurations || [];

    const allDocumentTypesInScenarioAreEqual = dailyDocumentsPageConfigs.every(
      (config) =>
        config?.dailyDocumentType ===
        dailyDocumentsPageConfigs[0].dailyDocumentType
    );

    const documentType = dailyDocumentsPageConfigs[0].dailyDocumentType;

    if (!allDocumentTypesInScenarioAreEqual) {
      console.error(
        'Dailydocument pageConfigs documentTypes in this scenario are not equal, selecting index[0] dailyDocumentType as fallback'
      );
    }

    return (
      <Container>
        <HelmetTags scenario={scenario} />
        <FlashMessage />
        <Header as="h1">
          <Header.Content>
            <FormattedMessage
              id={dynID(`${scenario?.nameTranslationKey}_HEADER`)}
            />
          </Header.Content>
          <Header.Subheader>
            <FormattedMessage
              id={dynID(`${scenario?.nameTranslationKey}_SUBHEADER`)}
            />
          </Header.Subheader>
        </Header>
        <Menu pointing secondary compact data-spec="types">
          {FILTER_TABS.map((filter) => (
            <Menu.Item
              data-spec={filter}
              key={filter}
              as={NavLink}
              to={{
                search: `?${stringify({
                  type: filter,
                })}`,
              }}
              isActive={() =>
                params?.type
                  ? params?.type === filter
                  : filter === FILTER_TABS[0]
              }>
              <FormattedMessage
                id={dynID(
                  dailyDocumentsPageConfigs.find(
                    (d) => d.dailyDocumentPageType === filter
                  )?.pageLabelTranslationKey
                )}
              />
            </Menu.Item>
          ))}
        </Menu>

        {params?.type === DAILY_DOCUMENT_OVERVIEW ? (
          <DailyInvoiceOverviewComponent
            dailyInvoiceOverViewPageConfiguration={getDailyDocumentPageConfig(
              scenario,
              documentType,
              DAILY_DOCUMENT_OVERVIEW
            )}
            {...this.props}
          />
        ) : (
          <DailyInvoiceCreateComponent
            dailyInvoiceCreatePageConfiguration={getDailyDocumentPageConfig(
              scenario,
              documentType,
              DAILY_DOCUMENT_CREATE
            )}
            {...this.props}
          />
        )}
      </Container>
    );
  }
}

DailyDocumentComponent.propTypes = {
  scenario: scenarioShape.isRequired,
};

export default withScenario()(DailyDocumentComponent);
