import React from 'react';
import {isEmpty, includes} from 'lodash';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Table, Button, Icon, Label, Popup} from 'semantic-ui-react';
import {intlShape} from '@ecosio/components';
import ConfirmPopup from '../../../../ConfirmPopup/index';
import {requiresLineItemConfirmation} from '../../../../../util/scenarioUtil';
import ValidationWarnIcon from '../../../ValidationWarnIcon';
import {inMemoryValidatorShape} from '../../../../../shapes/inMemoryValidatorShape';

const WarnLabel = injectIntl(({error, intl: {formatMessage}}) => {
  const style = {padding: '6px 3px 6px 15px', backgroundColor: 'red'};
  if (!error) {
    return null;
  }

  const label = (
    <Label pointing="right" style={style}>
      <Icon name="exclamation triangle" className="white" />
    </Label>
  );

  return (
    <Popup
      trigger={label}
      header={formatMessage({id: 'LE_CONFIRM_POPUP_HEADER'})}
      content={formatMessage({id: 'LE_CONFIRM_POPUP_CONTENT'})}
    />
  );
});

WarnLabel.propTypes = {
  error: PropTypes.bool.isRequired,
  intl: intlShape,
};

const inMemoryValidatePerLinItem = (inMemoryValidation, linIdex) => {
  const {hasErrors, errorData} = inMemoryValidation;

  if (hasErrors) {
    //filter here the validation errors per linPosition
    const filteredErrors = errorData.filter((singleError) =>
      includes(
        Object.keys(singleError)[0],
        `details.ordersData.ordersLineItems[${linIdex}]`
      )
    );

    if (filteredErrors.length) {
      return (
        <ValidationWarnIcon tooltipData="GENERAL_VALIDATOR_LIN_MODAL_VALIDATION_ERRORS" />
      );
    }
  }
};

const EditCell = ({
  dataSpec,
  formFields,
  onEdit,
  onDelete,
  onDuplicate,
  canDelete,
  canDuplicate,
  idx,
  row,
  inMemoryValidation,
}) => {
  let isValid = true;

  if (requiresLineItemConfirmation(formFields)) {
    isValid = !isEmpty(row.lineItemActionRequest);
  }

  return (
    <Table.Cell
      verticalAlign="top"
      collapsing
      textAlign="left"
      data-spec={dataSpec}>
      <WarnLabel error={!isValid} />
      {onEdit !== null ? (
        <React.Fragment>
          {inMemoryValidatePerLinItem(inMemoryValidation, idx)}
          <Button
            data-spec="edit-lineItem"
            size="mini"
            color="purple"
            index={idx}
            lineitem={row}
            onClick={onEdit}
            compact
            icon>
            <Icon name="edit" />
          </Button>
        </React.Fragment>
      ) : null}{' '}
      {canDuplicate === true ? (
        <React.Fragment>
          <Button
            data-spec="duplicate-lineItem"
            size="mini"
            color="purple"
            index={idx}
            lineitem={row}
            onClick={onDuplicate}
            compact
            icon>
            <Icon name="copy outline" />
          </Button>
        </React.Fragment>
      ) : null}{' '}
      {canDelete ? (
        <ConfirmPopup
          data-spec="delete-lineItem-confirm-popup"
          dialogtitle={
            <span>
              <FormattedMessage id="GENERAL_DELETE" /> ?
            </span>
          }
          dialogcontent={<FormattedMessage id="ORDER_POS_DELETE" />}
          callbackprops={{index: idx, lineitem: row}}
          onConfirm={onDelete}
          trigger={
            <Button
              data-spec="delete-lineItem"
              size="mini"
              color="red"
              compact
              icon>
              <Icon name="trash" />
            </Button>
          }
        />
      ) : null}
    </Table.Cell>
  );
};

EditCell.propTypes = {
  formFields: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  canDelete: PropTypes.bool,
  onDuplicate: PropTypes.func,
  canDuplicate: PropTypes.bool,
  idx: PropTypes.number,
  row: PropTypes.object,
  inMemoryValidation: inMemoryValidatorShape,
  dataSpec: PropTypes.string,
};

export default EditCell;
