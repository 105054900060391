/* eslint-disable react/prefer-stateless-function */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Loader} from 'semantic-ui-react';
import {flowRight} from 'lodash';
import withScenario from '../../../hoc/withScenario';
import withDocument from '../../../hoc/withDocument';
import DynamicTemplate from '../../../DynamicTemplate/index';
import {layoutOptions} from '../../Layout/ViewDocumentLayout';
import {documentExchangeShape} from '../../../../shapes/documentExchange';
import {scenarioShape} from '../../../../shapes/scenarios';

const SwitchLoader = () => (
  <Fragment>
    <Loader active />
  </Fragment>
);

class ViewDocumentSwitch extends React.PureComponent {
  render() {
    const {scenario, documentExchange, documentUuid} = this.props;

    if (documentExchange.error) {
      throw new Error(documentExchange.error);
    }

    if (!documentExchange.pageConfig) {
      const message = `Unable to determine pageConfig for document uuid "${documentUuid}"`;
      throw new Error(message);
    }

    return (
      <DynamicTemplate
        componentName={scenario.detailLayout}
        options={layoutOptions}
        {...this.props}
      />
    );
  }
}

ViewDocumentSwitch.propTypes = {
  scenario: scenarioShape,
  documentExchange: documentExchangeShape,
  documentUuid: PropTypes.string,
};

export default flowRight(
  withScenario(),
  withDocument(SwitchLoader)
)(ViewDocumentSwitch);
