import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {shouldRenderDrafts} from '../DocumentDrafts/draftUtils';

export const DRAFT_FEATURES_REQUESTS_NAMES = {
  FETCH_WEBEDI_SETTINGS: 'FETCH_WEBEDI_SETTINGS',
};

export const DRAFT_FEATURES_REQUESTS_DEAULT = {
  [DRAFT_FEATURES_REQUESTS_NAMES.FETCH_ALL_DRAFTS]: false,
};

const withDocumentDraftSettings = (activateDraftFeatures = {}) => (
  WrappedComponent
) => {
  const mapStateToProps = ({config}) => ({
    userConfig: config?.userConfig,
  });

  function WithDocumentDraftSettings(props) {
    const [webediSettings, setWebediSettings] = useState([]);
    const [fetchingSettings, setFetchingSettings] = useState(false);

    const {userConfig} = props;
    useEffect(() => {
      const fetchWebediSettings = get(
        activateDraftFeatures,
        DRAFT_FEATURES_REQUESTS_NAMES.FETCH_WEBEDI_SETTINGS,
        false
      );

      if (fetchWebediSettings) {
        setFetchingSettings(true);
        axios
          .get('/api/settings/fetch')
          .then((res) => {
            setWebediSettings(res?.data?.settings || []);
            setFetchingSettings(false);
          })
          .catch(() => {
            setFetchingSettings(false);
          });
      }
    }, []);

    return (
      <WrappedComponent
        draftSettings={{
          webediSettings: webediSettings,
          shouldRenderDrafts: shouldRenderDrafts(userConfig),
          fetchingSettings: fetchingSettings,
        }}
        {...props}
      />
    );
  }

  WithDocumentDraftSettings.propTypes = {};

  return connect(mapStateToProps)(WithDocumentDraftSettings);
};

export default withDocumentDraftSettings;
