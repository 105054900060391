/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Message, Header, Button, Modal} from 'semantic-ui-react';
import {Form, Submit, Input} from '@ecosio/pathform';
import {FormattedMessage} from 'react-intl';

const CustomPropertyEditor = ({
  items,
  formFields,
  open,
  posting,
  onCancel,
  correlatorId,
  onSubmit,
  error,
}) => {
  return (
    <Modal open={open} size="large">
      <Header>
        <FormattedMessage
          id="BUSINESSCASE_EDIT_HEADLINE"
          values={{correlatorId: correlatorId || ''}}
        />
      </Header>
      <Modal.Content>
        {error && <Message error>{error}</Message>}
        <Form initialValues={items} onSubmit={onSubmit} fields={formFields}>
          {() => {
            return (
              <Fragment>
                {Object.keys(formFields).map((i, idx) => {
                  return (
                    <Input
                      key={idx}
                      path={i}
                      name={i}
                      disabled={posting}
                      stripTimezone={false}
                    />
                  );
                })}
                <div style={{textAlign: 'right'}}>
                  <Button
                    type="button"
                    color="red"
                    onClick={onCancel}
                    disabled={posting}>
                    <FormattedMessage id="GENERAL_CANCEL" />
                  </Button>

                  <Submit
                    color="green"
                    loading={posting}
                    disabled={() => posting}>
                    <FormattedMessage id="GENERAL_SAVE" />
                  </Submit>
                </div>
              </Fragment>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

CustomPropertyEditor.propTypes = {
  correlatorId: PropTypes.string,
  items: PropTypes.object,
  formFields: PropTypes.object,
  open: PropTypes.bool,
  posting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

export default CustomPropertyEditor;
