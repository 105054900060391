import jexl from 'jexl';
import {get} from 'lodash';

/**
 * This function is used to check the daily documents limitations.
 * The return value of this function is used to enable/disable dataGrid lineItems, based on configured limit
 *
 * @param {*} data  lineItems array
 * @param {*} expression jexl expression from config
 * @returns an object with data which has been passed through and a boolean flag (countReached)
 * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/298
 */
export const checkDocumentLineItemsLimit = (data = [], expression) => {
  const context = {
    evalData: {
      data: data,
      countReached: false,
    },
  };

  jexl.addTransform('checkLimit', (value, path, limit) =>
    checkLimit(value, path, limit)
  );

  return jexl.evalSync(`${expression}`, context);
};

const checkLimit = (evalData = {}, paths = [], limit) => {
  if (!Array.isArray(paths)) {
    console.error('check invoice Limitation Paths is not an Array');
  }

  let count = 0;
  let countReached = false;

  const result = evalData?.data?.reduce((arr, curr) => {
    let itemExists = false;

    /**
     * check if the current item value exists in the result array. check works for multiple paths.
     */
    itemExists = checkIfconcatedValueExists(arr, paths, curr);

    /**
     * if the curr value does not exist in the result AND count is smaller than limit,
     * add curr object to result array and increment count by 1.
     */
    if (!itemExists && count < limit) {
      arr.push(curr);
      count = count + 1;
    }

    /**
     * if the curr value exists in the result AND count is smaller or equal to limit,
     * add curr object, dont increment count.
     * duplicate items are allowed and counted as 1 according to ticket:
     * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/298
     */
    if (itemExists && count <= limit) {
      arr.push(curr);
    }

    /**
     * check after the  above steps if current item value exists in the result array.check works for multiple paths.
     */
    itemExists = checkIfconcatedValueExists(arr, paths, curr);

    /***
     * The countReached flag is used to disable the dataGrid line checkboxes.
     * Here we set countReached only to true, when:
     * - limit is set in expression,
     * - itemExists is false, which means the curr object item is not in result array.
     * - count is equal to limit.
     * - there was a prev object in the array.
     *
     * this checks are neccessary to disable the lineItem checkboxes in dataGrid.
     * useCase example:
     * - user selects items in DataGrid through clicking the checkboxes, unselected items are enabled
     * - count reached limit, all dataGrid items are still enabled.
     * - user tries to select an other item after reaching the limt, the last selected checkbox will be
     * unchecked and all unchecked items are disabled.
     */
    if (limit && !itemExists && count === limit && arr.length) {
      countReached = true;
    } else {
      countReached = false;
    }

    if (
      !countReached &&
      evalData?.countReached &&
      evalData?.data.length !== 0 &&
      evalData?.data.length <= limit
    ) {
      countReached = true;
    }

    return arr;
  }, []);

  return {
    data: result || [],
    countReached: countReached,
  };
};
/**
 *
 * @param {*} arr result Array of objects
 * @param {*} paths array of Paths
 * @param {*} curr current object
 * @returns boolean if the current concated "paths" value exits in result array
 */
const checkIfconcatedValueExists = (arr, paths, curr) => {
  return !!arr.find(
    (d) =>
      concatValuesFromPathArray(paths, d) ===
      concatValuesFromPathArray(paths, curr)
  );
};

/**
 *
 * @param {*} paths array of paths
 * @param {*} object lineItem object
 * @returns a concated String. Acts as primary key
 *
 * This funtions gets all values from object and concats them.
 * xpath is taken from paths array.
 *
 */
const concatValuesFromPathArray = (paths = [], object = {}) => {
  return paths.reduce((val, path) => {
    const value = get(object, path);

    if (value) {
      val = val + value;
    }
    return val;
  }, '');
};
