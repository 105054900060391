import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Header, Grid, Button, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER,
  INDUSTRY_ORDER_RESPONSE,
} from '../../../shapes/supportedDocTypes';
import LineItemsBatchEditor from './LineItemsBatchEditor';

const LineItemsBatchActionSetValuesModal = ({
  documentTypeId,
  onSave,
  onCancel,
  openBatchModal,
  fields,
  batchActionModalHeader,
}) => {
  let TypedLineItemsEditor = null;

  switch (documentTypeId) {
    case INDUSTRY_ORDER:
    case INDUSTRY_ORDER_RESPONSE:
    case INDUSTRY_ORDCHG:
      TypedLineItemsEditor = LineItemsBatchEditor;
      break;
    default:
      console.error(
        `LinItemBatchActionSetValuesModal, documentTypeID not supported: ${documentTypeId}`
      );
  }

  return (
    openBatchModal && (
      <Modal open size="large">
        <Header>
          <FormattedMessage id={dynID(batchActionModalHeader)} />
        </Header>
        <Modal.Content>
          {TypedLineItemsEditor ? (
            <TypedLineItemsEditor
              fields={fields}
              onSave={onSave}
              onCancel={onCancel}
            />
          ) : (
            <Grid divided="vertically" container>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Message negative size="big">
                    <Message.Header>
                      <FormattedMessage id="GENERAL_ERROR" />
                    </Message.Header>
                    <p>
                      <FormattedMessage id="BATCH_EDIT_LIN_MODAL_EDITOR_INVALID_DOCTYPE" />
                    </p>
                  </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    floated="right"
                    type="button"
                    color="red"
                    onClick={onCancel}
                    data-spec="orderLineItemEditor-cancel">
                    <FormattedMessage id="GENERAL_CANCEL" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Modal.Content>
      </Modal>
    )
  );
};

LineItemsBatchActionSetValuesModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  documentTypeId: PropTypes.string.isRequired,
  fields: PropTypes.object,
  openBatchModal: PropTypes.bool.isRequired,
  batchActionModalHeader: PropTypes.string.isRequired,
};

export default LineItemsBatchActionSetValuesModal;
