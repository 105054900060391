/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {isEmpty} from 'lodash';
import {dynID} from '@ecosio/pathform';
import {priceShape} from '../../../../../../shapes/industryDocument';
import {ALLOWANCE, PERCENTAGE, CURRENCY_EUR} from '../../../../../../constants';
import {wasStateChanged} from '../TableBody';
import CircleForValueChanged from '../CircleForValueChanged';

/**
 * Notice: this component is used for both invoice and order response line items.
 * They don't have the same shape.
 */
const UnitPriceCell = ({
  dataSpec,
  lineItem,
  currency = CURRENCY_EUR,
  styleAoc = {color: '#99a4af'},
  ordersDocument,
  themeSpecificColors,
  lineItemIndex,
}) => {
  let unitKey = undefined;
  const customFieldChangedStyle = {
    color: themeSpecificColors?.primaryColor,
    fontWeight: 700,
  };
  const currentItemPriceCalculationGrossChanged = wasStateChanged(
    ordersDocument,
    lineItem,
    'currentItemPriceCalculationGross.value',
    lineItemIndex
  );

  const unitPriceBasisChanged = wasStateChanged(
    ordersDocument,
    lineItem,
    'unitPriceBasis',
    lineItemIndex
  );

  if (lineItem.currentItemPriceCalculationGross?.unit) {
    unitKey = lineItem.currentItemPriceCalculationGross.unit;
  } else if (lineItem?.measureUnitQualifier) {
    //according to Orders Schema measureUnitQualifier is correct
    unitKey = lineItem.measureUnitQualifier;
  } else if (lineItem?.measureUnit) {
    //according to invoice Schema measureUnit is correct
    unitKey = lineItem.measureUnit;
  }

  const Discount = () => {
    if (isEmpty(lineItem.allowancesAndCharges)) {
      return null;
    }

    return lineItem.allowancesAndCharges.map((aoc, idx) => {
      return aoc.allowanceChargeTypeCoded === PERCENTAGE ? (
        <div style={styleAoc} key={`aocUnitPrice_${idx}`}>
          {aoc.allowanceChargeQualifier === ALLOWANCE ? '-' : '+'}
          <FormattedNumber
            style="percent"
            minimumFractionDigits={2}
            value={aoc.percentageOfAllowanceOrCharge / 100}
          />
          <br />
          <br />
        </div>
      ) : (
        <span key={`aocUnitPrice_${idx}`}>
          <br /> <br />
        </span>
      );
    });
  };
  const unitPrice = lineItem.currentItemPriceCalculationGross?.value;
  return (
    <Table.Cell verticalAlign="center" textAlign="right" data-spec={dataSpec}>
      <div data-spec="currency">
        {isNaN(unitPrice) ? (
          ' - '
        ) : (
          <span
            style={{
              ...(currentItemPriceCalculationGrossChanged
                ? customFieldChangedStyle
                : {}),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}>
            <CircleForValueChanged
              shouldRender={currentItemPriceCalculationGrossChanged}
            />
            <FormattedNumber
              style="currency"
              currency={currency}
              minimumFractionDigits={2}
              value={lineItem.currentItemPriceCalculationGross?.value}
            />
          </span>
        )}{' '}
      </div>
      <div
        data-spec="price"
        style={{
          ...(unitPriceBasisChanged ? customFieldChangedStyle : {}),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}>
        <CircleForValueChanged shouldRender={unitPriceBasisChanged} />
        <FormattedMessage
          id="LE_PER"
          values={{value: lineItem.unitPriceBasis}}
        />{' '}
        {unitKey ? <FormattedMessage id={dynID(unitKey)} /> : ''}
      </div>

      <Discount />
    </Table.Cell>
  );
};
export default UnitPriceCell;
UnitPriceCell.propTypes = {
  styleAoc: PropTypes.object,
  currency: PropTypes.string,
  // TODO: proper reusable shape
  lineItem: PropTypes.shape({
    currentItemPriceCalculationGross: priceShape,
    measureUnitQualifier: PropTypes.string,
    allowancesAndCharges: PropTypes.arrayOf(
      PropTypes.shape({
        allowanceChargeTypeCoded: PropTypes.string,
        allowanceChargeQualifier: PropTypes.string,
        percentageOfAllowanceOrCharge: PropTypes.number,
      })
    ),
  }),
  dataSpec: PropTypes.string,
};
