/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {RenderContents, Content, dynID, inputShape} from '@ecosio/pathform';
import {Button, Table, Modal, Header, Grid} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import isNumber from 'lodash/isNumber';
import {FormatDate, intlShape} from '@ecosio/components';
import BusinessEntityComponent from '../../BusinessEntityComponent';
import FreeTextComponent from '../../FreeTextComponent';
import {CALCULATION_TYPES} from '../../../../shapes/scenarios';
import {filterPathFormArray} from '../../../../util/documentUtil';
import {ALLOWANCE, PERCENTAGE, CURRENCY_EUR} from '../../../../constants';
import {ConditionalCurrency} from './ConditionalCurrency';
import {actionRequestMap} from './Table/TableBody';

const styleAoc = {color: '#99a4af'};
const SPACE = ' ';
const AllowanceOrChanceItem = ({item, currency}) => {
  const isPercent = item.allowanceChargeTypeCoded === PERCENTAGE;
  const PREFIX = item.allowanceChargeQualifier === ALLOWANCE ? '-' : '+';

  return isPercent ? (
    <div style={styleAoc}>
      {PREFIX}
      <FormattedNumber
        style="percent"
        minimumFractionDigits={2}
        value={item.percentageOfAllowanceOrCharge / 100}
      />
    </div>
  ) : (
    <div style={styleAoc}>
      {PREFIX}
      <ConditionalCurrency
        value={item.allowanceChargeAmount}
        currency={currency}
      />
    </div>
  );
};

AllowanceOrChanceItem.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string,
};

const ItemComponent = ({content, unit, input, intl}) => {
  return content ? (
    <div
      style={{display: 'inline-block', marginRight: '15px'}}
      data-spec={`lin_modal_${input.label}`}>
      <strong>{intl.formatMessage({id: input.label})}</strong>
      <div>
        {content} {unit ? <FormattedMessage id={unit} /> : ''}
      </div>
      <br />
    </div>
  ) : null;
};
ItemComponent.propTypes = {
  content: PropTypes.string,
  input: inputShape,
  intl: intlShape,
  unit: PropTypes.string,
};

const ItemDateComponent = ({content, input, intl}) => {
  return content ? (
    <div
      style={{display: 'inline-block', marginRight: '15px'}}
      data-spec={`lin_modal_${input.label}`}>
      <strong>{intl.formatMessage({id: input.label})}</strong>
      <div>
        <FormatDate stripTimezone dateString={content} />
      </div>

      <br />
    </div>
  ) : null;
};
ItemDateComponent.propTypes = {
  content: PropTypes.string,
  // TODO: export this shape from the pathform lib
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  intl: intlShape,
};

export const LineItemActionRequest = ({content, input, intl}) => {
  let value = content;
  const isNumericCode = isNumber(parseInt(content));

  if (isNumericCode) {
    value = actionRequestMap[value];
  }

  if (!value) {
    // this is okay when viewing merged documents, as the lineItemActionRequest
    // is not merged into it.
    // we do not know the value in a merged view
    // console.warn('Could not retrieve line-item action request type', content);
    // will be improved in https://gitlab.ecosio.com/code/frontend/issues/464
    value = content;
  }

  const label = input?.label ? input?.label : 'LE_ACTION_REQUEST';

  return content ? (
    <div style={{display: 'inline-block', marginRight: '15px'}}>
      <strong>{intl.formatMessage({id: label})}</strong>
      <div>{intl.formatMessage({id: value})}</div>
      <br />
    </div>
  ) : null;
};

LineItemActionRequest.propTypes = {
  content: PropTypes.string,
  input: PropTypes.object,
  intl: intlShape,
};
const ContractReference = ({content, input, intl, options}) => {
  if (typeof content === 'undefined' || content === null) {
    return null;
  }

  const itemTexts = options
    .filter((option) => option.value === content)
    .map((item) => item.text);

  let value;

  if (Array.isArray(itemTexts) && itemTexts.length === 1) {
    const id = itemTexts[0];
    // make sure we don't crash if this is not a string...
    value = typeof id === 'string' ? intl.formatMessage({id}) : id;
  } else {
    console.warn('Could not extract contract reference', content, options);
    value = content;
  }

  return (
    <div style={{display: 'inline-block', marginRight: '15px'}}>
      <strong>{intl.formatMessage({id: input.label})}</strong>
      <div>{value}</div>
      <br />
    </div>
  );
};

ContractReference.propTypes = {
  content: PropTypes.string,
  input: PropTypes.object,
  intl: intlShape,
  options: PropTypes.object,
};

const PriceCalculation = ({path, currency}) => (
  <Content
    path={path}
    render={({content, input, intl}) => (
      <React.Fragment>
        <Grid.Row>{intl.formatMessage({id: input.label})}</Grid.Row>
        <Grid.Row>
          <h3>
            <FormattedNumber value={content} /> {currency}
          </h3>
        </Grid.Row>
      </React.Fragment>
    )}
  />
);

PriceCalculation.propTypes = {
  path: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

const Prices = ({lineitem, currency = CURRENCY_EUR, fields}) => {
  return (
    <RenderContents initialValues={lineitem} fields={fields}>
      {() => (
        <Grid>
          <Grid.Column width={5} style={{textAlign: 'left'}}>
            <PriceCalculation
              path="currentItemPriceCalculationGross.value"
              currency={currency}
            />
          </Grid.Column>
          <Grid.Column width={6} style={{textAlign: 'center'}}>
            <PriceCalculation
              path="currentItemPriceCalculationNet.value"
              currency={currency}
            />
          </Grid.Column>
          <Grid.Column width={5} style={{textAlign: 'right'}}>
            <PriceCalculation path="lineItemAmount" currency={currency} />
          </Grid.Column>
        </Grid>
      )}
    </RenderContents>
  );
};

Prices.propTypes = {
  lineitem: PropTypes.object.isRequired,
  currency: PropTypes.string,
  fields: PropTypes.array.isRequired,
};

const AllowancesAndCharges = ({lineitem, currency, lineItemConfig}) => {
  if (lineitem.allowancesAndCharges.length < 1) {
    // https://gitlab.ecosio.com/code/frontend/issues/337
    if (lineItemConfig.amountCalculation === CALCULATION_TYPES.NONE) {
      return null;
    }

    return (
      <Fragment>
        <h3 style={{textAlign: 'center'}}>
          <FormattedMessage id="LE_AOC_EMPTY_STATE" />
        </h3>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <h3>
        <FormattedMessage id="GENERAL_ALLOWANCES_AND_CHARGES" />
      </h3>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="GENERAL_UNIT_PRICE" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="GENERAL_TOTAL_PRICE" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lineitem.allowancesAndCharges.map((item, idx) => (
            <Table.Row key={idx}>
              <Table.Cell textAlign="right" verticalAlign="top">
                <ConditionalCurrency
                  value={lineitem.currentItemPriceCalculationGross?.value}
                  currency={currency}
                />
                <AllowanceOrChanceItem currency={currency} item={item} />
              </Table.Cell>
              <Table.Cell textAlign="right" verticalAlign="top">
                <ConditionalCurrency
                  value={item.chargeBaseQuantity}
                  currency={currency}
                />

                <div style={styleAoc}>
                  {item.allowanceChargeQualifier === ALLOWANCE ? '-' : '+'}
                  <ConditionalCurrency
                    value={item.allowanceChargeAmount}
                    currency={currency}
                  />
                </div>

                {item.allowanceChargeQualifier === ALLOWANCE ? (
                  <ConditionalCurrency
                    value={item.chargeBaseQuantity - item.allowanceChargeAmount}
                    currency={currency}
                  />
                ) : (
                  <ConditionalCurrency
                    value={item.chargeBaseQuantity + item.allowanceChargeAmount}
                    currency={currency}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
};

AllowancesAndCharges.propTypes = {
  lineitem: PropTypes.object,
  currency: PropTypes.string,
  lineItemConfig: PropTypes.object,
};

const Deliveries = ({ordersScheduleLines, lineitem}) => {
  return (
    <Fragment>
      <h3>
        <FormattedMessage id="GENERAL_DELIVERIES" />
      </h3>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="GENERAL_DELIVERY_DATE" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="GENERAL_QUANTITY" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ordersScheduleLines.map((item, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>
                <FormatDate
                  stripTimezone
                  dateString={get(item, 'scheduledQuantityDate.dateTime')}
                />
              </Table.Cell>
              <Table.Cell>
                {item.scheduledQuantity}
                {SPACE}
                <FormattedMessage id={dynID(lineitem.measureUnitQualifier)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <strong>
                <FormattedMessage id="GENERAL_TOTAL_QUANTITY" />
              </strong>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <strong>
                {lineitem.orderedQuantity}
                {SPACE}
                <FormattedMessage id={dynID(lineitem.measureUnitQualifier)} />
              </strong>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

Deliveries.propTypes = {
  ordersScheduleLines: PropTypes.array,
  lineitem: PropTypes.object,
};

const SimpleOrderLineItemView = ({lineitem, fields}) => (
  <RenderContents initialValues={lineitem} fields={fields}>
    {() => (
      <Grid className="_si_ord_sl">
        <Content
          path="previouslyScheduledQuantity"
          render={() => (
            <Grid.Row data-spec="prev-qty">
              <Grid.Column width={4}>
                <FormattedMessage id="LE_PREV_QTY" />
              </Grid.Column>
              <Grid.Column width={6} textAlign="right">
                {lineitem.previouslyScheduledQuantity}
                {SPACE}
                <FormattedMessage id={dynID(lineitem.measureUnitQualifier)} />
              </Grid.Column>
            </Grid.Row>
          )}
        />
        <Content
          path="orderedQuantity"
          render={() => (
            <Grid.Row data-spec="ord-qty">
              <Grid.Column width={4}>
                <FormattedMessage id="ORDERED_QUANTITY" />
              </Grid.Column>
              <Grid.Column width={6} textAlign="right">
                {lineitem.orderedQuantity}
                {SPACE}
                <FormattedMessage id={dynID(lineitem.measureUnitQualifier)} />
              </Grid.Column>
            </Grid.Row>
          )}
        />
      </Grid>
    )}
  </RenderContents>
);

SimpleOrderLineItemView.propTypes = {
  lineitem: PropTypes.object,
  fields: PropTypes.object,
};

const FilteredContractReference = ({content, input, intl, filter}) => {
  if (typeof content === 'undefined' || content === null) {
    return null;
  }

  const valuePath = 'contractReference.referenceNumber';
  const value = filterPathFormArray(content, {filter}, valuePath).join(',');

  return (
    <div style={{display: 'inline-block', marginRight: '15px'}}>
      <strong>{intl.formatMessage({id: input.label})}</strong>
      <div>{value}</div>
      <br />
    </div>
  );
};

FilteredContractReference.propTypes = {
  content: PropTypes.string,
  input: PropTypes.object,
  intl: intlShape,
  filter: PropTypes.object,
};

const ModalImpl = ({
  open,
  fields,
  lineitem,
  lineItemConfig,
  currency,
  onClose,
}) => {
  return (
    <Modal open={open} size="large">
      <Header>
        <FormattedMessage
          id="LE_VIEW_MODAL_HEADER"
          values={{position: lineitem.positionNumber}}
        />
      </Header>
      <Modal.Content>
        <RenderContents initialValues={lineitem} fields={fields}>
          {() => {
            return (
              <Grid divided="vertically" container>
                <Grid.Column width={9}>
                  <Grid.Row>
                    <Content path="itemDescription" render={ItemComponent} />
                    <Content path="itemCategory" render={ItemComponent} />
                    <br />
                    <Content path="gtin" render={ItemComponent} />
                    <Content path="gtinpriceItem" render={ItemComponent} />
                    <Content
                      path="manufacturersArticleNumber"
                      render={ItemComponent}
                    />
                    <br />
                    <Content
                      path="customersItemMaterialNumber"
                      render={ItemComponent}
                    />
                    <Content
                      path="suppliersItemMaterialNumber"
                      render={ItemComponent}
                    />

                    <Content path="unitPriceBasis" render={ItemComponent} />
                    <Grid.Row>
                      <Content
                        path="references[0].contractReference.referenceNumber"
                        render={ContractReference}
                      />
                      <Content
                        path="references[0].contractReference.lineNumber"
                        render={ContractReference}
                      />
                    </Grid.Row>
                    <Grid.Row>
                      <Content
                        path="references[0].orderReference.referenceNumber"
                        render={ContractReference}
                      />
                      <Content
                        path="references[0].orderReference.lineNumber"
                        render={ContractReference}
                      />
                    </Grid.Row>
                    <Grid.Row>
                      <Content
                        path="references[0].externalOrderReference.referenceNumber"
                        render={ContractReference}
                      />
                      <Content
                        path="references[0].externalOrderReference.lineNumber"
                        render={ContractReference}
                      />
                    </Grid.Row>

                    <Content
                      path="references"
                      render={FilteredContractReference}
                    />

                    <Content
                      path="lineItemActionRequest"
                      render={LineItemActionRequest}
                    />

                    <Content
                      path="reasonOfReturn.value"
                      render={ItemComponent}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Content
                      path="countryOfOrigin.countryName"
                      render={ItemComponent}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Content
                      path="packagingType.value"
                      render={ItemComponent}
                    />
                    <Content
                      path="secondaryPackagingType.value"
                      render={ItemComponent}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Content
                      path="alternativeQuantity.value"
                      unit={lineitem?.alternativeQuantity?.unit}
                      render={ItemComponent}
                    />
                    <Content
                      path="ratioAlternativeQuantity"
                      render={ItemComponent}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Content
                      path="informativePrice.value"
                      unit={lineitem?.informativePrice?.unit}
                      render={ItemComponent}
                    />
                  </Grid.Row>

                  <Grid.Row>
                    <Content
                      path="promotion.referenceNumber"
                      render={ItemComponent}
                    />
                    <Content
                      path="promotion.referenceQualifier"
                      render={ItemComponent}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Content
                      path="promotion.date.dateTime"
                      render={ItemDateComponent}
                    />
                    <Content
                      path="promotion.endDate.dateTime"
                      render={ItemDateComponent}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Content path="caliber.value" render={ItemComponent} />
                  </Grid.Row>
                  <Grid.Row>
                    <Content path="batchNumber" render={ItemComponent} />
                    <Content path="lotNumber" render={ItemComponent} />
                  </Grid.Row>
                  <Grid.Row>
                    <Content
                      path="bestBeforeDate.dateTime"
                      render={ItemDateComponent}
                    />
                  </Grid.Row>
                  <FreeTextComponent
                    path="freeTexts"
                    fields={fields}
                    object={lineitem}
                  />

                  <BusinessEntityComponent
                    path="consignee"
                    object={lineitem}
                    fields={fields}
                  />
                </Grid.Column>
                <Grid.Column width={7}>
                  {lineItemConfig.lineItemsHaveScheduleLines ? (
                    <Deliveries
                      ordersScheduleLines={lineitem.ordersScheduleLines}
                      lineitem={lineitem}
                    />
                  ) : (
                    <SimpleOrderLineItemView
                      fields={fields}
                      ordersScheduleLines={lineitem.ordersScheduleLines}
                      lineitem={lineitem}
                    />
                  )}

                  {lineItemConfig.amountCalculation ===
                  'NO_CALCULATION' ? null : (
                    <Fragment>
                      <Prices
                        currency={currency}
                        lineitem={lineitem}
                        fields={fields}
                      />
                      <AllowancesAndCharges
                        currency={currency}
                        lineitem={lineitem}
                        lineItemConfig={lineItemConfig}
                      />
                    </Fragment>
                  )}
                  <br />
                  <Grid.Row>
                    <Content
                      path="packagingDetails.packagingCapacity"
                      render={ItemComponent}
                    />
                    <Content
                      path="packagingDetails.numberOfPackages"
                      render={ItemComponent}
                    />
                  </Grid.Row>
                  <br />
                  <Content path="freeTexts[0].text" render={ItemComponent} />
                </Grid.Column>
              </Grid>
            );
          }}
        </RenderContents>
      </Modal.Content>
      <Modal.Actions data-spec="modalAction">
        <Button
          color="purple"
          onClick={onClose}
          data-spec="closeButton-lin-modal">
          <FormattedMessage id="GENERAL_CLOSE" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalImpl.propTypes = {
  open: PropTypes.bool,
  fields: PropTypes.object,
  lineitem: PropTypes.object,
  lineItemConfig: PropTypes.object,
  onClose: PropTypes.func,
  currency: PropTypes.string,
};

const OrderLineItemViewer = ({
  lineitem,
  pageConfig,
  open,
  currency,
  onClose,
}) => {
  // it is possible that a document has no line items...
  if (typeof lineitem === 'undefined') {
    return null;
  }

  return (
    <ModalImpl
      open={open}
      onClose={onClose}
      lineitem={lineitem}
      currency={currency}
      fields={pageConfig.formFields}
      lineItemConfig={pageConfig.lineItemConfiguration}
    />
  );
};

OrderLineItemViewer.propTypes = {
  open: PropTypes.bool,
  currency: PropTypes.string,
  pageConfig: PropTypes.object,
  lineitem: PropTypes.object,
  onClose: PropTypes.func,
};

export default OrderLineItemViewer;
