import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';
import {pageConfigShape} from '../../../../../../../../shapes/scenarios';
import {ContentDivider, HeaderLineContainer} from '../StyledDivs';
import EditForm from './EditFormFields/EditForm';
import HeaderViewFields from './ViewFields/HeaderViewFields';

const OrderHeaderInformation = ({
  showReadonly,
  documentExchange,
  pageConfig,
  onSubmit,
  exposeHeaderValues,
  exposedValues,
}) => (
  <div data-spec="ordersHeader">
    <HeaderLineContainer>
      <div>
        <Header as="h4">
          <Msg id="GENERAL_ORDER_INFORMATION" />
        </Header>
      </div>
      <ContentDivider />
    </HeaderLineContainer>
    <div>
      <HeaderViewFields
        showAllFields={showReadonly}
        document={documentExchange?.document}
        pageConfig={pageConfig}
      />
      {!showReadonly ? (
        <EditForm
          documentExchange={documentExchange}
          onSubmit={onSubmit}
          pageConfig={pageConfig}
          exposeHeaderValues={exposeHeaderValues}
          exposedValues={exposedValues}
        />
      ) : (
        ''
      )}
    </div>
  </div>
);

OrderHeaderInformation.defaultProps = {
  showReadonly: false,
  exposeHeaderValues: () => {},
};

OrderHeaderInformation.propTypes = {
  showReadonly: PropTypes.bool,
  pageConfig: pageConfigShape.isRequired,
  documentExchange: PropTypes.object,
  onSubmit: PropTypes.func,
  exposeHeaderValues: PropTypes.func,
  exposedValues: PropTypes.object,
};

export default OrderHeaderInformation;
