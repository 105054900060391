import React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
/**
 * A SubmitButton Component for SettingsPage.
 * The negative margin-right is neccessary to get the correct bottom-right position in the form
 */

const StyledButton = styled(Button)`
  display: flex !important;
  justify-content: space-between !important;
  width: 160px;
`;

const ButtonWrapper = styled.div`
  display: flex !important;
  flex-direction: row-reverse !important;
  padding-top: 40px;
  margin-bottom: -20px;
  margin-right: -22px;
`;

const SubmitButton = (props) => {
  const {disableButton, buttonName, dataSpec, onClick} = props;
  return (
    <ButtonWrapper>
      <StyledButton
        data-spec={dataSpec}
        onClick={onClick}
        disabled={disableButton}
        loading={disableButton}>
        <FormattedMessage id={buttonName} />
        <Icon name="angle right" size="large" />
      </StyledButton>
    </ButtonWrapper>
  );
};

SubmitButton.defaultProps = {};

SubmitButton.propTypes = {
  dataSpec: PropTypes.string.isRequired,
  disableButton: PropTypes.bool.isRequired,
  buttonName: PropTypes.string.isRequired,
};

export default SubmitButton;
