/* eslint-disable react/no-array-index-key */
import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Dropdown} from 'semantic-ui-react';
import {DataGrid, intlShape} from '@ecosio/components';
import {FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import {cloneDeep, set, get} from 'lodash';
import jexl from 'jexl';
import {UnitPriceCell, TotalPriceCell} from '../../Order/LineItems/Table/Cells';
import {pageConfigShape} from '../../../../shapes/scenarios';
import FailSafeFormattedNumber from '../../../General/FailSaveFormattedNumber';
import {inMemoryValidatorShape} from '../../../../shapes/inMemoryValidatorShape';
import ValidationWarnIcon from '../../ValidationWarnIcon';
import {ALLOWANCE} from '../../../../constants';
import EditCell from './EditCell';
import buildVatOptions from './DocumentAocEditor/buildVatOptions';
import SerialNumberCell from './SerialNumbers/SerialNumberCell';
import InvoiceFooterRoundingRow from './InvoiceFooterRoundingRow';

const styleAoc = {color: '#99a4af'};

const VatRateSelector = injectIntl(
  ({pageConfig, vatrates, value, onChange, intl}) => {
    const customerSpecificTranslations =
      pageConfig?.customerSpecificTranslations;

    //when there are no vatrates configured, then don`t render the vatrate dropdown
    if (!vatrates) {
      return null;
    }
    const options = buildVatOptions(
      vatrates,
      value,
      intl,
      customerSpecificTranslations
    );

    const defaultValue = value
      ? value
      : vatrates.length === 1
      ? vatrates[0]
      : value === 0
      ? 0
      : undefined;
    return (
      <Dropdown
        data-spec="le-vat-rate-select"
        placeholder={intl.formatMessage({
          id: get(customerSpecificTranslations, 'INVOIC_VAT', 'INVOIC_VAT'),
        })}
        compact
        selection
        options={options}
        error={defaultValue === undefined ? true : false}
        value={defaultValue === undefined ? 0 : `${defaultValue}`}
        onChange={onChange}
      />
    );
  }
);

VatRateSelector.propTypes = {
  vatrates: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const checkDocAlcConfig = (docAlcConfig, document) => {
  if (docAlcConfig && !docAlcConfig?.hideDocAlcExpression) {
    return docAlcConfig?.hideDocAlc;
  }

  if (docAlcConfig && docAlcConfig?.hideDocAlcExpression) {
    return jexl.evalSync(`${docAlcConfig?.hideDocAlcExpression}`, document);
  }

  return false;
};

class InvoiceTable extends Component {
  constructor(props) {
    super(props);

    this.emptystate = {
      icon: 'times',
      header: this.props.intl.formatMessage({id: 'INVOIC_NO_LINS'}),
      subHeader: this.props.intl.formatMessage({id: 'INVOIC_NO_LINS_SUB'}),
    };

    this.state = {};
  }

  validate = (data) => {
    console.warn('TODO: validate', data);
    const errors = {};
    // invoiceDoc.invoiceDoc.map((item, idx) => {
    //   if (item.wat.array[0].test === 'negative') {
    //     set(errors, `invoiceDoc[${idx}].wat.array[0].test`, 'This is an Error');
    //   }
    // });

    if (Object.keys(errors).length === 0) {
      return undefined;
    }

    return errors;
  };

  inMemoryValidate = () => {
    const errors = {};
    const {inMemoryValidation} = this.props;

    if (inMemoryValidation?.hasErrors) {
      inMemoryValidation?.errorData.map((inMemoryErrorData) => {
        const inMemoryErrorDataKey = Object.keys(inMemoryErrorData)[0];
        const index = inMemoryErrorDataKey.indexOf('[');
        if (index !== -1) {
          const errorKey = inMemoryErrorDataKey.slice(index);
          set(
            errors,
            `data${errorKey}`,
            <ValidationWarnIcon
              tooltipData={inMemoryErrorData[inMemoryErrorDataKey]}
            />
          );
        }
      });
    }

    if (Object.keys(errors).length === 0) {
      return undefined;
    }

    return errors;
  };

  render() {
    const {
      fields,
      invoiceDoc,
      pageConfig,
      onRoundingChange,
      onOpenHeadAoc,
      onVatRateChange,
      onSerialNumberEdit,
      onDetail,
      onDupe,
      onDelete,
      vatrates,
      inMemoryValidation,
      docCurrency,
      intl,
    } = this.props;

    const customerSpecificTranslations =
      pageConfig?.customerSpecificTranslations;

    const staticConfig = [
      {
        id: 'unitprice',
        label:
          fields['currentItemPriceCalculationGross.value']?.input?.label ||
          'GENERAL_UNIT_PRICE',
        sort:
          fields['currentItemPriceCalculationGross.value']?.tableSort || 101,
        sortable: false,
        hidden:
          typeof fields['currentItemPriceCalculationGross.value'] ===
          'undefined',
        hideable: false,
        render: (Table, value, row) => {
          return (
            <UnitPriceCell
              lineItem={row}
              currency={docCurrency}
              dataSpec="unit-price"
            />
          );
        },
      },
      {
        id: 'totalprice',
        label:
          fields['currentItemPriceCalculationNet.value']?.input?.label ||
          'GENERAL_TOTAL_PRICE',
        sort: fields['currentItemPriceCalculationNet.value']?.tableSort || 103,
        sortable: false,
        hidden: false,
        hideable: false,
        render: (Table, value, row) => {
          return <TotalPriceCell lineItem={row} currency={docCurrency} />;
        },
      },
      {
        id: 'vatrate',
        label: fields['vatrate']?.input?.label || 'INVOICE_VAT',
        sortable: false,
        sort: fields['vatrate']?.tableSort || 103,
        hidden: typeof fields['vatrate'] === 'undefined',
        hideable: true,
        forceUpdate: false,
        render: (Table, novalue, row) => {
          return (
            <Table.Cell>
              <VatRateSelector
                row={row}
                vatrates={vatrates}
                pageConfig={pageConfig}
                value={row.vatrate}
                onChange={(e, {value}) => onVatRateChange(row, value)}
              />
            </Table.Cell>
          );
        },
      },
      {
        id: 'vatamount',
        label: fields['vatamount']?.input?.label || 'LE_VAT_AMOUNT',
        sortable: false,
        sort: fields['vatamount']?.tableSort || 104,
        hidden: typeof fields['vatamount'] === 'undefined',
        hideable: true,
        forceUpdate: false,
        render: (Table, vatamount) => {
          return (
            <Table.Cell>
              {vatamount && (
                <FormattedNumber
                  style="currency"
                  currency={docCurrency}
                  minimumFractionDigits={2}
                  value={vatamount}
                />
              )}
            </Table.Cell>
          );
        },
      },
      {
        id: '_actions',
        label: 'GENERAL_ACTIONS',
        sortable: false,
        sort: 999,
        hidden: false,
        hideable: false,
        forceUpdate: true,
        render: (Table, value, row, error, index) => {
          const clonedRow = cloneDeep(row);
          return (
            <EditCell
              pageConfig={pageConfig}
              idx={index}
              row={clonedRow}
              onDetail={onDetail}
              onDelete={() => onDelete(clonedRow)}
              onDupe={() => onDupe(clonedRow)}
              inMemoryValidation={inMemoryValidation}
            />
          );
        },
      },
    ];

    if (
      fields['materialCharacteristics.serialNumbers'] &&
      fields['materialCharacteristics.serialNumbers'].tableColumn
    ) {
      const snrCell = {
        id: 'serials',
        label:
          fields['materialCharacteristics.serialNumbers']?.input?.label ||
          'MUC_SERIALNUMBERS',
        sortable: false,
        sort: fields['materialCharacteristics.serialNumbers']?.tableSort || 10,
        hidden: false,
        hideable: false,
        render: (Table, value, row) => {
          const clonedRow = cloneDeep(row);
          return (
            <SerialNumberCell
              pageConfig={pageConfig}
              lineItem={clonedRow}
              onSerial={() => onSerialNumberEdit(clonedRow)}
            />
          );
        },
      };

      staticConfig.push(snrCell);
    }

    if (fields['invoicedQuantity'] && fields['invoicedQuantity'].tableColumn) {
      const invoicedQuantityCell = {
        id: fields['invoicedQuantity'].path,
        label: fields['invoicedQuantity'].input.label,
        render: (Table, value, row, error) => {
          return (
            <Table.Cell>
              {value}{' '}
              {row.measureUnit ? intl.formatMessage({id: row.measureUnit}) : ''}
              {error}
            </Table.Cell>
          );
        },
        sortable: false,
        hidden: false,
        hideable: false,
        sort: fields['invoicedQuantity']?.tableSort || 8,
      };

      staticConfig.push(invoicedQuantityCell);
    }

    const dynConfig = Object.keys(fields)
      .filter(
        (k) =>
          fields[k].tableColumn &&
          // non-literal fields have dedicated rendering components
          fields[k].path !== 'currentItemPriceCalculationGross.value' &&
          fields[k].path !== 'currentItemPriceCalculationNet.value' &&
          fields[k].path !== 'materialCharacteristics.serialNumbers' &&
          fields[k].path !== 'vatrate' &&
          fields[k].path !== 'vatamount' &&
          fields[k].path !== 'invoicedQuantity'
      )
      .map((k) => ({
        id: fields[k].path,
        label: fields[k].input.label,
        render: (Table, value, row, error) => {
          return (
            <Table.Cell>
              {value} {error}
            </Table.Cell>
          );
        },
        sortable: false,
        hidden: false,
        hideable: false,
        sort: fields[k].tableSort,
      }));

    const config = [...dynConfig, ...staticConfig];

    // we need the invoiceDoc prop here - so we cant externalize the components from render()
    const renderFooter = ({Table, cols}) => {
      const FooterRow = ({title, amount, side, intlValues, dataSpec}) => {
        return (
          <Table.Row data-spec={dataSpec}>
            <Table.HeaderCell
              colSpan={cols - 2}
              textAlign="right"
              data-spec="label">
              <strong>
                <FormattedMessage id={title} values={intlValues} />
              </strong>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={1} textAlign="right" data-spec="amount">
              <FailSafeFormattedNumber value={amount} currency={docCurrency} />
            </Table.HeaderCell>
            <Table.HeaderCell data-spec="side">{side}</Table.HeaderCell>
          </Table.Row>
        );
      };

      FooterRow.defaultProps = {
        intlValues: null,
      };

      FooterRow.propTypes = {
        intlValues: PropTypes.object,
      };

      const AocRow = ({data = [], taxable = 0}) => {
        return (
          <Table.Row>
            <Table.HeaderCell colSpan={cols - 2} textAlign="right">
              <strong>
                <FormattedMessage id="GENERAL_ALLOWANCES_AND_CHARGES" />
              </strong>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={1} textAlign="right">
              {data.map((aoc, idx) => {
                const sign =
                  aoc.allowanceChargeQualifier === ALLOWANCE ? '-' : '+';
                return (
                  <span key={`aoc_${idx}`}>
                    <FormattedNumber
                      value={aoc.chargeBaseQuantity}
                      style="currency"
                      currency={docCurrency}
                    />

                    <div style={styleAoc}>
                      {sign}
                      <FormattedNumber
                        value={aoc.allowanceChargeAmount}
                        style="currency"
                        currency={docCurrency}
                      />
                    </div>
                  </span>
                );
              })}
              <FormattedNumber
                value={data.length ? taxable : 0}
                style="currency"
                currency={docCurrency}
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={1}>
              <Button
                data-spec="aoc-doc-edit"
                size="mini"
                color="purple"
                onClick={onOpenHeadAoc}
                compact
                icon>
                <Icon name="pencil" />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        );
      };

      return (
        <Table.Footer>
          <FooterRow
            dataSpec="footer-sub-total"
            title="INVOIC_SUB_TOTAL"
            amount={
              invoiceDoc.footer.invoiceFooter.invoiceTotals.totalLineItemAmount
            }
          />

          {/**
           * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/427#note_213102
           */}
          {invoiceDoc.footer.invoiceFooter.invoiceTotals.totalLineItemAmount ===
            0 ||
          checkDocAlcConfig(
            pageConfig?.docLevelAlcConfiguration,
            invoiceDoc
          ) ? null : (
            <AocRow
              data={invoiceDoc.header?.invoiceHeader?.allowancesAndCharges}
              taxable={
                invoiceDoc.footer?.invoiceFooter?.invoiceTotals
                  ?.totalTaxableAmount
              }
            />
          )}

          {invoiceDoc.footer.invoiceFooter.vatrates.map((item, idx) => {
            return item.vatrate !== 0 &&
              item.vatrate !== '0' &&
              typeof item.vatrate !== 'undefined' ? (
              <FooterRow
                dataSpec={`footer-vat-${idx}`}
                key={`vatrate_${idx}`}
                title={get(
                  customerSpecificTranslations,
                  'INVOIC_FOOTER_VAT',
                  'INVOIC_FOOTER_VAT'
                )}
                intlValues={{vat: item.vatrate}}
                amount={item.vatamount}
              />
            ) : null;
          })}

          <InvoiceFooterRoundingRow
            onRoundingChange={onRoundingChange}
            cols={cols}
            pageConfig={pageConfig}
            doc={invoiceDoc}
          />

          <FooterRow
            dataSpec="footer-total"
            title="INVOIC_TOTAL"
            renderLabelIfValueUndefined
            amount={invoiceDoc.footer.invoiceFooter.invoiceTotals.invoiceAmount}
          />
        </Table.Footer>
      );
    };

    return (
      <Fragment>
        <div className="invoice-table">
          <DataGrid
            translated
            data-spec="invoice-table"
            data={invoiceDoc.details.invoiceData.invoiceLineItems}
            validationKey="data"
            //onError is mandatory for validation, gets validation errors , we do not need them for now
            onError={() => null}
            validate={() => this.inMemoryValidate()}
            config={{idSelector: 'positionNumber', fields: config}}
            emptyState={this.emptystate}
            renderFooter={renderFooter}
          />
        </div>
      </Fragment>
    );
  }
}

InvoiceTable.propTypes = {
  fields: PropTypes.object,
  invoiceDoc: PropTypes.object,
  pageConfig: pageConfigShape.isRequired,
  onRoundingChange: PropTypes.func.isRequired,
  onOpenHeadAoc: PropTypes.func.isRequired,
  onVatRateChange: PropTypes.func.isRequired,
  onSerialNumberEdit: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired,
  onDupe: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  vatrates: PropTypes.array,
  intl: intlShape,
  inMemoryValidation: inMemoryValidatorShape,
  docCurrency: PropTypes.string.isRequired,
};

export default injectIntl(InvoiceTable);
