export const isCreateTurnaroundButtonDisabled = (
  mappings,
  allDocumentDocTypes,
  targetDocType
) => {
  const targetMappings = mappings.filter(
    (mapping) => mapping.outboundDocType === targetDocType
  );

  if (targetMappings.length === 0) {
    return true;
  }

  const matchedDocTypes = allDocumentDocTypes.filter((docType) => {
    return (
      targetMappings.filter((mapping) => mapping.inboundDocType === docType)
        .length > 0
    );
  });

  return matchedDocTypes.length === 0;
};

export const shouldShowCreateDocumentButton = (scenario, outDocType) => {
  if (!scenario?.mappings) {
    console.warn(
      'No scenario or mappings received, will hide button',
      outDocType
    );
    return false;
  }
  return (
    scenario.mappings.filter(
      (mapping) => mapping.outboundDocType === outDocType
    ).length > 0
  );
};

export const getTurnaround = (scenario, inDocType, outDocType) => {
  if (!scenario.mappings) {
    return;
  }

  const filtered = scenario.mappings.filter((mapping) => {
    return (
      mapping.inboundDocType === inDocType &&
      mapping.outboundDocType === outDocType
    );
  });

  if (filtered.length !== 1) {
    console.warn(
      `Cannot create button config, no mapping found for ${inDocType}/${outDocType} on scenario ${scenario.uuid}`,
      scenario
    );
    return;
  }

  return filtered[0];
};

export const pageConfigForDocType = (scenario, docType) => {
  if (!scenario) {
    return;
  }

  const inbound = scenario.inboundWebDocTypes || [];
  const outbound = scenario.outboundWebDocTypes || [];

  // if an inboundMatch is found, it wins
  const inboundMatch =
    inbound.filter((item) => docType === item.documentTypeId).pop() || false;

  if (inboundMatch) {
    return inboundMatch;
  }

  const outBoundMatch =
    outbound.filter((item) => docType === item.documentTypeId).pop() || false;

  if (outbound) {
    return outBoundMatch;
  }
};

export const outboundPageConfigForDocType = (scenario, docType) => {
  if (!scenario) {
    return;
  }
  const outbound = scenario.outboundWebDocTypes || [];
  return (
    outbound.filter((item) => docType === item.documentTypeId).pop() || false
  );
};

/**
 * Matches docType against all pageConfigurations (inbound and outbound)
 * in the scenario. If it finds a pageConfiguration who's docType matches
 * docType, it will return the detailLayout of that pageConfiguration.
 *
 * @param scenario
 * @param docType or undefined if no match found
 */
export const detailLayoutForDocType = (scenario, docType) => {
  const pageConfig = pageConfigForDocType(scenario, docType);
  return pageConfig ? pageConfig.detailLayout : null;
};

/**
 * Checks if this formField configuration has a required lineItemActionRequest
 */
export const requiresLineItemConfirmation = (formFields) => {
  const actionRequestConfig = formFields['lineItemActionRequest'];
  return actionRequestConfig && actionRequestConfig.input.required === true;
};

export const isInboundDocType = (docType, scenario) => {
  const inbound = scenario.inboundWebDocTypes || [];
  // if an inboundMatch is found, it wins
  const inboundMatch =
    inbound.filter((item) => docType === item.documentTypeId).pop() || false;

  return inboundMatch !== false;
};

export const isOutboundDocType = (docType, scenario) => {
  const outbound = scenario.outboundWebDocTypes || [];
  // if an inboundMatch is found, it wins
  const outboundMatch =
    outbound.filter((item) => docType === item.documentTypeId).pop() || false;

  return outboundMatch !== false;
};

export const getInputDocumentTypesFromMappings = (scenario) => {
  return Array.from(
    new Set(scenario.mappings.map((turnaround) => turnaround.inboundDocType))
  );
};
