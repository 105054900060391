import React, {Fragment} from 'react';
import {Helmet} from 'react-helmet';
import {get} from 'lodash';
import {FormatDateTime} from '@ecosio/components';
import {Button, Icon, Message, Segment} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {STATUS_ARCHIVED} from '../../../../shapes/supportedEnvelopeStati';
import {DynamicMessage} from '../../../../helpers/translate';
import CustomPropertyEditor from '../../CustomPropertyEditor';
import DocumentHeader from '../../DocumentHeader';
import {SwitcherOrBackButton} from '../../SwitchDropdown';
import DownloadPdfButton from '../../Download/DownloadPdfButton';
import CreateDocumentButtonBar from '../../Turnaround/CreateDocumentButtonBar';
import OrderMeta from '../../Order/View/OrderMeta';
import DocumentMeta from '../../Order/View/DocumentMeta';
import DrawingDownloads from '../../Download/DrawingDownloads';
import {pageConfigShape, scenarioShape} from '../../../../shapes/scenarios';
import {documentExchangeShape} from '../../../../shapes/documentExchange';
import {StatusPopup} from '../../Order/View/OrdersTemplate';
import LineItemTable from '../LineItems/LineItemTable';
import InvoiceLineItemViewer from '../LineItems/InvoiceLineItemViewer';
import {DAILY_DOCUMENT} from '../../../../helpers/constants';

class ViewInvoiceTemplate extends React.Component {
  constructor(props) {
    super(props);

    const {envelope} = this.props;
    this.state = {
      dropdownItems: this.formatDropdownItems(
        this.props.envelope.data.selectableDocuments
      ),
      customProperties: this.formatCustomProperties(
        envelope.data.customProperties
      ),

      metaDocuments: envelope.data.metaDocuments,
      propertyEditor: {
        open: false,
      },
      invoiceLineItemViewer: {
        open: false,
        index: 0,
      },
    };
  }

  formatCustomProperties = (props) =>
    props.reduce((acc, item) => {
      acc[item.path] = item.value;
      return acc;
    }, {});

  onSelect = (e, {index}) => {
    this.setState({
      invoiceLineItemViewer: {
        open: true,
        index,
      },
    });
  };

  onSelectClose = () => {
    this.setState((prevState) => ({
      invoiceLineItemViewer: {
        ...prevState.invoiceLineItemViewer,
        open: false,
      },
    }));
  };

  onClickMetaDocument = (uuid) => {
    const {scenarioUuid, envelopeUuid} = this.props;
    this.props.history.push(
      `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${uuid}`
    );
  };

  // TODO: this could be extracted and reused
  formatDropdownItems = (data) => {
    return (
      data.map((item, idx) => ({
        key: idx,
        text: (
          <div>
            <DynamicMessage id={item.documentTypeId} />{' '}
            <DynamicMessage id="GENERAL_FROM" />{' '}
            <FormatDateTime stripTimezone={false} dateString={item.createdAt} />
          </div>
        ),
        value: item.uuid,
        content: (
          <div>
            <strong>
              <DynamicMessage id={item.documentTypeId} />
            </strong>
            <div>
              <FormatDateTime
                stripTimezone={false}
                dateString={item.createdAt}
              />
            </div>
          </div>
        ),
      })) || []
    );
  };

  render() {
    const {
      documentUuid,
      scenario,
      scenarioUuid,
      envelope,
      envelopeUuid,
      documentExchange,
      history,
    } = this.props;

    const pageConfig = documentExchange.pageConfig;
    const documentTypeId = get(documentExchange, 'data.meta.documentTypeId');
    const messageId = get(documentExchange, 'data.meta.erpelMessageId');
    const isArchived = envelope.data.status === STATUS_ARCHIVED;
    const lineItems =
      documentExchange?.data?.document?.details?.invoiceData
        ?.invoiceLineItems || [];
    const lineItem = lineItems.length
      ? lineItems[this.state.invoiceLineItemViewer.index]
      : {};

    let currency = get(
      documentExchange,
      'data.document.header.referenceCurrency'
    );

    if (!currency) {
      currency = '€';
    }

    const isDailyDocumentScenario = scenario?.listLayout === DAILY_DOCUMENT;

    return (
      <div className="_si_order_view">
        <Helmet>
          <title>ecosio GmbH</title>
        </Helmet>

        <CustomPropertyEditor
          correlatorId={envelope.data.correlatorId}
          open={this.state.propertyEditor.open}
          error={envelope.props.error}
          posting={envelope.props.posting}
          onSubmit={this.onSubmitPropertyEditor}
          onCancel={this.onTogglePropertyEditor}
          items={this.state.customProperties}
          formFields={scenario.customPropertiesConfig.formFields}
        />

        <InvoiceLineItemViewer
          open={this.state.invoiceLineItemViewer.open}
          onClose={this.onSelectClose}
          currency={currency}
          pageConfig={pageConfig}
          lineitem={lineItem}
        />
        <React.Fragment>
          <DocumentHeader
            messageId={messageId}
            envelope={envelope.data}
            pageConfig={pageConfig}
            scenario={scenario}
            documentExchange={documentExchange}
            metaDocuments={this.state.metaDocuments}
            correlatorId={envelope.data.correlatorId}
            envelopeStatus={envelope.data.status}
            customProperties={this.state.customProperties}>
            <Fragment>
              {!isArchived && scenario.customPropertiesConfig.enabled ? (
                <Button
                  type="button"
                  primary
                  icon
                  labelPosition="left"
                  onClick={this.onTogglePropertyEditor}>
                  <Icon name="edit" />
                  <FormattedMessage id="BUSINESSCASE_EDIT" />
                </Button>
              ) : null}
              <StatusPopup
                loading={envelope.status.posting}
                onArchive={this.onClickArchive}
                status={envelope.data.status}
                pageConfig={pageConfig}
              />
            </Fragment>
          </DocumentHeader>

          {isArchived ? (
            <Message warning>
              <Message.Header>
                <FormattedMessage id="ARCHIVED_BC_WARN_TITLE" />
              </Message.Header>
              <p>
                <FormattedMessage id="ARCHIVED_BC_WARN_CONTENT" />
              </p>
            </Message>
          ) : null}

          {envelope.status.error ? (
            <Message warning>
              <Message.Header>
                <FormattedMessage id="GENERAL_ERROR" />
              </Message.Header>
              <p>{envelope.status.error}</p>
            </Message>
          ) : null}

          <Segment attached="top" clearing>
            <SwitcherOrBackButton
              onChange={this.onChangeDropdown}
              items={this.state.dropdownItems}
              selected={documentUuid}
              scenarioUuid={scenarioUuid}
              envelopeUuid={envelopeUuid}
              documentUuid={documentUuid}
              isDailyDocumentScenario={isDailyDocumentScenario}
              history={history}
            />
            <Button.Group floated="right">
              <DownloadPdfButton
                dataSpec="createDocumentBar-download-button"
                currentDocumentUuid={documentUuid}
                metaDocuments={this.state.metaDocuments}
                envelopeUuid={envelopeUuid}
                scenario={scenario}
                currentDocumentTypeID={documentTypeId}
              />
              <CreateDocumentButtonBar
                deliveryDates={documentExchange.deliveryDates?.data}
                hide={false}
                envelopeStatus={envelope.data.status}
                scenario={scenario}
                envelopeUuid={envelopeUuid}
                envelope={envelope.data}
                documentUuid={documentUuid}
                docType={documentExchange.data.meta.documentTypeId}
                pageConfig={pageConfig}
              />
            </Button.Group>
          </Segment>
          <Segment attached>
            <div>
              <OrderMeta
                documentExchange={documentExchange.data}
                pageConfig={pageConfig}
                scenario={scenario}
              />
              <LineItemTable
                data={documentExchange.data.document}
                pageConfig={pageConfig}
                currency={currency}
                onSelect={this.onSelect}
              />
            </div>
          </Segment>
          <DocumentMeta
            metaDocuments={this.state.metaDocuments}
            onSelect={this.onClickMetaDocument}
            pageConfig={pageConfig}
            location={this.props?.history?.location}
          />
          <DrawingDownloads
            correlator={envelope.data.correlatorId}
            sender={envelope.data.offeringCompanyUuid}
            receiver={envelope.data.usingCompanyUuid}
          />
        </React.Fragment>
      </div>
    );
  }
}

ViewInvoiceTemplate.propTypes = {
  documentUuid: PropTypes.string,
  envelopeUuid: PropTypes.string,
  scenarioUuid: PropTypes.string,
  scenario: scenarioShape,
  envelope: PropTypes.object,
  history: PropTypes.object,
  documentExchange: PropTypes.shape({
    data: documentExchangeShape,
    pageConfig: pageConfigShape,
  }),
};

export default ViewInvoiceTemplate;
