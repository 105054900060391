import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Header, Popup, Segment, Form, Button, Select} from 'semantic-ui-react';
import qs from 'qs';
import {intlShape} from '@ecosio/components';

const DEFAULT_FILTER = {
  status: 'UPDATED,NEW,READ',
  search: '',
  sort: 'createdAt,desc',
};

const AdvancedPop = ({sortstate, onChangeSort, intl, ...props}) => {
  const ORDER = [
    {
      key: 'asc',
      text: intl.formatMessage({id: 'GENERAL_ASCENDING'}),
      value: 'asc',
    },
    {
      key: 'desc',
      text: intl.formatMessage({id: 'GENERAL_DESCENDING'}),
      value: 'desc',
    },
  ];

  const SORT = [
    {
      key: 'updatedAt',
      text: intl.formatMessage({id: 'DATE_CHANGED'}),
      value: 'updatedAt',
    },
    {
      key: 'createdAt',
      text: intl.formatMessage({id: 'DATE_CREATED'}),
      value: 'createdAt',
    },
  ];

  const [sortProp, sortValue] = sortstate.split(',');

  return (
    <Popup {...props} position="bottom right" on="click" wide>
      <div style={{minWidth: '600px'}}>
        <Segment>
          <Header as="h3">
            <Header.Content>
              <FormattedMessage id="GENERAL_SORTING" />
            </Header.Content>
          </Header>
          <Form>
            <Form.Group>
              <Form.Field>
                <Select
                  name="prop"
                  onChange={onChangeSort}
                  defaultValue={sortProp}
                  compact
                  options={SORT}
                  style={{width: '250px'}}
                />
              </Form.Field>

              <Form.Field>
                <Select
                  name="order"
                  onChange={onChangeSort}
                  defaultValue={sortValue}
                  compact
                  options={ORDER}
                  style={{width: '250px'}}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </div>
    </Popup>
  );
};

AdvancedPop.propTypes = {
  sortstate: PropTypes.string.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

class EnvelopeFilter extends Component {
  constructor(props) {
    super(props);

    const {location} = this.props;
    // qs returns an empty object at parseerror or empty string
    // substr needed for querystring indicator questionmark
    const searchObj =
      location.search && location.search.trim() !== ''
        ? qs.parse(this.props.location.search.substr(1))
        : {};

    this.state = Object.assign({}, DEFAULT_FILTER, searchObj);
  }

  trimSearchValue = (search) => {
    if (search?.trim().length === 0) {
      return undefined;
    }
    return search;
  };

  onApply = () => {
    // If search is null, undefined, or empty string - disable filtering by search text
    const sv = this.trimSearchValue(this.state.search);
    if (sv === undefined) {
      this.setState(
        {
          search: sv,
        },
        () => {
          this.props.history.push({
            search: `?${qs.stringify(this.state, {skipNulls: true})}`,
          });
        }
      );
    } else {
      this.props.history.push({
        search: `?${qs.stringify(this.state, {skipNulls: true})}`,
      });
    }
  };

  onChangeInput = (e) => {
    this.setState({
      search: e.target.value || null,
    });
  };

  onChangeDocType = (e, {value}) => {
    this.setState({
      status: value,
    });
  };

  onChangeSort = (e, {value, name}) => {
    /*
      Order of the querystringparams is always ?sort=Property,Order in Spring
      decide via the name of the forminput where what we have to set
    */
    const [prop, order] = this.state.sort.split(',');
    return this.setState({
      sort:
        name === 'prop' ? [value, order].join(',') : [prop, value].join(','),
    });
  };

  render() {
    const {intl} = this.props;
    const FILTERS = [
      {
        key: 'ALL',
        text: intl.formatMessage({id: 'GENERAL_NOT_ARCHIVED_DOCS'}),
        value: 'UPDATED,NEW,READ',
      },
      {
        key: 'ARCHIVED',
        text: intl.formatMessage({id: 'GENERAL_ARCHIVED_DOCS'}),
        value: 'ARCHIVED',
      },
    ];

    return (
      <Fragment>
        <Header as="h3">
          <Header.Content>
            <FormattedMessage id="ENVELOPE_FILTER_TITLE" />
          </Header.Content>
        </Header>
        <Form onSubmit={this.onApply} id="envelopes-filter">
          <Form.Input type="text" action>
            <input
              data-spec="envelopes-search"
              placeholder={intl.formatMessage({
                id: 'ENVELOPE_SEARCH_PLACEHOLDER',
              })}
              onChange={this.onChangeInput}
              value={this.state.search}
            />
            <Select
              data-spec="envelope-filter-documents-select-dropdown"
              onChange={this.onChangeDocType}
              compact
              options={FILTERS}
              value={this.state.status}
              style={{width: '250px'}}
            />
            <AdvancedPop
              intl={intl}
              onChangeSort={this.onChangeSort}
              sortstate={this.state.sort}
              trigger={
                <Button color="purple" type="button" data-spec="advanced">
                  <FormattedMessage id="GENERAL_ADVANCED" />
                </Button>
              }
            />
            <Button type="submit" positive data-spec="apply">
              <FormattedMessage id="GENERAL_APPLY" />
            </Button>
          </Form.Input>
        </Form>
      </Fragment>
    );
  }
}

EnvelopeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EnvelopeFilter);
