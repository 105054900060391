import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Header, Grid, Button} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Form, ExternalSubmit} from '@ecosio/pathform';

import AllowancesAndCharges from '../../../Order/LineItems/AllowancesAndCharges';
import FreeTextComponent from '../../../FreeTextComponent';
import invoiceDecorator from './invoiceDecorator';

import PriceInformation from './PriceInformation';
import InvoiceFormFields from './FormFields';

const EditorLayout = ({lineItem, itemConfig, fields, onSave, docCurrency}) => {
  const hideAoC =
    !itemConfig.allowAddingCharges &&
    !itemConfig.allowRemovingCharges &&
    !itemConfig.allowAddingAllowances &&
    !itemConfig.allowRemovingAllowances;

  return (
    <Form
      data-spec="line-item-form"
      decorators={[invoiceDecorator]}
      formid="InvoiceLineItemEditor"
      initialValues={lineItem}
      onSubmit={onSave}
      fields={fields}>
      {(ctx) => (
        <Grid divided data-spec="line-item-editor">
          <Grid.Row columns={2}>
            <Grid.Column>
              <InvoiceFormFields />
              <FreeTextComponent
                path="freeTexts"
                fields={fields}
                object={lineItem}
              />
            </Grid.Column>
            <Grid.Column>
              <PriceInformation lineItem={lineItem} currency={docCurrency} />
              {!hideAoC ? (
                <AllowancesAndCharges
                  lineItemAmount={ctx?.form?.getState()?.values?.lineItemAmount}
                  push={ctx.form.mutators.push}
                  currency={docCurrency}
                  lineItemConfig={itemConfig}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Form>
  );
};

EditorLayout.propTypes = {
  fields: PropTypes.object,
  itemConfig: PropTypes.object,
  lineItem: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  docCurrency: PropTypes.string.isRequired,
};

const InvoiceLineItemEditor = ({
  lineItem,
  pageConfig,
  onCancel,
  onSave,
  docCurrency,
}) => {
  return (
    lineItem && (
      <Modal open size="large">
        <Header>
          <FormattedMessage
            id="LE_EDIT_MODAL_HEADER"
            values={{position: lineItem.positionNumber}}
          />
        </Header>
        <Modal.Content>
          <EditorLayout
            lineItem={lineItem}
            itemConfig={pageConfig.lineItemConfiguration}
            fields={pageConfig.formFields}
            onSave={onSave}
            docCurrency={docCurrency}
          />
        </Modal.Content>
        <Modal.Actions>
          <ExternalSubmit
            formid="InvoiceLineItemEditor"
            color="green"
            form="InvoiceLineItemEditor"
            content={<FormattedMessage id="GENERAL_SAVE" />}
          />
          <Button
            color="red"
            onClick={onCancel}
            type="button"
            data-spec="cancel">
            <FormattedMessage id="GENERAL_CANCEL" />
          </Button>
        </Modal.Actions>
      </Modal>
    )
  );
};

export default InvoiceLineItemEditor;
