import {combineReducers} from 'redux';
import {reducer as locales} from '@ecosio/locales';
import {configReducer as config} from '@ecosio/auth';
import {connectRouter} from 'connected-react-router';
import {reducer as scenariosList} from './scenariosList';
import {reducer as envelopesList} from './envelopesList';
import {reducer as envelope} from './envelope';
import {reducer as documentExchange} from './documentExchange';
import {reducer as orderResponse} from './turnaround'; // TODO: PETE - refactor to "turnaround" ? to catch this did me cost alot of time
import {reducer as fileDownload} from './fileDownload';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    locales,
    scenariosList,
    envelope,
    envelopesList,
    documentExchange,
    orderResponse,
    config,
    fileDownload,
  });
