/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {isEmpty} from 'lodash';
import {priceShape} from '../../../../../../shapes/industryDocument';
import {ALLOWANCE, PERCENTAGE} from '../../../../../../constants';

const UnitPriceCell = ({
  lineItem,
  currency,
  styleAoc = {color: '#99a4af'},
  dataSpec,
}) => {
  const Discount = () => {
    if (isEmpty(lineItem.allowancesAndCharges)) {
      return null;
    }

    return lineItem.allowancesAndCharges.map((aoc, idx) => {
      return aoc.allowanceChargeTypeCoded === PERCENTAGE ? (
        <div style={styleAoc} key={`aocUnitPrice_${idx}`}>
          {aoc.allowanceChargeQualifier === ALLOWANCE ? '-' : '+'}
          <FormattedNumber
            style="percent"
            minimumFractionDigits={2}
            value={aoc.percentageOfAllowanceOrCharge / 100}
          />
          <br />
          <br />
        </div>
      ) : (
        <span key={`aocUnitPrice_${idx}`}>
          <br /> <br />
        </span>
      );
    });
  };

  return (
    <Table.Cell verticalAlign="top" textAlign="right" data-spec={dataSpec}>
      <div>
        <FormattedNumber
          style="currency"
          currency={currency}
          minimumFractionDigits={2}
          value={lineItem.currentItemPriceCalculationGross?.value}
        />{' '}
      </div>
      <div>
        <FormattedMessage
          id="LE_PER"
          values={{value: lineItem.unitPriceBasis}}
        />{' '}
        <FormattedMessage id={lineItem?.measureUnit} />
      </div>

      <Discount />
    </Table.Cell>
  );
};
export default UnitPriceCell;
UnitPriceCell.propTypes = {
  dataSpec: PropTypes.string,
  styleAoc: PropTypes.object,
  currency: PropTypes.string,
  lineItem: PropTypes.shape({
    currentItemPriceCalculationGross: priceShape,
    allowancesAndCharges: PropTypes.arrayOf(
      PropTypes.shape({
        allowanceChargeTypeCoded: PropTypes.string,
        allowanceChargeQualifier: PropTypes.string,
        percentageOfAllowanceOrCharge: PropTypes.number,
      })
    ),
  }),
};
