import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Form, Input as SemanticInput, Dropdown} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Field} from 'react-final-form';
import {ErrorLabel} from '@ecosio/pathform';
import {aocItemShape, semanticOptionShape} from '../shapes';
import {ABSOLUTE, ALLOWANCE, PERCENTAGE} from '../../../../../../constants';

const percentageOrAbsolute = (currency) => [
  {key: ABSOLUTE, text: currency, value: ABSOLUTE},
  {key: PERCENTAGE, text: '%', value: PERCENTAGE},
];

const AllowanceOrChargeLabel = ({item, currency}) => {
  return (
    <label
      style={{fontWeight: 'normal', fontStyle: 'italic'}}
      className="_si_aoc_label">
      {item.allowanceChargeQualifier === ALLOWANCE ? (
        <FormattedMessage
          id="LE_ALLOWANCE_AMOUNT"
          values={{amount: item.allowanceChargeAmount}}
        />
      ) : (
        <FormattedMessage
          id="LE_CHARGE_AMOUNT"
          values={{amount: item.allowanceChargeAmount}}
        />
      )}{' '}
      {currency}
    </label>
  );
};

AllowanceOrChargeLabel.propTypes = {
  item: PropTypes.object,
  currency: PropTypes.string.isRequired,
};

const aocFieldValidation = (value) => {
  if (!value && value !== 0) {
    return 'GENERAL_REQUIRED_FIELD';
  }

  if (value < 0) {
    return 'GENERAL_NO_NEGATIVE_NUMBERS_OR_PERCENTAGE';
  }

  return undefined;
};

const AocField = ({item, currency, options, name, index}) => {
  const fieldname =
    item.allowanceChargeTypeCoded === PERCENTAGE
      ? `${name}.percentageOfAllowanceOrCharge`
      : `${name}.allowanceChargeAmount`;

  const optionCount = Array.isArray(options) ? options.length : 0;

  return (
    <Form.Field data-spec={`aoc-field-${index}`}>
      <AllowanceOrChargeLabel item={item} currency={currency} />
      <Field
        name={fieldname}
        validate={aocFieldValidation}
        render={({input, meta, ...rest}) => (
          <Fragment>
            <SemanticInput
              {...input}
              {...rest}
              type="number"
              step="0.01"
              labelPosition="right"
              size="mini"
              required
              placeholder="Allowance or Charge">
              <Field
                name={`${name}.allowanceChargeQualifier`}
                render={({input, ...rest}) => {
                  return (
                    <Dropdown
                      {...input}
                      {...rest}
                      onChange={(e, {value}) => {
                        e.preventDefault();
                        input.onChange(value);
                      }}
                      disabled={optionCount <= 1}
                      options={options}
                      className="label _si_aoc_sel"
                    />
                  );
                }}
              />

              <input />
              <Field
                name={`${name}.allowanceChargeTypeCoded`}
                render={({input, ...rest}) => {
                  return (
                    <Dropdown
                      {...input}
                      {...rest}
                      onChange={(e, {value}) => {
                        e.preventDefault();
                        input.onChange(value);
                      }}
                      options={percentageOrAbsolute(currency)}
                      className="label _si_aoc_typ"
                    />
                  );
                }}
              />
            </SemanticInput>
            {(meta.error || meta.submitError) && meta.touched && (
              <ErrorLabel error={meta.error || meta.submitError} />
            )}
          </Fragment>
        )}
      />
    </Form.Field>
  );
};

AocField.propTypes = {
  currency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  item: aocItemShape,
  options: semanticOptionShape,
  index: PropTypes.number,
};

export default AocField;
