import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Loader, Message, Segment} from 'semantic-ui-react';
import {get, set, cloneDeep} from 'lodash';
import {doInMemoryValidation} from '@ecosio/pathform';
import axios from 'axios';
import {intlShape, toast} from '@ecosio/components';
import DocumentHeader from '../../DocumentHeader';
import {
  fetchTurnaroundDocument,
  postDocumentToEnvelope,
} from '../../../../reducers/turnaround';
import InvoiceCalculator from '../../../../calculations/InvoiceCalculator';
import {pageConfigShape, scenarioShape} from '../../../../shapes/scenarios';
import {envelopesShape} from '../../../../shapes/envelopes';
import {INDUSTRY_INVOICE} from '../../../../shapes/supportedDocTypes';
import {
  ContextWrapper,
  HEADER_FORM_ID,
  HeaderMetaContext,
} from '../../Order/View/Header/DocumentHeadEditForm';
import OrderMeta from '../../Order/View/OrderMeta';
import {submitForm} from '../../../../util/formUtil';
import {
  COMPLEX_ENRICHMENT_DEFAULT_INTERNAL_VAT_VARIANCE_CODE,
  CURRENCY_EUR,
  DRAFTS_DELAY_TIME,
  DRAFTS_DELAY_TIME_IN_MS_FALLBACK,
} from '../../../../constants';
import {
  DAILY_DOCUMENT,
  STANDARD_TOAST_DURATION,
} from '../../../../helpers/constants';
import {preprocessInvoice} from '../../../../preprocessors/preProcessInvoice';
import {exportErrorMessageTranslationKeyFromHTTPResponse} from '../../../../util/httpUtil';
import {jexlWithTransformFuctions} from '../../../../util/jexlWithTransformFunctions';
import {
  deleteDraftWithUuid,
  saveDocumentDraftWithScheduledDate,
  saveDocumentDraftWithSendingDelay,
} from '../../../DocumentDrafts/draftRestCalls';
import DocumentDraftInfoHeader from '../../../DocumentDrafts/DocumentDraftInfoHeader';
import {
  isSendingDelayFromWebEdiSettingsEnabled,
  triggerDelayErrorToast,
} from '../../../DocumentDrafts/draftUtils';
import InvoiceLineItemEditor from './InvoiceLineItemEditor';
import InvoiceTable from './InvoiceTable';
import DocumentAocEditor from './DocumentAocEditor';
import DocumentSendConfirm from './DocumentSendConfirm';
import SerialNumberModal from './SerialNumbers/SerialNumberModal';
import './InvoiceTemplate.css';

const VAT_VARIANT_PATH = 'header.invoiceHeader.internalVATVarianceCode';

const Invoice = new InvoiceCalculator();

const IglErrorMessage = () => (
  <Message error>
    <FormattedMessage id="INVOICE_IGL_HEAD_VAT_INVALID" />
  </Message>
);

const GenericInvalidDocument = () => {
  return (
    <Message error data-spec="validation-error">
      <FormattedMessage id="GENERAL_DOCUMENT_NOT_VALID" />
    </Message>
  );
};

const mapDispatchToProps = (dispatch) => ({
  postInvoice: (uuid, content, actionType, scenarioUuid, scenarioChecksum) =>
    dispatch(
      postDocumentToEnvelope(
        uuid,
        content,
        actionType,
        scenarioUuid,
        scenarioChecksum
      )
    ),
  fetchTurnaround: (scenario, envelopeUuid, documentUuid, outboundDocType) => {
    return dispatch(
      fetchTurnaroundDocument(
        scenario,
        envelopeUuid,
        documentUuid,
        outboundDocType
      )
    );
  },
});

const isVatVariantConfigured = (config) => {
  return config.formFields[VAT_VARIANT_PATH];
};

const isIglSet = (document) => {
  return get(document, VAT_VARIANT_PATH) === 'VAT_IGL';
};

const filterVatVariant = (pageConfig = {}, documentExchange = {}) => {
  const savedVatVarianceCode = get(
    documentExchange,
    'data.document.header.invoiceHeader.internalVATVarianceCode'
  );

  let vatVariant = pageConfig?.vatSelectorConfig[savedVatVarianceCode];

  if (Object.keys(pageConfig?.vatSelectorConfig).length === 0) {
    throw new Error('No vatSelectorConfig found, cannot render invoice');
  }

  // default to this one if it's only a single one
  if (!vatVariant && Object.keys(pageConfig?.vatSelectorConfig).length === 1) {
    const key = Object.keys(pageConfig.vatSelectorConfig)[0];
    vatVariant = pageConfig.vatSelectorConfig[key];
  }

  //will work for non hagebau
  if (
    !vatVariant &&
    Object.keys(pageConfig.vatSelectorConfig).find(
      (key) => key === COMPLEX_ENRICHMENT_DEFAULT_INTERNAL_VAT_VARIANCE_CODE
    )
  ) {
    vatVariant =
      pageConfig.vatSelectorConfig[
        COMPLEX_ENRICHMENT_DEFAULT_INTERNAL_VAT_VARIANCE_CODE
      ];
  }

  return vatVariant;
};

/*
  TODO:
  - Inject Currency (currently everywhere EUR is used)
  - Test the Math in InvoiceCalculator
  - VAT Dropdowns
  - Tests
*/

class InvoiceTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      documentExchange: {},
      editLineItem: null,
      editSerialNumbers: null,
      editHeadAoc: false,
      vatRates: [],
      vatVariant: null,
      hideVatCols: false,
      inMemoryValidation: {
        hasErrors: false,
        errorData: [],
      },
      preventSendingOfDocument: false,
      currentDraftData: null,
    };
  }

  async componentDidMount() {
    const {
      match,
      scenario,
      fetchTurnaround,
      scenarioUuid,
      location,
      pageConfig,
    } = this.props;

    //pass pageConfig to invoiceCalculator for configbased calculation
    Invoice.setPageConfig(pageConfig);

    if (location?.state?.dailyDocumentLineItems) {
      await axios
        .post(
          `/api/dailyDocuments/scenario/${scenarioUuid}/lineItems/turnaround`,
          location?.state?.dailyDocumentLineItems,
          {
            params: {
              envelopeUuid: location?.state?.envelopeUuid,
              browserScenarioUuid: scenario?.uuid,
              browserScenarioConfigChecksum: scenario?.scenarioChecksum,
            },
          }
        )
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }

          preprocessInvoice(res?.data?.document);
          this.setState({
            documentExchange: Invoice.setDocument(res).getDocument(),
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({loading: false, error: err});
          console.error(err);
        });
    } else if (location?.state?.draftDocumentExchange) {
      const vatVariant = filterVatVariant(this.props.pageConfig, {
        data: location?.state?.documentExchange,
      });
      const vatRates = vatVariant?.vatrates ? vatVariant.vatrates : [];
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        documentExchange: Invoice.setDocument({
          data: location?.state?.draftDocumentExchange,
        }).getDocument(),
        currentDraftData: location?.state?.draftData,
        loading: false,
        vatRates: vatRates,
      });
    } else {
      await fetchTurnaround(
        scenario,
        match.params.envelopeUuid,
        match.params.documentUuid,
        match.params.documentType
      )
        // eslint-disable-next-line radar/no-identical-functions
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.setState({
            documentExchange: Invoice.setDocument(res).getDocument(),
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({loading: false, error: err});
          console.error(err);
        });
    }
    await this.runInMemoryValidation();

    this.triggerCalculationsWhenVatVariantValueIsPreFilled();
  }

  componentDidUpdate() {
    const {pageConfig} = this.props;

    if (pageConfig?.docPreventSendingConfiguration?.preventSendingExpression) {
      this.checkIfSendingShouldBePrevented();
    }
  }

  checkIfSendingShouldBePrevented = () => {
    const {documentExchange, preventSendingOfDocument} = this.state;
    const {intl, pageConfig} = this.props;
    const jexlContext = documentExchange?.data || {};
    const expression =
      pageConfig?.docPreventSendingConfiguration?.preventSendingExpression;

    const preventSending = jexlWithTransformFuctions(
      `${expression}`,
      jexlContext
    );
    if (preventSending) {
      toast({
        title: intl.formatMessage({
          id: 'INVOICE_TOTAL_INVOICE_AMOUNT_ZERO_PREVENTED_SENDING_TITLE',
        }),
        description: intl.formatMessage({
          id: 'INVOICE_TOTAL_INVOICE_AMOUNT_ZERO_PREVENTED_SENDING_DESCRIPTION',
        }),
        type: 'warning',
      });
      if (!preventSendingOfDocument) {
        this.setState({
          preventSendingOfDocument: true,
        });
      }
    } else {
      if (preventSendingOfDocument) {
        toast({
          title: intl.formatMessage({
            id: 'INVOICE_TOTAL_INVOICE_AMOUNT_NOT_ZERO_SENDING_ALLOWED_TITLE',
          }),
          description: intl.formatMessage({
            id:
              'INVOICE_TOTAL_INVOICE_AMOUNT_NOT_ZERO_SENDING_ALLOWED_DESCRIPTION',
          }),
          type: 'success',
          time: STANDARD_TOAST_DURATION,
        });
        this.setState({
          preventSendingOfDocument: false,
        });
      }
    }
  };

  /**
   * Triggers invoiceCalculation with correct varVariant and vatrates values
   * this is useful when the vatVariant is prefilled through enrichment
   */

  triggerCalculationsWhenVatVariantValueIsPreFilled = () => {
    const {pageConfig} = this.props;
    const {documentExchange} = this.state;
    const vatVariant = filterVatVariant(pageConfig, documentExchange);
    this.setVatVariantAndVatrates(vatVariant);
  };

  onSaveHeadAoc = async (submittedDocument) => {
    const newDocument = Invoice.setDocument(submittedDocument).getDocument();
    this.setState({
      documentExchange: newDocument,
      editHeadAoc: false,
    });
    await this.runInMemoryValidation();
  };

  onCancelHeadAoc = () => {
    this.setState({
      editHeadAoc: false,
    });
  };

  onOpenHeadAoc = () => {
    this.setState({
      editHeadAoc: true,
    });
  };

  onDetailCancel = () => {
    this.setState({
      editLineItem: null,
      editSerialNumbers: null,
    });
  };

  onSerialNumberEdit = (lineItem) => {
    this.setState({
      editSerialNumbers: lineItem,
    });
  };

  onDetailLineItem = (lineItem) => {
    this.setState({
      editLineItem: lineItem,
    });
  };

  onDetailSave = async (lineItem) => {
    const newDocument = Invoice.setLineItem(
      lineItem.positionNumber,
      () => lineItem
    ).getDocument();

    this.setState({
      documentExchange: newDocument,
      editLineItem: null,
      editSerialNumbers: null,
    });
    await this.runInMemoryValidation();
  };

  onRoundingChange = async (values) => {
    const amount = parseFloat(
      values?.footer?.simpleTotalInvoiceAmountRoundingAmount
    );

    if (isNaN(amount)) {
      // TODO log warn?
      return;
    }

    const newDocument = Invoice.setRoundingAmount(amount).getDocument();

    this.setState({
      documentExchange: newDocument,
    });

    await this.runInMemoryValidation();
  };

  onVatRateChange = (lineItem, vatrate) => {
    lineItem.vatrate = vatrate;

    const newDocument = Invoice.setLineItem(
      lineItem.positionNumber,
      () => lineItem
    ).getDocument();

    this.setState({
      documentExchange: newDocument,
    });
  };
  onDupeLineItem = (lineItem) => {
    const newDocument = Invoice.setLineItem(null, () => lineItem)
      .reIndexLineItems()
      .getDocument();

    this.setState({
      documentExchange: newDocument,
    });
  };

  onDeleteLineItem = (lineItem) => {
    const newDocument = Invoice.removeLineItem(lineItem.positionNumber)
      .reIndexLineItems()
      .getDocument();

    this.setState({
      documentExchange: newDocument,
    });
  };

  onSaveMeta = (values) => {
    return new Promise((resolve) => {
      const copy = Object.assign({}, this.state.documentExchange);

      const newHeader = Object.assign(
        {},
        values?.header || copy.data?.document?.header
      );

      this.setState(
        {
          documentExchange: set(copy, 'data.document.header', newHeader),
        },
        () => {
          resolve();
          // don't block the save operation of the header form here, we only want to make sure the
          // in-memory validation is in sync
          this.runInMemoryValidation();
        }
      );
    });
  };

  onVatVariantChange = (e, value) => {
    const {pageConfig} = this.props;

    const vatVariant = pageConfig.vatSelectorConfig[value];
    this.setVatVariantAndVatrates(vatVariant);
  };

  setVatVariantAndVatrates = (vatVariant) => {
    const vatRates = vatVariant?.vatrates ? vatVariant.vatrates : [];
    this.setState(() => {
      const newDocument = Invoice.resetLineItemVats(vatVariant).getDocument();

      if (vatVariant?.regulatoryInformation) {
        newDocument.data.document.header.invoiceHeader.regulatoryInformation =
          vatVariant.regulatoryInformation;
      }

      /**
       * Set vatVariance code here.
       * Except when vatVariantCode is Hagebau default value
       */
      if (
        vatVariant?.internalVATVarianceCode &&
        vatVariant?.internalVATVarianceCode !==
          COMPLEX_ENRICHMENT_DEFAULT_INTERNAL_VAT_VARIANCE_CODE &&
        !newDocument?.data?.document?.header?.invoiceHeader
          ?.internalVATVarianceCode
      ) {
        newDocument.data.document.header.invoiceHeader.internalVATVarianceCode =
          vatVariant?.internalVATVarianceCode;
      }

      return {
        documentExchange: newDocument,
        vatRates,
        vatVariant,
      };
    });
  };

  onCancelDocument = (e) => {
    e.preventDefault();
    const {match, scenarioUuid, history, scenario} = this.props;
    const envelopeUuid = match.params.envelopeUuid;
    const isDailyDocument = scenario?.listLayout === DAILY_DOCUMENT;

    if (isDailyDocument) {
      history.goBack();
    } else {
      history.push(`/scenario/${scenarioUuid}/envelope/${envelopeUuid}`);
    }
  };

  onSubmitDocument = async (canSubmit, actionType) => {
    const {history, scenarioUuid, scenario, pageConfig, location} = this.props;
    if (!canSubmit) {
      submitForm(HEADER_FORM_ID);
      return;
    }

    const doc = Invoice.getDocument();
    const envelopeUuid =
      this.props.envelope?.data?.uuid || location?.state?.envelopeUuid;

    if (!envelopeUuid) {
      console.error('Unable to retrieve envelope uuid');
      return;
    }

    const invoice = doc?.data?.document;

    if (!invoice) {
      console.error('Unable to obtain invoice.');
    }

    const documentExchange = {
      meta: this.state.documentExchange.data.meta,
      document: invoice,
    };

    this.setState({loading: true});

    //validate on document post
    await this.runInMemoryValidation();
    if (!this.state.inMemoryValidation.hasErrors) {
      this.props
        .postInvoice(
          envelopeUuid,
          documentExchange,
          actionType?.type,
          scenarioUuid,
          scenario?.scenarioChecksum
        )
        .then((res) => {
          const erpelMessageId = res?.documentMetadata?.erpelMessageId;
          const isDailyDocument = scenario?.listLayout === DAILY_DOCUMENT;

          let pathToRedirect = `/scenario/${scenarioUuid}/envelope/${envelopeUuid}`;

          if (
            scenario?.redirectToEnvelopeListPageOnArchive &&
            actionType?.type === 'SEND_AND_ARCHIVE'
          ) {
            pathToRedirect = `/scenario/${scenarioUuid}`;
          }

          if (isDailyDocument) {
            pathToRedirect = `/scenario/${scenarioUuid}/dailyDocuments`;
          }

          history.push({
            pathname: pathToRedirect,
            state: {
              message: 'GENERAL_DOCUMENT_SENT',
              status: 'info',
              erpelMessageId: erpelMessageId,
              downloadButtonData: {
                downloadConfigExists: pageConfig?.downloadConfigurations.length
                  ? true
                  : false,
                currentDocumentUuid: res?.documentMetadata?.uuid,
                envelopeUuid: envelopeUuid,
                scenario: scenario,
                currentDocumentTypeID: res?.documentMetadata?.documentTypeId,
              },
            },
          });
        })
        .catch((err) => {
          let errDescTranslationKey = exportErrorMessageTranslationKeyFromHTTPResponse(
            err
          );
          if (errDescTranslationKey === undefined) {
            errDescTranslationKey =
              'UPLOAD_AND_SEND_UNSUCCESSFUL_BACKEND_ERROR_DESCRIPTION';
          }
          toast({
            title: this.props.intl.formatMessage({
              id: 'UNABLE_TO_SEND_DOCUMENT',
            }),
            description: this.props.intl.formatMessage({
              id: errDescTranslationKey,
            }),
            type: 'error',
            time: STANDARD_TOAST_DURATION,
          });

          this.setState({loading: false});
          console.error(err);
        });
    } else {
      this.setState({loading: false});
    }
  };

  runInMemoryValidation = async () => {
    const {pageConfig} = this.props;
    const {documentExchange} = this.state;

    const allFields = pageConfig.formFields;
    const fieldConfig =
      pageConfig?.invoiceFooterRoundingField?.invoiceFooterRoundingFormField;
    if (fieldConfig) {
      allFields[fieldConfig.path] = fieldConfig;
    }

    await doInMemoryValidation(allFields, documentExchange.data.document)
      .then((data) => {
        this.setState({
          inMemoryValidation: {
            hasErrors: false,
            errorData: data,
          },
        });
      })
      .catch((errorData) => {
        this.setState({
          inMemoryValidation: {
            hasErrors: true,
            errorData: errorData,
          },
        });
      });
  };

  saveInvoiceDraftWithDefaultDelay = async (canSubmit = false) => {
    const {
      history,
      scenarioUuid,
      scenario,
      envelope,
      draftSettings,
    } = this.props;

    if (!canSubmit) {
      submitForm(HEADER_FORM_ID);
      return;
    }

    const {currentDraftData} = this.state;
    const webediSettings = draftSettings?.webediSettings || [];

    const defaultDelayTimeObjectFromSettings = webediSettings.find(
      (setting) => setting?.type === DRAFTS_DELAY_TIME
    );

    const defaultDelayTime = defaultDelayTimeObjectFromSettings?.value
      ? Number(defaultDelayTimeObjectFromSettings?.value)
      : DRAFTS_DELAY_TIME_IN_MS_FALLBACK; //15min

    const documentExchange = {
      meta: this.state.documentExchange.data.meta,
      document: this.state.documentExchange.data.document,
    };

    const documentNumber =
      documentExchange?.document?.header?.beginningOfMessage?.documentNumber;

    //validate on document post
    await this.runInMemoryValidation();
    if (!this.state.inMemoryValidation.hasErrors) {
      saveDocumentDraftWithSendingDelay(
        scenarioUuid,
        envelope?.data?.uuid,
        documentExchange,
        documentNumber,
        defaultDelayTime,
        scenario?.scenarioChecksum,
        currentDraftData?.uuid
      )
        .then(() =>
          history.push(
            `/scenario/${scenarioUuid}/envelope/${envelope?.data?.uuid}`
          )
        )
        .catch((error) => {
          console.error(
            'Failed, saving invoice document draft with default delay',
            error
          );
          triggerDelayErrorToast(error, this.props.intl);
        });
    }
  };

  saveInvoiceDraftWithScheduledDate = async (
    canSubmit = false,
    scheduledDate
  ) => {
    const {history, scenarioUuid, scenario, envelope} = this.props;

    if (!canSubmit) {
      submitForm(HEADER_FORM_ID);
      return;
    }

    //validate on document post
    await this.runInMemoryValidation();
    if (!this.state.inMemoryValidation.hasErrors) {
      const {currentDraftData} = this.state;

      const documentExchange = {
        meta: this.state.documentExchange.data.meta,
        document: this.state.documentExchange.data.document,
      };

      const documentNumber =
        documentExchange?.document?.header?.beginningOfMessage?.documentNumber;

      if (scheduledDate) {
        saveDocumentDraftWithScheduledDate(
          scenarioUuid,
          envelope?.data?.uuid,
          documentExchange,
          documentNumber,
          scheduledDate,
          scenario?.scenarioChecksum,
          currentDraftData?.uuid
        )
          // eslint-disable-next-line radar/no-identical-functions
          .then(() =>
            history.push(
              `/scenario/${scenarioUuid}/envelope/${envelope?.data?.uuid}`
            )
          )
          .catch((error) => {
            console.error(
              'Failed, saving invoice document draft with scheduledDate delay',
              error
            );
            triggerDelayErrorToast(error, this.props.intl);
          });
      }
    }
  };

  submitInvoiceWithOutDelay = async (canSubmit, actionType) => {
    const {currentDraftData} = this.state;

    if (currentDraftData?.uuid) {
      //validate on document post
      await this.runInMemoryValidation();
      if (!this.state.inMemoryValidation.hasErrors) {
        deleteDraftWithUuid(currentDraftData?.uuid)
          .then(async () => await this.onSubmitDocument(canSubmit, actionType))
          .catch((error) => {
            console.error(
              `Failed, deleting the draft ${currentDraftData?.uuid} before sending the document without delay`,
              error
            );
            triggerDelayErrorToast(error, this.props.intl);
          });
      } else {
        this.setState({loading: false});
      }
    } else {
      await this.onSubmitDocument(canSubmit, actionType);
    }
  };

  render() {
    // we cannot recover from this.
    if (this.state.error) {
      console.error(this.state.error);
      const errDescTranslationKey = exportErrorMessageTranslationKeyFromHTTPResponse(
        this.state.error
      );
      if (errDescTranslationKey === null) {
        throw new Error(this.state.error);
      } else {
        throw new Error(errDescTranslationKey);
      }
    }

    const {envelope, scenario, pageConfig, draftSettings} = this.props;
    const {
      documentExchange,
      loading,
      inMemoryValidation,
      preventSendingOfDocument,
      currentDraftData,
    } = this.state;
    const invoiceDoc = get(documentExchange.data, 'document');

    // TODO: discuss with PW and ML how we handle this:
    // - fallback configurable?
    let docCurrency = get(invoiceDoc, 'header.referenceCurrency');
    docCurrency = docCurrency ? docCurrency : CURRENCY_EUR;

    const renderLoader = loading || draftSettings?.fetchingSettings;

    if (renderLoader) {
      return <Loader active />;
    }

    const invoiceAmount =
      invoiceDoc.footer?.invoiceFooter?.invoiceTotals?.invoiceAmount;

    const invoiceLineItems = invoiceDoc.details?.invoiceData?.invoiceLineItems;
    let isValid = true;

    if (!this.state.hideVatCols) {
      isValid = isFinite(invoiceAmount);
    }

    if (!this.state.hideVatCols && invoiceLineItems.length) {
      invoiceLineItems.map((lineItem) => {
        if (!lineItem.vatrate && lineItem.vatrate !== 0) {
          isValid = false;
        }
      });
    }
    let iglError = false;
    // TODO since we now have the inMemoryValidator, this could also be done with it i guess
    if (isVatVariantConfigured(pageConfig) && isIglSet(invoiceDoc)) {
      const headerAoc =
        invoiceDoc.header?.invoiceHeader?.allowancesAndCharges || [];
      // if we find a positive header vatrate here, the user should not be able to submit
      iglError =
        headerAoc.filter((aoc) => parseFloat(aoc.vatrate) > 0).length > 0;

      //reset lineItemsVats for to tigger footerCalc, when IGL is set
      Invoice.resetLineItemVats(this.state.vatVariant).getDocument();
      invoiceLineItems.map((lineItem) => {
        if (lineItem.vatrate || lineItem.vatrate === 0) {
          isValid = true;
        }
      });
    }

    return (
      <Fragment>
        <ContextWrapper>
          <DocumentDraftInfoHeader
            draftData={currentDraftData}
            scenarioUuid={scenario?.uuid}
            envelopeUuid={envelope?.data?.uuid}
            draftSettings={this.props.draftSettings}
          />
          {/* we need to clone the document in the modal because the form works on the reference. if you edit the doc and cancel, the values persisted in the document anyways...*/}
          {this.state.editHeadAoc ? (
            <DocumentAocEditor
              pageConfig={pageConfig}
              isIglSet={isIglSet(invoiceDoc)}
              documentExchange={cloneDeep(this.state.documentExchange)}
              onCancel={this.onCancelHeadAoc}
              onSave={this.onSaveHeadAoc}
              vatrates={this.state.vatRates}
              docCurrency={docCurrency}
            />
          ) : null}

          <InvoiceLineItemEditor
            pageConfig={pageConfig}
            lineItem={this.state.editLineItem}
            onCancel={this.onDetailCancel}
            onSave={this.onDetailSave}
            docCurrency={docCurrency}
          />
          <SerialNumberModal
            pageConfig={pageConfig}
            lineItem={this.state.editSerialNumbers}
            onCancel={this.onDetailCancel}
            onSave={this.onDetailSave}
          />
          <DocumentHeader
            pageConfig={pageConfig}
            envelope={envelope.data}
            documentExchange={this.state.documentExchange}
            scenario={scenario}
          />
          <Segment attached="top" clearing>
            <OrderMeta
              showOnlyForm
              onVatVariantChange={this.onVatVariantChange}
              documentExchange={this.state.documentExchange?.data}
              pageConfig={pageConfig}
              onSubmit={this.onSaveMeta}
              editable
            />
          </Segment>
          <Segment attached="bottom">
            <InvoiceTable
              pageConfig={pageConfig}
              fields={pageConfig.formFields}
              invoiceDoc={invoiceDoc}
              inMemoryValidation={inMemoryValidation}
              vatrates={this.state.vatRates}
              onRoundingChange={this.onRoundingChange}
              onSerialNumberEdit={this.onSerialNumberEdit}
              onDetail={this.onDetailLineItem}
              onDupe={this.onDupeLineItem}
              onDelete={this.onDeleteLineItem}
              onOpenHeadAoc={this.onOpenHeadAoc}
              onVatRateChange={this.onVatRateChange}
              docCurrency={docCurrency}
            />

            <HeaderMetaContext.Consumer>
              {({headerValid}) => (
                <Fragment>
                  {iglError && <IglErrorMessage />}
                  {this.state.inMemoryValidation?.hasErrors && (
                    <GenericInvalidDocument />
                  )}
                  <DocumentSendConfirm
                    onConfirm={async (actionType) =>
                      isSendingDelayFromWebEdiSettingsEnabled(draftSettings)
                        ? await this.saveInvoiceDraftWithDefaultDelay(
                            isValid && headerValid && !iglError
                          )
                        : await this.submitInvoiceWithOutDelay(
                            isValid && headerValid && !iglError,
                            actionType
                          )
                    }
                    onCancel={this.onCancelDocument}
                    pageConfig={pageConfig}
                    docType={INDUSTRY_INVOICE}
                    isEnabled={!preventSendingOfDocument}
                    saveDraftWithDefaultDelay={async () =>
                      await this.saveInvoiceDraftWithDefaultDelay(
                        isValid && headerValid && !iglError
                      )
                    }
                    submitDraftWithOutDelay={async (actionType) =>
                      await this.submitInvoiceWithOutDelay(
                        isValid && headerValid && !iglError,
                        actionType
                      )
                    }
                    saveDraftWithScheduledDate={async (scheduledDate) =>
                      await this.saveInvoiceDraftWithScheduledDate(
                        isValid && headerValid && !iglError,
                        scheduledDate
                      )
                    }
                    currentDraftData={currentDraftData}
                  />
                </Fragment>
              )}
            </HeaderMetaContext.Consumer>
          </Segment>
        </ContextWrapper>
      </Fragment>
    );
  }
}

InvoiceTemplate.propTypes = {
  intl: intlShape.isRequired,
  postInvoice: PropTypes.func,
  envelope: envelopesShape.isRequired,
  pageConfig: pageConfigShape.isRequired,
  scenario: scenarioShape.isRequired,
  fetchTurnaround: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  scenarioUuid: PropTypes.string.isRequired,
  draftSettings: PropTypes.object,
};

export default injectIntl(connect(null, mapDispatchToProps)(InvoiceTemplate));
