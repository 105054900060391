import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';
import {Input, Form as FinalForm} from '@ecosio/pathform';
import {compose} from 'lodash/fp';
import {intlShape} from '@ecosio/components';
import moment from 'moment';
import {DRAFTS_DELAY_SCHEDULED, DRAFTS_DELAY_TIME} from '../../constants';
import withDocumentDraftSettings, {
  DRAFT_FEATURES_REQUESTS_NAMES,
  DRAFT_FEATURES_REQUESTS_DEAULT,
} from '../hoc/withDocumentDraftSettings';
import {loadTheme} from '../../helpers/utils';
import UNION_SVG from '../../assets/union.svg?react';
import {
  convertUTCdateTimeToLocalDateTime,
  isSendingDelayFromWebEdiSettingsEnabled,
} from './draftUtils';
import SVGRenderer from 'src/helpers/components/SVGRenderer.tsx';

const StyledDropDownItem = styled(DropdownItem)`
  margin: 8px;
  cursor: auto;
  text-wrap: pretty;
  ${(props) =>
    props.backgroudColor ? `background: ${props.backgroudColor}` : null}
`;

const StyledDatePickerDropDownItem = styled(DropdownItem)`
  margin: 8px;
  cursor: auto;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
`;

const StyledDropDown = styled(Dropdown)`
  margin-left: -3px !important;
  background: ${(props) =>
    props.disabled
      ? ecosioColors.greyShades[3]
      : props.backgroundColor} !important;
  .dropdown.icon {
    font-size: 14px !important;
    color: white !important;
  }

  border-radius: 0 !important;
`;

const StyledFinalForm = styled(FinalForm)`
  display: flex;
`;

const StyledSendDropdownItemDefaultHeader = styled.p`
  font-weight: 700;
  letter-spacing: 0px;
`;

const StyledSendDropdownItemDefaultDescription = styled.p`
  text-wrap: pretty;
  font-weight: 400;
  margin-top: -15px;
  color: ${ecosioColors.greyShades[1]};
  letter-spacing: 0px;
`;

const StyledDefaultSendButtonInfoText = styled.p`
  position: absolute;
  right: 3px;
  color: #5c5d5d;
  width: max-content;
  font-size: 10px;
`;

const DocumentDraftSendButtonOptions = (props) => {
  const {
    userConfig,
    disabled = false,
    hide,
    loading = false,
    submitWithOutDelay,
    saveDraftWithDefaultDelay,
    saveDraftWithScheduledDate,
    draftSettings,
    intl,
    locale,
    currentDraftData = {},
  } = props;
  const [scheduledDate, setScheduledDate] = useState(null);
  const [openDropDown, setOpenDropDown] = useState(false);

  if (!draftSettings?.shouldRenderDrafts || hide) {
    return null;
  }

  const settingsFormFields =
    userConfig?.webEdiSettingsConfiguration?.formFields || {};

  const webediSettings = draftSettings?.webediSettings || [];
  const delayIsDelayObject = webediSettings.find(
    (setting) => setting?.type === DRAFTS_DELAY_TIME
  );

  const delayDuration = moment.duration(
    Number(delayIsDelayObject?.value),
    'milliseconds'
  );

  //ecosio.successGreen === 	hsl(120, 39%, 54%)
  const dropDownButtonColor = !openDropDown
    ? 'hsl(120, 39%, 70%)'
    : 'hsl(120, 39%, 30%)';

  const delayFromWebEdiSettingsEnabled = isSendingDelayFromWebEdiSettingsEnabled(
    draftSettings
  );

  const currentTheme = loadTheme(props.theme);

  return (
    <StyledFinalForm
      initialValues={{}}
      onSubmit={() => {}}
      locale={locale}
      fields={settingsFormFields}>
      {() => (
        <>
          <StyledDropDown
            data-spec="DRAFT_SEND_BUTTON_DROPDOWN"
            className="icon"
            button
            backgroundColor={dropDownButtonColor}
            upward
            open={openDropDown}
            onClick={() => setOpenDropDown(!openDropDown)}
            selectOnBlur={false}
            disabled={disabled}
            loading={loading}>
            <DropdownMenu
              style={{width: '300px', opacity: '1'}}
              direction="left"
              data-spec="DRAFT_SEND_DROPDOWN_MENU">
              {delayFromWebEdiSettingsEnabled ? (
                <StyledDropDownItem
                  backgroudColor={currentTheme?.secondaryColor}
                  data-spec="DRAFT_SEND_DROPDOWN_ITEM_DEFAULT"
                  onClick={async () => await saveDraftWithDefaultDelay()}>
                  <StyledSendDropdownItemDefaultHeader>
                    {intl.formatMessage({
                      id: 'DRAFT_SEND_DROPDOWN_ITEM_DEFAULT_HEADER',
                    })}
                  </StyledSendDropdownItemDefaultHeader>
                  <StyledSendDropdownItemDefaultDescription>
                    {intl.formatMessage(
                      {
                        id: 'DRAFT_SEND_DROPDOWN_ITEM_DEFAULT_DESCRIPTION',
                      },
                      {
                        delay: delayDuration.asMinutes(),
                      }
                    )}
                  </StyledSendDropdownItemDefaultDescription>
                </StyledDropDownItem>
              ) : null}

              <StyledDropDownItem
                backgroudColor={
                  !delayFromWebEdiSettingsEnabled
                    ? currentTheme?.secondaryColor
                    : null
                }
                data-spec="DRAFT_SEND_DROPDOWN_ITEM_WITHOUT_DELAY"
                onClick={async (event) => await submitWithOutDelay(event)}>
                <StyledSendDropdownItemDefaultHeader>
                  {intl.formatMessage({
                    id: 'DRAFT_SEND_DROPDOWN_ITEM_WITHOUT_DELAY_HEADER',
                  })}
                </StyledSendDropdownItemDefaultHeader>
                <StyledSendDropdownItemDefaultDescription>
                  {intl.formatMessage({
                    id: 'DRAFT_SEND_DROPDOWN_ITEM_WITHOUT_DELAY_DESCRIPTION',
                  })}
                </StyledSendDropdownItemDefaultDescription>
              </StyledDropDownItem>
              <StyledDatePickerDropDownItem
                onClick={(e) => e.stopPropagation()}
                data-spec="DRAFT_SEND_DROPDOWN_ITEM_SCHEDULED_DATE">
                <Input
                  name={DRAFTS_DELAY_SCHEDULED}
                  path={DRAFTS_DELAY_SCHEDULED}
                  onInputChange={async (e, date) => {
                    await setScheduledDate(date);
                  }}
                  stripTimezone
                />
                <SVGRenderer
                  data-spec="documentDraftSendButtonOptions_union_svg"
                  svgIcon={UNION_SVG}
                  style={{
                    marginTop: '25px',
                    color: currentTheme?.primaryColor,
                  }}
                  height="22px"
                  onClick={async (e) => {
                    e.preventDefault();
                    await saveDraftWithScheduledDate(scheduledDate);
                  }}
                />
              </StyledDatePickerDropDownItem>
            </DropdownMenu>
          </StyledDropDown>
          <SendButtonDelayInfoText
            currentDraftData={currentDraftData}
            locale={locale}
            intl={intl}
            draftSettings={draftSettings}
          />
        </>
      )}
    </StyledFinalForm>
  );
};

DocumentDraftSendButtonOptions.defaultProps = {
  disabled: false,
  hide: false,
  loading: false,
  locale: 'en',
  currentDraftData: {},
};

DocumentDraftSendButtonOptions.propTypes = {
  userConfig: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  loading: PropTypes.bool,
  submitWithOutDelay: PropTypes.func.isRequired,
  saveDraftWithDefaultDelay: PropTypes.func.isRequired,
  saveDraftWithScheduledDate: PropTypes.func.isRequired,
  draftSettings: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  locale: PropTypes.string,
  currentDraftData: PropTypes.object,
};

const mapStateToProps = ({config, locales}) => ({
  theme: config?.userConfig?.theme,
  locale: locales?.locale,
});

export default injectIntl(
  compose(
    connect(mapStateToProps),
    withDocumentDraftSettings({
      ...DRAFT_FEATURES_REQUESTS_DEAULT,
      [DRAFT_FEATURES_REQUESTS_NAMES.FETCH_WEBEDI_SETTINGS]: true,
    })
  )(DocumentDraftSendButtonOptions)
);

const SendButtonDelayInfoText = (props) => {
  const currentDraft = props?.currentDraftData;
  const draftSettings = props?.draftSettings || {};
  const webediSettings = draftSettings?.webediSettings || [];

  if (draftSettings?.shouldRenderDrafts === false) {
    return false;
  }

  const delayFromWebEdiSettingsEnabled = isSendingDelayFromWebEdiSettingsEnabled(
    draftSettings
  );

  const intl = props?.intl;

  let renderText = intl.formatMessage({
    id: 'DRAFT_SEND_BUTTON_INFO_TEXT_WITHOUT_DELAY',
  });

  const delayIsDelayObject = webediSettings.find(
    (setting) => setting?.type === DRAFTS_DELAY_TIME
  );

  if (delayFromWebEdiSettingsEnabled && delayIsDelayObject) {
    const delayDuration = moment.duration(
      Number(delayIsDelayObject?.value),
      'milliseconds'
    );

    renderText = intl.formatMessage(
      {
        id: 'DRAFT_SEND_BUTTON_INFO_TEXT_DEFAULT_DELAY',
      },
      {
        delay: delayDuration.asMinutes(),
      }
    );
  }

  if (currentDraft?.sendDate) {
    //convert utc dateTime to browser local dateTime
    const sendDate = convertUTCdateTimeToLocalDateTime(
      currentDraft?.sendDate,
      props?.locale
    );

    renderText = intl.formatMessage(
      {
        id: 'DRAFT_SEND_BUTTON_INFO_TEXT_CURRENT_DRAFT_DELAY',
      },
      {
        sendDate: sendDate,
      }
    );
  }

  return (
    <StyledDefaultSendButtonInfoText>
      {renderText}
    </StyledDefaultSendButtonInfoText>
  );
};
