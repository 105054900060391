/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';
import {FormattedMessage as Msg} from 'react-intl';
import './Sidebar.css';
import styled from 'styled-components';
import * as DesAdvColors from '../../../CommonComponents/Create/DesAdvColors';
import SidebarItem from './SidebarItem';

const SidebarPanel = styled.div`
  background-color: ${DesAdvColors.panelLightGrey};
  padding: 20px;
`;

const Sidebar = ({data, envelope, config, ordersDataTemp}) => (
  <SidebarPanel data-spec="sidebar">
    <Header as="h4">
      <Msg id="DESADV_BASKET_HEADER"></Msg>
    </Header>
    <label>
      <Msg
        id="DESADV_BASKET_SUB_HEADER"
        values={{
          correlatorId: envelope?.data?.correlatorId,
        }}></Msg>
    </label>
    {data.ordersData.ordersLineItems
      .sort((a, b) =>
        a.customersItemMaterialNumber > b.customersItemMaterialNumber ? 1 : -1
      )
      .map((item, idx) => {
        return (
          <SidebarItem
            config={config}
            item={item}
            key={`SIDEBAR_${item.customersItemMaterialNumber}_${idx}`}
            tempItem={ordersDataTemp?.orderLineItems[item.positionNumber]}
          />
        );
      })}
  </SidebarPanel>
);

Sidebar.propTypes = {
  data: PropTypes.object,
  envelope: PropTypes.object,
};

export default Sidebar;
