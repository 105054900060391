/* eslint-disable react/no-array-index-key */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Message} from 'semantic-ui-react';
import {dynID} from '@ecosio/pathform';
import {FormattedMessage} from 'react-intl';
import DownloadPdfButton from '../Document/Download/DownloadPdfButton';
import {
  NON_ENVELOPE_PDF,
  ON_SUCCESS_FLASH_MESSAGE_DURATION,
} from '../../helpers/constants';
import MonthlyReportPDFDownloadButton from '../MontlyReport/MonthlyReportPDFDownloadButton';

export const FlashMessage = withRouter(({location}) => {
  const [visible, setVisible] = useState(false);

  const message = location.state?.message;
  const pdfType = location.state?.pdfType;

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(
      () => setVisible(false),
      ON_SUCCESS_FLASH_MESSAGE_DURATION
    );
    return () => clearTimeout(timer);
  }, [location.key]);

  if (!visible || !message) {
    return null;
  }

  if (pdfType === NON_ENVELOPE_PDF) {
    const downloadButtonData = location?.state?.downloadButtonData || {};
    const {
      downloadConfigExists,
      reportMessageUuid,
      downloadConfigurations,
      pageConfigUuid,
      scenarioUuid,
    } = downloadButtonData;

    return (
      <Message
        success
        data-spec="doc-created-successMessage"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid',
        }}
        data-message-id={location?.state?.messageId}>
        <Message.Content>
          <span>
            <FormattedMessage id={dynID(location.state.message)} />
            &nbsp;&nbsp;&nbsp;
            {downloadConfigExists
              ? Object.keys(downloadConfigurations).map((d, key) => (
                  <MonthlyReportPDFDownloadButton
                    key={key}
                    index={`${key}`}
                    reportMessageUuid={reportMessageUuid}
                    downloadConfigurations={downloadConfigurations}
                    pageConfigUuid={pageConfigUuid}
                    downloadRenderingKey={d}
                    scenarioUuid={scenarioUuid}
                  />
                ))
              : null}
          </span>
        </Message.Content>
      </Message>
    );
  } else {
    const downloadButtonData = location?.state?.downloadButtonData || {};
    const {
      downloadConfigExists,
      currentDocumentUuid,
      envelopeUuid,
      scenario,
      currentDocumentTypeID,
      documentData,
    } = downloadButtonData;
    return (
      <Message
        success
        data-spec="doc-send-successMessage"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid',
        }}
        data-message-id={location.state.erpelMessageId}>
        <Message.Content>
          <span>
            <FormattedMessage id={dynID(location.state.message)} />
            &nbsp;&nbsp;&nbsp;
            {downloadConfigExists ? (
              <DownloadPdfButton
                dataSpec="onSuccess-Message-download-button"
                currentDocumentUuid={currentDocumentUuid}
                documentData={documentData}
                envelopeUuid={envelopeUuid}
                scenario={scenario}
                currentDocumentTypeID={currentDocumentTypeID}
              />
            ) : null}
          </span>
        </Message.Content>
      </Message>
    );
  }
});

const DynamicTemplateNotFound = () => <h1>Sorry - not Found</h1>;

const DynamicTemplate = ({componentName, options, ...rest}) => {
  const RenderError = () => {
    if (options && options.error) {
      const ErrorComponent = options.error;
      return <ErrorComponent />;
    } else {
      return <DynamicTemplateNotFound />;
    }
  };

  if (!componentName || typeof componentName !== 'string') {
    console.error(
      `[DynamicTemplate] please provide a componentName property (string) in options`
    );
    return <RenderError />;
  }

  if (!options || !options.templates) {
    console.error(
      `[DynamicTemplate] please provide a template property in options`
    );
    return <RenderError />;
  }

  if (!options.templates[componentName]) {
    console.error(
      `[DynamicTemplate] Dynamic Template named ${componentName} not found - rendering null with Props: \n ${rest}`
    );
    return <RenderError />;
  }

  if (options && options.debug) {
    console.info(rest);
  }

  const Template = options.templates[componentName];
  return <Template {...rest} />;
};

export default DynamicTemplate;

DynamicTemplate.propTypes = {
  componentName: PropTypes.string.isRequired,
  options: PropTypes.shape({
    templates: PropTypes.amy,
    debug: PropTypes.bool,
    error: PropTypes.any,
  }),
};
