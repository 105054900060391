import React from 'react';
import PropTypes from 'prop-types';
import {Table, Button, Icon} from 'semantic-ui-react';
import {includes} from 'lodash';
import {pageConfigShape} from '../../../../shapes/scenarios';
import ValidationWarnIcon from '../../ValidationWarnIcon';
import {inMemoryValidatorShape} from '../../../../shapes/inMemoryValidatorShape';

export const ActionButton = ({
  onAction,
  icon,
  color = 'purple',
  spec = 'edit-le',
}) => (
  <Button
    size="mini"
    color={color}
    onClick={onAction}
    compact
    icon
    data-spec={spec}>
    <Icon name={icon} />
  </Button>
);

ActionButton.defaultProps = {
  spec: 'edit-le',
};

ActionButton.propTypes = {
  onAction: PropTypes.func.isRequired,
  icon: PropTypes.node,
  color: PropTypes.string,
  spec: PropTypes.string,
};

const inMemoryValidatePerLinItem = (inMemoryValidation, linIdex) => {
  const {hasErrors, errorData} = inMemoryValidation;

  if (hasErrors) {
    //filter here the validation errors per linPosition
    const filteredErrors = errorData.filter((singleError) =>
      includes(
        Object.keys(singleError)[0],
        `details.invoiceData.invoiceLineItems[${linIdex}]`
      )
    );

    if (filteredErrors.length) {
      return (
        <ValidationWarnIcon tooltipData="GENERAL_VALIDATOR_LIN_MODAL_VALIDATION_ERRORS" />
      );
    }
  }
};

const EditCell = ({
  row,
  onDetail,
  onDupe,
  onDelete,
  pageConfig,
  idx,
  inMemoryValidation,
}) => {
  const canDupe = pageConfig.lineItemConfiguration.allowDuplicateLineItem;
  const canDelete = pageConfig.lineItemConfiguration.allowDeleteLineItem;

  return (
    <Table.Cell verticalAlign="top" data-spec="le-action">
      <pre>
        {inMemoryValidatePerLinItem(inMemoryValidation, idx)}
        <ActionButton icon="pencil" onAction={() => onDetail(row)} />
        {canDupe ? (
          <ActionButton icon="copy outline" onAction={() => onDupe(row)} />
        ) : null}

        {canDelete ? (
          <ActionButton
            color="red"
            icon="trash"
            onAction={() => onDelete(row)}
          />
        ) : null}
      </pre>
    </Table.Cell>
  );
};

EditCell.propTypes = {
  idx: PropTypes.number,
  row: PropTypes.object,
  onDetail: PropTypes.func.isRequired,
  onDupe: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  pageConfig: pageConfigShape.isRequired,
  inMemoryValidation: inMemoryValidatorShape,
};

export default EditCell;
