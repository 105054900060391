import {CALCULATION_TYPES} from '../shapes/scenarios';

export const showLinCalculation = (lineItemConfiguration) =>
  lineItemConfiguration?.amountCalculation === CALCULATION_TYPES.DEFAULT ||
  lineItemConfiguration?.amountCalculation ===
    CALCULATION_TYPES.NO_LINEITEMAMOUNT_AND_TOTAL_AMOUNT_CALCULATION;

export const hideLinTotalPrice = (lineItemConfiguration) =>
  lineItemConfiguration?.amountCalculation ===
  CALCULATION_TYPES.NO_LINEITEMAMOUNT_AND_TOTAL_AMOUNT_CALCULATION;
