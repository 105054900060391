import {generateDesadvPackageNumbers} from '../../../Utils/CreateUtils';
import {
  CPS_SHIPPING_MARK_TYPE,
  DESADV_NULL_POSITION,
} from '../../../../../../helpers/constants';
import {DEFAULT_DISPATCH_MEASURE_UNIT} from '../../../../../../constants';
import {
  extractCpsIndexNumberFromUniquePositionNumber,
  extractOriginalPositionNumberFromUniquePositionNumber,
} from './positionNumberTransform';

/***
 * Builds the number of cpses depending on the numberOfpackages
 * With sscc.
 * Used in modalsidebar for packages.
 */
export const buildSimpleDesadvWithComplexStructure = async (
  cpsesWithRoot,
  cpsConfigs
) => {
  if (!Array.isArray(cpsesWithRoot) || !cpsesWithRoot.length) {
    console.error(
      'building simple complex packaging structure failed, root cps is empty or undefined'
    );
    return [];
  }

  if (!Array.isArray(cpsConfigs) || !cpsConfigs.length) {
    console.error(
      'building simple complex packaging structure failed, cpsConfig array is empty or undefined'
    );
    return [];
  }

  const packageTypeCustomer =
    cpsConfigs[0]?.packagingInformation?.packageTypeCustomer;
  const shippingMarkType =
    cpsConfigs[0]?.packagingInformation?.shippingMarkType;
  const numberOfPackages =
    cpsesWithRoot[0].packagingInformations[0].numberOfPackages;

  if (!numberOfPackages) {
    if (numberOfPackages !== 0) {
      console.error(
        'building simple complex packaging structure failed, numberOfPackages is undefined'
      );
    }

    return [];
  }
  const totalSsccNumbers = await generateDesadvPackageNumbers(
    'SSCC_GENERATE',
    numberOfPackages
  );
  const ssccnumbers = {
    packagingNumber: totalSsccNumbers?.packagingNumber?.length
      ? totalSsccNumbers?.packagingNumber?.splice(0, numberOfPackages)
      : [],
  };

  //one is for rootCPS
  let hierarchicalIDCounter = 1;
  const prevCpses = cpsesWithRoot.filter((cps, index) => index !== 0) || [];

  return Promise.all(
    Array.from({length: numberOfPackages}, (cps, cpsindex) => {
      const prevDeliveryLineItems =
        prevCpses[cpsindex]?.deliveryLineItems || [];
      const prevDeliveryLineItemsObject = prevDeliveryLineItems.length
        ? {
            deliveryLineItems: prevDeliveryLineItems,
          }
        : {};
      return {
        hierarchicalParentID: '1',
        hierarchicalID: `${++hierarchicalIDCounter}`,
        packagingInformations:
          prevCpses &&
          prevCpses[cpsindex] &&
          prevCpses[cpsindex]?.packagingInformations[0]?.identifications[0]
            ?.identityNumber !== DESADV_NULL_POSITION &&
          ssccnumbers?.packagingNumber[cpsindex]?.value !== DESADV_NULL_POSITION
            ? prevCpses[cpsindex]?.packagingInformations.map((info) => {
                return {
                  ...info,
                };
              })
            : [
                {
                  numberOfPackages: '1',
                  packageTypeCustomer: packageTypeCustomer,
                  shippingMarkType: shippingMarkType,
                  identifications: [
                    {
                      identityNumber:
                        ssccnumbers?.packagingNumber[cpsindex]?.value,
                    },
                  ],
                },
              ],
        ...prevDeliveryLineItemsObject,
      };
    })
  );
};

//Final cps Build before sending to Hub
export const buildComplexCps = (dispatchData) => {
  const dispatchDataDeliveryLineItems =
    dispatchData?.consignmentPackagingSequences[0]?.deliveryLineItems;

  if (
    !dispatchDataDeliveryLineItems ||
    !Array.isArray(dispatchDataDeliveryLineItems) ||
    !dispatchDataDeliveryLineItems.length
  ) {
    console.error(
      'Failed to build complex Structured desadv with cpses, undefined or empty deliveryLineItems',
      dispatchDataDeliveryLineItems
    );

    return undefined;
  }

  const cpses = dispatchData?.consignmentPackagingSequences || [];

  dispatchDataDeliveryLineItems.forEach((lineItem) => {
    if (Number(lineItem?.dispatchQuantity?.value) === 0) {
      const nullCps = lineItem?.cpses[0];
      const tempLineItem = {...lineItem};
      delete tempLineItem.cpses;

      const nullPositionObject = {
        ...nullCps,
        deliveryLineItems: [{...tempLineItem}],
      };

      cpses.push(nullPositionObject);
    }
  });

  let positionNumberCounter = 0;
  return cpses
    .map((cps, cpsIndex) => {
      const tempCps = cps;

      if (cpsIndex === 0) {
        delete tempCps.deliveryLineItems;
        return {
          ...tempCps,
        };
      }

      const isNullPosition =
        cps?.packagingInformations[0]?.identifications[0]?.identityNumber ===
        DESADV_NULL_POSITION;

      if (isNullPosition) {
        return {
          ...cps,
          deliveryLineItems: [
            {
              ...cps?.deliveryLineItems[0],
              positionNumber: `${++positionNumberCounter}`,
            },
          ],
        };
      }

      const tempDeliveryLineItems = tempCps?.deliveryLineItems || [];

      return {
        ...tempCps,
        deliveryLineItems: tempDeliveryLineItems.map((lineItem) => {
          const positionNumberForCps0 = extractOriginalPositionNumberFromUniquePositionNumber(
            lineItem.positionNumber
          );

          const cpsIndexNumber = Number(
            extractCpsIndexNumberFromUniquePositionNumber(
              lineItem.positionNumber
            )
          );
          const deliveryLineItem = dispatchDataDeliveryLineItems.find(
            (i) => `${i.positionNumber}` === positionNumberForCps0
          );

          const packageInfoCpses = deliveryLineItem?.cpses || [];

          const quantityPerPack =
            packageInfoCpses[cpsIndexNumber]?.packagingInformations[0]
              ?.quantityPerPack || 0;

          const batchNumber =
            packageInfoCpses[cpsIndexNumber]?.packagingInformations[0]
              ?.batchNumber;

          const mhd =
            packageInfoCpses[cpsIndexNumber]?.packagingInformations[0]
              ?.expirationDate;

          return {
            ...lineItem,
            positionNumber: `${++positionNumberCounter}`,
            batchNumber: batchNumber,
            expirationDate: mhd,
            dispatchQuantity: {
              unit: lineItem?.dispatchQuantity?.unit
                ? lineItem.dispatchQuantity.unit
                : DEFAULT_DISPATCH_MEASURE_UNIT,
              value: Number(quantityPerPack || 0),
            },
          };
        }),
      };
    })
    .map((cps, index) => {
      if (index === 0) {
        return {
          ...cps,
          hierarchicalID: `${index + 1}`,
          packagingInformations: [
            {
              ...cps.packagingInformations[0],
              numberOfPackages: cpses.length - 1,
            },
          ],
        };
      }

      let shippingMarkType = CPS_SHIPPING_MARK_TYPE.UN_MIXED_PACKAGE;
      if (cps?.deliveryLineItems.length > 1) {
        const cpsDeliveryItems = cps?.deliveryLineItems || [];
        //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/787
        for (const delItem of cpsDeliveryItems) {
          if (
            delItem?.references?.agreementOrOrderLineNumber !==
            cps?.deliveryLineItems[0]?.references?.agreementOrOrderLineNumber
          ) {
            shippingMarkType = CPS_SHIPPING_MARK_TYPE.MIXED_PACKAGE;
          }
        }
      }

      return {
        ...cps,
        packagingInformations: [
          {
            ...cps?.packagingInformations[0],
            //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/787
            shippingMarkType,
          },
        ],
        hierarchicalID: `${index + 1}`,
      };
    });
};
