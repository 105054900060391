import {Header, Segment} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';
import PropTypes from 'prop-types';
import DashboardTasksTable from './DashboardTasksTable';

const DashboardTasksContainer = styled(Segment)`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height || '100%'};
  background-color: ${ecosioColors.greyShades[5]} !important;
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
`;

const DashboardTasks = ({renderConfig, stats, isLoading, currentTheme}) => {
  return (
    <DashboardTasksContainer loading={isLoading}>
      <Header as="h3" style={{marginBottom: '25px', float: 'left'}}>
        <FormattedMessage id="TASKS_YOUR_TASKS" />
      </Header>
      {!isLoading && (
        <DashboardTasksTable
          renderConfig={renderConfig}
          stats={stats}
          currentTheme={currentTheme}
        />
      )}
    </DashboardTasksContainer>
  );
};

DashboardTasks.propTypes = {
  renderConfig: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  currentTheme: PropTypes.object,
};

export default DashboardTasks;
