import {Grid, Checkbox, Popup, Icon, Form} from 'semantic-ui-react';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Field} from 'react-final-form';
import {
  CHARGE,
  EnableRelativeCalculationForAllowanceConstants,
} from '../../../../../../constants';

const RelativeCalculationCheckBoxFieldLabel = () => {
  return (
    <div style={{display: 'flex'}}>
      <label className="themed" style={{paddingRight: '3px'}}>
        <FormattedMessage id="INVOICE_ENABLE_RELATIVE_CALCULATION" />
      </label>
      <Popup
        content={
          <FormattedMessage id="INVOICE_ENABLE_RELATIVE_CALCULATION_POPUP" />
        }
        trigger={<Icon name="info circle" />}
      />
    </div>
  );
};

/**
 * This component is just for enabling/disable RelativeCalculation logic in InvoiceCalculator
 * The checkbox value is not save in the invoice document
 */
const RelativeCalculationCheckBoxField = ({
  aocItem,
  docLevelAlcConfiguration,
  name,
  allowanceChargeRelativeCalculationEnabledForThisAoc,
  index,
}) => {
  if (
    aocItem?.allowanceChargeQualifier === CHARGE ||
    !docLevelAlcConfiguration?.showAllowanceVatRate ||
    docLevelAlcConfiguration?.enableRelativeCalculationForAllowance !==
      EnableRelativeCalculationForAllowanceConstants.USER_SELECTION
  ) {
    return null;
  }
  return (
    <Form.Field data-spec={`aoc-col-relativeCalculation-${index}`}>
      <Grid.Column
        width={1}
        style={{
          padding: 8,
        }}>
        <RelativeCalculationCheckBoxFieldLabel />
        <Field
          name={`${name}.allowanceChargeRelativeCalculation`}
          initialValue
          render={({input}) => {
            return (
              <Checkbox
                {...input}
                onChange={(e, data) => {
                  input.onChange(data.checked);
                }}
                checked={allowanceChargeRelativeCalculationEnabledForThisAoc}
              />
            );
          }}
        />
      </Grid.Column>
    </Form.Field>
  );
};

export default RelativeCalculationCheckBoxField;
