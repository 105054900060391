import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber} from 'react-intl';
import {CURRENCY_EUR} from '../../constants';

/**
 * Formats a number and avoids NaNs
 *
 * TODO: tests
 */
const FailSafeFormattedNumber = ({value, currency}) => {
  if (typeof value === 'undefined' || isNaN(value)) {
    return null;
  }
  return <FormattedNumber value={value} style="currency" currency={currency} />;
};

FailSafeFormattedNumber.defaultProps = {
  currency: CURRENCY_EUR,
};

FailSafeFormattedNumber.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string,
};

export default FailSafeFormattedNumber;
