/* eslint-disable react/no-array-index-key */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Loader} from 'semantic-ui-react';
import moment from 'moment';
import qs from 'qs';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {FormatDateTime, intlShape} from '@ecosio/components';
import {FormattedMessage, injectIntl} from 'react-intl';
import MonthlyReportFilter from '../DailyDocument/DailyDocumentFilter';
import MonthlyReportListTableGird from '../DailyDocument/DailyDocumentsTableGrid';
import {stringifyQuery, DIRECTION_CONVERTER_OUT} from '../../helpers/utils';
import {
  INVOICE_AND_CREDITNOTE_REPORT,
  NON_ENVELOPE_PDF,
} from '../../helpers/constants';
import {scenarioShape} from '../../shapes/scenarios';
import {historyShape, locationShape} from '../../shapes/generalShapes';
import MonthlyReportEmptyState from './MonthlyReportEmptyState';
import CreateMonthlyReportModal from './CreateMonthlyReportModal';
import MonthlyReportPDFDownloadButton from './MonthlyReportPDFDownloadButton';
import {getSortColumnNameForBackend} from './index';

const defaultDataRender = (Table, value) => (
  <Table.Cell collapsing>
    {value ? value : <FormattedMessage id="-" />}
  </Table.Cell>
);

const dateRender = (Table, value) => {
  //FormatDateTime needs timezone for converting the date correcly for the current timeZone.
  const localDateTimeWithUTCTimeZone = value ? `${value}+00:00` : value;

  return (
    <Table.Cell collapsing>
      <FormatDateTime
        stripTimezone={false}
        dateString={localDateTimeWithUTCTimeZone}
      />
    </Table.Cell>
  );
};

export const getDynamicCell = (field) => {
  if (!field) {
    return undefined;
  }
  const Cell = {
    id: 'test',
    label: '',
    hidden: false,
    hideable: true,
    sortable: field?.sortable,
    sort: 2,
    render: defaultDataRender,
  };

  if (field?.tableSort) {
    Cell.sort = field?.tableSort;
  }

  if (field?.path) {
    Cell.id = field?.path;
  }

  if (field?.type === 'date') {
    Cell.render = dateRender;
  }

  if (field?.input?.label) {
    Cell.label = field?.input?.label;
  }

  return Cell;
};

class MonthlyReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyReportList: [],
      pageNr: 1, //it is used to calculate the offset for backend
      dataGridSortBy: {},
      noContentReturned: false,
    };
  }

  async componentDidMount() {
    const params = this.parseParams();
    await this.fetchData(params, true);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const params = this.parseParams();
      await Promise.all([this.fetchData(params, true)]);
    }
    if (!this.props.openModal && this.state.noContentReturned) {
      this.setState({
        noContentReturned: false,
      });
    }
  }

  getGridConfig = (monthlyReportPageConfig) => {
    const formFields = monthlyReportPageConfig?.formFields || {};
    const downloadConfigurations =
      monthlyReportPageConfig?.downloadConfigurations;
    const defaultCfg = {
      idSelector: 'uuid',
      fields: [
        {
          id: 'downloadMessageUuid',
          label: 'GENERAL_DOWNLOAD',
          hidden: false,
          hideable: false,
          render: (Table, value, row, error, index) => {
            return (
              <Table.Cell collapsing>
                {Object.keys(downloadConfigurations).map((d, key) => (
                  <MonthlyReportPDFDownloadButton
                    key={key}
                    index={`${index}-${key}`}
                    reportMessageUuid={value}
                    downloadConfigurations={downloadConfigurations}
                    pageConfigUuid={monthlyReportPageConfig?.uuid}
                    downloadRenderingKey={d}
                    scenarioUuid={this.props.scenarioUuid}
                  />
                ))}
              </Table.Cell>
            );
          },
        },
      ],
    };

    const monthFormField = get(formFields, 'reportMonth');
    if (monthFormField) {
      const monthFieldObject = {
        id: monthFormField?.path,
        label: monthFormField?.input?.label,
        hidden: false,
        hideable: true,
        sortable: monthFormField?.sortable,
        sort: monthFormField?.tableSort,
        render: (Table, value) => {
          const {locale} = this.props;
          return (
            <Table.Cell collapsing>
              {value
                ? moment()
                    .locale(locale)
                    .month(value - 1)
                    .format('MMMM')
                : '-'}
            </Table.Cell>
          );
        },
      };
      defaultCfg.fields.push(monthFieldObject);
    }

    Object.keys(formFields)
      .filter((k) => formFields[k].path !== 'reportMonth')
      .forEach((path) => {
        const field = formFields[path];
        if (getDynamicCell(field)) {
          defaultCfg.fields.push(getDynamicCell(field));
        }
      });

    return defaultCfg;
  };

  fetchData = async (params, clearData = true) => {
    const {scenarioUuid, scenario} = this.props;

    const monthlyReportPageConfigs =
      scenario?.monthlyDocumentsPageConfigurations || [];

    const monthlyReportConfig = monthlyReportPageConfigs.find(
      (d) => d?.monthlyDocumentType === INVOICE_AND_CREDITNOTE_REPORT
    );
    try {
      this.setState({loading: true});
      const newParams = Object.assign({}, params);
      const defaultSortingSettings =
        monthlyReportConfig?.defaultSortingSettings;

      if (
        !newParams?.sort &&
        defaultSortingSettings?.sortOrder &&
        defaultSortingSettings?.columnName
      ) {
        const columnName = getSortColumnNameForBackend(
          monthlyReportConfig,
          defaultSortingSettings?.columnName
        );

        const sortOrder = defaultSortingSettings?.sortOrder.toLowerCase();

        newParams.sort = `${columnName},${sortOrder}`;
      }
      const config = {params: newParams};

      const response = await axios.get(
        `/api/monthlyReport/scenario/${scenarioUuid}/lineItems/list`,
        config
      );

      const lineItemsData = response?.data?.lineItemsData || [];

      if (clearData) {
        this.setState({
          monthlyReportList: lineItemsData,
          loading: false,
          responseDataCount: lineItemsData.length,
          noContentReturned: false,
        });
      } else {
        this.setState((prevState) => ({
          monthlyReportList: [...prevState.monthlyReportList, ...lineItemsData],
          loading: false,
          responseDataCount: lineItemsData.length,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  onSort = ({col, dir}) => {
    const {scenario} = this.props;

    const monthlyReportPageConfigs =
      scenario?.monthlyDocumentsPageConfigurations || [];

    const monthlyReportConfig = monthlyReportPageConfigs.find(
      (d) => d?.monthlyDocumentType === INVOICE_AND_CREDITNOTE_REPORT
    );
    const columnName = getSortColumnNameForBackend(monthlyReportConfig, col);
    const sortOrder = DIRECTION_CONVERTER_OUT[dir];
    const params = this.parseParams();

    const newParams = {
      ...params,
      sort: sortOrder ? `${columnName},${sortOrder}` : undefined,
    };
    if (dir) {
      this.setState({
        dataGridSortBy: {
          col: col,
          dir: dir,
        },
      });
    } else {
      this.setState({
        dataGridSortBy: null,
      });
    }

    this.props.history.push({
      search: `?${stringifyQuery(newParams)}`,
    });
  };

  getMoreData = async (params) => {
    const {pageNr} = this.state;

    await this.setState({
      pageNr: pageNr + 1,
    });

    await this.fetchData(params, false);
  };

  getFilterDropDownSettings = (filterFormFields = []) => {
    const {locale} = this.props;
    return filterFormFields.reduce((obj, item) => {
      const key = item.formField.path;

      if (key === 'month' && item.formField.options.length) {
        const modifiedOptions = item.formField.options.map((d) => {
          const momentWithMonth = moment().month(d.value - 1);
          return {
            text: momentWithMonth.locale(locale).format('MMMM'),
            value: d.value,
          };
        });
        return {
          ...obj,
          [key]: modifiedOptions,
        };
      }
      if (item.formField.options.length) {
        return {
          ...obj,
          [key]: item.formField.options || [],
        };
      }

      if (item.startingDate) {
        const fromYear = moment(item.startingDate).format('YYYY');
        const toYear = moment().format('YYYY');

        const itemDropDownValues = [];
        for (let a = fromYear; a <= toYear; a++) {
          const dropdownValue = {
            text: `${a}`,
            value: `${a}`,
          };
          itemDropDownValues.push(dropdownValue);
        }

        return {
          ...obj,
          [key]: itemDropDownValues,
        };
      }
    }, {});
  };

  parseParams = (props = this.props) => {
    return qs.parse(props.location.search.substr(1));
  };

  resetPageNr = () => {
    this.setState({
      pageNr: 1,
    });
  };

  onFilter = (values) => {
    const existingParams = this.parseParams();

    const newParams = {
      ...existingParams,
      ...values,
    };

    this.resetPageNr();

    this.props.history.push({
      search: `?${stringifyQuery(newParams)}`,
    });
  };

  rerenderAndShowFlashMessage = (reportUuid) => {
    const {history, scenario, scenarioUuid} = this.props;

    const monthlyReportPageConfigs =
      scenario?.monthlyDocumentsPageConfigurations || [];

    const monthlyReportConfig = monthlyReportPageConfigs.find(
      (d) => d?.monthlyDocumentType === INVOICE_AND_CREDITNOTE_REPORT
    );

    const downloadConfigurations = monthlyReportConfig?.downloadConfigurations;
    const existingParams = this.parseParams();
    /**
     * We need to push some random data to search
     * to trigger the fetchData in ComponentDidUpdate and re-render the dataGrid component.
     * With this we dont have to re-render the whole page with "window.location.reload(false)"
     * */
    const values = {
      key: history.location.key,
    };
    const newParams = {
      ...existingParams,
      ...values,
    };

    this.props.history.push({
      search: `?${stringifyQuery(newParams)}`,
      state: {
        pdfType: NON_ENVELOPE_PDF,
        message: 'GENERAL_REPORT_GENERATED',
        messageId: reportUuid,
        downloadButtonData: {
          downloadConfigExists:
            Object.keys(downloadConfigurations).length > 0 ? true : false,
          reportMessageUuid: reportUuid,
          downloadConfigurations: downloadConfigurations,
          pageConfigUuid: monthlyReportConfig?.uuid,
          scenarioUuid: scenarioUuid,
        },
      },
    });
  };

  onCreateReport = async (value, setShowModalMessage) => {
    const {scenarioUuid, onCloseModal, scenario} = this.props;
    const date = new Date(value.value);
    try {
      /**
       * Date is correct
       * When calculating startTS millis from date, browser TZ has to be calculated and converted so that it indicates offset in millis compared to UTC
       */
      const config = {
        startTS: moment(date).startOf('month').utc(true).valueOf(),
        endTS: moment(date).endOf('month').utc(true).valueOf(),
      };

      const response = await axios.post(
        `/api/monthlyReport/scenario/${scenarioUuid}/createReport`,
        config,
        {
          params: {
            envelopeUuid: location?.state?.envelopeUuid,
            browserScenarioUuid: scenarioUuid,
            browserScenarioConfigChecksum: scenario?.scenarioChecksum,
          },
        }
      );

      if (response?.status === 204) {
        this.setState({
          noContentReturned: true,
        });
        setShowModalMessage();
      }

      if (response?.status === 200) {
        this.setState({
          noContentReturned: false,
        });
        onCloseModal();

        this.rerenderAndShowFlashMessage(response?.data?.reportUuid);
      }
    } catch (err) {
      console.error(err);
    }
  };
  render() {
    const {scenario, history, openModal, onCloseModal} = this.props;
    const {
      loading,
      monthlyReportList,
      responseDataCount,
      noContentReturned,
    } = this.state;

    const monthlyReportPageConfigs =
      scenario?.monthlyDocumentsPageConfigurations || [];

    const monthlyReportConfig = monthlyReportPageConfigs.find(
      (d) => d?.monthlyDocumentType === INVOICE_AND_CREDITNOTE_REPORT
    );

    const monthlyReportModalConfig =
      monthlyReportConfig?.monthlyReportModalConfig;

    const renderLoader = loading;
    const filterFormFields = monthlyReportConfig?.filters || [];
    const isEmptyData = monthlyReportList.length === 0;
    const params = this.parseParams();

    return renderLoader ? (
      <Loader active />
    ) : (
      <Fragment>
        <MonthlyReportFilter
          onSubmit={this.onFilter}
          initialValues={params}
          history={history}
          resetPageNr={this.resetPageNr}
          filterFormFields={filterFormFields}
          dropDownSettings={this.getFilterDropDownSettings(filterFormFields)}
        />
        <CreateMonthlyReportModal
          open={openModal}
          onCloseModal={onCloseModal}
          modalConfig={monthlyReportModalConfig}
          onCreateReport={this.onCreateReport}
          noContentReturned={noContentReturned}
        />
        {isEmptyData ? (
          <MonthlyReportEmptyState history={history} />
        ) : (
          <Fragment>
            <MonthlyReportListTableGird
              dataSpec="monthly-report-list-table"
              data={monthlyReportList}
              loading={loading}
              onSort={this.onSort}
              getMoreData={this.getMoreData}
              responseDataCount={responseDataCount}
              selectable={false}
              params={params}
              dataGridSortBy={this.state.dataGridSortBy}
              dailyDocumentPageConfig={monthlyReportConfig}
              history={history}
              gridConfig={this.getGridConfig(monthlyReportConfig)}
              renderFooter={false}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({locales}) => ({
  locale: locales.locale,
});

MonthlyReportList.defaultProps = {
  locale: 'en',
};

MonthlyReportList.propTypes = {
  scenario: scenarioShape.isRequired,
  history: historyShape.isRequired,
  scenarioUuid: PropTypes.string.isRequired,
  openModal: PropTypes.func,
  onCloseModal: PropTypes.func,
  location: locationShape.isRequired,
  locale: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(connect(mapStateToProps)(MonthlyReportList));
