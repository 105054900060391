import {Button, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {ECOSIO_THEME} from '../../../helpers/constants';
import {loadTheme} from '../../../helpers/utils';

export const StyledActionButton = styled(Button)`
  padding: 5px 5px;
  margin-right: 5px;
  border-radius: 15px;
  background: ${(props) => props.background};
  color: black;
  border: none;
  font-weight: 400;
  i {
    margin-left: 5px;
    background: ${(props) => props.background};
    color: ${(props) => props.textColor};
  }

  span {
    display: inline-block;
    background: ${(props) => props.backgroundCircle};
    color: black;
    padding: 2px 6px;
    margin-right: 5px;
    margin-left: 3px;
    border-radius: 50%;
  }
`;

const TaskActionsButtonGroup = ({action, theme, intl, ...rest}) => {
  const currentTheme = loadTheme(theme);
  return (
    <StyledActionButton
      primary
      as={Link}
      to={`${action.link}`}
      target="_blank"
      background={currentTheme.primaryColor}
      backgroundCircle={currentTheme.secondaryColor}
      textColor={currentTheme.secondaryColor}
      {...rest}>
      <span>{action.actionCount}</span>
      {intl.formatMessage({id: action.taskActionType})}
      <Icon name="arrow right"></Icon>
    </StyledActionButton>
  );
};

TaskActionsButtonGroup.propTypes = {
  action: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

TaskActionsButtonGroup.defaultProps = {
  theme: ECOSIO_THEME,
};

const mapStateToProps = ({config}) => ({
  theme: config?.userConfig?.theme,
});

export default injectIntl(connect(mapStateToProps)(TaskActionsButtonGroup));
