import {ABSOLUTE, ALLOWANCE, CHARGE, PERCENTAGE} from '../constants';
import {round, ensureZero, percentageOf} from './math';

/**
 * Helper class to calculate AllowanceOrCharge collections.
 */
export default class AllowanceOrCharges {
  constructor(arr) {
    this.aocArray = arr;
  }

  /**
   * returns the calculated Array from calcAoc
   * @return {*}
   */
  getResult = () => {
    return this.aocArray;
  };

  /**
   * Returns the calculated AoC amount of the last AoC item which is the
   * absolute amount for this AoC collection.
   *
   * Will basically be either the lineItemAmount after AoCs in line items
   * of the totalLineItemAmount on document level.
   *
   */
  calcTotal = () => {
    const lastItem = this.aocArray.slice(-1)[0];
    return this.calcAocAmount(lastItem);
  };

  /**
   * Calculates the absolute amount for this AoC item based on the AoC
   * being an allowance or charge.
   */
  calcAocAmount = (aocItem) => {
    const {
      allowanceChargeQualifier,
      chargeBaseQuantity,
      allowanceChargeAmount,
    } = aocItem;

    switch (allowanceChargeQualifier) {
      case ALLOWANCE:
        // reduces the chargeBaseQuantity
        return chargeBaseQuantity - allowanceChargeAmount;

      case CHARGE:
        // increased the chargeBaseQuantity
        return chargeBaseQuantity + allowanceChargeAmount;

      default:
        throw new Error(
          `allowanceChargeQualifier (${allowanceChargeQualifier}) is invalid (should be A or C)`
        );
    }
  };

  /**
   Calculates the Allowance or Charge Array based on calcAocItem
   the chargeBaseQuantity is always 1 step behind since its starts always with the
   baseamount of an LineItem (currentItemPriceCalculationGross)

   The "total" amount will never be calculated in this array - for that you need calcTotal()

   To get the calculated Array you have to call getResult();

   Flow Example with Absolute numbers:
   index 0:
   {
      chargeBaseQuantity: 100 (currentItemPriceCalculationGross of LineItem)
      allowanceChargeQualifier: 'C' (Charge)
      allowanceChargeAmount: 10
    }

   index 1:
   {
      chargeBaseQuantity: Result of the calculation of [index 0] => 100 + 10 = 110
      allowanceChargeQualifier: 'A' (Allowance)
      allowanceChargeAmount: 20
    }

   index 2:
   {
      chargeBaseQuantity: Result of the calculation of [index 1] => 110 - 20 = 90
      allowanceChargeAmount: 5  <= this will actually never be calculated here, you can get this with instance.getResult();
    }
   *
   * @param baseAmount
   * @return {AllowanceOrCharges}
   */
  calcAoc = (baseAmount) => {
    this.aocArray = this.aocArray.reduce((aocArray, currentAoc, index) => {
      const last = index - 1;
      if (index === 0) {
        // first index - uses the baseamount parameter
        aocArray[index] = this.calcAocItem(currentAoc, baseAmount);
      } else {
        // calculate the chargeBaseQuantity based from the last item
        const lastBaseAmount = this.calcAocAmount(aocArray[last]);
        // calculate the current aoc using the new baseAmount
        aocArray[index] = this.calcAocItem(currentAoc, lastBaseAmount);
      }

      return aocArray;
    }, this.aocArray);

    return this;
  };

  /**
   Calculates the Allowance or Charge item based on allowanceChargeTypeCoded
   "P" means Percentage so we have to calculate it via the chargeBaseQuantity
   "A" means Absolute so dont touch it - just validate that its a number
   *
   * @param aoc
   * @param baseAmount
   * @return {*}
   */
  calcAocItem = (aoc, baseAmount) => {
    let percentageOfAllowanceOrCharge = parseFloat(
      aoc.percentageOfAllowanceOrCharge
    );

    // the base amount for percentage calculations
    const chargeBaseQuantity = baseAmount;

    // the absolute amount of allowance or charge
    let allowanceChargeAmount = parseFloat(aoc.allowanceChargeAmount);

    const allowanceChargeTypeCoded = aoc.allowanceChargeTypeCoded;

    switch (allowanceChargeTypeCoded) {
      case PERCENTAGE:
        percentageOfAllowanceOrCharge = ensureZero(
          percentageOfAllowanceOrCharge
        );

        const calculatedAmount = percentageOf(
          parseFloat(percentageOfAllowanceOrCharge),
          chargeBaseQuantity
        );

        allowanceChargeAmount = round(calculatedAmount);

        break;

      case ABSOLUTE:
        percentageOfAllowanceOrCharge = undefined;
        allowanceChargeAmount = ensureZero(allowanceChargeAmount);
        break;

      default:
        throw new Error(
          `allowanceChargeTypeCoded (${allowanceChargeTypeCoded}) is invalid (should be A or P)`
        );
    }

    // It's possible that an AoC has no vatRate:
    // a) on line item AoCs
    // b) on Allowances on header AoCs
    const vatamount = aoc.vatrate
      ? percentageOf(aoc.vatrate, allowanceChargeAmount)
      : undefined;

    let allowanceChargeRelativeCalculation = {};

    if (aoc.allowanceChargeQualifier === CHARGE) {
      if (aoc?.allowanceChargeRelativeCalculation !== undefined) {
        allowanceChargeRelativeCalculation = {
          allowanceChargeRelativeCalculation: false,
        };
      }
    } else {
      if (aoc?.allowanceChargeRelativeCalculation !== undefined) {
        allowanceChargeRelativeCalculation = {
          allowanceChargeRelativeCalculation:
            aoc.allowanceChargeRelativeCalculation,
        };
      }
    }

    return vatamount || `${vatamount}` === '0'
      ? {
          ...aoc,
          vatamount,
          chargeBaseQuantity,
          percentageOfAllowanceOrCharge,
          allowanceChargeAmount,
          ...allowanceChargeRelativeCalculation,
        }
      : {
          ...aoc,
          chargeBaseQuantity,
          percentageOfAllowanceOrCharge,
          allowanceChargeAmount,
          ...allowanceChargeRelativeCalculation,
        };
  };
}
