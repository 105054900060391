import qs from 'qs';
import {ecosioColors} from '@ecosio/customer-layout';
import {saveAs} from 'file-saver';

import {extractContentDispoFilename} from '../util/httpUtil';

export const isProd = () => process.env.NODE_ENV === 'production';

/**
 * Note: please do not use this function to stringify query params
 * for an axios request, rather pass the parameters of the request
 * in the axios config object
 *
 * Only use this to generate links or push to the history
 */
export const stringifyQuery = (params) => {
  const newParams = Object.assign({}, params);

  return qs.stringify(newParams, {indices: false});
};

export const DIRECTION_CONVERTER_OUT = {
  ascending: 'asc',
  descending: 'desc',
};

export const DIRECTION_CONVERTER_IN = {
  asc: 'ascending',
  desc: 'descending',
};

export const loadTheme = (configTheme) => {
  const theme = configTheme?.split('/')[1];
  const allThemes = ecosioColors?.themes || {};
  const selectedTheme = Object.keys(allThemes).find(
    (themeName) => themeName === theme
  );

  return allThemes[selectedTheme] ? allThemes[selectedTheme] : allThemes.ecosio;
};
export const loadThemeDefaultToBlack = (configTheme) => {
  const theme = configTheme?.split('/')[1];
  const allThemes = ecosioColors?.themes || {};
  const selectedTheme = Object.keys(allThemes).find(
    (themeName) => themeName === theme
  );

  return allThemes[selectedTheme]
    ? allThemes[selectedTheme]
    : allThemes.defaultBlack;
};

/**
 * saves resultBlob as file
 */
export const saveDocument = (res) => {
  const filename = extractContentDispoFilename(
    res.headers['content-disposition']
  );
  return saveAs(res.data, filename);
};
