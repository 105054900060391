import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Header} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {pageConfigShape} from '../../../../shapes/scenarios';
import {
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER,
  INDUSTRY_ORDER_RESPONSE,
} from '../../../../shapes/supportedDocTypes';
import OrderLineItemEditor from './OrderLineItemEditor';

const LineItemModal = ({
  documentTypeId,
  currency,
  onSave,
  onCancel,
  lineItem,
  pageConfig,
  fields,
}) => {
  let TypedLineItemEditor = null;

  switch (documentTypeId) {
    case INDUSTRY_ORDER:
    case INDUSTRY_ORDER_RESPONSE:
    case INDUSTRY_ORDCHG:
      TypedLineItemEditor = OrderLineItemEditor;
      break;
    default:
      throw new Error(`DocumentTypeID not supported ${documentTypeId}`);
  }

  return (
    lineItem && (
      <Modal open size="large">
        <Header>
          <FormattedMessage
            id="LE_EDIT_MODAL_HEADER"
            values={{position: lineItem.positionNumber}}
          />
        </Header>
        <Modal.Content>
          <TypedLineItemEditor
            currency={currency}
            fields={fields}
            lineItem={lineItem}
            pageConfig={pageConfig}
            onSave={onSave}
            onCancel={onCancel}
          />
        </Modal.Content>
      </Modal>
    )
  );
};

LineItemModal.propTypes = {
  currency: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  pageConfig: pageConfigShape.isRequired,
  lineItem: PropTypes.object,
  fields: PropTypes.object,
  documentTypeId: PropTypes.string.isRequired,
};

export default LineItemModal;
