import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Popup, Table} from 'semantic-ui-react';
import {Form, Input} from '@ecosio/pathform';
import {FormSpy} from 'react-final-form';
import {FormattedMessage} from 'react-intl';
import jexl from 'jexl';
import AutoSave from '../../../../helpers/AutoSave';

import {pageConfigShape} from '../../../../shapes/scenarios';

const hideInvoiceFooterRoundingRow = (footerRoundingConfig, document) => {
  if (
    footerRoundingConfig &&
    !footerRoundingConfig?.hideFooterRoundingFieldExpression
  ) {
    return footerRoundingConfig?.hideFooterRoundingField;
  }

  if (
    footerRoundingConfig &&
    footerRoundingConfig?.hideFooterRoundingFieldExpression
  ) {
    return jexl.evalSync(
      `${footerRoundingConfig?.hideFooterRoundingFieldExpression}`,
      document
    );
  }

  return false;
};

/**
 * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/128
 */
const InvoiceFooterRoundingRow = ({
  cols,
  pageConfig,
  doc,
  onRoundingChange,
}) => {
  if (
    !pageConfig?.invoiceFooterRoundingField ||
    hideInvoiceFooterRoundingRow(pageConfig?.invoiceFooterRoundingField, doc)
  ) {
    return null;
  }

  const fieldConfig =
    pageConfig?.invoiceFooterRoundingField?.invoiceFooterRoundingFormField;
  const path = fieldConfig.path;

  const fields = {
    [path]: fieldConfig,
  };

  return (
    <Table.Row data-spec="rounding-row">
      <Table.HeaderCell
        colSpan={cols - 2}
        textAlign="right"
        data-spec="label-cell">
        <strong>
          <FormattedMessage id={fieldConfig.input.label} />{' '}
        </strong>

        {fieldConfig?.input?.helpText && (
          <Popup
            content={<FormattedMessage id={fieldConfig.input.helpText} />}
            trigger={<Icon name="question circle" />}
          />
        )}
      </Table.HeaderCell>
      <Table.HeaderCell colSpan={1} textAlign="right">
        <Form initialValues={doc} fields={fields} onSubmit={onRoundingChange}>
          {() => {
            return (
              <div className="rounding-field" data-spec="rounding-field">
                <FormSpy
                  onSubmit={onRoundingChange}
                  subscription={{values: true}}
                  component={AutoSave}
                  debounce={100}
                />
                <Input path={path} name={path} />
              </div>
            );
          }}
        </Form>
      </Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  );
};

InvoiceFooterRoundingRow.propTypes = {
  cols: PropTypes.number.isRequired,
  pageConfig: pageConfigShape.isRequired,
  doc: PropTypes.object.isRequired,
  onRoundingChange: PropTypes.func.isRequired,
};

export default InvoiceFooterRoundingRow;
