import React from 'react';
import PropTypes from 'prop-types';
import {CollapseBox} from '@ecosio/components';
import {useIntl} from 'react-intl';
import EmptyStateTemplate from '../EmptyPageTemplate';
import {historyShape} from '../../../shapes/generalShapes';

/**
 * It is used as Empty state page, when create daily Invoice query returns an empty result
 */
const CreateViewEmptyState = ({
  history,
  dailyDocumentType,
  noButtons = false,
}) => {
  const intl = useIntl();
  return (
    <CollapseBox>
      <EmptyStateTemplate
        image={{
          height: 324,
          width: 388,
          src: `/webedi/assets/empty.svg`,
        }}
        subHeader={intl.formatMessage({
          id: `DAILY_${dailyDocumentType}_CREATE_VIEW_NO_POS_FOUND_SUB_HEADER`,
        })}
        header={intl.formatMessage({
          id: `DAILY_${dailyDocumentType}_CREATE_VIEW_NO_POS_FOUND_HEADER`,
        })}
        secondButton={
          !noButtons
            ? {
                onClick: history.goBack,
                text: intl.formatMessage({
                  id: `BACK_BUTTON_DAILY_${dailyDocumentType}_CREATE_VIEW`,
                }),
              }
            : undefined
        }
      />
    </CollapseBox>
  );
};

CreateViewEmptyState.propTypes = {
  noButtons: PropTypes.bool,
  history: historyShape.isRequired,
  dailyDocumentType: PropTypes.string.isRequired,
};

export default CreateViewEmptyState;
