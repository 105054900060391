import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Label, Popup, Header, List} from 'semantic-ui-react';
import {PartyName} from '../../General/PartyName';

export const TabbedItem = ({title, content, ...props}) => {
  if (!content) {
    return null;
  }

  return (
    <List.Item divided="true" {...props}>
      <List.Content floated="right">{content}</List.Content>
      <List.Content>
        <strong>
          <FormattedMessage id={title} />:
        </strong>
      </List.Content>
    </List.Item>
  );
};

TabbedItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
};

// TODO: Cleanup ...
export const BusinessEntity = ({data, headerId, headerSize, headerStyle}) => {
  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <Header size={headerSize || 'medium'} as="h2" style={headerStyle}>
        <FormattedMessage id={headerId} />
      </Header>
      <List>
        <List.Item>
          <b>
            <PartyName entity={data} />
          </b>
        </List.Item>
        {data.street && <List.Item>{data.street}</List.Item>}
        {data.postCode && data.city && (
          <List.Item>
            {data.postCode} {data.city}
          </List.Item>
        )}
        {data.contact && <BusinessContact contact={data.contact} />}
        {data.unloadingPoint && (
          <List.Item>
            <b>
              <FormattedMessage id="UNLOADING_POINT" />
            </b>
            : {data.unloadingPoint}
          </List.Item>
        )}
        {data.storageLocation && (
          <List.Item>
            <b>
              <FormattedMessage id="STORAGE_LOCATION" />
            </b>
            : {data.storageLocation}
          </List.Item>
        )}
      </List>
    </Fragment>
  );
};

BusinessEntity.propTypes = {
  data: PropTypes.object,
  headerId: PropTypes.string.isRequired,
  headerSize: PropTypes.string,
  headerStyle: PropTypes.object,
};

export const BusinessContact = ({contact}) => {
  if (!contact) {
    return null;
  }

  const {name, telephone, fax, email} = contact;

  return (
    <Fragment>
      {name && <List.Item content={name} />}
      {telephone && <List.Item content={telephone} />}
      {fax && <List.Item content={fax} />}
      {email && <List.Item content={email} />}
    </Fragment>
  );
};

BusinessContact.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    telephone: PropTypes.string,
    fax: PropTypes.string,
    email: PropTypes.string,
  }),
};

export const ReleaseStatusLabel = ({identifier}) => {
  // TODO: this should be configurable and normalized in the mapping
  // https://gitlab.ecosio.com/code/frontend/issues/513
  const identifierMap = [
    '',
    'RELEASE_STATUS_FIRM',
    'RELEASE_STATUS_PROD',
    'RELEASE_STATUS_MAT',
    'RELEASE_STATUS_PLAN',
    'RELEASE_STATUS_BACKLOG',
    '',
    '',
    '',
    '',
    'RELEASE_STATUS_IMMEDIATE',
  ];

  const releaseStatus = identifierMap[identifier];

  if (typeof releaseStatus === 'undefined') {
    return null;
  }

  return (
    <Popup
      trigger={
        <Label>
          <FormattedMessage id={identifierMap[identifier]} />
        </Label>
      }>
      <FormattedMessage id={`${identifierMap[identifier]}_TOOLTIP`} />
    </Popup>
  );
};

ReleaseStatusLabel.propTypes = {
  identifier: PropTypes.any,
};

export const TimeTargetLabel = ({label}) => {
  const colorMap = {
    D: 'red',
    W: 'orange',
    M: 'green',
  };

  return (
    <Popup
      trigger={
        <Label color={colorMap[label]}>
          <FormattedMessage id={`FREQUENCY_IDENTIFIER_${label}`} />
        </Label>
      }>
      <FormattedMessage id={`DELFOR_DELIVERY_${label}`} />
    </Popup>
  );
};

TimeTargetLabel.propTypes = {
  label: PropTypes.string,
};

export default TimeTargetLabel;
