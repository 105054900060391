import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import DynamicTemplate, {FlashMessage} from '../../DynamicTemplate';
import OrdersTemplate from '../Order/View/OrdersTemplate';
import DelforTemplate from '../Delfor/DelforTemplate';
import DesadvSimpleTemplate from '../Desadv/V1/SimpleDesadv/View/DesadvSimpleTemplate';
import DesadvComplexTemplate from '../Desadv/V1/ComplexDesadv/View/DesadvComplexTemplate';
import InvoiceTemplate from '../Invoice/View/ViewInvoiceTemplate';
import DesadvSimpleTemplateV2 from '../Desadv/V2/DesadvSimpleV2/View/ViewDesadvSimpleTemplateV2';
import DesadvComplexTemplateV1_1 from '../Desadv/V2/DesadvComplexV1_1/View/DesadvComplexViewTemplateV1_1';

const templates = {
  OrdersTemplate,
  DelforTemplate,
  DesadvComplexTemplate,
  DesadvComplexTemplateV1_1,
  DesadvSimpleTemplate,
  DesadvSimpleTemplateV2,
  InvoiceTemplate,
};

const ViewDocumentLayout = ({documentExchange, ...rest}) => {
  const detailTemplate = get(documentExchange, 'pageConfig.detailLayout');
  return (
    <div className="_si_doc_view">
      <FlashMessage />
      <DynamicTemplate
        {...rest}
        componentName={detailTemplate}
        documentExchange={documentExchange}
        options={{templates}}
      />
    </div>
  );
};

ViewDocumentLayout.propTypes = {
  documentExchange: PropTypes.object,
};

export const layoutOptions = {
  templates: {
    DEFAULT: ViewDocumentLayout,
  },
};
