/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import {Segment, Header, List} from 'semantic-ui-react';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {dynID} from '@ecosio/pathform';
import {intlShape} from '@ecosio/components';
import {cpsTransformation} from '../../../../../../util/desadvUtil';
import './DesadvComplexRender.css';
import {deliveryLineItemShape} from '../../../Utils/shapes';

const FieldContent = ({value, field, translate}) => {
  return value ? (
    <div style={{display: 'flex', marginRight: '15px'}}>
      <strong style={{marginRight: '5px'}}>
        <Msg id={field} />:
      </strong>
      {translate ? <Msg id={dynID(value)} /> : value}
    </div>
  ) : null;
};

FieldContent.defaultProps = {
  translate: false,
};

FieldContent.propTypes = {
  value: PropTypes.any,
  field: PropTypes.string,
  translate: PropTypes.bool,
};

const SerialNumbers = ({lin}) => (
  <Fragment>
    <Header as="h5">
      <Msg id="GENERAL_DESADV_SERIAL_NUMBER" />
    </Header>
    <List horizontal>
      {lin.serialNumbers.map((sn, idx) => (
        <List.Item key={`sn_${idx}`}>{sn}</List.Item>
      ))}
    </List>
  </Fragment>
);

SerialNumbers.propTypes = {
  lin: deliveryLineItemShape,
};

const renderTree = (nodes, intl, id = 0, pId = 'hierarchicalParentID') => {
  return nodes
    .filter((node) => {
      return node[pId] === id;
    })
    .map((node, nodeIdx) => {
      return (
        <Fragment key={nodeIdx}>
          {/* TODO: this should not be necessary - remove this before merging and simply do not render the root cps (1)*/}
          {node.packagingInformations.length === 0 ? null : (
            <Segment>
              {node.packagingInformations.map((info, infoIdx) => {
                return (
                  <Fragment key={infoIdx}>
                    <Header>
                      <Msg id={`DESADV_PKG_TYPE_${info.packageTypeCustomer}`} />
                    </Header>
                    <List horizontal>
                      <List.Item>
                        <FieldContent
                          value={info.grossWeight}
                          field="DESADV_GROSS_WEIGHT"
                        />
                      </List.Item>
                      <List.Item>
                        <FieldContent
                          value={info.netWeight}
                          field="DESADV_NET_WEIGHT"
                        />
                      </List.Item>
                      <List.Item>
                        <FieldContent
                          value={info.identifications[0].identityNumber}
                          field="DESADV_NVE"
                        />
                      </List.Item>
                    </List>
                  </Fragment>
                );
              })}
              {node.deliveryLineItems.length === 0 ? null : (
                <Fragment>
                  {node.deliveryLineItems.map((lineItem, lineItemIdx) => {
                    return (
                      <Segment key={lineItemIdx}>
                        <Header className="header">
                          <Msg id="DESADV_MAT_NR_HDR" />{' '}
                          {lineItem.customersItemMaterialNumber} /{' '}
                          {lineItem.suppliersItemMaterialNumber}
                          <br />
                          <span className="header-sub">
                            {lineItem.itemDescription}
                          </span>
                        </Header>
                        <List horizontal>
                          <List.Item>
                            <FieldContent
                              value={lineItem.itemCharacteristic}
                              field="DESADV_INTRASTAT_NUMBER"
                            />
                          </List.Item>
                          <List.Item>
                            <FieldContent
                              translate
                              value={
                                lineItem.countryOfOrigin?.countryCode
                                  ? `COUNTRY_${lineItem.countryOfOrigin?.countryCode}`
                                  : undefined
                              }
                              field="DESADV_COUNTRY_OF_ORIGIN"
                            />
                          </List.Item>
                          <List.Item>
                            <FieldContent
                              value={`${
                                lineItem.dispatchQuantity.value
                              } ${intl.formatMessage({
                                id: lineItem.dispatchQuantity.unit,
                              })}`}
                              field="DESADV_QUANTITY"
                            />
                          </List.Item>

                          <List.Item>
                            <FieldContent
                              translate
                              value={lineItem.batchNumber}
                              field="GENERAL_LOT_NUMBER"
                            />
                          </List.Item>
                        </List>
                        {Array.isArray(lineItem.serialNumbers) &&
                        lineItem.serialNumbers.length > 0 ? (
                          <SerialNumbers lin={lineItem} />
                        ) : null}
                      </Segment>
                    );
                  })}
                </Fragment>
              )}
              {/*TODO: need to remove duplicate code */}
              {node.cps.length === 0 ? null : (
                <Fragment>
                  {renderTree(node.cps, intl, node.hierarchicalID)}
                </Fragment>
              )}
            </Segment>
          )}
          {node.hierarchicalID !== 1 ? null : (
            <Fragment>
              {renderTree(node.cps, intl, node.hierarchicalID)}
            </Fragment>
          )}
        </Fragment>
      );
    });
};

const DesadvComplexRender = ({cps, intl}) => {
  const transformedCps = cpsTransformation(cps);
  return (
    <Fragment>
      <Header>
        <Msg id="COMPLEX_DESADV_HEADER" />
      </Header>
      {renderTree(transformedCps, intl)}
    </Fragment>
  );
};

DesadvComplexRender.propTypes = {
  //TODO: set proper proTypes for cps
  cps: PropTypes.any,
  intl: intlShape,
};

export default injectIntl(DesadvComplexRender);
