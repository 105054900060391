import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Content, RenderContents, ContentPath, dynID} from '@ecosio/pathform';
import {get} from 'lodash';
import {industryDocumentShape} from '../../../../shapes/industryDocument';
import {pageConfigShape} from '../../../../shapes/scenarios';
import {inMemoryValidatorShape} from '../../../../shapes/inMemoryValidatorShape';
import DocumentHeadEditForm from './Header/DocumentHeadEditForm';
import DocumentHeadView from './Header/DocumentHeadView';

export const BusinessEntityBlock = ({prefix, headline, initialEntityValue}) => (
  <ContentPath path={`header.businessEntities.${prefix}`}>
    {() => {
      return (
        <div data-spec={`${prefix}-address`}>
          <h3>
            <FormattedMessage id={headline} />
          </h3>
          <BusinessEntitySpan path="gln" prefix={prefix} contentLabel="GLN: " />
          <PartyNamesSpan prefix={prefix} />
          <BusinessEntitySpan path="street" prefix={prefix} />
          <BusinessEntitySpan path="postCode" prefix={prefix} noLineBreak />
          <BusinessEntitySpan path="city" prefix={prefix} />
          <BusinessEntitySpan path="countryCode" prefix={prefix} />
          <CountrySpan prefix={prefix} />
          <BusinessEntitySpan path="purchasingDepartment" prefix={prefix} />
          <BusinessEntityContactDetails
            initialEntityValue={initialEntityValue}
          />
        </div>
      );
    }}
  </ContentPath>
);

BusinessEntityBlock.propTypes = {
  prefix: PropTypes.string,
  headline: PropTypes.string,
};

export const BusinessEntityContactDetails = ({
  contentLabel,
  noLineBreak,
  initialEntityValue = {},
}) => {
  const contactExists = get(initialEntityValue, 'contact');
  return (
    <>
      <br />
      {contactExists ? (
        <React.Fragment>
          <strong>
            <FormattedMessage id="GENERAL_CONTACT_DATA" /> :
          </strong>
          <br />
        </React.Fragment>
      ) : (
        ``
      )}
      <BusinessEntityContent
        initialEntityValue={initialEntityValue}
        path="contact.name"
        contentLabel={contentLabel}
        noLineBreak={noLineBreak}
      />
      <BusinessEntityContent
        initialEntityValue={initialEntityValue}
        path="contact.telephone"
        contentLabel={contentLabel}
        noLineBreak={noLineBreak}
      />
      <BusinessEntityContent
        initialEntityValue={initialEntityValue}
        path="contact.email"
        contentLabel={contentLabel}
        noLineBreak={noLineBreak}
      />
    </>
  );
};

export const BusinessEntityContent = ({
  contentLabel,
  noLineBreak,
  initialEntityValue,
  path,
}) => {
  const content = get(initialEntityValue, path);
  if (content) {
    return (
      <React.Fragment>
        <strong>
          {contentLabel} {content}
        </strong>
        {!noLineBreak ? <br /> : ''}
      </React.Fragment>
    );
  }
  return null;
};

const BusinessEntityAttribute = ({path, prefix}) => (
  <Content
    path={`header.businessEntities.${prefix}.${path}`}
    render={({label, content}) => (
      <React.Fragment>
        <span>
          <FormattedMessage id={label} defaultMessage={label} />
        </span>
        : <span>{content}</span>
      </React.Fragment>
    )}
  />
);

BusinessEntityAttribute.propTypes = {
  path: PropTypes.string,
  prefix: PropTypes.string,
};

export const BusinessEntitySpan = ({
  path,
  prefix = 'supplier',
  contentLabel,
  noLineBreak,
}) => {
  return (
    <Content
      path={`header.businessEntities.${prefix}.${path}`}
      render={({content}) => {
        if (!content) {
          return null;
        }

        return (
          <React.Fragment>
            <strong>
              {contentLabel} {content}
            </strong>
            {!noLineBreak ? <br /> : ''}
          </React.Fragment>
        );
      }}
    />
  );
};

BusinessEntitySpan.propTypes = {
  path: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  contentLabel: PropTypes.string,
  noLineBreak: PropTypes.bool,
};

export const CountrySpan = ({prefix = 'supplier'}) => {
  // TODO: wrap in error boundary
  return (
    <Content
      path={`header.businessEntities.${prefix}`}
      render={({content}) => {
        const code = content?.country?.countryName
          ? content.country.countryName
          : content?.country?.countryCode;
        return (
          <React.Fragment>
            {code && (
              <strong>
                <FormattedMessage id={`COUNTRY_${code}`} />
              </strong>
            )}
            <br />
          </React.Fragment>
        );
      }}
    />
  );
};

CountrySpan.propTypes = {
  prefix: PropTypes.string.isRequired,
};

export const PartyNamesSpan = ({prefix = 'supplier'}) => {
  return (
    <Content
      path={`header.businessEntities.${prefix}`}
      render={({content}) => (
        <React.Fragment>
          {content &&
            content.partyNames &&
            Array.isArray(content.partyNames) &&
            content.partyNames.map((name) => (
              <React.Fragment key={name}>
                <strong>{name}</strong>
                <br />
              </React.Fragment>
            ))}
        </React.Fragment>
      )}
    />
  );
};

PartyNamesSpan.propTypes = {
  prefix: PropTypes.string.isRequired,
};

// TODO this should be renamed to something more generic, it serves
// as header for all document types
class OrderMeta extends Component {
  constructor(props) {
    super(props);
    const {documentExchange} = props;
    if (!documentExchange.document) {
      return;
    }

    this.state = {
      mode: 'view',
    };
  }

  onClickEditMode = () => {
    this.setState({
      mode: 'edit',
    });
  };

  onClickCancel = (e) => {
    e.preventDefault();
    this.setState({
      mode: 'view',
    });
  };

  onSubmit = (values) => {
    this.setState({
      mode: 'view',
    });
    return this.props.onSubmit(values);
  };

  render() {
    const {
      documentExchange,
      pageConfig,
      editable,
      onVatVariantChange,
      inMemoryValidation,
    } = this.props;
    const documentTypeId = pageConfig?.documentTypeId;

    // TODO: use the BusinessEntityComponent instead of this RenderContents block
    return (
      <div
        style={{marginBottom: '20px'}}
        className="_si_order_meta"
        data-spec="document-meta">
        <Grid columns={2} data-spec={`document_meta_${documentTypeId}`}>
          <Grid.Column width={10}>
            <Grid columns={3}>
              <Grid.Row>
                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const supplier = get(
                      documentExchange.document,
                      'header.businessEntities.supplier'
                    );

                    const supplierConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.supplier'
                    );
                    if (!supplier || !supplierConfig) {
                      return null;
                    }
                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.supplier">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="supplier"
                                  headline="GENERAL_SUPPLIER"
                                  initialEntityValue={supplier}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>
                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const buyer = get(
                      documentExchange.document,
                      'header.businessEntities.buyer'
                    );

                    const buyerConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.buyer'
                    );
                    if (!buyer || !buyerConfig) {
                      return null;
                    }

                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.buyer">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="buyer"
                                  headline="GENERAL_BUYER"
                                  initialEntityValue={buyer}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>
                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const consignor = get(
                      documentExchange.document,
                      'header.businessEntities.consignor'
                    );

                    const consignorConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.consignor'
                    );
                    if (!consignor || !consignorConfig) {
                      return null;
                    }
                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.consignor">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="consignor"
                                  headline="GENERAL_CONSIGNOR"
                                  initialEntityValue={consignor}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>

                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const consignee = get(
                      documentExchange.document,
                      'header.businessEntities.consignee'
                    );
                    const consigneeConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.consignee'
                    );

                    if (!consignee || !consigneeConfig) {
                      return null;
                    }

                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.consignee">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="consignee"
                                  headline="GENERAL_CONSIGNEE"
                                  initialEntityValue={consignee}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>
              </Grid.Row>
              <Grid.Row>
                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const originalBuyer = get(
                      documentExchange.document,
                      'header.businessEntities.originalBuyer'
                    );

                    const originalBuyerConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.originalBuyer'
                    );
                    if (!originalBuyer || !originalBuyerConfig) {
                      return null;
                    }

                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.originalBuyer">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="originalBuyer"
                                  headline={dynID(
                                    originalBuyerConfig?.input?.label
                                  )}
                                  initialEntityValue={originalBuyer}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>
                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const invoiceRecipient = get(
                      documentExchange.document,
                      'header.businessEntities.invoiceRecipient'
                    );

                    const invoiceRecipientConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.invoiceRecipient'
                    );
                    if (!invoiceRecipient || !invoiceRecipientConfig) {
                      return null;
                    }
                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.invoiceRecipient">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="invoiceRecipient"
                                  headline="GENERAL_INVOICE_RECIPIENT"
                                  initialEntityValue={invoiceRecipient}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>

                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const ultimateConsignee = get(
                      documentExchange.document,
                      'header.businessEntities.ultimateConsignee'
                    );
                    const ultimateConsigneeConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.ultimateConsignee'
                    );
                    if (!ultimateConsignee || !ultimateConsigneeConfig) {
                      return null;
                    }

                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.ultimateConsignee">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="ultimateConsignee"
                                  headline="GENERAL_ULTIMATE_CONSIGNEE"
                                  initialEntityValue={ultimateConsignee}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>
                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const shipFrom = get(
                      documentExchange.document,
                      'header.businessEntities.shipFrom'
                    );
                    const shipFromConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.shipFrom'
                    );
                    if (!shipFrom || !shipFromConfig) {
                      return null;
                    }

                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.shipFrom">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="shipFrom"
                                  headline="GENERAL_SHIP_FROM_PARTY"
                                  initialEntityValue={shipFrom}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>
                <RenderContents
                  initialValues={documentExchange.document}
                  fields={pageConfig.formFields}>
                  {() => {
                    const carrier = get(
                      documentExchange.document,
                      'header.businessEntities.carrier'
                    );
                    const carrierConfig = get(
                      pageConfig.formFields,
                      'header.businessEntities.carrier'
                    );

                    if (!carrier || !carrierConfig) {
                      return null;
                    }

                    return (
                      <Grid.Column>
                        <div className="adressBlock">
                          <ContentPath path="header.businessEntities.carrier">
                            {() => {
                              return (
                                <BusinessEntityBlock
                                  prefix="carrier"
                                  headline="GENERAL_CARRIER"
                                  initialEntityValue={carrier}
                                />
                              );
                            }}
                          </ContentPath>
                        </div>
                      </Grid.Column>
                    );
                  }}
                </RenderContents>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={6}>
            <div className="metaBlock">
              {!this.props.showOnlyForm && this.state.mode === 'view' ? (
                <DocumentHeadView
                  documentExchange={documentExchange}
                  pageConfig={pageConfig}
                  inMemoryValidation={inMemoryValidation}
                  onEditClick={this.onClickEditMode}
                  editable={editable}
                />
              ) : (
                <DocumentHeadEditForm
                  documentExchange={documentExchange}
                  pageConfig={pageConfig}
                  onSubmit={this.onSubmit}
                  onClickCancel={this.onClickCancel}
                  showOnlyForm={this.props.showOnlyForm}
                  onVatVariantChange={onVatVariantChange}
                />
              )}
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

OrderMeta.defaultProps = {
  showOnlyForm: false,
};

OrderMeta.propTypes = {
  showOnlyForm: PropTypes.bool,
  editable: PropTypes.bool,
  documentExchange: industryDocumentShape,
  pageConfig: pageConfigShape,
  onSubmit: PropTypes.func,
  onVatVariantChange: PropTypes.func.isRequired,
  inMemoryValidation: inMemoryValidatorShape,
};

export default OrderMeta;
