import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Input, dynID} from '@ecosio/pathform';
import styled from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';
import {get, isEmpty} from 'lodash';
import {FormattedMessage as Msg} from 'react-intl';
import {ItemsContainer} from '../DesadvLineItems';
import DesadvInput from '../../../../CommonComponents/Create/LineItems/DesadvInput';
import FieldContent from '../../../../CommonComponents/Create/LineItems/FieldContent';
import InputArrayValueElementsListComponent from '../../../../CommonComponents/Create/InputArrayValueElementsListComponent.jsx';

const StyledCheckBox = styled.div`
  div > div {
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid ${ecosioColors.greyShades[4]};
    background: ${ecosioColors.greyShades[7]};
    height: 37.8px;
    box-sizing: border-box;
    padding-left: 12px;
    ${(props) =>
      props.disabled
        ? `pointer-events: none;
      opacity: 0.6;
    input {
      display: none !important;
    }`
        : ''}
  }
`;

const StyledInputWithDropDownAsLabel = styled.div`
  div > div {
    input {
      width: 70% !important;
    }
    div {
      min-width: 30% !important;
      span {
        overflow-wrap: break-word !important;
      }
    }
  }
`;

export const getDispatchQuantityOptions = (lineItem) => {
  if (!lineItem && isEmpty(lineItem)) {
    return [];
  }

  const dispatchQuantityUnit = get(lineItem, 'dispatchQuantity.unit');
  const alternativeWebUIUnit = get(
    lineItem,
    'alternativeQuantity.alternativeWebUIUnit'
  );
  if (!(dispatchQuantityUnit && alternativeWebUIUnit)) {
    if (dispatchQuantityUnit) {
      return [{value: dispatchQuantityUnit, text: dispatchQuantityUnit}];
    }

    if (alternativeWebUIUnit) {
      return [{value: alternativeWebUIUnit, text: alternativeWebUIUnit}];
    }
    return [];
  }

  if (dispatchQuantityUnit !== alternativeWebUIUnit) {
    return [
      {value: dispatchQuantityUnit, text: dispatchQuantityUnit},
      {value: alternativeWebUIUnit, text: alternativeWebUIUnit},
    ];
  }

  return [{value: dispatchQuantityUnit, text: dispatchQuantityUnit}];
};

const LineItemEditFields = ({
  index,
  formFieldConfig,
  partialDeliveryAllowed,
  formContext,
  initialValues,
  sscc,
  exposedValues,
  setDelisOpacity,
  delisOpacity,
}) => {
  const dispatchQuantityUnit = formContext.form.getFieldState(
    `deliveryLineItems[${index}].dispatchQuantity.unit`
  )?.value;

  const dispatchQuantityUnitTranslation = dispatchQuantityUnit
    ? {
        labelPosition: 'right',
        inputLabel: {
          basic: true,
          content: <Msg id={dynID(dispatchQuantityUnit)} />,
          attached: 'bottom right',
        },
      }
    : {};

  const remainingQuantityConfig = get(
    formFieldConfig,
    'remainingQuantityAggregateForCompletePosition.value'
  );

  if (remainingQuantityConfig) {
    const partialDelivery = formContext.form.getFieldState(
      `deliveryLineItems[${index}].partialDelivery`
    )?.value;

    if (partialDelivery !== true) {
      formContext.form.change(
        `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.unit`,
        dispatchQuantityUnit
      );

      formContext.form.change(
        `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.value`,
        0
      );
    }
    if (partialDelivery === true) {
      const dispatchQuantity = formContext.form.getFieldState(
        `deliveryLineItems[${index}].dispatchQuantity.value`
      )?.value;

      const requestedQuantity = get(
        initialValues[index],
        `requestedQuantity.value`
      );

      const remainingQuantity = requestedQuantity - dispatchQuantity;

      let result = 0;

      if (remainingQuantity > 0) {
        result = remainingQuantity;
      }

      formContext.form.change(
        `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.unit`,
        dispatchQuantityUnit
      );

      formContext.form.change(
        `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.value`,
        result
      );
    }
  }

  return (
    <ItemsContainer
      key={`Edit_deliveryLineItems_${index}`}
      className="desadvLineItemEditComponent">
      <DesadvInput
        columnWidth={1}
        path="partialDelivery"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <StyledCheckBox disabled={!partialDeliveryAllowed}>
            <Input
              {...props}
              name={`deliveryLineItems[${index}].partialDelivery`}
              path="partialDelivery"
              defaultValue={false}
              value={
                formContext.form.getFieldState(
                  `deliveryLineItems[${index}].partialDelivery`
                )?.value
              }
              onInputChange={(e, value) => {
                if (value === true) {
                  formContext.form.change(
                    `deliveryLineItems[${index}].dispatchQuantity.value`,
                    0
                  );
                }

                return null;
              }}
              toggle
            />
          </StyledCheckBox>
        )}
      />

      <DesadvInput
        columnWidth={2}
        path="dispatchQuantity.value"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <StyledInputWithDropDownAsLabel>
            <Input
              {...props}
              action={
                <Input
                  renderLabel={false}
                  options={getDispatchQuantityOptions(initialValues[index])}
                  disabled={
                    getDispatchQuantityOptions(initialValues[index]).length ===
                    1
                  }
                  onInputChange={() => {
                    formContext.form.change(
                      `deliveryLineItems[${index}].dispatchQuantity.value`,
                      0
                    );

                    if (remainingQuantityConfig) {
                      formContext.form.change(
                        `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.unit`,
                        dispatchQuantityUnit
                      );

                      formContext.form.change(
                        `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.value`,
                        0
                      );
                    }

                    return null;
                  }}
                  name={`deliveryLineItems[${index}].dispatchQuantity.unit`}
                  path="dispatchQuantity.unit"
                  type="number"
                />
              }
              name={`deliveryLineItems[${index}].dispatchQuantity.value`}
              path="dispatchQuantity.value"
              onInputChange={(e, value) => {
                const fieldConfig = get(
                  formFieldConfig,
                  'dispatchQuantity.value'
                );
                if (typeof value === fieldConfig.input.type) {
                  formContext.form.change(
                    `deliveryLineItems[${index}].dispatchQuantity.value`,
                    value
                  );
                }
                if (remainingQuantityConfig) {
                  const partialDelivery = formContext.form.getFieldState(
                    `deliveryLineItems[${index}].partialDelivery`
                  )?.value;

                  if (partialDelivery === true) {
                    const dispatchQuantity = formContext.form.getFieldState(
                      `deliveryLineItems[${index}].dispatchQuantity.value`
                    )?.value;

                    const requestedQuantity = get(
                      initialValues[index],
                      `requestedQuantity.value`
                    );

                    const remainingQuantity =
                      requestedQuantity - dispatchQuantity;

                    let result = 0;

                    if (remainingQuantity > 0) {
                      result = remainingQuantity;
                    }

                    formContext.form.change(
                      `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.unit`,
                      dispatchQuantityUnit
                    );

                    formContext.form.change(
                      `deliveryLineItems[${index}].remainingQuantityAggregateForCompletePosition.value`,
                      result
                    );
                  }
                }

                // when userInput from dispatchQuantity is 0 then quantity per pack should be 1
                if (value === '0') {
                  formContext.form.change(
                    `deliveryLineItems[${index}].quantityPerPack`,
                    1
                  );
                }

                if (Number(value) !== 0) {
                  delisOpacity[index] = 0;
                  setDelisOpacity(delisOpacity);
                }

                if (Number(value) === 0) {
                  delisOpacity[index] = 0.65;
                  setDelisOpacity(delisOpacity);
                }

                return null;
              }}
            />
          </StyledInputWithDropDownAsLabel>
        )}
      />
      {sscc ? (
        <Fragment>
          <DesadvInput
            columnWidth={2.5}
            path="quantityPerPack"
            formFieldConfig={formFieldConfig}
            exposedValues={exposedValues}
            lineItemIndex={index}
            render={(props) => (
              <Input
                {...props}
                {...dispatchQuantityUnitTranslation}
                name={`deliveryLineItems[${index}].quantityPerPack`}
                path="quantityPerPack"
                defaultValue={
                  //when dispatch quantity is 0, then default value of quantity per pack should be 1, customer requirement
                  formContext.form.getFieldState(
                    `deliveryLineItems[${index}].dispatchQuantity.value`
                  )?.value === 0
                    ? 1
                    : undefined
                }
                onInputChange={(e, value) => {
                  const fieldConfig = get(formFieldConfig, 'quantityPerPack');
                  if (typeof value === fieldConfig.input.type) {
                    formContext.form.change(
                      `deliveryLineItems[${index}].quantityPerPack`,
                      value
                    );
                  }
                  return null;
                }}
              />
            )}
          />
          <DesadvInput
            columnWidth={2.5}
            path="packagingInformations.numberOfPackages"
            formFieldConfig={formFieldConfig}
            exposedValues={exposedValues}
            lineItemIndex={index}
            render={(props) => (
              <FieldContent
                {...props}
                value={
                  formContext.form.getFieldState(
                    `deliveryLineItems[${index}].dispatchQuantity.value`
                  )?.value &&
                  formContext.form.getFieldState(
                    `deliveryLineItems[${index}].quantityPerPack`
                  )?.value
                    ? Math.ceil(
                        (formContext.form.getFieldState(
                          `deliveryLineItems[${index}].dispatchQuantity.value`
                        )?.value || 0) /
                          (formContext.form.getFieldState(
                            `deliveryLineItems[${index}].quantityPerPack`
                          )?.value || 0)
                      )
                    : 0
                }
                unit="LE_PACKAGES"
                fieldConfig={get(
                  formFieldConfig,
                  'packagingInformations.numberOfPackages'
                )}
              />
            )}
          />
        </Fragment>
      ) : (
        ''
      )}
      <DesadvInput
        columnWidth={3}
        path="primaryPackagingType.value"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <Input
            {...props}
            name={`deliveryLineItems[${index}].primaryPackagingType.value`}
            path="primaryPackagingType.value"
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="countryOfOrigin.countryName"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <Input
            {...props}
            name={`deliveryLineItems[${index}].countryOfOrigin.countryName`}
            path="countryOfOrigin.countryName"
          />
        )}
      />
      <DesadvInput
        columnWidth={2}
        path="batchNumber"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <Input
            {...props}
            name={`deliveryLineItems[${index}].batchNumber`}
            path="batchNumber"
          />
        )}
      />

      <DesadvInput
        columnWidth={2}
        path="expirationDate"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <Input
            {...props}
            name={`deliveryLineItems[${index}].expirationDate`}
            path="expirationDate"
            stripTimezone
          />
        )}
      />
      <DesadvInput
        columnWidth={2}
        path="manufacturingDepartments"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <InputArrayValueElementsListComponent
            inputArrayName={`deliveryLineItems[${index}].manufacturingDepartments`}
            inputName={`manufacturingDepartments[${index}]`}
            path="manufacturingDepartments"
            maxArrayElements={2} // https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/1003
            {...props}
          />
        )}
      />
      <DesadvInput
        columnWidth={2}
        path="ggns"
        formFieldConfig={formFieldConfig}
        exposedValues={exposedValues}
        lineItemIndex={index}
        render={(props) => (
          <InputArrayValueElementsListComponent
            inputArrayName={`deliveryLineItems[${index}].ggns`}
            inputName={`ggns[${index}]`}
            path="ggns"
            maxArrayElements={5} // https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/1003
            {...props}
          />
        )}
      />
    </ItemsContainer>
  );
};

LineItemEditFields.propTypes = {
  index: PropTypes.number.isRequired,
  formFieldConfig: PropTypes.object.isRequired,
  partialDeliveryAllowed: PropTypes.bool.isRequired,
  formContext: PropTypes.object.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  sscc: PropTypes.bool.isRequired,
};

export default LineItemEditFields;
