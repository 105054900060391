/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {has} from 'lodash';
import jexl from 'jexl';

const calulateSemanticWidth = (props) => {
  let width = 'auto';
  const columnWidth = props?.columnWidth;
  if (
    columnWidth &&
    typeof columnWidth === 'number' &&
    columnWidth >= 1 &&
    columnWidth <= 16
  ) {
    width = `${(columnWidth / 16) * 100}%`;
  }

  return width;
};

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => calulateSemanticWidth(props)};
  min-width: 82px;
  padding: 11px;
  ${(props) =>
    props.disabled
      ? `pointer-events: none;  
    opacity: 0.6;
    input:focus { display:none }
    `
      : ''}
`;
const DesadvInput = ({
  render,
  forceRender,
  path,
  formFieldConfig,
  columnWidth,
  shouldNotRender,
  exposedValues,
  lineItemIndex,
  readOnlyField,
  disabled,
  ...rest
}) => {
  let disableField = false;

  if (
    (!forceRender && !has(formFieldConfig, path)) ||
    !render ||
    typeof render !== 'function' ||
    shouldNotRender
  ) {
    return null;
  }

  if (
    !readOnlyField &&
    formFieldConfig[path]?.conditionalDesadvFieldDisablingAndHiding
  ) {
    const expression = formFieldConfig[
      path
    ]?.conditionalDesadvFieldDisablingAndHiding?.disableOrHideDesadvFieldExpression.replace(
      '[INDEX_SPACEHOLDER]',
      `[${lineItemIndex}]`
    );

    disableField = jexl.evalSync(expression, exposedValues);
  }

  //when disable condition is true and hiding flag is set dont render anything
  if (
    disableField &&
    formFieldConfig[path]?.conditionalDesadvFieldDisablingAndHiding?.hideField
  ) {
    return null;
  }

  return (
    <StyledInput disabled={disableField} columnWidth={columnWidth} {...rest}>
      {render({disabled: disableField || disabled, ...rest})}
    </StyledInput>
  );
};

DesadvInput.defaultProps = {
  shouldNotRender: false,
  forceRender: false,
  formFieldConfig: {},
  exposedValues: {},
  lineItemIndex: 0,
  readOnlyField: false,
  disabled: false,
};

DesadvInput.propTypes = {
  columnWidth: (props, propName, componentName) => {
    componentName = componentName || 'ANONYMOUS';
    if (props[propName]) {
      const value = props[propName];
      if (typeof value === 'number') {
        return value >= 1 && value <= 16
          ? null
          : new Error(`${propName} in ${componentName} is not within 1 to 16`);
      }
    }

    return null;
  },
  render: PropTypes.func.isRequired,
  forceRender: PropTypes.bool,
  path: PropTypes.string.isRequired,
  formFieldConfig: PropTypes.object.isRequired,
  shouldNotRender: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DesadvInput;
