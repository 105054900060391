import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Form, FormField} from 'semantic-ui-react';
import {useIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import {connect} from 'react-redux';
import {
  MONTHS,
  DATE_MONTH_NAME_AND_YEAR_FORMAT,
  DATE_YEAR_MONTH_DAY_FORMAT,
} from '../../helpers/constants';

const moment = extendMoment(Moment);

function CreateMonthlyReportDropDownSelector(props) {
  const {onSelect, modalConfig, locale} = props;
  const intl = useIntl();
  const reportMonthField = modalConfig?.reportMonthField;

  const options = buildMonthlyReportModalDropDownOptions(
    modalConfig,
    locale,
    intl
  );
  const lableTranslationKey = dynID(reportMonthField?.input?.label);
  return (
    <Form>
      <FormField>
        <label>{intl.formatMessage({id: lableTranslationKey})}</label>
        <Dropdown
          data-spec="monthly-report-modal-dropdown"
          placeholder={intl.formatMessage({
            id: `${lableTranslationKey}_PLACEHOLDER`,
          })}
          selection
          fluid
          options={options}
          onChange={(event, data) => onSelect(data)}
        />
      </FormField>
    </Form>
  );
}

const mapStateToProps = ({locales}) => ({
  locale: locales.locale,
});

CreateMonthlyReportDropDownSelector.defaultProps = {
  locale: 'en',
};

CreateMonthlyReportDropDownSelector.propTypes = {
  modalConfig: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  locale: PropTypes.string,
};

export default connect(mapStateToProps)(CreateMonthlyReportDropDownSelector);

const buildMonthlyReportModalDropDownOptions = (modalConfig, locale, intl) => {
  const result = [];

  const firstReportMonth = modalConfig?.firstReportMonth;
  const lastMonthsLimit = modalConfig?.lastMonthsLimit;
  if (firstReportMonth) {
    const startDate = moment(firstReportMonth);
    const endDate = moment();
    const range = moment.range(startDate, endDate.subtract(1, MONTHS));
    const rangeByMonthArray = Array.from(range.by(MONTHS));

    rangeByMonthArray.forEach((d) =>
      result.push({
        text: intl.formatMessage(
          {
            id: 'REPORT_FOR',
          },
          {date: d.locale(locale).format(DATE_MONTH_NAME_AND_YEAR_FORMAT)}
        ),
        value: d.format(DATE_YEAR_MONTH_DAY_FORMAT),
      })
    );
  } else if (lastMonthsLimit) {
    const startDate = moment().subtract(lastMonthsLimit, MONTHS);
    const endDate = moment().subtract(1, MONTHS);
    const range = moment.range(startDate, endDate);
    const rangeByMonthArray = Array.from(range.by(MONTHS));

    // eslint-disable-next-line radar/no-identical-functions
    rangeByMonthArray.forEach((d) =>
      result.push({
        text: intl.formatMessage(
          {
            id: 'REPORT_FOR',
          },
          {date: d.locale(locale).format(DATE_MONTH_NAME_AND_YEAR_FORMAT)}
        ),
        value: d.format(DATE_YEAR_MONTH_DAY_FORMAT),
      })
    );
  }
  return result;
};
