import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon} from 'semantic-ui-react';

const ActionButton = ({type, onClick, ...rest}) => {
  const types = {
    remove: {
      icon: 'trash',
      color: 'red',
    },
    add: {
      icon: 'plus',
      color: 'purple',
    },
  };

  return onClick ? (
    <Button
      data-spec={`action-${type}`}
      compact
      icon
      color={types[type].color}
      size="tiny"
      type="button"
      onClick={onClick}
      {...rest}
      {...types}>
      <Icon name={types[type].icon} />
    </Button>
  ) : null;
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['remove', 'add']).isRequired,
};

export default ActionButton;
