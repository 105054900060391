import React, {Component} from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {get} from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {startPolling, startFileDownload} from '../../reducers/fileDownload';
import {loadTheme} from '../../helpers/utils';
import {MONTHLY_REPORT, MONTHLY_REPORT_CSV} from '../../helpers/constants';

class MonthlyReportPDFDownloadButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfConfiguration: get(
        this.props.downloadConfigurations,
        this.props.downloadRenderingKey,
        {}
      ),
      loading: false,
    };
  }

  onItemClicked = () => {
    const {
      locale,
      scenarioUuid,
      pageConfigUuid,
      reportMessageUuid,
      downloadRenderingKey,
    } = this.props;
    this.setState({loading: true});
    const {pdfRenderingConfigID, fileFormat} = this.state.pdfConfiguration;

    this.props
      .startFileDownload(
        null,
        null,
        scenarioUuid,
        locale,
        pdfRenderingConfigID,
        fileFormat,
        pageConfigUuid,
        false,
        downloadRenderingKey,
        reportMessageUuid
      )
      .then(({downloadId}) => this.props.startPolling(downloadId))
      .catch((err) => console.error(err));
  };

  render() {
    const {index, fileDownload, theme, downloadRenderingKey} = this.props;
    const {loading} = this.state;
    const polling = fileDownload.polling && loading;

    const currentTheme = loadTheme(theme);

    if (!polling && loading) {
      this.setState({loading: false});
    }
    return (
      <Button
        data-spec={`downloadLink-${index}`}
        onClick={() => this.onItemClicked()}
        primary
        loading={polling}
        compact
        icon
        style={{
          backgroundColor: 'transparent',
          color: currentTheme?.primaryColor,
        }}>
        <Icon name="download" />
        <FormattedMessage id={dynID(`${downloadRenderingKey}_DOWNLOAD`)} />
      </Button>
    );
  }
}

const mapStateToProps = ({fileDownload, locales, config}) => ({
  fileDownload,
  locale: locales.locale,
  theme: config?.userConfig?.theme,
});

MonthlyReportPDFDownloadButton.defaultProps = {
  locale: 'en',
};
MonthlyReportPDFDownloadButton.propTypes = {
  downloadConfigurations: PropTypes.object,
  locale: PropTypes.string,
  scenarioUuid: PropTypes.string.isRequired,
  pageConfigUuid: PropTypes.string.isRequired,
  reportMessageUuid: PropTypes.string.isRequired,
  startFileDownload: PropTypes.func.isRequired,
  startPolling: PropTypes.func.isRequired,
  fileDownload: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
  downloadRenderingKey: PropTypes.oneOf({
    MONTHLY_REPORT,
    MONTHLY_REPORT_CSV,
  }).isRequired,
};

export default injectIntl(
  connect(mapStateToProps, {
    startFileDownload,
    startPolling,
  })(MonthlyReportPDFDownloadButton)
);
