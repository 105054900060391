import PropTypes from 'prop-types';
import {shapes} from '@ecosio/auth';

// TODO: replace with @ecosio/auth config.shape

const configShape = PropTypes.shape({
  userConfig: shapes.userConfig,
  authServer: PropTypes.string,
});
export default configShape;
