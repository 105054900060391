import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {DataGrid} from '@ecosio/components';
import {
  STATUS_NEW,
  STATUS_UPDATED,
  STATUS_ARCHIVED,
} from '../../shapes/supportedEnvelopeStati';
import {scenarioShape} from '../../shapes/scenarios';
import {
  generateLocalStorageKey,
  getFromLocalStorage,
} from '../../helpers/localStorageUtil';
import getGridConfig from './getGridConfig';
import {ENVELOPE_SORT, ENVELOPE_COLUMN_VISIBILITY} from '.';
import './EnvelopeDataGridTable.css';

const rowFormatter = (row) => {
  return {
    'data-spec': `row-${row?.correlatorId}`,
    positive: row.status === STATUS_NEW || row.status === STATUS_UPDATED,
    custom: {
      condition: row.status === STATUS_ARCHIVED,
      style: {color: '#A0A0A0'},
    },
  };
};

/**
 *
 * @param {*} scenarioUuid scenarioUuid
 * @param {*} userID userID
 * @param {*} sortData , should be 'direction' or 'columnName'
 */
export const extractUserSort = (scenarioUuid, userID, sortData) => {
  const key = generateLocalStorageKey(ENVELOPE_SORT, scenarioUuid, userID);

  const userSort = getFromLocalStorage(key)?.sort?.split(',');

  if (!Array.isArray(userSort) || userSort.length === 0) {
    // returning null here is fine, fallback happens in getInitialSortColumn() and getInitialSortDirection()
    return null;
  }

  if (userSort[0] && sortData === 'columnName') {
    return userSort[0];
  }
  if (userSort[1] && sortData === 'direction') {
    const map = {
      asc: 'ascending',
      desc: 'descending',
    };

    return map[userSort[1]];
  }
  return null;
};

class EnvelopesGrid extends Component {
  constructor(props) {
    super(props);
    this.emptyState = {
      icon: 'envelope square',
      header: 'GENERAL_NO_DOCUMENTS_HEADER',
      subHeader: 'GENERAL_NO_DOCUMENTS_SUBHEADER',
    };
  }

  getInitialSortColumn() {
    const {scenario, userID} = this.props;
    const defaultSortEnvelopes = scenario?.defaultSortEnvelopes;
    const userSortColumnName = extractUserSort(
      scenario?.uuid,
      userID,
      'columnName'
    );
    const initialSortColumn = userSortColumnName
      ? userSortColumnName
      : defaultSortEnvelopes?.columnName;

    return initialSortColumn ? initialSortColumn : 'createdAt';
  }

  getInitialSortDirection() {
    const {scenario, userID} = this.props;

    const defaultSortEnvelopes = scenario?.defaultSortEnvelopes;
    const userSortDirection = extractUserSort(
      scenario?.uuid,
      userID,
      'direction'
    );
    const initialSortDirection = userSortDirection
      ? userSortDirection
      : defaultSortEnvelopes?.sortOrder === 'ASC'
      ? 'ascending'
      : 'descending';

    return initialSortDirection ? initialSortDirection : 'descending';
  }

  render() {
    const {
      data,
      actions,
      onSort,
      fetching,
      renderFooter,
      loadMessage,
      MetaComponent,
      customPropertiesConfig,
      scenario,
      scenarioUuid,
      userID,
      draftsPerEnvelope,
    } = this.props;

    const gridData = data.map((item) => {
      const cprops = {};
      item.customProperties.forEach((prop) => {
        cprops[prop.path] = prop.value;
      });

      // Unwrap referenced documents
      if (item.referencingDocuments) {
        const referencesDocsArrays = [];
        // Get referencing docs arrays for each referenced envelope
        for (const refDocArray of Object.values(item.referencingDocuments)) {
          // Merge it in one array
          for (const refDoc of refDocArray) {
            referencesDocsArrays.push(refDoc);
          }
        }

        for (const docRef of referencesDocsArrays) {
          // Add only unique document uuids -> avoid duplicated
          if (
            item.documents.find(
              (doc) => doc.documentUuid === docRef.documentUuid
            ) === undefined
          ) {
            item.documents.push(docRef);
          }
        }
      }
      return {
        ...item,
        ...cprops,
      };
    });

    const config = getGridConfig({
      customPropertiesConfig,
      scenario,
      scenarioUuid,
      draftsPerEnvelope,
    });

    return (
      <Fragment>
        <DataGrid
          initialSortColumn={this.getInitialSortColumn()}
          initialSortDirection={this.getInitialSortDirection()}
          data-spec="envelopes"
          saveCols={generateLocalStorageKey(
            ENVELOPE_COLUMN_VISIBILITY,
            scenarioUuid,
            userID
          )}
          translated
          hideableCols
          actions={actions}
          onSort={onSort}
          loading={fetching}
          data={gridData}
          config={config}
          renderFooter={renderFooter}
          MetaComponent={MetaComponent}
          rowFormatter={rowFormatter}
          emptyState={this.emptyState}
          loadMessage={loadMessage}
          //reset selections, when scenarios are switched
          selectable={fetching}
        />
      </Fragment>
    );
  }
}

EnvelopesGrid.propTypes = {
  userID: PropTypes.string.isRequired,
  customPropertiesConfig: PropTypes.array,
  MetaComponent: PropTypes.object,
  actions: PropTypes.array,
  data: PropTypes.any,
  fetching: PropTypes.bool,
  onSort: PropTypes.func,
  loadMessage: PropTypes.any,
  renderFooter: PropTypes.func,
  scenario: scenarioShape,
  scenarioUuid: PropTypes.string,
};

export default EnvelopesGrid;
