import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
  ClickOutsideDivCloseFunction,
  FeatureBaseIFrameComponent,
  intlShape,
  toast,
} from '@ecosio/components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {injectIntl} from 'react-intl';
import {STANDARD_TOAST_DURATION} from '../../helpers/constants';

const NewsAndFeedbackBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
`;
const NewsAndFeedbackComponent = ({
  newsFeedbackConfiguration,
  renderView,
  closeViewCallbackFn,
  intl,
}) => {
  const [featurebaseSSOToken, setFeaturebaseSSOToken] = useState(null);
  const [componentOpened, setComponentOpened] = useState(false);
  const [
    triggerFeaturebaseAppClosing,
    setTriggerFeaturebaseAppClosing,
  ] = useState(0);

  useEffect(() => {
    if (renderView) {
      setComponentOpened(true);
      if (!featurebaseSSOToken) {
        //               Fetch SSO token from backend before rendering IFrame
        axios
          .get(newsFeedbackConfiguration.fetchSSOTokenUrl)
          .then((res) => {
            if (res.status === 200) {
              setFeaturebaseSSOToken(res.data.ssoToken);
            }
          })
          .catch((err) => {
            const errMsg = err.response?.data?.errorTranslationKey
              ? err.response?.data?.errorTranslationKey
              : 'ERR_FETCH_SSO_TOKEN_FAILED';
            console.warn(
              `Unable to load SSO token for featurebase iframe ${errMsg}`
            );
            toast({
              title: intl.formatMessage({
                id: 'ERR_NEWS_FEEDBACK_CANNOT_OPEN',
              }),
              description: intl.formatMessage({
                id: errMsg,
              }),
              type: 'error',
              time: STANDARD_TOAST_DURATION,
            });
          });
      }
    }
  }, [intl, renderView, newsFeedbackConfiguration, featurebaseSSOToken]);
  const closeFeatureBaseApp = () => {
    setComponentOpened(false);
    setTriggerFeaturebaseAppClosing(0);
    closeViewCallbackFn();
  };

  // Due to open/close animation in the FeatureBaseIFrameComponent, parent app needs to
  // signal closing action with any > 0 integer ando once the animation is done
  // FeatureBaseIFrameComponent for call the provided closeIFrameFn function
  // in this case - closeFeatureBaseApp()
  const signalFeatureBaseAppClosing = () => {
    setTriggerFeaturebaseAppClosing(triggerFeaturebaseAppClosing + 1);
  };

  return (
    <div>
      {componentOpened ? (
        <NewsAndFeedbackBackground>
          <ClickOutsideDivCloseFunction onClick={signalFeatureBaseAppClosing}>
            {featurebaseSSOToken?.length > 0 && (
              <FeatureBaseIFrameComponent
                featureBaseConfig={newsFeedbackConfiguration}
                ssoToken={featurebaseSSOToken}
                closeIFrameFn={closeFeatureBaseApp}
                closeIFrameTriggerFlag={triggerFeaturebaseAppClosing}
              />
            )}
          </ClickOutsideDivCloseFunction>
        </NewsAndFeedbackBackground>
      ) : null}
    </div>
  );
};

NewsAndFeedbackComponent.propTypes = {
  newsFeedbackConfiguration: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  closeViewCallbackFn: PropTypes.func.isRequired,
};

export default injectIntl(NewsAndFeedbackComponent);
