/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Field} from 'react-final-form';
import {Input, InputArray} from '@ecosio/pathform';
import {connect} from 'react-redux';

export const newScheduleItem = () => ({scheduledQuantity: '0'});

const OrdersScheduleLine = ({fields, name, index, unit, locale}) => (
  <Grid.Row className="condensed">
    <Grid.Column width={6}>
      <Input
        name={`${name}.scheduledQuantityDate.dateTime`}
        path="ordersScheduleLines.scheduledQuantityDate.dateTime"
        stripTimezone
        locale={locale}
        size="mini"
        forceRendering
        includeTime={false}
      />
    </Grid.Column>
    <Grid.Column width={6}>
      <Input
        name={`${name}.scheduledQuantity`}
        path="ordersScheduleLines.scheduledQuantity"
        size="mini"
        inputLabel={{
          basic: true,
          content: <FormattedMessage id={unit || ''} />,
        }}
      />
    </Grid.Column>
    <Grid.Column width={4} textAlign="right">
      {fields.length > 1 ? (
        <Button
          data-spec={`remove-scheduleLine-${index}`}
          color="red"
          icon
          compact
          size="tiny"
          type="button"
          onClick={() => fields.remove(index)}>
          <Icon name="trash" />
        </Button>
      ) : null}
      {/*
      <Button type="button" size="tiny" onClick={() => console.log('split')}>
        <Icon name="bars" />
      </Button>
      */}
    </Grid.Column>
  </Grid.Row>
);

OrdersScheduleLine.propTypes = {
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  unit: PropTypes.string,
  locale: PropTypes.string,
};

OrdersScheduleLine.defaultProps = {
  locale: 'en',
};

const OrderScheduleLines = ({push, lineItem, lineItemConfig, locale}) => {
  return (
    <Grid className="_si_ord_sl">
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h3" textAlign="left">
            <FormattedMessage id="GENERAL_DELIVERIES" />
          </Header>
        </Grid.Column>
        {lineItemConfig.allowAddScheduleLine ? (
          <Grid.Column textAlign="right">
            <Button
              data-spec="add-scheduleLine-button"
              primary
              type="button"
              size="mini"
              compact
              icon
              onClick={() => push('ordersScheduleLines', newScheduleItem())}>
              <Icon name="add" />
            </Button>
          </Grid.Column>
        ) : null}
      </Grid.Row>
      <InputArray name="ordersScheduleLines">
        {({fields}) => (
          <React.Fragment>
            {fields.map((name, index) => (
              <OrdersScheduleLine
                unit={lineItem.measureUnitQualifier || ''}
                key={index}
                fields={fields}
                index={index}
                name={name}
                locale={locale}
              />
            ))}
          </React.Fragment>
        )}
      </InputArray>
      <Grid.Row className="bottom-row">
        <Field
          name="orderedQuantity"
          render={({input}) => (
            <React.Fragment>
              <Grid.Column width={6} textAlign="left">
                <label className="total-quantity">
                  <FormattedMessage id="GENERAL_TOTAL_QUANTITY" />
                </label>
              </Grid.Column>
              <Grid.Column width={6} textAlign="right">
                <span className="total-quantity"> {input.value}</span>{' '}
                <span>
                  <FormattedMessage id={lineItem.measureUnitQualifier || ''} />
                </span>
              </Grid.Column>
            </React.Fragment>
          )}
        />
      </Grid.Row>
    </Grid>
  );
};

OrderScheduleLines.propTypes = {
  push: PropTypes.func.isRequired,
  lineItem: PropTypes.object,
  lineItemConfig: PropTypes.object,
};

const mapStateToProps = ({locales}) => ({
  locale: locales.locale,
});
export default connect(mapStateToProps)(OrderScheduleLines);
