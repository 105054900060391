import {ecosioColors} from '@ecosio/customer-layout';
import {Header} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import styled from 'styled-components';

export const DashboardUserInfoHeader = styled.b`
  color: ${(props) => props.textColor || 'black'};
`;

export const DashboardHeaderTasksMessage = styled.div`
  color: ${ecosioColors.greyShades[1]};
  b {
    padding: 1px 4px;
    border-radius: 5px;
    background-color: ${ecosioColors.greyShades[5]};
    color: ${ecosioColors.greyShades[0]};
  }
`;

const DashboardHeader = ({
  intl,
  currentTheme,
  firstName,
  lastName,
  stats,
  isLoading,
}) => {
  return (
    <Header as="h1">
      <Header.Content>
        {intl.formatMessage({
          id: 'DASHBOARD_GREETING',
        })}
        ,{' '}
        <DashboardUserInfoHeader textColor={currentTheme?.primaryColor}>
          {firstName} {lastName}
        </DashboardUserInfoHeader>
      </Header.Content>
      <Header.Subheader>
        <DashboardHeaderTasksMessage>
          {!isLoading && stats && (
            <FormattedMessage
              id="DASHBOARD_HEADER_TASKS"
              values={{
                ...stats,
                b: (chunks) => <b>{chunks}</b>,
              }}></FormattedMessage>
          )}
        </DashboardHeaderTasksMessage>
      </Header.Subheader>
    </Header>
  );
};

export default DashboardHeader;
