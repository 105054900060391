import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import axios from 'axios';
import {toast} from '@ecosio/components';
import {injectIntl} from 'react-intl';
import useInterval from '../hooks/useInterval';
import {STANDARD_TOAST_DURATION} from '../constants';

const ScenarioChecksumCheck = (props) => {
  const {userConfig, scenario, intl} = props;
  const [checksumCheckInterval, setChecksumCheckInterval] = useState(
    userConfig?.scenarioChecksumPollingIntervalInMs
  );

  useInterval(() => {
    axios
      .get(`/api/scenarios/${scenario?.uuid}/checksum`)
      .then((backendScenarioData) => {
        if (
          checksumCheckInterval !==
          userConfig?.scenarioChecksumPollingIntervalInMs
        ) {
          /**
           * when we get here, it means the checksum check previous checksum check api returned an
           * error and the interval was increased.
           * Now, after receiving a 200, we should reset the inverval
           */
          setChecksumCheckInterval(
            userConfig?.scenarioChecksumPollingIntervalInMs
          );
        }

        const backendScenarioChecksum =
          backendScenarioData?.data?.scenarioChecksum;
        const browserScenarioChecksum = scenario?.scenarioChecksum;

        if (
          backendScenarioChecksum &&
          backendScenarioChecksum !== browserScenarioChecksum
        ) {
          toast({
            title: intl.formatMessage({
              id: 'SCENARIO_CHECKSUM_MISMATCH_NOTIFICATION_TITLE',
            }),
            description: intl.formatMessage({
              id: 'SCENARIO_CHECKSUM_MISMATCH_NOTIFICATION_DESCRIPTION',
            }),
            type: 'warning',
            time: STANDARD_TOAST_DURATION,
          });
        }
      })
      .catch((error) => {
        console.warn(
          `Could not fetch scenario with uuid ${scenario?.uuid} for scenario checksum check`,
          error
        );
        /**
         * exponential backoff.
         * we don`t want to spam backend with requests each x ms when backend is down or something like that.
         * here we increase the interval on each error.
         */
        setChecksumCheckInterval(checksumCheckInterval * 2);
      });
  }, checksumCheckInterval);

  return <></>;
};

ScenarioChecksumCheck.defaultProps = {};

ScenarioChecksumCheck.propTypes = {
  userConfig: PropTypes.object.isRequired,
};

const mapStateToProps = ({config}) => ({
  userConfig: config?.userConfig,
});

export default injectIntl(connect(mapStateToProps)(ScenarioChecksumCheck));
