import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Header} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Input} from '@ecosio/pathform';

const SimpleOrderLineItems = ({lineItem}) => {
  return (
    <Grid className="_si_ord_sl">
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h3" textAlign="left">
            <FormattedMessage id="GENERAL_DELIVERIES" />
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Input
        name="previouslyScheduledQuantity"
        path="previouslyScheduledQuantity"
        size="mini"
        inputLabel={{
          basic: true,
          content: (
            <FormattedMessage id={lineItem.measureUnitQualifier || ''} />
          ),
        }}
      />
      <Input
        name="orderedQuantity"
        path="orderedQuantity"
        size="mini"
        inputLabel={{
          basic: true,
          content: (
            <FormattedMessage id={lineItem.measureUnitQualifier || ''} />
          ),
        }}
      />
    </Grid>
  );
};

SimpleOrderLineItems.propTypes = {
  lineItem: PropTypes.object,
};

export default SimpleOrderLineItems;
