/* eslint-disable react/default-props-match-prop-types */
import React, {Component} from 'react';
import {DataGrid, FormatDate} from '@ecosio/components';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {DIRECTION_CONVERTER_IN} from '../../helpers/utils';
import DailyDocumentFooter from './DailyDocumentFooter';

const defaultDataRender = (Table, value) => (
  <Table.Cell collapsing textAlign="center">
    {value ? value : <FormattedMessage id="-" />}
  </Table.Cell>
);

const dateRender = (Table, value) => (
  <Table.Cell collapsing textAlign="center">
    <FormatDate dateString={value} />
  </Table.Cell>
);

export const getDynamicCell = (field) => {
  if (!field) {
    return undefined;
  }
  const Cell = {
    id: 'test',
    label: '',
    hidden: false,
    hideable: true,
    sortable: field?.sortable,
    sort: 2,
    render: defaultDataRender,
  };

  if (field?.tableSort) {
    Cell.sort = field?.tableSort;
  }

  if (field?.path) {
    Cell.id = field?.path;
  }

  if (field?.type === 'date') {
    Cell.render = dateRender;
  }

  if (field?.input?.label) {
    Cell.label = field?.input?.label;
  }

  return Cell;
};

class DailyDocumentsTableGrid extends Component {
  constructor(props) {
    super(props);
    this.emptyState = {
      icon: 'envelope square',
      header: 'GENERAL_NO_DOCUMENTS_HEADER',
      subHeader: 'GENERAL_NO_DOCUMENTS_SUBHEADER',
    };
  }

  getInitialSortColumn(defaultSortSettings = {}, dataGridSortBy) {
    let initialSortColumn = dataGridSortBy?.col;

    if (!initialSortColumn) {
      initialSortColumn = defaultSortSettings?.columnName || 'businessType';
    }

    return initialSortColumn;
  }

  getInitialSortDirection(defaultSortSettings = {}, dataGridSortBy) {
    let initialSortDirection = dataGridSortBy?.dir;
    if (!initialSortDirection) {
      initialSortDirection = defaultSortSettings?.sortOrder || 'desc';
      initialSortDirection =
        DIRECTION_CONVERTER_IN[initialSortDirection.toLowerCase()];
    }

    return initialSortDirection;
  }

  render() {
    const {
      data,
      onSort,
      loading,
      dataSpec,
      getMoreData,
      params,
      responseDataCount,
      dailyDocumentPageConfig,
      onSelectionChange,
      onSelectAllCallback,
      onDeselectAllCallback,
      selectable,
      dataGridSortBy,
      gridConfig,
      disableSelectAllCheckbox,
      rowFormatter,
      selectedLineItemsUuids,
      renderFooter,
    } = this.props;

    const defaultSortSettings = dailyDocumentPageConfig?.defaultSortingSettings;

    return (
      <DataGrid
        initialSortColumn={this.getInitialSortColumn(
          defaultSortSettings,
          dataGridSortBy
        )}
        initialSortDirection={this.getInitialSortDirection(
          defaultSortSettings,
          dataGridSortBy
        )}
        disableSelectAllCheckbox={disableSelectAllCheckbox}
        translated
        data-spec={dataSpec}
        data={data}
        loading={loading}
        onSort={onSort}
        config={gridConfig}
        emptyState={this.emptyState}
        selectable={selectable}
        selected={selectedLineItemsUuids}
        onSelectionChangeCallback={onSelectionChange}
        onSelectAllCallback={onSelectAllCallback}
        onDeselectAllCallback={onDeselectAllCallback}
        // needs this to hide ActionSelector in DataGrid
        renderActionRow={() => {}}
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        renderFooter={({ignored, cols}) => (
          <DailyDocumentFooter
            dataSpec={`${dataSpec}-footer`}
            cols={cols}
            fetchData={getMoreData}
            loading={loading}
            pageSize={dailyDocumentPageConfig?.paginationSettings?.pageSize}
            responseDataCount={responseDataCount}
            renderFooter={renderFooter}
            params={params}
          />
        )}
        rowFormatter={rowFormatter}
      />
    );
  }
}

DailyDocumentsTableGrid.propTypes = {
  data: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dataSpec: PropTypes.array.isRequired,
  getMoreData: PropTypes.func.isRequired,
  params: PropTypes.object,
  responseDataCount: PropTypes.number.isRequired,
  dailyDocumentPageConfig: PropTypes.object.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onSelectAllCallback: PropTypes.func.isRequired,
  onDeselectAllCallback: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  dataGridSortBy: PropTypes.object,
  gridConfig: PropTypes.object,
  disableSelectAllCheckbox: PropTypes.bool,
};

DailyDocumentsTableGrid.defaultProps = {
  loading: null,
  data: [],
  params: null,
  onSelectionChange: () => {},
  onSelectAllCallback: () => {},
  onDeselectAllCallback: () => {},
  selectable: true,
  disableSelectAllCheckbox: false,
};

export default DailyDocumentsTableGrid;
