import React, {Component} from 'react';
import {Header, Button, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {cloneDeep, set, omit, get} from 'lodash';
import PropTypes from 'prop-types';
import DesadvLineItemsForm from '../Create/LineItems/DesadvLineItemsForm';
import DocumentMeta from '../../CommonComponents/Create/Header/DocumentMeta';
import {pageConfigShape} from '../../../../../../shapes/scenarios';
import {ButtonContainer} from '../Create/DesadvSimpleTemplateV2';
import DownloadPdfButton from '../../../../Download/DownloadPdfButton';
import {
  HeaderContainer,
  TemplateContainer,
  TemplateSubContainer,
} from '../../CommonComponents/Create/Header/StyledDivs';
import DesadvPackagingInformationModal from './DesadvPackagingInformationModal';

export const modifyDeliveryLineItems = (documentExchange, ssccRequired) => {
  const documentExchangeToModify = cloneDeep(documentExchange);
  const consignmentPackagingSequences =
    documentExchangeToModify?.data?.document?.details?.dispatchData
      ?.consignmentPackagingSequences || [];

  const businessCaseType =
    documentExchange?.data?.document?.header?.businessCaseType;

  //when sscc is required then make the desadv a unstructured one to enable read only view
  if (ssccRequired || businessCaseType === 'X2') {
    const consignmentPackagingSequencesWithDeliveryLineItems = consignmentPackagingSequences.filter(
      (d) => d?.hierarchicalID !== '1'
    );

    const flatDeliveryLineItems = consignmentPackagingSequencesWithDeliveryLineItems.flatMap(
      (cps) => {
        const allDel = cps.deliveryLineItems.map((del) => {
          const packInfo = omit(cps, 'deliveryLineItems');
          const expirationDateFromPackagingInfo = get(
            packInfo,
            'packagingInformations[0].expirationDate'
          );

          const batchNumberFromPackagingInfo = get(
            packInfo,
            'packagingInformations[0].batchNumber'
          );

          const modifiedCps = {
            ...packInfo,
            packagingInformations: [
              {
                ...packInfo?.packagingInformations[0],
                quantityPerPack: del?.dispatchQuantity?.value,
                expirationDate:
                  expirationDateFromPackagingInfo || del?.expirationDate,
                batchNumber: batchNumberFromPackagingInfo || del?.batchNumber,
              },
            ],
          };
          return {
            ...del,
            cpses: [modifiedCps],
          };
        });
        return [...allDel];
      }
    );

    const uniqueDeliveryLineItems = flatDeliveryLineItems.reduce(
      (acc, curr) => {
        const findDuplicateLinItem = acc.find(
          (item) =>
            item?.references?.agreementOrOrderLineNumber ===
            curr?.references?.agreementOrOrderLineNumber
        );

        if (findDuplicateLinItem) {
          const lineItemOneDispatchQuantity =
            findDuplicateLinItem.dispatchQuantity.value;
          const lineItemTwoDispatchQuantity = curr.dispatchQuantity.value;

          if (findDuplicateLinItem?.cpses.length) {
            const currentCpses = curr?.cpses || [];
            findDuplicateLinItem.cpses.push(...currentCpses);
          }

          set(
            findDuplicateLinItem,
            'dispatchQuantity.value',
            lineItemOneDispatchQuantity + lineItemTwoDispatchQuantity
          );
        }

        if (!findDuplicateLinItem) {
          acc.push(curr);
        }
        return acc;
      },
      []
    );

    //Make consignmentPackagingSequences like unstructured desadv with only one cps and many deliveryLineItems
    set(
      documentExchangeToModify.data,
      'document.details.dispatchData.consignmentPackagingSequences',
      [consignmentPackagingSequences[0]]
    );

    /*
     *set consignmentPackagingSequences[0].deliveryLineItems to the mergedDeliveryLineItems Array
     * with this last step, we have a unstructured desadv for the read only view, when sscc is required
     */
    set(
      documentExchangeToModify.data,
      'document.details.dispatchData.consignmentPackagingSequences[0].deliveryLineItems',
      uniqueDeliveryLineItems
    );

    return consignmentPackagingSequences[0]?.deliveryLineItems;
  }

  //simply return the deliveryLineItems when sscc is not required
  return consignmentPackagingSequences[0]?.deliveryLineItems;
};

class ViewDesadvSimpleTemplateV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  render() {
    const {
      documentExchange,
      envelope,
      documentUuid,
      scenario,
      envelopeUuid,
    } = this.props;

    const {pageConfig} = documentExchange;
    const ssccRequired = envelope?.data?.documentData?.SSCC_REQUIRED === 'true';

    const documentTypeId = get(documentExchange, 'data.meta.documentTypeId');

    return (
      <TemplateContainer>
        <HeaderContainer>
          <Header as="h1">
            <Icon
              circular
              link
              name="chevron left"
              onClick={() => this.props.history.goBack()}
            />
            <Header.Content>
              <FormattedMessage id="XML_DESADV_1p0_ERPEL_INDUSTRY_MESSAGE" />
            </Header.Content>
          </Header>

          <DownloadPdfButton
            dataSpec="createDocumentBar-download-button"
            currentDocumentUuid={documentUuid}
            documentData={envelope?.data?.documentData}
            metaDocuments={envelope.data.metaDocuments}
            envelopeUuid={envelopeUuid}
            scenario={scenario}
            currentDocumentTypeID={documentTypeId}
          />
        </HeaderContainer>

        <TemplateSubContainer>
          <DesadvPackagingInformationModal
            open={this.state.modal}
            deliveryLineItems={modifyDeliveryLineItems(
              documentExchange,
              ssccRequired
            )}
            pageConfig={pageConfig}
            document={documentExchange?.data?.document}
            onCancel={() => {
              this.setState({
                modal: false,
              });
            }}
          />
          <DocumentMeta
            showReadonly
            documentExchange={documentExchange.data}
            pageConfig={pageConfig}
          />
          <DesadvLineItemsForm
            showReadonly
            goBackToEditing={this.goBackToEditing}
            sscc={ssccRequired}
            onSubmit={() => null}
            pageConfig={pageConfig || {}}
            deliveryLineItems={modifyDeliveryLineItems(
              documentExchange,
              ssccRequired
            )}
          />
          <ButtonContainer>
            <Button
              data-spec="back"
              color="black"
              onClick={() => this.props.history.goBack()}
              content={<FormattedMessage id="GENERAL_BACK" />}
            />
            {ssccRequired ? (
              <div>
                <Button
                  data-spec="show-desadv-structure"
                  onClick={() => this.setState({modal: true})}
                  content={<FormattedMessage id="SHOW_DESADV_STRUCTURE" />}
                />
              </div>
            ) : (
              ''
            )}
          </ButtonContainer>
        </TemplateSubContainer>
      </TemplateContainer>
    );
  }
}

ViewDesadvSimpleTemplateV2.defaultProps = {};

ViewDesadvSimpleTemplateV2.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  documentExchange: PropTypes.object.isRequired,
  envelope: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ViewDesadvSimpleTemplateV2;
