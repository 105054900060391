import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Popup} from 'semantic-ui-react';
import {useIntl} from 'react-intl';

/**
 * A tooltip icon with popup
 * Will be rendered when value has been changed in
 * merged state through ordchg
 */
const CircleForValueChanged = ({shouldRender = false}) => {
  const intl = useIntl();
  if (!shouldRender) {
    return null;
  }

  return (
    <Popup
      content={intl.formatMessage({
        id: `MERGED_STATE_VALUE_CHANGED`,
      })}
      trigger={<Icon size="tiny" name="circle" />}
    />
  );
};

CircleForValueChanged.propTypes = {
  shouldRender: PropTypes.bool,
};

export default CircleForValueChanged;
