import React from 'react';
import PropTypes from 'prop-types';
import {Button, Popup} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {dynID} from '@ecosio/pathform';
import {INDUSTRY_DESADV} from '../../../shapes/supportedDocTypes';
import {shouldShowCreateDocumentButton} from '../../../util/scenarioUtil';
import {scenarioShape} from '../../../shapes/scenarios';
import {envelopesShape} from '../../../shapes/envelopes';
import {
  createTurnaroundLink,
  getTurnaroundSourceDocuments,
  isCreateButtonDisabled,
} from '../../../util/turnaroundUtil';
import CreateButtonDraftListComponent from '../../DocumentDrafts/CreateButtonDraftList';

const maximumOutboundDocumentsReached = (
  pageConfig,
  currentNumberOfDocuments
) => {
  if (currentNumberOfDocuments && pageConfig?.maximumOutboundDocuments) {
    return currentNumberOfDocuments >= pageConfig?.maximumOutboundDocuments;
  }
  return false;
};

const CreateLink = ({
  scenario,
  envelopeUuid,
  documentUuid,
  docType,
  disabled,
  buttonLabel,
  disabledButtonTooltip,
  disableOnMaximumOutboundDocuments,
  ...rest
}) => {
  const scenarioUuid = scenario.uuid;
  const url = createTurnaroundLink({
    scenarioUuid,
    envelopeUuid,
    documentUuid,
    docType,
  });

  const CreateButton = () => (
    <div style={{display: 'inline-block', marginRight: '2px'}}>
      <Button
        {...rest}
        primary
        disabled={disabled}
        as={Link}
        to={url}
        data-spec={`CREATE_${docType}`}
        className={`_si_create_${docType}`}>
        {buttonLabel ? (
          <FormattedMessage id={dynID(buttonLabel)} />
        ) : (
          <FormattedMessage id={`CREATE_${docType}`} />
        )}
      </Button>
      <CreateButtonDraftListComponent
        disabled={disabled}
        docType={docType}
        scenarioUuid={scenarioUuid}
        envelopeUuid={envelopeUuid}
      />
    </div>
  );

  if (disabled) {
    // https://github.com/Semantic-Org/Semantic-UI-React/issues/1413
    return (
      <Popup
        trigger={
          <span>
            <CreateButton />
          </span>
        }
        content={
          disabledButtonTooltip ? (
            <FormattedMessage id={dynID(disabledButtonTooltip)} />
          ) : disableOnMaximumOutboundDocuments ? (
            <FormattedMessage
              id="GENERAL_BUTTON_DISABLED_ON_MAXIMUM_DOCUMENTS_REACHED"
              values={{
                docType: buttonLabel ? (
                  <FormattedMessage id={dynID(buttonLabel)} />
                ) : (
                  <FormattedMessage id={docType} />
                ),
              }}
            />
          ) : (
            <FormattedMessage id="GENERAL_BUTTON_DISABLED_SUPPORT" />
          )
        }
      />
    );
  }

  return <CreateButton />;
};
CreateLink.defaultProps = {
  disabled: false,
};

CreateLink.propTypes = {
  scenario: scenarioShape.isRequired,
  envelopeUuid: PropTypes.string.isRequired,
  documentUuid: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  disabledButtonTooltip: PropTypes.string,
  disableOnMaximumOutboundDocuments: PropTypes.bool,
};

/**
 * Renders a button to create a new turnaround document.
 *
 * Rendering the button can have 2 outcomes:
 *
 * a) if the target document can be determined without ambiguity,
 *    then it will simply return a link to the turnaround form.
 *
 * b) if the target document can not be determined without ambiguity,
 *    the button will trigger a modal which then collects further information
 *    from the user.
 *
 */
const CreateDocumentButton = ({
  scenario,
  envelope,
  targetDocType,
  onOpenModal,
  pageConfig,
}) => {
  const envelopeUuid = envelope?.uuid;
  const createDocButtonLabel = pageConfig?.createDocButtonLabel;
  const createdAt = envelope?.createdAt;
  const disabledButtonTooltip = pageConfig?.createDocButtonToolTip;

  if (!scenario || !Array.isArray(scenario.outboundWebDocTypes)) {
    console.warn('Cannot render new-document button without valid scenario');
    return null;
  }

  // somethings totally wrong...
  if (typeof targetDocType !== 'string' || typeof envelopeUuid !== 'string') {
    // TODO: log warning
    return null;
  }

  // we don't need to render the button at all
  if (!shouldShowCreateDocumentButton(scenario, targetDocType)) {
    return null;
  }

  let sourceDocuments = getTurnaroundSourceDocuments(
    envelope,
    scenario,
    targetDocType
  );

  if (
    pageConfig?.selectLatestSourceDocumentForTurnAround &&
    sourceDocuments.length
  ) {
    //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/387
    sourceDocuments = [sourceDocuments[0]];
  }
  const metaDocuments = envelope?.metaDocuments;

  const currentNumberOfDocuments = Object.keys(metaDocuments)
    .filter((d) => d === targetDocType)
    .reduce((acc, cur) => {
      return metaDocuments[cur].length;
    }, 0);

  const disabled = isCreateButtonDisabled(
    currentNumberOfDocuments,
    sourceDocuments,
    pageConfig,
    createdAt
  );

  // if we have more than one potential source docs, we need to ask
  // the user which one
  let showModal =
    sourceDocuments.length > 1 || targetDocType === INDUSTRY_DESADV;

  // if we create a desadv, we must check the documents content
  // for consignee / delivery dates to make the user choose them
  // if ambiguous
  if (targetDocType === INDUSTRY_DESADV) {
    showModal = true;
  }

  let documentUuid = null;

  // the source can be determined without further user interaction
  // determine the UUID of the document to be used as input
  // for the turnaround mapping
  if (sourceDocuments.length === 1) {
    documentUuid = sourceDocuments[0].uuid;
  } else {
    // this really should never happen, but who knows...
    if (sourceDocuments.length > 1 && !showModal) {
      console.warn(
        'Illegal state. Determined more than 1 source documents but now showing modal.'
      );
      return null;
    }
  }

  if (showModal) {
    const CreateButton = () => (
      <div style={{display: 'inline-block', marginRight: '2px'}}>
        <Button
          primary
          className={`_si_create_${targetDocType}`}
          onClick={() =>
            onOpenModal({
              targetDocType,
              envelopeUuid,
              sourceDocuments,
              scenario,
            })
          }
          data-spec={`CREATE_${targetDocType}`}
          disabled={disabled}>
          {createDocButtonLabel ? (
            <FormattedMessage id={dynID(createDocButtonLabel)} />
          ) : (
            <span>
              <FormattedMessage id={`CREATE_${targetDocType}`} />
            </span>
          )}
        </Button>
        <CreateButtonDraftListComponent
          disabled={disabled}
          docType={targetDocType}
          scenarioUuid={scenario?.uuid}
          envelopeUuid={envelopeUuid}
        />
      </div>
    );

    if (disabled) {
      // https://github.com/Semantic-Org/Semantic-UI-React/issues/1413
      return (
        <Popup
          trigger={
            <span>
              <CreateButton />
            </span>
          }
          content={
            disabledButtonTooltip ? (
              <FormattedMessage id={dynID(disabledButtonTooltip)} />
            ) : (
              <FormattedMessage id="GENERAL_BUTTON_DISABLED_SUPPORT" />
            )
          }
        />
      );
    }
    return <CreateButton />;
  }

  return (
    <CreateLink
      scenario={scenario}
      envelopeUuid={envelopeUuid}
      documentUuid={documentUuid}
      docType={targetDocType}
      disabled={disabled}
      buttonLabel={createDocButtonLabel}
      disabledButtonTooltip={disabledButtonTooltip}
      disableOnMaximumOutboundDocuments={maximumOutboundDocumentsReached(
        pageConfig,
        currentNumberOfDocuments
      )}
    />
  );
};

CreateDocumentButton.propTypes = {
  scenario: scenarioShape,
  envelope: envelopesShape.isRequired,
  // the docType of the button
  targetDocType: PropTypes.string.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  pageConfig: PropTypes.object.isRequired,
};

export default CreateDocumentButton;
