import PropTypes from 'prop-types';

export const priceShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const industryDocumentLineItemsShape = PropTypes.arrayOf(
  PropTypes.shape({
    positionNumber: PropTypes.number,
    customersItemMaterialNumber: PropTypes.string,
    suppliersItemMaterialNumber: PropTypes.any,
    itemDescription: PropTypes.string,
    itemCategory: PropTypes.any,
    orderedQuantity: PropTypes.number,
    receivedQuantity: PropTypes.any,
    previouslyScheduledQuantity: PropTypes.any,
    measureUnitQualifier: PropTypes.string,
    currentItemPriceCalculationNet: priceShape,
    currentItemPriceCalculationGross: priceShape,
    unitPriceBasis: PropTypes.any,
    requestedShipmentDate: PropTypes.shape({
      dateQualifier: PropTypes.string,
      dateTime: PropTypes.string,
      dateTimeFormatQualifier: PropTypes.string,
    }),
    lineItemActionRequest: PropTypes.any,
    ultimateSoldToCustomerPartNumber: PropTypes.any,
    ultimateSoldToCustomerPurchaseOrderLineItemNumber: PropTypes.any,
    ultimateShipToCustomerPartNumber: PropTypes.any,
    ultimateShipToCustomerUPCNumber: PropTypes.any,
    freeTexts: PropTypes.array,
    references: PropTypes.arrayOf(
      PropTypes.shape({
        contractReference: PropTypes.shape({
          referenceQualifier: PropTypes.string,
          referenceNumber: PropTypes.string,
          lineNumber: PropTypes.any,
          referenceVersionNumber: PropTypes.any,
          date: PropTypes.any,
        }),
        orderReference: PropTypes.any,
      })
    ),
    ordersScheduleLines: PropTypes.array,
    allowancesAndCharges: PropTypes.array,
  })
);

export const industryDocumentFooterShape = PropTypes.shape({
  invoiceFooter: PropTypes.shape({
    invoiceTotals: PropTypes.shape({
      totalLineItemAmount: PropTypes.any,
      totalTaxableAmount: PropTypes.number,
      totalVATAmount: PropTypes.any,
      paymentDiscountAmount: PropTypes.any,
      totalAllowanceAndSurchargesAmount: PropTypes.any,
      totalPrepaidAmount: PropTypes.any,
      invoiceAmount: PropTypes.number,
      currencyQualifier: PropTypes.any,
    }),
    vatrates: PropTypes.arrayOf(
      PropTypes.shape({
        taxableAmount: PropTypes.number,
        vatrate: PropTypes.number,
        vatamount: PropTypes.number,
      })
    ),
  }),
  messageTrailer: PropTypes.any,
});

export const industryDocumentShape = PropTypes.shape({
  creationDate: PropTypes.string,
  documentNumber: PropTypes.string,
  documentSendStatus: PropTypes.string,
  industryDocument: PropTypes.shape({
    header: PropTypes.shape({
      messageHeader: PropTypes.shape({
        messageReferenceNumber: PropTypes.string,
        messageType: PropTypes.string,
      }),
      beginningOfMessage: PropTypes.shape({
        documentNameEncoded: PropTypes.string,
        documentNumber: PropTypes.string,
        messageFunction: PropTypes.string,
      }),
      dates: PropTypes.shape({
        documentDate: PropTypes.shape({
          dateQualifier: PropTypes.string,
          dateTime: PropTypes.number,
        }),
      }),
      referencedDocuments: PropTypes.shape({
        customerReferenceNumber: PropTypes.shape({
          referenceQualifier: PropTypes.string,
          referenceNumber: PropTypes.string,
          date: PropTypes.shape({
            dateQualifier: PropTypes.string,
            dateTime: PropTypes.number,
          }),
        }),
        shipmentLoadingReferenceNumber: PropTypes.shape({
          referenceQualifier: PropTypes.string,
          referenceNumber: PropTypes.string,
        }),
        purchaseOrderReferenceNumber: PropTypes.shape({
          referenceNumber: PropTypes.string,
        }),
      }),
      businessEntities: PropTypes.shape({
        supplier: PropTypes.shape({
          partyQualifier: PropTypes.string,
          partyIdentification: PropTypes.string,
          partyIdentificationCodeListResponsibleAgency: PropTypes.string,
          partyName: PropTypes.string,
          street: PropTypes.string,
          city: PropTypes.string,
          postCode: PropTypes.string,
          country: PropTypes.shape({
            countryName: PropTypes.string,
            countryCode: PropTypes.string,
          }),
        }),
        consignee: PropTypes.shape({
          partyQualifier: PropTypes.string,
          partyIdentification: PropTypes.string,
          partyIdentificationCodeListResponsibleAgency: PropTypes.string,
          partyName: PropTypes.string,
          street: PropTypes.string,
          city: PropTypes.string,
          postCode: PropTypes.string,
          country: PropTypes.shape({
            countryName: PropTypes.string,
            countryCode: PropTypes.string,
          }),
        }),
      }),
    }),
    details: PropTypes.shape({
      receiptAdviceData: PropTypes.shape({
        consignmentPackagingSequenceRecadvs: PropTypes.arrayOf(
          PropTypes.shape({
            hierarchicalID: PropTypes.string,
            packagingLevelCode: PropTypes.string,
            receiptAdviceLineItems: PropTypes.arrayOf(
              PropTypes.shape({
                positionNumber: PropTypes.number,
                customersItemMaterialNumber: PropTypes.string,
                itemDescription: PropTypes.string,
                despatchQuantity: PropTypes.number,
                receivedQuantity: PropTypes.number,
                descrepancyQuantity: PropTypes.number,
                descrepancyQuantityQualifier: PropTypes.string,
              })
            ),
          })
        ),
      }),
    }),
  }),
});
