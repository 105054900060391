import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {
  CollapseBox,
  AutoSave,
  intlShape,
  dynID,
  toast,
} from '@ecosio/components';
import {Icon, Grid} from 'semantic-ui-react';
import {stringify} from 'qs';
import {connect} from 'react-redux';
import {Input, Form as FinalForm} from '@ecosio/pathform';
import {historyShape} from '../../shapes/generalShapes';
import {
  STANDARD_TOAST_DURATION,
  ECOSIO_THEME,
  EMPTY_DROPDOWN_VALUE,
} from '../../helpers/constants';
import {loadTheme} from '../../helpers/utils';

const dropDownOptions = (
  dropDownSettings = {},
  path = '',
  appendDropDownValueToText = false,
  intl
) => {
  const result = [];
  const dropDownConfig = dropDownSettings[path];
  if (dropDownConfig && dropDownConfig.length) {
    result.push(...dropDownConfig);
  }

  return result
    .sort((a, b) => {
      //sort empty value to top
      if (a.value === EMPTY_DROPDOWN_VALUE) {
        return -1;
      }

      if (b.value === EMPTY_DROPDOWN_VALUE) {
        return 1;
      }

      return 0;
    })
    .map((item) => {
      const dropDownText = appendDropDownValueToText
        ? `${item?.text} (${item?.value})`
        : item?.text;

      return {
        text: intl.formatMessage({id: dynID(dropDownText)}),
        value: item?.value,
        key: item?.value,
      };
    });
};

const getfilterField = (filterField, intl, dropDownSettings) => {
  const path = filterField?.formField?.path;
  const label = filterField?.formField?.input?.label;
  const fieldType = filterField?.formField?.type;
  const fieldWidth = filterField?.fieldWidth || 2;

  const dropDownOptionsPath = path !== 'buyer.gln' ? path : 'buyer';
  const appendDropDownValueToText = path === 'buyer.gln' ? true : false;

  switch (fieldType) {
    case 'dateV2':
      return (
        <Grid.Column width={fieldWidth} data-spec={path}>
          <div style={{marginTop: '2px'}}>
            <Input
              stripTimezone
              name={path}
              path={path}
              placeholder={`${label}_PLACEHOLDER`}
            />
          </div>
        </Grid.Column>
      );

    case 'dropdown':
      return (
        <Grid.Column width={fieldWidth} data-spec={path}>
          <Input
            name={path}
            path={path}
            label={intl.formatMessage({id: dynID(label)})}
            activeWhenApplied
            options={dropDownOptions(
              dropDownSettings,
              dropDownOptionsPath,
              appendDropDownValueToText,
              intl
            )}
            placeholder={intl.formatMessage({
              id: dynID(`${label}_PLACEHOLDER`),
            })}
            selectOnBlur={false}
            autoFocus
            compact
            style={{
              paddingTop: '9.5px',
              paddingBottom: '9.5px',
            }}
          />
        </Grid.Column>
      );
    default:
      return (
        <Grid.Column width={fieldWidth} data-spec={path}>
          <Input
            name={path}
            path={path}
            label={intl.formatMessage({id: dynID(label)})}
            activeWhenApplied
            placeholder={intl.formatMessage({
              id: dynID(`${label}_PLACEHOLDER`),
            })}
            icon="search"
            selectOnBlur={false}
            autoFocus
            compact
          />
        </Grid.Column>
      );
  }
};

const DailyDocumentsFilter = ({
  onSubmit,
  intl,
  initialValues,
  history,
  resetPageNr,
  filterFormFields,
  dropDownSettings,
  theme,
  locale,
}) => {
  const numOfFields = filterFormFields.length || 1;

  const currentTheme = loadTheme(theme);

  //transform formfieldList to formFieldMap for react-final-form fields
  const finalFormFieldsObject = filterFormFields.reduce((obj, item) => {
    const tempItem = {...item};
    delete tempItem.formField;
    return {
      ...obj,
      [item.formField.path]: {
        ...tempItem,
        ...item.formField,
      },
    };
  }, {});
  return (
    <CollapseBox style={{marginTop: '0px'}} data-spec="daily-document-filter">
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        locale={locale}
        fields={finalFormFieldsObject || {}}>
        {() => (
          <>
            <AutoSave debounce={500} />
            <Grid columns={2}>
              <Grid.Column floated="left" width={14}>
                <Grid>
                  <Grid.Row columns={numOfFields}>
                    {filterFormFields
                      .sort(
                        (a, b) =>
                          a?.leftToRightPositionOrdinal -
                          b?.leftToRightPositionOrdinal
                      )
                      .map((filterField) =>
                        getfilterField(filterField, intl, dropDownSettings)
                      )}
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column width={2} data-spec="f-clear" floated="right">
                {intl.formatMessage({
                  id: dynID(`RESET_FILTER`),
                })}
                <Icon
                  style={{
                    marginTop: '2.5rem',
                    marginLeft: '0.5rem',
                    color: currentTheme?.primaryColor,
                  }}
                  name="undo"
                  link
                  onClick={() => {
                    history.push({
                      search: `?${stringify({
                        type: initialValues?.type,
                        sort: initialValues?.sort,
                      })}`,
                    });
                    resetPageNr();
                    toast({
                      title: intl.formatMessage({
                        id: 'DAILY_DOCUMENT_RESET_FILTER_NOTIFICATION_TITLE',
                      }),
                      description: intl.formatMessage({
                        id:
                          'DAILY_DOCUMENT_RESET_FILTER_NOTIFICATION_DESCRIPTION',
                      }),
                      type: 'success',
                      time: STANDARD_TOAST_DURATION,
                    });
                  }}
                />
              </Grid.Column>
            </Grid>
          </>
        )}
      </FinalForm>
    </CollapseBox>
  );
};

const mapStateToProps = ({config, locales}) => ({
  theme: config?.userConfig?.theme,
  locale: locales?.locale,
});

DailyDocumentsFilter.defaultProps = {
  filterFormFields: [],
  theme: ECOSIO_THEME,
  locale: 'en',
};

DailyDocumentsFilter.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  resetPageNr: PropTypes.func.isRequired,
  history: historyShape.isRequired,
  filterFormFields: PropTypes.array,
  dropDownSettings: PropTypes.object,
  theme: PropTypes.string,
  locale: PropTypes.string,
};

export default injectIntl(connect(mapStateToProps)(DailyDocumentsFilter));
