import React, {Component} from 'react';
import styled from 'styled-components';
import {Header, Button} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {MappingValidationLog, toast, intlShape} from '@ecosio/components';
import axios from 'axios';
import PropTypes from 'prop-types';
import withScenario from '../../hoc/withScenario';
import {HelmetTags} from '../../../helpers/components/HelmetTags';
import {scenarioShape} from '../../../shapes/scenarios';
import {exportErrorMessageTranslationKeyFromHTTPResponse} from '../../../util/httpUtil';
import DropzoneComponent from './DropzoneComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 38px 48px 59px 48px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

class DesadvUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentValid: false,
      selectedFile: null,
      mappingLogData: null,
      loading: false,
      sendButtonDisabled: false,
      showProgressBar: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.selectedFile !== this.state.selectedFile) {
      this.setState({
        sendButtonDisabled: false,
      });
    }
  }

  setUploadedFile = (selectedFile) => {
    this.setState({
      selectedFile: selectedFile,
    });
  };

  resetState = (newState, delay = 0) =>
    new Promise((resolve) => {
      setTimeout(() => {
        this.setState(newState, resolve);
      }, delay);
    });

  uploadValidateAndSendToHub = async () => {
    const {scenarioUuid, scenario, intl} = this.props;
    const {selectedFile} = this.state;
    const data = new FormData();
    data.append('file', selectedFile);
    this.setState({
      documentValid: false,
      loading: true,
      showProgressBar: true,
    });

    await axios
      .post(
        `/api/webediUpload/${scenarioUuid}/documents/${scenario?.startingWebDocTypeId}/uploadValidateAndSendToHub`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            selectedFile.progress = percentCompleted;
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            documentValid: true,
            mappingLogData: null,
          });
          toast({
            title: intl.formatMessage({
              id: 'UPLOAD_AND_SEND_SUCCESSFUL_TITLE',
            }),
            description: intl.formatMessage({
              id: 'UPLOAD_AND_SEND_SUCCESSFUL_DESCRIPTION',
            }),
            type: 'success',
          });
        } else {
          this.setState({
            documentValid: false,
            mappingLogData: null,
          });
          toast({
            title: intl.formatMessage({
              id: 'UPLOAD_AND_SEND_UNSUCCESSFUL_BACKEND_ERROR_TITLE',
            }),
            description: intl.formatMessage({
              id: 'UPLOAD_AND_SEND_UNSUCCESSFUL_BACKEND_ERROR_DESCRIPTION',
            }),
            type: 'error',
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({
            documentValid: false,
            mappingLogData: error.response.data,
          });
          toast({
            title: intl.formatMessage({
              id: 'SEND_UNSUCCESSFUL_CHECK_UPLOADED_FILE_TITLE',
            }),
            description: intl.formatMessage({
              id: 'SEND_UNSUCCESSFUL_CHECK_UPLOADER_FILE_DESCRIPTION',
            }),
            type: 'warning',
          });
        } else {
          selectedFile.progress = 0;
          let errorDescription = exportErrorMessageTranslationKeyFromHTTPResponse(
            error
          );
          if (errorDescription === undefined) {
            errorDescription =
              'UPLOAD_AND_SEND_UNSUCCESSFUL_BACKEND_ERROR_DESCRIPTION';
          }
          toast({
            title: intl.formatMessage({
              id: 'UPLOAD_AND_SEND_UNSUCCESSFUL_BACKEND_ERROR_TITLE',
            }),
            description: intl.formatMessage({
              id: errorDescription,
            }),
            type: 'error',
          });
          console.error(error);
        }
      });
    await this.resetState({
      loading: false,
      sendButtonDisabled: true,
    });

    await this.resetState(
      {
        showProgressBar: false,
        selectedFile: false,
      },
      2000
    );
  };

  render() {
    const {scenario} = this.props;
    const {
      documentValid,
      mappingLogData,
      selectedFile,
      loading,
      sendButtonDisabled,
      showProgressBar,
    } = this.state;

    const fileUploadPageConfigs = scenario?.fileUploadPageConfigurations || [];

    //TODO: https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/511
    const fileUploadPageConfig = fileUploadPageConfigs[0];

    return (
      <Container data-spec="upload-container">
        <HelmetTags scenario={scenario} />
        <HeaderContainer>
          <Header as="h1">
            <Header.Content>
              <FormattedMessage
                id={dynID(`${scenario?.nameTranslationKey}_HEADER`)}
              />
            </Header.Content>
            <Header.Subheader>
              <FormattedMessage
                id={dynID(`${scenario?.nameTranslationKey}_SUBHEADER`)}
              />
            </Header.Subheader>
          </Header>
        </HeaderContainer>
        <DropzoneComponent
          {...this.props}
          showProgressBar={showProgressBar}
          selectedFile={selectedFile}
          setUploadedFile={this.setUploadedFile}
          fileUploadPageConfig={fileUploadPageConfig}
        />
        <StyledButton
          data-spec="send-uploaded-document"
          loading={loading}
          disabled={!selectedFile || sendButtonDisabled}
          onClick={this.uploadValidateAndSendToHub}>
          <FormattedMessage id="UPLOAD_AND_SEND_DOCUMENT_BUTTON" />
        </StyledButton>
        {!documentValid && mappingLogData ? (
          <MappingValidationLog Log={mappingLogData} />
        ) : (
          ''
        )}
      </Container>
    );
  }
}

DesadvUploadComponent.propTypes = {
  scenario: scenarioShape.isRequired,
  intl: intlShape.isRequired,
  scenarioUuid: PropTypes.string.isRequired,
};

export default injectIntl(withScenario()(DesadvUploadComponent));
