import createDecorator from 'final-form-calculate';
import {
  recalculateLineItem,
  recalculateSumOnly,
} from '../../../../../calculations/orderLineItemCalculations';

const updates = (value, name, allValues) => recalculateLineItem(allValues);
const sumOnlyUpdates = (value, name, allValues) =>
  recalculateSumOnly(allValues);

// allowancesAndCharges
export const defaultLineItemCalculator = createDecorator(
  {
    field: /unitPriceBasis/,
    updates,
  },
  {
    field: 'currentItemPriceCalculationGross.value',
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.percentageOfAllowanceOrCharge/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.allowanceChargeAmount/,
    updates,
  },
  {
    field: /allowancesAndCharges\[\d]\.allowanceChargeQualifier/,
    updates,
  },
  {
    field: /ordersScheduleLines\[\d]\.scheduledQuantity/,
    updates,
  },
  {
    //onDelete of scheduleLines, we should trigger here a recalculate for totalLineItemAmount
    field: /ordersScheduleLines/,
    updates,
  }
);

export const sumOnlyLineItemCalculator = createDecorator({
  field: /ordersScheduleLines\[\d]\.scheduledQuantity/,
  updates: sumOnlyUpdates,
});
