import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Loader} from 'semantic-ui-react';
import {flowRight} from 'lodash';
import withScenario from '../../../hoc/withScenario';
import DynamicTemplate from '../../../DynamicTemplate/index';
import {layoutOptions} from '../../Layout/CreateDocumentLayout';
import {fetchEnvelope} from '../../../../reducers/envelope';
import {envelopesShape} from '../../../../shapes/envelopes';
import {scenarioShape} from '../../../../shapes/scenarios';
import {DAILY_DOCUMENT} from '../../../../helpers/constants';
import withDocumentDraftSettings, {
  DRAFT_FEATURES_REQUESTS_NAMES,
  DRAFT_FEATURES_REQUESTS_DEAULT,
} from '../../../hoc/withDocumentDraftSettings';

const mapStateToProps = ({envelope}) => ({envelope});

const mapDispatchToProps = (dispatch) => ({
  fetchEnvelope: (envelopeUuid, scenario) =>
    dispatch(fetchEnvelope(envelopeUuid, scenario)),
});

class CreateDocumentSwitch extends React.Component {
  componentDidMount() {
    const {
      scenario,
      match: {
        params: {envelopeUuid},
      },
    } = this.props;

    if (scenario?.listLayout !== DAILY_DOCUMENT) {
      this.props.fetchEnvelope(envelopeUuid, scenario);
    }
  }

  render() {
    const {scenario, envelope} = this.props;

    if (scenario?.listLayout !== DAILY_DOCUMENT && envelope.fetching) {
      return <Loader active />;
    }

    const docType = this.props.match.params.documentType;
    const pageConfig = scenario.outboundWebDocTypes.find(
      (config) => config.documentTypeId === docType
    );

    if (!pageConfig) {
      throw new Error(
        `Unable to load outbound page config for docType ${docType}`
      );
    }

    return (
      <DynamicTemplate
        componentName={scenario.detailLayout}
        options={layoutOptions}
        {...this.props}
        pageConfig={pageConfig}
        envelope={envelope}
        scenario={scenario}
      />
    );
  }
}

CreateDocumentSwitch.propTypes = {
  scenario: PropTypes.shape({data: scenarioShape}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      envelopeUuid: PropTypes.string,
      documentType: PropTypes.string,
    }),
  }),
  envelopeUuid: PropTypes.string,
  fetchEnvelope: PropTypes.func,
  envelope: PropTypes.shape({
    data: envelopesShape,
    fetching: PropTypes.boolean,
  }),
};

export default flowRight(
  connect(mapStateToProps, mapDispatchToProps),
  withDocumentDraftSettings({
    ...DRAFT_FEATURES_REQUESTS_DEAULT,
    [DRAFT_FEATURES_REQUESTS_NAMES.FETCH_WEBEDI_SETTINGS]: true,
  }),
  withScenario()
)(CreateDocumentSwitch);
