/* eslint-disable react/no-array-index-key */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Grid,
  Popup,
  Button,
  Icon,
} from 'semantic-ui-react';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {ecosioColors} from '@ecosio/customer-layout';
import {withRouter} from 'react-router-dom';
import {intlShape} from '@ecosio/components';
import {loadTheme} from '../../helpers/utils';
import {ECOSIO_THEME} from '../../helpers/constants';
import {historyShape} from '../../shapes/generalShapes';
import {
  getDraftsForEnvelopeAndDocType,
  deleteDraftWithUuid,
  editDraftWithUuid,
} from './draftRestCalls';
import {
  convertUTCdateTimeToLocalDateTime,
  shouldRenderDrafts,
  triggerDelayErrorToast,
} from './draftUtils';

const CreateButtonDraftListItemWrapper = styled.div`
  width: 310px;
  font-size: 10px;
`;

const StyledSendDate = styled.div`
  line-height: 12px;
  width: max-content;
  border-radius: 0.28rem;
  border: 2px solid ${(props) => props.primaryColor};
  padding: 6px;
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => props.primaryColor};
  gap: 4px;
`;

class CreateButtonDraftListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentDraftList: [],
      draftListOpened: false,
    };
  }

  async componentDidMount() {
    const {disabled, userConfig} = this.props;

    if (!disabled && shouldRenderDrafts(userConfig)) {
      await this.fetchDrafts();
    }
  }

  fetchDrafts = async () => {
    const {scenarioUuid, envelopeUuid, docType} = this.props;

    try {
      const response = await getDraftsForEnvelopeAndDocType(
        scenarioUuid,
        envelopeUuid,
        docType
      );

      this.setState({
        documentDraftList: response?.data?.documentDraftList,
      });
    } catch (error) {
      console.error('Fetching document drafts failed ', error);
      triggerDelayErrorToast(error, this.props.intl);
    }
  };

  deleteDraft = async (draftUuid) => {
    try {
      await deleteDraftWithUuid(draftUuid);
      const {documentDraftList} = this.state;

      const updatedDocumentDraftList = documentDraftList.filter(
        (documentDraft) => documentDraft.uuid !== draftUuid
      );

      this.setState({
        documentDraftList: updatedDocumentDraftList,
      });
    } catch (error) {
      console.error('Deleting document drafts failed ', error);

      triggerDelayErrorToast(error, this.props.intl);
    }
  };

  editDraft = async (draftUuid) => {
    try {
      const {scenarioUuid, envelopeUuid, history} = this.props;
      const result = await editDraftWithUuid(scenarioUuid, draftUuid);
      const documentExchangeMeta = result?.data?.meta;
      const documentExchange = result?.data;
      const {documentDraftList} = this.state;

      const pathToRedirect = `/scenario/${scenarioUuid}/envelope/${envelopeUuid}/doc/${draftUuid}/create/${documentExchangeMeta?.documentTypeId}`;
      const draftData = documentDraftList.find(
        (draft) => draft?.uuid === draftUuid
      );
      if (result?.data?.document) {
        history.push({
          pathname: pathToRedirect,
          state: {
            draftDocumentExchange: documentExchange,
            envelopeUuid: envelopeUuid,
            draftData: draftData,
          },
        });
      }
    } catch (error) {
      console.error(`Editing document draft ${draftUuid} failed `, error);
      triggerDelayErrorToast(error, this.props.intl);
    }
  };

  handleHover = (opened) => {
    this.setState({
      draftListOpened: opened,
    });
  };

  render() {
    const {disabled, theme, locale, userConfig, docType} = this.props;
    const {documentDraftList, draftListOpened} = this.state;

    if (!shouldRenderDrafts(userConfig) || documentDraftList.length === 0) {
      return null;
    }
    const currentTheme = loadTheme(theme);

    return (
      <Dropdown
        data-spec={`DRAFT_DROPDOWN_${docType}`}
        onMouseEnter={() => this.handleHover(true)}
        onClick={() => this.handleHover(!draftListOpened)}
        open={draftListOpened}
        disabled={disabled}
        trigger={
          <Button icon>
            <Icon name="clock" />
          </Button>
        }>
        <DropdownMenu direction="left">
          {documentDraftList.map((draft, index) => {
            return (
              <DropdownItem
                key={index}
                style={{cursor: 'auto'}}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover(false)}>
                <CreateButtonDraftListItem
                  draftResponse={draft}
                  currentTheme={currentTheme}
                  locale={locale}
                  deleteDraft={this.deleteDraft}
                  editDraft={this.editDraft}
                  index={index}
                />
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({config, locales}) => ({
  userConfig: config?.userConfig,
  theme: config?.userConfig?.theme,
  locale: locales?.locale,
});

CreateButtonDraftListComponent.defaultProps = {
  theme: ECOSIO_THEME,
  locale: 'en',
  disabled: false,
};

CreateButtonDraftListComponent.propTypes = {
  scenarioUuid: PropTypes.string.isRequired,
  envelopeUuid: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  theme: PropTypes.string,
  locale: PropTypes.string,
  disabled: PropTypes.bool,
  history: historyShape.isRequired,
  intl: intlShape.isRequired,
  userConfig: PropTypes.object,
};

export default connect(mapStateToProps)(
  withRouter(injectIntl(CreateButtonDraftListComponent))
);

const CreateButtonDraftListItem = ({
  draftResponse,
  currentTheme,
  locale = 'en',
  deleteDraft,
  editDraft,
  index,
}) => {
  //convert utc dateTime to browser local dateTime
  const sendDate = convertUTCdateTimeToLocalDateTime(
    draftResponse?.sendDate,
    locale
  );

  return (
    <CreateButtonDraftListItemWrapper
      data-spec={`DRAFT_DROPDOWN_ITEM_${index}`}>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column
            width={9}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <p
              data-spec={`DRAFT_DROPDOWN_ITEM_${index}_DOCUMENT_NAME`}
              style={{
                fontSize: '12px',
                fontWeight: '600',
                color: currentTheme?.primaryColor,
                lineHeight: '16px',
                height: '16px',
              }}>
              {draftResponse?.documentName}
            </p>
            <Popup
              content={draftResponse?.updatedBy}
              trigger={
                <p
                  data-spec={`DRAFT_DROPDOWN_ITEM_${index}_UPDATED_BY`}
                  style={{
                    fontSize: '10px',
                    fontWeight: '400',
                    lineHeight: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginTop: '-10px',
                    height: '12px',
                    color: ecosioColors.greyShades[1],
                  }}>
                  <FormattedMessage
                    id="DOCUMENT_DRAFT_LIST_UPDATED_BY"
                    values={{
                      updatedBy: draftResponse?.updatedBy,
                    }}
                  />
                </p>
              }
            />
          </Grid.Column>
          <Grid.Column width={7}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <StyledSendDate primaryColor={currentTheme?.primaryColor}>
                <Icon name="clock outline" />
                <span data-spec={`DRAFT_DROPDOWN_ITEM_${index}_SEND_DATE`}>
                  {sendDate}
                </span>
              </StyledSendDate>
              <div
                style={{
                  width: '32px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '-5px',
                  marginLeft: '12px',
                }}>
                <DraftActionButton
                  onClick={async () => await editDraft(draftResponse?.uuid)}
                  iconName="edit"
                  currentTheme={currentTheme}
                />
                <DraftActionButton
                  onClick={async () => await deleteDraft(draftResponse?.uuid)}
                  iconName="trash"
                  currentTheme={currentTheme}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CreateButtonDraftListItemWrapper>
  );
};

CreateButtonDraftListItem.propTypes = {
  draftResponse: PropTypes.object,
  currentTheme: PropTypes.object,
  locale: PropTypes.string,
  deleteDraft: PropTypes.func.isRequired,
  editDraft: PropTypes.func.isRequired,
  index: PropTypes.number,
};

const StyledDraftActionIcon = styled(Icon)`
  color: ${(props) => props.primaryColor};
  cursor: pointer !important;
`;

export const DraftActionButton = ({
  iconName = 'info',
  currentTheme,
  onClick,
  dataSpec,
}) => {
  return (
    <StyledDraftActionIcon
      data-spec={
        dataSpec || `DRAFT_DROPDOWN_ITEM_${iconName.toLocaleUpperCase()}`
      }
      name={iconName}
      onClick={onClick}
      primaryColor={currentTheme?.primaryColor}
    />
  );
};

DraftActionButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  currentTheme: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  dataSpec: PropTypes.string,
};
