import {calculateFooterTaxes} from '../calculations/orderFooterCalculations';
import {recalculateLineItem} from '../calculations/orderLineItemCalculations';
import {ABSOLUTE, PERCENTAGE, CURRENCY_EUR} from '../constants';

const logInvalidItem = (msg) => {
  console.warn(msg);
};

export const preprocessOrderLineItems = (lineItems) => {
  return lineItems.map((item) => {
    // initialize net price object if not present
    // see ordersPreprocessors logic at the bottom
    if (!item.currentItemPriceCalculationNet) {
      item.currentItemPriceCalculationNet = {};
    }
    // set the unitPriceBasis to 1 if not already present, otherwise
    // we cannot calculate
    const unitPriceBasis = item.unitPriceBasis ? item.unitPriceBasis : 1;

    // preprocess allowances and charges
    const allowancesAndCharges = item?.allowancesAndCharges || [];
    const processedAllowances = allowancesAndCharges.map((allowance) => {
      // set the internal flag to know if it's percentage or absolute
      const allowanceChargeTypeCoded = allowance.percentageOfAllowanceOrCharge
        ? PERCENTAGE // Percentage based AoC
        : ABSOLUTE; // Absolute amount
      return {
        ...allowance,
        allowanceChargeTypeCoded,
      };
    });

    // the client relies on delivery information on line item level to
    // be in the ordersScheduleLine. We need to prepare it if the backend
    // does not deliver
    if (!item.ordersScheduleLines || item.ordersScheduleLines.length === 0) {
      if (!item.orderedQuantity) {
        logInvalidItem(
          'LineItem has no orderedQuantity and no ordersScheduleLines'
        );
      }
      if (!item.requestedShipmentDate) {
        logInvalidItem(
          'LineItem has no requestedShipmentDate and no ordersScheduleLines'
        );
      }
      const newScheduleLine = {
        scheduledQuantity: item.orderedQuantity,
        scheduledQuantityDate: item.requestedShipmentDate,
      };

      // recalculate to make sure all values are present
      return recalculateLineItem({
        ...item,
        // if there's no unitPriceBasis, we simply set it to one, as we need
        // it in the lineItemDecorator for price calculations
        unitPriceBasis,
        ordersScheduleLines: [newScheduleLine],
        allowancesAndCharges: processedAllowances,
      });
    }

    // recalculate to make sure all values are present
    return recalculateLineItem({
      ...item,
      unitPriceBasis,
      allowancesAndCharges: processedAllowances,
    });
  });
};

/**
 * @param order
 * @returns {*}
 */
export const preProcessOrder = (order) => {
  let clonedDoc = Object.assign({}, order);
  const lineItems = clonedDoc?.details?.ordersData?.ordersLineItems || [];
  const processedLineItems = preprocessOrderLineItems(lineItems);

  // prepare the cloned object
  clonedDoc = Object.assign(clonedDoc, {
    ...clonedDoc,
    header: {
      ...clonedDoc.header,
      referenceCurrency: clonedDoc?.header?.referenceCurrency || CURRENCY_EUR,
      beginningOfMessage: {
        ...clonedDoc.header?.beginningOfMessage,
        messageFunction: null,
      },
    },
    details: {
      ...clonedDoc.details,
      ordersData: {
        ...clonedDoc.details?.ordersData,
        ordersLineItems: processedLineItems,
      },
    },
  });

  const diff = calculateFooterTaxes(processedLineItems);

  return Object.assign(clonedDoc, {
    ...diff,
    footer: {
      ...diff?.footer,
      invoiceFooter: {
        ...diff?.footer?.invoiceFooter,
        // clear the vat rates
        vatrates: [],
      },
    },
  });
};
