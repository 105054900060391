import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {get as g} from 'lodash';
import {Grid, Header, Segment, List, Loader} from 'semantic-ui-react';
import {FormatDate} from '@ecosio/components';
import {FormattedMessage} from 'react-intl';
import {RenderContents, dynID} from '@ecosio/pathform';
import {scenarioShape, pageConfigShape} from '../../shapes/scenarios';
import {MessageType} from '../../helpers/mappers.jsx';

import {isInboundDocType} from '../../util/scenarioUtil';
import LinkList from './LinkList';
import {TabbedItem} from './Order/TabbedItem';

export const RETURN_ORDERS_ENCODED = '70E';
export const RETURN_ORDER_TYPE = 'RETANN';

export const CREDIT_NOTE_ENCODED = '381';
export const CREDIT_NOTE_TYPE = 'CREDIT_NOTE';

const DAILY_DOCUMENT = 'DAILY_DOCUMENT';

const CustomProperties = ({items, formFields}) => {
  const noValues = !items || !Object.keys(items).length;
  return (
    <Fragment>
      <Header as="h4">
        <FormattedMessage id="CUSTOM_PROPERTIES_HEADER" />
      </Header>
      {noValues ? (
        <List>
          {Object.keys(formFields).map((i) => (
            <List.Item divided="true" key={i}>
              <List.Content floated="right">---</List.Content>
              <List.Content>
                <strong>
                  <FormattedMessage id={formFields[i].input.label} />:
                </strong>
              </List.Content>
            </List.Item>
          ))}
        </List>
      ) : (
        <RenderContents initialValues={items} fields={formFields}>
          {(ctx) => {
            return (
              <List>
                {Object.keys(ctx.fields).map((i) => {
                  return <TabbedItem path={ctx.fields[i].path} key={i} />;
                })}
              </List>
            );
          }}
        </RenderContents>
      )}
    </Fragment>
  );
};

CustomProperties.propTypes = {
  items: PropTypes.array,
  formFields: PropTypes.object.isRequired,
};

const CreateSubHeadline = ({documentType}) => (
  <FormattedMessage
    id="BUSINESSCASE_CREATE_SUB_HEADLINE"
    values={{
      documentType: MessageType(documentType),
    }}
  />
);

CreateSubHeadline.propTypes = {
  documentType: PropTypes.string.isRequired,
};

const ViewSubHeadline = ({documentType, exchange}) => (
  <FormattedMessage
    id="BUSINESSCASE_SUB_HEADLINE"
    values={{
      documentType: MessageType(documentType),
      date: (
        <FormatDate
          stripTimezone
          dateString={g(
            exchange,
            'data.document.header.dates.documentDate.dateTime'
          )}
        />
      ),
    }}
  />
);

ViewSubHeadline.propTypes = {
  documentType: PropTypes.string.isRequired,
  exchange: PropTypes.object.isRequired,
};

const ContentHeader = ({
  correlatorId,
  documentExchange,
  metaDocuments,
  scenario,
  pageConfig,
}) => {
  let documentType = g(
    documentExchange,
    'data.document.header.messageHeader.messageType'
  );

  const documentNameCoded = pageConfig?.documentTypeEncoded;

  if (documentNameCoded === RETURN_ORDERS_ENCODED) {
    documentType = RETURN_ORDER_TYPE;
  }

  if (documentNameCoded === CREDIT_NOTE_ENCODED) {
    documentType = CREDIT_NOTE_TYPE;
  }

  const isInbound =
    documentType === 'merged' || isInboundDocType(documentType, scenario);

  const businessCaseHeadingTranslationKey =
    scenario?.businessCaseHeadingTranslationKey;

  return (
    <Fragment>
      <Header as="h2" data-spec="content-header">
        {businessCaseHeadingTranslationKey ? (
          <FormattedMessage
            id={dynID(businessCaseHeadingTranslationKey)}
            values={{correlatorId: correlatorId}}
          />
        ) : (
          <FormattedMessage
            id="BUSINESSCASE_HEADLINE"
            values={{correlatorId: correlatorId}}
          />
        )}

        <Header.Subheader>
          {isInbound ? (
            <ViewSubHeadline
              documentType={documentType}
              exchange={documentExchange}
            />
          ) : (
            <CreateSubHeadline documentType={documentType} />
          )}
        </Header.Subheader>
      </Header>

      {metaDocuments ? (
        <LinkList
          optionalTranslation={pageConfig?.headerButtonLabel}
          metaDocuments={metaDocuments}
        />
      ) : null}
    </Fragment>
  );
};

ContentHeader.propTypes = {
  correlatorId: PropTypes.string,
  documentExchange: PropTypes.object,
  metaDocuments: PropTypes.object,
  scenario: scenarioShape,
  pageConfig: pageConfigShape,
};

const DocumentHeader = ({
  messageId,
  envelope,
  scenario,
  documentExchange,
  metaDocuments = null,
  customProperties,
  children = null,
  pageConfig,
}) => {
  const isDailyDocument = scenario?.listLayout === DAILY_DOCUMENT;
  return envelope.correlatorId && !documentExchange.fetching ? (
    <Fragment>
      <Segment
        attached={children ? 'top' : false}
        data-erpelid={messageId}
        data-spec="doc-header">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={10}>
              <ContentHeader
                documentExchange={documentExchange}
                correlatorId={envelope.correlatorId}
                metaDocuments={metaDocuments}
                scenario={scenario}
                pageConfig={pageConfig}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              {scenario && scenario.customPropertiesConfig.enabled ? (
                <CustomProperties
                  items={customProperties}
                  formFields={scenario.customPropertiesConfig.formFields}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {children ? (
        <Segment attached="bottom" style={{textAlign: 'right'}}>
          {children}
        </Segment>
      ) : null}
    </Fragment>
  ) : isDailyDocument ? null : (
    <Loader active inline="centered" />
  );
};

DocumentHeader.propTypes = {
  envelope: PropTypes.object.isRequired,
  documentExchange: PropTypes.object.isRequired,
  metaDocuments: PropTypes.any,
  scenario: scenarioShape,
  children: PropTypes.any,
  customProperties: PropTypes.object,
  messageId: PropTypes.string,
  pageConfig: pageConfigShape.isRequired,
};

export default DocumentHeader;
