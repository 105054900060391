import React from 'react';
import PropTypes from 'prop-types';
import PALETTE from '../../assets/cps/PALETTE.svg?react';
import BOX from '../../assets/cps/BOX.svg?react';
import SVGRenderer from '../../helpers/components/SVGRenderer.tsx';

const ICONS = [
  {
    type: 'PALETTE',
    icon: PALETTE,
  },
  {
    type: 'BOX',
    icon: BOX,
  },
];
const CpsIcon = ({type, size = '22px', onClick, ...rest}) => {
  const item = ICONS.find((item) => item.type === type.toUpperCase());
  const isIE = !!window.MSInputMethodContext && !!document.documentMode;

  const style = onClick ? {cursor: 'pointer'} : {};

  if (!item) {
    console.info(`CpsIcon: Cant find SVG icon ${type}`);
    return null;
  }

  if (isIE && size !== 'auto') {
    return (
      <SVGRenderer
        height={size}
        width={size}
        svgIcon={item.icon}
        style={style}
        onClick={onClick}
        {...rest}
      />
    );
  } else if (size === 'auto') {
    return (
      <SVGRenderer
        svgIcon={item.icon}
        style={style}
        onClick={onClick}
        {...rest}
      />
    );
  } else {
    return (
      <SVGRenderer
        height={size}
        svgIcon={item.icon}
        style={style}
        onClick={onClick}
        {...rest}
      />
    );
  }
};

CpsIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default CpsIcon;
