/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Table, Button, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import ConfirmPopup from '../../../../../ConfirmPopup';

const DispatchQuantity = ({item, show}) => {
  if (!show) {
    return null;
  }

  return (
    <Table.Cell verticalAlign="top">
      {item.deliveryLineItems[0].dispatchQuantity.value}{' '}
      {item.deliveryLineItems[0].dispatchQuantity.unit}
    </Table.Cell>
  );
};

DispatchQuantity.propTypes = {
  item: PropTypes.object,
  show: PropTypes.bool,
};

const PackagingID = ({item, show}) => {
  if (!show) {
    return null;
  }

  return (
    <Table.Cell verticalAlign="top">
      {item.packagingInformations.map((x, idx) => {
        return (
          <Fragment key={idx}>
            {x.identifications.map((k, i) => {
              return <div key={`${idx}-${i}`}>{k.identityNumber}</div>;
            })}
            <br />
          </Fragment>
        );
      })}
    </Table.Cell>
  );
};

PackagingID.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.object,
};

const Packaging = ({item, unit, show}) => {
  if (!show) {
    return null;
  }

  return (
    <Table.Cell verticalAlign="top">
      {item.packagingInformations.map((x, idx) => {
        /*
        Make new array with length of packaging uuids (+1) with linebreaks from index 0
        to visually seperate the values of the 2 columns packaging and package id
        */
        const breaks = new Array((x.identifications.length || 0) + 1).fill(
          <br key={`br-${idx}`} />,
          0
        );

        return (
          <div key={`packaging-${idx}`}>
            {x.numberOfPackages} {x.packageTypeCustomer} x {x.quantityPerPack}{' '}
            {unit}
            {breaks}
          </div>
        );
      })}
    </Table.Cell>
  );
};

Packaging.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.object,
  unit: PropTypes.string,
};

export const HeaderCell = ({field, width}) => {
  if (!field) {
    return null;
  }
  // the field is disabled in the table, don't render it
  if (field.tableColumn === false) {
    return null;
  }

  return (
    <Table.HeaderCell width={width}>
      <FormattedMessage id={field.input.label} />
    </Table.HeaderCell>
  );
};

HeaderCell.propTypes = {
  width: PropTypes.number,
  field: PropTypes.shape({
    input: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  }),
};

const TableHeader = ({showActions, pageConfig}) => {
  const getField = (path) => {
    return pageConfig.formFields[path];
  };

  return (
    <Table.Header>
      <Table.Row>
        <HeaderCell field={getField('positionNumber')} />
        <HeaderCell field={getField('gtin')} />
        <HeaderCell field={getField('manufacturersArticleNumber')} />
        <HeaderCell field={getField('customersItemMaterialNumber')} />
        <HeaderCell field={getField('suppliersItemMaterialNumber')} />
        <HeaderCell field={getField('itemDescription')} />
        <HeaderCell field={getField('batchNumber')} />
        <HeaderCell field={getField('itemCategory')} />
        <HeaderCell field={getField('packaging')} />
        <HeaderCell field={getField('packagingInformation')} />
        <HeaderCell field={getField('dispatchQuantity')} />
        {showActions ? null : <Table.HeaderCell />}
      </Table.Row>
    </Table.Header>
  );
};

TableHeader.propTypes = {
  showActions: PropTypes.bool,
  pageConfig: PropTypes.object,
};

const TableBody = ({
  cpsitems,
  onEdit,
  onDuplicate,
  onDelete,
  onView,
  pageConfig,
  onlyView,
}) => {
  const allowDelete =
    !onlyView &&
    get(pageConfig, 'lineItemConfiguration.allowDeleteLineItem', false) &&
    cpsitems.length > 1;

  const allowDuplicate =
    !onlyView &&
    get(pageConfig, 'lineItemConfiguration.allowDuplicateLineItem', false);

  const allowEdit = !onlyView && onEdit;

  const getField = (path) => {
    return pageConfig.formFields[path];
  };

  const Cell = ({value, config, width}) => {
    // it's not configured, don't render the cell at all
    if (!config) {
      return null;
    }

    // the field is disabled in the table, don't render it
    if (!config.tableColumn) {
      return null;
    }

    switch (config.type) {
      // TODO: date / number types
      case 'dropdown':
        const opt = config.options.find((item) => item.value === value) || {
          text: 'GENERAL_SHIPMENT_NOT_FOUND',
        };
        return (
          <Table.Cell width={width} verticalAlign="top">
            <FormattedMessage id={opt.text} />
          </Table.Cell>
        );

      default:
        return (
          <Table.Cell width={width} verticalAlign="top">
            {value}
          </Table.Cell>
        );
    }
  };

  Cell.propTypes = {
    value: PropTypes.any,
    config: PropTypes.object,
    width: PropTypes.number,
  };

  return (
    <Table.Body>
      {cpsitems.map((item, idx) => (
        <Table.Row key={idx}>
          <Cell
            config={getField('positionNumber')}
            value={item.deliveryLineItems[0].positionNumber}
          />
          <Cell
            config={getField('gtin')}
            value={item.deliveryLineItems[0].gtin}
          />

          <Cell
            config={getField('manufacturersArticleNumber')}
            value={item.deliveryLineItems[0].manufacturersArticleNumber}
          />
          <Cell
            config={getField('customersItemMaterialNumber')}
            value={item.deliveryLineItems[0].customersItemMaterialNumber}
          />

          <Cell
            config={getField('suppliersItemMaterialNumber')}
            value={item.deliveryLineItems[0].suppliersItemMaterialNumber}
          />

          <Cell
            config={getField('itemDescription')}
            value={item.deliveryLineItems[0].itemDescription}
          />

          <Cell
            config={getField('batchNumber')}
            value={item.deliveryLineItems[0].batchNumber}
          />

          <Cell
            config={getField('itemCategory')}
            value={item.deliveryLineItems[0].itemCharacteristic}
          />

          {/* TODO: dangerous, possibly undefined */}
          <Packaging
            item={item}
            unit={item.deliveryLineItems[0].dispatchQuantity.unit}
            show={typeof getField('packaging') === 'object'}
          />

          <PackagingID
            item={item}
            show={typeof getField('packagingInformation') === 'object'}
          />

          <DispatchQuantity
            item={item}
            show={typeof getField('dispatchQuantity') === 'object'}
          />

          {onlyView && !onView ? null : (
            <Table.Cell verticalAlign="top" collapsing>
              {onView && (
                <Button
                  size="mini"
                  color="purple"
                  compact
                  index={idx}
                  onClick={onView}
                  icon>
                  <Icon name="eye" />
                </Button>
              )}

              {allowEdit && (
                <Button
                  size="mini"
                  color="purple"
                  compact
                  index={idx}
                  onClick={onEdit}
                  icon>
                  <Icon name="edit" />
                </Button>
              )}

              {allowDuplicate && (
                <Button
                  size="mini"
                  color="purple"
                  index={idx}
                  onClick={onDuplicate}
                  compact
                  icon>
                  <Icon name="copy outline" />
                </Button>
              )}

              {allowDelete && (
                <ConfirmPopup
                  dialogtitle={
                    <span>
                      <FormattedMessage id="GENERAL_DELETE" /> ?
                    </span>
                  }
                  dialogcontent={<FormattedMessage id="ORDER_POS_DELETE" />}
                  callbackprops={{index: idx, lineitem: null}}
                  onConfirm={onDelete}
                  trigger={
                    <Button size="mini" color="red" compact icon>
                      <Icon name="trash" />
                    </Button>
                  }
                />
              )}
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table.Body>
  );
};

TableBody.propTypes = {
  cpsitems: PropTypes.array,
  onEdit: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  pageConfig: PropTypes.object,
  onlyView: PropTypes.bool,
};

const DesadvLineItemTable = ({
  cpsitems,
  pageConfig,
  onEdit,
  onDuplicate,
  onDelete,
  onlyView,
  onView,
}) => (
  <Table compact celled>
    <TableHeader showActions={onlyView && !onView} pageConfig={pageConfig} />
    {cpsitems && (
      <TableBody
        onView={onView}
        onlyView={onlyView}
        pageConfig={pageConfig}
        cpsitems={cpsitems}
        onDelete={onDelete}
        onEdit={onEdit}
        onDuplicate={onDuplicate}
      />
    )}
  </Table>
);

DesadvLineItemTable.propTypes = {
  cpsitems: PropTypes.array,
  pageConfig: PropTypes.object,
  onEdit: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onlyView: PropTypes.bool,
  onView: PropTypes.func,
};

export default DesadvLineItemTable;
