import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import {Loader} from 'semantic-ui-react';
import GracefulConfigurationError from '../Error/GracefulConfigurationError';
import {BASE_URL} from '../../constants';
import ScenarioChecksumPolling from '../../helpers/components/ScenarioChecksumPolling';

/**
 * HoC to inject the current scenario into the wrapped component
 * based on the scenarioUuid URL parameter.
 */
const withScenario = () => (WrappedComponent) => {
  const WithScenario = (props) => {
    const {
      scenarios,
      ediPermissions,
      downloadConfigurations,
      match,
      location,
    } = props;
    const scenario = scenarios.find(
      (item) => item.uuid === match.params.scenarioUuid
    );

    if (scenario === undefined) {
      // Scenario with current EDI permission is not found, try finding it in other EDIPermissions
      if (ediPermissions) {
        const ediPermission = ediPermissions.find((permission) =>
          permission.webediScenarioUuids?.includes(match.params.scenarioUuid)
        );
        if (ediPermission) {
          // change EDI permission to match the one user needs for the document
          console.info(
            `[DEBUG] User should change edi permission to ${ediPermission.uuid} for company ${ediPermission.offeringCompany.name}`
          );
          axios
            .put(`/api/webediUser/setEdiPermission`, null, {
              params: {
                ediPermissionUuid: ediPermission.uuid,
              },
            })
            .then(() => {
              // Prepend /webedi to route and redirect user to the original URL
              window.location.replace(BASE_URL + location.pathname);
            })

            .catch((e) => {
              console.warn(e);
              return <GracefulConfigurationError></GracefulConfigurationError>;
            });

          return <Loader active />;
        } else {
          console.warn(
            `Configuration issue: Scenario ${match.params.scenarioUuid} not configured for user`
          );
          return (
            <GracefulConfigurationError
              error={{
                message: `Configuration issue: This scenario not configured for user`,
              }}></GracefulConfigurationError>
          );
        }
      }
    } else {
      return (
        <>
          <ScenarioChecksumPolling scenario={scenario} />
          <WrappedComponent
            scenario={scenario}
            downloadConfigurations={downloadConfigurations}
            scenarioUuid={scenario.uuid}
            {...props}
          />
        </>
      );
    }
  };

  WithScenario.propTypes = {
    scenarios: PropTypes.array,
    ediPermissions: PropTypes.array,
    downloadConfigurations: PropTypes.array,
    match: PropTypes.object,
  };

  return WithScenario;
};

export default withScenario;
