import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {List, Header, Segment, Divider, Grid} from 'semantic-ui-react';
import {FormatDate} from '@ecosio/components';

import {FormattedMessage} from 'react-intl';
import get from 'lodash/get';
import ForecastQuantities from './ForecastQuantities';

import {TabbedItem, BusinessEntity} from './DelforCommons';

const ForecastLineItemHeader = ({consignee}) => (
  <BusinessEntity data={consignee} headerId="GENERAL_CONSIGNEE" size="small" />
);

ForecastLineItemHeader.propTypes = {
  consignee: PropTypes.shape({
    partyQualifier: PropTypes.string,
    partyIdentification: PropTypes.string,
    partyIdentificationCodeListQualifier: PropTypes.string,
    partyName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    postCode: PropTypes.string,
    country: PropTypes.shape({countryName: PropTypes.string}),
    contact: PropTypes.shape({
      telephone: PropTypes.string,
      fax: PropTypes.string,
    }),
  }),
};

const renderDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  return <FormatDate stripTimezone dateString={dateString} />;
};

const ForecastLineItemDetails = ({lineItem}) => {
  if (!lineItem) {
    return null;
  }

  const additionalInformation = lineItem.additionalInformation
    ? lineItem.additionalInformation
    : {};

  const referencedDocumentsDatesValues = lineItem.referencedDocumentsDatesValues
    ? lineItem.referencedDocumentsDatesValues
    : {};

  return (
    <List>
      <TabbedItem
        title="LE_ITEM_POSITION_NUMBER"
        content={get(lineItem, 'positionNumber')}
      />
      <TabbedItem
        title="LE_CUMULATIVE_SCHEDULED_QUANTITY"
        content={get(
          referencedDocumentsDatesValues,
          'cumulativeScheduledQuantity'
        )}
      />
      <TabbedItem
        title="LE_CUMULATIVE_RECEIVED_QUANTITY"
        content={get(
          referencedDocumentsDatesValues,
          'cumulativeReceivedQuantity'
        )}
      />
      <TabbedItem
        title="LE_LAST_RECEIVED_QUANTITY"
        content={get(referencedDocumentsDatesValues, 'lastReceivedQuantity')}
      />
      <TabbedItem
        title="LE_LAST_ASN_NUMBER"
        content={get(referencedDocumentsDatesValues, 'lastASNNumber')}
      />
      <TabbedItem
        title="LE_LAST_ASN_DATE"
        content={renderDate(
          get(referencedDocumentsDatesValues, 'lastASNDate.dateTime')
        )}
      />
      <TabbedItem
        title="GENERAL_DESCRIPTION"
        content={additionalInformation.itemDescription}
      />
      <TabbedItem
        title="LE_PLANNING_HORIZON_START"
        content={renderDate(
          get(
            referencedDocumentsDatesValues,
            'planningHorizionStartDate.dateTime'
          )
        )}
      />
      <TabbedItem
        title="LE_PLANNING_HORIZON_END"
        content={renderDate(
          get(
            referencedDocumentsDatesValues,
            'planningHorizionEndDate.dateTime'
          )
        )}
      />
      <TabbedItem
        title="LE_DRAWING_REVISION_NUMBER"
        content={additionalInformation.drawingRevisionNumber}
      />
      <TabbedItem title="LE_GTIN" content={additionalInformation.gtin} />
      <TabbedItem
        title="LE_MANUFACTURERS_ARTICLENUMBER"
        content={additionalInformation.manufacturersArticleNumber}
      />
      <TabbedItem
        title="LE_CUSTOMERS_ITEM_MATERIAL_NUMBER"
        content={additionalInformation.customersItemMaterialNumber}
      />
      <TabbedItem
        title="LE_SUPPLIERS_ITEM_MATERIAL_NUMBER"
        content={additionalInformation.suppliersItemMaterialNumber}
      />
      <TabbedItem
        title="LE_SUPPLIERS_ITEM_PRODUCTION_AUTHORIZATION_DATE"
        content={renderDate(
          get(
            referencedDocumentsDatesValues,
            'productionAuthorization.authorizationEndDate.dateTime'
          )
        )}
      />
      <TabbedItem
        title="LE_SUPPLIERS_ITEM_MATERIAL_AUTHORIZATION_DATE"
        content={renderDate(
          get(
            referencedDocumentsDatesValues,
            'materialAuthorization.authorizationEndDate.dateTime'
          )
        )}
      />
    </List>
  );
};

ForecastLineItemDetails.propTypes = {
  lineItem: PropTypes.shape({
    additionalInformation: PropTypes.any,
    referencedDocumentsDatesValues: PropTypes.shape({
      callOffNumber: PropTypes.string,
      callOffDate: PropTypes.shape({
        dateQualifier: PropTypes.string,
        dateTime: PropTypes.string,
      }),
      cumulativeReceivedQuantity: PropTypes.number,
      cumulativeScheduledQuantity: PropTypes.number,
      lastReceivedQuantity: PropTypes.number,
      planningHorizionStartDate: PropTypes.shape({
        dateQualifier: PropTypes.string,
      }),
      planningHorizionEndDate: PropTypes.shape({
        dateQualifier: PropTypes.string,
        dateTime: PropTypes.string,
      }),
      materialAuthorization: PropTypes.shape({
        authorizationQuantity: PropTypes.number,
        authorizationEndDate: PropTypes.shape({
          dateQualifier: PropTypes.string,
          dateTime: PropTypes.string,
        }),
      }),
      productionAuthorization: PropTypes.shape({
        authorizationQuantity: PropTypes.number,
        authorizationEndDate: PropTypes.shape({
          dateQualifier: PropTypes.string,
          dateTime: PropTypes.string,
        }),
      }),
    }),
  }),
};

const ForcastItem = ({lineitem}) => {
  return (
    <Fragment>
      <Segment attached="top">
        <Header as="h2" size="large">
          <FormattedMessage id="PART_DETAILS" />{' '}
          {get(
            lineitem,
            'additionalInformation.customersItemMaterialNumber',
            'N/A'
          )}
        </Header>
      </Segment>
      <Segment attached="bottom">
        <Grid columns={2}>
          <Grid.Column mobile={16} tablet={16} computer={6}>
            <ForecastLineItemHeader
              consignee={get(lineitem, 'businessEntities.consignee')}
            />
            <Divider />
            <ForecastLineItemDetails lineItem={lineitem} />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={10}>
            <ForecastQuantities items={lineitem.planningQuantities} />
          </Grid.Column>
        </Grid>
      </Segment>
    </Fragment>
  );
};

ForcastItem.propTypes = {
  lineitem: PropTypes.object,
};

export default ForcastItem;
