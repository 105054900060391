/* eslint-disable react/no-array-index-key */
import React from 'react';
import {Icon} from 'semantic-ui-react';
import styled from 'styled-components';

const ArrayValuePinRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  div {
    flex: 1;
    word-break: break-word;
  }
`;

const ArrayValuePins = ({values}) => {
  if (!values?.length) {
    return null;
  }

  return values.map((name, index) => {
    return (
      <ArrayValuePinRow key={index}>
        <Icon name="map pin" />
        <div>{values[index]}</div>
      </ArrayValuePinRow>
    );
  });
};

export default ArrayValuePins;
