import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';
import {get} from 'lodash';
import {ItemsContainer} from '../DesadvLineItems';
import DesadvInput from '../../../../CommonComponents/Create/LineItems/DesadvInput';
import {
  FieldContent,
  valueIsUndefined,
} from '../../../../CommonComponents/Create/LineItems/FieldContent';
import ArrayValuePins from '../../../../CommonComponents/Create/Header/OrderHeaderInformation/ViewFields/ArrayValuePins.jsx';

export const LineItemReadFieldsFromEditForm = ({
  index,
  formFieldConfig,
  initialValues,
  sscc,
  partialDeliveryAllowed,
}) => {
  return (
    <ItemsContainer
      key={`Read_DeliveryFields_${index}`}
      className="desadvLineItemEditComponent">
      <DesadvInput
        columnWidth={1}
        path="partialDelivery"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.partialDelivery || partialDeliveryAllowed
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={
              initialValues[index]?.partialDelivery === 'true' ||
              initialValues[index]?.partialDelivery === true ? (
                <Icon name="check" />
              ) : (
                <Icon name="x" />
              )
            }
            fieldConfig={get(formFieldConfig, 'partialDelivery')}
          />
        )}
      />

      <DesadvInput
        columnWidth={2}
        path="dispatchQuantity.value"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.dispatchQuantity?.value
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.dispatchQuantity?.value}
            unit={initialValues[index]?.dispatchQuantity?.unit}
            fieldConfig={get(formFieldConfig, 'dispatchQuantity.value')}
          />
        )}
      />
      {sscc ? (
        <Fragment>
          <DesadvInput
            columnWidth={2.5}
            path="quantityPerPack"
            formFieldConfig={formFieldConfig}
            readOnlyField
            shouldNotRender={valueIsUndefined(
              initialValues[index]?.quantityPerPack
            )}
            render={(props) => (
              <FieldContent
                {...props}
                value={initialValues[index]?.quantityPerPack}
                unit={initialValues[index]?.dispatchQuantity?.unit}
                fieldConfig={get(formFieldConfig, 'quantityPerPack')}
              />
            )}
          />
          <DesadvInput
            columnWidth={2.5}
            forceRender
            readOnlyField
            render={(props) => (
              <FieldContent
                {...props}
                value={
                  initialValues[index]?.cpses &&
                  Array.isArray(initialValues[index]?.cpses)
                    ? initialValues[index]?.cpses.length
                    : 0
                }
                unit="LE_PACKAGES"
                fieldConfig={get(
                  formFieldConfig,
                  'packagingInformations.numberOfPackages'
                )}
              />
            )}
          />
        </Fragment>
      ) : (
        ''
      )}
      <DesadvInput
        columnWidth={3}
        path="primaryPackagingType.value"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.primaryPackagingType?.value
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.primaryPackagingType?.value}
            fieldConfig={get(formFieldConfig, 'primaryPackagingType.value')}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="countryOfOrigin.countryName"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.countryOfOrigin?.countryName
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.countryOfOrigin?.countryName}
            fieldConfig={get(formFieldConfig, 'countryOfOrigin.countryName')}
          />
        )}
      />
      <DesadvInput
        columnWidth={2}
        path="batchNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(initialValues[index]?.batchNumber)}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.batchNumber}
            fieldConfig={get(formFieldConfig, 'batchNumber')}
          />
        )}
      />

      <DesadvInput
        columnWidth={2}
        path="expirationDate"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(initialValues[index]?.expirationDate)}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.expirationDate}
            fieldConfig={get(formFieldConfig, 'expirationDate')}
          />
        )}
      />
      <DesadvInput
        columnWidth={2}
        path="manufacturingDepartments"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.manufacturingDepartments
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={
              <ArrayValuePins
                values={initialValues[index]?.manufacturingDepartments}
              />
            }
            fieldConfig={get(formFieldConfig, 'manufacturingDepartments')}
          />
        )}
      />
      <DesadvInput
        columnWidth={2}
        path="ggns"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(initialValues[index]?.ggns)}
        render={(props) => (
          <FieldContent
            {...props}
            value={<ArrayValuePins values={initialValues[index]?.ggns} />}
            fieldConfig={get(formFieldConfig, 'ggns')}
          />
        )}
      />
    </ItemsContainer>
  );
};

LineItemReadFieldsFromEditForm.defaultProps = {};

LineItemReadFieldsFromEditForm.propTypes = {
  index: PropTypes.number.isRequired,
  formFieldConfig: PropTypes.object.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  sscc: PropTypes.bool.isRequired,
  partialDeliveryAllowed: PropTypes.bool,
};

const LineItemViewFields = ({index, formFieldConfig, initialValues}) => {
  return (
    <ItemsContainer
      key={`Read_deliveryLineItems_${index}`}
      backgroundColor="#F8F8F8">
      <DesadvInput
        columnWidth={1}
        path="positionNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(initialValues[index]?.positionNumber)}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.positionNumber}
            fieldConfig={get(formFieldConfig, 'positionNumber')}
          />
        )}
      />
      <DesadvInput
        columnWidth={4}
        path="itemDescription"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.itemDescription
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.itemDescription}
            fieldConfig={get(formFieldConfig, 'itemDescription')}
            injectedLabelValue={
              initialValues[index]?.references?.agreementOrOrderLineNumber
            }
          />
        )}
      />

      <DesadvInput
        columnWidth={2}
        path="gtin"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(initialValues[index]?.gtin)}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.gtin}
            fieldConfig={get(formFieldConfig, 'gtin')}
          />
        )}
      />
      <DesadvInput
        columnWidth={2.5}
        path="customersItemMaterialNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.customersItemMaterialNumber
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.customersItemMaterialNumber}
            fieldConfig={get(formFieldConfig, 'customersItemMaterialNumber')}
          />
        )}
      />

      <DesadvInput
        columnWidth={2.5}
        path="suppliersItemMaterialNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.suppliersItemMaterialNumber
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.suppliersItemMaterialNumber}
            fieldConfig={get(formFieldConfig, 'suppliersItemMaterialNumber')}
          />
        )}
      />

      <DesadvInput
        columnWidth={2}
        path="requestedQuantity.value"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.requestedQuantity?.value
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.requestedQuantity?.value}
            unit={initialValues[index]?.requestedQuantity?.unit}
            fieldConfig={get(formFieldConfig, 'requestedQuantity.value')}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="alternativeQuantity.value"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.alternativeQuantity?.value
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.alternativeQuantity?.value}
            unit={initialValues[index]?.alternativeQuantity?.unit}
            fieldConfig={get(formFieldConfig, 'alternativeQuantity.value')}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="references.contractReference.referenceNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.references?.contractReference?.referenceNumber
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={
              initialValues[index]?.references?.contractReference
                ?.referenceNumber
            }
            fieldConfig={get(
              formFieldConfig,
              'references.contractReference.referenceNumber'
            )}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="promotion.referenceNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.promotion?.referenceNumber
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.promotion?.referenceNumber}
            fieldConfig={get(formFieldConfig, 'promotion.referenceNumber')}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="promotion.date.dateTime"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.promotion?.date?.dateTime
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.promotion?.date?.dateTime}
            fieldConfig={get(formFieldConfig, 'promotion.date.dateTime')}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="promotion.endDate.dateTime"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.promotion?.endDate?.dateTime
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.promotion?.endDate?.dateTime}
            fieldConfig={get(formFieldConfig, 'promotion.endDate.dateTime')}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="itemCharacteristic"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.itemCharacteristic
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.itemCharacteristic}
            fieldConfig={get(formFieldConfig, 'itemCharacteristic')}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="secondaryPackagingType.value"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.secondaryPackagingType?.value
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.secondaryPackagingType?.value}
            fieldConfig={get(formFieldConfig, 'secondaryPackagingType.value')}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="lotNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(initialValues[index]?.lotNumber)}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.lotNumber}
            fieldConfig={get(formFieldConfig, 'lotNumber')}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="caliber.value"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(initialValues[index]?.caliber?.value)}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.caliber?.value}
            fieldConfig={get(formFieldConfig, 'caliber.value')}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="references.agreementOrOrderLineNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.references?.agreementOrOrderLineNumber
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={initialValues[index]?.references?.agreementOrOrderLineNumber}
            fieldConfig={get(
              formFieldConfig,
              'references.agreementOrOrderLineNumber'
            )}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="references.agreementOrOrderNumberReference"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          initialValues[index]?.references?.agreementOrOrderNumberReference
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={
              initialValues[index]?.references?.agreementOrOrderNumberReference
            }
            fieldConfig={get(
              formFieldConfig,
              'references.agreementOrOrderNumberReference'
            )}
          />
        )}
      />
    </ItemsContainer>
  );
};

LineItemViewFields.defaultProps = {};

LineItemViewFields.propTypes = {
  index: PropTypes.number.isRequired,
  formFieldConfig: PropTypes.object.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LineItemViewFields;
