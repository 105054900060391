import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import {Popup} from 'semantic-ui-react';
import {dynID} from '@ecosio/pathform';
import htmlParse from 'html-react-parser';
import {FormatDate, intlShape} from '@ecosio/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {get} from 'lodash';

export const StyledReadyOnlyField = styled.div`
  span {
    word-wrap: break-word;
  }
  label {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  div {
    display: flex;
  }
`;

export const valueIsUndefined = (value) => {
  return typeof value === 'undefined';
};

export const isFieldReadOnly = (formFieldConfig, path) => {
  return get(formFieldConfig, path)?.input?.disabled === true;
};

export const fieldShouldBeRenderedAsReadOnly = (
  document,
  formFieldConfig,
  path
) => {
  return (
    !valueIsUndefined(get(document, path)) &&
    isFieldReadOnly(formFieldConfig, path)
  );
};

export const FieldContent = injectIntl(
  ({
    value,
    unit,
    fieldConfig,
    injectedLabelValue,
    injectedLabelValue2,
    intl,
    translatedLabel,
    renderLabel = true,
  }) => {
    if (fieldConfig && !valueIsUndefined(value)) {
      const labelTranslation = fieldConfig?.input?.label;
      const labelPopupTranslation = fieldConfig?.input?.labelPopupText;
      const inputType = fieldConfig?.input?.type;
      const injectedValues =
        injectedLabelValue || injectedLabelValue2
          ? {
              values: {
                value: injectedLabelValue,
                value2: injectedLabelValue2,
              },
            }
          : {};
      return (
        <StyledReadyOnlyField data-spec={fieldConfig?.path}>
          {renderLabel ? (
            <Popup
              content={
                translatedLabel
                  ? translatedLabel
                  : intl.messages[labelPopupTranslation]
                  ? intl.formatMessage({id: dynID(labelPopupTranslation)})
                  : intl.formatMessage(
                      {id: dynID(labelTranslation)},
                      {
                        value: injectedLabelValue,
                        value2: injectedLabelValue2,
                      }
                    )
              }
              mouseEnterDelay={500}
              trigger={
                <label className="themed">
                  {translatedLabel ? (
                    translatedLabel
                  ) : (
                    <Msg id={dynID(labelTranslation)} {...injectedValues}>
                      {(chunks) => {
                        return htmlParse(chunks.join(''));
                      }}
                    </Msg>
                  )}
                </label>
              }
            />
          ) : null}

          <span>
            {inputType === 'date' ? (
              <FormatDate stripTimezone dateString={value} />
            ) : (
              value
            )}{' '}
            {unit ? <Msg id={dynID(unit)} /> : ''}
          </span>
        </StyledReadyOnlyField>
      );
    }
    return null;
  }
);

FieldContent.propTypes = {
  value: PropTypes.any,
  unit: PropTypes.string,
  fieldConfig: PropTypes.object,
  injectedLabelValue: PropTypes.string,
  injectedLabelValue2: PropTypes.string,
  translatedLabel: PropTypes.string,
  intl: intlShape,
};

export default FieldContent;
