import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Header, Modal} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {submitForm} from '../../../../../util/formUtil';
import PaginatedSerialNumberForm from './PaginatedSerialNumberForm';
import {materialCharactericticsShape} from './SerialNumberCell';
import './SerialNumberModal.css';

const SerialNumberModal = ({lineItem, onSave, onCancel}) => {
  const doSubmit = () => {
    submitForm('serialNumberForm');
  };

  const serialNumbers = lineItem?.materialCharacteristics?.serialNumbers || [];
  const posNr = lineItem?.positionNumber || '';
  const matNr = lineItem?.suppliersItemMaterialNumber || '';

  return (
    <Fragment>
      <Modal open={lineItem !== null}>
        <Header>
          {serialNumbers.length ? (
            <FormattedMessage
              id="INVOICE_SNR_HEADER"
              values={{
                count: serialNumbers.length,
                position: posNr,
              }}
            />
          ) : (
            <FormattedMessage
              id="INVOICE_SNR_HEADER_EMPTY"
              values={{
                position: posNr,
              }}
            />
          )}

          <div className="sub">{matNr}</div>
        </Header>
        <Modal.Content>
          {lineItem != null && (
            <PaginatedSerialNumberForm
              onSave={onSave}
              onCancel={onCancel}
              arrayPath="materialCharacteristics.serialNumbers"
              inputLabel="SERIAL_NUMBERS"
              initialValues={lineItem}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          {serialNumbers.length > 0 && (
            <Button primary type="submit" onClick={doSubmit}>
              <FormattedMessage id="GENERAL_SAVE" />
            </Button>
          )}
          <Button type="submit" onClick={onCancel}>
            <FormattedMessage id="GENERAL_CANCEL" />
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

SerialNumberModal.propTypes = {
  lineItem: PropTypes.shape({
    materialCharacteristics: materialCharactericticsShape,
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SerialNumberModal;
