import PropTypes from 'prop-types';

const documentMetaShape = PropTypes.shape({
  status: PropTypes.string,
  documentTypeId: PropTypes.string,
  uuid: PropTypes.string,
  contentlength: PropTypes.number,
});

const envelopesShape = PropTypes.shape({
  status: PropTypes.string,
  documentTypeId: PropTypes.string,
  documentIdentifier: PropTypes.string,
  contentLength: PropTypes.number,
  usingCompanyUuid: PropTypes.string,
  offeringCompanyUuid: PropTypes.string,
  correlatorId: PropTypes.string,
  scenarioUuid: PropTypes.string,
  documents: PropTypes.arrayOf(documentMetaShape),
  documentData: PropTypes.shape({
    ORDERID: PropTypes.string,
  }),
  uuid: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

const envelopesListShape = PropTypes.shape({
  content: PropTypes.arrayOf(envelopesShape),
  last: PropTypes.bool,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  size: PropTypes.number,
  number: PropTypes.number,
  first: PropTypes.bool,
  numberOfElements: PropTypes.number,
});

const DEFAULT_LAYOUT = 'DEFAULT';

const DETAIL_LAYOUTS = {
  DEFAULT: DEFAULT_LAYOUT,
};

export {
  envelopesListShape,
  envelopesShape,
  documentMetaShape,
  DEFAULT_LAYOUT,
  DETAIL_LAYOUTS,
};
