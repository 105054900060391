import React from 'react';
import PropTypes from 'prop-types';
import {get, isEmpty} from 'lodash';
import DesadvInput from '../../../LineItems/DesadvInput';
import {pageConfigShape} from '../../../../../../../../../shapes/scenarios';
import {ItemsContainer} from '../EditFormFields/EditForm';
import {
  FieldContent,
  fieldShouldBeRenderedAsReadOnly,
  valueIsUndefined,
} from '../../../LineItems/FieldContent';
import HeaderFreeTextViewElements from '../../../../../../../Order/View/Header/HeaderFreeTextViewElements';
import ArrayValuePins from './ArrayValuePins.jsx';

const HeaderViewFields = ({showAllFields, document, pageConfig}) => {
  const formFieldConfig = pageConfig?.formFields || {};

  return (
    <div>
      <ItemsContainer>
        <DesadvInput
          columnWidth={2}
          path="header.businessEntities.supplier.duns"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.businessEntities.supplier.duns'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(document, 'header.businessEntities.supplier.duns')}
              fieldConfig={get(
                formFieldConfig,
                'header.businessEntities.supplier.duns'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={2}
          path="header.businessEntities.supplier.partyIdentification"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.businessEntities.supplier.partyIdentification'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(
                document,
                'header.businessEntities.supplier.partyIdentification'
              )}
              fieldConfig={get(
                formFieldConfig,
                'header.businessEntities.supplier.partyIdentification'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={2}
          path="header.businessEntities.buyer.partyIdentification"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.businessEntities.buyer.partyIdentification'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(
                document,
                'header.businessEntities.buyer.partyIdentification'
              )}
              fieldConfig={get(
                formFieldConfig,
                'header.businessEntities.buyer.partyIdentification'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={2}
          path="header.businessEntities.consignee.partyIdentification"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.businessEntities.consignee.partyIdentification'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(
                document,
                'header.businessEntities.consignee.partyIdentification'
              )}
              fieldConfig={get(
                formFieldConfig,
                'header.businessEntities.consignee.partyIdentification'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={2}
          path="header.dates.documentDate.dateTime"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.dates.documentDate.dateTime'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(document, 'header.dates.documentDate.dateTime')}
              fieldConfig={get(
                formFieldConfig,
                'header.dates.documentDate.dateTime'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={2}
          path="header.businessCaseType"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.businessCaseType'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(document, 'header.businessCaseType')}
              fieldConfig={get(formFieldConfig, 'header.businessCaseType')}
            />
          )}
        />
        <DesadvInput
          columnWidth={3}
          path="header.referencedDocuments.purchaseOrderReferenceNumber.referenceNumber"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.referencedDocuments.purchaseOrderReferenceNumber.referenceNumber'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(
                document,
                'header.referencedDocuments.purchaseOrderReferenceNumber.referenceNumber'
              )}
              fieldConfig={get(
                formFieldConfig,
                'header.referencedDocuments.purchaseOrderReferenceNumber.referenceNumber'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={2}
          path="header.referencedDocuments.purchaseOrderReferenceNumber.date.dateTime"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.referencedDocuments.purchaseOrderReferenceNumber.date.dateTime'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(
                document,
                'header.referencedDocuments.purchaseOrderReferenceNumber.date.dateTime'
              )}
              fieldConfig={get(
                formFieldConfig,
                'header.referencedDocuments.purchaseOrderReferenceNumber.date.dateTime'
              )}
            />
          )}
        />

        <DesadvInput
          columnWidth={2}
          path="header.dates.deliveryDate.dateTime"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.dates.deliveryDate.dateTime'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(document, 'header.dates.deliveryDate.dateTime')}
              fieldConfig={get(
                formFieldConfig,
                'header.dates.deliveryDate.dateTime'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={3}
          path="header.businessEntities.buyer.purchasingDepartment"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.businessEntities.buyer.purchasingDepartment'
            )
          }
          render={(props) => (
            <FieldContent
              {...props}
              value={get(
                document,
                'header.businessEntities.buyer.purchasingDepartment'
              )}
              fieldConfig={get(
                formFieldConfig,
                'header.businessEntities.buyer.purchasingDepartment'
              )}
            />
          )}
        />

        <DesadvInput
          columnWidth={3}
          path="header.freeTexts"
          formFieldConfig={formFieldConfig}
          readOnlyField
          shouldNotRender={
            !fieldShouldBeRenderedAsReadOnly(
              document,
              formFieldConfig,
              'header.freeTexts'
            )
          }
          render={() => (
            <HeaderFreeTextViewElements
              fields={pageConfig?.formFields}
              freeTextsPath="header.freeTexts"
              values={document}
            />
          )}
        />
      </ItemsContainer>
      {showAllFields ? (
        <HeaderReadFieldsFromEditFrom
          document={document}
          pageConfig={pageConfig}
        />
      ) : (
        ''
      )}
    </div>
  );
};

HeaderViewFields.defaultProps = {
  showAllFields: false,
};

HeaderViewFields.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  document: PropTypes.object,
  showAllFields: PropTypes.bool,
};

export default HeaderViewFields;

const HeaderReadFieldsFromEditFrom = ({document, pageConfig}) => {
  const formFieldConfig = pageConfig?.formFields || {};
  return (
    <ItemsContainer>
      <DesadvInput
        columnWidth={3}
        path="header.beginningOfMessage.documentNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.beginningOfMessage.documentNumber')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.beginningOfMessage.documentNumber')}
            fieldConfig={get(
              formFieldConfig,
              'header.beginningOfMessage.documentNumber'
            )}
          />
        )}
      />
      <DesadvInput
        columnWidth={4}
        path="header.referencedDocuments.masterBillOfLading.referenceNumber"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(
            document,
            'header.referencedDocuments.masterBillOfLading.referenceNumber'
          )
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(
              document,
              'header.referencedDocuments.masterBillOfLading.referenceNumber'
            )}
            fieldConfig={get(
              formFieldConfig,
              'header.referencedDocuments.masterBillOfLading.referenceNumber'
            )}
          />
        )}
      />

      <DesadvInput
        columnWidth={4}
        path="header.dates.estimatedArrivalDate.dateTime"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.dates.estimatedArrivalDate.dateTime')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.dates.estimatedArrivalDate.dateTime')}
            fieldConfig={get(
              formFieldConfig,
              'header.dates.estimatedArrivalDate.dateTime'
            )}
          />
        )}
      />
      <DesadvInput
        columnWidth={4}
        path="header.deliveryHeader.carrierID"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.deliveryHeader.carrierID')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.deliveryHeader.carrierID')}
            fieldConfig={get(
              formFieldConfig,
              'header.deliveryHeader.carrierID'
            )}
          />
        )}
      />

      <DesadvInput
        columnWidth={4}
        path="header.deliveryHeader.trackingNumbers"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={isEmpty(
          get(document, 'header.deliveryHeader.trackingNumbers')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={
              <ArrayValuePins
                values={get(document, 'header.deliveryHeader.trackingNumbers')}
              />
            }
            fieldConfig={get(
              formFieldConfig,
              'header.deliveryHeader.trackingNumbers'
            )}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="header.deliveryHeader.termsOfDeliveryOrTransport"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.deliveryHeader.termsOfDeliveryOrTransport')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(
              document,
              'header.deliveryHeader.termsOfDeliveryOrTransport'
            )}
            fieldConfig={get(
              formFieldConfig,
              'header.deliveryHeader.termsOfDeliveryOrTransport'
            )}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="header.countryOfOrigin.countryName"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.countryOfOrigin.countryName')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.countryOfOrigin.countryName')}
            fieldConfig={get(
              formFieldConfig,
              'header.countryOfOrigin.countryName'
            )}
          />
        )}
      />
      <DesadvInput
        columnWidth={3}
        path="header.headerComment"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.headerComment')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.headerComment')}
            fieldConfig={get(formFieldConfig, 'header.headerComment')}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="header.businessEntities.shipFrom.city"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.businessEntities.shipFrom.city')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.businessEntities.shipFrom.city')}
            fieldConfig={get(
              formFieldConfig,
              'header.businessEntities.shipFrom.city'
            )}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="header.businessEntities.shipFrom.street"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.businessEntities.shipFrom.street')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.businessEntities.shipFrom.street')}
            fieldConfig={get(
              formFieldConfig,
              'header.businessEntities.shipFrom.street'
            )}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="header.businessEntities.shipFrom.country.countryName"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.businessEntities.shipFrom.country.countryName')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(
              document,
              'header.businessEntities.shipFrom.country.countryName'
            )}
            fieldConfig={get(
              formFieldConfig,
              'header.businessEntities.shipFrom.country.countryName'
            )}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="header.businessEntities.shipFrom.postCode"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(document, 'header.businessEntities.shipFrom.postCode')
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(document, 'header.businessEntities.shipFrom.postCode')}
            fieldConfig={get(
              formFieldConfig,
              'header.businessEntities.shipFrom.postCode'
            )}
          />
        )}
      />

      <DesadvInput
        columnWidth={3}
        path="details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages"
        formFieldConfig={formFieldConfig}
        readOnlyField
        shouldNotRender={valueIsUndefined(
          get(
            document,
            'details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages'
          )
        )}
        render={(props) => (
          <FieldContent
            {...props}
            value={get(
              document,
              'details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages'
            )}
            fieldConfig={get(
              formFieldConfig,
              'details.dispatchData.consignmentPackagingSequences[0].packagingInformations[0].numberOfPackages'
            )}
          />
        )}
      />
    </ItemsContainer>
  );
};

HeaderReadFieldsFromEditFrom.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  document: PropTypes.object,
};
