import React from 'react';
import {Grid, Button, Table} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const DailyDocumentFooter = ({
  cols,
  loading,
  fetchData,
  responseDataCount,
  params,
  pageSize,
  dataSpec,
  renderFooter,
}) => {
  // don't have 2 loaders at the same time
  if (loading || !renderFooter) {
    return null;
  }

  return (
    <Table.Footer data-spec={dataSpec}>
      <Table.Row>
        <Table.HeaderCell colSpan={cols}>
          <Grid columns={1}>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Button
                data-spec="loadMore"
                primary
                loading={loading}
                disabled={responseDataCount < pageSize}
                style={{minWidth: '120px', margin: '15px 0 15px 0'}}
                onClick={() => fetchData(params)}>
                <FormattedMessage id="LOAD_MORE" />
              </Button>
            </Grid.Column>
          </Grid>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

DailyDocumentFooter.propTypes = {
  cols: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  params: PropTypes.object,
  responseDataCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  dataSpec: PropTypes.string,
  renderFooter: PropTypes.bool,
};

DailyDocumentFooter.defaultProps = {
  loading: null,
  params: null,
  pageSize: 20,
  dataSpec: 'table-footer',
  renderFooter: true,
};

export default DailyDocumentFooter;
