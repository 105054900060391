import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Popup} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {intlShape} from '@ecosio/components';

const ValidationWarnIcon = ({tooltipData, intl}) => {
  return (
    <Popup
      content={intl.formatMessage({id: dynID(tooltipData)})}
      trigger={<Icon name="warning sign" color="red" />}
    />
  );
};
ValidationWarnIcon.propTypes = {
  intl: intlShape,
  tooltipData: PropTypes.string,
};

export default injectIntl(ValidationWarnIcon);
