import React from 'react';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {Grid, Header} from 'semantic-ui-react';
import {RenderContents, Content} from '@ecosio/pathform';
import {FormattedMessage} from 'react-intl';
import {PartyNames} from './Order/LineItems/Table/TableBody';

const EntityAttribute = ({content}) => (
  <Grid.Row className="max condensed">
    <Grid.Column>{content}</Grid.Column>
  </Grid.Row>
);

EntityAttribute.propTypes = {
  content: PropTypes.string.isRequired,
};

const PartyNamesAttribute = ({content}) => (
  <Grid.Row className="max condensed">
    <Grid.Column>
      <PartyNames partyNames={content} />{' '}
    </Grid.Column>
  </Grid.Row>
);

PartyNamesAttribute.propTypes = {
  content: PropTypes.array.isRequired,
};

const ZipAndCity = ({path, entity, fields}) => {
  if (!fields[`${path}.postCode`] && !fields[`${path}.city`]) {
    return null;
  }

  return (
    <Grid.Row className="max condensed">
      <Grid.Column>
        {entity.postCode} {entity.city}
      </Grid.Column>
    </Grid.Row>
  );
};

ZipAndCity.propTypes = {
  path: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

const BusinessEntityComponent = ({path, object, fields}) => {
  const objectField = fields[path];
  if (!objectField) {
    return null;
  }

  const businessEntity = get(object, path);

  if (!businessEntity) {
    return null;
  }

  return (
    <React.Fragment>
      <Header as="h3" style={{marginBottom: 20, marginTop: 20}}>
        <FormattedMessage id={path || ''} />
      </Header>
      <RenderContents initialValues={object} fields={fields}>
        {() => (
          <Grid columns={1}>
            <Content path="consignee.partyNames" render={PartyNamesAttribute} />
            <Content path="consignee.city" render={EntityAttribute} />
            <ZipAndCity path={path} entity={businessEntity} fields={fields} />
          </Grid>
        )}
      </RenderContents>
    </React.Fragment>
  );
};

BusinessEntityComponent.propTypes = {
  path: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

export default BusinessEntityComponent;
