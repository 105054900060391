import React from 'react';
import {useDropzone} from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import {Progress} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {dynID} from '@ecosio/pathform';

const SubContainer = styled.div`
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 30vh;
  border-width: 2px;
  border-radius: 2px;
  border-color: 'black';
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
`;

const Container = styled.div`
  margin-bottom: 39px;
  flex: 1;
`;

const DropzoneComponent = ({
  scenarioUuid,
  setUploadedFile,
  fileUploadPageConfig = {},
  selectedFile,
  showProgressBar,
}) => {
  if (
    Object.keys(fileUploadPageConfig).length &&
    !fileUploadPageConfig?.allowedFileExtensions
  ) {
    console.warn(
      `No allowedFileExtensions config defined in fileUploadPageConfig for scenario ${scenarioUuid}`
    );
  }
  const onDrop = (acceptedFiles = []) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const {getRootProps, getInputProps, fileRejections} = useDropzone({
    onDrop,
    multiple: false,
    minSize: 1,
    accept: fileUploadPageConfig?.allowedFileExtensions
      ? fileUploadPageConfig?.allowedFileExtensions
      : null,
  });

  const fileRejectionErrors =
    fileRejections &&
    fileRejections.length > 0 &&
    fileRejections[0].errors &&
    fileRejections[0].errors.length > 0 &&
    fileRejections[0].errors;

  return (
    <Container>
      <SubContainer data-spec="fileUpload-container" {...getRootProps()}>
        <input
          data-spec="fileInput"
          type="file"
          name="file"
          {...getInputProps()}
        />
        <h3>
          <FormattedMessage id="KTM_UPLOAD_ZONE_TEXT" />
        </h3>
        <span>
          <h3>{selectedFile?.path}</h3>
          {selectedFile?.path && showProgressBar && (
            <Progress percent={selectedFile?.progress} progress />
          )}
        </span>
      </SubContainer>
      {fileRejectionErrors &&
        fileRejectionErrors.map((fileRejectionError) =>
          fileRejectionError?.code ? (
            <h3 style={{color: 'red'}} key={fileRejectionError.code}>
              <FormattedMessage
                //ErrorCodes from react-dropzone component, modified to our locales key convention
                id={dynID(
                  `KTM_${fileRejectionError.code
                    .replace(/-/g, '_')
                    .toUpperCase()}`
                )}
              />
            </h3>
          ) : (
            ''
          )
        )}
    </Container>
  );
};

DropzoneComponent.propTypes = {
  scenarioUuid: PropTypes.string.isRequired,
  setUploadedFile: PropTypes.func.isRequired,
  fileUploadPageConfig: PropTypes.object,
  selectedFile: PropTypes.object,
};

export default DropzoneComponent;
