/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Form, FormGroup, Input, Submit} from '@ecosio/pathform';
import {FormattedMessage} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {FormSpy} from 'react-final-form';
import {isEqual, includes, get, set} from 'lodash';
import {industryDocumentShape} from '../../../../../shapes/industryDocument';
import {pageConfigShape} from '../../../../../shapes/scenarios';
import BusinessEntityBankAccount from '../../../Invoice/Create/BusinessEntityBankAccount';
import {HeaderCountryOfOriginComponent} from '../../../ComplexEnrichmentComponents/HeaderCountryOfOriginComponent';
import {ORIGINAL_VAT_RATES_VARIANCE_CODE} from '../../../../../constants';

export const HEADER_FORM_ID = 'HEADER_FORM';

export const HeaderMetaContext = React.createContext({
  headerValid: false,
  // default: update function is disabled to not break other forms (ordrsp for example)
  update: null,
});

export const getFreeTextsConfigWithMaxNumberOfRows = (
  freeTextsPath,
  fields
) => {
  if (!freeTextsPath || !fields) {
    return null;
  }

  //Filter the FreeText-Configs
  const filteredHeaderFreeTextsConfig = Object.keys(fields)
    .filter((path) => includes(path, freeTextsPath))
    .map((path) => fields[path]);

  if (!Array.isArray(filteredHeaderFreeTextsConfig)) {
    console.warn('headerFreetexts-Config is not an array');
    return null;
  }

  //find the maxNumberOfRows
  const maxNumberOfRows = Math.max.apply(
    Math,
    filteredHeaderFreeTextsConfig
      .filter((config) => config?.formSort)
      .map((config) => {
        return config.formSort.row;
      })
  );

  return {
    filteredHeaderFreeTextsConfig: filteredHeaderFreeTextsConfig,
    maxNumberOfRows: maxNumberOfRows,
  };
};

export const HeaderFreeTextElements = ({freeTextsPath, fields}) => {
  const filteredConfig = getFreeTextsConfigWithMaxNumberOfRows(
    freeTextsPath,
    fields
  );

  if (!filteredConfig) {
    return null;
  }

  const result = [];

  for (let i = 1; i <= filteredConfig.maxNumberOfRows; i++) {
    const row = [];

    filteredConfig.filteredHeaderFreeTextsConfig
      .filter((config) => config?.formSort?.row === i)
      .sort((a, b) => a.formSort.column - b.formSort.column)
      .forEach((config, columnKey) => {
        row.push(
          <Input
            key={columnKey}
            path={config.path}
            name={config.path}
            size="mini"
          />
        );
      });
    result.push(
      <FormGroup key={i} widths="equal">
        {row}
      </FormGroup>
    );
  }
  return result;
};

/**
 * Provides a context for the header form validation state
 */
export class ContextWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    // idk why eslint is nagging, the state is passed down to the provider...
    this.state = {
      // eslint-disable-next-line
      headerValid: false,
      // eslint-disable-next-line
      update: this.update
    };
  }

  update = (headerValid) => {
    // eslint-disable-next-line
    this.setState({headerValid});
  };

  render() {
    return (
      <HeaderMetaContext.Provider value={this.state}>
        {this.props.children}
      </HeaderMetaContext.Provider>
    );
  }
}

ContextWrapper.propTypes = {
  children: PropTypes.any,
};

class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {values: props.values};
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const {values, onSubmit} = this.props;
    if (!isEqual(this.state.values, values)) {
      this.setState({values});
      this.promise = onSubmit(values);
      await this.promise;
      delete this.promise;
    }
  };

  render() {
    return <></>;
  }
}

AutoSave.propTypes = {
  values: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  debounce: PropTypes.number,
};

// getting contextType warnings if we refactor this to a stateless component...
// https://github.com/facebook/react/issues/14061
// eslint-disable-next-line react/prefer-stateless-function
class DocumentHeadEditForm extends React.PureComponent {
  render() {
    const {
      documentExchange,
      onClickCancel,
      onVatVariantChange,
      onSubmit,
      pageConfig,
      showOnlyForm,
    } = this.props;

    const initialValues = documentExchange.document;

    /**
     *
     * when there is no vatVariant, then add fallback to Original-vatrates for VatVariants,
     * non-hagebau companies
     * //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/862
     */
    if (!get(initialValues, 'header.invoiceHeader.internalVATVarianceCode')) {
      const customerSpecificTranslations =
        pageConfig?.customerSpecificTranslations || {};

      //check pageConfig if there is an alternative translation key for default vatVariant
      const vatVarianceTranslationKey = get(
        customerSpecificTranslations,
        ORIGINAL_VAT_RATES_VARIANCE_CODE,
        ORIGINAL_VAT_RATES_VARIANCE_CODE
      );
      set(
        initialValues,
        'header.invoiceHeader.internalVATVarianceCode',
        vatVarianceTranslationKey
      );
    }

    return (
      <div className="readableOpacity" data-spec="document-head-form">
        <Form
          formid={HEADER_FORM_ID}
          onSubmit={onSubmit}
          initialValues={initialValues}
          fields={pageConfig.formFields}>
          {(formContext) => (
            <React.Fragment>
              {/* if we only show the form then we need to inject an autosaver */}
              {showOnlyForm && (
                <FormSpy
                  onSubmit={onSubmit}
                  subscription={{values: true}}
                  component={AutoSave}
                  debounce={250}
                />
              )}
              <HeaderMetaContext.Consumer>
                {({update}) => {
                  if (typeof update !== 'function') {
                    return null;
                  }
                  return (
                    <FormSpy
                      onChange={({valid}) => update(valid)}
                      subscription={{valid: true}}
                    />
                  );
                }}
              </HeaderMetaContext.Consumer>
              <FormGroup widths="equal">
                <Input
                  path="header.referencedDocuments.purchaseOrderReferenceNumber.referenceNumber"
                  name="header.referencedDocuments.purchaseOrderReferenceNumber.referenceNumber"
                  size="mini"
                />
                <Input
                  path="header.referencedDocuments.purchaseOrderReferenceNumber.date.dateTime"
                  name="header.referencedDocuments.purchaseOrderReferenceNumber.date.dateTime"
                  stripTimezone
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.referencedDocuments.shipmentLoadingReferenceNumber.referenceNumber"
                  name="header.referencedDocuments.shipmentLoadingReferenceNumber.referenceNumber"
                  size="mini"
                />
                <Input
                  path="header.referencedDocuments.shipmentLoadingReferenceNumber.date.dateTime"
                  name="header.referencedDocuments.shipmentLoadingReferenceNumber.date.dateTime"
                  stripTimezone
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.beginningOfMessage.documentNumber"
                  name="header.beginningOfMessage.documentNumber"
                  size="mini"
                />
                <Input
                  path="header.referencedDocuments.masterBillOfLading.referenceNumber"
                  name="header.referencedDocuments.masterBillOfLading.referenceNumber"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.dates.documentDate.dateTime"
                  name="header.dates.documentDate.dateTime"
                  size="mini"
                  stripTimezone
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.ordersHeader.incotermsCode"
                  name="header.ordersHeader.incotermsCode"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.ordersHeader.incotermsLocation"
                  name="header.ordersHeader.incotermsLocation"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.businessEntities.supplier.duns"
                  name="header.businessEntities.supplier.duns"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.businessEntities.supplier.partyIdentification"
                  name="header.businessEntities.supplier.partyIdentification"
                  size="mini"
                />
                <Input
                  path="header.businessEntities.buyer.partyIdentification"
                  name="header.businessEntities.buyer.partyIdentification"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.dates.deliveryDate.dateTime"
                  name="header.dates.deliveryDate.dateTime"
                  size="mini"
                  stripTimezone
                />
                <Input
                  path="header.dates.estimatedArrivalDate.dateTime"
                  name="header.dates.estimatedArrivalDate.dateTime"
                  stripTimezone
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.businessEntities.supplier.contact.name"
                  name="header.businessEntities.supplier.contact.name"
                  size="mini"
                />
                <Input
                  path="header.businessEntities.supplier.contact.telephone"
                  name="header.businessEntities.supplier.contact.telephone"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.businessEntities.supplier.contact.fax"
                  name="header.businessEntities.supplier.contact.fax"
                  size="mini"
                />
                <Input
                  path="header.businessEntities.supplier.contact.email"
                  name="header.businessEntities.supplier.contact.email"
                  size="mini"
                />
              </FormGroup>

              <FormGroup widths="equal">
                <Input
                  path="header.referencedDocuments.contractReferenceNumber.referenceNumber"
                  name="header.referencedDocuments.contractReferenceNumber.referenceNumber"
                  size="mini"
                />
                <Input
                  path="header.businessEntities.supplier.vatid"
                  name="header.businessEntities.supplier.vatid"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.businessEntities.buyer.vatid"
                  name="header.businessEntities.buyer.vatid"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.invoiceHeader.internalVATVarianceCode"
                  name="header.invoiceHeader.internalVATVarianceCode"
                  size="mini"
                  clearable
                  onInputChange={onVatVariantChange}
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.referenceCurrency"
                  name="header.referenceCurrency"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.paymentReference"
                  name="header.paymentReference"
                  size="mini"
                />
              </FormGroup>

              <HeaderFreeTextElements
                freeTextsPath="header.freeTexts"
                fields={pageConfig.formFields}
              />

              <BusinessEntityBankAccount prefix="header.businessEntities.supplier" />
              <FormGroup widths="equal">
                <Input
                  action={
                    <Input
                      style={{display: 'flex'}}
                      renderLabel={false}
                      path="header.paymentTerms[0].typeOfPeriod"
                      name="header.paymentTerms[0].typeOfPeriod"
                      size="mini"
                    />
                  }
                  path="header.paymentTerms[0].numberOfPeriods"
                  name="header.paymentTerms[0].numberOfPeriods"
                  size="mini"
                />
              </FormGroup>

              <FormGroup widths="equal">
                <Input
                  path="header.paymentTerms[0].paymentTermPercentage"
                  name="header.paymentTerms[0].paymentTermPercentage"
                  size="mini"
                />
              </FormGroup>

              <FormGroup widths="equal">
                <HeaderCountryOfOriginComponent
                  documentExchange={documentExchange}
                  path="header.countryOfOrigin.countryName"
                  name="header.countryOfOrigin.countryName"
                  formContext={formContext}
                  onVatVariantChange={onVatVariantChange}
                />
              </FormGroup>

              <FormGroup widths="equal">
                <Input
                  path="header.deliveryHeader.meansOfTransportIdentification"
                  name="header.deliveryHeader.meansOfTransportIdentification"
                  size="mini"
                />
              </FormGroup>

              <FormGroup widths="equal">
                <Input
                  path="header.referencedDocuments.externalOrderReference.referenceNumber"
                  name="header.referencedDocuments.externalOrderReference.referenceNumber"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.headerComment"
                  name="header.headerComment"
                  size="mini"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <Input
                  path="header.headerComment2"
                  name="header.headerComment2"
                  size="mini"
                />
              </FormGroup>

              {showOnlyForm ? null : (
                <div style={{textAlign: 'right'}}>
                  <Submit color="green" compact data-spec="head-submit">
                    <FormattedMessage id="GENERAL_SAVE" />
                  </Submit>
                  <Button
                    color="red"
                    compact
                    onClick={onClickCancel}
                    data-spec="head-cancel">
                    <FormattedMessage id="GENERAL_CANCEL" />
                  </Button>
                </div>
              )}
            </React.Fragment>
          )}
        </Form>
      </div>
    );
  }
}

DocumentHeadEditForm.contextType = HeaderMetaContext;

DocumentHeadEditForm.defaultProps = {
  showOnlyForm: false,
  onVatVariantChange: () => {},
};

DocumentHeadEditForm.propTypes = {
  documentExchange: industryDocumentShape,
  pageConfig: pageConfigShape,
  onSubmit: PropTypes.func,
  onVatVariantChange: PropTypes.func,
  onClickCancel: PropTypes.func,
  showOnlyForm: PropTypes.bool,
};

export default DocumentHeadEditForm;
