import {get} from 'lodash';

const buildVatOptions = (
  vatrates,
  value,
  intl,
  customerSpecificTranslations = {}
) => {
  const found = vatrates.filter((item) => {
    return item === value;
  });

  // why have we done this in the past?
  // this causes vat-rates dropdowns in the AOC editor to have an addition 0% vat-rate,
  // event if we don't want that.
  //
  // We need to check if this has sideeffects for other customers
  if (typeof value !== 'undefined' && !found.length) {
    // vatrates.push(value);
  }

  const invoiceVatrateTranslationKey = get(
    customerSpecificTranslations,
    'INVOIC_LIN_VAT_RATE'
  );

  if (invoiceVatrateTranslationKey) {
    return vatrates.map((item, idx) => {
      const vatrateTransform = `${item}`.replace('.', '_');
      const customInvoiceVatrateTranslationKey = `${invoiceVatrateTranslationKey}_${vatrateTransform}`;

      const tranlsationText = intl.formatMessage({
        id: customInvoiceVatrateTranslationKey,
      });

      return {
        key: `VATSELECT_${item}_${idx}`,
        text: tranlsationText,
        value: item,
      };
    });
  }

  return vatrates.map((item, idx) => ({
    key: `VATSELECT_${item}_${idx}`,
    text: `${item} %`,
    value: item,
  }));
};

export default buildVatOptions;
