import {preProcessOrder} from '../preprocessors/ordersPreprocessor';
import {toBool} from '../helpers/serialnumbers';
import {
  INDUSTRY_ORDER,
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER_RESPONSE,
  INDUSTRY_DESADV,
  INDUSTRY_INVOICE,
} from '../shapes/supportedDocTypes';
import {preprocessInvoice} from '../preprocessors/preProcessInvoice';
import webEdiEvent, {WEBEDI_DOCUMENT_PROCESSING_FAILED} from '../modules/Event';
import {postDocument, getTurnaroundDocument} from './fetcher';
import {
  shouldRecalculateLineItems,
  updateResponseDocument,
} from './documentExchange';

export const TYPES = {
  SENDING: 'TURNAROUND/send',
  REQUEST: 'TURNAROUND/request',
  SUCCESS_GET: 'TURNAROUND/success/get',
  ERROR_GET: 'TURNAROUND/error/get',
  SUCCESS_POST: 'TURNAROUND/success/post',
  ERROR_POST: 'TURNAROUND/error/post',
};

const initState = {
  data: {},
  fetching: true,
  sending: false,
  error: null,
};

const fetchTurnaroundSending = () => ({
  type: TYPES.SENDING,
});

const fetchTurnaroundRequest = () => ({
  type: TYPES.REQUEST,
});

const fetchTurnaroundSuccess = (data) => {
  return {
    type: TYPES.SUCCESS_GET,
    data,
  };
};

export const fetchTurnaroundError = (error, scenarioUuid, envelopeUuid) => {
  const isBackEndError = error?.response?.status;
  //trigger events only for frontend errors here, backend error events are already triggered in backend controller
  if (!isBackEndError) {
    const errorEvent = {
      eventName: WEBEDI_DOCUMENT_PROCESSING_FAILED,
      scenarioUuid: scenarioUuid,
      envelopeUuid: envelopeUuid,
      errorMessage: error?.message,
    };
    webEdiEvent.triggerEvent(errorEvent);
  }
  return {
    type: TYPES.ERROR_GET,
    error: error.message,
  };
};

const postTurnaroundSuccess = (data) => {
  return {
    type: TYPES.SUCCESS_POST,
    data: data,
  };
};

const postTurnaroundError = (error) => ({
  type: TYPES.ERROR_POST,
  error: error.message,
});

export const postDocumentToEnvelope = (
  envelopeUuid,
  content,
  actionType,
  scenarioUuid,
  scenarioChecksum
) => (dispatch) => {
  dispatch(fetchTurnaroundSending());

  return new Promise((resolve, reject) => {
    postDocument(
      envelopeUuid,
      content,
      actionType,
      scenarioUuid,
      scenarioChecksum
    )
      .then((res) => {
        dispatch(postTurnaroundSuccess(res.data));
        resolve(res.data);
      })
      // errors will be logged by the redux middleware in @ecosio/components
      .catch((error) => {
        dispatch(postTurnaroundError(error));
        reject(error);
      });
  });
};

export const fetchTurnaroundDocument = (
  scenario,
  envelopeUuid,
  documentMetaUuid,
  outboundDocType,
  params
) => (dispatch) => {
  dispatch(fetchTurnaroundRequest());

  return getTurnaroundDocument(
    envelopeUuid,
    documentMetaUuid,
    outboundDocType,
    params
  )
    .then((res) => {
      const docType = res?.data?.meta?.documentTypeId;
      let responseDoc = res?.data?.document;

      switch (docType) {
        case INDUSTRY_INVOICE:
          responseDoc = preprocessInvoice(responseDoc);
          // TODO remove me before merge mappings3 must do this !
          //          responseDoc.header.freeTexts.push({textFunction: 'AAK'});
          //          responseDoc.header.freeTexts.push({textFunction: 'SUR'});
          //          responseDoc.header.freeTexts.push({textFunction: 'ZZZ'});

          break;
        case INDUSTRY_DESADV:
          responseDoc = toBool(responseDoc);
          break;
        case INDUSTRY_ORDER:
        case INDUSTRY_ORDER_RESPONSE:
        case INDUSTRY_ORDCHG:
          const shouldCalc = shouldRecalculateLineItems(
            scenario,
            outboundDocType
          );
          responseDoc = shouldCalc ? preProcessOrder(responseDoc) : responseDoc;

          res = updateResponseDocument(res, responseDoc);
          break;
      }
      return dispatch(fetchTurnaroundSuccess(res.data));
    })
    .catch((error) => {
      const errorMessage = error?.message;

      if (errorMessage) {
        console.error(
          `Fetching turnAround document failed: ${error?.message}`,
          error
        );
      } else if (error) {
        console.error(
          `Fetching turnAround document failed for scenario : ${scenario?.uuid}`,
          error
        );
      } else {
        console.error(
          `Fetching turnAround document failed and error object is undefined for scenario :
            ${scenario?.uuid}
          `
        );
      }

      return dispatch(
        fetchTurnaroundError(error, scenario?.uuid, envelopeUuid)
      );
    });
};

export function reducer(state = initState, action) {
  switch (action.type) {
    case TYPES.REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case TYPES.SUCCESS_GET:
      return {
        ...state,
        data: action.data,
        fetching: false,
        error: null,
      };

    case TYPES.ERROR_GET:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };

    case TYPES.SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case TYPES.SUCCESS_POST:
      return {
        ...state,
        sending: false,
        error: null,
      };

    case TYPES.ERROR_POST:
      return {
        ...state,
        sending: false,
        error: action.error,
      };

    default:
      return state;
  }
}
