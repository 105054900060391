import React from 'react';
import PropTypes from 'prop-types';
import {Message, Container, Header, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {intlShape} from '@ecosio/components';
import {FormattedMessage, injectIntl} from 'react-intl';

// TODO: use the one from @ecosio/components
const ConfigurationErrorTemplate = ({message, title}) => {
  const defaultMessage =
    'Our technical team has been notified and is already working on a solution.';

  return (
    <Container>
      <Message error>
        <Header as="h1">{title || 'Woops, something went wrong!'}</Header>
        <br />
        <Header as="h3">{message || defaultMessage}</Header>
      </Message>
    </Container>
  );
};

ConfigurationErrorTemplate.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
};

class GracefulConfigurationError extends React.PureComponent {
  render() {
    const titleTranslation = this.props.error?.message
      ? this.props.intl.formatMessage({
          id: this.props.error?.message,
        })
      : undefined;
    return (
      <div>
        <ConfigurationErrorTemplate title={titleTranslation} />
        {!this.props.hideBackButton ? (
          <Container style={{marginTop: 20}}>
            <Button as={Link} to="/" primary>
              <FormattedMessage id="GENERAL_BACK" />
            </Button>
          </Container>
        ) : null}
      </div>
    );
  }
}

GracefulConfigurationError.propTypes = {
  error: PropTypes.any,
  hideBackButton: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(GracefulConfigurationError);
