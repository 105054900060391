import {get} from 'lodash';

/**
 * Retrieve the joined version of a list of partyNames on an entity
 */
export const getPartyName = (entity) => {
  if (
    !entity ||
    !entity.partyNames ||
    !Array.isArray(entity.partyNames) ||
    !entity.partyNames.length
  ) {
    return '';
  }

  return entity.partyNames.join(', ');
};

export const filterPathFormArray = (value, config, valuePath) => {
  const path = config.filter?.path;

  return value.reduce((filtered, item) => {
    const matchValue = get(item, path);
    const isMatch =
      typeof matchValue !== 'undefined' && matchValue === config.filter?.value;

    if (isMatch) {
      filtered.push(get(item, valuePath));
    }
    return filtered;
  }, []);
};
