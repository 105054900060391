/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Header, Message, Segment} from 'semantic-ui-react';
import {
  FormattedMessage as Msg,
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import './Sidebar.css';

const SidebarItemContent = ({label, value, nobreak}) => {
  return value ? (
    <div style={{marginTop: '10px'}}>
      <strong>
        <FormattedMessage id={label} />:
      </strong>
      {!nobreak ? <br /> : ' '}
      {value}
    </div>
  ) : null;
};

SidebarItemContent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  nobreak: PropTypes.bool,
};

const SidebarItem = injectIntl(({item, intl}) => {
  let formattedUnit = item.measureUnitQualifier;

  // only try to translate it if it's a string, intl will fail otherwise
  if (typeof formattedUnit === 'string' || formattedUnit instanceof String) {
    formattedUnit = intl.formatMessage({
      id: item.measureUnitQualifier,
    });
  }

  // fallback if it's not set, render nothing
  if (typeof formattedUnit === 'undefined') {
    formattedUnit = '';
  }

  return (
    <Segment data-spec="sidebar-item">
      <Header className="item-header">
        <Msg id="DESADV_MAT_NR_HDR" /> {item.customersItemMaterialNumber} /{' '}
        {item.suppliersItemMaterialNumber}
        <br />
        <span className="header-sub">{item.itemDescription}</span>
      </Header>
      <SidebarItemContent
        label="DESADV_SB_ORD_QTY"
        value={`${item.orderedQuantity} ${formattedUnit}`}
        nobreak
      />
    </Segment>
  );
});

const Sidebar = ({data, envelope}) => (
  <Segment.Group data-spec="sidebar">
    <Message attached="top" warning>
      <Message.Header>
        <FormattedMessage id="DESADV_BASKET_HEADER" />
      </Message.Header>
      <Message.Content>
        <FormattedMessage
          id="DESADV_BASKET_SUB_HEADER"
          values={{
            correlatorId: envelope?.data?.correlatorId,
          }}
        />
      </Message.Content>
    </Message>
    {data.ordersData.ordersLineItems.map((item, idx) => {
      return (
        <SidebarItem
          item={item}
          key={`SIDEBAR_${item.customersItemMaterialNumber}_${idx}`}
        />
      );
    })}
  </Segment.Group>
);

Sidebar.propTypes = {
  data: PropTypes.object,
  envelope: PropTypes.object,
};

export default Sidebar;
