import React from 'react';
import {Input} from '@ecosio/pathform';
import {Divider, Grid, Header} from 'semantic-ui-react';
import {get} from 'lodash';
import {useIntl} from 'react-intl';
import {DRAFTS_DELAY_ENABLED, DRAFTS_DELAY_TIME} from '../../constants';
import {FormFieldsWrapper} from './ReusableComponents/FormFieldsWrapper';

const WebediSettingsDraftAndDelayFields = ({formContextValues = {}}) => {
  const intl = useIntl();

  const draftsAndDelayEnabled =
    `${get(formContextValues, DRAFTS_DELAY_ENABLED, undefined)}` === 'true'
      ? true
      : false;

  return (
    <FormFieldsWrapper>
      <Header as="h5">
        {intl.formatMessage({
          id: 'WEBEDI_SETTINGS_DRAFT_SENDING_OPTIONS_HEADER',
        })}
      </Header>
      <p>
        {intl.formatMessage({
          id: 'WEBEDI_SETTINGS_DRAFT_SENDING_OPTIONS_DESCRIPTION',
        })}
      </p>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Input
              data-spec="webedi-drafts-delay-enabled"
              path={DRAFTS_DELAY_ENABLED}
              name={DRAFTS_DELAY_ENABLED}
              renderLabel={false}
              style={{
                'letter-spacing': '1px',
                display: 'block',
                margin: '0 0 0.28571429rem 0',
                fontSize: '10px',
                'font-weight': '700',
                'text-transform': 'uppercase',
              }}
              toggle
              value={draftsAndDelayEnabled}
            />
          </Grid.Column>
        </Grid.Row>
        {draftsAndDelayEnabled && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Input
                data-spec="webedi-drafts-set-delay-time"
                path={DRAFTS_DELAY_TIME}
                name={DRAFTS_DELAY_TIME}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <Divider />
            <Header as="h5">
              {intl.formatMessage({
                id: 'WEBEDI_SETTINGS_DRAFT_SCHEDULED_HEADER',
              })}
            </Header>
            <p>
              {intl.formatMessage({
                id: 'WEBEDI_SETTINGS_DRAFT_SCHEDULED_DESCRIPTION',
              })}
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormFieldsWrapper>
  );
};

export default WebediSettingsDraftAndDelayFields;
