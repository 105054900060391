import {get, set} from 'lodash';

const validator = (values, nveIndex, intl) => {
  if (!values) {
    console.warn('No values provided in desadv validator');
    return {};
  }

  if (!nveIndex) {
    console.warn('No nveIndex provided in desadv validator');
    return {};
  }

  const errors = {};
  const total = parseInt(
    get(values, 'deliveryLineItems[0].dispatchQuantity.value')
  );

  const packagingInformations = get(values, 'packagingInformations', []);

  const sum = packagingInformations.reduce((sum, item) => {
    sum +=
      (parseInt(item.numberOfPackages) || 0) *
      (parseInt(item.quantityPerPack) || 0);

    return sum;
  }, 0);

  const currentNVES = packagingInformations.reduce((acc, item) => {
    acc = [...acc, ...item.identifications];
    return acc;
  }, []);

  packagingInformations.map((item, row) => {
    const identifications = get(item, 'identifications', []);

    identifications.map((num, index) => {
      // Check if the NVEs are already used in the Document outside
      if (nveIndex.includes(num.identityNumber)) {
        set(
          errors,
          `packagingInformations[${row}].identifications[${index}].identityNumber`,
          intl.formatMessage({
            id: 'NVE_DUPLICATE_ID_ERROR',
          })
        );
      }
      // Check if the NVEs are already used in this LineItem
      if (
        currentNVES.filter((item) => item.identityNumber === num.identityNumber)
          .length > 1
      ) {
        set(
          errors,
          `packagingInformations[${row}].identifications[${index}].identityNumber`,
          intl.formatMessage({
            id: 'NVE_DUPLICATE_ID_ERROR',
          })
        );
      }
    });
  });

  if (sum !== 0 && sum !== total) {
    const errorMessage = intl.formatMessage(
      {
        id: 'VALIDATION_ERROR_NVE_ISNOT_DISPATCH',
      },
      {
        dispatch: total,
        nve: sum,
      }
    );
    set(errors, 'deliveryLineItems[0].dispatchQuantity.value', errorMessage);
  }

  return errors;
};

export default validator;
