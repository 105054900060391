import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Icon} from 'semantic-ui-react';
import {ecosioColors} from '@ecosio/customer-layout';
import {connect} from 'react-redux';
import {pageConfigShape} from '../../../../../../shapes/scenarios';
import {ECOSIO_THEME} from '../../../../../../helpers/constants';
import {loadTheme} from '../../../../../../helpers/utils';

const DeleteAndDuplicateContainer = styled.div`
  background-color: ${ecosioColors.greyShades[6]};
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props?.backgroundColor ? `background:${props.backgroundColor}` : ''}
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 8px !important;
  opacity: 0.5 !important;
  &:hover {
    color: ${(props) => props?.primaryColor || `blue`};
  }
`;

// https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/1011
function deriveBatchNumberFromDocNumberAndPosition(docNumber, position) {
  // Remove up to 2 leading 0 from document number
  const leadingZeroesInDocNumber = countLeadingZeros(docNumber);
  let finalDocNumber = docNumber;
  if (leadingZeroesInDocNumber > 0) {
    const removeLeadingCharsCount =
      leadingZeroesInDocNumber >= 2 ? 2 : leadingZeroesInDocNumber;
    finalDocNumber = docNumber.substring(removeLeadingCharsCount);
  }
  // if position is 1 digit - add 0 as its prefix
  let finalPosition = position.toString();
  if (position < 10 && position > 0) {
    finalPosition = `0${position}`;
  }
  return finalDocNumber + finalPosition;
}

function countLeadingZeros(str) {
  // Check if str is undefined or null, return 0 in such cases
  if (typeof str !== 'string') {
    return 0;
  }

  let count = 0;

  // Iterate through the string
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '0') {
      count++;
    } else {
      break; // Stop counting when a non-zero character is encountered
    }
  }

  return count;
}

const DeleteAndDuplicateComponent = ({
  pageConfig,
  formContext,
  index,
  theme,
  delisOpacity,
  setDelisOpacity,
}) => {
  const allowDeleteLineItem =
    pageConfig?.lineItemConfiguration?.allowDeleteLineItem;
  const allowDuplicateLineItem =
    pageConfig?.lineItemConfiguration?.allowDuplicateLineItem;

  const recalculatePostionNumberAndRemoveLineItem = useCallback(
    (formContext) => {
      const deliLineItems = formContext?.values?.deliveryLineItems || [];
      let delis = [...deliLineItems];

      delis = delis
        .filter((item, i) => i !== index)
        .map((item, i) => {
          let pos = item?.positionNumber;

          const detailsProperties = item?.detailsProperties || [];

          const noDeleteSet = detailsProperties.filter(
            (d) => d?.key === 'NO_DELETION' && d?.value === 'true'
          );

          if (noDeleteSet.length === 0) {
            pos = i + 1;
          }

          const batchNumber = deriveBatchNumberFromDocNumberAndPosition(
            item?.references?.agreementOrOrderNumberReference,
            pos
          );

          return {
            ...item,
            positionNumber: pos,
            batchNumber: batchNumber,
          };
        });

      formContext.form.mutators.setValue('deliveryLineItems', delis);
    },
    [index]
  );

  const duplicateLineItem = useCallback((formContext, index) => {
    const deliLineItems = formContext?.values?.deliveryLineItems || [];
    let delis = [...deliLineItems];

    const currDel = {
      ...delis[index],
    };

    let currDelDetailsProperties = currDel?.detailsProperties || [];
    //remove no deletion key for duplicated lins
    currDelDetailsProperties = currDelDetailsProperties.filter(
      (d) => d?.key !== 'NO_DELETION'
    );

    currDel.detailsProperties = currDelDetailsProperties;
    // add at last index
    delis.splice(delis.length, 0, currDel);
    delis = delis.map((item, i) => {
      const pos = i + 1;

      const batchNumber = deriveBatchNumberFromDocNumberAndPosition(
        item?.references?.agreementOrOrderNumberReference,
        pos
      );

      return {
        ...item,
        positionNumber: pos,
        batchNumber: batchNumber,
      };
    });

    formContext.form.mutators.setValue('deliveryLineItems', delis);
  }, []);

  const deleteLineItem = useCallback(
    (formContext, index) => {
      const deliLineItems = formContext?.values?.deliveryLineItems || [];
      const delis = [...deliLineItems];

      const currDeli = delis[index] || {};
      const detailsProperties = currDeli?.detailsProperties || [];

      const noDeleteSet = detailsProperties.filter(
        (d) => d?.key === 'NO_DELETION' && d?.value === 'true'
      );

      if (noDeleteSet?.length === 1) {
        const delisOpacities = [...delisOpacity];
        delisOpacities[index] = 0.65;
        setDelisOpacity(delisOpacities);
        formContext.form.mutators.setValue(
          `deliveryLineItems[${index}].dispatchQuantity.value`,
          0
        );
      } else {
        recalculatePostionNumberAndRemoveLineItem(formContext, index);
      }
    },
    [delisOpacity, setDelisOpacity, recalculatePostionNumberAndRemoveLineItem]
  );

  if (!allowDeleteLineItem && !allowDuplicateLineItem) {
    return null;
  }

  const delis = formContext?.values?.deliveryLineItems || [];

  const currentTheme = loadTheme(theme);

  return (
    <DeleteAndDuplicateContainer>
      {allowDuplicateLineItem && (
        <StyledIcon
          name="copy"
          primaryColor={currentTheme?.primaryColor}
          onClick={() => duplicateLineItem(formContext, index)}
        />
      )}
      {allowDeleteLineItem && delis.length > 1 && (
        <StyledIcon
          name="trash"
          primaryColor={currentTheme?.primaryColor}
          onClick={() => deleteLineItem(formContext, index)}
        />
      )}
    </DeleteAndDuplicateContainer>
  );
};

const mapStateToProps = ({config}) => ({
  theme: config?.userConfig?.theme,
});

DeleteAndDuplicateComponent.defaultProps = {
  pageConfig: {},
  theme: ECOSIO_THEME,
};

DeleteAndDuplicateComponent.propTypes = {
  pageConfig: pageConfigShape,
  index: PropTypes.number.isRequired,
  theme: PropTypes.string,
};

export default connect(mapStateToProps)(DeleteAndDuplicateComponent);
