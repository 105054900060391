import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, List} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {RenderContents, Content} from '@ecosio/pathform';
import {intlShape} from '@ecosio/components';
import {TabbedDate, TabbedItem} from '../../TabbedItem';
import {industryDocumentShape} from '../../../../../shapes/industryDocument';
import {pageConfigShape} from '../../../../../shapes/scenarios';
import './DocumentHeadeView.css';
import ValidationWarnIcon from '../../../ValidationWarnIcon';
import {inMemoryValidatorShape} from '../../../../../shapes/inMemoryValidatorShape';
import {getFreeTextsConfigWithMaxNumberOfRows} from './DocumentHeadEditForm';
import HeaderFreeTextViewElements from './HeaderFreeTextViewElements';

const HeaderFreeTextElements = ({
  freeTextsPath,
  fields,
  inMemoryValidation,
}) => {
  const filteredConfig = getFreeTextsConfigWithMaxNumberOfRows(
    freeTextsPath,
    fields
  );

  if (!filteredConfig) {
    return null;
  }
  const result = [];

  for (let i = 1; i <= filteredConfig.maxNumberOfRows; i++) {
    filteredConfig.filteredHeaderFreeTextsConfig
      .filter((config) => config.formSort?.row === i)
      .sort((a, b) => a.formSort.column - b.formSort.column)
      .forEach((config) => {
        result.push(
          <Content
            inMemoryValidation={inMemoryValidation}
            config={fields}
            path={config.path}
            render={ItemComponent}
          />
        );
      });
  }
  return result;
};

const renderValidationIconOnError = (path, inMemoryValidation) => {
  if (
    inMemoryValidation?.hasErrors &&
    Array.isArray(inMemoryValidation?.errorData)
  ) {
    const filteredError = inMemoryValidation.errorData.filter(
      (error) => error[path]
    );

    if (filteredError[0]) {
      return <ValidationWarnIcon tooltipData={filteredError[0][path]} />;
    }
  }
};

const ItemComponent = ({
  content,
  input,
  config,
  intl,
  path,
  inMemoryValidation,
}) => {
  if (config) {
    const config2 = config[path];
    if (config2.type === 'dropdown') {
      const dropdownValue = config2.options.find(
        (dropdown) => dropdown.value === content
      );
      return (
        <List.Item divided="true" data-spec={`l-${path}`}>
          <List.Content floated="left">
            <strong>
              <FormattedMessage id={input?.label} />:
            </strong>
          </List.Content>
          <List.Content floated="right">
            {dropdownValue?.text
              ? intl.formatMessage({id: dropdownValue.text})
              : ''}
          </List.Content>
        </List.Item>
      );
    }
  }

  return content ? (
    <List.Item divided="true" data-spec={`l-${path}`}>
      <List.Content floated="left">
        <strong>
          <FormattedMessage id={input?.label} />:
        </strong>
      </List.Content>
      <List.Content floated="right">
        {intl.formatMessage({id: content})}
        {renderValidationIconOnError(path, inMemoryValidation)}
      </List.Content>
    </List.Item>
  ) : null;
};

ItemComponent.propTypes = {
  content: PropTypes.string,
  // TODO: export this shape from the pathform lib
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  intl: intlShape,
  path: PropTypes.string,
  inMemoryValidation: inMemoryValidatorShape,
  config: PropTypes.object,
};

const BusinessEntityBankAccountView = ({prefix, inMemoryValidation}) => {
  return (
    <React.Fragment>
      <TabbedItem
        inMemoryValidation={inMemoryValidation}
        path={`${prefix}.bankAccounts[0].iban`}
      />

      <TabbedItem
        inMemoryValidation={inMemoryValidation}
        path={`${prefix}.bankAccounts[0].bankAccountNr`}
      />
      <TabbedItem
        inMemoryValidation={inMemoryValidation}
        path={`${prefix}.bankAccounts[0].bic`}
      />
      <TabbedItem
        inMemoryValidation={inMemoryValidation}
        path={`${prefix}.bankAccounts[0].bankAccountOwner`}
      />
    </React.Fragment>
  );
};

BusinessEntityBankAccountView.propTypes = {
  prefix: PropTypes.string.isRequired,
  inMemoryValidation: inMemoryValidatorShape,
};

const DocumentHeadView = ({
  documentExchange,
  pageConfig,
  onEditClick,
  editable,
  inMemoryValidation,
}) => {
  return (
    <div>
      {editable ? (
        <div style={{textAlign: 'right'}}>
          <Button
            size="mini"
            compact
            primary
            icon
            onClick={onEditClick}
            data-spec="edit-doc-head">
            <Icon name="edit" /> <FormattedMessage id="EDIT_DOCUMENTHEAD" />
          </Button>
        </div>
      ) : null}

      <RenderContents
        initialValues={documentExchange.document}
        fields={pageConfig.formFields}>
        {() => (
          <List>
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessCaseType"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.referencedDocuments.purchaseOrderReferenceNumber.referenceNumber"
            />
            <TabbedDate
              inMemoryValidation={inMemoryValidation}
              stripTimezone
              path="header.referencedDocuments.purchaseOrderReferenceNumber.date.dateTime"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.referencedDocuments.shipmentLoadingReferenceNumber.referenceNumber"
            />
            <TabbedDate
              inMemoryValidation={inMemoryValidation}
              stripTimezone
              path="header.referencedDocuments.shipmentLoadingReferenceNumber.date.dateTime"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.beginningOfMessage.documentNumber"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.referencedDocuments.masterBillOfLading.referenceNumber"
            />
            <TabbedDate
              inMemoryValidation={inMemoryValidation}
              stripTimezone
              path="header.dates.documentDate.dateTime"
            />
            <TabbedDate
              inMemoryValidation={inMemoryValidation}
              stripTimezone
              path="header.dates.requestedOrderConfirmationDate.dateTime"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.ordersHeader.incotermsCode"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.ordersHeader.incotermsLocation"
            />
            {/*<br />*/}
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.headerComment"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.headerComment2"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.supplier.partyIdentification"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.supplier.duns"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.supplier.contact.name"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.supplier.contact.telephone"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.supplier.contact.fax"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.supplier.contact.email"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.referencedDocuments.contractReferenceNumber.referenceNumber"
            />

            {/*<br />*/}
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.buyer.partyIdentification"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.buyer.duns"
            />
            {/*<br />*/}
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.consignee.partyIdentification"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.consignee.duns"
            />
            <TabbedDate
              inMemoryValidation={inMemoryValidation}
              stripTimezone
              path="header.dates.deliveryDate.dateTime"
            />
            <TabbedDate
              inMemoryValidation={inMemoryValidation}
              stripTimezone
              path="header.dates.pickUpDate.dateTime"
            />
            <TabbedDate
              inMemoryValidation={inMemoryValidation}
              stripTimezone
              path="header.dates.estimatedArrivalDate.dateTime"
            />

            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.supplier.vatid"
            />

            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.buyer.vatid"
            />

            <HeaderFreeTextElements
              inMemoryValidation={inMemoryValidation}
              freeTextsPath="header.freeTexts"
              fields={pageConfig.formFields}
            />
            <Content
              inMemoryValidation={inMemoryValidation}
              path="header.invoiceHeader.internalVATVarianceCode"
              render={ItemComponent}
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.consignee.unloadingPoint"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.businessEntities.consignee.storageLocation"
            />
            <BusinessEntityBankAccountView prefix="header.businessEntities.supplier" />

            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.referenceCurrency"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.paymentReference"
            />

            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.paymentTerms[0].typeOfPeriod"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.paymentTerms[0].numberOfPeriods"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.paymentTerms[0].paymentTermPercentage"
            />
            <TabbedItem
              inMemoryValidation={inMemoryValidation}
              path="header.countryOfOrigin.countryName"
            />

            <TabbedItem
              path="header.deliveryHeader.meansOfTransportIdentification"
              name="header.deliveryHeader.meansOfTransportIdentification"
            />
            <TabbedItem
              path="header.referencedDocuments.externalOrderReference.referenceNumber"
              name="header.referencedDocuments.externalOrderReference.referenceNumber"
            />

            {/**
             * !!! It should be at the end of the list,
             * it maps all freeTexts fields
             */}
            <HeaderFreeTextViewElements
              inMemoryValidation={inMemoryValidation}
              fields={pageConfig?.formFields}
              freeTextsPath="header.freeTexts"
              values={documentExchange?.document}
            />
          </List>
        )}
      </RenderContents>
    </div>
  );
};

DocumentHeadView.propTypes = {
  documentExchange: industryDocumentShape,
  pageConfig: pageConfigShape,
  onEditClick: PropTypes.func,
  editable: PropTypes.bool,
  inMemoryValidation: inMemoryValidatorShape,
};

export default DocumentHeadView;
