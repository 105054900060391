/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Table} from 'semantic-ui-react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {industryDocumentFooterShape} from '../../../../../shapes/industryDocument';
import {round} from '../../../../../calculations/math';

const styleAoc = {color: '#99a4af'};

const alcDocTable = (currency) => {
  return (alc, idx) => (
    <div key={`headerALC_${idx}`}>
      <FormattedNumber
        style="currency"
        currency={currency}
        value={round(alc.chargeBaseQuantity)}
      />
      <br />
      <div style={styleAoc}>
        {alc.allowanceChargeQualifier === 'C' ? '+' : '-'}{' '}
        <FormattedNumber
          style="currency"
          currency={currency}
          value={round(alc.allowanceChargeAmount)}
        />{' '}
      </div>
    </div>
  );
};

const TableFooterWithOutVatRates = ({footer, header, colSpan, currency}) => {
  const alc = get(header, 'invoiceHeader.allowancesAndCharges', []);

  return (
    <Table.Footer fullWidth>
      {alc.length ? (
        <Table.Row>
          <Table.Cell colSpan={colSpan} textAlign="right" />
          <Table.Cell textAlign="left">
            <FormattedMessage id="GENERAL_ALLOWANCES_AND_CHARGES" />{' '}
          </Table.Cell>
          <Table.Cell colSpan={1} textAlign="right">
            {get(header, 'invoiceHeader.allowancesAndCharges', []).map(
              alcDocTable(currency)
            )}
          </Table.Cell>
        </Table.Row>
      ) : null}
      <Table.Row>
        <Table.HeaderCell colSpan={colSpan} />
        <Table.HeaderCell colSpan={1}>
          <strong>
            <FormattedMessage id="GENERAL_TOTAL_SUM" />
          </strong>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <strong>
            <FormattedNumber
              value={round(
                get(footer, 'invoiceFooter.invoiceTotals.totalLineItemAmount')
              )}
              style="currency"
            />
          </strong>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

TableFooterWithOutVatRates.propTypes = {
  footer: industryDocumentFooterShape,
  colSpan: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  header: PropTypes.object,
};

const TableFooterWithVatRates = ({footer, header, colSpan, currency}) => {
  const alc = get(header, 'invoiceHeader.allowancesAndCharges', []);
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell colSpan={colSpan} />
        <Table.HeaderCell colSpan={1}>
          <FormattedMessage id="GENERAL_SUBTOTAL" />
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <FormattedNumber
            value={round(
              get(footer, 'invoiceFooter.invoiceTotals.totalTaxableAmount')
            )}
            style="currency"
            currency={currency}
          />
        </Table.HeaderCell>
      </Table.Row>

      {alc.length ? (
        <Table.Row>
          <Table.Cell colSpan={colSpan} textAlign="right" />
          <Table.Cell textAlign="left">
            <FormattedMessage id="GENERAL_ALLOWANCES_AND_CHARGES" />{' '}
          </Table.Cell>
          <Table.Cell colSpan={1} textAlign="right">
            {get(header, 'invoiceHeader.allowancesAndCharges', []).map(
              alcDocTable(currency)
            )}
          </Table.Cell>
        </Table.Row>
      ) : null}

      <Table.Row>
        <Table.Cell colSpan={colSpan} />
        <Table.Cell colSpan={1}>
          {get(footer, 'invoiceFooter.vatrates', []).map((vat, idx) => (
            <div key={`footerVatPercent_${idx}`}>
              <FormattedMessage id="GENERAL_PRICE_PLUS" />{' '}
              <FormattedNumber
                style="percent"
                minimumFractionDigits={2}
                value={vat.vatrate / 100}
              />{' '}
              <FormattedMessage id="GENERAL_VAT" />
            </div>
          ))}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {get(footer, 'invoiceFooter.vatrates', []).map((vat, idx) => (
            <div key={`footerVatAmount_${idx}`}>
              <FormattedNumber
                value={round(vat.vatamount)}
                style="currency"
                currency={currency}
              />
            </div>
          ))}
        </Table.Cell>
      </Table.Row>
      {get(footer, 'simpleTotalInvoiceAmountRoundingAmount') ? (
        <Table.Row>
          <Table.HeaderCell colSpan={colSpan} />
          <Table.HeaderCell colSpan={1}>
            <FormattedMessage id="INVOICE_ROUNDING_FIELD" />
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
            <FormattedNumber
              value={round(
                get(footer, 'simpleTotalInvoiceAmountRoundingAmount')
              )}
            />{' '}
            {currency ? <FormattedMessage id={dynID(currency)} /> : ''}
          </Table.HeaderCell>
        </Table.Row>
      ) : null}

      <Table.Row>
        <Table.HeaderCell colSpan={colSpan} />
        <Table.HeaderCell colSpan={1}>
          <strong>
            <FormattedMessage id="GENERAL_TOTAL_SUM" />
          </strong>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <strong>
            <FormattedNumber
              value={round(
                get(footer, 'invoiceFooter.invoiceTotals.invoiceAmount')
              )}
              style="currency"
              currency={currency}
            />
          </strong>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

TableFooterWithVatRates.propTypes = {
  footer: industryDocumentFooterShape,
  colSpan: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  header: PropTypes.object,
};

const TableFooter = ({footer, ...rest}) => {
  const vatRates = get(footer, 'invoiceFooter.vatrates', []);

  return vatRates.length ? (
    <TableFooterWithVatRates footer={footer} {...rest} />
  ) : (
    <TableFooterWithOutVatRates footer={footer} {...rest} />
  );
};

export default TableFooter;

TableFooter.propTypes = {
  footer: industryDocumentFooterShape,
  colSpan: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
