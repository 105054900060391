import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

const SendFailErrorMessage = ({show}) => {
  if (!show) {
    return null;
  }

  return (
    <Message
      data-spec="send-fail-error-message"
      error
      className="_sie_send_error">
      <Icon data-spec="send-fail-error-message-icon" name="warning sign" />
      <Message.Content>
        <Message.Header>
          <FormattedMessage id="GENERAL_MESSAGE_SEND_FAIL_HEAD" />
        </Message.Header>
        <FormattedMessage id="GENERAL_MESSAGE_SEND_FAIL_BODY" />
      </Message.Content>
    </Message>
  );
};

SendFailErrorMessage.propTypes = {
  show: PropTypes.bool,
};

SendFailErrorMessage.defaultProps = {
  show: false,
};

export default SendFailErrorMessage;
