export class DispatchedQuantityAggregate {
  constructor(positionNumber, measureUnitQualifier, dispatchedQuantity) {
    this.positionNumber = positionNumber;
    this.quantities = [];
    this.aggregateDispatchedQuantityForMeasureUnit(
      measureUnitQualifier,
      dispatchedQuantity
    );
  }

  aggregateDispatchedQuantityForMeasureUnit(
    measureUnitQualifier,
    dispatchedQuantity
  ) {
    const measureUnitAggregate = this.quantities?.find(
      (qua) => qua.measureUnitQualifier === measureUnitQualifier
    );
    // Check if quantities for the measureUnitQualifier are already aggregated
    if (measureUnitAggregate) {
      // If aggregate already exists for the measureUnit -> add up orderedQuantity that user entered
      measureUnitAggregate.dispatchedQuantity += dispatchedQuantity;
    } else {
      this.quantities.push(
        new DispatchedQuantityForMeasureUnit(
          measureUnitQualifier,
          dispatchedQuantity
        )
      );
    }
  }
}

export class DispatchedQuantityForMeasureUnit {
  constructor(measureUnitQualifier, dispatchedQuantity) {
    this.measureUnitQualifier = measureUnitQualifier;
    this.dispatchedQuantity = dispatchedQuantity;
  }
}

export const calculateOrdersDataRemainingQuantityAggregates = (cpsRoot) => {
  const remainingQuantityAggregates = [];

  for (let cpsCounter = 0; cpsCounter < cpsRoot?.length; cpsCounter++) {
    const cpsData = cpsRoot[cpsCounter];
    for (
      let liCounter = 0;
      liCounter < cpsData.lineitems?.length;
      liCounter++
    ) {
      const liData = cpsData.lineitems[liCounter].props;
      const dispatchedLIQuantity = parseFloat(liData.orderedQuantity);
      // Try finding aggregate for the selected position. If none, init empty aggregate
      const positionAggregate = remainingQuantityAggregates.find(
        (aggr) => aggr.positionNumber === liData.positionNumber
      );
      if (positionAggregate) {
        positionAggregate.aggregateDispatchedQuantityForMeasureUnit(
          liData.measureUnitQualifier,
          dispatchedLIQuantity
        );
      } else {
        remainingQuantityAggregates.push(
          new DispatchedQuantityAggregate(
            liData.positionNumber,
            liData.measureUnitQualifier,
            dispatchedLIQuantity
          )
        );
      }
    }
  }

  return remainingQuantityAggregates;
};

export const calculateRemainingQuantityForPosition = (
  ordersQuantity,
  measureUnitQualifier,
  remainingQuantityAggregate,
  returnNegativeValue = false
) => {
  let remainingQuantityForPosition = null;

  if (remainingQuantityAggregate) {
    // If any quantity is dispatched with the measureUnitQualifier that is not specified in the ordersData, set remainingQuantity to 0
    const nonOrderedMeasureUnit = remainingQuantityAggregate.quantities.find(
      (q) => q.measureUnitQualifier !== measureUnitQualifier
    );

    if (nonOrderedMeasureUnit) {
      remainingQuantityForPosition = 0;
    } else {
      const orderedMeasureUnit = remainingQuantityAggregate.quantities.find(
        (q) => q.measureUnitQualifier === measureUnitQualifier
      );
      if (orderedMeasureUnit) {
        remainingQuantityForPosition =
          ordersQuantity - orderedMeasureUnit.dispatchedQuantity;
        if (!returnNegativeValue && remainingQuantityForPosition < 0) {
          remainingQuantityForPosition = 0;
        }
      }
    }
  } else {
    remainingQuantityForPosition = ordersQuantity;
  }

  return remainingQuantityForPosition;
};
