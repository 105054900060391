import React from 'react';
import {Form} from '@ecosio/pathform';
import {Header} from 'semantic-ui-react';
import {FormSpy} from 'react-final-form';
import PropTypes from 'prop-types';
import {FormattedMessage as Msg} from 'react-intl';
import {
  AutoSave,
  DocumentMetaContext,
} from '../../../CommonComponents/Create/Header/OrderHeaderInformation/EditFormFields/EditForm';
import {
  ContentDivider,
  HeaderLineContainer,
} from '../../../CommonComponents/Create/Header/StyledDivs';
import {pageConfigShape} from '../../../../../../../shapes/scenarios';
import DesadvLineItems from './DesadvLineItems';

export const DESADV_LINEITEM_FORM_ID = 'DESADV_LINEITEM_FORM';

const DesadvLineItemsForm = (props) => {
  return (
    <div>
      <HeaderLineContainer>
        <div>
          <Header as="h4">
            <Msg id="GENERAL_POSITIONS" />
          </Header>
        </div>
        <ContentDivider />
      </HeaderLineContainer>
      <Form
        formid={DESADV_LINEITEM_FORM_ID}
        onSubmit={props.onSubmit}
        fields={props?.pageConfig?.formFields || {}}
        initialValues={{
          deliveryLineItems: props?.deliveryLineItems,
        }}>
        {() => {
          return (
            <>
              <FormSpy
                onSubmit={props.onSubmit}
                subscription={{values: true}}
                component={AutoSave}
                debounce={250}
              />
              <DocumentMetaContext.Consumer>
                {({updateLineItems}) => {
                  if (typeof updateLineItems !== 'function') {
                    return null;
                  }
                  return (
                    <FormSpy
                      onChange={({valid}) => updateLineItems(valid)}
                      subscription={{valid: true}}
                    />
                  );
                }}
              </DocumentMetaContext.Consumer>
              <FormSpy
                subscription={{values: true}}
                onChange={(state) => {
                  const {values} = state;
                  props?.exposeDeliveryLineItemValues(values.deliveryLineItems);
                }}
              />
              <DesadvLineItems
                partialDeliveryAllowed={props.partialDeliveryAllowed}
                initialValues={props.deliveryLineItems}
                showReadonly={props.showReadonly}
                pageConfig={props.pageConfig}
                exposedValues={props.exposedValues}
                sscc={props.sscc}
              />
            </>
          );
        }}
      </Form>
    </div>
  );
};

DesadvLineItemsForm.defaultProps = {
  exposeDeliveryLineItemValues: () => {},
};

DesadvLineItemsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  deliveryLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  partialDeliveryAllowed: PropTypes.bool,
  showReadonly: PropTypes.bool,
  sscc: PropTypes.bool,
  pageConfig: pageConfigShape,
  exposeDeliveryLineItemValues: PropTypes.func,
  exposedValues: PropTypes.object,
};

export default DesadvLineItemsForm;
