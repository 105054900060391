import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Button, Dimmer, Header, Loader, Modal} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {ecosioColors} from '@ecosio/customer-layout';
import styled from 'styled-components';
import {dynID} from '@ecosio/pathform';
import {scenarioShape} from '../../../shapes/scenarios';
import {getDocumentExchange} from '../../../reducers/fetcher';
import {INDUSTRY_DESADV} from '../../../shapes/supportedDocTypes';
import {documentMetaShape} from '../../../shapes/documentExchange';
import {createExchangePayload} from '../../../reducers/documentExchange';
import {createTurnaroundLink} from '../../../util/turnaroundUtil';
import {envelopesShape} from '../../../shapes/envelopes';
import {MERGED_UUID} from '../../../constants';
import TurnaroundInputDocumentSelector from './TurnaroundInputDocumentSelector';
import DesadvSelector from './DesadvSelector';

const StyledModalHeaderContainer = styled(Header)`
  display: flex !important;
  flex-wrap: wrap;
  background-color: ${ecosioColors.greyShades[7]} !important;
  align-items: center;
`;

const StyledModalHeaderElement = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  border: 1px solid ${ecosioColors.greyShades[3]};
  border-radius: 5px;
  padding: 6px;
  margin-left: 20px;
`;

const StyledModalCancelButton = styled(Button)`
  border: 1px solid ${ecosioColors.greyShades[3]} !important;
  color: ${ecosioColors.greyShades[0]} !important;
  background-color: ${ecosioColors.greyShades[6]} !important;
`;

class CreateTurnaroundModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocumentExchange: null,
      link: null,
      loading: false,
      error: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.state.loading || this.state.error === true) {
      return;
    }
    const {sourceDocuments} = this.props;
    // if we have exactly one source document, we can load it straight away
    if (Array.isArray(sourceDocuments) && sourceDocuments.length === 1) {
      const uuid = sourceDocuments[0].uuid;
      // only load if its changed
      if (this.state.selectedDocumentExchange?.meta?.uuid === uuid) {
        return;
      }

      this.loadDocument({value: uuid});
    }
  }

  loadDocument = ({value}) => {
    this.setState({loading: true, error: false});
    return getDocumentExchange(this.props.envelope.uuid, value)
      .then(({data}) => {
        const payload = createExchangePayload(data, this.props.scenario, true);

        const docTypesToMerge =
          this.props.scenario.documentMerger?.documentTypesToMerge || [];

        data.meta.uuid =
          docTypesToMerge.indexOf(data.meta.documentTypeId) > -1
            ? MERGED_UUID
            : data.meta.uuid;

        this.setState({
          selectedDocumentExchange: data,
          deliveryDates: payload.deliveryDates.data,
          loading: false,
          error: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({loading: false, error: true});
      });
  };

  onDocumentChange = ({value}) => {
    this.loadDocument({value})
      .then(() => {
        // desadv needs still some selections
        if (this.props.targetDocType !== INDUSTRY_DESADV) {
          this.updateLink();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateLink = (dateFilter, duns, storageLocation, unloadingPoint) => {
    const {scenario, envelope} = this.props;
    const envelopeUuid = envelope.uuid;
    const scenarioUuid = scenario?.uuid;

    const documentUuid = this.state.selectedDocumentExchange?.meta?.uuid;

    if (!documentUuid) {
      console.error('No documnetuuid');
      return;
    }

    if (!scenarioUuid || !envelopeUuid) {
      console.error('No envelope or scenario set...');
      return;
    }

    const link = createTurnaroundLink({
      scenarioUuid,
      envelopeUuid,
      documentUuid,
      docType: this.props.targetDocType,
      deliveryDate: dateFilter,
      consignee: {duns, storageLocation, unloadingPoint},
    });

    this.setState({link});
  };

  onDesadvLinkUpdate = ({
    dateFilter,
    unloadingPoint,
    duns,
    storageLocation,
  }) => {
    if (!dateFilter || !duns) {
      this.setState({link: null});
      return;
    }

    const documentUuid = this.state.selectedDocumentExchange?.meta?.uuid;

    if (!documentUuid) {
      // TODO
      console.error('No documnetuuid');
      this.setState({link: null});
      return;
    }

    const {scenario, envelope} = this.props;
    const envelopeUuid = envelope?.uuid;
    const scenarioUuid = scenario?.uuid;

    if (!scenarioUuid || !envelopeUuid) {
      console.error('No envelope or scenario set...');
      return;
    }

    this.updateLink(dateFilter, duns, storageLocation, unloadingPoint);
  };

  render() {
    const {sourceDocuments, targetDocType, scenario} = this.props;
    const sourceLength = Array.isArray(sourceDocuments)
      ? sourceDocuments.length
      : 0;

    const businessCaseHeadingTranslationKey =
      scenario?.businessCaseHeadingTranslationKey;

    return (
      <Fragment>
        <Modal
          data-spec="create-invoice-from-desadv-modal"
          dimmer
          open={this.props.open}
          className="_si_desadv_modal"
          onClose={this.props.onCloseModal}>
          <StyledModalHeaderContainer>
            <FormattedMessage
              id="CREATE_DOCTYPE"
              values={{
                doctype: <FormattedMessage id={targetDocType || ''} />,
              }}
            />
            <StyledModalHeaderElement>
              {businessCaseHeadingTranslationKey ? (
                <FormattedMessage
                  id={dynID(businessCaseHeadingTranslationKey)}
                  values={{correlatorId: this.props?.envelope?.correlatorId}}
                />
              ) : (
                <FormattedMessage
                  id="BUSINESSCASE_HEADLINE"
                  values={{correlatorId: this.props?.envelope?.correlatorId}}
                />
              )}
            </StyledModalHeaderElement>
          </StyledModalHeaderContainer>

          <Modal.Content style={{backgroundColor: ecosioColors.greyShades[6]}}>
            {this.state.loading ? (
              <Dimmer active inverted>
                <Loader active inline="centered" />
              </Dimmer>
            ) : (
              <>
                {sourceLength > 1 && (
                  <TurnaroundInputDocumentSelector
                    sourceDocuments={sourceDocuments}
                    selectedDocument={this.state.selectedDocumentExchange}
                    onDocumentSelect={this.onDocumentChange}
                  />
                )}
                {targetDocType === INDUSTRY_DESADV &&
                  this.state.deliveryDates && (
                    <DesadvSelector
                      deliveryDates={this.state.deliveryDates}
                      onLinkUpdate={this.onDesadvLinkUpdate}
                    />
                  )}
              </>
            )}
          </Modal.Content>
          <Modal.Actions style={{backgroundColor: ecosioColors.greyShades[6]}}>
            <StyledModalCancelButton
              data-spec={`create_${targetDocType}_modal_cancel`}
              onClick={() => {
                this.setState(
                  {
                    link: null,
                  },
                  () => this.props.onCloseModal()
                );
              }}>
              <FormattedMessage id="GENERAL_CANCEL" />
            </StyledModalCancelButton>
            <Button
              data-spec={`create_${targetDocType}`}
              primary
              as={Link}
              to={this.state.link || ''}
              disabled={!this.state.link}>
              <FormattedMessage id={targetDocType || ''} />
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

CreateTurnaroundModal.propTypes = {
  open: PropTypes.bool.isRequired,
  scenario: scenarioShape.isRequired,
  envelope: envelopesShape.isRequired,
  // these 3 props are set on demand when clicking the buttons
  // they cannot be required
  sourceDocuments: PropTypes.arrayOf(documentMetaShape),
  targetDocType: PropTypes.string,
  onCloseModal: PropTypes.func,
};

export default injectIntl(CreateTurnaroundModal);
