import PropTypes from 'prop-types';

const actionShape = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

const actionsShape = PropTypes.arrayOf(PropTypes.shape(actionShape));

export {actionsShape};
