import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedPlural} from 'react-intl';
import {intlShape} from '@ecosio/components';

/*
<MessagePlural id="MESSAGE_KEY" value={number} />

Message JSON:
MESSAGE_KEY: {
  zero: "Items"
  one: "Item",
  other: "Items"
}
*/
const MessagePlural = ({id, value, intl}) => {
  if (!intl.messages[id]) {
    return null;
  }

  return (
    <FormattedPlural
      one={intl.messages[id].one}
      other={intl.messages[id].other}
      zero={intl.messages[id].zero}
      value={value || 0}
    />
  );
};

MessagePlural.propTypes = {
  value: PropTypes.number.isRequired,
  id: PropTypes.any.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(MessagePlural);
