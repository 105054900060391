import {DESADV_WITH_COMPLEX_PACKAGING_UNIQUE_POSITION_NUMBER_SEPERATOR} from '../../../../../../constants';

/**
 *
 * SimpleDesadv with complex structure function.
 *
 * A unique position number over all cpses.
 * example:
 *   pos: 1_0 --> cps0 of pos 1
 *   pos: 1_1 --> cps1 of pos 1
 *
 * With this we ensure that each lineItem in non-root-consignmentPackagingSequences has a unique number.
 * We need this unique number to remove it from old non-root-consignmentPackagingSequences to new non-root-consignmentPackagingSequences.
 * e.g.
 *
 * onDropDownInputchange, if we move the lineItem from package 1 to package 2
 *
 * package: 1 or cps 1
 * non root => cps[1].deliverLineItems[0].positionNumber = 1_0;
 *
 * change it to different package, we need to delete it from package 1.
 *  and add it to package 2.
 *
 * package: 2 or cps 2
 * non root => cps[2].deliverLineItems[0].positionNumber = 1_0;
 */

export const generateUniquePositionNumber = (positionNumber, cpsIndex) => {
  return `${positionNumber}${DESADV_WITH_COMPLEX_PACKAGING_UNIQUE_POSITION_NUMBER_SEPERATOR}${cpsIndex}`;
};

/**
 * SimpleDesadv with complex structure function.
 *
 * Extracts the originalPositionNumber from the modified ones (modified by generateUniquePositionNumber)
 * e.g:
 *
 * positionNumber: 1_0
 *
 * extracts:  --> 1
 */
export const extractOriginalPositionNumberFromUniquePositionNumber = (
  positionNumber
) => {
  return positionNumber.split(
    DESADV_WITH_COMPLEX_PACKAGING_UNIQUE_POSITION_NUMBER_SEPERATOR
  )[0];
};

/**
 * SimpleDesadv with complex structure function.
 *
 * Extracts the cpsIndexNumber from the modified ones (modified by generateUniquePositionNumber)
 *
 *  positionNumber: 1_0
 *
 * extracts:  --> 0
 */
export const extractCpsIndexNumberFromUniquePositionNumber = (
  positionNumber
) => {
  return positionNumber.split(
    DESADV_WITH_COMPLEX_PACKAGING_UNIQUE_POSITION_NUMBER_SEPERATOR
  )[1];
};
