import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';
import {intlShape} from '@ecosio/components';
import {pageConfigShape, sendActionShape} from '../../shapes/scenarios';
import ConfirmPopup from '../ConfirmPopup';
import DocumentDraftSendButtonOptions from '../DocumentDrafts/DocumentDraftSendButtonOptions';
import {
  CREDIT_NOTE_ENCODED,
  RETURN_ORDER_TYPE,
  RETURN_ORDERS_ENCODED,
  CREDIT_NOTE_TYPE,
} from './DocumentHeader';

const SingleSendActionPopup = ({
  intl,
  docType,
  translatedDocType,
  onConfirm,
  actionType,
  isEnabled,
  saveDraftWithDefaultDelay,
  submitDraftWithOutDelay,
  saveDraftWithScheduledDate,
  currentDraftData,
}) => {
  return (
    <div style={{display: 'flex'}}>
      <ConfirmPopup
        dialogtitle={intl.formatMessage(
          {
            id: dynID(actionType.confirmKey),
          },
          {docType: translatedDocType}
        )}
        dialogcontent={intl.formatMessage(
          {
            id: dynID(actionType.confirmContentKey),
          },
          {docType: translatedDocType}
        )}
        onConfirm={(event) => onConfirm(event)}
        position="top center"
        trigger={
          <Button
            type="button"
            color="green"
            disabled={!isEnabled}
            data-spec={`send-btn-${actionType.type}`}>
            <FormattedMessage
              id={dynID(actionType.translationKey)}
              values={{
                docType: <FormattedMessage id={docType || ''} />,
              }}
            />
          </Button>
        }
      />
      <DocumentDraftSendButtonOptions
        disabled={!isEnabled}
        submitWithOutDelay={(event) => submitDraftWithOutDelay(event)}
        saveDraftWithDefaultDelay={saveDraftWithDefaultDelay}
        saveDraftWithScheduledDate={saveDraftWithScheduledDate}
        currentDraftData={currentDraftData}
      />
    </div>
  );
};

SingleSendActionPopup.propTypes = {
  actionType: sendActionShape.isRequired,
  translatedDocType: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  intl: intlShape,
  onConfirm: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  saveDraftWithDefaultDelay: PropTypes.func.isRequired,
  submitDraftWithOutDelay: PropTypes.func.isRequired,
  saveDraftWithScheduledDate: PropTypes.func.isRequired,
};

const SendDocumentButton = ({
  intl,
  docType,
  pageConfig,
  onSubmit,
  isEnabled,
  saveDraftWithDefaultDelay,
  submitDraftWithOutDelay,
  saveDraftWithScheduledDate,
  currentDraftData,
}) => {
  const documentTypeEncoded = pageConfig?.documentTypeEncoded;
  let documentType = docType;

  if (documentTypeEncoded === CREDIT_NOTE_ENCODED) {
    documentType = CREDIT_NOTE_TYPE;
  }

  if (documentTypeEncoded === RETURN_ORDERS_ENCODED) {
    documentType = RETURN_ORDER_TYPE;
  }

  const translatedDocType = intl.formatMessage({id: dynID(docType) || ''});

  let actionTypes = pageConfig?.sendActionTypes;

  if (!actionTypes) {
    console.warn(
      'pageConfig has no sendActionTypes, rendering default action type'
    );
    actionTypes = [
      {
        type: 'SEND',
        translationKey: 'GENERAL_SEND_DOCTYPE',
        confirmKey: 'DOCTYPE_SEND',
        confirmContentKey: 'DOCTYPE_SEND_CONTENT',
      },
    ];
  }
  return (
    <>
      {actionTypes.map((actionType) => (
        <SingleSendActionPopup
          key={actionType.type}
          isEnabled={isEnabled}
          actionType={actionType}
          translatedDocType={translatedDocType}
          docType={documentType}
          intl={intl}
          pageConfig={pageConfig}
          onConfirm={(event) => onSubmit(event, actionType)}
          saveDraftWithDefaultDelay={saveDraftWithDefaultDelay}
          submitDraftWithOutDelay={(event) =>
            submitDraftWithOutDelay(event, actionType)
          }
          saveDraftWithScheduledDate={saveDraftWithScheduledDate}
          currentDraftData={currentDraftData}
        />
      ))}
    </>
  );
};

SendDocumentButton.defaultProps = {
  isEnabled: true,
};

SendDocumentButton.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  docType: PropTypes.string.isRequired,
  intl: intlShape,
  isEnabled: PropTypes.bool,
  saveDraftWithDefaultDelay: PropTypes.func.isRequired,
  submitDraftWithOutDelay: PropTypes.func.isRequired,
  saveDraftWithScheduledDate: PropTypes.func.isRequired,
  currentDraftData: PropTypes.object,
};

export default injectIntl(SendDocumentButton);
