/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import {isEmpty} from 'lodash';
import {ConditionalCurrency} from '../../ConditionalCurrency';
import {ALLOWANCE} from '../../../../../../constants';

const TotalPriceCell = ({
  lineItem,
  currency,
  styleAoc = {color: '#99a4af'},
  dataSpec,
}) => {
  return isEmpty(lineItem.allowancesAndCharges) ? (
    <Table.Cell verticalAlign="top" textAlign="right" data-spec={dataSpec}>
      <ConditionalCurrency
        value={lineItem.lineItemAmount}
        currency={currency}
      />{' '}
    </Table.Cell>
  ) : (
    <Table.Cell verticalAlign="top" textAlign="right" data-spec={dataSpec}>
      {lineItem.allowancesAndCharges.map((aoc, idx) => (
        <div key={`aocTotalPrice_${idx}`}>
          <ConditionalCurrency
            value={aoc.chargeBaseQuantity}
            currency={currency}
          />{' '}
          <br />
          <div style={styleAoc}>
            {aoc.allowanceChargeQualifier === ALLOWANCE ? '-' : '+'}
            <ConditionalCurrency
              value={aoc.allowanceChargeAmount}
              currency={currency}
            />
          </div>
        </div>
      ))}
    </Table.Cell>
  );
};

export default TotalPriceCell;

TotalPriceCell.propTypes = {
  dataSpec: PropTypes.string,
  styleAoc: PropTypes.object,
  currency: PropTypes.string.isRequired,
  lineItem: PropTypes.shape({
    lineItemAmount: PropTypes.number,
    allowancesAndCharges: PropTypes.arrayOf(
      PropTypes.shape({
        chargeBaseQuantity: PropTypes.number,
        allowanceChargeAmount: PropTypes.number,
        allowanceChargeQualifier: PropTypes.string,
      })
    ),
  }),
};
