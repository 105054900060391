import React from 'react';
import {Grid, Icon, Header, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';

export const ImageWrapper = styled.div``;

export const IconWrapper = styled.span`
  height: 12px;
  width: 16px;
  margin-left: 10px;
  margin-right: 16px;
  color: ${ecosioColors.greyShades[7]};
  font-family: 'Font Awesome 5 Free';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  ${(props) =>
    props.contact &&
    css`
      color: ${ecosioColors.primaryBlue};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${(props) => props.height || '100%'};
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  max-width: 310px;
`;

export const HeaderSubHeader = styled.div`
  height: 16px;
  width: 470px;
  color: #5c5d5d;
  font-family: Lato;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyPageTemplate = ({
  image,
  firstButton,
  secondButton,
  header,
  subHeader,
  height,
  'data-spec': dataSpec,
}) => {
  return (
    <Container height={height} data-spec={dataSpec}>
      <Row>
        <Header as="h1" style={{display: 'inline'}}>
          <Header.Content>
            {header}
            <Header.Subheader>
              <HeaderSubHeader>{subHeader}</HeaderSubHeader>
            </Header.Subheader>
          </Header.Content>
        </Header>

        <ButtonContainer>
          {firstButton && (
            <Grid.Row>
              <Button
                style={{marginBottom: '4px'}}
                primary
                fluid
                onClick={firstButton.onClick}
                icon>
                {firstButton.text}
                <IconWrapper>
                  <Icon name="angle right" />
                </IconWrapper>
              </Button>
            </Grid.Row>
          )}
          {secondButton && (
            <Grid.Row>
              <Button
                style={{
                  marginTop: '4px',
                  backgroundColor: `${ecosioColors.greyShades[7]}`,
                  border: `1px solid ${ecosioColors.greyShades[3]}`,
                  color: `${ecosioColors.secondaryBlue}`,
                }}
                fluid
                primary
                onClick={secondButton.onClick}
                icon>
                {secondButton.text}
                <IconWrapper contact>
                  <Icon name="angle right" />
                </IconWrapper>
              </Button>
            </Grid.Row>
          )}
        </ButtonContainer>
      </Row>
      <ImageWrapper>
        <img
          src={image.src}
          height={image.height}
          width={image.width}
          alt="Placeholder"
        />
      </ImageWrapper>
    </Container>
  );
};

const buttonShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
});

EmptyPageTemplate.propTypes = {
  image: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
  firstButton: buttonShape,
  secondButton: buttonShape,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  height: PropTypes.string,
};

EmptyPageTemplate.defaultProps = {
  firstButton: undefined,
  secondButton: undefined,
  height: '100%',
};

export default EmptyPageTemplate;
