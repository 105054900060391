import orderBy from 'lodash/orderBy';
import {
  ALLOWANCE,
  CHARGE,
  EnableRelativeCalculationForAllowanceConstants,
} from '../constants';
import {percentageOfWithoutRound, round} from './math';

/**
 * PageConfig
 *  flag enableRelativeCalculationForAllowance -> showVatrate when allowance(flag showAllowanceVatRate)
 *
 *  enableRelativeCalculationForAllowance DEFAULT ->showAllowanceVatRate true or false-> default state, hide vatrate and enables relativeCalucation
 *  enableRelativeCalculationForAllowance FALSE -> showAllowanceVatRate true--> tg feature now --> shows vatrate and disabled relativeCalcuation
 *  enableRelativeCalculationForAllowance FALSE -> showAllowanceVatRate false --> hides vatratefield (sets vat to 0%) and disabled relative  calculation
 *  enableRelativeCalculationForAllowance USER_SELECTION -> showAllowanceVatRate true --> shows checkbox for rel cal, checked by default and vatrate shows up when rel is disabled
 *
 *   usecase buggy -> do not configure this
 *   enableRelativeCalculationForAllowance USER_SELECTION -> showAllowanceVatRate false --> hides vatfield(sets vat to 0%), hides checkbox and disabled relative  calculation
 */

/**
 *
 * This calculates the summed vatRates and their amounts for the whole document
 * based on the lineItems and the document-level allowance and charges.
 *
 * The excel on which these calculations are based on can be found
 * here: https://docs.google.com/spreadsheets/d/1xIIiEop72IozpD6-0y79HNQFYP1658STNrVNK7uyLbY/edit#gid=0
 *
 * @param lineItems an array of pre-calculated line items
 * @param docAocs an array of pre-calculated allowanceOrCharges from the document header
 * @param pageConfig configuration of the invoicePage
 */
export const calculateVatRates = (
  lineItems = [],
  docAocs = [],
  pageConfig = {}
) => {
  const showAllowanceVatRate =
    pageConfig?.docLevelAlcConfiguration?.showAllowanceVatRate || false;

  // totalLineItemAmount will be needed for lineItemWeighting
  const totalLineItemAmount = lineItems.reduce(
    (acc, cur) => acc + cur.lineItemAmount,
    0
  );

  // total sum of allowances without charges - needed for
  // vat calculation of allowances
  const totalAllowances = docAocs.reduce((acc, cur) => {
    const enableRelativeCalculationForAllowance =
      pageConfig?.docLevelAlcConfiguration
        ?.enableRelativeCalculationForAllowance;

    if (showAllowanceVatRate) {
      if (
        cur.allowanceChargeQualifier === ALLOWANCE &&
        (enableRelativeCalculationForAllowance ===
          EnableRelativeCalculationForAllowanceConstants.DEFAULT ||
          cur?.allowanceChargeRelativeCalculation)
      ) {
        return acc + cur.allowanceChargeAmount;
      } else {
        return acc;
      }
    } else if (!showAllowanceVatRate) {
      if (cur.allowanceChargeQualifier === ALLOWANCE) {
        return acc + cur.allowanceChargeAmount;
      } else {
        return acc;
      }
    }
  }, 0);

  // this is our final array which holds an entry for each distinct
  // vatrate in the document (line item or header AoC).
  let finalVatBucket = lineItems.reduce((acc, lineItem) => {
    const {vatrate, vatamount, lineItemAmount} = lineItem;
    const currentVatItem = acc[vatrate];

    if (currentVatItem) {
      const newVatAmount = currentVatItem.vatamount + vatamount;
      const newTaxableAmount = currentVatItem.taxableAmount + lineItemAmount;
      acc[vatrate] = {
        vatrate,
        vatamount: newVatAmount,
        taxableAmount: newTaxableAmount,
      };
    } else {
      acc[vatrate] = {
        vatrate,
        vatamount,
        taxableAmount: lineItemAmount,
      };
    }
    return acc;
  }, {});

  // this array contained grouped relative percentages for distinct vatRates
  // of all lineItems.

  // each entry contains:
  // vatrate: the vatrate of this bucket (e.g. 7, 19, 20, etc)
  // relativePercentageOfTotal: the sum percentage this vatRate relative of the total invoice amount
  // this variable says: "line items with 19% VatRate make 27% of the invoice total"

  // add the relative amount in percentage of to each lineItem
  // relative to the totalLineItemAmount
  const lineItemVatBuckets = lineItems.reduce((acc, lineItem) => {
    // how many percentage of the total invoice amount is this line item?
    // i.e. "this line item is 29% of the all line item amounts of this invoice"

    //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/427#note_213102
    const relativePercentageOfTotal =
      totalLineItemAmount === 0
        ? 0
        : (lineItem.lineItemAmount * 100) / totalLineItemAmount;
    const newLineItem = {
      ...lineItem,
      relativePercentageOfTotal,
    };

    const {vatrate} = newLineItem;
    const currentVatItem = acc[vatrate];

    // vatRate exists, add the relativeAmount
    if (currentVatItem) {
      // add the relativePercentage of this lineItem to the vatGroup
      const newRelativePercentage =
        acc[vatrate].relativePercentageOfTotal + relativePercentageOfTotal;
      // replace the existing vat object with the updated one
      acc[vatrate] = {
        vatrate,
        relativePercentageOfTotal: newRelativePercentage,
      };
    } else {
      // vatRate does not exist yet in the array, create a new one
      acc[vatrate] = {
        vatrate,
        relativePercentageOfTotal,
      };
    }

    return acc;
  }, {});

  // now add the charges of the header AoCs.
  finalVatBucket = docAocs.reduce((acc, aoc) => {
    if (!showAllowanceVatRate && aoc.allowanceChargeQualifier === ALLOWANCE) {
      return acc;
    }
    const {
      vatrate,
      vatamount,
      allowanceChargeAmount,
      allowanceChargeQualifier,
    } = aoc;
    const currentVatItem = acc[vatrate];

    if (currentVatItem && !showAllowanceVatRate) {
      const newVatAmount = acc[vatrate].vatamount + vatamount;
      const newTaxableAmount =
        acc[vatrate].taxableAmount + allowanceChargeAmount;
      acc[vatrate] = {
        vatrate,
        vatamount: newVatAmount,
        taxableAmount: newTaxableAmount,
        //will be removed at the end, need it to check which alc should be calculated with relativeCalculation
        allowanceChargeRelativeCalculation:
          aoc.allowanceChargeRelativeCalculation,
      };
    } else if (currentVatItem && showAllowanceVatRate) {
      // check if relativeCalc should be enabled
      let shouldEnableRelativeCal = true;

      switch (
        pageConfig?.docLevelAlcConfiguration
          ?.enableRelativeCalculationForAllowance
      ) {
        case EnableRelativeCalculationForAllowanceConstants.FALSE:
          shouldEnableRelativeCal = false;
          break;
        case EnableRelativeCalculationForAllowanceConstants.DEFAULT:
          shouldEnableRelativeCal = true;
          break;

        case EnableRelativeCalculationForAllowanceConstants.USER_SELECTION:
          shouldEnableRelativeCal = Object.values(acc).reduce((a, c) => {
            const tempVatrate = `${vatrate}`;
            const cVatrate = `${c.vatrate}`;
            //check if the same vatrate has allowanceChargeRelativeCalculation enabled
            if (
              c?.allowanceChargeRelativeCalculation === true &&
              cVatrate === tempVatrate
            ) {
              a = true;
            }
            //check if 0 vatrate has allowanceChargeRelativeCalculation enabled
            if (
              cVatrate === '0' &&
              c?.allowanceChargeRelativeCalculation === true
            ) {
              a = true;
            }

            return a;
          }, false);
          break;
        default:
          shouldEnableRelativeCal = true;
      }

      const newVatAmount =
        allowanceChargeQualifier === CHARGE
          ? acc[vatrate].vatamount + vatamount
          : acc[vatrate].vatamount - vatamount;
      const newTaxableAmount =
        allowanceChargeQualifier === CHARGE
          ? acc[vatrate].taxableAmount + allowanceChargeAmount
          : acc[vatrate].taxableAmount - allowanceChargeAmount;

      acc[vatrate] = {
        vatrate,
        vatamount: newVatAmount,
        taxableAmount: newTaxableAmount,
        //will be removed at the end, need it to check which alc should be calculated with relativeCalculation
        allowanceChargeRelativeCalculation: aoc?.allowanceChargeRelativeCalculation
          ? true
          : shouldEnableRelativeCal,
      };
    } else {
      //for allowance minus and for charge plus
      const vatAmountResult =
        aoc.allowanceChargeQualifier === ALLOWANCE
          ? Math.abs(vatamount) * -1
          : Math.abs(vatamount);

      const allowanceChargeAmountResult =
        aoc.allowanceChargeQualifier === ALLOWANCE
          ? Math.abs(allowanceChargeAmount) * -1
          : Math.abs(allowanceChargeAmount);

      acc[vatrate] = {
        vatrate,
        vatamount: vatAmountResult,
        taxableAmount: allowanceChargeAmountResult,
        //will be removed at the end, need it to check which alc should be calculated with relativeCalculation
        allowanceChargeRelativeCalculation:
          aoc.allowanceChargeRelativeCalculation,
      };
    }

    return acc;
  }, finalVatBucket);

  // we now have an array where the relativePercentages of each
  // line item amount are grouped by vatRate for all line items
  // the next reduce call subtracts reduces the vatAmount and
  // taxableAmount for each lineItemVatItem based on its
  // relativePercentage of the totalAllowances
  finalVatBucket = Object.values(lineItemVatBuckets)
    // calculate the relative vat amounts for each group
    .reduce((acc, vatItem) => {
      const {vatrate} = vatItem;
      const currentVatItem = acc[vatrate];

      //-------------------------------

      let doRelativeCalculation = false;

      switch (
        pageConfig?.docLevelAlcConfiguration
          ?.enableRelativeCalculationForAllowance
      ) {
        case EnableRelativeCalculationForAllowanceConstants.FALSE:
          doRelativeCalculation = false;
          break;
        case EnableRelativeCalculationForAllowanceConstants.DEFAULT:
          doRelativeCalculation = true;
          break;

        case EnableRelativeCalculationForAllowanceConstants.USER_SELECTION:
          doRelativeCalculation = finalVatBucket[0]
            ?.allowanceChargeRelativeCalculation
            ? true
            : false;
          if (
            currentVatItem &&
            finalVatBucket[vatrate] &&
            finalVatBucket[vatrate]?.allowanceChargeRelativeCalculation ===
              false
          ) {
            doRelativeCalculation = false;
          }

          if (
            currentVatItem &&
            finalVatBucket[vatrate] &&
            finalVatBucket[vatrate]?.allowanceChargeRelativeCalculation === true
          ) {
            doRelativeCalculation = true;
          }
          break;
        default:
          doRelativeCalculation = false;
      }
      //---------------------------------

      // calculate the relative allowance based on the total allowances
      /**
       * we dont need to add relative allowanceAmount to lineItems here,
       * because the allowance amount is subtracted from the totalInvoiceAmount
       * we set it to 0 here
       * https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/730
       */
      const relativeAllowanceAmount =
        doRelativeCalculation || !showAllowanceVatRate
          ? percentageOfWithoutRound(
              vatItem.relativePercentageOfTotal,
              totalAllowances
            )
          : 0;

      const vatamount = percentageOfWithoutRound(
        vatItem.vatrate,
        relativeAllowanceAmount
      );

      if (currentVatItem) {
        const newVatAmount = acc[vatrate].vatamount - vatamount;
        const newTaxableAmount =
          acc[vatrate].taxableAmount - relativeAllowanceAmount;

        acc[vatrate] = {
          vatrate,
          vatamount: newVatAmount,
          taxableAmount: newTaxableAmount,
        };
      } else {
        acc[vatrate] = {
          vatrate,
          vatamount,
          // if the reduction / allowance is the first item here it needs
          // to be negative
          taxableAmount: 0 - relativeAllowanceAmount,
        };
      }

      return acc;
    }, finalVatBucket);

  //we only need one taxcode, for footer
  const taxCode = lineItems[0]?.vattaxCode;

  // round them
  finalVatBucket = Object.values(finalVatBucket).map((vatItem) => {
    // we should remove it here, as the usage of it for calculation is finíshed.
    delete vatItem['allowanceChargeRelativeCalculation'];
    return {
      ...vatItem,
      vatamount: round(vatItem.vatamount),
      taxableAmount: round(vatItem.taxableAmount),
      vattaxCode: taxCode,
    };
  });
  // and sort them by vatrate
  return orderBy(finalVatBucket, ['vatrate'], ['asc']);
};
