/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {InputArray, Input, Form, dynID} from '@ecosio/pathform';
import PropTypes from 'prop-types';
import {Modal, Header, Button, Icon, Popup} from 'semantic-ui-react';
import styled from 'styled-components';
import {get} from 'lodash';
import {injectIntl, FormattedMessage as Msg} from 'react-intl';
import {connect} from 'react-redux';
import {intlShape} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import jexl from 'jexl';
import {pageConfigShape} from '../../../../../../shapes/scenarios';
import DesadvInput from '../../CommonComponents/Create/LineItems/DesadvInput';
import FieldContent from '../../CommonComponents/Create/LineItems/FieldContent';
import {ContentDivider} from '../../CommonComponents/Create/Header/StyledDivs';
import {loadTheme} from '../../../../../../helpers/utils';
import {
  DESADV_NULL_POSITION,
  ECOSIO_THEME,
  COOP_BUSINESSCASE_EXPRESSION_X2,
} from '../../../../../../helpers/constants';
import {generateDesadvPackageNumbers} from '../../../Utils/CreateUtils';
import {generateUniquePositionNumber} from '../../CommonComponents/SimpleWithComplexStructureFunctions/positionNumberTransform';
import SimpleComplexPackagingDropDownField, {
  moveToCps,
} from './SimpleComplexPackagingDropDownField';
import ModalSideBar from './SimpleComplexModalSideBar';

export const validateSsccDuplicates = (value, values) => {
  //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/514
  if (value && `${value}`.length === 18) {
    const duplicates = [];
    const deliItems =
      values?.consignmentPackagingSequences[0]?.deliveryLineItems || [];
    for (const item of deliItems) {
      const itemCpses = item?.cpses || [];
      for (const cps of itemCpses) {
        if (
          cps?.packagingInformations[0]?.identifications[0]?.identityNumber ===
          value
        ) {
          duplicates.push(cps);
        }
      }
    }
    if (duplicates.length > 1) {
      return 'GENERAL_SSCC_DUPLICATE';
    }
  }

  return undefined;
};

export const validateCpsPackagingInformation = (
  packagingInformationsArrayFieldName,
  value,
  values,
  deliveryLineItemIndex,
  cpsesKey,
  errorTranslationKey
) => {
  //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/800
  const packetNumber = get(
    values,
    `consignmentPackagingSequences[0].deliveryLineItems[${deliveryLineItemIndex}].cpses[${cpsesKey}].packagingInformations[0].quantityLanded`
  );

  if (!packetNumber) {
    return undefined;
  }

  const customArray = values?.consignmentPackagingSequences[0]?.deliveryLineItems[
    deliveryLineItemIndex
  ]?.cpses.map((cps, key) => {
    return {
      cpsesKey: key,
      [packagingInformationsArrayFieldName]: get(
        cps,
        `packagingInformations[0].${packagingInformationsArrayFieldName}`,
        'undefined'
      ),
      quantityLanded: get(
        cps,
        'packagingInformations[0].quantityLanded',
        'undefined'
      ),
    };
  });

  const filteredArray = customArray.filter(
    (item) => item?.quantityLanded === packetNumber
  );

  if (filteredArray.length <= 1) {
    return undefined;
  }

  const allEqual = filteredArray.every(
    (item) => `${get(item, packagingInformationsArrayFieldName)}` === `${value}`
  );

  if (!allEqual) {
    return errorTranslationKey || 'GENERAL_ERROR';
  }
  return undefined;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props?.index !== 0 ? '32px' : '0px')};
  margin-bottom: ${(props) => (props?.islastLineItem ? '10em' : '0em')};
  button {
    background: none !important;
    i{
     font-size: 17px !important;
     }
    }
  }
`;

const ContainerHeader = styled.div`
  flex: 1;
  display: flex;
`;

const PackagingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props?.backgroundColor ? `background:${props.backgroundColor}` : ''}
`;

const PackagingLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    display: flex;
    i {
      margin-right: 8px;
      font-size: 12px !important;
    }
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    display: flex;
    i {
      margin-right: 8px;
      font-size: 12px !important;
    }
  }
  .ui.header {
    margin-bottom: 4px !important;
  }
`;

const StyledButton = styled(Button)`
  #add-icon {
    font-size: 12px !important;
  }
  ${(props) =>
    props?.customColor ? `color:${props.customColor} !important` : ''}
`;

const StyledModifyButton = styled(Button)`
  ${(props) =>
    props?.customColor ? `color:${props.customColor} !important` : ''}
`;

const StyledModifyButtonContainer = styled.div`
  display: flex;
  ${(props) =>
    props?.renderLabel ? `padding-top: 10px;` : 'padding-top: 0px;'}
`;

const ModalContainer = styled.div``;

const ModalSubContainer = styled.div`
  display: flex;
  width: 100% !important;
`;

const ModalContentContainer = styled.div`
  width: 100% !important;
  min-height: 70vh;
`;

const DesadvModalLineItem = ({
  index,
  name,
  deliveryLineItem,
  pageConfig,
  theme,
  progressNumber,
  intl,
  exposedValues,
  formContext,
  lineItemContext,
}) => {
  const packagingLabel = pageConfig?.cpsConfigs[0]?.label;
  const formFieldConfig = pageConfig?.formFields || {};

  const currentTheme = loadTheme(theme);
  const isSimpleV2WithComplexStructure =
    exposedValues?.header?.businessCaseType === 'X2';

  const ctxFields = lineItemContext?.fields || [];
  const islastLineItem = ctxFields.length > 0 && ctxFields.length === index + 1;
  return (
    <Container
      index={index}
      islastLineItem={islastLineItem}
      data-type="position"
      data-spec={`modal-position-${index}`}>
      <ContainerHeader role="grid">
        <DesadvInput
          columnWidth={6}
          path="itemDescription"
          forceRender
          exposedValues={exposedValues}
          render={(props) => (
            <FieldContent
              {...props}
              value={deliveryLineItem?.itemDescription}
              injectedLabelValue={
                deliveryLineItem.references?.agreementOrOrderLineNumber
              }
              fieldConfig={get(formFieldConfig, 'itemDescription')}
            />
          )}
        />
        <DesadvInput
          columnWidth={3}
          path="gtin"
          forceRender
          exposedValues={exposedValues}
          render={(props) => (
            <FieldContent
              {...props}
              value={deliveryLineItem?.gtin}
              fieldConfig={get(formFieldConfig, 'gtin')}
            />
          )}
        />
        <DesadvInput
          columnWidth={3}
          path="packagingInformations.numberOfPackages"
          forceRender
          exposedValues={exposedValues}
          render={(props) => (
            <FieldContent
              {...props}
              value={`${deliveryLineItem.cpses.length}`}
              unit="LE_PACKAGES"
              fieldConfig={get(
                formFieldConfig,
                'packagingInformations.numberOfPackages'
              )}
            />
          )}
        />
        <DesadvInput
          columnWidth={3}
          forceRender
          exposedValues={exposedValues}
          render={() => (
            <ProgressContainer>
              <label className="themed" data-spec="progress">
                <Msg id={dynID('Progress')} />
              </label>
              <div>
                {Number(progressNumber) !==
                Number(deliveryLineItem?.dispatchQuantity?.value) ? (
                  <Icon name="warning circle" color="red" />
                ) : (
                  <Icon name="check" />
                )}
                {Number(progressNumber)}/
                {deliveryLineItem?.dispatchQuantity?.value}
                {` `}
                <Msg id={dynID(deliveryLineItem?.dispatchQuantity?.unit)} />
              </div>
            </ProgressContainer>
          )}
        />
      </ContainerHeader>
      <ContentDivider />
      <InputArray
        name={`${name}.cpses`}
        path={`${name}.cpses`}
        // eslint-disable-next-line radar/cognitive-complexity
        render={(cpses) => {
          if (!cpses.fields.length) {
            return null;
          }

          let totalDispatchQuantity = deliveryLineItem.dispatchQuantity.value;
          const dispatchQuantityUnit = deliveryLineItem.dispatchQuantity.unit;
          const quantityPerPack = deliveryLineItem.quantityPerPack;

          return cpses.fields.map((cpsesPath, cpsesKey) => {
            totalDispatchQuantity = totalDispatchQuantity - quantityPerPack;
            const ssccNumber = get(
              cpses.fields?.value[cpsesKey],
              'packagingInformations[0].identifications[0].identityNumber',
              undefined
            );
            const isNullPosition = ssccNumber === DESADV_NULL_POSITION;

            return (
              <>
                <PackagingContainer
                  data-spec={`packaging-${cpsesKey}`}
                  data-type="packaging"
                  key={cpsesKey}
                  className="desadvModalPackagingEditFieldsComponent">
                  <DesadvInput
                    columnWidth={3}
                    forceRender
                    exposedValues={exposedValues}
                    render={() => (
                      <PackagingLabelContainer>
                        <Header as="h4" data-spec="pck-type">
                          <Icon name="pallet" />
                          <Msg id={dynID(packagingLabel)} />
                        </Header>
                        <div data-spec="quantityPerPack">
                          {cpses?.fields?.value[cpsesKey]
                            ? cpses?.fields?.value[cpsesKey]
                                ?.packagingInformations[0]?.quantityPerPack
                            : deliveryLineItem?.cpses[cpsesKey]
                                ?.packagingInformations[0]
                                ?.quantityPerPack}{' '}
                          / {deliveryLineItem?.quantityPerPack}{' '}
                          <Msg id={dynID(dispatchQuantityUnit)} />
                        </div>
                      </PackagingLabelContainer>
                    )}
                  />
                  <DesadvInput
                    columnWidth={2}
                    path="packagingInformations.quantityPerPack"
                    formFieldConfig={formFieldConfig}
                    exposedValues={exposedValues}
                    render={(props) => (
                      <Input
                        {...props}
                        key={`${cpsesPath}.packagingInformations[0].quantityPerPack-${cpses.fields.length}`}
                        name={`${cpsesPath}.packagingInformations[0].quantityPerPack`}
                        path="packagingInformations.quantityPerPack"
                        disabled={isNullPosition}
                        renderLabel={cpsesKey === 0}
                        validate={(value, values, meta) => {
                          let currentValue = value;
                          let currentPackageMaxValue =
                            deliveryLineItem?.quantityPerPack;

                          const dispatchQuantityValue = Number(
                            deliveryLineItem?.dispatchQuantity?.value
                          );

                          if (
                            typeof currentValue !== 'number' ||
                            typeof currentPackageMaxValue !== 'number'
                          ) {
                            currentValue = Number(value);
                            currentPackageMaxValue = Number(
                              deliveryLineItem?.quantityPerPack
                            );
                          }

                          if (currentValue > currentPackageMaxValue) {
                            return 'COOP_MODAL_CURRENT_CPS_QUANTITY_EXCEEDED';
                          } else if (
                            progressNumber !== dispatchQuantityValue &&
                            (!currentValue ||
                              meta?.dirty ||
                              currentValue !== currentPackageMaxValue)
                          ) {
                            return 'COOP_MODAL_TOTAL_QUANTITY_NOT_CORRECT';
                          } else {
                            return undefined;
                          }
                        }}
                        inputLabel={{
                          basic: true,
                          content: `/ ${deliveryLineItem?.quantityPerPack}${' '}
                          ${intl.formatMessage({
                            id: dynID(dispatchQuantityUnit),
                          })}`,
                          attached: 'bottom right',
                        }}
                        labelPosition="right"
                      />
                    )}
                  />

                  <DesadvInput
                    columnWidth={2}
                    path="packagingInformations.identityNumber"
                    formFieldConfig={formFieldConfig}
                    exposedValues={exposedValues}
                    render={(props) => (
                      <Input
                        {...props}
                        name={`${cpsesPath}.packagingInformations[0].identifications[0].identityNumber`}
                        path="packagingInformations.identityNumber"
                        disabled={isNullPosition}
                        renderLabel={cpsesKey === 0}
                        validate={validateSsccDuplicates}
                      />
                    )}
                  />

                  <DesadvInput
                    columnWidth={2}
                    path="packagingInformations.packageTypeCustomer"
                    formFieldConfig={formFieldConfig}
                    exposedValues={exposedValues}
                    render={(props) => (
                      <Input
                        {...props}
                        name={`${cpsesPath}.packagingInformations[0].packageTypeCustomer`}
                        path="packagingInformations.packageTypeCustomer"
                        disabled={isNullPosition}
                        renderLabel={cpsesKey === 0}
                      />
                    )}
                  />

                  {!isNullPosition && (
                    <DesadvInput
                      columnWidth={3}
                      path="packagingInformations.packages"
                      formFieldConfig={formFieldConfig}
                      exposedValues={exposedValues}
                      render={(props) => (
                        <SimpleComplexPackagingDropDownField
                          {...props}
                          name={`${cpsesPath}.packagingInformations[0].quantityLanded`}
                          path="packagingInformations.packages"
                          disabled={isNullPosition}
                          renderLabel={cpsesKey === 0}
                          lineItemIndex={index}
                          cpsIndex={cpsesKey}
                          formContext={formContext}
                        />
                      )}
                    />
                  )}

                  <DesadvInput
                    columnWidth={2}
                    path="packagingInformations.batchNumber"
                    formFieldConfig={formFieldConfig}
                    exposedValues={exposedValues}
                    render={(props) => (
                      <Input
                        {...props}
                        name={`${cpsesPath}.packagingInformations[0].batchNumber`}
                        path="packagingInformations.batchNumber"
                        disabled={isNullPosition}
                        validate={(value, values) =>
                          validateCpsPackagingInformation(
                            `batchNumber`,
                            value,
                            values,
                            index,
                            cpsesKey,
                            'CPS_DELI_WITH_DIFFERENT_BATCHNUMBER_FOR_ONE_OLI'
                          )
                        }
                        renderLabel={cpsesKey === 0}
                      />
                    )}
                  />
                  <DesadvInput
                    columnWidth={2}
                    path="packagingInformations.expirationDate"
                    formFieldConfig={formFieldConfig}
                    exposedValues={exposedValues}
                    render={(props) => (
                      <Input
                        stripTimezone
                        {...props}
                        name={`${cpsesPath}.packagingInformations[0].expirationDate`}
                        path="packagingInformations.expirationDate"
                        renderLabel={cpsesKey === 0}
                        disabled={isNullPosition}
                        fieldConfig={get(
                          formFieldConfig,
                          'packagingInformations.expirationDate'
                        )}
                        icon="calendar alternate outline"
                        validate={(value, values) =>
                          validateCpsPackagingInformation(
                            `expirationDate`,
                            value,
                            values,
                            index,
                            cpsesKey,
                            'CPS_DELI_WITH_DIFFERENT_EXPIRATION_DATE_FOR_ONE_OLI'
                          )
                        }
                      />
                    )}
                  />
                  {!isNullPosition && (
                    <DesadvInput
                      columnWidth={1}
                      forceRender
                      exposedValues={exposedValues}
                      render={() => (
                        <StyledModifyButtonContainer
                          renderLabel={cpsesKey === 0}>
                          <StyledModifyButton
                            icon="copy"
                            data-spec={`desadv-modal-copy-transport-unit-button-${index}-${cpsesKey}`}
                            onClick={async (e) => {
                              e.preventDefault();

                              if (cpses.fields.length !== 0) {
                                const fieldValue = cpses.fields.value[cpsesKey];

                                const ssccNumber = !isSimpleV2WithComplexStructure
                                  ? await generateDesadvPackageNumbers(
                                      'SSCC_GENERATE',
                                      1
                                    )
                                  : undefined;
                                cpses.fields.push({
                                  ...fieldValue,
                                  packagingInformations: [
                                    {
                                      ...fieldValue?.packagingInformations[0],
                                      identifications: [
                                        {
                                          identityNumber:
                                            ssccNumber?.packagingNumber[0]
                                              ?.value,
                                        },
                                      ],
                                    },
                                  ],
                                });

                                if (
                                  fieldValue?.packagingInformations[0]
                                    ?.quantityLanded &&
                                  isSimpleV2WithComplexStructure
                                ) {
                                  moveToCps(
                                    fieldValue?.packagingInformations[0]
                                      ?.quantityLanded,
                                    formContext,
                                    index,
                                    //new index will be pushed to last, new field is pushed above and will be added to current cpskey+1
                                    cpses.fields.length,
                                    false
                                  );
                                }
                              }
                            }}
                            customColor={currentTheme?.primaryColor}
                          />
                          <StyledModifyButton
                            icon="trash"
                            data-spec={`desadv-modal-remove-transport-unit-button-${index}-${cpsesKey}`}
                            onClick={(e) => {
                              e.preventDefault();
                              if (cpses.fields.length > 1) {
                                const fieldValue = cpses.fields.value[cpsesKey];
                                cpses.fields.remove(cpsesKey);

                                if (
                                  fieldValue?.packagingInformations[0]
                                    ?.quantityLanded &&
                                  isSimpleV2WithComplexStructure
                                ) {
                                  moveToCps(
                                    fieldValue?.packagingInformations[0]
                                      ?.quantityLanded,
                                    formContext,
                                    index,
                                    cpsesKey,
                                    true
                                  );
                                }
                              }
                            }}
                            customColor={currentTheme?.primaryColor}
                          />
                        </StyledModifyButtonContainer>
                      )}
                    />
                  )}
                  {!isNullPosition && cpsesKey + 1 === cpses.fields.length ? (
                    <StyledButton
                      data-spec={`desadv-modal-add-transport-unit-button-${index}`}
                      onClick={async (e) => {
                        e.preventDefault();

                        const fieldValue = cpses.fields.value[cpsesKey];
                        const ssccNumber = !isSimpleV2WithComplexStructure
                          ? await generateDesadvPackageNumbers(
                              'SSCC_GENERATE',
                              1
                            )
                          : undefined;

                        cpses.fields.push({
                          ...fieldValue,
                          packagingInformations: [
                            {
                              ...fieldValue?.packagingInformations[0],
                              identifications: [
                                {
                                  identityNumber:
                                    ssccNumber?.packagingNumber[0]?.value,
                                },
                              ],
                            },
                          ],
                        });

                        if (
                          fieldValue?.packagingInformations[0]
                            ?.quantityLanded &&
                          isSimpleV2WithComplexStructure
                        ) {
                          moveToCps(
                            fieldValue?.packagingInformations[0]
                              ?.quantityLanded,
                            formContext,
                            index,
                            //new index will be pushed to last, new field is pushed above and will be added to current cpskey+1
                            cpses.fields.length
                          );
                        }
                      }}
                      customColor={currentTheme?.primaryColor}>
                      <Icon id="add-icon" name="add" />
                      &nbsp;
                      <Msg id="COOP_MODAL_ADD_CPS_BUTTON" />
                    </StyledButton>
                  ) : (
                    ''
                  )}
                </PackagingContainer>
                {cpsesKey !== cpses.fields.length - 1 && (
                  <ContentDivider style={{margin: '0px'}} />
                )}
              </>
            );
          });
        }}
      />
    </Container>
  );
};

DesadvModalLineItem.defaultProps = {
  theme: ECOSIO_THEME,
};

DesadvModalLineItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  deliveryLineItem: PropTypes.object,
  pageConfig: pageConfigShape.isRequired,
  intl: intlShape.isRequired,
  theme: PropTypes.string,
  progressNumber: PropTypes.number.isRequired,
  exposedValues: PropTypes.object.isRequired,
  formContext: PropTypes.object.isRequired,
};

/**
 *this function adds all deliveryLins to one cps --> cps[1]
 * removes all cpses, except cps[0] and cps[1]
 *
 * All lins will be added to a single package (or cps with hierarchialID==2)
 * Why hierarchialID 2?
 *
 * hierarchialID 1, is always the root cps or the whole delivery without any lins
 * e.g.
 *
 * consignmentPackagingSequences:[
 * {
 * //rootcps
 * hierarchialID:1
 * },
 * {
 * //refers to the delivery hierarchialID:1
 * hierarchicalParentID: 1,
 * //this one is the actual package, where all lins are added
 * hierarchialID:2,
 * }
 * ]
 */
export const autoGeneratePackages = async (formContext) => {
  const cpses = formContext?.values?.consignmentPackagingSequences || [];

  const nonRootPackageExists = cpses.length > 1;
  if (nonRootPackageExists) {
    const cpses = formContext?.values?.consignmentPackagingSequences || [];
    const cps0 = cpses[0];

    //build lineItems with unique posNum
    const result = await Promise.all(
      cps0.deliveryLineItems.map(async (lineItem) => {
        return await Promise.all(
          lineItem.cpses.map(async (lineCps, lineCpsIndex) => {
            const tempPositionNumber = generateUniquePositionNumber(
              lineItem?.positionNumber,
              lineCpsIndex
            );
            const currentLineItem = {
              ...lineItem,
              positionNumber: tempPositionNumber,
            };

            delete currentLineItem.cpses;

            return currentLineItem;
          })
        );
      })
    );

    formContext.form.batch(() => {
      //update cpses, only cps[0](root) and cps[1](all lins are here) allowed
      formContext.form.change('consignmentPackagingSequences', [
        {
          ...formContext.values.consignmentPackagingSequences[0],
          deliveryLineItems: formContext.values.consignmentPackagingSequences[0].deliveryLineItems.map(
            (line) => {
              return {
                ...line,
                cpses: line.cpses.map((cps) => {
                  return {
                    ...cps,
                    packagingInformations: [
                      {
                        ...cps.packagingInformations[0],
                        quantityLanded: 2,
                      },
                    ],
                  };
                }),
              };
            }
          ),
        },
        {
          ...formContext.values.consignmentPackagingSequences[1],
          hierarchicalID: '2',
          deliveryLineItems: result
            .flatMap((d) => d)
            //only allows non NULL_POSITIONS
            .filter((line) => line?.dispatchQuantity?.value),
        },
      ]);

      //change root cps numOf pack to 1
      formContext.form.change(
        `consignmentPackagingSequences[0].packagingInformations[0]`,
        {numberOfPackages: 1}
      );
    });

    return result.length;
  }

  return undefined;
};
const DesadvPackagingInformationModal = ({
  open,
  dispatchData,
  onSave,
  onCancel,
  pageConfig,
  theme,
  intl,
  exposedValues,
  locale,
}) => {
  const packagingLabel = pageConfig?.cpsConfigs[0]?.label;

  const [autoGenerateButtonDisabled, setAutoGenerateButtonDisabled] = useState(
    false
  );

  const isSimpleComplexDesadvWithSideBar = jexl.evalSync(
    COOP_BUSINESSCASE_EXPRESSION_X2,
    exposedValues
  );

  return (
    <Modal data-spec="desadv-v2-packaging-modal" open={open} size="fullscreen">
      <Form
        initialValues={dispatchData}
        onSubmit={onSave}
        locale={locale}
        fields={pageConfig?.formFields || {}}>
        {(formContext) => {
          let lineItemProgress = 0;

          return (
            <ModalContainer>
              <Header
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                  background: `${ecosioColors.greyShades[5]}`,
                  padding: '1.25rem 1.5rem',
                  borderBottom: '1px solid #22242626',
                  marginBottom: '0',
                  fontSize: '1.4rem',
                  lineHeight: '1.3em',
                  fontWeight: '700',
                }}>
                <Msg id={dynID('LE_MODAL_ADD_INFORMATION')} />
                <div>
                  {isSimpleComplexDesadvWithSideBar && (
                    <Popup
                      trigger={
                        <Button
                          data-spec="auto-generate-cps-packaging"
                          content={
                            <Msg id="COOP_AUTO_GENERATE_CPS_PACKAGING" />
                          }
                          disabled={
                            (
                              formContext?.values
                                ?.consignmentPackagingSequences || []
                            ).length <= 1
                              ? !autoGenerateButtonDisabled
                              : autoGenerateButtonDisabled
                          }
                          onClick={async (e) => {
                            e.preventDefault();

                            const result = await autoGeneratePackages(
                              formContext
                            );

                            if (!result) {
                              setAutoGenerateButtonDisabled(true);
                            } else {
                              setAutoGenerateButtonDisabled(false);
                            }
                          }}
                        />
                      }
                      content={
                        <Msg id="COOP_AUTO_GENERATE_CPS_PACKAGING_TOOLTIP" />
                      }
                    />
                  )}

                  <Icon
                    onClick={(e) => onCancel(e, formContext)}
                    style={{marginLeft: '35px'}}
                    name="cancel"
                  />
                </div>
              </Header>

              <Modal.Content>
                <ModalSubContainer>
                  <ModalSideBar
                    formFieldConfig={pageConfig?.formFields}
                    packagingLabel={packagingLabel}
                    dispatchData={dispatchData || {}}
                    exposedValues={exposedValues}
                    theme={theme}
                    formContext={formContext}
                  />
                  <ModalContentContainer>
                    <InputArray
                      name="consignmentPackagingSequences[0].deliveryLineItems"
                      path="consignmentPackagingSequences[0].deliveryLineItems"
                      render={(ctx) => {
                        if (!ctx.fields.length) {
                          //is a valid state, when all lineItems are delivered according to LINI
                          console.info(
                            'No lineitems given to simple desadvV2 line item modal'
                          );
                          return null;
                        }

                        return ctx.fields.map((name, key) => {
                          const currentLinCpses =
                            ctx?.fields?.value[key]?.cpses || [];
                          lineItemProgress = currentLinCpses.reduce(
                            (acc, curr) => {
                              const currentQuantityPerPack =
                                curr?.packagingInformations[0]
                                  ?.quantityPerPack || 0;

                              return acc + Number(currentQuantityPerPack);
                            },
                            0
                          );

                          return (
                            <>
                              <DesadvModalLineItem
                                lineItemContext={ctx}
                                progressNumber={lineItemProgress}
                                pageConfig={pageConfig}
                                index={key}
                                name={name}
                                theme={theme}
                                dispatchData={dispatchData}
                                deliveryLineItem={
                                  dispatchData?.consignmentPackagingSequences[0]
                                    ?.deliveryLineItems[key]
                                }
                                intl={intl}
                                exposedValues={exposedValues}
                                formContext={formContext}
                              />
                            </>
                          );
                        });
                      }}
                    />
                  </ModalContentContainer>
                </ModalSubContainer>
                <ContentDivider
                  style={{
                    marginTop: '0px',
                    marginLeft: '-20px',
                  }}
                />
                <div style={{float: 'left', margin: '13px 20px'}}>
                  <Button
                    data-spec="cancel"
                    color="black"
                    onClick={(e) => onCancel(e, formContext)}>
                    <Msg id="GENERAL_BACK" />
                  </Button>
                </div>
                <div style={{float: 'right', margin: '13px 20px'}}>
                  <Button
                    color="green"
                    disabled={
                      !formContext?.dirtySinceLastSubmit &&
                      formContext?.submitFailed
                    }
                    data-spec="save-and-confirm"
                    content={<Msg id="GENERAL_CONFIRM_AND_NEXT" />}
                  />
                </div>
              </Modal.Content>
            </ModalContainer>
          );
        }}
      </Form>
    </Modal>
  );
};

DesadvPackagingInformationModal.defaultProps = {
  locale: 'en',
};

DesadvPackagingInformationModal.propTypes = {
  open: PropTypes.bool,
  deliveryLineItems: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  pageConfig: pageConfigShape.isRequired,
  intl: intlShape.isRequired,
  theme: PropTypes.string.isRequired,
  dispatchData: PropTypes.object.isRequired,
  exposedValues: PropTypes.object.isRequired,
  locale: PropTypes.string,
};

const mapStateToProps = ({config, locales}) => ({
  theme: config?.userConfig?.theme,
  locale: locales?.locale || 'en',
});

export default injectIntl(
  connect(mapStateToProps)(DesadvPackagingInformationModal)
);
