import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {cloneDeep, set} from 'lodash';
import {parse} from 'qs';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Segment, Button} from 'semantic-ui-react';
import ConfirmPopup from '../../../../../ConfirmPopup';
import DocumentHeader from '../../../../DocumentHeader';
import OrderMeta from '../../../../Order/View/OrderMeta';
import {
  fetchTurnaroundDocument,
  postDocumentToEnvelope,
} from '../../../../../../reducers/turnaround';

import SendFailErrorMessage from '../../../../SendFailErrorMessage';
import SendDocumentButton from '../../../../SendDocumentButton';
import {pageConfigShape} from '../../../../../../shapes/scenarios';
import DesadvLineItemTable from './DesadvLineItemTable';
import DesAdvLineItemEditor from './DesAdvLineItemEditor';

const mapDispatchToProps = (dispatch) => ({
  postDocumentToEnvelope: (
    uuid,
    content,
    actionType,
    scenarioUuid,
    scenarioChecksum
  ) =>
    dispatch(
      postDocumentToEnvelope(
        uuid,
        content,
        actionType,
        scenarioUuid,
        scenarioChecksum
      )
    ),

  fetchTurnaround: (
    scenario,
    envelopeUuid,
    documentMetaUuid,
    documentType,
    filterParams = {}
  ) =>
    dispatch(
      fetchTurnaroundDocument(
        scenario,
        envelopeUuid,
        documentMetaUuid,
        documentType,
        filterParams
      )
    ),
});

const mapStateToProps = ({turnaround}) => ({turnaround});
const ConfirmationArea = injectIntl(
  ({docType, onConfirm, onCancel, pageConfig}) => (
    <div style={{float: 'right', marginTop: '20px'}}>
      <Fragment>
        <SendDocumentButton
          onSubmit={(event, actionType) => {
            onConfirm(actionType);
          }}
          pageConfig={pageConfig}
          docType={docType}
        />
        <ConfirmPopup
          position="left center"
          dialogtitle={
            <span>
              <FormattedMessage id="DOCTYPE_CANCEL" /> ?
            </span>
          }
          dialogcontent={<FormattedMessage id="DOCTYPE_CANCEL_CONTENT" />}
          onConfirm={onCancel}
          trigger={
            <Button color="red" type="button">
              <FormattedMessage id="GENERAL_CANCEL" />
            </Button>
          }
        />
      </Fragment>
    </div>
  )
);

ConfirmationArea.propTypes = {
  pageConfig: pageConfigShape.isRequired,
  docType: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

class DesadvForm extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    scenario: PropTypes.object,
    fetchTurnaround: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    postDocumentToEnvelope: PropTypes.func,
    envelope: PropTypes.object,
    pageConfig: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      modal: false,
      documentExchange: {},
      selectedIndex: null,
      selectedItem: null,
      nveIndex: [],
      postError: false,
    };
  }

  componentDidMount() {
    const {match, scenario} = this.props;
    const params = parse(this.props.location.search.substring(1)) || {};

    this.props
      .fetchTurnaround(
        scenario,
        match.params.envelopeUuid,
        match.params.documentUuid,
        match.params.documentType,
        params
      )
      .then((action) => {
        this.setState({
          documentExchange: action,
          fetching: false,
        });
        this.buildNveIndex();
      })
      .catch((err) => console.error(err));
  }

  cancelDocument = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  postDocument = (actionType) => {
    const {
      match: {
        params: {scenarioUuid, envelopeUuid},
      },
      history,
      scenario,
      pageConfig,
    } = this.props;

    this.props
      .postDocumentToEnvelope(
        envelopeUuid,
        this.state.documentExchange.data,
        actionType?.type,
        scenarioUuid,
        scenario?.scenarioChecksum
      )
      .then((res) => {
        const erpelMessageId = res?.documentMetadata?.erpelMessageId;

        history.push({
          pathname: `/scenario/${scenarioUuid}/envelope/${envelopeUuid}`,
          state: {
            message: 'GENERAL_DOCUMENT_SENT',
            status: 'info',
            erpelMessageId: erpelMessageId,
            downloadButtonData: {
              downloadConfigExists: pageConfig?.downloadConfigurations.length
                ? true
                : false,
              currentDocumentUuid: res?.documentMetadata?.uuid,
              envelopeUuid: envelopeUuid,
              scenario: scenario,
              currentDocumentTypeID: res?.documentMetadata?.documentTypeId,
            },
          },
        });
      })
      .catch(() => {
        this.setState({postError: true});
      });
  };

  onSaveOrderMeta = (content) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newDoc = Object.assign({}, this.state.documentExchange);
    set(newDoc, 'data.document.header', content.header);

    this.setState({
      selectedItem: null,
      selectedIndex: null,
      documentExchange: newDoc,
    });
  };

  onClickEdit = (e, {index}) => {
    this.setState((prevState) => ({
      modal: true,
      selectedIndex: index,
      selectedItem:
        prevState.documentExchange.data.document.details.dispatchData
          .consignmentPackagingSequences[index],
    }));
  };

  buildNveIndex = () => {
    /*
      This will generate a Multidimensional Array Matrix for Validation
      Array Structure is:
      [[index]: [array of LineItemNumbers]]
      this will be flattened in the Editor to
      [array of all numbers] - minus the current index of the opened lineitem
    */

    // eslint-disable-next-line react/no-access-state-in-setstate
    const nveIndex = this.state.documentExchange.data.document.details.dispatchData.consignmentPackagingSequences.reduce(
      (acc, item, index) => {
        item.packagingInformations.map((pkg) => {
          if (acc[index]) {
            acc[index] = [
              ...acc[index],
              ...pkg.identifications.map((a) => a.identityNumber),
            ];
          } else {
            acc[index] = [...pkg.identifications.map((a) => a.identityNumber)];
          }
        });
        return acc;
      },
      []
    );

    this.setState({
      nveIndex,
    });
  };

  onModalSave = (values) => {
    const newthing = this.state.documentExchange.data.document.details.dispatchData.consignmentPackagingSequences.map(
      (item, idx) => (idx === this.state.selectedIndex ? values : item)
    );
    this.setState((prevState) => ({
      documentExchange: {
        ...prevState.documentExchange,
        data: {
          ...prevState.documentExchange.data,
          document: {
            ...prevState.documentExchange.data.document,
            details: {
              ...prevState.documentExchange.data.document.details,
              dispatchData: {
                ...prevState.documentExchange.data.document.details
                  .dispatchData,
                consignmentPackagingSequences: newthing,
              },
            },
          },
        },
      },
      selectedIndex: null,
      modal: false,
      selectedItem: null,
    }));
  };

  onModalCancel = () => {
    this.setState({
      selectedItem: null,
      modal: false,
      selectedIndex: null,
    });
  };

  onTableDuplicate = (e, {index}) => {
    const clone = cloneDeep(
      this.state.documentExchange.data.document.details.dispatchData
        .consignmentPackagingSequences[index]
    );

    clone.deliveryLineItems[0].dispatchQuantity.value = 0;
    clone.packagingInformations = [];

    const consignmentPackagingSequences = [
      ...this.state.documentExchange.data.document.details.dispatchData
        .consignmentPackagingSequences,
      clone,
    ].map((item, idx) => {
      set(item, 'deliveryLineItems[0].positionNumber', idx + 1);
      return item;
    });

    this.setState((prevState) => ({
      documentExchange: {
        ...prevState.documentExchange,
        data: {
          ...prevState.documentExchange.data,
          document: {
            ...prevState.documentExchange.data.document,
            details: {
              ...prevState.documentExchange.data.document.details,
              dispatchData: {
                ...prevState.documentExchange.data.document.details
                  .dispatchData,
                consignmentPackagingSequences,
              },
            },
          },
        },
      },
      selectedIndex: null,
      modal: false,
      selectedItem: null,
    }));
  };

  onTableDelete = (e, {index}) => {
    const consignmentPackagingSequences = this.state.documentExchange.data.document.details.dispatchData.consignmentPackagingSequences
      .map((item, idx) => (idx !== index ? item : null))
      .filter((item) => item)
      .map((item, idx) => {
        set(item, 'deliveryLineItems[0].positionNumber', idx + 1);
        return item;
      });

    // eslint-disable-next-line radar/no-identical-functions
    this.setState((prevState) => ({
      documentExchange: {
        ...prevState.documentExchange,
        data: {
          ...prevState.documentExchange.data,
          document: {
            ...prevState.documentExchange.data.document,
            details: {
              ...prevState.documentExchange.data.document.details,
              dispatchData: {
                ...prevState.documentExchange.data.document.details
                  .dispatchData,
                consignmentPackagingSequences,
              },
            },
          },
        },
      },
      selectedIndex: null,
      modal: false,
      selectedItem: null,
    }));
  };

  render() {
    const {envelope, pageConfig, scenario} = this.props;

    return (
      !this.state.fetching && (
        <div className="_si_desadv_create">
          <DesAdvLineItemEditor
            fields={pageConfig.formFields}
            item={this.state.selectedItem}
            selectedIndex={this.state.selectedIndex}
            nveIndex={this.state.nveIndex}
            open={this.state.modal}
            onSave={this.onModalSave}
            onCancel={this.onModalCancel}
          />
          <React.Fragment>
            <DocumentHeader
              pageConfig={pageConfig}
              envelope={envelope.data}
              documentExchange={this.state.documentExchange}
              scenario={scenario}
            />
            <Segment>
              <OrderMeta
                documentExchange={this.state.documentExchange.data}
                pageConfig={pageConfig}
                onSubmit={this.onSaveOrderMeta}
                editable
              />
              <DesadvLineItemTable
                pageConfig={pageConfig}
                onEdit={this.onClickEdit}
                onDuplicate={this.onTableDuplicate}
                onDelete={this.onTableDelete}
                cpsitems={
                  this.state.documentExchange.data.document.details.dispatchData
                    .consignmentPackagingSequences
                }
              />
              <SendFailErrorMessage show={this.state.postError} />
              <ConfirmationArea
                pageConfig={pageConfig}
                docType={pageConfig.documentTypeId}
                onConfirm={this.postDocument}
                onCancel={this.cancelDocument}
              />
              <div style={{clear: 'both'}} />
            </Segment>
          </React.Fragment>
        </div>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesadvForm);
