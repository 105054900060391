import React from 'react';
import {Helmet} from 'react-helmet';
import {injectIntl} from 'react-intl';
import {dynID} from '@ecosio/pathform';

export const HelmetTags = injectIntl(({scenario, intl}) => (
  <Helmet>
    <title>
      {intl.formatMessage({id: dynID(scenario.nameTranslationKey)})}
    </title>
  </Helmet>
));
