/* eslint-disable react/no-array-index-key */
import React from 'react';
import {FormatDate} from '@ecosio/components';
import {FormattedMessage} from 'react-intl';
import {Segment, Header, List} from 'semantic-ui-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {flowRight} from 'lodash';

const buildDownloadLink = (baseUrl, id) => `${baseUrl}/drawings/${id}/download`;

const DownloadList = ({drawings, supplyServer}) => {
  return (
    <List horizontal>
      {drawings.map((drawing, idx) => (
        <List.Item key={idx}>
          <List.Icon name="file outline" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header
              as="a"
              target="_blank"
              href={buildDownloadLink(supplyServer, drawing.id)}>
              {drawing.fileName}
            </List.Header>
            <List.Description>
              <FormattedMessage
                id="DRAWING_SUBTITLE"
                values={{
                  date: (
                    <FormatDate
                      stripTimezone={false}
                      dateString={drawing.createdAt}
                    />
                  ),
                }}
              />
            </List.Description>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

const drawingShape = {
  createdAt: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

DownloadList.propTypes = {
  drawings: PropTypes.arrayOf(PropTypes.shape(drawingShape)).isRequired,
  supplyServer: PropTypes.string.isRequired,
};

class DrawingDownloads extends React.Component {
  static propTypes = {
    sender: PropTypes.string.isRequired,
    receiver: PropTypes.string.isRequired,
    correlator: PropTypes.string.isRequired,
  };

  state = {
    drawings: null,
  };

  componentDidMount() {
    const {sender, receiver, correlator} = this.props;
    const url = `/api/drawings?sender=${sender}&receiver=${receiver}&correlator=${correlator}`;
    axios
      .get(url)
      .then((response) => {
        const drawings = response.data;
        this.setState({
          drawings,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const {drawings} = this.state;
    const {servers} = this.props;

    if (drawings && drawings.length > 0) {
      return (
        <Segment>
          <Header as="h3" size="medium">
            <FormattedMessage id="DRAWING_DOWNLOADS" />
          </Header>
          <DownloadList
            drawings={drawings}
            supplyServer={servers.FE_CONFIG_SUPPLY_SERVER}
          />
        </Segment>
      );
    } else {
      return null;
    }
  }
}

const serversShape = PropTypes.shape({
  FE_CONFIG_WEBEDI_SERVER: PropTypes.string.isRequired,
  FE_CONFIG_SUPPLY_SERVER: PropTypes.string.isRequired,
  FE_CONFIG_OAUTH_SERVER: PropTypes.string.isRequired,
});

DrawingDownloads.propTypes = {
  servers: serversShape,
};

export default flowRight(connect(({config}) => ({...config})))(
  DrawingDownloads
);
