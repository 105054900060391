import React from 'react';
import PropTypes from 'prop-types';
import DESADV from '../../assets/doctypes/DESADV.svg?react';
import INVOICE from '../../assets/doctypes/INVOICE.svg?react';
import ORDCHG from '../../assets/doctypes/ORDCHG.svg?react';
import ORDERS from '../../assets/doctypes/ORDERS.svg?react';
import ORDRSP from '../../assets/doctypes/ORDRSP.svg?react';
import DELFOR from '../../assets/doctypes/DELFOR.svg?react';
import DELJIT from '../../assets/doctypes/DELJIT.svg?react';
import SVGRenderer from '../../helpers/components/SVGRenderer.tsx';

import {
  INDUSTRY_ORDER,
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER_RESPONSE,
  INDUSTRY_DESADV,
  INDUSTRY_INVOICE,
  INDUSTRY_DELFOR,
  INDUSTRY_DELJIT,
} from '../../shapes/supportedDocTypes';

// TODO: extract this to @ecosio/components ?
const ICONS = [
  {
    type: INDUSTRY_DELJIT,
    icon: DELJIT,
  },
  {
    type: INDUSTRY_DESADV,
    icon: DESADV,
  },
  {
    type: INDUSTRY_INVOICE,
    icon: INVOICE,
  },
  {
    type: INDUSTRY_ORDCHG,
    icon: ORDCHG,
  },
  {
    type: INDUSTRY_ORDER,
    icon: ORDERS,
  },
  {
    type: INDUSTRY_ORDER_RESPONSE,
    icon: ORDRSP,
  },
  {
    type: INDUSTRY_DELFOR,
    icon: DELFOR,
  },
];

const DocIcon = ({type, size = '22px'}) => {
  const item = ICONS.find((item) => item.type === type);
  const isIE = !!window.MSInputMethodContext && !!document.documentMode;

  if (!item) {
    console.info(`DocIcon: Cant find SVG icon ${type}`);
    return null;
  }

  // IE11 cant calculate proportionally the width to the height - so IE11 users get uneven icons
  if (isIE && size !== 'auto') {
    return <SVGRenderer height={size} width={size} svgIcon={item.icon} />;
  } else if (size === 'auto') {
    return <SVGRenderer svgIcon={item.icon} />;
  } else {
    return <SVGRenderer height={size} width={size} svgIcon={item.icon} />;
  }
};

DocIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default DocIcon;
