import React, {Fragment} from 'react';
import {Icon, Message} from 'semantic-ui-react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {dynID, toast} from '@ecosio/components';
import {loadTheme} from '../utils';
import {ECOSIO_THEME, STANDARD_TOAST_DURATION} from '../constants';

type FileDownloadObjectType = {
  errorReport?: {
    errorTranslationKey?: string;
  };
  error?: string;
  downloadId?: string;
  polling: boolean;
};

interface DownloadProgressIndicatorType {
  theme: string;
  downloadId?: string;
  fileDownload?: FileDownloadObjectType;
}

const DownloadProgressIndicator: React.FC<DownloadProgressIndicatorType> = (
  props
) => {
  const {fileDownload, theme = ECOSIO_THEME} = props;
  const downloadId = fileDownload?.downloadId;
  const downloadPending = fileDownload?.polling;
  const error =
    fileDownload?.errorReport?.errorTranslationKey || fileDownload?.error;
  const intl = useIntl();

  if (error) {
    const descriptionTranslationKeyExists = !!intl.messages[error];

    //UserFeedback when there is an error while rendering
    toast({
      title: intl.formatMessage({
        id: 'RENDERING_FAILED_NOTIFICATION_TITLE',
      }),
      description: intl.formatMessage({
        id: descriptionTranslationKeyExists
          ? dynID(error)
          : 'RENDERING_FAILED_NOTIFICATION_DEFAULT_DESCRIPTION',
      }),
      type: 'error',
      time: STANDARD_TOAST_DURATION,
    });
  }

  if (downloadId && downloadPending) {
    const currentTheme = loadTheme(theme);

    return (
      <div data-spec="download-pending">
        <Message
          icon
          size="small"
          floating
          style={{
            width: '400px',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: '1000',
          }}>
          <Icon
            color={currentTheme?.primaryColor || 'blue'}
            name="circle notched"
            loading
          />
          <Message.Content style={{display: 'flex', marginRight: 0}}>
            <div style={{flex: 6}}>
              <Message.Header>
                <FormattedMessage id="WEBEDI_DOWNLOAD_INDICATOR_HEADER" />
              </Message.Header>
              <FormattedMessage id="WEBEDI_DOWNLOAD_INDICATOR_CONTENT" />
            </div>
          </Message.Content>
        </Message>
      </div>
    );
  }

  return <Fragment />;
};

type MapToStatePropsType = {
  locales: {
    locale: string;
  };
  fileDownload: FileDownloadObjectType;
  config: {
    userConfig: {
      theme: string;
    };
  };
};

const mapStateToProps = ({
  locales,
  fileDownload,
  config,
}: MapToStatePropsType) => ({
  theme: config?.userConfig?.theme,
  fileDownload,
  locale: locales.locale,
});

export default connect(mapStateToProps)(DownloadProgressIndicator);
