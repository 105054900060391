export const INDUSTRY_ORDER = 'XML_ORDERS_1p0_ERPEL_INDUSTRY_MESSAGE';
export const INDUSTRY_ORDCHG = 'XML_ORDCHG_1p0_ERPEL_INDUSTRY_MESSAGE';
export const INDUSTRY_ORDER_RESPONSE = 'XML_ORDRSP_1p0_ERPEL_INDUSTRY_MESSAGE';
export const INDUSTRY_DESADV = 'XML_DESADV_1p0_ERPEL_INDUSTRY_MESSAGE';
export const INDUSTRY_INVOICE = 'XML_INVOIC_1p0_ERPEL_INDUSTRY_MESSAGE';
export const INDUSTRY_DELFOR = 'XML_DELFOR_1p0_ERPEL_INDUSTRY_MESSAGE';
export const INDUSTRY_DELJIT = 'XML_DELJIT_1p0_ERPEL_INDUSTRY_MESSAGE';

export const INDUSTRY_DOCUMENTS = [
  INDUSTRY_DELFOR,
  INDUSTRY_ORDER,
  INDUSTRY_ORDCHG,
  INDUSTRY_ORDER_RESPONSE,
  INDUSTRY_DESADV,
  INDUSTRY_INVOICE,
  INDUSTRY_DELJIT,
];
