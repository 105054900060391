import {Icon, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';
import {FormatDate} from '@ecosio/components';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {loadTheme} from '../../../helpers/utils';
import {ECOSIO_THEME} from '../../../helpers/constants';

export const StyledDateLabel = styled(Label)`
  color: black !important;
  background: white !important;
  font-weight: normal !important;
  border-radius: 5px;
  border: 1px solid ${ecosioColors.greyShades[3]} !important;
`;

const TaskDateLabel = ({dateString, iconColor, theme, ...rest}) => {
  iconColor = iconColor || ecosioColors.primaryBlue;
  const currentTheme = loadTheme(theme);
  return (
    <StyledDateLabel iconColor={iconColor} {...rest}>
      <Icon
        style={{color: currentTheme.primaryColor}}
        name="calendar alternate outline"></Icon>
      <FormatDate dateString={dateString} />
    </StyledDateLabel>
  );
};

TaskDateLabel.propTypes = {
  dateString: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  theme: PropTypes.object,
};

const mapStateToProps = ({config}) => ({
  theme: config?.userConfig?.theme,
});

TaskDateLabel.defaultProps = {
  theme: ECOSIO_THEME,
};

export default injectIntl(connect(mapStateToProps)(TaskDateLabel));
