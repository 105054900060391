import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {get, set, merge, isEmpty, cloneDeep} from 'lodash';
import {connect} from 'react-redux';
import {Segment, Loader, Button} from 'semantic-ui-react';
import {
  Form,
  FormGroup,
  Input,
  doInMemoryValidation,
  dynID,
} from '@ecosio/pathform';
import {Field} from 'react-final-form';
import {DirtyDialog} from '@ecosio/components';
import {injectIntl, FormattedMessage} from 'react-intl';
import {
  fetchTurnaroundDocument,
  postDocumentToEnvelope,
} from '../../../../reducers/turnaround';
import {calculateFooterTaxes} from '../../../../calculations/orderFooterCalculations';
import {recalculateLineItem} from '../../../../calculations/orderLineItemCalculations';
import {requiresLineItemConfirmation} from '../../../../util/scenarioUtil';
import {envelopesShape} from '../../../../shapes/envelopes';
import {industryDocumentShape} from '../../../../shapes/industryDocument';
import DocumentHeader from '../../DocumentHeader';
import OrderMeta from '../View/OrderMeta';
import LineItemTableWithDataGrid from '../LineItems/LineItemTableWithDataGrid';
import LineItemModal from '../LineItems/LineItemModal';
import LineItemsBatchActionSetValuesModal from '../../LineItemBatchActions/LineItemsBatchActionSetValuesModal';
import {
  DOCUMENT_SEND_ACTIONS,
  pageConfigShape,
  scenarioShape,
} from '../../../../shapes/scenarios';
import ConfirmPopup from '../../../ConfirmPopup/index';
import {shouldRecalculateLineItems} from '../../../../reducers/documentExchange';
import {newScheduleItem} from '../LineItems/OrderScheduleLines';
import SendFailErrorMessage from '../../SendFailErrorMessage';
import SendDocumentButton from '../../SendDocumentButton';
import {
  DRAFTS_DELAY_TIME,
  DRAFTS_DELAY_TIME_IN_MS_FALLBACK,
} from '../../../../constants';
import {
  deleteDraftWithUuid,
  saveDocumentDraftWithScheduledDate,
  saveDocumentDraftWithSendingDelay,
} from '../../../DocumentDrafts/draftRestCalls';
import {
  isSendingDelayFromWebEdiSettingsEnabled,
  triggerDelayErrorToast,
} from '../../../DocumentDrafts/draftUtils';
import DocumentDraftInfoHeader from '../../../DocumentDrafts/DocumentDraftInfoHeader';
import ValidationErrorMessage from './ValidationErrorMessage';

const mapStateToProps = ({orderResponse}) => ({
  orderResponse,
});

const mapDispatchToProps = (dispatch) => ({
  postOrderResponse: (
    uuid,
    content,
    actionType,
    scenarioUuid,
    scenarioChecksum
  ) =>
    dispatch(
      postDocumentToEnvelope(
        uuid,
        content,
        actionType,
        scenarioUuid,
        scenarioChecksum
      )
    ),
  fetchTurnaround: (scenario, envelopeUuid, documentUuid, outboundDocType) =>
    dispatch(
      fetchTurnaroundDocument(
        scenario,
        envelopeUuid,
        documentUuid,
        outboundDocType
      )
    ),
});

const ConfirmationArea = injectIntl(
  ({
    onSubmit,
    onCancel,
    fields,
    initialValues,
    docType,
    pageConfig,
    disabled,
    onSaveOrdersMeta,
    saveDraftWithDefaultDelay,
    submitDraftWithOutDelay,
    saveDraftWithScheduledDate,
    currentDraftData,
  }) => {
    const messageFunction = 'header.beginningOfMessage.messageFunction';
    const confirmField = fields[messageFunction];
    const formFields = {
      'header.beginningOfMessage.messageFunction': confirmField,
    };

    return (
      <div style={{float: 'right', marginTop: '20px'}}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          fields={formFields}>
          {({handleSubmit, form, values}) => (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                {/*
            Save the messageFunction value where it should be - we are here in the "global context"
            Thats the reason for name=document.header....
           */}
                <Input
                  onInputChange={(event, value) => {
                    /**
                     * Special Case
                     *
                     * Triggering onInputChange is important here:
                     * We need to validate with the inMemoryValidator, on each dropdown input change.
                     * To do that, we have to set the value for "header.beginningOfMessage.messageFunction" manually.
                     * And send the new document for validation to "onSaveOrdersMeta".
                     * The "onSaveOrdersMeta" mutates  and validates  the document
                     *
                     * If we do not do it, then the send button is always disabled, when "messageFunction" is required
                     * */
                    if (value) {
                      const newDoc = Object.assign(
                        {},
                        get(initialValues, 'document')
                      );
                      set(
                        newDoc,
                        'header.beginningOfMessage.messageFunction',
                        value
                      );
                      onSaveOrdersMeta(newDoc);
                    }
                  }}
                  path="header.beginningOfMessage.messageFunction"
                  name="document.header.beginningOfMessage.messageFunction"
                  renderLabel={false}
                  type="dropdown"
                  upward
                />
                {/* actionType is a hidden field to store the type of send action in the form */}
                <Field name="actionType" component="input" type="hidden" />
                <SendDocumentButton
                  onSubmit={(event, actionType) => {
                    // save the type of save action in the values
                    form.change('actionType', actionType.type);

                    // trigger the submit
                    handleSubmit(event);
                  }}
                  pageConfig={pageConfig}
                  docType={docType}
                  isEnabled={!disabled}
                  saveDraftWithDefaultDelay={saveDraftWithDefaultDelay}
                  submitDraftWithOutDelay={async () => {
                    const sendValues = {
                      actionType: 'SEND',
                      ...values,
                    };
                    await submitDraftWithOutDelay(sendValues);
                  }}
                  saveDraftWithScheduledDate={saveDraftWithScheduledDate}
                  currentDraftData={currentDraftData}
                />
                <ConfirmPopup
                  position="left center"
                  dialogtitle={
                    <span>
                      <FormattedMessage id="DOCTYPE_CANCEL" /> ?
                    </span>
                  }
                  dialogcontent={
                    <FormattedMessage id="DOCTYPE_CANCEL_CONTENT" />
                  }
                  onConfirm={onCancel}
                  trigger={
                    <Button color="red" type="button">
                      <FormattedMessage id="GENERAL_CANCEL" />
                    </Button>
                  }
                />
              </FormGroup>
            </form>
          )}
        </Form>
      </div>
    );
  }
);

ConfirmationArea.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fields: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  docType: PropTypes.string.isRequired,
  currentDraftData: PropTypes.object,
};

class OrderForm extends Component {
  constructor(props) {
    super(props);

    const {pageConfig} = this.props;

    this.state = {
      dirty: false,
      sending: false,
      fetching: true,
      document: {},
      selectedItem: null,
      selectedIndex: null,
      showLineItemValidationError: false,
      showMessageFunctionValidationError: false,
      postError: false,
      actionType: DOCUMENT_SEND_ACTIONS.SEND,
      inMemoryValidation: {
        hasErrors: false,
        errorData: [],
      },
      actions: this.getLinBatchActions(pageConfig),
      openBatchModal: false,
      lineBatchAction: null,
      lineItemsBatchActionsSelectedPositions: null,
      currentDraftData: null,
    };
  }

  async componentDidMount() {
    const {
      fetchTurnaround,
      scenario,
      match: {
        params: {envelopeUuid, documentUuid, documentType},
      },
      location,
    } = this.props;

    if (location?.state?.draftDocumentExchange) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        document: location?.state?.draftDocumentExchange,
        fetching: false,
        currentDraftData: location?.state?.draftData,
      });
    } else {
      await fetchTurnaround(scenario, envelopeUuid, documentUuid, documentType)
        .then((action) => {
          this.setState({
            document: action.data,
            fetching: false,
          });
        })
        .catch((err) => console.error(err));
    }

    await this.runInMemoryValidation();
  }

  selectedFetch = (selected, action) => {
    this.setState({
      openBatchModal: true,
      lineBatchAction: action,
      lineItemsBatchActionsSelectedPositions: selected,
    });
  };

  getLinBatchActions = (pageConfig) => {
    return pageConfig?.lineItemBatchConfigurations.map((value) => {
      if (!value?.dropdownTranslationKey) {
        console.error(
          'Missing Order/OrderResponse Lin-Batch-Action-DropDown translationKey'
        );
        return {
          id: dynID(value?.dropdownTranslationKey),
          label: dynID(value?.dropdownTranslationKey),
          action: () => {
            return null;
          },
        };
      }

      return {
        id: value?.dropdownTranslationKey,
        label: value?.dropdownTranslationKey,
        action: this.selectedFetch,
      };
    });
  };

  runInMemoryValidation = async () => {
    const {document} = this.state;
    const {pageConfig} = this.props;

    await doInMemoryValidation(pageConfig.formFields, document.document)
      .then((data) => {
        this.setState({
          inMemoryValidation: {
            hasErrors: false,
            errorData: data,
          },
        });
      })
      .catch((errorData) => {
        this.setState({
          inMemoryValidation: {
            hasErrors: true,
            errorData: errorData,
          },
        });
      });
  };

  onSaveOrderMeta = async (values) => {
    this.saveStateHeader(get(values, 'header'));
    await this.runInMemoryValidation();
  };

  onCancelDocument = (e) => {
    e.preventDefault();
    const {match, scenarioUuid, history} = this.props;
    const envelopeUuid = match.params.envelopeUuid;

    history.push(`/scenario/${scenarioUuid}/envelope/${envelopeUuid}`);
  };

  prepareDocumentBeforePost = async (values) => {
    const messageFunctionPath =
      'document.header.beginningOfMessage.messageFunction';
    const messageFunction = get(values, messageFunctionPath);
    const functionConfigPath = `pageConfig.formFields.${messageFunctionPath}`;
    const messageFunctionConfig = get(this.props, functionConfigPath);

    // if the messageFunction is configured and not present
    // we prevent the form submission with a validation error.
    if (typeof messageFunctionConfig !== 'undefined' && !messageFunction) {
      this.setState({
        showMessageFunctionValidationError: true,
        sending: false,
      });
      return;
    }

    await this.saveStateHeader(get(values, 'document.header'));

    const confirmationRequired = requiresLineItemConfirmation(
      this.props.pageConfig.formFields
    );

    let showLineItemValidationError = false;

    if (confirmationRequired) {
      const unconfirmedItem = this.state.document.document.details.ordersData.ordersLineItems.find(
        (item) => isEmpty(item.lineItemActionRequest)
      );
      showLineItemValidationError = typeof unconfirmedItem !== 'undefined';
    }

    if (showLineItemValidationError) {
      this.setState({
        showLineItemValidationError: true,
        showMessageFunctionValidationError: false,
      });
    }
  };

  onPostDocument = async (values) => {
    // actionType is the hidden field used to persist the send action type
    // a.k.a which send button was pressed
    const actionType = values.actionType;
    // delete the actionType from the backend values
    delete values.actionType;

    const {match, scenarioUuid, scenario, history, pageConfig} = this.props;
    const envelopeUuid = match.params.envelopeUuid;

    this.setState({
      sending: true,
    });

    await this.prepareDocumentBeforePost(values);
    // if there is some error in "prepareDocumentBeforePost", dont send the document
    if (
      this.state.showMessageFunctionValidationError ||
      this.state.showLineItemValidationError
    ) {
      return;
    }

    //validate on document post
    await this.runInMemoryValidation();
    if (!this.state.inMemoryValidation.hasErrors) {
      this.props
        .postOrderResponse(
          envelopeUuid,
          this.state.document,
          actionType,
          scenarioUuid,
          scenario?.scenarioChecksum
        )
        .then((res) => {
          this.setState({
            sending: false,
          });

          const erpelMessageId = res?.documentMetadata?.erpelMessageId;

          let pathToRedirect = `/scenario/${scenarioUuid}/envelope/${envelopeUuid}`;

          if (
            scenario?.redirectToEnvelopeListPageOnArchive &&
            actionType === 'SEND_AND_ARCHIVE'
          ) {
            pathToRedirect = `/scenario/${scenarioUuid}`;
          }

          history.push({
            pathname: pathToRedirect,
            state: {
              message: 'GENERAL_DOCUMENT_SENT',
              status: 'info',
              erpelMessageId: erpelMessageId,
              downloadButtonData: {
                downloadConfigExists: pageConfig?.downloadConfigurations.length
                  ? true
                  : false,
                currentDocumentUuid: res?.documentMetadata?.uuid,
                envelopeUuid: envelopeUuid,
                scenario: scenario,
                currentDocumentTypeID: res?.documentMetadata?.documentTypeId,
              },
            },
          });
        })
        .catch(() => {
          this.setState({postError: true});
        });
    } else {
      this.setState({
        sending: false,
      });
    }
  };

  CalcTaxesTotal = () => {
    const path = 'document.document.details.ordersData.ordersLineItems';
    const lineItems = get(this.state, path, []);
    return {document: calculateFooterTaxes(lineItems)};
  };

  onDuplicateItem = (event, {lineitem, index}) => {
    const lineItems = get(
      this.state.document.document,
      'details.ordersData.ordersLineItems',
      []
    );

    // make sure we have a deep clone of the item
    // https://gitlab.ecosio.com/code/frontend/issues/85
    const clonedItem = cloneDeep(lineitem);

    // filter out any absolute allowances and charges, keep the percentage
    // based one. Info from PW.
    const allowancesAndCharges = clonedItem.allowancesAndCharges.filter(
      (aoc) => aoc.allowanceChargeTypeCoded === 'P'
    );

    const newItem = {
      ...clonedItem,
      packagingDetails: {
        ...clonedItem?.packagingDetails,
        numberOfPackages: clonedItem?.packagingDetails?.numberOfPackages
          ? '0'
          : null,
      },
      // reset quantity
      orderedQuantity: '0',
      ordersScheduleLines: [
        {
          ...clonedItem.ordersScheduleLines[0],
          // reset quantity
          scheduledQuantity: '0',
        },
      ],
      allowancesAndCharges,
    };

    // recalculate the item and append it next to the selected one
    lineItems.splice(index + 1, 0, this.recalculateLineItemInternal(newItem));
    // update the state
    this.saveStateLineItems(lineItems, lineItems.length);
  };

  recalculateLineItemInternal = (lineItem) => {
    const should = shouldRecalculateLineItems(
      this.props.scenario,
      this.props.pageConfig.documentTypeId
    );

    if (!should) {
      return lineItem;
    }
    return recalculateLineItem(lineItem);
  };

  onEditLineItem = (event, {lineitem, index}) => {
    this.setState({
      // make sure the values are properly initialized needed for calculations
      selectedItem: this.recalculateLineItemInternal(lineitem),
      selectedIndex: index,
    });
  };

  onAddLineItem = () => {
    const newLinetItem = {ordersScheduleLines: [newScheduleItem()]};
    this.setState({
      // make sure the values are properly initialized needed for calculations
      selectedItem: newLinetItem,
      selectedIndex: undefined,
    });
  };

  onDeleteLineItem = (event, {index}) => {
    const lineItems = get(
      this.state.document.document,
      'details.ordersData.ordersLineItems',
      []
    );

    lineItems.splice(index, 1);
    this.saveStateLineItems(lineItems, index + 1, true);
  };

  saveStateHeader = (header) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newDoc = Object.assign({}, this.state.document);

    set(newDoc, 'document.header', header);

    this.setState({
      dirty: true,
      selectedItem: null,
      selectedIndex: null,
      document: newDoc,
    });
  };

  onSaveLineItem = (editedItem) => {
    let lineItems = this.state.document.document.details.ordersData
      .ordersLineItems;

    // this is a new lineItem
    if (typeof this.state.selectedIndex === 'undefined') {
      lineItems.push(editedItem);
      // this is an existing lineItem
    } else {
      lineItems = lineItems.map((item, idx) =>
        this.state.selectedIndex === idx ? editedItem : item
      );
    }

    this.saveStateLineItems(lineItems, editedItem.positionNumber);
  };

  onCancelLineItem = () => {
    this.setState({selectedItem: null, selectedIndex: null});
  };

  /**
   * 1) Updates the lineItems in the document
   * 2) Handles lineItemConfirmation validation
   * 3) Calculates the footer
   */
  // eslint-disable-next-line no-unused-vars
  saveStateLineItems = async (lineItems, positionNumber, del = false) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newDoc = Object.assign({}, this.state.document);

    set(
      newDoc,
      'document.details.ordersData.ordersLineItems',
      lineItems.map((item, idx) => ({
        ...item,
        positionNumber: idx + 1,
      }))
    );

    /*
      lodashs deepmerge merges also "references of items" from arrays
      so we kill the array, completely before merging
    */
    set(newDoc, 'document.footer.invoiceFooter.vatrates', []);
    merge(newDoc, this.CalcTaxesTotal());

    const confirmationRequired = requiresLineItemConfirmation(
      this.props.pageConfig.formFields
    );
    let showLineItemValidationError = false;

    if (confirmationRequired) {
      const unconfirmedItem = lineItems.find((item) =>
        isEmpty(item.lineItemActionRequest)
      );
      showLineItemValidationError = typeof unconfirmedItem !== 'undefined';
    }

    this.setState({
      dirty: true,
      selectedItem: null,
      selectedIndex: null,
      document: newDoc,
      showLineItemValidationError,
      openBatchModal: false,
    });

    await this.runInMemoryValidation();
  };

  setInMemoryValidationErrorFlag = (hasErrors) => {
    this.setState((prevState) => ({
      inMemoryValidation: {
        ...prevState.inMemoryValidation,
        hasErrors: hasErrors,
      },
    }));
  };

  getLinBatchActionsFormFields = (lineItemBatchConfigurations) => {
    const {lineBatchAction} = this.state;
    const lineBatchConfig = lineItemBatchConfigurations.find((config) => {
      return config.dropdownTranslationKey === lineBatchAction;
    });

    return lineBatchConfig?.formFields ? lineBatchConfig.formFields : {};
  };

  getModalHeaderTranslationKey = (lineItemBatchConfigurations) => {
    return lineItemBatchConfigurations.reduce((acc, item) => {
      if (item.batchActionType === 'BATCH_SET_LIN_VALUES') {
        if (!item.modalHeaderTranslationKey) {
          console.error(
            'Missing Order/OrderResponse Lin-Batch-Action-Modal-Headers translationKey'
          );
        }

        return item.modalHeaderTranslationKey;
      }
    }, '');
  };

  onSaveLineBatchActionSetValues = (editedItem) => {
    let lineItems = this.state.document.document.details.ordersData
      .ordersLineItems;

    lineItems = lineItems.map((item) => {
      return this.state.lineItemsBatchActionsSelectedPositions.find(
        (selected) => selected === item.positionNumber
      )
        ? merge(item, editedItem)
        : item;
    });

    this.saveStateLineItems(lineItems);
  };

  onCancelLineBatchActionSetValues = () => {
    this.setState({
      openBatchModal: false,
      lineItemsBatchActionsSelectedPositions: null,
    });
  };

  saveOrdRspDraftWithDefaultDelay = async () => {
    const {
      history,
      scenarioUuid,
      scenario,
      envelope,
      draftSettings,
      intl,
    } = this.props;

    const documentExchange = this.state?.document;

    const {currentDraftData} = this.state;
    const webediSettings = draftSettings?.webediSettings || [];

    const defaultDelayTimeObjectFromSettings = webediSettings.find(
      (setting) => setting?.type === DRAFTS_DELAY_TIME
    );

    const defaultDelayTime = defaultDelayTimeObjectFromSettings?.value
      ? Number(defaultDelayTimeObjectFromSettings?.value)
      : DRAFTS_DELAY_TIME_IN_MS_FALLBACK; //15min

    const documentNumber =
      documentExchange?.document?.header?.beginningOfMessage?.documentNumber;

    await this.prepareDocumentBeforePost(documentExchange);
    // if there is some error in "prepareDocumentBeforePost", dont send the document
    if (
      this.state.showMessageFunctionValidationError ||
      this.state.showLineItemValidationError
    ) {
      return;
    }

    //validate on document post
    await this.runInMemoryValidation();
    if (!this.state.inMemoryValidation.hasErrors) {
      saveDocumentDraftWithSendingDelay(
        scenarioUuid,
        envelope?.data?.uuid,
        documentExchange,
        documentNumber,
        defaultDelayTime,
        scenario?.scenarioChecksum,
        currentDraftData?.uuid
      )
        .then(() =>
          history.push(
            `/scenario/${scenarioUuid}/envelope/${envelope?.data?.uuid}`
          )
        )
        .catch((error) => {
          console.error(
            'Failed, saving ordrsp document draft with default delay',
            error
          );
          triggerDelayErrorToast(error, intl);
        });
    }
  };

  submitOrdRspWithOutDelay = async (values) => {
    const {currentDraftData} = this.state;

    if (currentDraftData?.uuid) {
      //validate on document post
      await this.runInMemoryValidation();
      if (!this.state.inMemoryValidation.hasErrors) {
        deleteDraftWithUuid(currentDraftData?.uuid)
          .then(async () => await this.onPostDocument(values))
          .catch((error) => {
            console.error(
              `Failed, deleting the draft ${currentDraftData?.uuid} before sending the document without delay`,
              error
            );
            triggerDelayErrorToast(error, this.props.intl);
          });
      } else {
        this.setState({
          sending: false,
        });
      }
    } else {
      await this.onPostDocument(values);
    }
  };

  saveOrdRspDraftWithScheduledDate = async (scheduledDate) => {
    const {history, intl, scenario, scenarioUuid, envelope} = this.props;

    await this.prepareDocumentBeforePost(this.state.document);
    // if there is some error in "prepareDocumentBeforePost", dont send the document
    if (
      this.state.showMessageFunctionValidationError ||
      this.state.showLineItemValidationError
    ) {
      return;
    }

    //validate on document post
    await this.runInMemoryValidation();
    if (!this.state.inMemoryValidation.hasErrors) {
      const {currentDraftData} = this.state;
      const documentExchange = this.state?.document;

      const documentNumber =
        documentExchange?.document?.header?.beginningOfMessage?.documentNumber;

      if (scheduledDate) {
        saveDocumentDraftWithScheduledDate(
          scenarioUuid,
          envelope?.data?.uuid,
          documentExchange,
          documentNumber,
          scheduledDate,
          scenario?.scenarioChecksum,
          currentDraftData?.uuid
        )
          // eslint-disable-next-line radar/no-identical-functions
          .then(() =>
            history.push(
              `/scenario/${scenarioUuid}/envelope/${envelope?.data?.uuid}`
            )
          )
          .catch((error) => {
            console.error(
              'Failed, saving Ordrsp document draft with scheduledDate delay',
              error
            );
            triggerDelayErrorToast(error, intl);
          });
      }
    }
  };

  render() {
    const {
      envelope,
      orderResponse,
      pageConfig,
      scenario,
      draftSettings,
    } = this.props;
    const {inMemoryValidation, currentDraftData} = this.state;

    const renderLoader =
      orderResponse.sending ||
      this.state.fetching ||
      draftSettings?.fetchingSettings;

    const currency = get(
      this.state,
      'document.document.header.referenceCurrency'
    );

    return renderLoader ? (
      <Loader active />
    ) : (
      <div className="_si_order_create">
        <DocumentDraftInfoHeader
          draftData={currentDraftData}
          scenarioUuid={scenario?.uuid}
          envelopeUuid={envelope?.data?.uuid}
          draftSettings={this.props.draftSettings}
        />
        <LineItemModal
          currency={currency}
          lineItem={this.state.selectedItem}
          fields={pageConfig.formFields}
          pageConfig={pageConfig}
          onSave={this.onSaveLineItem}
          onCancel={this.onCancelLineItem}
          documentTypeId={pageConfig.documentTypeId}
        />
        <LineItemsBatchActionSetValuesModal
          openBatchModal={this.state.openBatchModal}
          batchActionModalHeader={this.getModalHeaderTranslationKey(
            pageConfig.lineItemBatchConfigurations
          )}
          fields={this.getLinBatchActionsFormFields(
            pageConfig.lineItemBatchConfigurations
          )}
          pageConfig={pageConfig}
          onSave={this.onSaveLineBatchActionSetValues}
          onCancel={this.onCancelLineBatchActionSetValues}
          documentTypeId={pageConfig.documentTypeId}
        />
        <DirtyDialog dirty={this.state.dirty} />
        <React.Fragment>
          <DocumentHeader
            envelope={envelope.data}
            pageConfig={pageConfig}
            documentExchange={orderResponse}
            scenario={scenario}
          />

          <Segment>
            <OrderMeta
              showOnlyForm
              documentExchange={orderResponse.data}
              pageConfig={pageConfig}
              inMemoryValidation={inMemoryValidation}
              onSubmit={this.onSaveOrderMeta}
              editable
            />

            <LineItemTableWithDataGrid
              data={this.state.document.document}
              pageConfig={pageConfig}
              inMemoryValidation={inMemoryValidation}
              onAdd={this.onAddLineItem}
              onEdit={this.onEditLineItem}
              onDelete={this.onDeleteLineItem}
              currency={currency}
              onDuplicate={this.onDuplicateItem}
              actions={this.state.actions}
            />
            <ValidationErrorMessage
              show={this.state.showLineItemValidationError}
              id="LE_CONFIRMATION_ERROR_MESSAGE"
            />

            <ValidationErrorMessage
              show={this.state.showMessageFunctionValidationError}
              id="LE_MESSAGE_FUNCTION_ERROR_MESSAGE"
            />
            <SendFailErrorMessage show={this.state.postError} />
            <ConfirmationArea
              onSaveOrdersMeta={this.onSaveOrderMeta}
              fields={pageConfig.formFields}
              initialValues={this.state.document}
              onSubmit={async (values) => {
                isSendingDelayFromWebEdiSettingsEnabled(draftSettings)
                  ? await this.saveOrdRspDraftWithDefaultDelay()
                  : await this.submitOrdRspWithOutDelay(values);
              }}
              onCancel={this.onCancelDocument}
              docType={pageConfig.documentTypeId}
              pageConfig={pageConfig}
              disabled={inMemoryValidation.hasErrors}
              saveDraftWithDefaultDelay={this.saveOrdRspDraftWithDefaultDelay}
              submitDraftWithOutDelay={this.submitOrdRspWithOutDelay}
              saveDraftWithScheduledDate={this.saveOrdRspDraftWithScheduledDate}
              currentDraftData={currentDraftData}
            />
            <div style={{clear: 'both'}} />
          </Segment>
        </React.Fragment>
      </div>
    );
  }
}

OrderForm.propTypes = {
  scenarioUuid: PropTypes.string,
  scenario: scenarioShape,
  history: PropTypes.any,
  envelope: PropTypes.shape({data: envelopesShape, status: PropTypes.object}),
  pageConfig: pageConfigShape,
  match: PropTypes.object,
  orderResponse: industryDocumentShape,
  postOrderResponse: PropTypes.func,
  fetchTurnaround: PropTypes.func,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderForm)
);
