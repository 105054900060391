import PropTypes from 'prop-types';
/**
 * Collection point for all shapes.
 * Here are all shapes which cannot be assigned to other specific shape files
 * */

export const locationShape = PropTypes.shape({
  search: PropTypes.string.isRequired,
});

export const historyShape = PropTypes.shape({
  push: PropTypes.func.isRequired,
});
