import axios from 'axios';
import {stringify} from 'qs';
import {DEFAULT_PAGE_SIZE} from '@ecosio/components';

const PAGE_INDEX = 1;
const BASE_URL = '/';

export const BASE_SCENARIOS = `${BASE_URL}api/scenarios`;
export const BASE_ENVELOPES = `${BASE_URL}api/envelopes`;
const BASE_DOWNLOAD = `${BASE_URL}api/download`;

// paging (backend) is indexed with 1
const InitialStatePaging = {
  last: false,
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 1,
  first: true,
  numberOfElements: 0,
};

const postComplexDesadv = (
  envelopeUuid,
  documentExchange,
  cps,
  scenarioUuid,
  scenarioChecksum
) => {
  const pathParam = `?browserScenarioConfigChecksum=${scenarioChecksum}&browserScenarioUuid=${scenarioUuid}`;

  return axios.post(`${BASE_ENVELOPES}/${envelopeUuid}/desadv${pathParam}`, {
    document: documentExchange,
    payload: cps,
  });
};

const postEnvelopeCustomProperties = (envelopeUuid, props) =>
  axios.post(`${BASE_ENVELOPES}/${envelopeUuid}/props`, props);

const postEnvelopeStatus = (envelopeUuids, status) =>
  axios.post(`${BASE_ENVELOPES}/${envelopeUuids}/status`, {
    newStatus: status,
  });

const getDocumentExchange = (envelopeUuid, documentUuid) => {
  return axios.get(
    `${BASE_ENVELOPES}/${envelopeUuid}/documents/${documentUuid}`
  );
};

const postDocument = (
  envelopeUuid,
  content,
  actionType,
  scenarioUuid,
  scenarioChecksum
) => {
  const pathParam = actionType
    ? `?actionType=${actionType}&browserScenarioConfigChecksum=${scenarioChecksum}&browserScenarioUuid=${scenarioUuid}`
    : '';
  return axios.post(
    `${BASE_ENVELOPES}/${envelopeUuid}/documents${pathParam}`,
    content
  );
};

const getTurnaroundDocument = (
  envelopeUuid,
  documentMetaUuid,
  outboundDocType,
  filterParams = {}
) => {
  const params =
    filterParams && Object.keys(filterParams).length
      ? `?${stringify(filterParams)}`
      : '';
  return axios.get(
    `${BASE_ENVELOPES}/${envelopeUuid}/documents/${documentMetaUuid}/turnaround/${outboundDocType}${params}`
  );
};

const getEnvelope = (envelopeUuid) =>
  axios.get(`${BASE_ENVELOPES}/${envelopeUuid}`);

const getEnvelopesList = (scenarioUuid, query) => {
  const defaultCfg = {
    page: PAGE_INDEX,
    size: DEFAULT_PAGE_SIZE,
    sort: 'createdAt,desc',
    status: 'UPDATED,NEW,READ',
  };

  const cfg = {
    params: Object.assign({}, defaultCfg, query),
  };

  return axios.get(`${BASE_SCENARIOS}/${scenarioUuid}/envelopes`, cfg);
};

const startFileDownload = (
  envelopeUuid,
  documentUuid,
  scenarioUuid,
  locale,
  pdfRenderingConfigID,
  fileFormat,
  pageConfigUuid,
  envelopeBasedFile = true,
  fileRenderingConfigKey,
  reportMessageUuid
) => {
  const url = `${BASE_DOWNLOAD}/file/start?envelope=${envelopeUuid}&document=${documentUuid}&scenario=${scenarioUuid}&locale=${locale}&pdfRenderingConfigID=${pdfRenderingConfigID}&fileFormat=${fileFormat}`;
  const downloadPdfTypeConfig = Object.assign(
    {},
    {
      webediPageConfigUuid: pageConfigUuid,
      envelopeBasedFile: envelopeBasedFile,
      fileRenderingConfigKey: fileRenderingConfigKey,
      reportMessageUuid: reportMessageUuid,
    }
  );

  return axios.post(url, downloadPdfTypeConfig);
};

const getDownloadStatus = (downloadId) => {
  return axios.get(`${BASE_DOWNLOAD}/file/status/${downloadId}`);
};

const getPdfConfigurations = () => {
  return axios.get('/api/pdfConfiguration');
};

const startBatchPdfDownload = (scenarioUuid, action, params) => {
  const url = `/api/download/batch/file/${scenarioUuid}/${action}?${params}`;

  return axios.post(url);
};

const downloadFile = (url) => {
  return axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  });
};

export {
  InitialStatePaging,
  getEnvelopesList,
  getEnvelope,
  getDocumentExchange,
  postDocument,
  postEnvelopeStatus,
  postEnvelopeCustomProperties,
  getTurnaroundDocument,
  startFileDownload,
  getDownloadStatus,
  getPdfConfigurations,
  postComplexDesadv,
  startBatchPdfDownload,
  downloadFile,
};
