import jexl from 'jexl';

const jexlWithTransformFuctions = (expression, context) => {
  if (!expression || !context) {
    return null;
  }

  // transform for checking deliveryLineItems
  jexl.addTransform(
    'checkAllDelisPartialDeliveryIsTrueAndDispatchQuantityIsZero',
    (contextDeliveryLineItems) => {
      const deliveryLineItems = contextDeliveryLineItems || [];
      const partialDeliveryTrueAndDispatchQuantityZeroArray = deliveryLineItems.filter(
        (del) =>
          del?.partialDelivery === true &&
          `${del?.dispatchQuantity?.value}` === '0'
      );

      if (
        partialDeliveryTrueAndDispatchQuantityZeroArray.length &&
        deliveryLineItems.length &&
        partialDeliveryTrueAndDispatchQuantityZeroArray.length ===
          deliveryLineItems.length
      ) {
        return true;
      }

      return false;
    }
  );

  return jexl.evalSync(`${expression}`, context);
};

export {jexlWithTransformFuctions};
