import axios from 'axios';
import {getApplicationUrls} from '@ecosio/auth';
import {BASE_URL, WEB_EXCLUSION} from '../constants';
import {BASE_SCENARIOS} from './fetcher';

export const TYPES = {
  REQUEST: 'scenariosList/request',
  SUCCESS: 'scenariosList/success',
  ERROR: 'scenariosList/error',
  CHANGE_EDI_PERMISSION_REQUEST: 'scenariosList/changeEdiPermissionRequest',
  CHANGE_EDI_PERMISSION_SUCCESS: 'scenariosList/changeEdiPermissionSuccess',
  CHANGE_EDI_PERMISSION_ERROR: 'scenariosList/changeEdiPermissionError',
};

const initState = {
  data: [],
  // currently only used in the AppRouter to wait
  // until anything is loaded because we don't have a dashboard
  // page yet.
  scenariosLoaded: false,
  fetching: false,
  error: null,
};

const fetchScenariosRequest = () => ({
  type: TYPES.REQUEST,
});

const fetchScenariosSuccess = (data) => ({
  type: TYPES.SUCCESS,
  ediPermissions: data?.ediPermissions,
  data: data?.scenarios.map((item) => ({
    ...item,
    link: `/scenario/${item.uuid}`,
  })),
});

const fetchScenariosError = (error) => ({
  type: TYPES.ERROR,
  error: error.message,
});

const setEdiPermissionRequest = () => ({
  type: TYPES.CHANGE_EDI_PERMISSION_REQUEST,
});

const setEdiPermissionSuccess = (ediPermissionSetData) => ({
  type: TYPES.CHANGE_EDI_PERMISSION_SUCCESS,
  ediPermissionSetData: ediPermissionSetData,
});

const setEdiPermissionError = (error) => ({
  type: TYPES.CHANGE_EDI_PERMISSION_ERROR,
  error: error.message,
});

export const getScenarioUuidsForWebediPermissions = (config) => {
  if (!config || !config.userConfig) {
    console.warn('No user config provided, will use unfiltered scenarios');
    return;
  }
  const userConfig = config.userConfig;

  if (!userConfig?.ediPermissions?.length) {
    console.warn('No EDI permissions found for user');
    return;
  }

  return userConfig.ediPermissions;
};

const getEDIPermissionWithWebediScenarios = (ediPermissions) => {
  if (!ediPermissions) {
    console.warn('No EDI permissions found for user');
    return;
  }

  return ediPermissions.find((perm) => perm.webediScenarioUuids?.length > 0);
};
/**
 * Filters scenarios which are excluded in the userConfig
 */
export const preProcessScenarios = (scenarios, config) => {
  if (!config || !config.userConfig) {
    console.warn('No user config provided, will use unfiltered scenarios');
    return scenarios;
  }
  const userConfig = config.userConfig;
  if (!userConfig?.ediPermissions?.length) {
    return scenarios;
  }
  const permission = userConfig.ediPermissions.find(
    (perm) => perm.uuid === userConfig.selectedEdiPermission
  );

  if (!permission) {
    console.warn(
      'Could not find selected permission in config {}',
      userConfig?.selectedEdiPermission
    );
    return scenarios;
  }

  const exclusions = permission.exclusions || {};

  const excludedScenarios = exclusions[WEB_EXCLUSION.SCENARIO];
  if (excludedScenarios) {
    scenarios = scenarios.filter((scenario) => {
      return excludedScenarios.indexOf(scenario.uuid) === -1;
    });
  }

  const excludedDocTypes = exclusions[WEB_EXCLUSION.OUTBOUND_DOC_TYPE];
  if (excludedDocTypes) {
    scenarios = scenarios.map((scenario) => {
      const outboundWebDocTypes = scenario.outboundWebDocTypes.filter(
        (pageConfig) => {
          return excludedDocTypes.indexOf(pageConfig.documentTypeId) === -1;
        }
      );
      return {
        ...scenario,
        outboundWebDocTypes,
      };
    });
  }

  return scenarios;
};

const setEdiPermission = (dispatch, ediPermissionWithWebedi) => {
  console.info(
    `[DEBUG] EDI permission should be switched to ${ediPermissionWithWebedi.uuid}`
  );

  dispatch(setEdiPermissionRequest());
  // set new edi permission
  return axios
    .put(`/api/webediUser/setEdiPermission`, null, {
      params: {
        ediPermissionUuid: ediPermissionWithWebedi.uuid,
      },
    })
    .then((res) => {
      const redirectToAccounts = res?.data?.redirectToAccounts;
      if (redirectToAccounts !== undefined) {
        return dispatch(setEdiPermissionSuccess(res.data));
      }
    })
    .catch((error) => {
      return dispatch(setEdiPermissionError(error));
    });
};

export const fetchScenarios = () => (dispatch, getStore) => {
  dispatch(fetchScenariosRequest());
  return axios
    .get(BASE_SCENARIOS)
    .then((res) => {
      const ediPermissions = getScenarioUuidsForWebediPermissions(
        getStore().config
      );

      const scenarios = preProcessScenarios(res.data, getStore().config);

      if (scenarios?.length === 0) {
        // If the current EDI permission doesnt have webedi scenarios - find webedi permission that has and switch to it
        const ediPermissionWithWebedi = getEDIPermissionWithWebediScenarios(
          ediPermissions
        );
        if (!ediPermissionWithWebedi) {
          console.warn('No EDI permission with webedi scenario found!');
          return dispatch(
            fetchScenariosError({
              message: 'No EDI permission with webedi scenario found',
            })
          );
        } else {
          return setEdiPermission(dispatch, ediPermissionWithWebedi);
        }
      } else {
        return dispatch(
          fetchScenariosSuccess({
            scenarios,
            ediPermissions,
          })
        );
      }
    })
    .catch((error) => dispatch(fetchScenariosError(error)));
};

export function reducer(state = initState, action) {
  switch (action.type) {
    case TYPES.REQUEST:
    case TYPES.CHANGE_EDI_PERMISSION_REQUEST:
      return {
        ...state,
        fetching: true,
        scenariosLoaded: false,
        error: null,
      };
    case TYPES.SUCCESS:
      return {
        ...state,
        data: action.data,
        ediPermissions: action.ediPermissions,
        fetching: false,
        scenariosLoaded: true,
        error: null,
      };
    case TYPES.CHANGE_EDI_PERMISSION_SUCCESS:
      const {ediPermissionSetData} = action;
      const redirectToAccounts = ediPermissionSetData
        ? ediPermissionSetData.redirectToAccounts
        : false;

      //if redirectToAccounts flag is redurect, that means that edi permission is changes and indicates to which app user should be redirected
      if (redirectToAccounts === true) {
        const urls = getApplicationUrls(window.location.href);
        if (urls?.FE_CONFIG_OAUTH_SERVER?.length > 0) {
          window.location.replace(urls.FE_CONFIG_OAUTH_SERVER);
        } else {
          console.warn(
            '[WARN] Unable to determine accounts app url, redirecting to webedi base'
          );
          window.location.replace(BASE_URL);
        }
      } else {
        window.location.replace(BASE_URL);
      }
      return {
        ...state,
        ediPermissionSetData: {
          redirectToAccounts: redirectToAccounts,
        },
        fetching: false,
        scenariosLoaded: true,
        error: null,
      };
    case TYPES.ERROR:
      const errorMessage = action?.error?.message;
      if (errorMessage) {
        console.error(
          `Fetching scenarios failed : ${errorMessage}`,
          action?.error
        );
      } else if (action?.error) {
        console.error(`Fetching scenarios failed `, action?.error);
      } else {
        //this should not happen, but just in case:

        console.error(
          `Fetching scenarios failed and the error object is undefined `
        );
      }

      return {
        ...state,
        fetching: false,
        scenariosLoaded: true,
        error: action?.error,
      };

    case TYPES.CHANGE_EDI_PERMISSION_ERROR:
      const errorMessagePermissionChange = action?.error?.message;

      if (errorMessagePermissionChange) {
        console.error(
          `Setting ediPermission failed : ${errorMessagePermissionChange}`,
          action?.error
        );
      } else {
        console.error(`Setting ediPermission failed `, action?.error);
      }

      return {
        ...state,
        fetching: false,
        scenariosLoaded: true,
        error: action?.error,
      };
    default:
      return state;
  }
}
